import { useLayoutEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { reRenderAtom } from "../pages/pipeline/pipeline-atoms";

interface Size {
  width: number;
  height: number;
}

function debounce(func: Function) {
  let timer: any;
  return function (event: any) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 300, event);
  };
}

function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
] {
  const [node, setNode] = useState<T | null>(null);
  const nodeRef = useRef(node);
  const reRender = useRecoilValue(reRenderAtom);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  useLayoutEffect(() => {
    nodeRef.current = node;
    if (nodeRef.current?.offsetWidth && nodeRef.current?.offsetHeight) {
      setSize({
        width: nodeRef.current?.offsetWidth || 0,
        height: nodeRef.current?.offsetHeight || 0,
      });
    }
  }, [node]);

  useLayoutEffect(() => {
    nodeRef.current = node;
    if (nodeRef.current?.offsetWidth && nodeRef.current?.offsetHeight) {
      setTimeout(() => {
        setSize({
          width: nodeRef.current?.offsetWidth || 0,
          height: nodeRef.current?.offsetHeight || 0,
        });
      }, 5);
    }
    //eslint-disable-next-line
  }, [reRender]);

  useLayoutEffect(() => {
    const handleSize = () => {
      if (nodeRef.current?.offsetWidth && nodeRef.current?.offsetHeight) {
        setSize({
          width: nodeRef.current?.offsetWidth || 0,
          height: nodeRef.current?.offsetHeight || 0,
        });
      }
    };

    const debouncedHandler = debounce(handleSize);
    window.addEventListener("resize", debouncedHandler);
    handleSize();
    return () => window.removeEventListener("resize", debouncedHandler);
  }, [node]);

  return [setNode, size];
}

export default useElementSize;
