import React, { Dispatch, ReactNode } from "react";
import Modal from "react-modal";

import { CloseIcon } from "../assets/SvgIcons";

import "./app-modal.css";

type AppModalType = {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  children: ReactNode;
  style?: { overlay?: React.CSSProperties; content?: React.CSSProperties };
  shouldCloseOnOverlayClick?: boolean;
  showCloseButton?: boolean;
};

const ContentModal = ({
  isOpen = true,
  setIsOpen,
  children,
  style,
  shouldCloseOnOverlayClick = true,
  showCloseButton = true,
}: AppModalType) => {
  function handleAuthModalClose() {
    setIsOpen(false);
  }
  return (
    <>
      <Modal
        appElement={document.getElementById("root") as HTMLElement}
        closeTimeoutMS={300}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        className="bg-catskill dark:bg-darkBackground rounded-[7px]  shadow-md  dark:shadow-none z-10"
        style={{
          overlay: {
            display: "grid",
            justifyItems: "center",
            background: "transparent",
            backdropFilter: "blur(2px)",
            WebkitBackdropFilter: "blur(2px)",
          },
          content: {
            ...{
              width: "fit-content",
              height: "fit-content",
              inset: 0,
              margin: "auto",
            },
            ...style?.content,
          },
        }}
      >
        <div className="grid grid-cols-auto-1fr-auto min-h-[80vh] w-[50vw]  p-3  md:max-h-[calc(100vh-150px)]  shadow-xl rounded-md overflow-y-auto">
          <div className="grid items-start w-[20px]" />

          <div className="">
            <div>{children}</div>
          </div>
          <div className="grid items-start w-[20px]">
            {showCloseButton && (
              <button
                className="w-fit-content h-fit-content text-cyan fixed"
                onClick={handleAuthModalClose}
              >
                <CloseIcon />
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContentModal;
