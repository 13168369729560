import React from "react";
import { cloneDeep } from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { IconArrowLeft } from "../../assets/SvgIcons";
import { useStudioData } from "../../lib/use-studio-data";
import { curApiItemAtom } from "../../lib/atoms";
import { currDocsScrollPositionAtom } from "../../lib/atoms";
import { docsScrollPositionsAtom } from "../../lib/atoms";
import { getItemIcon, getSkillIcon, useIsMobile } from "../PipelinePage/utils";
import "./docs-style.css";
import theme from "../../theme";
import parse, {
  domToReact,
  HTMLReactParserOptions,
  Element,
} from "html-react-parser";
import * as ReactDOMServer from "react-dom/server";
import { atomOneLight, CopyBlock, dracula } from "react-code-blocks";
import { useThemeColor } from "../../components/theme-color";
import prettier from "prettier/standalone";
import babylon from "prettier/parser-babel";
import { unescape } from "lodash";

export default function APIDocumentPanel({ item, panelRef }: any) {
  const { data } = useStudioData();
  const isMobile = useIsMobile();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isDark } = useThemeColor();
  const navigate = useNavigate();
  const containerRef = React.useRef<any>();
  const setCurApiItem = useSetRecoilState<any>(curApiItemAtom);
  const setScrollPosition = useSetRecoilState<any>(docsScrollPositionsAtom);
  const scrollPositions = useRecoilValue<any>(docsScrollPositionsAtom);
  const setCurrDocsScrollPosition = useSetRecoilState<any>(
    currDocsScrollPositionAtom,
  );
  const currDocsScrollPosition = useRecoilValue<any>(
    currDocsScrollPositionAtom,
  );

  function getItemType(itemName: string) {
    const currentAPI = data?.documentation?.find((apiSections: any) => {
      return apiSections?.items?.find((item: any) => {
        return item?.item_name === itemName;
      });
    });
    return currentAPI?.type;
  }

  const type = getItemType(item?.item_name);

  const itemIcon =
    type === "skills"
      ? getSkillIcon(item?.item_name, "DOCS_LARGE", true, data)
      : type === "items"
      ? getItemIcon(item?.item_name, "DOCS_LARGE")
      : "";

  function linkClicksHandler(event: any) {
    navigationStrategy(event);
    event.preventDefault();
  }

  function htmlToString(code: any) {
    try {
      const domElements: any = domToReact(code, options);
      const newString = ReactDOMServer.renderToStaticMarkup(domElements);

      // Unescapes &#x27; to ', since unescape for some reason doesn't unescape just that!
      const replacedString = newString.replaceAll("&#x27;", "'");

      return unescape(replacedString);
    } catch (e: any) {
      return "HTML Parse Error";
    }
  }

  function navigationStrategy(event: any) {
    const targetLink = event?.target?.closest("a");
    const link = event?.target?.getAttribute("href");

    const studioPathArr = [
      "https://stage-studio.oneai.com",
      "https://studio.oneai.com",
      "https://preview-staging-studio--studio-v5-bfe5car3.web.app",
      "https://prod-preview.oneai.com",
    ];

    const studioPath = studioPathArr.some((path) => link?.includes(path));

    if (!targetLink) return;

    if (
      (link.includes("https://") || link.includes("http://")) &&
      !studioPath
    ) {
      window.open(link, "_blank");
    } else {
      event.preventDefault();
      navigate(targetLink);
    }
  }
  function formatCode(code: string) {
    try {
      return prettier.format(code, {
        parser: "babel",
        plugins: [babylon],
      });
    } catch (e) {
      return code;
    }
  }

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.attribs) {
        if (
          domNode &&
          domNode.name === "code" &&
          domNode.attribs.class === "code-block"
        ) {
          if (domNode?.children && domNode?.children?.length) {
            return (
              <pre className="no-color">
                <CopyBlock
                  language={"html"}
                  text={formatCode(htmlToString(domNode.children))}
                  theme={isDark ? dracula : atomOneLight}
                  style={{
                    borderRadius: "5px",
                  }}
                />
              </pre>
            );
          }
          return (
            <pre className="no-color">
              <CopyBlock
                language={"html"}
                text={htmlToString(domNode.children)}
                theme={isDark ? dracula : atomOneLight}
                style={{
                  borderRadius: "5px",
                }}
              />
            </pre>
          );
        } else {
          if (
            domNode &&
            domNode.name === "code" &&
            domNode.attribs.class !== "code-block"
          ) {
            return (
              <code className="px-1">{htmlToString(domNode.children)}</code>
            );
          }
        }
      }
    },
  };

  React.useEffect(() => {
    //1: store current scroll position in scrolls array atom
    const clonedPositions = cloneDeep(scrollPositions);
    const scrolledItemIdx = clonedPositions.findIndex(
      (currScrollItem: any) =>
        currScrollItem.name === currDocsScrollPosition.name,
    );
    if (scrolledItemIdx === -1) {
      // add & save in atom
      clonedPositions.push(currDocsScrollPosition);
    } else {
      //replace & save in atom
      clonedPositions.splice(scrolledItemIdx, 1, currDocsScrollPosition);
    }
    setScrollPosition(clonedPositions);

    //2: scroll to old saved position if exists
    const scrolledItem = scrollPositions.find(
      (currScrollItem: any) => currScrollItem?.name === item?.item_name,
    );
    if (scrolledItem) {
      containerRef?.current?.scrollTo({
        top: scrolledItem.position,
        behavior: "smooth",
      });
    } else {
      containerRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
    //eslint-disable-next-line
  }, [item]);

  const handleScroll = () => {
    //store current scroll position in atom
    setCurrDocsScrollPosition({
      name: item.item_name,
      position: containerRef?.current.scrollTop,
    });
  };

  return (
    <div
      ref={containerRef}
      className="overflow-x-hidden dark:bg-darkGrayBackground bg-white rounded-md w-full min-h-[400px]"
      style={{
        height: panelRef?.current?.offsetHeight,
      }}
      onScroll={handleScroll}
    >
      <div className="p-6">
        <div className=" dark:bg-darkGrayBackground bg-white grid gap-x-4 relative min-w-[100px] items-center gap-y-4">
          {isMobile && (
            <>
              <figure
                onClick={() => {
                  setCurApiItem(null);
                  searchParams.delete("item");
                  setSearchParams(searchParams);
                }}
              >
                <IconArrowLeft
                  className="text-shadeBlue"
                  height="30px"
                  width="30px"
                />
              </figure>
            </>
          )}
          {item && (
            <div className="grid grid-cols-auto-1fr items-center dark:bg-darkGrayBackground bg-white gap-x-4">
              <figure className="grid items-center">{itemIcon}</figure>
              <p className="grid dark:text-white text-black font-poppins text-xl md:text-[1.9rem] font-bold items-center leading-10 m-0 p-0">
                {item?.item_name}
              </p>
            </div>
          )}
        </div>
        <div className="pt-6 text-left dark:text-white text-black overflow-x-hidden mt-0 min-w-[100px] font-poppins font-normal leading-8">
          {item && (
            <>
              <div onClick={linkClicksHandler} className="content">
                {parse(item?.content_html || "Coming soon...", options)}
              </div>
            </>
          )}
        </div>
        <style>
          {`
          /* Links styles */
          .content a {
            color: ${theme.colors.shadeBlue};
            text-decoration: underline;
          }
          .content a:hover {
            text-decoration: underline;
          }

          /* Titles styles */
          .content h1, h2, h3, h4, h5, h6 {
            color: ${isDark ? "#FFFFFF" : "#333333"};
          }

          .content h1 {
            font-size: 2rem;
          }

          .content h2 {
            font-size: 2rem;
          }

          .content h3 {
            font-size: 1.5rem;
          }


          /* Code snippet styles */
          .content code {
            font-family: 'IBM Plex Mono', monospace;
            border-radius: 5px;
          }
          .content .no-color code {
            background-color: transparent !important;
          }

          .content code {
            background-color: ${isDark ? "rgba(0,0,0,0.5)" : "rgba(0,0,0,0.1)"};
          }
          /* Table styles */

          .content table {
            border-collapse: collapse;
            width: 100%;
          }

          .content table tr, th {
            border: 1px solid ${
              isDark ? theme.colors.grayBorderLight : theme.colors.borderLight
            };
            padding: 2px;
            padding-left: 8px;

          }
          .content table td {
            border: 1px solid ${
              isDark ? theme.colors.grayBorderLight : theme.colors.borderLight
            };
            padding: 2px;
            padding-left: 8px;

          }
          /* List styles */
          .content ul {
            list-style-type: "•  ";
            list-style-position: inside;
          }
          .content ul > li > ul { margin-left: 10%;}
          `}
        </style>
      </div>
    </div>
  );
}
