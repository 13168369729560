import { AxiosError } from "axios";
import { cloneDeep } from "lodash";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useMutateUser, useUserData } from "../components/auth/data";
import {
  apiResponseAtom,
  pipelineErrorAtom,
  pipelineSkillsAtom,
  requestIdHeader,
  runPipelineAtom,
  sharedPipelineAtom,
} from "../pages/pipeline/pipeline-atoms";
import { useGetPipeline } from "../pages/PipelinePage/api";
import {
  capitalizeFirstCharacter,
  detectInputType,
  inputSelectionTypes,
  stripHTML,
} from "../pages/PipelinePage/utils";
import {
  cubeStateAtom,
  fileContentAtom,
  inputResponseAtom,
  inputTypeAtom,
  loaderAtom,
  summaryOriginsAtom,
  textAreaAtom,
} from "./atoms";
import { useEventLogger, UserEvent } from "./event_logger";
import highlightRange from "./InputHighlight";
import { useExperiment, variantTwo } from "./use-experiment";
import useRequestBuilder from "./use-request-builder";
import { useSummaryOrigin } from "./use-summary-origin";

export default function useRunPipeline() {
  // Hooks
  const requestBuilder = useRequestBuilder();
  const navigate = useNavigate();
  const hasErrors = usePipelineErrors();
  const { userEventLogger } = useEventLogger();
  const { getPipeline } = useGetPipeline();
  const { increaseExperimentCount } = useMutateUser();
  const { value: userData } = useUserData();
  const { isSummary } = useSummaryOrigin();
  const [searchParams, setSearchParams] = useSearchParams();

  // Setters
  const setInputResponse = useSetRecoilState(inputResponseAtom);

  const setLoader = useSetRecoilState(loaderAtom);
  const setPipelineError = useSetRecoilState(pipelineErrorAtom);

  // States
  const cubeState = useRecoilValue(cubeStateAtom);
  const [summaryOrigins, setSummaryOrigins] =
    useRecoilState<boolean>(summaryOriginsAtom);
  const setApiResponse = useSetRecoilState(apiResponseAtom);
  const [textArea, setTextArea] = useRecoilState(textAreaAtom);
  const setRunningPipeline = useSetRecoilState(runPipelineAtom);
  const sharedPipeline = useRecoilValue(sharedPipelineAtom);
  const { isParticipating, variant, almostReachedLimit } = useExperiment();
  const setRequestID = useSetRecoilState(requestIdHeader);
  const setFileContent = useSetRecoilState(fileContentAtom);
  const { search } = useLocation();
  const [currInputType, setSelectedInputType] = useRecoilState(inputTypeAtom);
  const hasPipelineErrors = hasErrors();

  const runPipeline = async () => {
    if (almostReachedLimit && variant === variantTwo) {
      increaseExperimentCount();
      return;
    }

    if (hasPipelineErrors.length) {
      return;
    }
    let {
      input,
      input_type,
      steps,
      unfiltered_steps: unfilteredSteps,
    } = requestBuilder();

    const skillNamesArray = unfilteredSteps?.map((skill: any) => skill?.skill);
    const isOnlySummary =
      skillNamesArray.length === 1 && skillNamesArray.includes("summarize");

    setRunningPipeline(true);

    userEventLogger(UserEvent.RUN_PIPELINE, {
      value: {
        data: skillNamesArray?.join(","),
        pipeline_is_summary: isOnlySummary,
      },
    });

    if (!window.location.search.includes("?pipeline")) {
      userEventLogger(UserEvent.RUN_PIPELINE_CUSTOM, {
        value: {
          data: skillNamesArray?.join(","),
          pipeline_is_summary: isOnlySummary,
        },
      });
    } else {
      if (!sharedPipeline) {
        userEventLogger(UserEvent.RUN_PIPELINE_SAMPLE, {
          value: { data: input_type, pipeline_is_summary: isOnlySummary },
        });
      } else {
        userEventLogger(UserEvent.RUN_PIPELINE_SHARE, {
          value: {
            data: searchParams.get("pipeline"),
            pipeline_is_summary: isOnlySummary,
          },
        });
      }
    }

    navigate(`/pipeline-output${search}`);
    setLoader(true);

    let formattedText = input;
    if (summaryOrigins) {
      formattedText = stripHTML(input);
    }

    getPipeline(formattedText, input_type, steps, userData?.studio_key)
      .then(({ data, headers }: any) => {
        if (data?.input_text && cubeState === "FINISHED") {
          setInputResponse(true);
          setTextArea(data?.input_text);

          if (currInputType === inputSelectionTypes.article) {
            setSelectedInputType(detectInputType(data?.input_text));
          }
          setFileContent("");
        }

        setRequestID(headers?.["x-oneai-request-id"]);

        userEventLogger(UserEvent.PIPELINE_SUCCESS, {
          value: headers?.["x-oneai-request-id"],
        });

        const { foundSummary } = isSummary(data);
        const { summaryIndex } = isSummary(data);

        if (foundSummary) {
          setSummaryOrigins(true);
          const clonedLabels = cloneDeep(data?.output[summaryIndex]?.labels);
          const highlightedText = highlightRange(
            stripHTML(cubeState === "FINISHED" ? data?.input_text : textArea),
            clonedLabels,
          );
          setTextArea(highlightedText);
        }
        setApiResponse(data);
        setLoader(false);
        setRunningPipeline(false);

        if (isParticipating && variant === variantTwo) {
          increaseExperimentCount();
        }
      })
      .catch(
        (
          error: AxiosError<{
            status_code: string;
            message: string;
            details: string;
          }>,
        ) => {
          setLoader(false);
          setRunningPipeline(false);

          if (summaryOrigins) {
            setTextArea(stripHTML(textArea));
          }

          setApiResponse(null);
          if (searchParams.get("pipeline")) {
            searchParams.delete("pipeline");
            setSearchParams(searchParams);
            navigate("/pipeline-input");
          } else {
            navigate("/pipeline-input" + search);
          }

          if (error.response) {
            setPipelineError({
              name: error?.response?.data?.message
                ? capitalizeFirstCharacter(error?.response?.data?.message)
                : "Error",
              message: error?.response?.data?.details,
              showError: true,
              requestId: error?.response?.headers?.["x-oneai-request-id"],
            });
          } else {
            console.error("[PipelinePage] error", error);
            setPipelineError({
              name: "Error",
              message: error?.message || "Unknown Error",
              showError: true,
              requestId: "",
            });
          }
        },
      );
  };
  return { runPipeline };
}

export function usePipelineErrors() {
  const pipelineSkills = useRecoilValue(pipelineSkillsAtom);
  const textArea = useRecoilValue(textAreaAtom);
  const fileContent = useRecoilValue(fileContentAtom);

  return () => {
    const errors = [];
    if (!textArea?.length && !fileContent) {
      errors.push("Please add input text");
    }
    if (!pipelineSkills?.steps?.length) {
      errors.push("Please add skills to pipeline");
    }
    if (isClusteringEmpty(pipelineSkills)) {
      errors.push("Please choose clustering skill");
    }

    return errors;
  };
}

function isClusteringEmpty(pipelineSkills: any) {
  if (pipelineSkills?.steps?.length) {
    return pipelineSkills?.steps?.some(
      (step: any) => step?.params?.input_skill === "",
    );
  }
  return false;
}
