import { flatMap, groupBy, uniqBy } from "lodash";
import { atom, selector } from "recoil";
import { textAreaAtom } from "../../lib/atoms";
import { PipelineErrorType, SkillsType } from "../../lib/interfaces";
import contentFile from "../../utils/content.json";
import {
  containsALink,
  containsHTML,
  randomString,
} from "../PipelinePage/utils";

export const apiResponseAtom = atom<null | any>({
  key: "apiResponseAtom",
  default: null,
});

export const editorCodeTab = atom<boolean>({
  key: "editorCodeTab",
  default: true,
});

export const sharedPipelineIDAtom = atom<string>({
  key: "sharedPipelineIDAtom",
  default: randomString(6),
});

export const sharedPipelineAtom = atom<boolean>({
  key: "sharedPipelineAtom",
  default: false,
});

export const requestIdHeader = atom<string>({
  key: "requestIdHeader",
  default: "",
});

export const lastSharedPipelineAtom = atom<string>({
  key: "lastSharedPipelineAtom",
  default: "",
});

export const buildSkillsLibraryAtom = (originalLibrary: any) => {
  const grouped = groupBy(originalLibrary, "category");

  for (const oldKey in grouped) {
    let newKey;
    try {
      newKey = contentFile.skill_categories.filter(
        (cat) => cat.id === parseInt(oldKey),
      )[0].category_name;
    } catch (error) {
      newKey = "Other";
    }
    delete Object.assign(grouped, { [newKey]: grouped[oldKey] })[oldKey];
  }
  return grouped;
};

export const skillsLibraryAtom = atom({
  key: "skillsLibraryAtom",
  default: {
    skills: buildSkillsLibraryAtom(contentFile.skills),
  },
});

export const runPipelineAtom = atom<boolean>({
  key: "runPipelineAtom",
  default: false,
});

export const skillsLibraryFilteredBySearchSelector = selector({
  key: "skillsLibraryFilteredBySearchSelector",
  get: ({ get }) => {
    const skillsLibrary: any = get(skillsLibraryAtom);
    const searchTerm = get(pipelineSkillsSearchAtom);
    const newObject: any = [];
    Object.entries(skillsLibrary?.skills)?.forEach(([key, category]: any) => {
      category?.forEach((skill: any) => {
        newObject.push(skill);
      });
    });

    const keywordsArray = newObject?.filter((skillsIncludingKeywords: any) => {
      return skillsIncludingKeywords.keywords?.some((keyword: any) =>
        keyword.startsWith(searchTerm.toLowerCase()),
      );
    });

    const parsedSkillNames = keywordsArray.map((skill: any) => skill.name);
    const filteredColumn = newObject?.filter((libraryItem: any) =>
      parsedSkillNames.includes(libraryItem.name),
    );

    const newAtom = buildSkillsLibraryAtom(filteredColumn);
    return newAtom;
  },
});

const pipelineSkillsEffect = ({ setSelf, onSet }: any) => {
  onSet((newValue: any, oldValue: any) => {
    const pipelineSkills = newValue.steps;

    let splitPoints = [0];
    for (let index = 1; index < pipelineSkills.length; index++) {
      const skill = pipelineSkills[index];
      if (skill?.creates_new_output === true) {
        splitPoints.push(index);
      }
    }
    const chunks = [];
    if (splitPoints.length === 1) {
      chunks.push(pipelineSkills);
    } else {
      for (let index = 1; index < splitPoints.length; index++) {
        const start = splitPoints[index - 1];
        const end = splitPoints[index];
        chunks.push(pipelineSkills.slice(start, end));
      }

      chunks.push(pipelineSkills.slice(splitPoints[splitPoints.length - 1]));
    }
    let resArray: any = [];
    for (let index = 0; index < chunks.length; index++) {
      const chunk = chunks[index];
      const unique = uniqBy(chunk, "name");

      resArray.push(...unique);
    }
    setSelf({ steps: resArray });
  });
};

export const pipelineSkillsAtom = atom<any>({
  key: "pipelineSkillsAtom",
  default: {
    steps: [],
  },
  effects: [pipelineSkillsEffect],
});

export const currentOutputTab = atom<any>({
  key: "currentOutputTab",
  default: 0,
});

export const pipelineFilteredStepsSelector = selector({
  key: "pipelineFilteredStepsSelector",
  get: ({ get }) => {
    const pipelineSkills = get(pipelineSkillsAtom);
    return pipelineSkills?.steps
      ?.filter((pipelineSkill: any) => {
        return pipelineSkill?.fake_skill !== true;
      })
      .map((pipelineSkill: any) => {
        return {
          skill: pipelineSkill.value,
          ...(pipelineSkill.params && { params: pipelineSkill.params }),
        };
      });
  },
});

export const pipelineStepsSelector = selector({
  key: "pipelineStepsSelector",
  get: ({ get }) => {
    const pipelineSkills = get(pipelineSkillsAtom);
    return pipelineSkills.steps.map((pipelineSkill: any) => {
      return {
        skill: pipelineSkill.value,
        ...(pipelineSkill.params && { params: pipelineSkill.params }),
      };
    });
  },
});

export const pipelineSkillsSearchAtom = atom<string>({
  key: "pipelineSkillsSearchAtom",
  default: "",
});

export const pipelineErrorAtom = atom<PipelineErrorType>({
  key: "pipelineErrorAtom",
  default: {
    name: "",
    message: "",
    showError: false,
    requestId: "",
  },
});

export const runPipelineHasErrorAtom = atom<boolean>({
  key: "runPipelineHasErrorAtom",
  default: false,
});

export const reRenderAtom = atom<string>({
  key: "reRenderAtom",
  default: "",
});

export const clusteringStatelessAtom = atom<string>({
  key: "clusteringStatelessAtom",
  default: "",
});

function getSkillData(skill: any, librarySkills: any) {
  const retSkill: SkillsType | undefined = librarySkills?.find(
    (newSkill: SkillsType) => {
      return newSkill?.name === skill?.name;
    },
  );

  if (retSkill) {
    const mutatedSkill: any = {
      id: skill?.id,
      category: Number(retSkill?.category),
      name: retSkill?.name,
      type: retSkill?.type,
      value: retSkill?.value,
      is_labs: retSkill?.is_labs,
      short_name: retSkill?.short_name,
      creates_new_output: retSkill?.creates_new_output,
      disallow_in_document: retSkill?.disallow_in_document,
      disallow_in_dialog: retSkill?.disallow_in_dialog,
      ...(skill?.params && { params: skill.params }),
      ...(retSkill?.defaultParams && {
        defaultParams: retSkill?.defaultParams,
      }),
      // Text Extraction skills
      ...(skill?.text_exctraction_skill && {
        text_exctraction_skill: skill.retSkill,
      }),
      // Pre Processing skills
      ...(skill?.text_exctraction_skill && {
        text_exctraction_skill: skill.retSkill,
      }),
    };
    return mutatedSkill;
  } else {
    if (skill?.name === "Entities") {
      return skill;
    }
    return null;
  }
}

export const partitionedSkillsSelector = selector({
  key: "partitionedSkillsSelector",
  get: ({ get }) => {
    const pipelineSkills = get(pipelineSkillsAtom);
    const librarySkills: any = get(skillsLibraryAtom);
    const allSkills = flatMap(librarySkills?.skills);
    const textArea: any = get(textAreaAtom);
    const isContainsHTML = containsHTML(textArea);
    const isContainsALink = containsALink(textArea);

    let outputIndex = 0;
    const res: Array<Array<any>> = [[]];
    if (pipelineSkills.steps.length > 0) {
      const skillData = getSkillData(pipelineSkills.steps[0], allSkills);
      if (skillData) {
        res[0].push(skillData);
      }
    }

    for (let index = 1; index < pipelineSkills?.steps?.length; index++) {
      const skillData = getSkillData(pipelineSkills.steps[index], allSkills);

      if (skillData?.creates_new_output === true) {
        if (isContainsHTML && skillData?.name === "Html-all-text") {
          // DO NOTHING - WE DON'T WANT TO ADD ANOTHER CATEGORY ARRAY!
        } else if (isContainsALink && skillData?.name === "Html-to-Article") {
          // DO NOTHING - WE DON'T WANT TO ADD ANOTHER CATEGORY ARRAY!
        } else {
          res.push([]);
          outputIndex += 1;
        }
      }
      if (skillData) {
        //@ts-ignore

        if (skillData?.name === "Html-all-text" && isContainsHTML) {
          res[0].unshift(skillData);
        } else if (skillData?.name === "Html-to-Article" && isContainsALink) {
          res[0].unshift(skillData);
        } else {
          res[outputIndex].push(skillData);
        }
      }
    }
    return res;
  },
});
