import React from "react";
import ContentModal from "./content-modal";
import Editor from "@monaco-editor/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { contentFileAtom } from "../lib/atoms";
import { useThemeColor } from "./theme-color";
import { cloneDeep } from "lodash";
import { selectStyles } from "../utils/select-styles";
import { useDraftData } from "../lib/use-studio-data";

export default function AddItemDocumentationModal() {
  const [isOpen, setIsOpen_] = React.useState<boolean>(false);

  function setIsOpen(isOpen: boolean) {
    setError([]);
    setIsOpen_(isOpen);
  }

  const { isDark } = useThemeColor();
  const [error, setError] = React.useState<Array<string>>([]);
  const { t, i18n } = useTranslation("content-management");
  const [typeOptions, setTypeOptions] = React.useState<any[]>([]);
  const [selectedSection, setSelectedSection] = React.useState<any>({});
  const [draft, setDraft] = useRecoilState(contentFileAtom);

  const { data } = useDraftData(draft);
  React.useEffect(() => {
    if (data) {
      // Fetch skill types and categories
      const sectionOptions = data?.documentation
        .filter((section: any) => {
          return section.type !== "skills";
        })
        .map((section: any) => ({
          value: section.name,
          label: section.name,
        }));

      setTypeOptions(sectionOptions);
      setSelectedSection(sectionOptions[0]);
    }

    //eslint-disable-next-line
  }, [draft]);

  const onSubmit = async (values: any) => {
    try {
      const clonedContent = cloneDeep(data);
      const section = clonedContent.documentation.find((section: any) => {
        return section.name === selectedSection.value;
      });

      section?.items.push({
        item_name: values?.name,
        content_html: values?.content_html,
      });

      setDraft(clonedContent);
      setIsOpen(false);
      /*
      const newSkill = {
        name: values?.name,
        value: values?.value,
        type: se?.value,
        id: biggerId.toString(),
        keywords: values?.keywords
          ? values?.keywords.toString().split(",")
          : [],
        tooltip: {
          title: values?.title,
          example: values?.example,
          extras: values?.extras ? JSON.parse(values?.extras) : [],
        },
      };

      const newSkillDocumentation = {
        skill_name: values?.name,
        response: values?.response,
        content_html: values?.content,
        code_snippet: values?.code_snippet
          ? JSON.parse(values?.code_snippet)
          : [],
      };

      const findSkillsIndex = clonedContent?.documentation.findIndex(
        (section: any) => {
          return section.type === "skills";
        },
      );
      clonedContent?.documentation[findSkillsIndex].items.push(
        newSkillDocumentation,
      );

      clonedContent?.skills?.push(newSkill);

      setContent(clonedContent);
      setIsOpen(false);
      */
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (values: any) => {
    setError([]);
    const errors = {} as any;
    if (!values.name) errors.name = t("Required");

    const keys = Object.keys(errors).map((key: any) => {
      switch (key) {
        case "name":
          return "Item name is required";
      }
      return key;
    });

    setError(keys);
    return errors;
  };
  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        aria-controls={isOpen ? "add-skill-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        className="min-w-fit cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl"
      >
        {t("Add new item")}
      </button>

      <ContentModal isOpen={isOpen} setIsOpen={setIsOpen}>
        {error.length > 0 && (
          <div className="fixed left-3 rounded-md max-w-[200px] bg-dark bg-opacity-40 text-white p-2 z-50">
            {error.map((error: any) => (
              <p>{error}</p>
            ))}
          </div>
        )}

        <Formik
          initialValues={{
            name: "",
            content_html: "",
          }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="grid grid-cols-1 gap-y-4 font-poppins pb-4 text-black dark:text-white">
              <p className="text-center text-2xl">{t("Add a new item")}</p>
              <span className="font-normal text-center">
                {t("Make sure sure you know what you're doing!")}
              </span>
              <div className="grid md:grid-cols-1 gap-y-4 gap-x-4 md:h-full ">
                <div className="gap-y-4">
                  <p className="underline text-xl font-normal">
                    {t("Item information")}
                  </p>
                  <div className="grid gap-y-4">
                    <div className="grid gap-y-1">
                      <label
                        htmlFor="name"
                        className="text-sm font-normal leading-6"
                      >
                        {t("Item name:")}
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="bg-transparent border border-borderLight dark:border-darkGrayBorder  rounded p-3 w-full"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red error-message"
                      />
                    </div>
                    <div className="grid  gap-y-1">
                      <label
                        htmlFor="select"
                        className="text-sm font-normal leading-6"
                      >
                        {t("Item section:")}
                      </label>
                      <Select
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        styles={selectStyles(isDark ? true : false)}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        options={typeOptions}
                        onChange={(selectedOption): any => {
                          setSelectedSection(selectedOption);
                        }}
                        value={selectedSection}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-1 gap-y-4 gap-x-4 ">
                    <div className="grid gap-y-4">
                      <div className="grid gap-y-1">
                        <label htmlFor="id" className="text-sm font-normal">
                          {t("Content (HTML):")}
                        </label>
                        <Field name="content_html">
                          {({ field, form }: any) => (
                            <Editor
                              height="500px"
                              className="border border-borderLight dark:border-darkGrayBorder rounded-md p-1"
                              defaultLanguage="html"
                              defaultValue={field.value}
                              theme={isDark ? "vs-dark" : undefined}
                              onChange={(newValue: any) => {
                                form.setFieldValue(field.name, newValue);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                dir={i18n.dir()}
                className={`grid grid-flow-col gap-x-2 justify-end fixed bottom-1 right-3`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={() => setIsOpen(false)}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Add skill")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ContentModal>
    </>
  );
}
