import { annotateOutputText } from "../../../../lib/InputHighlight";

export function SummaryHighlighter({
  text,
  labels,
  input_text,
}: {
  text: string;
  labels: any;
  input_text: string;
}) {
  const section = annotateOutputText(text, labels, input_text);
  return <div dangerouslySetInnerHTML={{ __html: section.text }}></div>;
}
