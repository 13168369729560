import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { forgotPassword } from "./data";
import logo from "../../assets/logo-forms.svg";
import darkModeLogo from "../../assets/logo-gray.svg";
import { useThemeColor } from "../theme-color";
import { Box, CircularProgress } from "@mui/material";
import { FormNameType } from "../../lib/interfaces";
import { validateEmail } from "../../pages/PipelinePage/utils";

type FormType = {
  setForm: Dispatch<FormNameType>;
};

export default function ForgotPasswordModal({ setForm }: FormType) {
  const { t } = useTranslation("index");
  const { isDark } = useThemeColor();
  const [email, setEmail] = React.useState<string>("");
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [nextMessage, setNextMessage] = React.useState<string>("");
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const handleChange = (event: any) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address!");
    } else {
      setErrorMessage("");
      setNextMessage("");
      setButtonLoading(true);
      const result = await forgotPassword(email);
      if (result?.success) {
        setButtonLoading(false);
        setNextMessage("An email has been sent to your email address.");
      } else {
        setNextMessage("");
        setButtonLoading(false);
        setNextMessage("An email has been sent to your email address.");
        console.log("Error forgot-password :: ", result?.message);
        console.log("error: ");
      }
    }
    return true;
  };
  return (
    <div className="h-[618px] grid grid-rows-1fr-auto">
      <div className="space-y-3">
        <div className="py-3 grid justify-center">
          <figure className="image w-[110px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <h1 className="grid justify-center text-2xl dark:text-white text-black font-bold m-0 p-0 py-6">
          {t("Forgot Password?")}
        </h1>
        <p className="grid text-center text-black dark:text-white font-normal font-poppins text-[16px] leading-8 m-0 p-0 ">
          {t(
            "To reset your password, enter your e-mail and we will send you a link to create new password",
          )}
        </p>
        <div className="py-4">
          <input
            disabled={nextMessage ? true : false}
            placeholder={t("Email")}
            onChange={handleChange}
            type="text"
            value={email}
            className="p-4 bg-white dark:bg-inputDarkGray border border-borderLight dark:border-darkGrayBorder rounded-[5px] text-black h-[54px] dark:text-white focus:outline-none w-full placeholder-formsgray text-[14px]"
          />
        </div>
        {nextMessage && (
          <p className="text-green font-normal text-md text-center p-0">
            {nextMessage}
          </p>
        )}
        {errorMessage && (
          <p className="text-red font-normal text-md text-center p-0">
            {errorMessage}
          </p>
        )}
      </div>

      <div className="grid items-end">
        <div className="relative">
          {buttonLoading ? (
            <button
              type="button"
              className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none w-full  p-4 hover:bg-opacity-60  grid grid-flow-col`}
              disabled
            >
              <span>
                <Box>
                  <CircularProgress
                    style={{
                      width: 15,
                      height: 15,
                      color: "white",
                      padding: 0,
                      margin: 0,
                    }}
                  />
                </Box>
              </span>
            </button>
          ) : (
            <button
              type="submit"
              onClick={() => handleSubmit()}
              disabled={nextMessage ? true : false}
              className={`bg-shadeBlue disabled:opacity-70 font-mono rounded-md text-sm text-white outline-none w-full p-4 ${
                !nextMessage ? "hover:bg-opacity-60" : ""
              }`}
            >
              {t("Send")}
            </button>
          )}
        </div>

        <div className="grid justify-center py-3 pt-10">
          <div className="text-black dark:text-white text-sm font-poppins font-normal">
            {t("Have no account?")}
            <button
              className="text-shadeBlue px-2 font-mono font-bold justify-self-center"
              onClick={() => setForm("signup")}
            >
              {t("Sign Up")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
