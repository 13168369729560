import { useMonaco } from "@monaco-editor/react";

export const useMonacoLanguage = () => {
  const monaco = useMonaco();

  function getMonacoLanguage() {
    if (monaco) {
      monaco.languages.register({ id: "curl" });

      monaco.languages.setMonarchTokensProvider("curl", {
        keywords: ["curl"],

        tokenizer: {
          root: [[/[{}]/, "delimiter.bracket"], { include: "common" }],

          common: [
            // identifiers and keywords
            [
              /[a-z_$][\w$]*/,
              {
                cases: {
                  "@keywords": "keyword",
                },
              },
            ],

            // whitespace
            { include: "@whitespace" },

            // regular expression: ensure it is terminated before beginning (otherwise it is an opeator)
            [
              /\/(?=([^\\/]|\\.)+\/([gimsuy]*)(\s*)(\.|;|\/|,|\)|\]|\}|$))/,
              { token: "regexp", bracket: "@open", next: "@regexp" },
            ],

            // delimiters and operators
            [/[()[]]/, "@brackets"],

            // delimiter: after number because of .\d floats
            [/[;,.]/, "delimiter"],

            // strings
            [/"([^"\\]|\\.)*$/, "string.invalid"], // non-teminated string
            [/"/, "string", "@string_double"],
            [/'/, "string", "@string_single"],
          ],

          whitespace: [
            [/[ \t\r\n]+/, ""],
            [/\/\*\*(?!\/)/, "comment.doc", "@jsdoc"],
            [/\/\*/, "comment", "@comment"],
            [/\/\/.*$/, "comment"],
          ],

          comment: [
            [/[^/*]+/, "comment"],
            [/\*\//, "comment", "@pop"],
            [/[/*]/, "comment"],
          ],

          jsdoc: [
            [/[^/*]+/, "comment.doc"],
            [/\*\//, "comment.doc", "@pop"],
            [/[/*]/, "comment.doc"],
          ],

          // We match regular expression quite precisely
          regexp: [
            [
              /(\{)(\d+(?:,\d*)?)(\})/,
              [
                "regexp.escape.control",
                "regexp.escape.control",
                "regexp.escape.control",
              ],
            ],
            [
              /(\()(\?:|\?=|\?!)/,
              ["regexp.escape.control", "regexp.escape.control"],
            ],
            [/[()]/, "regexp.escape.control"],
            [/[^\\/]/, "regexp"],
            [/\\\./, "regexp.invalid"],
          ],

          regexrange: [
            [/-/, "regexp.escape.control"],
            [/\^/, "regexp.invalid"],
            [/[^\]]/, "regexp"],
            [
              /\]/,
              {
                token: "regexp.escape.control",
                next: "@pop",
                bracket: "@close",
              },
            ],
          ],

          string_double: [
            [/[^\\"]+/, "string"],
            [/\\./, "string.escape.invalid"],
            [/"/, "string", "@pop"],
          ],

          string_single: [
            [/[^\\']+/, "string"],
            [/\\./, "string.escape.invalid"],
            [/'/, "string", "@pop"],
          ],

          bracketCounting: [
            [/\{/, "delimiter.bracket", "@bracketCounting"],
            [/\}/, "delimiter.bracket", "@pop"],
            { include: "common" },
          ],
        },
      });
    }
  }

  return { getMonacoLanguage };
};
