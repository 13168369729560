import theme from "../../theme";

function codeTheme(isDark: any, darker?: boolean) {
  return {
    lineNumberColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    lineNumberBgColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    backgroundColor: darker
      ? isDark
        ? "rgba(0,0,0,0.2)"
        : "rgba(0,0,0,0.2)"
      : isDark
      ? "transparent"
      : "transparent",
    textColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    substringColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    keywordColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    attributeColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    selectorAttributeColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    docTagColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    nameColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    builtInColor: isDark ? theme.colors.white : theme.colors.white,
    literalColor: isDark ? theme.colors.white : theme.colors.white,
    bulletColor: isDark ? theme.colors.white : theme.colors.white,
    codeColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    additionColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    regexpColor: isDark ? theme.colors.white : theme.colors.white,
    symbolColor: isDark ? theme.colors.white : theme.colors.white,
    variableColor: isDark ? theme.colors.white : theme.colors.white,
    templateVariableColor: isDark ? theme.colors.white : theme.colors.white,
    linkColor: isDark ? theme.colors.white : theme.colors.white,
    selectorClassColor: isDark ? theme.colors.white : theme.colors.white,
    typeColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    stringColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    selectorIdColor: isDark ? theme.colors.white : theme.colors.white,
    quoteColor: isDark ? theme.colors.white : theme.colors.white,
    templateTagColor: isDark ? theme.colors.white : theme.colors.white,
    deletionColor: isDark ? theme.colors.white : theme.colors.white,
    titleColor: isDark ? theme.colors.white : theme.colors.white,
    sectionColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    commentColor: theme.colors.codeCommentColor,
    metaKeywordColor: isDark ? theme.colors.white : theme.colors.white,
    metaColor: isDark
      ? theme.colors.codeStringDarkColor
      : theme.colors.codeStringLightColor,
    functionColor: isDark ? theme.colors.white : theme.colors.white,
    numberColor: theme.colors.codeNumberColor,
  };
}

export default codeTheme;
