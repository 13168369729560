import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { IconTooltip } from "../../../assets/SvgIcons";
import AppModal from "../../../components/app-modal";
import {
  getUserOrganizationData,
  useUserData,
} from "../../../components/auth/data";
import { LottieLoader } from "../../../components/Loader";
import QuotaRequest from "../../../components/quota-request-form";
import { useEventLogger } from "../../../lib/event_logger";
import {
  emptyOrganizationData,
  UserOrganizationDataType,
} from "../../../lib/interfaces";
import { Chart } from "./chart";
import { StatisticBlock } from "./statistic-block";

const MONTHS_NUMBER = 12;

const getLastThirtyDaysData = (data: any) => {
  if (!data || data.length === 0) {
    return 0;
  }

  return (
    // @ts-ignore
    data.reduce((acc, cur: any) => {
      acc += cur[1];
      return acc;
    }, 0) / data.length
  );
};

const getMonthData = (months: any) => {
  if (!months) {
    return [];
  }
  return Object.entries(months).slice(-MONTHS_NUMBER);
};

const getDayData = (days: any) => {
  if (!days) {
    return [];
  }
  const currentTime = moment().format("YYYY-MM-DD");
  const yearAndMonth = currentTime.slice(0, -3);
  const monthNumber = yearAndMonth.slice(-2);
  const dayNumber = currentTime.slice(-2);
  let currentDayIteration = dayNumber;
  const result = [];
  for (let i = +dayNumber; i > 0; i--) {
    if (days[i]?.date === `${yearAndMonth}-${currentDayIteration}`) {
      result.push([days[i].date.slice(-5), days[i].count]);
    } else {
      result.push([`${monthNumber}-${currentDayIteration}`, 0]);
    }
    currentDayIteration =
      `${+currentDayIteration - 1}`.length > 1
        ? `${+currentDayIteration - 1}`
        : `0${+currentDayIteration - 1}`;
  }
  return result.reverse();
};

export default function UsageChart() {
  const empty = emptyOrganizationData();

  const { loading } = useUserData();
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);

  const [orgData, setOrgData] = React.useState<UserOrganizationDataType>(empty);
  const [orgDataLoading, setOrgDataLoading] = React.useState<boolean>(true);

  const { t } = useTranslation("usage-chart");
  const { userUIEventLogger } = useEventLogger();

  console.log("Loading is :: ", loading);
  React.useEffect(() => {
    if (!loading) {
      const getOrgData = async () => {
        const data = await getUserOrganizationData();
        console.log("Data is :: ", data);
        setOrgData(data);
        setOrgDataLoading(false);
      };

      getOrgData();
    }
  }, [loading]);

  const days = orgData?.usage?.daily_usage;
  const months = orgData?.usage?.monthly_usage;

  const isDaily = days ? !!Object.keys(days).length : false;
  const isMonthly = months ? !!Object.keys(months).length : false;
  const currentDate = new Date();
  const monthlyUsageKey =
    String(currentDate.getFullYear()) +
    "-" +
    String(currentDate.getMonth() + 1).padStart(2, "0");

  return (
    <>
      <div className="px-3 dark:text-white text-black overflow-auto md:h-[calc(100vh-175px)]">
        <div className="container items-start dark:bg-darkGrayBackground bg-white">
          {orgDataLoading ? (
            <div className="grid items-center justify-center h-full w-full">
              <LottieLoader />
            </div>
          ) : (
            <>
              <div className="grid grid-rows-auto-1fr ">
                <div className="flex items-center justify-between">
                  <h1
                    className={`items md:visible font-poppins font-bold dark:text-white text-black`}
                  >
                    {t("Usage & Quota")}
                  </h1>
                  <div className="flex items-center justify-between">
                    <Link
                      onClick={() => {
                        userUIEventLogger(
                          "settings__usage_chart__need_more_quota",
                        );
                      }}
                      to={"/settings/billing"}
                      className="font-mono text-sm font-medium bg-borderBlue text-white rounded-md cursor-pointer px-4 py-3"
                    >
                      {t("Need more quota?")}
                    </Link>
                    {/* <button
                      className="font-mono text-sm font-medium bg-shadeBlue rounded-md cursor-pointer px-4 py-3 hover:bg-opacity-90"
                      onClick={handleQuotaRequest}
                    >
                      {t("Need more quota?")}
                    </button> */}
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-auto-1fr items-center mt-5 p-4 bg-catskill dark:bg-usageBackground rounded-md w-fit-content">
                    <figure className="cursor-pointer text-blueOpacity mr-[10px]">
                      <IconTooltip width="18" height="18" />
                    </figure>
                    <div className="break-all md:whitespace-nowrap">
                      {t(
                        "We calculate usage based on words in input text, per skill.",
                      )}
                    </div>
                  </div>
                  <div className="grid md:grid-flow-col justify-between pt-10 overflow-x-auto overflow-y-hidden">
                    {/* <StatisticBlock
                      title="Daily usage today"
                      data={{
                        //@ts-ignore
                        usage: days ? days[dailyUsageKey]?.count : 0,
                        max: orgData?.usage?.daily_quota,
                      }}
                    /> */}
                    <StatisticBlock
                      title="Daily average last 30 days"
                      data={{
                        usage: +getLastThirtyDaysData(getDayData(days)).toFixed(
                          1,
                        ),
                        max: orgData?.usage?.daily_quota,
                      }}
                      extraTitle="day"
                    />
                    <StatisticBlock
                      title="Your account's total usage this month"
                      data={{
                        usage: months[monthlyUsageKey] || 0,
                        max: orgData?.usage?.monthly_quota,
                      }}
                    />
                  </div>
                  {(isDaily || isMonthly) && (
                    <Chart
                      data={{
                        days: getDayData(days),
                        months: getMonthData(months),
                      }}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <AppModal isOpen={isModalOpen} setIsOpen={setModalOpen}>
        <QuotaRequest setIsOpen={setModalOpen} />
      </AppModal>
    </>
  );
}
