import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useRecoilState, useSetRecoilState } from "recoil";
import { queryClient } from "../../App";
import {
  BillingIcon,
  GenericCardIcon,
  IconCheckCircle,
  IconTooltip,
  MasterCardIcon,
  TrashIcon,
  VisaIcon,
} from "../../assets/SvgIcons";
import { useUser } from "../../components/auth/auth";
import {
  billingHandler,
  emailEvent,
  getUserOrganizationData,
  useUserData,
} from "../../components/auth/data";
import Button from "../../components/button";
import GlobalModal from "../../components/global-modal";
import { LottieLoader } from "../../components/Loader";
import { useThemeColor } from "../../components/theme-color";
import AppTooltip from "../../components/tooltip";
import {
  addedPaymentMethodAtom,
  addMethodModalOpenAtom,
  cancelPaidAccountModalOpenAtom,
  clientSecretAtom,
  preferencesModalOpenAtom,
} from "../../lib/billing-atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { UserFirebase, UserOrganizationDataType } from "../../lib/interfaces";
import { theme } from "../../tailwind.config";
import { getUserCountry } from "../billing/countries-provider";
import PrefrencesModal from "../billing/preferences-modal";
import { bignumbers } from "../PipelinePage/utils";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";
const stripePromise = loadStripe(publishableKey);
export const quotaPerOneDollar = 100_000;

const getOrganizationWrapper = async ({ queryKey }: any) => {
  const res = await billingHandler({
    action: "GET_PAYER_ORGANIZATION",
    payload: { organizationID: queryKey[1] },
  });
  return res;
};

const useMongoBilling = () => {
  const { value: firebaseUser, loading: firebaseUserLoading } = useUserData();

  const reload = () => {
    queryClient.invalidateQueries(getPayerOrganizationQueryKey);
  };

  const { data: organization, isLoading: loading } = useQuery<any>(
    [getPayerOrganizationQueryKey, firebaseUser?.org_ref],
    getOrganizationWrapper,
    {
      enabled: firebaseUser?.org_ref !== "",
    },
  );

  const extendedLoading = firebaseUserLoading || loading;

  return {
    organizationBilling: organization?.billing,
    organizationMonthlyQuota: organization?.monthly_quota,
    firebaseUser,
    firebaseUserLoading,
    loading: extendedLoading,
    monthlyUsage: organization?.monthlyUsage,
    dailyUsage: organization?.dailyUsage,
    transcribeMonthlyQuota: organization?.monthly_transcribe_quota,
    monthlyTranscribeUsage: organization?.monthly_transcribe_usage_counters,
    reload,
  };
};

export const getPayerCustomerQueryKey = "getPayerCustomerQueryKey";
export const getPayerOrganizationQueryKey = "getPayerOrganizationQueryKey";

const useIsPaidUser = () => {
  const { organizationBilling, loading } = useMongoBilling();

  const isPaidUser = organizationBilling?.account_type === "PAID";
  return { isPaidUser, loading };
};

const getPayerCustomerQuery = async ({ queryKey }: any) => {
  const res = await billingHandler({
    action: "GET_PAYER_CUSTOMER",
    payload: {
      customer_id: queryKey[1],
    },
  });

  console.log("[Billing] getPayerCustomerQuery res", res);
  return res;
};

export default function Billing() {
  const { organizationBilling, loading: organizationLoading } =
    useMongoBilling();
  const [addMethodModalOpen, setAddMethodModalOpen] = useRecoilState(
    addMethodModalOpenAtom,
  );
  const [preferencesModalOpen, setPreferencesModalOpen] = useRecoilState(
    preferencesModalOpenAtom,
  );
  const [addedPaymentMethod, setAddedPaymentMethod] = useRecoilState(
    addedPaymentMethodAtom,
  );
  const { isPaidUser, loading } = useIsPaidUser();

  if (loading || organizationLoading) {
    return (
      <div className="grid gap-y-6 px-3  dark:text-white text-black overflow-y-hidden">
        <div className="grid">
          <h1 className="font-medium">Billing</h1>
          <div className="grid justify-center items-center">
            <LottieLoader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid gap-y-6 px-3  dark:text-white text-black overflow-y-hidden">
      <div className="grid grid-cols-1fr-auto">
        <h1 className="font-medium">Billing</h1>
        {isPaidUser ? (
          <>
            <Button
              ariaLabel="Preferences"
              className="!px-4 !min-w-[120px] !h-fit"
              onClick={() => {
                setPreferencesModalOpen(true);
              }}
            >
              Preferences
            </Button>
          </>
        ) : null}
      </div>

      <BillingContent />

      <GlobalModal
        onClose={() => {
          if (addedPaymentMethod) {
            setPreferencesModalOpen(true);
            setAddedPaymentMethod(false);
          }
        }}
        isOpen={addMethodModalOpen}
        setIsOpen={setAddMethodModalOpen}
      >
        <BillingForm />
      </GlobalModal>
      <GlobalModal
        isOpen={preferencesModalOpen}
        setIsOpen={setPreferencesModalOpen}
      >
        <PrefrencesModal userBilling={organizationBilling} />
      </GlobalModal>
    </div>
  );
}

function BillingContent() {
  return (
    <div>
      <div className="grid pt-8 lg:grid-cols-1fr-1fr">
        <AccountInfo />
        <PaymentsMethodsInfo />
      </div>
      <PaymentsHistory />
    </div>
  );
}

function SinglePaymentMethodInfo({ payment_method }: any) {
  return (
    <>
      <div className="grid grid-cols-auto-auto-1fr gap-x-8 items-center">
        <div className="grid grid-cols-auto-1fr items-center gap-x-3">
          <CardIcon payment_method={payment_method} />
          <span className="">Credit card</span>
        </div>
        <span>*** {payment_method?.card?.last4}</span>
        <ul className="m-0">
          <PaymentMethodButtons payment_method={payment_method} />
        </ul>
      </div>
      <span className="grid ml-[52px] text-transparentGray">
        Expires on {payment_method?.card?.exp_month}/
        {payment_method?.card?.exp_year}
      </span>
    </>
  );
}

function CardIcon({ payment_method }: any) {
  return (
    <>
      {payment_method.card.brand === "mastercard" ? (
        <MasterCardIcon />
      ) : payment_method.card.brand === "visa" ? (
        <VisaIcon />
      ) : (
        <GenericCardIcon />
      )}
    </>
  );
}

function PaymentsMethodsInfo() {
  const { isPaidUser } = useIsPaidUser();
  const setAddMethodModalOpen = useSetRecoilState(addMethodModalOpenAtom);
  const { organizationBilling } = useMongoBilling();
  const customer_id = organizationBilling?.customer_id;

  const { data: payerCustomer } = useQuery<any>(
    ["getPayerCustomerQueryKey", customer_id],
    getPayerCustomerQuery,
    {
      // The query will not execute until the customer_id exists
      enabled: !!customer_id,
    },
  );
  if (!isPaidUser) {
    return null;
  }
  if (payerCustomer?.sources === undefined) {
    return null;
  }
  const onClick = () => {
    setAddMethodModalOpen(true);
  };
  return (
    <div className="grid pt-10 lg:pt-0 lg:justify-items-center">
      {payerCustomer !== null ? (
        <div className="grid gap-y-3">
          <h2 className="font-medium m-0">Payment methods</h2>
          <div className="grid gap-y-3 mb-4">
            {payerCustomer?.sources?.data?.map(
              (payment_method: any, index: number) => {
                // console.log("Payment method :: ", payment_method);
                return (
                  <SinglePaymentMethodInfo
                    payment_method={payment_method}
                    key={index}
                  />
                );
              },
            )}
          </div>
          <Button
            ariaLabel="Add payment method"
            className="!h-fit-content !w-fit-content !px-12"
            onClick={onClick}
          >
            Add method
          </Button>
        </div>
      ) : (
        <>Customer not found</>
      )}
    </div>
  );
}

function PaymentMethodButtons({ payment_method }: any) {
  const [deletePaymentMethodModalOpen, setDeletePaymentMethodModalOpen] =
    useState(false);

  const onClickDelete = () => {
    setDeletePaymentMethodModalOpen(true);
  };

  return (
    <>
      <li key={payment_method.id} className="grid grid-cols-1fr-auto gap-x-6">
        <button className="w-fit">
          <TrashIcon className="text-shadeBlue" onClick={onClickDelete} />
        </button>
      </li>
      <GlobalModal
        isOpen={deletePaymentMethodModalOpen}
        setIsOpen={setDeletePaymentMethodModalOpen}
        children={
          <DeleteCreditCardModal
            payment_method={payment_method}
            setDeletePaymentMethodModalOpen={setDeletePaymentMethodModalOpen}
          />
        }
      />
    </>
  );
}

function AccountInfo() {
  const { value: user } = useUserData();
  const { user: firebaseUser } = useUser();
  const { isPaidUser } = useIsPaidUser();
  const setAddMethodModalOpen = useSetRecoilState(addMethodModalOpenAtom);
  const { userEventLogger } = useEventLogger();
  const { organizationBilling, reload } = useMongoBilling();
  if (!user) {
    return null;
  }
  const onClick = async () => {
    userEventLogger(UserEvent.CLICKED_UPGRADE, {
      value: user,
    });

    const hasPaymentsMethods = organizationBilling?.payment_methods.length > 0;
    if (hasPaymentsMethods) {
      const res = await billingHandler({
        action: "MUTATE_PAYER_ORGANIZATION",
        payload: {
          organizationID: user?.org_ref,
          updatedObject: {
            "billing.account_type": "PAID",
            daily_quota: null,
          },
        },
      });
      console.log("[AccountInfo]: billingHandler", res);
      reload();

      await sendEmailEvent(firebaseUser, "PAID_ACCOUNT");
    } else {
      setAddMethodModalOpen(true);
    }
  };
  return (
    <div className="grid gap-y-6 w-fit-content">
      <div className="grid grid-cols-auto-1fr gap-x-4 items-center">
        <BillingIcon
          className="text-borderBlue"
          width="31px"
          height="35.25px"
        />
        <div className="grid grid-cols-auto-1fr items-end">
          <h2>{isPaidUser ? "Paid" : "Free"} account</h2>
        </div>
      </div>
      <div className="grid gap-y-1">
        {isPaidUser ? (
          <>
            <div className="grid sm:grid-flow-col grid-flow-row md:gap-24 gap-10">
              <div>
                Current month balance
                <p className="text-brightCyan text-2xl font-medium mb-0 mt-4">
                  <MonthlyBalance />
                </p>
              </div>
              <div>
                Projected
                <p className="text-brightCyan text-2xl font-medium mb-0 mt-4">
                  <ProjectedBalance />
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="grid grid-rows-2 gap-4">
            <MonthlyFreeUsage />
            <MonthlyUsage />
          </div>
        )}
      </div>

      {isPaidUser ? (
        <CancelPaidAccount />
      ) : organizationBilling ? (
        <Button
          ariaLabel="Upgrade my account"
          onClick={onClick}
          className="!max-w-[160px]"
        >
          Upgrade my account
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}

const MonthlyBalance = () => {
  const { organizationMonthlyQuota, monthlyUsage } = useMongoBilling();

  const usage = monthlyUsage;
  return (
    <span>
      $
      {usage < organizationMonthlyQuota
        ? 0
        : Math.floor(usage / quotaPerOneDollar) + 1}
      <span className="pl-2.5 text-transparentGray text-sm">
        *qualifies for{" "}
      </span>
      <AppTooltip
        title={
          "Users (paying users included) who do not surpass the 200K free words quota in a given month will not be charged for that month"
        }
        children={
          <span className="text-borderBlue text-sm cursor-pointer">
            free tier
          </span>
        }
      />
      <br />
      <small> Used: {bignumbers(usage)} words</small>
    </span>
  );
};

const ProjectedBalance = () => {
  const { organizationMonthlyQuota, monthlyUsage } = useMongoBilling();
  const [orgData, setOrgData] = React.useState<UserOrganizationDataType>();
  const [average, setAverage] = React.useState<number>(0);
  const [restOfTheMonth, setRestOfTheMonth] = React.useState<number>(0);

  React.useEffect(() => {
    const getOrgData = async () => {
      const data = await getUserOrganizationData();
      setOrgData(data);
    };
    getOrgData();
  }, []);

  React.useEffect(() => {
    const counter = getAverage();
    monthEnd(counter);

    //eslint-disable-next-line
  }, [orgData]);

  const getAverage = () => {
    let counter = 0;

    if (orgData?.usage?.daily_usage) {
      let usage = Object.entries(orgData?.usage?.daily_usage).slice(-5);

      if (usage.length > 5) {
        counter = calcUsage(usage.slice(0, usage.length - 5));
        setRestOfTheMonth(counter);
        counter = 0;
      }

      usage = usage.slice(-5);

      if (usage.length > 0) {
        counter = calcUsage(usage);
        counter = counter / usage.length;
      }
    }
    return counter;
  };

  const calcUsage = (usage: any) => {
    let counter = 0;

    for (var i = 0; i < usage.length; i++) {
      counter = counter + usage[i][1].count;
    }
    return counter;
  };

  const monthEnd = (counter: number) => {
    let dateToday = new Date();
    let lastDayOfMonth = new Date(
      dateToday.getFullYear(),
      dateToday.getMonth() + 1,
      0,
    );
    let daysUntilEndOfMonth = lastDayOfMonth.getDate() - dateToday.getDate();

    setAverage(restOfTheMonth + daysUntilEndOfMonth * counter);
  };

  return (
    <span>
      $
      {monthlyUsage + average < organizationMonthlyQuota
        ? 0
        : Math.floor((monthlyUsage + average) / 100000) + 1}
      <br />
      <small> {bignumbers(monthlyUsage + average)} words</small>
    </span>
  );
};

const MonthlyFreeUsage = () => {
  const { organizationMonthlyQuota, transcribeMonthlyQuota } =
    useMongoBilling();

  return (
    <div className="grid grid-rows-2 gap-1">
      <div>
        Your account is limited to{" "}
        <span className="text-categoryCyan">
          {bignumbers(organizationMonthlyQuota) || "0"}
        </span>{" "}
        words / month
      </div>
      <div>
        Transcription hours:{" "}
        <span className="text-categoryCyan">{transcribeMonthlyQuota}h</span>
      </div>
    </div>
  );
};

const MonthlyUsage = () => {
  const {
    organizationMonthlyQuota,
    monthlyUsage,
    transcribeMonthlyQuota,
    monthlyTranscribeUsage,
  } = useMongoBilling();
  console.debug("[MonthlyUsage]", monthlyUsage);

  const currentDate = new Date();
  const monthlyUsageKey =
    String(currentDate.getFullYear()) +
    "-" +
    String(currentDate.getMonth() + 1).padStart(2, "0");

  const getTime = () => {
    if (!monthlyTranscribeUsage) {
      return transcribeMonthlyQuota + "h";
    }

    let duration = Math.max(
      0,
      transcribeMonthlyQuota * 3600 - monthlyTranscribeUsage?.[monthlyUsageKey],
    ); // time left in seconds
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration % 3600) / 60);

    if (minutes === 0 && hours > 0) {
      return hours + "h";
    }
    if (hours === 0 && minutes > 0) {
      return minutes + "m";
    }

    return hours + "h " + minutes + "m";
  };

  const wordsLeft = Math.max(0, organizationMonthlyQuota - monthlyUsage);
  const timeLeft = getTime();

  return (
    <div className="grid grid-rows-2 gap-1">
      <div>
        Words left this month:{" "}
        <span className="text-categoryCyan">{bignumbers(wordsLeft)}</span>
      </div>
      <div>
        Hours left: <span className="text-categoryCyan">{timeLeft}</span>
      </div>
    </div>
  );
};

function CancelPaidAccount() {
  const [cancelPaidAccountModalOpen, setCancelPaidAccountModalOpenAtom] =
    useRecoilState(cancelPaidAccountModalOpenAtom);
  return (
    <div>
      <p>
        You will be billed for actual usage at the end of each calendar month
      </p>
      <button
        className="text-sm text-borderBlue"
        onClick={() => {
          setCancelPaidAccountModalOpenAtom(true);
        }}
      >
        Cancel paid account
      </button>
      <GlobalModal
        isOpen={cancelPaidAccountModalOpen}
        setIsOpen={setCancelPaidAccountModalOpenAtom}
      >
        <CancelPaidAccountModal />
      </GlobalModal>
    </div>
  );
}

function CancelPaidAccountModal() {
  const { value: user } = useUserData();
  const { user: firebaseUser } = useUser();

  const setCancelPaidAccountModalOpen = useSetRecoilState(
    cancelPaidAccountModalOpenAtom,
  );
  const { reload } = useMongoBilling();
  const onClickConfirm = async () => {
    console.debug("[Billing] CancelPaidAccountModal confirm");
    const res = await billingHandler({
      action: "MUTATE_PAYER_ORGANIZATION",
      payload: {
        organizationID: user?.org_ref,
        updatedObject: {
          "billing.account_type": "FREE",
          daily_quota: 200_000,
          monthly_quota: 1_000_000,
          status: "Active",
        },
      },
    });

    setCancelPaidAccountModalOpen(false);

    if (res) {
      reload();
      await sendEmailEvent(firebaseUser, "FREE_ACCOUNT");
    }
  };
  const onClickCancel = () => {
    setCancelPaidAccountModalOpen(false);
  };
  return (
    <div className="text-white p-8 md:p-12 grid align-content-center min-w-[300px] md:max-w-[600px]">
      <ModalInfo />
      <h2 className="text-center py-4">
        Are you sure you want to cancel your paid account?
      </h2>
      <p className="text-center pb-4">
        By cancelling the paid account your subscription will go back to the
        free option. You will still be able to process text, but only up to
        1,000,000 words per month. Any cancelled subscription will be charged
        for the month of cancellation.
      </p>
      <CancelOrConfirmButtons
        onClickConfirm={onClickConfirm}
        onClickCancel={onClickCancel}
      />
    </div>
  );
}

async function sendEmailEvent(
  firebaseUser: UserFirebase,
  action: "PAID_ACCOUNT" | "FREE_ACCOUNT",
) {
  let title = "";
  let body = "";

  switch (action) {
    case "PAID_ACCOUNT":
      title = `User ${firebaseUser?.email} switched to a paying account`;
      body = `User ${firebaseUser?.email} (uid: ${firebaseUser?.uid}) switched to a paying account`;
      break;

    case "FREE_ACCOUNT":
      title = `User ${firebaseUser?.email} switched back to free account`;
      body = `User ${firebaseUser?.email} (uid: ${firebaseUser?.uid}) switched back to free account`;
      break;
  }

  return await emailEvent({
    title,
    body,
  });
}

const ModalInfo = () => {
  return (
    <div className="grid justify-items-center">
      <IconTooltip width="30" height="30" className={"text-shadeBlue"} />
    </div>
  );
};
type ModalStep = "FREE_ACCOUNT_MESSAGE" | "REMAINING_BALANCE_MESSAGE";
function DeleteCreditCardModal({
  payment_method,
  setDeletePaymentMethodModalOpen,
}: any) {
  const { organizationBilling } = useMongoBilling();
  const { user: firebaseUser } = useUser();
  const isLastCreditCard = organizationBilling?.payment_methods.length === 1;
  const [step, setStep] = React.useState<ModalStep>("FREE_ACCOUNT_MESSAGE");
  const { value: user } = useUserData();
  const onClickConfirm = async () => {
    await billingHandler({
      action: "DELETE_PAYER_CARD",
      payload: {
        payment_method_id: payment_method.id,
        customer_id: organizationBilling.customer_id,
      },
    });
    if (isLastCreditCard) {
      await billingHandler({
        action: "MUTATE_PAYER_ORGANIZATION",
        payload: {
          organizationID: user?.org_ref,
          updatedObject: {
            "billing.account_type": "FREE",
            daily_quota: 200_000,
            monthly_quota: 1_000_000,
          },
        },
      });
      await sendEmailEvent(firebaseUser, "FREE_ACCOUNT");
    }
    queryClient.invalidateQueries(getPayerCustomerQueryKey);
    queryClient.invalidateQueries(getPayerOrganizationQueryKey);
    setDeletePaymentMethodModalOpen(false);
  };
  const onClickCancel = () => {
    setDeletePaymentMethodModalOpen(false);
  };

  if (isLastCreditCard) {
    if (step === "FREE_ACCOUNT_MESSAGE") {
      return (
        <DeleteLastCardModal
          setStep={setStep}
          setDeletePaymentMethodModalOpen={setDeletePaymentMethodModalOpen}
          onClickConfirm={onClickConfirm}
        />
      );
    } else if (step === "REMAINING_BALANCE_MESSAGE") {
      return <DeleteLastCardModalFinalStep onClickConfirm={onClickConfirm} />;
    }
  }
  return (
    <div className="text-white py-8 grid align-content-center">
      <ModalInfo />
      <h2 className="text-center py-4">
        Are you sure you want to delete credit card?
      </h2>
      <p className="text-center pb-4">*** {payment_method?.card?.last4} </p>
      <CancelOrConfirmButtons
        onClickConfirm={onClickConfirm}
        onClickCancel={onClickCancel}
      />
    </div>
  );
}

function DeleteLastCardModalFinalStep({ onClickConfirm }: any) {
  return (
    <>
      <div className="text-white py-8 grid align-content-center max-w-[350px]">
        <ModalInfo />
        <h2 className="text-center py-4">
          If there is a remaining balance - your card will be charged for the
          current month's usage.
        </h2>
        <Button
          ariaLabel="Ok"
          className="bg-shadeBlue"
          onClick={onClickConfirm}
        >
          Ok
        </Button>
      </div>
    </>
  );
}

function DeleteLastCardModal({
  setDeletePaymentMethodModalOpen,
  setStep,
}: any) {
  function onClickProcceed() {
    setStep("REMAINING_BALANCE_MESSAGE");
    console.log("Clicked procceed");
  }

  function onClickCancel() {
    setDeletePaymentMethodModalOpen(false);
  }
  return (
    <>
      <div className="text-white py-8 grid align-content-center max-w-[350px]">
        <ModalInfo />
        <h2 className="text-center py-4">
          Deleting your payment method will set the account back to a free
          account. To keep your paying account, please add a new payment method
          before deleting the current one
        </h2>
        <CancelOrProceedButtons
          onClickProcceed={onClickProcceed}
          onClickCancel={onClickCancel}
        />
      </div>
    </>
  );
}

function CancelOrProceedButtons({ onClickCancel, onClickProcceed }: any) {
  return (
    <>
      <div className="grid grid-cols-1fr-1fr gap-x-4">
        <Button
          ariaLabel="Cancel"
          className="!bg-inputDarkGray"
          onClick={onClickCancel}
        >
          Cancel
        </Button>
        <Button ariaLabel="Procceed" className="" onClick={onClickProcceed}>
          Procceed
        </Button>
      </div>
    </>
  );
}

function CancelOrConfirmButtons({ onClickCancel, onClickConfirm }: any) {
  return (
    <div className="grid grid-cols-1fr-1fr gap-x-4">
      <Button
        ariaLabel="Cancel"
        className="!bg-inputDarkGray"
        onClick={onClickCancel}
      >
        Cancel
      </Button>
      <Button ariaLabel="Confirm" onClick={onClickConfirm}>
        Confirm
      </Button>
    </div>
  );
}

function PaymentsHistory() {
  // const { value: user } = useUserData();
  // const hasPaymentsHistory = user?.billing?.payment_history.length > 0;
  // if (!hasPaymentsHistory) {
  //   return null;
  // }
  return null;
  // return (
  //   <div>
  //     <h2>Payments History</h2>
  //   </div>
  // );
}

function BillingForm() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [needFetch, setNeedFetch] = useState(true);
  const { loading } = useUserData();
  const { isDark } = useThemeColor();
  const [clientSecret, setClientSecret] = useRecoilState(clientSecretAtom);
  const { organizationBilling, firebaseUser, firebaseUserLoading } =
    useMongoBilling();
  // Get  geolocation by an ip address.

  useEffect(() => {
    console.debug("[Billing] useEffect");
    async function fetchPayerData() {
      if (loading || firebaseUserLoading) {
        return;
      }
      const countryCode = await getUserCountry();
      if (needFetch) {
        try {
          const userHasCustomer = organizationBilling.customer_id !== "";
          let customer;
          console.debug(
            "[Billing] user.billing.customer_id",
            organizationBilling.customer_id,
          );
          if (userHasCustomer) {
            customer = await billingHandler({
              action: "GET_PAYER_CUSTOMER",
              payload: { customer_id: organizationBilling.customer_id },
            });
          } else {
            customer = await billingHandler({
              action: "CREATE_PAYER_CUSTOMER",
              payload: {
                uid: firebaseUser?.uid,
                countryCode,
              },
            });
            await billingHandler({
              action: "MUTATE_PAYER_ORGANIZATION",
              payload: {
                organizationID: firebaseUser?.org_ref,
                updatedObject: {
                  "billing.customer_id": customer.id,
                },
              },
            });
          }
          const setupIntent = await billingHandler({
            action: "CREATE_PAYER_SETUP_INTENTS",
            payload: {
              customerID: customer.id,
            },
          });

          console.log("[Billing] setupIntent", setupIntent);
          setClientSecret(setupIntent.client_secret);
        } catch (error) {
          setErrorMessage(
            "We ran into an issue, Sorry for that. please try later.",
          );
        }
      }
      setNeedFetch(false);
    }

    fetchPayerData();
    //eslint-disable-next-line
  }, [loading, firebaseUserLoading]);

  if (errorMessage !== null) {
    return (
      <div className="dark:text-white grid gap-y-4 justify-items-center">
        <IconTooltip width="30" height="30" className={"text-shadeBlue"} />
        <div role={"alert"}>{errorMessage}</div>
      </div>
    );
  }
  if (clientSecret === null) {
    return <LottieLoader />;
  }
  const options = {
    // passing the client secret obtained in step 2
    clientSecret: `${clientSecret}`,
    // Fully customizable with appearance API.
    appearance: {
      theme: "flat",
      labels: "floating",
      variables: {
        borderRadius: "5px",
        borderWidth: "40px",
        borderColor: isDark ? "#3D3A50" : "#8383",
        colorPrimary: theme.colors.shadeBlue,
        colorBackground: isDark
          ? theme.colors.inputDarkGray
          : theme.colors.neatWhite,
        colorBackgroundText: "#878787",
        colorText: isDark ? theme.colors.white : theme.colors.black,
      },
    },
  };

  return (
    <div className="bg-white dark:bg-categoryItemBackgroundDark p-4 md:p-12 max-h-[80vh]">
      {/* 
      //@ts-ignore */}
      <Elements stripe={stripePromise} options={options}>
        <SetupForm />
      </Elements>
    </div>
  );
}

export async function mutateBillingOrganization({
  organizationID,
  firstPaymentMethod,
  updatedObject,
}: {
  organizationID: string;
  firstPaymentMethod?: string | null;
  updatedObject: any;
}) {
  return await billingHandler({
    action: "MUTATE_PAYER_ORGANIZATION",
    payload: {
      organizationID,
      firstPaymentMethod,
      updatedObject,
    },
  });
}
const SetupForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { value: user } = useUserData();
  const { user: firebaseUser } = useUser();
  const setAddedPaymentMethod = useSetRecoilState<boolean>(
    addedPaymentMethodAtom,
  );
  const { userEventLogger } = useEventLogger();
  const { organizationBilling } = useMongoBilling();
  const mutation = useMutation(mutateBillingOrganization, {
    onSuccess: async () => {
      queryClient.invalidateQueries(getPayerCustomerQueryKey);
      queryClient.invalidateQueries(getPayerOrganizationQueryKey);
    },
  });

  const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
  const [stripeReady, setStripeReady] = React.useState<boolean>(false);

  const onReady = (e: any) => {
    setStripeReady(true);
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        // return_url,
      },
      redirect: "if_required",
    });

    if (error) {
      console.log("⭐ ~ handleSubmit ~ error", error);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message || "");
    } else {
      console.debug("[Billing] setupIntent:", setupIntent);
      const payment_methods_ids = [...organizationBilling?.payment_methods];
      if (setupIntent?.payment_method) {
        payment_methods_ids.push(setupIntent.payment_method);
      }

      const firstPaymentMethod =
        payment_methods_ids.length === 1 ? payment_methods_ids[0] : null;

      mutation.mutate({
        organizationID: user?.org_ref,
        firstPaymentMethod,
        updatedObject: {
          "billing.account_type": "PAID",
          "billing.payment_methods": payment_methods_ids,
          daily_quota: null,
          ...(firstPaymentMethod && {
            monthly_quota: 1000 * quotaPerOneDollar, // This is the default of 1000$
            "billing.limit_budget": 1000,
            "billing.country_code": await getUserCountry(),
          }),
        },
      });

      userEventLogger(UserEvent.UPGRADED, {
        value: user,
      });

      setShowSuccess(true);
      setAddedPaymentMethod(true);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.

      // NOTE: I'm placing this at the end of the function so it won't postpone everything else.
      await sendEmailEvent(firebaseUser, "PAID_ACCOUNT");
    }
  };

  return (
    <>
      {showSuccess ? (
        <AddPaymentMethodSuccess />
      ) : (
        <form
          onSubmit={handleSubmit}
          className="grid justify-items-center text-black dark:text-white md:min-w-[400px] md:min-h-[200px] p-4 md:p-12 max-h-[80vh]"
        >
          <h2 className="text-center py-4 font-medium">Add payment method</h2>
          <p className="text-center mb-4">
            Your card will be charged for actual usage at the end of each
            calendar month
          </p>
          {!stripeReady && <LottieLoader />}
          <PaymentElement onReady={onReady} />

          <Button
            ariaLabel="Add payment method"
            disabled={!stripe}
            className="!h-fit-content !w-fit-content !px-12 mt-8"
          >
            Add method
          </Button>
          {/* Show error message to your customers */}
          {errorMessage && <div>{errorMessage}</div>}
        </form>
      )}
    </>
  );
};

function AddPaymentMethodSuccess() {
  return (
    <div className="text-white grid items-center h-full">
      <div className="grid gap-y-3">
        <div className="grid justify-center items-center">
          <IconCheckCircle className="grid justify-center" />
        </div>

        <p className="text-2xl text-center">
          A new payment method has been
          <br /> added successfully
        </p>
      </div>
    </div>
  );
}
