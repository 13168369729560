import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Select from "react-select";
import {
  IconAlertTriangle,
  IconReactivateAccount,
  IconSearch,
  IconSuspendAccount,
} from "../../assets/SvgIcons";
import {
  getOrganizations,
  useAdminMutate,
  useUserData,
} from "../../components/auth/data";
import EditAccountPopover from "../../components/edit-account";
import { LottieLoader } from "../../components/Loader";
import { useThemeColor } from "../../components/theme-color";
import AppTooltip from "../../components/tooltip";
import { IOrganization } from "../../lib/interfaces";
import { theme } from "../../tailwind.config";
import { bignumbers, isProduction } from "../PipelinePage/utils";

type sortSelectType = {
  value: string;
  label: string;
};

const sortOptions: Array<sortSelectType> = [
  { value: "Organization", label: "Organization" },
  { value: "Last 30 days usage", label: "Last 30 days usage" },
  { value: "Paying Accounts", label: "Paying Accounts" },
];

export default function ManageAccounts() {
  const { t } = useTranslation("manage-accounts");
  const { isDark } = useThemeColor();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(5);
  const [collectionLength, setCollectionLength] = useState<number>(0);
  const [organizations, setOrganizations] = useState<Array<IOrganization>>([]);
  const [sortSelected, setSortSelected] = useState<sortSelectType>(
    sortOptions[1],
  );
  const LIMIT = 12;
  const sortOrganizationList = (selectedOption: sortSelectType) => {
    if (selectedOption === sortOptions[0]) {
      const sortedOrganizations = organizations.sort(
        (t1: IOrganization, t2: IOrganization) => {
          const firstEmail = t1?.founder_email || "";
          const t1Name = firstEmail.toLowerCase();
          const secondEmail = t2?.founder_email || "";
          const t2Name = secondEmail.toLowerCase();
          if (t1Name > t2Name) return 1;
          if (t1Name < t2Name) return -1;
          return 0;
        },
      );
      setOrganizations(sortedOrganizations);
    } else if (selectedOption === sortOptions[1]) {
      const sorted = organizations
        .sort((t1: IOrganization, t2: IOrganization) => {
          const monthly_usage1 = t1?.usage?.monthly_usage || 0;
          const monthly_usage2 = t2?.usage?.monthly_usage || 0;
          if (monthly_usage1 > monthly_usage2) return 1;
          if (monthly_usage1 < monthly_usage2) return -1;
          return 0;
        })
        .reverse();
      setOrganizations(sorted);
    } else if (selectedOption === sortOptions[2]) {
      const sorted = organizations
        .sort((t1: IOrganization, t2: IOrganization) => {
          const order = ["Paying"];

          const first = order.indexOf(t1.status);
          const second = order.indexOf(t2.status);

          if (first > second) return 1;
          return first - second;
        })
        .reverse();

      setOrganizations(sorted);
    }
  };

  const handleSelectSortOption = (selectedOption: sortSelectType) => {
    setSortSelected(selectedOption);
    sortOrganizationList(selectedOption);
  };

  const fetchOrganizations = async (search?: string) => {
    if (search === "") {
      const _organizations = await getOrganizations(LIMIT + offset);
      setOrganizations(_organizations?.organizations);
      setCollectionLength(_organizations.collectionLength);
    } else {
      const _organizations = await getOrganizations(LIMIT + offset, search);
      setOrganizations(_organizations.organizations);
      console.log("Orgs :: ", _organizations);
      setCollectionLength(_organizations.collectionLength);
    }

    setLoading(false);

    setTimeout(() => {
      setOffset((prev) => prev + 8);
    }, 200);
  };

  useEffect(() => {
    setLoading(true);
    if (search === "") {
      const asyncFetchOrganizations = async () => {
        await fetchOrganizations();
      };
      asyncFetchOrganizations();
    }
    if (search !== "") {
      setOffset(0);
      const fetchOrganizations = async () => {
        const _organizations = await getOrganizations(LIMIT + offset, search);

        console.log("New orgs :: ", _organizations);
        setOffset(8);
        setOrganizations(_organizations.organizations);
        setCollectionLength(_organizations.collectionLength);
        setLoading(false);
      };

      const delaySearch = setTimeout(async () => {
        await fetchOrganizations();
      }, 700);

      return () => clearTimeout(delaySearch);
    }

    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (organizations?.length) {
      sortOrganizationList(sortSelected);
    }

    //eslint-disable-next-line
  }, [organizations]);

  return (
    <React.Fragment>
      <div className="px-3 dark:text-white text-black ">
        <div className="grid">
          <div className="container grid grid-cols-auto-1fr items-center pb-0">
            <div className="">
              <h1
                className={`items-center md:visible font-poppins font-bold dark:text-white text-black`}
              >
                {t("Manage Accounts")}
              </h1>
              <div className="grid focus:ring-2 font-poppins h-[41px] gap-x-3 px-4 rounded-md grid-cols-1fr-auto items-center justify-items-center border border-borderLight dark:border-grayBorderLight bg-white  dark:bg-darkGrayBackground text-black dark:text-white ">
                <input
                  placeholder={t("Search")}
                  type="text"
                  className="w-full focus:outline-none bg-transparent text-sm placeholder-darkSearch"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
                <figure className="text-shadeBlue">
                  <IconSearch height="21" width="21" />
                </figure>
              </div>
            </div>

            <div
              className={`${!isProduction() ? "hidden" : "grid"} justify-end `}
            >
              <div className="grid grid-cols-auto-1fr gap-x-4 bg-pinkAlert dark:bg-darkPink dark:bg-opacity-10 h-[68px] w-[428px] rounded-md items-center px-4 justify-center font-poppins">
                <figure className="grid justify-center">
                  <IconAlertTriangle height="21" width="19" />
                </figure>
                <div className="grid items-center gap-y-1">
                  <p className="font-bold dark:text-white text-black text-sm m-0">
                    {t("You are editing customers on a Production server!")}
                  </p>
                  <span className="text-textLight text-xs self-center font-normal m-0">
                    {t("With great power comes great responsibility")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="grid font-poppins font-normal justify-end pt-10">
          <Select
            placeholder={t("Sort organizations by")}
            styles={selectStyles(isDark ? true : false)}
            components={{
              IndicatorSeparator: () => null,
            }}
            options={sortOptions}
            onChange={(selectedOption): any => {
              handleSelectSortOption(selectedOption || sortOptions[0]);
            }}
            value={sortSelected}
          />
        </p>
        <div className="overflow-x-auto">
          {loading ? (
            <LottieLoader />
          ) : !loading && !organizations?.length ? (
            <p className="text-lg">{t("No organizations found.")}</p>
          ) : (
            <OragnizationList
              organizations={organizations}
              setOrganizations={setOrganizations}
              search={search}
              loading={loading}
              setLoading={setLoading}
              fetchOrganizations={fetchOrganizations}
              collectionLength={collectionLength}
              offset={offset}
              setSortSelected={setSortSelected}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

type OrganizationListType = {
  organizations: Array<IOrganization>;
  setOrganizations: (organizations: Array<IOrganization>) => void;
  search: string;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  fetchOrganizations: (search: string) => void;
  collectionLength: number;
  offset: number;
  setSortSelected: Function;
};
const OragnizationList = ({
  organizations,
  collectionLength,
  fetchOrganizations,
  search,
  setSortSelected,
}: OrganizationListType) => {
  const { t } = useTranslation("manage-accounts");

  return (
    <div className="grid">
      <InfiniteScroll
        dataLength={organizations.length}
        next={() => {
          setSortSelected({
            value: "Sort list by",
            label: "Sort list by",
          });
          fetchOrganizations(search);
        }}
        height={"calc(100vh - 380px)"}
        scrollableTarget="scrollable"
        hasMore={collectionLength > organizations.length}
        loader={
          <div className="w-full rounded-md mx-auto my-3 px-4">
            <div className=" animate-pulse h-full justify-center">
              <div className="w-full bg-scorpionGray h-4 rounded-full "></div>
            </div>
          </div>
        }
        endMessage={<></>}
      >
        <table className="w-full table-fixed whitespace-nowrap pb-4 ">
          <thead className="border-b border-tableHeaderBorder dark:border-tableHeaderBorderDark dark:text-white text-black sticky">
            <tr>
              <th
                scope="col"
                className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left w-1/6"
              >
                {t("Organization")}
              </th>
              <th
                scope="col"
                className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left w-1/6"
              >
                {t("Status")}
              </th>
              <th
                scope="col"
                className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left w-1/6"
              >
                {t("Monthly Usage")}
              </th>
              <th
                scope="col"
                className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left w-1/6"
              >
                {t("Daily Usage")}
              </th>
              <th
                scope="col"
                className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left w-1/6"
              >
                {t("Data Default")}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>

          <tbody id="scrollable">
            {organizations?.map((orgData: IOrganization, index: number) => (
              <React.Fragment key={index}>
                <TableRow data={orgData} />
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

type DataDefaultSelectType = {
  value: string;
  label: string;
};

const dataDefaultOptions: Array<DataDefaultSelectType> = [
  { value: "Regular", label: "Regular" },
  { value: "Anonymize", label: "Anonymize" },
  { value: "No Save", label: "No Save" },
];

type TableRowType = {
  data: IOrganization;
};
const TableRow = ({ data }: TableRowType) => {
  const { t } = useTranslation("manage-accounts");
  const fullOrgData = data;
  const { isDark } = useThemeColor();
  const { value: mongoUserData } = useUserData();

  const statusStrategy = (statusName: string) => {
    if (!statusName) return;
    switch (statusName.toLowerCase()) {
      case "awaiting approval":
        return "bg-turbo bg-opacity-20 text-mustard rounded-[37px] px-[18px] py-1 text-xs min-h-[25px] w-fit-content";
      case "active":
        return "bg-strongPurple bg-opacity-20 text-darkPurple rounded-[37px] px-[18px] py-1 text-xs min-h-[25px] w-fit-content";
      case "approved":
        return "bg-newCyan bg-opacity-20 text-turquoise rounded-[37px] px-[18px] py-1 text-xs min-h-[25px] w-fit-content";
      case "suspended":
        return "bg-red bg-opacity-20 text-lightred dark:text-lightRed rounded-[37px] px-[18px] py-1 text-xs min-h-[25px] w-fit-content";
      case "inactive":
        return "bg-red bg-opacity-20 text-lightred dark:text-lightRed rounded-[37px] px-[18px] py-1 text-xs min-h-[25px] w-fit-content";
    }
  };
  const getOptionByName = (name: string) => {
    return dataDefaultOptions?.find(
      (option) => option.value.toLowerCase() === name?.toLowerCase(),
    );
  };

  const [defaultData, setDefaultData] = useState<DataDefaultSelectType>(
    getOptionByName(fullOrgData?.data_save_method || "") ||
      dataDefaultOptions[0],
  );

  const [monthUsageLimit, setMonthlyUsageLimit] = useState<number>(
    fullOrgData?.monthly_quota || 0,
  );

  const [dailyUsageLimit, setDailyUsageLimit] = useState<number>(
    fullOrgData?.daily_quota || 0,
  );

  const [accountStatus, setAccountStatus] = useState<string>(
    fullOrgData?.status || "Active",
  );

  const { mutateOrganizationField } = useAdminMutate();

  const handleSelectDefaultData = async (selectedOption: any) => {
    setDefaultData(selectedOption);
    await mutateOrganizationField({
      orgId: fullOrgData?.org_id,
      field: "data_save_method",
      newValue: selectedOption.value,
    });
  };

  const handleStatusChange = async () => {
    const newStatus = accountStatus === "Suspended" ? "Active" : "Suspended";

    const result = await mutateOrganizationField({
      orgId: fullOrgData?.org_id,
      field: "status",
      newValue: newStatus,
    });

    if (result?.data.success === true) {
      setAccountStatus(newStatus);
    }
  };

  const hasPermissions =
    mongoUserData?.role !== "Admin" && mongoUserData?.role !== "Editor";

  const updateOrganizationStates = () => {
    setDefaultData(
      getOptionByName(fullOrgData?.data_save_method || "") ||
        dataDefaultOptions[0],
    );
    setMonthlyUsageLimit(fullOrgData?.monthly_quota || 0);
    setDailyUsageLimit(fullOrgData?.daily_quota || 0);
    setAccountStatus(fullOrgData?.status || "Active");
  };

  React.useEffect(() => {
    updateOrganizationStates();
    //eslint-disable-next-line
  }, [fullOrgData]);

  return (
    <tr className="border-b border-tableBodyBorder dark:border-darkGrayBorder dark:text-white">
      <td className="px-6 py-4 font-poppins text-sm font-bold whitespace-normal break-all overflow-hidden">
        {fullOrgData?.founder_email}
      </td>
      <td className="px-6 py-4 w-full font-mono text-sm font-mediu  whitespace-normal min-w-[100px] overflow-hidden">
        <p className={statusStrategy(accountStatus)}>{accountStatus}</p>
      </td>
      <td className="text-sm font-poppins w-full font-light  px-6 py-4  whitespace-normal overflow-hidden">
        {bignumbers(fullOrgData?.usage?.monthly_usage)}/
        {bignumbers(monthUsageLimit)}
      </td>
      <td className="text-sm font-poppins w-full font-light  px-6 py-4  whitespace-normal overflow-hidden">
        {bignumbers(fullOrgData?.usage?.daily_usage)}/
        {bignumbers(dailyUsageLimit)}
      </td>
      <td className="text-sm font-poppins w-full font-light  px-6 py-4  whitespace-normal overflow-hidden">
        <div className="w-[149px]">
          <Select
            menuPortalTarget={document.body}
            styles={selectStyles(isDark ? true : false)}
            components={{
              IndicatorSeparator: () => null,
            }}
            options={dataDefaultOptions}
            onChange={(selectedOption): any => {
              handleSelectDefaultData(selectedOption);
            }}
            isDisabled={
              mongoUserData?.role !== "Admin" &&
              mongoUserData?.role !== "Editor"
            }
            value={defaultData}
          />
        </div>
      </td>
      <td className="font-poppins font-light px-6 py-4 h-full whitespace-normal overflow-hidden overflow-ellipsis">
        <div className="grid grid-cols-2-auto gap-x-6 w-fit-content">
          <div>
            <EditAccountPopover
              orgId={fullOrgData?.org_id}
              initialValues={{
                monthly_usage_limit: monthUsageLimit,
                daily_usage_limit: dailyUsageLimit,
              }}
              setMonthlyUsageLimit={setMonthlyUsageLimit}
              setDailyUsageLimit={setDailyUsageLimit}
              enabled={hasPermissions}
            />
          </div>
          <AppTooltip
            title={
              accountStatus === "Suspended"
                ? t<string>("Reactivate")
                : t<string>("Suspend")
            }
          >
            <button disabled={!hasPermissions} onClick={handleStatusChange}>
              {accountStatus === "Suspended" ? (
                <IconReactivateAccount
                  className={
                    !hasPermissions ? "text-shadeBlue" : "text-graylogo"
                  }
                  height="15px"
                  width="15px"
                />
              ) : (
                <IconSuspendAccount
                  className={
                    !hasPermissions ? "text-shadeBlue" : "text-graylogo"
                  }
                  height="15px"
                  width="15px"
                />
              )}
            </button>
          </AppTooltip>
        </div>
      </td>
    </tr>
  );
};

const selectStyles = (isDark: boolean) =>
  ({
    control: (provided: any, { isDisabled }: any) => ({
      ...provided,
      height: "41px",
      border: isDisabled
        ? theme.colors.transparent
        : isDark
        ? `1px solid ${theme.colors.darkGrayBorder}`
        : `1px solid ${theme.colors.borderLight}`,
      outline: "none",
      boxShadow: "none",
      borderRadius: "5px",
      backgroundColor: isDisabled
        ? theme.colors.transparent
        : isDark
        ? theme.colors.darkBackground
        : theme.colors.white,
      "&:hover": {
        border: isDark
          ? `1px solid ${theme.colors.darkGrayBorder}`
          : `1px solid ${theme.colors.borderLight}`,
      },
    }),

    singleValue: (provided: any) => ({
      ...provided,
      color: isDark ? theme.colors.white : theme.colors.black,
    }),
    dropdownIndicator: (provided: any, { isDisabled }: any) => ({
      ...provided,
      svg: {
        fill: isDisabled ? theme.colors.transparent : theme.colors.dodgerBlue,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.colors.formsgray,
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: isDark
        ? theme.colors.darkGrayBackground
        : theme.colors.faWhite,
      border: isDark
        ? `1px solid ${theme.colors.darkGrayBorder}`
        : `1px solid ${theme.colors.borderLight} `,
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),

    option: (provided: any, { isFocused, isSelected, isActive }: any) => ({
      ...provided,
      backgroundColor: isSelected
        ? isDark
          ? theme.colors.darkGrayBackground
          : theme.colors.faWhite
        : isFocused
        ? isDark
          ? theme.colors.faWhite
          : theme.colors.darkGrayBackground
        : isDark
        ? theme.colors.darkGrayBackground
        : theme.colors.faWhite,
      color: isSelected
        ? isDark
          ? theme.colors.faWhite
          : theme.colors.darkGrayBackground
        : isFocused
        ? isDark
          ? theme.colors.darkGrayBackground
          : theme.colors.faWhite
        : isDark
        ? theme.colors.faWhite
        : theme.colors.darkGrayBackground,

      "&:hover": {
        backgroundColor: isDark
          ? theme.colors.faWhite
          : theme.colors.darkGrayBackground,
        color: isDark ? theme.colors.darkGrayBackground : theme.colors.faWhite,
      },
      "&:active": {
        color: isDark ? theme.colors.black : theme.colors.white,
        backgroundColor: isDark
          ? theme.colors.faWhite
          : theme.colors.darkGrayBackground,
      },
    }),
  } as any);
