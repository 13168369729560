import React from "react";
import { useRecoilValue } from "recoil";
import { IconTabsTable } from "../../../assets/SvgIcons";
import Clustering from "../../../components/clustering-global";
import { useThemeColor } from "../../../components/theme-color";
import { pipelineDNDColumnsAtom } from "../../../lib/atoms";
import { PipelineValuesType } from "../../../lib/interfaces";

import theme from "../../../theme";
import Table from "../../pipeline/pipeline-output-table";
import { LabelsType } from "../types";
import { useEventLogger } from "../../../lib/event_logger";
import { useResizeDetector } from "react-resize-detector";

type TabsProps = {
  labels?: LabelsType;
  text?: string;
  labelTypeExists?: any;
  dataLabel?: any;
  tabsHeight?: number;
  tabValue?: PipelineValuesType;
  setTabValue?: any;
  tabHeight?: any;
};
const Tabs = ({
  labels,
  text,
  labelTypeExists,
  dataLabel,
  tabValue,
  setTabValue,
  tabsHeight,
  tabHeight,
}: TabsProps) => {
  const { isDark } = useThemeColor();
  const pipelineDNDColumns = useRecoilValue(pipelineDNDColumnsAtom);
  const [json, setJSON] = React.useState<any>("");

  React.useEffect(() => {
    setJSON(dataLabel);
    if (hasClusteringSkill) {
      //setTabsValue("tree");
    }
    //eslint-disable-next-line
  }, [dataLabel]);

  const hasClusteringSkill = React.useMemo(
    () =>
      pipelineDNDColumns?.pipeline?.items.some((skill: any) => {
        return skill.name === "Clustering";
      }),
    //eslint-disable-next-line
    [dataLabel],
  );

  const { userUIEventLogger } = useEventLogger();

  const { width, ref } = useResizeDetector();

  const renderTabs = () => {
    if (!!labels?.length && labels[0].skill) {
      return (
        <>
          {tabValue === "TABLE" ? (
            <>
              <div className="overflow-x-auto ">
                <Table
                  labels={labels}
                  text={text}
                  labelTypeExists={labelTypeExists}
                  tabsHeight={tabsHeight}
                />
              </div>
            </>
          ) : (
            <div
              className="h-full overflow-y-auto"
              ref={ref}
              data-cy="treemap-table"
            >
              <Clustering
                type={"STUDIO"}
                width={width || 0}
                height={tabHeight - 95}
                json={json}
                rightButton={
                  <button
                    className="grid justify-self-end items-center px-2"
                    onClick={() => {
                      setTabValue("TABLE");
                      userUIEventLogger(
                        "studio__output_table__labels_clusters",
                      );
                    }}
                  >
                    <IconTabsTable
                      width="35px"
                      height="35px"
                      fill={
                        isDark
                          ? theme.colors.darkGrayBackground
                          : theme.colors.white
                      }
                      type="table"
                    />
                  </button>
                }
              />
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className={`bg-white dark:bg-darkGrayBackground h-full rounded-md`}>
      {renderTabs()}
    </div>
  );
};

export default Tabs;
