import React from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { mobileMenuOpenAtom } from "../../lib/atoms";
import Logo from "../Logo";
import LogoMedium from "../Logo/logo-medium";
import { useThemeColor } from "../theme-color";
import UserMenu from "../user-menu";
import TopNavigation from "./top-navigation";

type LayoutType = {
  headerRightRender?: JSX.Element;
  children?: React.ReactNode;
  loader?: boolean;
};

const Layout = ({ children, headerRightRender, loader }: LayoutType) => {
  const { isDark } = useThemeColor();
  const location = useLocation();
  const isMobileMenuOpen = useRecoilValue(mobileMenuOpenAtom);
  const isOutput = location.pathname === "/pipeline-output";
  const showRunPipelineButton = [
    "/",
    "/pipeline-input",
    "/pipeline-skills",
    "/pipeline-output",
  ].includes(window.location.pathname);
  return (
    <React.Fragment>
      <div className="flex flex-col rounded-md md:overflow-y-hidden h-full">
        <header className="py-4">
          <div className="md:px-4 grid grid-cols-auto-1fr items-center">
            {/* Large screens top-navigation*/}
            <div className="hidden xl:grid items-center md:visible grid-flow-col gap-x-6">
              <Logo />
              <TopNavigation screen="large" />
            </div>

            {/* Medium screens top-navigation*/}
            <div className="hidden md:grid xl:hidden items-center md:visible grid-flow-col gap-x-6">
              <LogoMedium />
              <TopNavigation screen="medium" />
            </div>

            {/* Mobile screens top-navigation*/}
            <div className="grid md:hidden xl:hidden items-center md:visible grid-flow-col gap-x-6">
              <Logo />
            </div>

            <div className="grid grid-cols-2-auto justify-end items-center">
              <div className="md:grid md:relative hidden w-fit-content">
                {headerRightRender}
              </div>
              <UserMenu />
            </div>
          </div>
        </header>
        <main className="grow h-full rounded-md overflow-y-hidden">
          <div
            className={`${isOutput ? "px-4 md:pl-4 md:pr-0" : "px-4"} h-full`}
          >
            {children}
          </div>
        </main>
        {showRunPipelineButton && (
          <div
            className={`md:hidden fixed bottom-0 w-full bg-white dark:bg-darkGrayBackground h-[70px] py-4 z-20`}
          >
            {headerRightRender}
          </div>
        )}
      </div>
      <style>
        {` 
      ::-webkit-scrollbar {
        width: ${isMobileMenuOpen ? "0px" : "6px"};
        height: ${isMobileMenuOpen ? "0px" : "6px"};
      }

      ::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #333333;
        background: ${isDark ? "#333333" : "#F4F6F8"};
      }

      ::-webkit-scrollbar-thumb {
        background: ${isDark ? "#474747" : "#D8DEE6"};
        border-radius: 100vh;
      }

      h1, h2, h3, h4, h5, h6 {
        color: ${isDark ? "#FFFFFF" : "#333333"};
      }

        .output-loader > div > div > div:not(*.no-blur) div  {
          filter: blur(4px) !important;
        }

      `}
      </style>
    </React.Fragment>
  );
};
export default Layout;
