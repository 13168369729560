import { Box, CircularProgress } from "@mui/material";
import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import logo from "../../assets/logo-forms.svg";
import darkModeLogo from "../../assets/logo-gray.svg";
import { IconEnvelope } from "../../assets/SvgIcons";
import { registrationEmailAtom } from "../../lib/atoms";
import { FormNameType } from "../../lib/interfaces";
import { useThemeColor } from "../theme-color";
import { resendValidationEmail } from "./data";

type FormType = {
  setForm: Dispatch<FormNameType>;
};

export default function ValidateEmailModal({ setForm }: FormType) {
  const { t } = useTranslation("index");
  const { isDark } = useThemeColor();
  const email = useRecoilValue(registrationEmailAtom);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [floodSafe, setFloodSafe] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [successMessage, setSuccessMessage] = React.useState<string>("");

  const handleResend = async () => {
    setSuccessMessage("");
    setErrorMessage("");

    if (floodSafe) {
      setErrorMessage(
        "You can only resend the validation email once every 2 minutes",
      );
    } else {
      setButtonLoading(true);
      const result = await resendValidationEmail(email);
      setFloodSafe(true);
      setTimeout(() => {
        setFloodSafe(false);
      }, 1000 * 60 * 2);

      if (result?.success) {
        setSuccessMessage("Email sent successfully");
      } else {
        setErrorMessage("We could not re-send email, please try again later");
      }
      setButtonLoading(false);
      console.log(result);
    }
  };
  return (
    <div className="min-h-0 h-[803px] overflow-x-hidden overflow-y-auto grid grid-rows-1fr-auto">
      <div className="space-y-8">
        <div className="py-3 grid justify-center">
          <figure className="image w-[110px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <div className="pb-3 grid justify-center pt-8">
          <figure className="image w-[110px]">
            <IconEnvelope />
          </figure>
        </div>
        <p className="grid text-center font-bold text-black dark:text-white  font-poppins text-2xl leading-8 m-0 p-0 ">
          {t(`Registration email sent to`)} {email}.<br />
          {t("Open this email to finish signup.")}
        </p>
      </div>

      <div className="grid items-end px-2 gap-y-4 ">
        <div>
          <p className="text-black dark:text-white text-sm font-poppins font-normal text-center leading-7">
            {t(
              "If you don’t see this email in your inbox within 15 minutes, look for it in your junk mailfolder. ",
            )}
            &nbsp;
            {t("If you find it there, please mark the emails ‘’Not Junk’’")}
          </p>
        </div>
        <div>
          {buttonLoading ? (
            <button
              type="button"
              className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none  p-4 hover:bg-opacity-60 w-full grid grid-flow-col`}
              disabled
            >
              <span>
                <Box>
                  <CircularProgress
                    style={{
                      width: 15,
                      height: 15,
                      color: "white",
                      padding: 0,
                      margin: 0,
                    }}
                  />
                </Box>
              </span>
            </button>
          ) : (
            <button
              onClick={async () => {
                await handleResend();
              }}
              type="submit"
              className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none w-full p-4 hover:bg-opacity-60`}
            >
              {t("Resend email")}
            </button>
          )}

          {errorMessage && (
            <p className="text-red font-normal text-sm text-center p-0">
              {errorMessage}
            </p>
          )}
          {successMessage && (
            <p className="text-green font-normal text-sm text-center p-0">
              {successMessage}
            </p>
          )}
        </div>

        <div className="grid justify-center py-3 pt-10">
          <div className="text-black dark:text-white text-sm font-poppins font-normal">
            {t("Have an account?")}
            <button
              className="text-shadeBlue px-2 font-mono font-bold justify-self-center"
              onClick={() => setForm("login")}
            >
              {t("Sign in")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
