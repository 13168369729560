import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { IconOneAI } from "../../assets/SvgIcons";
import { LottieLoader } from "../../components/Loader";
import AppTooltip from "../../components/tooltip";
import {
  cubeFileAtom,
  cubeStateAtom,
  fileContentAtom,
  textAreaAtom,
  textAreaEmptyStateAtom,
} from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { LibrarySampleType } from "../../lib/interfaces";
import { useStudioData } from "../../lib/use-studio-data";
import settingsFile from "../../utils/settings.json";
import "./styles.css";
import { getSkillIcon } from "./utils";

const SampleLibrary = () => {
  const [librarySamples, setLibrarySamples] = useState<
    Array<LibrarySampleType>
  >([]);
  const [sampleCategories, setSampleCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [samplesLoading, setSamplesLoading] = useState<boolean>(false);
  const { data } = useStudioData();
  const { userEventLogger, userUIEventLogger } = useEventLogger();
  const { t } = useTranslation("index");
  const setCubeState = useSetRecoilState(cubeStateAtom);
  const setCubeFile = useSetRecoilState(cubeFileAtom);
  const setTextarea = useSetRecoilState(textAreaAtom);
  const setTextAreaEmptyStateAtom = useSetRecoilState(textAreaEmptyStateAtom);
  const setFileContent = useSetRecoilState(fileContentAtom);

  const navigate = useNavigate();
  const uniqueCategories = async () => {
    const categoryArray: any = [];
    settingsFile?.content_library.forEach((sample: any) => {
      sample?.categories?.forEach((categoryName: string) => {
        categoryArray.push(categoryName);
      });
    });
    return Array.from(new Set(categoryArray));
  };

  const getSampleLibraryItemsByCategoryName = async (categoryName: string) => {
    try {
      const sampleLibraryItems = settingsFile?.content_library.filter(
        (sample: any) => sample?.categories?.includes(categoryName),
      );
      if (sampleLibraryItems !== undefined) return sampleLibraryItems;
      return [];
    } catch (error: any) {
      console.log(error);
      return [];
    }
  };

  const sortCategoryItems = (categoryNamesArray: any) => {
    let sortingArray: any = settingsFile?.categories_order || [];
    categoryNamesArray.forEach((categoryName: string) => {
      if (sortingArray.includes(categoryName)) return;
      else {
        if (categoryNamesArray.includes(categoryName))
          sortingArray.push(categoryName);
      }
    });
    sortingArray.forEach((categoryName: string, index: number) => {
      if (!categoryNamesArray.includes(categoryName)) {
        sortingArray.splice(index, 1);
      }
    });
    let uniques: any = Array.from(new Set(sortingArray));
    return uniques;
  };

  React.useEffect(() => {
    async function getSamples() {
      if (selectedCategory === "") {
        const categoryNamesArray: any = await uniqueCategories();
        const sortedArray = sortCategoryItems(categoryNamesArray);

        setSampleCategories(sortedArray);
        setSelectedCategory(sortedArray[0]);
      } else {
        const samplesByCategoryName = await getSampleLibraryItemsByCategoryName(
          selectedCategory,
        );
        setLibrarySamples(samplesByCategoryName);
        setSamplesLoading(false);
      }
    }
    getSamples();
  }, [selectedCategory]);

  const onSetSelectedCategory = (categoryName: any) => {
    setSelectedCategory(categoryName);
    userUIEventLogger(`studio__sample_library__${categoryName}`);
  };

  if (samplesLoading)
    return (
      <div className="w-full dark:bg-darkGrayBackground bg-white overflow-x-hidden rounded-b-md overflow-y-hidden grid justify-center items-center">
        <LottieLoader />
      </div>
    );

  if (!samplesLoading && !sampleCategories?.length) {
    return (
      <div className="grid justify-center items-center dark:bg-darkGrayBackground bg-white overflow-x-hidden rounded-b-md overflow-y-hidden">
        <div className="grid justify-center">
          <p className="grid  justify-center self-center text-center font-poppins text-sm dark:text-white text-black font-normal p-6">
            {t("We have no samples at the moment. Please come back later!")}
          </p>
          <figure className="opacity-50 grid justify-center">
            <IconOneAI width="50px" height="50px" />
          </figure>
        </div>
      </div>
    );
  }
  return (
    <div className="grid grid-rows-auto-1fr w-full h-full overflow-x-hidden overflow-y-hidden">
      <div className="grid grid-flow-col gap-x-2 overflow-x-auto preserve-scrollbar p-3">
        {sampleCategories &&
          sampleCategories?.map((categoryName: any, index: any) => (
            <button
              key={index}
              onClick={() => onSetSelectedCategory(categoryName)}
              className={`rounded-md  text-center justify-center grid ${
                categoryName === selectedCategory
                  ? "bg-categoryCyan"
                  : "dark:bg-categoryDark bg-faWhite"
              }`}
            >
              <span
                className={`p-2 self-center font-roboto text-xs font-normal items-center select-none whitespace-nowrap ${
                  categoryName === selectedCategory
                    ? "text-black"
                    : "dark:text-categoryGray text-grayButtons"
                }`}
              >
                {categoryName}
              </span>
            </button>
          ))}
      </div>

      <div className="h-full overflow-y-auto preserve-scrollbar">
        <div className="samples pr-2 pl-3 grid gap-y-2 pb-10">
          {librarySamples &&
            librarySamples?.map((sample: any, index: number) => {
              return (
                <button
                  key={index}
                  onClick={() => {
                    setCubeState("WAITING");
                    setCubeFile("");
                    setFileContent("");
                    setTextarea("");
                    setTextAreaEmptyStateAtom(false);
                    userEventLogger(UserEvent.SELECT_LIBRARY_SAMPLE, {
                      value: sample?.header,
                    });
                    userEventLogger(UserEvent.INPUT_CONTENT, {
                      value: "SELECT_LIBRARY_SAMPLE",
                    });
                    if (sample?.share_link)
                      navigate("?pipeline=" + sample?.share_link, {
                        // replace: true,
                      });
                  }}
                  className="grid w-full rounded-sm dark:bg-categoryItemBackgroundDark bg-white hover:bg-faWhite shadow-md dark:hover:bg-categoryItemBackgroundDarkHover dark:text-white pb-2"
                >
                  <div className="grid grid-cols-1fr-auto gap-y-2 ">
                    <div className="content p-3 grid justify-self-start justify-start ">
                      <h1 className=" text-left dark:text-white text-[16px] font-poppins m-0 p-0 grid justify-self-start ">
                        {sample?.header}
                      </h1>
                    </div>
                    <div
                      className={`grid grid-flow-col gap-x-2 dark:bg-categoryItemBackgroundDarkHover bg-faWhite items-center px-3 rounded-bl-[32px] rounded-tr-sm pl-7 ${
                        sample?.skills?.length ? "" : "hidden"
                      }`}
                    >
                      {Array.from(new Set(sample?.skills))?.map(
                        (skillName: any, index: number) => {
                          return (
                            <AppTooltip title={skillName ? skillName : "Skill"}>
                              <figure className="m-0 p-0" key={index}>
                                {getSkillIcon(skillName, "LIBRARY", true, data)}
                              </figure>
                            </AppTooltip>
                          );
                        },
                      )}
                    </div>
                  </div>
                  <p className="grid px-3 leading-7 font-normal text-sm m-0 w-[85%] self-start text-left">
                    {sample?.description}
                  </p>
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SampleLibrary;
