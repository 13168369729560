import { cloneDeep } from "lodash";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { IconAlertTriangle, TrashIcon } from "../../assets/SvgIcons";
import Accordion from "../../components/accordion";
import AddDocumentationSection from "../../components/add-documentation-section";
import AddItemDocumentationModal from "../../components/add-item-documentation";
import AddSkillModal from "../../components/add-skill-modal";
import AddSkillTypePopover from "../../components/add-skill-type";
import AddVideoCategoryModal from "../../components/add-video-category-modal";
import AddVideoModal from "../../components/add-video-modal";
import {
  mutateDraftObject,
  resetContentDraft,
} from "../../components/auth/data";
import ConfirmContentChanges from "../../components/confirm-content-changes";
import ConfirmPopover from "../../components/confirm-popover";
import EditDocumentationSection from "../../components/edit-documentation-section";
import EditItemDocumentationModal from "../../components/edit-item-documentation";
import EditSkillModal from "../../components/edit-skill-modal";
import EditSkillTypePopover from "../../components/edit-skill-type";
import EditVideoCategory from "../../components/edit-video-category";
import EditVideoModal from "../../components/edit-video-modal";
import RemoveSkillPopover from "../../components/remove-skill";
import RemoveSkillTypePopover from "../../components/remove-type";
import AppTooltip from "../../components/tooltip";
import { contentFileAtom, previewModeAtom } from "../../lib/atoms";
import useDidMountEffect from "../../lib/use-did-mount-effect";
import { useDraftData } from "../../lib/use-studio-data";
import contentFile from "../../utils/content.json";
import {
  buildSkillsLibraryAtom,
  skillsLibraryAtom,
} from "../pipeline/pipeline-atoms";
import { isProduction } from "../PipelinePage/utils";
export default function ContentManagement() {
  const [draft, setDraft] = useRecoilState(contentFileAtom);
  const [previewMode, setPreviewMode] = useRecoilState(previewModeAtom);
  const { t } = useTranslation("content-management");
  const setSkillsLibrary = useSetRecoilState(skillsLibraryAtom);
  useDidMountEffect(() => {
    const mutateDraft = async () => {
      await mutateDraftObject(draft);
    };
    mutateDraft();
    //eslint-disable-next-line
  }, [draft]);

  async function handleDeleteDraft() {
    const resetDraft = await resetContentDraft("reset");
    if (resetDraft) {
      setDraft(resetDraft.oldContent);
    }
  }

  const { data, loading } = useDraftData(draft);

  function togglePreviewMode() {
    setPreviewMode(!previewMode);
  }

  const originalSkills: any = contentFile?.skills;

  React.useEffect(() => {
    if (previewMode) {
      setSkillsLibrary({ skills: buildSkillsLibraryAtom(data.skills) });
    } else {
      setSkillsLibrary({ skills: buildSkillsLibraryAtom(originalSkills) });
    }
    //eslint-disable-next-line
  }, [previewMode, loading]);

  return (
    <>
      <div className="grid grid-flow-row gap-y-3">
        <div className="grid grid-cols-1fr-auto items-center pb-0">
          <div className="grid grid-cols-1fr-auto">
            <div className="w-full grid grid-flow-col gap-x-4">
              <div className="grid justify-start">
                <h1
                  className={`items-center md:visible font-poppins font-bold dark:text-white text-black`}
                >
                  {t("Content Management ")}
                </h1>
              </div>
              <div className="grid justify-end">
                <div className="grid items-center justify-center grid-cols-3 gap-x-3">
                  <ConfirmContentChanges contentFile={data} />

                  <ConfirmPopover
                    title="Are you sure?"
                    text="This will delete your draft!"
                    onConfirm={handleDeleteDraft}
                    button={
                      <button className="overflow-hidden cursor-pointer text-center  transform-none m-0 items-center w-full py-3 px-5 dark:text-white bg-red bg-opacity-30 hover:bg-red text-black whitespace-nowrap text-xs md:text-xs  gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-40 hover:bg-lightback rounded-md shadow-xl ">
                        <span>{t("Delete Draft")}</span>
                      </button>
                    }
                  />

                  <button
                    onClick={() => {
                      togglePreviewMode();
                    }}
                    className="overflow-hidden relative cursor-pointer w-full  text-center transform-none m-0 items-center py-3 px-5 dark:text-white text-black whitespace-nowrap text-xs md:text-xs grid-rows-2  gap-x-2 font-mono font-bold tracking-tight   bg-shadeBlue bg-opacity-30 hover:bg-shadeBlue hover:bg-opacity-40 rounded-md shadow-xl"
                  >
                    <span className="grid">{t("Preview mode")}</span>
                    <span
                      className={`grid  ${
                        previewMode ? "text-green" : "text-red"
                      }`}
                    >
                      {t(previewMode ? "ON" : "OFF")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={`${isProduction() ? "" : "hidden"} justify-end `}>
            <div className="grid grid-cols-auto-1fr gap-x-4 bg-pinkAlert dark:bg-darkPink dark:bg-opacity-10 h-[68px] w-[428px] rounded-md items-center px-4 justify-center font-poppins">
              <figure className="grid justify-center">
                <IconAlertTriangle height="21" width="19" />
              </figure>
              <div className="grid items-center gap-y-1 justify-center">
                <p className="font-bold dark:text-white text-black text-sm m-0">
                  {t("You are editing production content")}
                </p>
                <span className="text-textLight text-xs self-center font-normal m-0">
                  {t("Please review the changes before saving them.")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`h-400 md:h-[calc(100vh-250px)] overflow-y-auto ${
            loading ? "blur-[2px]" : ""
          }`}
        >
          <Accordion
            title="Skills table"
            content={SkillsTable(data, t, setDraft)}
          />
          <Accordion
            title={t("Skill types table")}
            content={skillTypesTable(data, t)}
          />
          <Accordion
            title={t("Documentation table")}
            content={documentationTable(data, t, setDraft)}
          />
          <Accordion
            title={t("Documentation sections")}
            content={documentationSectionsTable(data, t, setDraft)}
          />
          <Accordion
            title={t("Videos table")}
            content={VideosCategories(data, setDraft)}
          />
          <Accordion
            title={t("Video categories table")}
            content={VideoCategoriesTable(data, setDraft)}
          />
        </div>
      </div>
    </>
  );
}

function getAllDocumentationSections(contentFile: any) {
  const allSkills = contentFile?.documentation.map((item: any) => {
    return item;
  });
  return allSkills;
}

function getAllSkills(contentFile: any) {
  const allSkills = contentFile?.skills?.map((item: any) => {
    return item;
  });
  return allSkills;
}

function getAllSkillTypes(contentFile: any) {
  const allSkills = contentFile?.skill_categories?.map((item: any) => {
    return item;
  });
  return allSkills;
}

function skillTypesTable(contentFile: any, t: any) {
  return (
    <div className="px-4">
      <div className="py-4">
        <AddSkillTypePopover />
      </div>
      <table className="text-left dark:text-white text-black font-poppins w-full font-normal border-collapse rounded-md table-fixed">
        <tr className="">
          <th className="py-4">{t("Type ID")}</th>
          <th className="py-4">{t("Type name")}</th>
          <th className="py-4">{t("Type color")}</th>
          <th></th>
          <th className="py-4">{t("Actions")}</th>
        </tr>
        {getAllSkillTypes(contentFile)?.map((item: any) => {
          return (
            <>
              <tr className="p-4 border-b border-lightBlack">
                <td className="py-4">{item.id}</td>
                <td
                  className="py-4"
                  style={{
                    color: item?.color,
                  }}
                >
                  {item.category_name}
                </td>
                <td className="py-4">{item?.color}</td>
                <td></td>
                <td className="py-4 grid justify-start grid-flow-col gap-x-4">
                  <EditSkillTypePopover skill_type={item} />
                  <RemoveSkillTypePopover type={item} />
                </td>
              </tr>
            </>
          );
        })}
      </table>
    </div>
  );
}

function documentationSectionsTable(
  contentFile: any,
  t: any,
  setContentFile: Function,
) {
  return (
    <div className="px-4">
      <div className="py-4">
        <p className="text-black dark:text-white text-xs">
          Note: skills documentation is generated automatically based on skills.
        </p>
        <AddDocumentationSection />
      </div>
      <table className="text-left dark:text-white text-black font-poppins w-full font-normal border-collapse rounded-md table-fixed">
        <tr className="">
          <th className="py-4">{t("Section name")}</th>
          <th className="py-4">{t("Items")}</th>
          <th></th>
          <th></th>
          <th className="py-4">{t("Actions")}</th>
        </tr>
        {getAllDocumentationSections(contentFile)?.map(
          (section: any, sectionIndex: number) => {
            return (
              <>
                <tr className="p-4 border-b border-lightBlack">
                  <td className="py-4">{section.name}</td>
                  <td className="py-4 overflow-x-ellipsis">
                    {section.items &&
                      section.items.map((item: any, index: number) => {
                        return `${item.item_name} ${
                          index !== section?.items?.length - 1 ? ", " : ""
                        }`;
                      })}
                  </td>
                  <td></td>
                  <td></td>
                  <td className="py-4 grid justify-start grid-flow-col gap-x-4">
                    <EditDocumentationSection section={section} />
                    {section.type === "skills" ? (
                      <TrashIcon className="text-tableHeaderBorderDark" />
                    ) : (
                      <ConfirmPopover
                        title="Are you sure?"
                        text="This will remove this documentation item"
                        tooltip="Remove item"
                        onConfirm={() => {
                          const clonedObject = cloneDeep(contentFile);
                          clonedObject.documentation.splice(sectionIndex, 1);
                          setContentFile(clonedObject);
                        }}
                        button={<TrashIcon className="text-shadeBlue" />}
                      />
                    )}
                  </td>
                </tr>
              </>
            );
          },
        )}
      </table>
    </div>
  );
}
function getAllDocumentation(contentFile: any) {
  const documentationExceptSkills = contentFile?.documentation
    ?.filter((docSection: any) => docSection.type !== "skills")
    .map((item: any) => {
      return item;
    });
  return documentationExceptSkills;
}

function documentationTable(
  contentFile: any,
  t: any,
  setContentFile: Function,
) {
  return (
    <div className="px-4">
      <div className="py-3">
        <AddItemDocumentationModal />
      </div>
      <table className="text-left dark:text-white text-black font-poppins w-full font-normal border-collapse rounded-md table-fixed">
        <tr className="">
          <th className="py-4">{t("Name")}</th>
          <th className="py-4">{t("Content")}</th>
          <th className="py-4">{t("Section")}</th>
          <th></th>
          <th className="py-4">{t("Actions")}</th>
        </tr>
        {getAllDocumentation(contentFile)?.map(
          (section: any, sectionIndex: number) => {
            return section?.items.map((doc: any, docIndex: number) => {
              return (
                <tr className="p-4 border-b border-lightBlack">
                  <td className="py-4">{doc.item_name}</td>
                  <td className="py-4">
                    {doc.content_html
                      ? doc.content_html?.substring(0, 15) + "..."
                      : "No content"}
                  </td>
                  <td>{section.name}</td>
                  <td></td>
                  <td className="py-4 grid justify-start grid-flow-col gap-x-4">
                    <EditItemDocumentationModal
                      item={doc}
                      section={section.name}
                    />
                    <ConfirmPopover
                      title="Are you sure?"
                      text="This will remove this documentation item"
                      tooltip="Remove item"
                      onConfirm={() => {
                        const clonedObject = cloneDeep(contentFile);
                        clonedObject.documentation[sectionIndex].items.splice(
                          docIndex,
                          1,
                        );
                        setContentFile(clonedObject);
                      }}
                      button={<TrashIcon className="text-shadeBlue" />}
                    />
                  </td>
                </tr>
              );
            });
          },
        )}
      </table>
    </div>
  );
}

export const getSkillTypeNameByID = (contentFile: any, skillID: number) => {
  const foundSkill = contentFile.skill_categories.find(
    (o: any) => o.id === skillID,
  );
  return foundSkill?.category_name || "Unknown";
};

function SkillsTable(contentFile: any, t: any, setDraft: any) {
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const getSkills = cloneDeep(contentFile);
    const skills = reorder(
      getSkills.skills,
      result.source.index,
      result.destination.index,
    );

    getSkills.skills = skills;
    const ordersArray = getSkills.skills.map((skill: any) => skill.name);
    getSkills.skills_order = ordersArray;
    setDraft(getSkills);
  }

  return (
    <div className="px-4">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <>
              <div className="py-3">
                <AddSkillModal />
              </div>

              <table
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="text-left dark:text-white text-black  font-poppins w-full font-normal border-collapse rounded-md table-fixed "
              >
                <tr className="">
                  <th className="py-4">{t("UI name")}</th>
                  <th className="py-4">{t("Endpoint")}</th>
                  <th className="py-4">{t("Skill category")}</th>
                  <th className="py-4"></th>
                  <th className="py-4">{t("Actions")}</th>
                </tr>
                {getAllSkills(contentFile) &&
                  getAllSkills(contentFile).map((item: any, index: number) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            className={`select-none w-full hover:bg-gray hover:bg-opacity-10 ${
                              snapshot.isDragging
                                ? " bg-red bg-opacity-20 w-[100%] table"
                                : "border-b border-lightBlack"
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {item.name}
                            </td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {item.value}
                            </td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {getSkillTypeNameByID(contentFile, item.category)}
                            </td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            ></td>
                            <td
                              className={`${
                                snapshot.isDragging ? "w-[20%]" : ""
                              } grid justify-start gap-x-4 text-shadeBlue grid-flow-col py-4`}
                            >
                              <EditSkillModal skill={item} />
                              <RemoveSkillPopover skill={item} />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder}
              </table>
            </>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function getAllVideoCategories(contentFile: any) {
  const allCategories = contentFile?.video_categories?.map((item: any) => {
    return item;
  });
  return allCategories;
}

function VideosCategories(contentFile: any, setDraft: any) {
  return (
    <div className="px-4">
      <div className="py-3">
        <AddVideoModal />
      </div>

      {contentFile.video_categories &&
        contentFile.video_categories.map((category: any) => {
          return (
            <Accordion
              title={`Category: ${category.video_category_name} (ID: ${category.video_category})`}
              content={
                <VideosTable
                  contentFile={contentFile}
                  setDraft={setDraft}
                  category={category.video_category}
                />
              }
            />
          );
        })}
    </div>
  );
}

function VideoCategoriesTable(contentFile: any, setDraft: any) {
  const { t } = useTranslation("index");
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const getVideos = cloneDeep(contentFile);
    const video_categories = reorder(
      getVideos.video_categories,
      result.source.index,
      result.destination.index,
    );

    getVideos.video_categories = video_categories;
    const videosArray = getVideos.video_categories.map(
      (video: any, index: number) => {
        return { ...video, video_category_order: index };
      },
    );
    getVideos.video_categories = videosArray;
    setDraft(getVideos);
  }

  return (
    <div className="px-4">
      <div className="py-3">
        <AddVideoCategoryModal />
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <table
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="text-left dark:text-white text-black font-poppins w-full font-normal border-collapse rounded-md table-fixed px-4"
            >
              <tr className="">
                <th className="py-4">{t("Category ID")}</th>
                <th className="py-4">{t("Category name")}</th>
                <th className="py-4"></th>
                <th className="py-4"></th>
                <th className="py-4">{t("Actions")}</th>
              </tr>
              {getAllVideoCategories(contentFile) &&
                getAllVideoCategories(contentFile)
                  .sort((a: any, b: any) => a.order - b.order)
                  .map((item: any, index: number) => {
                    const totalVideosInCategory = contentFile.videos.filter(
                      (video: any) => video.category === item.video_category,
                    ).length;

                    return (
                      <Draggable
                        key={item.video_category}
                        draggableId={String(item.video_category)}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            className={`select-none w-full hover:bg-gray hover:bg-opacity-10 ${
                              snapshot.isDragging
                                ? " bg-red bg-opacity-20 w-[100%] table"
                                : "border-b border-lightBlack"
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {item.video_category}
                            </td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {item.video_category_name}
                            </td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            ></td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            ></td>
                            <td
                              className={`${
                                snapshot.isDragging ? "w-[20%]" : ""
                              } grid justify-start gap-x-4 text-shadeBlue grid-flow-col py-4`}
                            >
                              <EditVideoCategory category={item} />
                              <td className="py-4 grid justify-start grid-flow-col gap-x-4">
                                {totalVideosInCategory < 1 ? (
                                  <ConfirmPopover
                                    title="Are you sure?"
                                    text="This will remove this video category"
                                    tooltip="Remove video category"
                                    onConfirm={() => {
                                      const clonedObject =
                                        cloneDeep(contentFile);
                                      clonedObject.video_categories.splice(
                                        index,
                                        1,
                                      );
                                      setDraft(clonedObject);
                                    }}
                                    button={
                                      <TrashIcon className="text-shadeBlue" />
                                    }
                                  />
                                ) : (
                                  <AppTooltip title="Category is not empty">
                                    <button>
                                      <TrashIcon className="text-tableHeaderBorderDark" />
                                    </button>
                                  </AppTooltip>
                                )}
                              </td>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
              {provided.placeholder}
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function getAllVideos(contentFile: any, category: any) {
  const allVideos = contentFile?.videos
    ?.filter((video: any) => video.link !== null && video.category === category)
    .map((item: any) => {
      return item;
    });
  return allVideos;
}

function VideosTable({ contentFile, setDraft, category }: any) {
  const { t } = useTranslation("index");
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const getVideos = cloneDeep(getAllVideos(contentFile, category));
    const fullArray = cloneDeep(contentFile);
    const videos = reorder(
      getVideos,
      result.source.index,
      result.destination.index,
    );

    // Sorts nested videos
    videos.forEach((video: any, index: number) => {
      fullArray.videos.forEach((item: any) => {
        if (item.id === video.id) {
          item.order = index;
        }
      });
    });
    setDraft(fullArray);
  }

  function getCategoryName() {
    try {
      return contentFile.video_categories?.find(
        (videoCategory: any) => videoCategory.video_category === category,
      ).video_category_name;
    } catch (e) {
      return "Deleted";
    }
  }
  return (
    <div className="px-4">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <table
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="text-left dark:text-white text-black font-poppins w-full font-normal border-collapse rounded-md table-fixed px-4"
            >
              <tr className="">
                <th className="py-4">{t("Video ID")}</th>
                <th className="py-4">{t("Title")}</th>
                <th className="py-4">{t("Description")}</th>
                <th className="py-4">{t("Category")}</th>
                <th className="py-4">{t("Actions")}</th>
              </tr>
              {getAllVideos(contentFile, category) &&
                getAllVideos(contentFile, category)
                  .sort((a: any, b: any) => a.order - b.order)
                  .map((item: any, index: number) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            className={`select-none w-full hover:bg-gray hover:bg-opacity-10 ${
                              snapshot.isDragging
                                ? " bg-red bg-opacity-20 w-[100%] table"
                                : "border-b border-lightBlack"
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {item.id}
                            </td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {item.title}
                            </td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {item.description}
                            </td>
                            <td
                              className={`py-4  ${
                                snapshot.isDragging ? "w-[20%]" : ""
                              }`}
                            >
                              {getCategoryName()}
                            </td>
                            <td
                              className={`${
                                snapshot.isDragging ? "w-[20%]" : ""
                              } grid justify-start gap-x-4 text-shadeBlue grid-flow-col py-4`}
                            >
                              <EditVideoModal video={item} />

                              <td className="py-4 grid justify-start grid-flow-col gap-x-4">
                                <ConfirmPopover
                                  title="Are you sure?"
                                  text="This will remove this video entirely"
                                  tooltip="Remove video"
                                  onConfirm={() => {
                                    const clonedObject = cloneDeep(contentFile);
                                    clonedObject.videos.splice(index, 1);
                                    setDraft(clonedObject);
                                  }}
                                  button={
                                    <TrashIcon className="text-shadeBlue" />
                                  }
                                />
                              </td>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
              {provided.placeholder}
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
