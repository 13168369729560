import { Link } from "react-router-dom";
import darkModeLogo from "../../assets/logo-gray.svg";
import logo from "../../assets/logo.svg";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { useThemeColor } from "../theme-color";

const Logo = () => {
  const { isDark } = useThemeColor();
  const { userEventLogger } = useEventLogger();

  return (
    <div className="px-2 md:px-0 grid items-center xl:mr-4">
      <Link to="/" onClick={() => userEventLogger(UserEvent.ONEAI_LOGO)}>
        <figure
          className="flex-shrink-0 md:min-w-[155px] max-w-[155px]"
          tabIndex={0}
        >
          <img
            src={isDark ? darkModeLogo : logo}
            className="h-[38px]"
            alt="Logo"
            data-cy="logo"
          />
        </figure>
      </Link>
    </div>
  );
};
export default Logo;
