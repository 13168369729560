import React from "react";
import { LabelsType } from "../../types";
import BucketsHighlighter from "./bucket-algorithm";
import { SummaryHighlighter } from "./summary-algorithm";

type HighlightType = {
  text: string;
  labels: LabelsType;
  input_text: string;
  hide_origin: boolean;
};

const HighlightText = ({
  text,
  labels,
  input_text,
  hide_origin,
}: HighlightType) => {
  const summaryOrigin = labels.every((label) => label.skill === "origin");

  const bucketsMemo = React.useMemo(() => {
    return (
      <BucketsHighlighter
        text={text ? text : ""}
        labels={labels ? labels : []}
      />
    );
  }, [labels, text]);

  return (
    <div className="text-darkGray dark:text-white md:text-sm text-md font-poppins font-medium dark:font-normal">
      {summaryOrigin && !hide_origin ? (
        <SummaryHighlighter
          text={text}
          labels={labels}
          input_text={input_text}
        />
      ) : (
        bucketsMemo
      )}
    </div>
  );
};

export default HighlightText;
