import { useRecoilValue } from "recoil";
import { apiResponseAtom } from "../pages/pipeline/pipeline-atoms";

export const useSummaryOrigin = () => {
  const apiResponse = useRecoilValue(apiResponseAtom);

  function isSummary(response?: any) {
    const newResponse = response ? response : apiResponse;
    let foundSummary = false;
    let summaryIndex = -1;

    for (let output = 0; output < newResponse?.output?.length; output++) {
      for (const label of newResponse?.output[output]?.labels) {
        if (label?.skill === "origin") {
          foundSummary = true;
          summaryIndex = output;
        }
      }
    }
    return { foundSummary, summaryIndex };
  }

  return { isSummary };
};
