import { useRecoilValue } from "recoil";
import { getOpenedAccordionArray } from "../pages/PipelinePage/utils";
import {
  accordionOpenedAtom,
  curApiAtom,
  curApiItemAtom,
  curApiTabAtom,
} from "./atoms";
import useIsSampleLibrary from "./use-is-sample-library";

export const useDocsParams = () => {
  const curApiItem = useRecoilValue<any>(curApiItemAtom);
  const curApi = useRecoilValue<any>(curApiAtom);
  const tabName = useRecoilValue<any>(curApiTabAtom);
  const accordion = useRecoilValue<any>(accordionOpenedAtom);

  const { isSampleLibrary } = useIsSampleLibrary();

  if (curApiItem)
    try {
      return (
        "/docs?api=" +
        curApi?.name.replaceAll(" ", "+") +
        "&item=" +
        curApiItem?.item_name.replaceAll(" ", "+") +
        "&tab=" +
        tabName.replaceAll(" ", "+") +
        "&accordion=" +
        getOpenedAccordionArray(accordion) +
        (isSampleLibrary ? "&textInput=sample-library" : "")
      );
    } catch (e) {
      return "/docs";
    }
  else return "/docs";
};
