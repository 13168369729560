import Editor, { useMonaco } from "@monaco-editor/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { IconCopySmall, IconTooltip } from "../../assets/SvgIcons";
import { useUserData } from "../../components/auth/data";
import { useThemeColor } from "../../components/theme-color";
import AppTooltip from "../../components/tooltip";
import { useExperiment, variantOne } from "../../lib/use-experiment";
import { useMonacoLanguage } from "../../lib/use-monaco-language";
import useRequestParams from "../../lib/use-request-params";
import { useSendCopyRequestEvent } from "../../lib/use-send-copy-request-event";
import monacoTheme from "../../utils/monaco-theme";
import SelectTechnology from "../PipelinePage/select-technology";
import {
  technologiesOptions,
  technologyToLanguage,
} from "../PipelinePage/utils";
import { apiResponseAtom, editorCodeTab } from "./pipeline-atoms";

type Props = {
  outputLength?: boolean;
  panelHeight: number | undefined;
  panelWidth: number | undefined;
};

export default function PipelineRequest({ panelHeight, panelWidth }: Props) {
  const upperPartRef: any = React.useRef(null);
  const [technology, setTechnology] = React.useState<any>(
    technologiesOptions[2].value,
  );
  const { getRequest } = useRequestParams();
  const { value, loading } = useUserData();

  React.useEffect(() => {
    const userPreferencesFromFirebase =
      value?.preferences?.programming_language;
    if (!userPreferencesFromFirebase) {
      setTechnology(technologiesOptions[2].value);
    } else setTechnology(userPreferencesFromFirebase);
    // eslint-disable-next-line
  }, [loading, value]);

  return (
    <>
      <SelectTechnologyUpper
        technology={technology}
        setTechnology={setTechnology}
        upperPartRef={upperPartRef}
      />
      <RequestEditor
        panelHeight={panelHeight}
        panelWidth={panelWidth}
        request={getRequest(technology, "PREVIEW") || ""}
        technology={technology}
      />
      <CopyToClipboardButton
        technology={technology}
        request={getRequest(technology, "REAL-REQUEST") || ""}
      />
    </>
  );
}

type SelectTechnologyUpperProps = {
  upperPartRef: any;
  technology: string;
  setTechnology: (value: string) => void;
};
function SelectTechnologyUpper({
  upperPartRef,
  technology,
  setTechnology,
}: SelectTechnologyUpperProps) {
  const location = useLocation();
  const isDocsGenerator = location.pathname === "/docs";
  const { getRequest } = useRequestParams();
  const [tab, setTab] = useRecoilState(editorCodeTab);

  React.useEffect(() => {
    if (isDocsGenerator) {
      setTab(true);
    }
  }, [isDocsGenerator, setTab]);

  return (
    <>
      <div
        className={`${
          isDocsGenerator ? "hidden" : ""
        } grid grid-cols-2 gap-x-2 h-[58px] w-full`}
      >
        <ResponseTab type="code" />
        <ResponseTab type="json" />
      </div>
      {tab ? (
        <div
          ref={upperPartRef}
          className={`${
            isDocsGenerator ? "pb-1" : "p-2 justify-items-end"
          } grid items-center grid-cols-1fr-auto-auto gap-x-2 rounded-t-md bg-white dark:bg-darkGrayBackground`}
        >
          <SelectTechnology
            selectOptions={technologiesOptions}
            technology={technology}
            setTechnology={setTechnology}
          />
          <CopyRequest
            outputLength={true}
            technology={technology}
            request={getRequest(technology, "REAL-REQUEST") || ""}
          />
        </div>
      ) : null}
    </>
  );
}

function ResponseTab({ type }: { type: string }) {
  const [tab, setTab] = useRecoilState(editorCodeTab);
  const { t } = useTranslation("index");
  const apiResponse = useRecoilValue(apiResponseAtom);

  const button = `grid h-full rounded-t-md text-center justify-center min-w-0 w-[100%]
  ${
    (type === "code" ? tab : !tab)
      ? "dark:bg-darkGrayBackground bg-white"
      : "bg-faWhite dark:bg-inputTabGrayDark dark:hover:bg-darkGrayBackground hover:bg-white"
  }`;

  const p = `self-center p-2 font-medium dark:font-normal font-poppins text-md xl2:text-lg ${
    (type === "code" ? tab : !tab)
      ? "dark:text-white text-black"
      : "text-inputGray font-medium"
  }`;

  const switchTab = () => {
    if (type === "code") {
      setTab(true);
    }
    if (type === "json" && apiResponse !== null) {
      setTab(false);
    }
  };

  const tooltip = (
    <button className={button} onClick={switchTab}>
      <p className={p}>
        {type === "code" ? t("Generated code") : t("JSON Response")}
      </p>
    </button>
  );

  return (
    <>
      {type === "json" && !apiResponse ? (
        <AppTooltip
          title={
            <div className="grid grid-cols-auto-1fr gap-x-3 items-center">
              <IconTooltip className={"text-blue"} />
              <span>{t("Please run a pipeline to get a JSON Response")}</span>
            </div>
          }
          children={tooltip}
        />
      ) : (
        tooltip
      )}
    </>
  );
}

type RequestEditorProps = {
  panelHeight: number | undefined;
  panelWidth: number | undefined;
  request: string;
  technology: string;
};
function RequestEditor({
  panelHeight,
  panelWidth,
  request,
  technology,
}: RequestEditorProps) {
  const editorRef: any = React.useRef(null);
  const monaco = useMonaco();
  const { isDark } = useThemeColor();
  const { sendCopyRequest } = useSendCopyRequestEvent();
  const apiResponse = useRecoilValue(apiResponseAtom);
  const tab = useRecoilValue(editorCodeTab);
  const [copyMethod, setMethod] = React.useState<boolean>(true);
  const { getMonacoLanguage } = useMonacoLanguage();

  function handleEditorDidMount(editor: any, monaco: any) {
    editorRef.current = editor;

    if (isDark) {
      monaco.editor.setTheme("onedark");
    } else {
      monaco.editor.setTheme("light");
    }
  }

  function checkCopy() {
    sendCopyRequest(technology, copyMethod ? "mouse" : "keyboard");
    setMethod(true);
  }

  function keyboardOrMouse(event: any) {
    const charCode = String.fromCharCode(event.which).toLowerCase();

    if ((event.ctrlKey || event.metaKey) && charCode === "c") {
      setMethod(false);
    }
  }

  function setEditorTheme(monaco: any) {
    monaco.editor.defineTheme("onedark", monacoTheme);
  }

  const getTechnology = () => {
    // Changes the language every time user changes the technology

    getMonacoLanguage();

    if (monaco && editorRef.current) {
      monaco.editor.setModelLanguage(
        editorRef.current.getModel(),
        technologyToLanguage(technology),
      );
    }
  };

  React.useEffect(() => {
    getTechnology();
    //eslint-disable-next-line
  }, [technology]);

  React.useEffect(() => {
    if (editorRef.current && monaco) {
      if (!tab) {
        monaco.editor.setModelLanguage(editorRef.current.getModel(), "json");
      } else {
        getTechnology();
      }
    }
    //eslint-disable-next-line
  }, [tab]);

  return (
    <div onKeyDown={keyboardOrMouse} onCopy={checkCopy}>
      <Editor
        className="rounded-md"
        defaultLanguage={"javascript"}
        height={panelHeight}
        options={{
          readOnly: true,
          scrollbar: {
            vertical: "visible",
            verticalScrollbarSize: 6,
          },
          autoIndent: "full",
          tabSize: 4,
          formatOnPaste: true,
          selectOnLineNumbers: true,
          minimap: { enabled: false },
        }}
        onChange={(editor: any, monaco: any) => {
          monaco.editor.setModelLanguage(editor.getModel(), "python");
          console.log("Language changed to :: ", "python");
        }}
        theme={isDark ? "onedark" : "light"}
        width={panelWidth}
        onMount={handleEditorDidMount}
        value={tab ? request : JSON.stringify(apiResponse, null, "  ")}
        beforeMount={setEditorTheme}
      />

      <style>
        {` 
          .monaco-editor .monaco-scrollable-element .scrollbar.vertical {
            border-radius: 100vh;
            background: ${isDark ? "#333333" : "#F4F6F8"};
          }

          .monaco-scrollable-element > .scrollbar > .slider {
            border-radius: 100vh;
            background: ${isDark ? "#474747" : "#D8DEE6"} !important;
          }
        `}
      </style>
    </div>
  );
}

type CopyRequestProps = {
  outputLength: boolean;
  request: string;
  technology: string;
};

function CopyRequest({ outputLength, request, technology }: CopyRequestProps) {
  const { t } = useTranslation("index");
  const [requestCopied, setRequestCopied] = React.useState<boolean>(false);
  const { sendCopyRequest } = useSendCopyRequestEvent();
  const handleCopyRequest = async () => {
    console.debug(
      "[handleCopyRequest] before the function call: ",
      technology,
      "request, ",
      request,
    );

    await sendCopyRequest(technology, "icon button", request);

    console.debug("[handleCopyRequest] after the function call");

    setRequestCopied(true);
  };
  const { variant, isParticipating } = useExperiment();
  const preventCopyMessage = isParticipating && variant === variantOne;

  return (
    <AppTooltip
      title={
        requestCopied && !preventCopyMessage ? (
          <div className="grid grid-cols-auto-1fr gap-x-3 items-center">
            <IconTooltip className={"text-blue"} />
            <span>{t("Code copied to clipboard")}</span>
          </div>
        ) : !outputLength ? (
          t("Please run a pipeline to copy code")
        ) : (
          t("Copy generated code")
        )
      }
      arrow={!requestCopied}
      leaveDelay={0}
      onClose={() => {
        setTimeout(() => {
          setRequestCopied(false);
        }, 200);
      }}
    >
      <button
        className={`relative`}
        id="generated_code_copy_button"
        onClick={handleCopyRequest}
      >
        <figure>
          <IconCopySmall width="17px" height="17px" className={`text-blue `} />
        </figure>
      </button>
    </AppTooltip>
  );
}

function CopyToClipboardButton({
  request,
  technology,
}: {
  request: string;
  technology: string;
}) {
  const { t } = useTranslation("index");
  const [requestCopied, setRequestCopied] = React.useState<boolean>(false);
  const { sendCopyRequest } = useSendCopyRequestEvent();
  const tab = useRecoilValue(editorCodeTab);
  const apiResponse = useRecoilValue(apiResponseAtom);
  const { variant, isParticipating } = useExperiment();

  const handleCopyRequest = async () => {
    console.debug(
      "[handleCopyRequest]  before the function call, technology: ",
      technology,
      "request, ",
      request,
    );
    await sendCopyRequest(
      technology,
      "button",
      tab ? request : JSON.stringify(apiResponse, null, "  "),
    );
    console.debug("[handleCopyRequest] after the function call");
    setRequestCopied(true);
  };

  const preventCopyMessage = isParticipating && variant === variantOne;

  return (
    <div className="absolute bottom-0 right-0 grid justify-end bg-transparent p-4 pt-2">
      <AppTooltip
        onClose={() => {
          setTimeout(() => {
            setRequestCopied(false);
          }, 500);
        }}
        title={
          requestCopied && !preventCopyMessage ? (
            <div className="grid grid-cols-auto-1fr gap-x-3 items-center">
              <IconTooltip className={"text-blue"} />
              <span>
                {tab
                  ? t("Code copied to clipboard")
                  : t("JSON copied to clipboard")}
              </span>
            </div>
          ) : (
            ""
          )
        }
      >
        <button
          id="generated_code_copy_to_clipboard_button"
          className={`items-center grid grid-cols-1fr-auto gap-x-2 border border-blue dark:border-turbo rounded-md py-2 px-4 text-blue dark:text-turbo text-sm font-mono bg-white dark:bg-darkGrayBackground`}
          onClick={handleCopyRequest}
        >
          {tab ? t("Copy Code") : t("Copy to Clipboard")}
          <figure>
            <IconCopySmall
              className="text-blue dark:text-turbo"
              height="17px"
              width="18px"
            />
          </figure>
        </button>
      </AppTooltip>
    </div>
  );
}
