import React, { useCallback } from "react";
import "./docs-style.css";
import { useThemeColor } from "../../components/theme-color";
import { CodeBlock } from "react-code-blocks";
import codeTheme from "./code-theme";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { curApiTabAtom } from "../../lib/atoms";
import { useSearchParams } from "react-router-dom";
import PipelineRequest from "../pipeline/pipeline-request";
import { useResizeDetector } from "react-resize-detector";

export const tabNames = ["Code Generator", "Example Responses"];

export default function APICodeSamplePanel({ item }: any) {
  const [tabName, setTabName] = useRecoilState<any>(curApiTabAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isDark } = useThemeColor();
  const { width, height, ref } = useResizeDetector();

  const handleTabChange = useCallback(
    (tabName: any) => {
      const currentParams = Object.fromEntries(searchParams);
      setSearchParams({
        ...currentParams,
        ...(tabName && { tab: tabName }),
      });
      setTabName(tabName);
    },

    [setSearchParams, setTabName, searchParams],
  );

  const { t } = useTranslation("docs");

  return (
    <div>
      <div
        className="h-[60px] relative grid grid-flow-col items-center gap-x-2 dark:text-white font-poppins text-black font-medium text-lg w-full"
        data-pane="right"
      >
        <button
          onClick={() => handleTabChange(tabNames[0])}
          className={`h-full rounded-t-md ${
            tabName === tabNames[0] || !item?.response
              ? "dark:bg-steelGray bg-white"
              : "dark:bg-tabDarkGray bg-faSmoke"
          }`}
        >
          <span className="font-light">{t(tabNames[0])}</span>
        </button>
        {item?.response ? (
          <button
            onClick={() => handleTabChange(tabNames[1])}
            className={`h-full rounded-t-md ${
              tabName === tabNames[1]
                ? "dark:bg-steelGray bg-white"
                : "dark:bg-tabDarkGray bg-faSmoke"
            }`}
          >
            <span className="font-light">{t(tabNames[1])}</span>
          </button>
        ) : (
          <button className={`h-full`}>
            <span className="text-transparent cursor-auto">
              {t(tabNames[1])}
            </span>
          </button>
        )}
      </div>
      <div
        className="pt-3 px-3 text-black dark:text-white overflow-y-auto text-xs dark:bg-steelGray bg-white rounded-b-md font-mono h-[calc(100%-60px)]"
        dir="ltr"
      >
        <>
          {(tabName === tabNames[0] || !item?.response) && (
            <div className="relative h-full overflow-y-hidden pb-4" ref={ref}>
              <PipelineRequest panelHeight={height} panelWidth={width} />
            </div>
          )}
          {tabName === tabNames[1] && item && item.response && (
            <CodeBlock
              language={"ts"}
              text={item?.response}
              showLineNumbers={false}
              theme={codeTheme(isDark)}
            />
          )}
        </>
      </div>
    </div>
  );
}
