import React from "react";
import { useSearchParams } from "react-router-dom";
import Split from "react-split";
import ReactSwipe from "react-swipe";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  accordionOpenedAtom,
  curApiAtom,
  curApiItemAtom,
  curApiTabAtom,
} from "../lib/atoms";
import { useEventLogger } from "../lib/event_logger";
import { useStudioData } from "../lib/use-studio-data";
import APICodeSamplePanel, { tabNames } from "./docs/api-code-sample-panel";
import APIDocumentPanel from "./docs/api-document-panel";
import APIListPanel from "./docs/api-list-panel";
import { PreviewLoader, useIsMobile } from "./PipelinePage/utils";

export default function DocsPage() {
  const { userUIEventLogger } = useEventLogger();
  const [curApiItem, setCurApiItem] = useRecoilState<any>(curApiItemAtom);
  const [accordionOpened, setAccordionOpened] =
    useRecoilState<any>(accordionOpenedAtom);
  const [curApi, setCurApi] = useRecoilState<any>(curApiAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const splitRef = React.useRef<any>();
  const panelRef = React.useRef<any>();

  const setTabName = useSetRecoilState<any>(curApiTabAtom);
  const isMobile = useIsMobile();
  const isOnItem = curApiItem !== null;

  const { data, loading } = useStudioData();

  const sortedDocumentationSkills = (categoryNamesArray: any) => {
    let sortingArray: any = data?.skills_order;
    let documentationSkillsArray: any = categoryNamesArray;
    let finalArray: any = [];

    sortingArray.forEach((el: any) => {
      const alreadyExists = finalArray?.find((e: any) => e?.item_name === el);
      if (!alreadyExists)
        finalArray.push(
          documentationSkillsArray?.find((e: any) => e.item_name === el),
        );
    });
    const retArray = finalArray.filter(function (element: any) {
      return element !== undefined;
    });

    // Add last and not sorted skills to end of the array
    documentationSkillsArray.forEach((el: any) => {
      if (!retArray.includes(el)) {
        retArray.push(el);
      }
    });

    return retArray;
  };

  React.useEffect(() => {
    const savedState = setDocumentation();
    if (
      !savedState?.API &&
      !savedState?.tabName &&
      !savedState?.item &&
      !savedState?.accordion
    ) {
      setSearchParams({
        ...(curApi?.name && { api: curApi?.name }),
        ...(curApiItem?.item_name && { item: curApiItem?.item_name }),
        ...(savedState?.tabName && { tab: savedState?.tabName }),
        ...(savedState?.accordion && { accordion: savedState?.accordion }),
      });
    }

    //eslint-disable-next-line
  }, []);

  function setDocumentation() {
    let API = searchParams.get("api");
    let tabName = searchParams.get("tab");
    let item = searchParams.get("item");
    let accordion = searchParams.get("accordion");

    if (API) {
      const currentAPI: any = data?.documentation?.find(
        (apiGroup: any) => apiGroup?.name === API,
      );

      if (currentAPI) {
        if (!item && !isMobile) {
          setCurApiItem(currentAPI.items[0]);

          setAccordionOpened((accordionOpened: any) => ({
            ...accordionOpened,
            [currentAPI.name]: true,
          }));
        }

        setCurApi(currentAPI);
        console.log("⭐ ~ setDocumentation ~ currentAPI", currentAPI);
      } else {
        console.log("Could not find API name");
      }
    }

    if (item && !loading) {
      const apiIndex: any = data?.documentation?.findIndex(
        (api: any) => api.name === API,
      );

      const retItem: any = data?.documentation[apiIndex]?.items?.find(
        (currentSkill: any) => {
          return currentSkill?.item_name === item;
        },
      );
      if (retItem && !loading) {
        setCurApiItem(retItem);
      } else {
        if (!isMobile) {
          setCurApiItem(data?.documentation[apiIndex]?.items[0]);
        }
      }
    }
    if (tabName) {
      if (tabNames.includes(tabName)) {
        setTabName(tabName);
      }
    }
    if (curApiItem === null && !item && !isMobile && !loading) {
      for (let apiGroup of data?.documentation) {
        for (let api of sortedDocumentationSkills(apiGroup.items)) {
          setCurApiItem(api);
          return;
        }
      }
    }

    if (accordion) {
      const newObj: any = {};
      accordion.split(",").forEach((el: any) => {
        if (accordion !== null) newObj[el] = true;
      });
      setAccordionOpened(newObj);
    }

    return {
      API,
      item,
      tabName,
      accordion,
    };
  }

  React.useEffect(() => {
    setDocumentation();
    //eslint-disable-next-line
  }, [searchParams]);

  React.useEffect(() => {
    let API = searchParams.get("api");
    let accordion = searchParams.get("accordion");
    if (!API && !accordion) {
      const defaultOpenIndex: any = data?.documentation?.findIndex(
        (api: any) => api.default_open === true,
      );
      if (defaultOpenIndex !== undefined && defaultOpenIndex !== -1) {
        setAccordionOpened((accordionOpened: any) => ({
          ...accordionOpened,
          [data?.documentation[defaultOpenIndex]?.name]: true,
        }));
      }
    }

    //eslint-disable-next-line
  }, []);
  let reactSwipeEl: any;

  React.useEffect(() => {
    if (reactSwipeEl) {
      if (isOnItem) {
        reactSwipeEl.next();
      } else {
        reactSwipeEl.prev();
      }
    }
    //eslint-disable-next-line
  }, [isOnItem]);

  const handleClickEvents = (ev: any) => {
    const api = searchParams.get("api")
      ? searchParams.get("api")
      : "Introduction";
    const item = searchParams.get("item")
      ? searchParams.get("item")
      : "Overview";

    const tab =
      ev.target.closest("[data-pane]")?.dataset.pane === "right"
        ? ev.target?.closest("button")?.innerText
        : false;

    const section =
      ev.target.closest("[data-pane]")?.dataset.pane === "left"
        ? ev.target?.closest("p")?.innerHTML
        : false;

    const page =
      ev.target.closest("[data-pane]")?.dataset.pane === "left"
        ? ev.target?.closest("span")?.innerHTML
        : false;

    const component = `${api}_${item}`;

    let text = "";
    if (section) text = `navigation_${section}`;
    else if (page) text = `navigation_${page}`;
    else if (tab) text = `output_tab_${tab}`;
    if (text === "") return;

    const value = `docs__${component}__${text}`;
    userUIEventLogger(value);
  };

  if (loading) {
    return <PreviewLoader />;
  }

  return isMobile ? (
    <>
      <div className="h-full overflow-y-auto">
        <ReactSwipe
          className="carousel"
          swipeOptions={{ continuous: false, disableScroll: true }}
          ref={(el: any) => (reactSwipeEl = el)}
        >
          <div ref={panelRef}>
            <APIListPanel
              data={data}
              setCurApiItem={setCurApiItem}
              accordionOpened={accordionOpened}
              setAccordionOpened={setAccordionOpened}
            />
          </div>

          <div>
            <APIDocumentPanel
              api={curApi}
              item={curApiItem}
              panelRef={panelRef}
            />
          </div>
        </ReactSwipe>
        <div className="grid grid-flow-row gap-y-3 py-4">
          <APICodeSamplePanel api={curApi} item={curApiItem} />
        </div>
      </div>
    </>
  ) : (
    <div
      className="w-full h-full min-w-[1000px] overflow-x-auto pb-4"
      onClick={handleClickEvents}
    >
      <Split
        className={`flex flex-row h-full mt-0 overflow-x-hidden overflow-y-clip w-full`}
        gutterSize={2}
        sizes={[25, 50, 25]}
        minSize={[10, 10, 10]}
        maxSize={1200}
        snapOffset={0}
        ref={splitRef}
      >
        <APIListPanel
          data={data}
          setCurApiItem={setCurApiItem}
          accordionOpened={accordionOpened}
          setAccordionOpened={setAccordionOpened}
        />
        <APIDocumentPanel api={curApi} item={curApiItem} />
        <APICodeSamplePanel
          api={curApi}
          item={curApiItem}
          accordionOpened={accordionOpened}
        />
      </Split>
    </div>
  );
}
