import { useRecoilValue } from "recoil";
import { previewModeAtom } from "../lib/atoms";
import { useExperiment } from "../lib/use-experiment";
import useLocationParams from "../lib/use-location-params";
import AppModal from "./app-modal";
import AuthForms from "./auth/auth-forms";

function BlurScreen() {
  // Hooks
  const isPreviewMode = useRecoilValue(previewModeAtom);
  const { reachedLimit, isParticipating } = useExperiment();
  const {
    isLoginModalOpen,
    anonymousTryOut,
    anonymouseProtectedAPIKeyGeneration,
    registrationToken,
    forgotPasswordToken,
    completedSignup,
    formToShow,
  } = useLocationParams();

  return (
    <>
      <AppModal
        isOpen={isLoginModalOpen}
        setIsOpen={() => {}}
        shouldCloseOnOverlayClick={false}
        showCloseButton={
          (anonymousTryOut && isParticipating && !reachedLimit) ||
          (anonymousTryOut && !isParticipating) ||
          anonymouseProtectedAPIKeyGeneration ||
          registrationToken ||
          forgotPasswordToken
        }
        style={{
          overlay: {
            backdropFilter: "blur(2px)",
            WebkitBackdropFilter: "blur(2px)",
          },
        }}
      >
        <AuthForms
          userCompletedSignup={completedSignup}
          formToShow={formToShow}
          setIsOpen={() => {}}
        />
      </AppModal>
      {isPreviewMode && (
        <div className="fixed top-0 w-full h-5 bg-black border-t border-black !z-[10001]">
          <p className="text-center grid items-center text-red text-xs font-mono font-bold">
            You are currently running the studio on preview mode
          </p>
        </div>
      )}
    </>
  );
}

export default BlurScreen;
