import React from "react";
import { useRecoilValue } from "recoil";
import {
  SkillCategoriesType,
  SkillsType,
  TableColumnType,
} from "../../lib/interfaces";
import { theme } from "../../tailwind.config";
import { LabelType } from "../PipelinePage/types";
import {
  capitalizeFirstCharacter,
  skillValueToName,
} from "../PipelinePage/utils";
import { nColsAtom } from "./pipeline-dnd";
import { Output } from "./pipeline-output-panel";

export const useLabelTypeExists = (labels: Array<LabelType>) => {
  const labelTypeExists = React.useMemo(() => {
    const isEmotionExists = labels?.some(
      (label: any) => label.skill === "emotions",
    );
    const isBusinessEntitiesExists = labels?.some(
      (label: any) => label.skill === "business-entities",
    );
    const isNamesExists = labels?.some((label: any) => label.skill === "names");
    const isEntityExists = labels?.some(
      (label: any) => label.skill === "entities",
    );
    const isHtmlExtractArticleExists = labels?.some(
      (label: any) => label.skill === "html-extract-article",
    );
    const isHtmlExtractTextExists = labels?.some(
      (label: any) => label.skill === "html-extract-text",
    );
    const isHighlightExists = labels?.some(
      (label: any) => label.skill === "highlights",
    );
    const isKeywordExists = labels?.some(
      (label: any) => label.skill === "keywords",
    );
    const isEnhanceSkillExists = labels?.some(
      (label: any) => label.skill === "enhance",
    );
    const isSentenceExists = labels?.some(
      (label: any) => label.skill === "sentences",
    );
    const isTopicsExists = labels?.some(
      (label: any) => label.skill === "article-topics" && label.value !== "-1",
    );
    const onlyTopicsExists = labels?.every(
      (label: any) => label.skill === "article-topics" && label.value !== "-1",
    );
    const isSentimentExists = labels?.every(
      (label: any) => label.skill === "sentiments",
    );

    const isActionItemsExists = labels?.every(
      (label: any) => label.skill === "action-items",
    );
    const isTypeSalesInsights = labels?.every(
      (label: any) => label.skill === "sales-insights",
    );

    const isTopicExistsWithOrWithoutOrigin = labels?.some(
      (label: any) =>
        (label.skill === "origin" && label.skill === "article-topics") ||
        label.skill === "article-topics",
    );

    const allExceptTopics = labels?.some(
      (label: any) =>
        label.skill === "sentiments" ||
        label.skill === "enhance" ||
        label.skill === "html-extract-article" ||
        label.skill === "html-extract-text" ||
        label.skill === "emotions" ||
        label.skill === "entities" ||
        label.skill === "highlights" ||
        label.skill === "keywords" ||
        label.skill === "sentences",
    );

    const showValueColumn =
      isEntityExists ||
      isHtmlExtractArticleExists ||
      isHtmlExtractTextExists ||
      isHighlightExists ||
      isKeywordExists ||
      isEnhanceSkillExists ||
      isEmotionExists ||
      isSentenceExists ||
      isTopicsExists ||
      isActionItemsExists ||
      isSentimentExists ||
      isBusinessEntitiesExists ||
      isTypeSalesInsights ||
      isNamesExists;

    const showSpanColumn = !onlyTopicsExists;

    const showScoreColumn = () => false; //isEmotionExists;

    let exportToCSVArray: any = [];
    // Here we are pushing the values we want into the array so we can use it for CSV.

    function labelsCSVData() {
      labels?.forEach((label: any) => {
        // These are just shortcuts for label types.
        const isTypeTranscriptionEnhancer = label.skill === "enhance";
        // const isTypeBusinessEntity = label.skill === "business-entities";
        const isTypeEmotion = label.skill === "emotions";
        const isTypeEntity = label.skill === "entities";
        const isTypeHtmlExtractArticle = label.skill === "html-extract-article";
        const isTypeHtmlExtractText = label.skill === "html-extract-text";
        // const isTypeActionItems = label.skill === "action-items";
        const isTypeHighlight = label.skill === "highlights";
        const isTypeKeyword = label.skill === "keywords";
        const isTypeSentence = label.skill === "sentences";
        const isTypeTopic =
          label.skill === "article-topics" && label?.value !== -1;
        const isTypeSentiment = label.skill === "sentiments";

        // This will help us get the original text for transcription enhancer.
        let spanLabel;
        let replacedText;
        if (!label.span) {
          spanLabel = "";
          replacedText = "";
        } else {
          spanLabel = `${label?.span[0]},${label?.span[1]}`;
          replacedText = "test"?.slice(label.span[0], label.span[1]);
        }
        let value = isTypeEmotion ? replacedText : `${label.value}`;
        const score = isTypeEmotion ? `${label.value}`.slice(0, 4) : null;

        // Here we are pushing the values we want into the array so we can use it for CSV.
        exportToCSVArray.push({
          Skill: label?.skill,
          ...(showSpanColumn && { Span: spanLabel }),
          Label:
            isTypeTranscriptionEnhancer || isTypeSentiment
              ? value
              : isTypeSentence ||
                isTypeHighlight ||
                isTypeKeyword ||
                isTypeTopic
              ? ""
              : capitalizeFirstCharacter(label?.name),
          ...(showValueColumn && {
            Value:
              isTypeTranscriptionEnhancer || isTypeEmotion
                ? replacedText
                : label?.value,
          }),
          ...(showScoreColumn() && {
            Score: isTypeEmotion
              ? score
              : isTypeTranscriptionEnhancer ||
                isTypeEntity ||
                isTypeHtmlExtractArticle ||
                isTypeHtmlExtractText ||
                isTypeSentence
              ? ""
              : value,
          }),
        });
      });

      return exportToCSVArray;
    }
    return {
      isEmotionExists,
      isBusinessEntitiesExists,
      isEntityExists,
      isHtmlExtractArticleExists,
      isHtmlExtractTextExists,
      isHighlightExists,
      isKeywordExists,
      isEnhanceSkillExists,
      isSentenceExists,
      isTopicsExists,
      onlyTopicsExists,
      isSentimentExists,
      isTopicExistsWithOrWithoutOrigin,
      allExceptTopics,
      isActionItemsExists,

      // Columns show conditions
      showScoreColumn,
      showSpanColumn,
      showValueColumn,
      labelsCSVData,
    };
  }, [labels]);

  return labelTypeExists;
};

export const sentimentValue = (value: string) =>
  value === "NEG" ? "Negative" : "Positive";

export const isLabelType = (label: LabelType) => {
  const isTypeTranscriptionEnhancer = label.skill === "enhance";
  const isTypeBusinessEntity = label.skill === "business-entities";
  const isTypeEmotion = label.skill === "emotions";
  const isTypeEntity = label.skill === "entities";
  const isTypeHtmlExtractArticle = label.skill === "html-extract-article";
  const isTypeHtmlExtractText = label.skill === "html-extract-text";
  const isTypeActionItems = label.skill === "action-items";
  const isTypeHighlight = label.skill === "highlights";
  const isTypeKeyword = label.skill === "keywords";
  const isTypeSentence = label.skill === "sentences";
  const isTypeTopic = label.skill === "article-topics" && label?.value !== "-1";
  const isTypeSentiment = label.skill === "sentiments";
  const isTypeSalesInsights = label.skill === "sales-insights";
  const isTypeNames = label.skill === "names";

  return {
    isTypeTranscriptionEnhancer,
    isTypeBusinessEntity,
    isTypeEmotion,
    isTypeEntity,
    isTypeHtmlExtractArticle,
    isTypeHtmlExtractText,
    isTypeActionItems,
    isTypeHighlight,
    isTypeKeyword,
    isTypeSentence,
    isTypeTopic,
    isTypeSentiment,
    isTypeSalesInsights,
    isTypeNames,
  };
};

export const tableLabelValue = (
  label: LabelType,
  text: string,
  column: TableColumnType,
) => {
  const _label = isLabelType(label);

  let spanLabel;
  let replacedText;
  if (!label.span) {
    spanLabel = "";
    replacedText = "";
  } else {
    spanLabel = `${label?.span[0]},${label?.span[1]}`;
    replacedText = text?.slice(label.span[0], label.span[1]);
  }
  let value = _label.isTypeEmotion ? replacedText : `${label.value}`;
  const score = _label.isTypeEmotion ? `${label.value}`.slice(0, 4) : null;

  if (column === "Skill") {
    if (label?.skill === "keywords") {
      return skillValueToName("Keyword");
    }

    return skillValueToName(label?.skill || "");
  } else if (column === "Label") {
    if (_label.isTypeSentiment) {
      return sentimentValue(value);
    } else if (_label.isTypeTranscriptionEnhancer) {
      return value;
    } else {
      if (label?.name) {
        return capitalizeFirstCharacter(label?.name);
      } else {
        if (label?.value) {
          return label?.value;
        }
      }
    }
  } else if (column === "Value") {
    if (_label.isTypeEmotion) {
      return value;
    } else if (_label.isTypeSentiment) {
      return label?.span_text;
    } else if (_label.isTypeTranscriptionEnhancer) {
      return replacedText;
    } else if (
      (_label.isTypeHighlight ||
        _label.isTypeKeyword ||
        _label.isTypeNames ||
        _label.isTypeSentence ||
        _label.isTypeEntity ||
        _label.isTypeHtmlExtractArticle ||
        _label.isTypeHtmlExtractText ||
        _label.isTypeTopic) &&
      label?.data?.value
    ) {
      return label?.data?.value;
    } else {
      return label.value;
    }
  } else if (column === "Span") {
    return spanLabel;
  } else if (column === "Score" && false) {
    return score;
  }
};

export const HalfWidthControlWrapper = ({
  children,
  className,
  type = "couple",
}: any) => {
  const nCols = useRecoilValue(nColsAtom);

  let calculatedClassName = "";
  if (type === "single") {
    calculatedClassName =
      nCols === 4
        ? "lg:grid-cols-2"
        : nCols === 3
        ? "w-2/3"
        : nCols === 2
        ? "w-4/5"
        : "";
  } else if (type === "couple") {
    calculatedClassName =
      nCols === 4
        ? "lg:grid-cols-2"
        : nCols === 3
        ? "grid-rows-2 w-2/3"
        : nCols === 2
        ? "grid-rows-2 w-4/5"
        : "";
  }

  return (
    <div
      className={`bg-white dark:bg-darkGrayBackground w-full px-4 pt-4 ${className}`}
    >
      <div className="max-w-screen-lg">
        <div className={`grid items-center gap-y-3 ${calculatedClassName} `}>
          {children}
        </div>
      </div>
    </div>
  );
};

export function generateOutputLabels(uniques: any) {
  const labels: any = {};
  Object.keys(uniques)?.forEach((key: string) => {
    labels[key] = true;
  });
  return labels;
}

export function getOnlyActiveLabels(output: Output, activeLabels: any) {
  const filteredOutput: any = { ...output };

  filteredOutput.labels = output?.labels?.filter((label: any) => {
    return activeLabels[label.skill];
  });
  return filteredOutput;
}

export function isActiveSkill(
  activeLabels: any,
  skill: SkillsType,
): "TRUE" | "FALSE" | "NOT_EXISTS" {
  return activeLabels[skill?.value]
    ? "TRUE"
    : activeLabels[skill?.value] === undefined
    ? "NOT_EXISTS"
    : "FALSE";
}

type CategoryColorType = "CATEGORY-TEXT" | "SKILL-BORDER" | "INACTIVE";

export const categoryColor = (
  categoryNameOrID: string | number,
  type: CategoryColorType,
  contentFile: any,
  isError?: any,
): React.CSSProperties => {
  if (isError) {
    return { borderLeftColor: theme.colors.error };
  }
  if (type === "INACTIVE") return { borderLeftColor: theme.colors.graylogo };
  const category: SkillCategoriesType | undefined =
    type === "CATEGORY-TEXT"
      ? contentFile?.skill_categories?.find(
          (cat: any) => cat.category_name === String(categoryNameOrID),
        )
      : contentFile?.skill_categories?.find(
          (cat: any) => cat.id === Number(categoryNameOrID),
        );

  if (category?.color) {
    return {
      ...(type === "CATEGORY-TEXT" && { color: category?.color }),
      ...(type === "SKILL-BORDER" && { borderLeftColor: category?.color }),
    };
  }
  return {
    ...(type === "CATEGORY-TEXT" && { color: "#FFF" }),
    ...(type === "SKILL-BORDER" && { borderLeftColor: "#FFF" }),
  };
};

export const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  borderRadius: "2px",
  ...draggableStyle,
});

export const getListStyle = (isDraggingOver: boolean) => ({
  borderRadius: "5px",
  background: isDraggingOver ? "rgba(255,255,255,0.01)" : "",
});
