import axios from "axios";

let BASE_URL = "/";

if (process.env.REACT_APP_API_SERVER) {
  BASE_URL = process.env.REACT_APP_API_SERVER;
}

const api = axios.create({
  baseURL: BASE_URL,
});

export const clustersAPI = axios.create({
  baseURL: BASE_URL + "/clustering/v1/collections",
});

export default api;
