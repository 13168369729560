import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function DocumentDirChanger() {
  const { i18n } = useTranslation("", { useSuspense: false });
  useEffect(() => {
    document.dir = i18n.dir(document.documentElement.lang);
  }, [i18n, i18n.language]);
  return null;
}

export default DocumentDirChanger;
