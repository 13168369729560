import React from "react";
import { useTranslation } from "react-i18next";
import "./find-origins.css";

export default function FindOrigin({
  findOriginsChecked,
  setFindOriginsChecked,
  setDefaultParams,
}: any) {
  const { t } = useTranslation("index");
  const handleOnChange = () => {
    setFindOriginsChecked(!findOriginsChecked);
    setDefaultParams();
  };

  return (
    <div className="py-2 pb-4">
      <label className="flex items-center" htmlFor="find-origins-checkbox">
        <input
          type="checkbox"
          className={"checkbox"}
          name="find-origins-checkbox"
          id="find-origins-checkbox"
          checked={findOriginsChecked}
          onChange={() => {
            handleOnChange();
          }}
        />
        <span className="text-sm">{t("Find Origins")}</span>
      </label>
    </div>
  );
}
