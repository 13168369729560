import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as React from "react";
import theme from "../theme";

interface OneAITooltipProps {
  title: string | React.ReactNode;
  children: React.ReactElement;
  arrow?: boolean;
  [x: string]: any;
}

export default function FeedbackTooltip({
  title,
  children,
  arrow = true,
  ...props
}: OneAITooltipProps) {
  return (
    <StyledTooltip title={title} {...props} arrow={arrow}>
      {children}
    </StyledTooltip>
  );
}

const StyledTooltip = styled(
  ({ className, arrow, noBackground, ...props }: any) => (
    <Tooltip {...props} arrow={arrow} classes={{ popper: className }} />
  ),
)({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.modalDark,
    borderTopRadius: "5px !important",
    borderRightRadius: "5px !important",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.modalDark,
    color: theme.colors.white,
    fontSize: theme.fontSize.xs,
    fontFamily: theme.fontFamily["poppins"][0],
    padding: theme.padding[0.5],
    marginTop: "0 !important",
  },
});
