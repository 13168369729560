import { logEvent } from "firebase/analytics";
import mixpanel from "mixpanel-browser";
import platform from "platform";
import { isDesktop, isTablet } from "react-device-detect";
import { useRecoilValueLoadable } from "recoil";
import Cookies from "universal-cookie";
import { firebaseAnalytics, segmentAnalytics } from "../components/auth/auth";
import api from "../services/api";
import { userDataAtom } from "./atoms";

function uuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const session_id = uuid();

enum UserEvent {
  RUN_PIPELINE = "RUN_PIPELINE",
  RUN_PIPELINE_CUSTOM = "RUN_PIPELINE_CUSTOM",
  RUN_PIPELINE_SAMPLE = "RUN_PIPELINE_SAMPLE",
  RUN_PIPELINE_SHARE = "RUN_PIPELINE_SHARE",
  LOGIN = "LOGIN",
  SIGNUP = "SIGNUP",
  USER_CREATED = "USER_CREATED",
  API_KEY_GENERATION = "API_KEY_GENERATION",
  SAMPLE_SELECTION = "SAMPLE_SELECTION",
  EXPAND_SKILL_LIBRARY = "EXPAND_SKILL_LIBRARY",
  INSERT_OR_PASTE_TEXT = "INSERT_OR_PASTE_TEXT",
  INPUT_TYPE_SELECTION = "INPUT_TYPE_SELECTION",
  SKILL_ADD = "SKILL_ADD",
  SKILL_REMOVE = "SKILL_REMOVE",
  COPY_REQUEST = "COPY_REQUEST",
  USER_MENU_OPEN = "USER_MENU_OPEN",
  API_KEYS_USER_MENU = "API_KEYS_USER_MENU",
  ADD_API_KEY = "ADD_API_KEY",
  SEARCH = "SEARCH",
  USAGE_QUOTA_SETTINGS = "USAGE_QUOTA_SETTINGS",
  TOGGLE_THEME_USER_MENU = "TOGGLE_THEME_USER_MENU",
  COPY_RESPONSE = "COPY_RESPONSE",
  EXPORT_TO_CSV = "EXPORT_TO_CSV",
  GO_BACK_TO_STUDIO = "GO_BACK_TO_STUDIO",
  ONEAI_LOGO = "ONEAI_LOGO",
  RUN_SAMPLE_BUTTON = "RUN_SAMPLE_BUTTON",
  USER_COOKIE_CONSENT = "USER_COOKIE_CONSENT",
  ERROR = "ERROR",
  SEND_QUOTA_REQUEST = "SEND_QUOTA_REQUEST",
  PAGE_LOAD = "PAGE_LOAD",
  DAILY_VISIT = "DAILY_VISIT",
  SWITCH_INPUT_TAB = "SWITCH_INPUT_TAB",
  SELECT_LIBRARY_SAMPLE = "SELECT_LIBRARY_SAMPLE",
  CODE_GENERATOR_LANG_SELECT = "CODE_GENERATOR_LANG_SELECT",
  LABEL_VISIBILITY_TOGGLE_ON = "LABEL_VISIBILITY_TOGGLE_ON",
  LABEL_VISIBILITY_TOGGLE_OFF = "LABEL_VISIBILITY_TOGGLE_OFF",
  PIPELINE_SUCCESS = "PIPELINE_SUCCESS",
  SHARE_BUTTON = "SHARE_BUTTON",
  OPEN_SHARE_LINK = "OPEN_SHARE_LINK",
  NAVIGATE = "NAVIGATE",
  DOCS_SEARCH = "DOCS_SEARCH",
  DOCS_PAGE = "DOCS_PAGE",
  VIDEO_PLAY = "VIDEO_PLAY",
  CLICK = "CLICK",
  SIGNUP_ANY_EMAIL_BUTTON = "SIGNUP_ANY_EMAIL_BUTTON",
  ANALYTICS_COLLECTION_SEARCH = "ANALYTICS_COLLECTION_SEARCH",
  CLICKED_UPGRADE = "CLICKED_UPGRADE",
  UPGRADED = "UPGRADED",
  INPUT_CONTENT = "INPUT_CONTENT",
  FILE_UPLOAD = "FILE_UPLOAD",
  FEEDBACK = "FEEDBACK",
  LATE_SIGNUP_EXPERIMENT = "LATE_SIGNUP_EXPERIMENT",
}

const eventToSendTo = (userEvent: UserEvent) => {
  switch (userEvent) {
    case UserEvent.RUN_PIPELINE:
      return "AW-10840240452/5TT7CIDh0ZkDEMTig7Eo";
    case UserEvent.SIGNUP:
      return "AW-10840240452/i4wDCODk0ZkDEMTig7Eo";
    case UserEvent.API_KEY_GENERATION:
      return "AW-10840240452/OGTqCOPk0ZkDEMTig7Eo";
    // case UserEvent.COPY_REQUEST:
    //   return "AW-10840240452/6dVyCPzNg8UDEMTig7Eo";
    case UserEvent.RUN_SAMPLE_BUTTON:
    case UserEvent.SELECT_LIBRARY_SAMPLE:
      return "AW-10840240452/QXw6CJu77aMDEMTig7Eo";
    default:
      return "AW-10840240452/generic";
  }
};

const getUTMParams = async (event: UserEvent, data?: EventData) => {
  const userData = { ...data };
  if (event === UserEvent.USER_CREATED || event === UserEvent.SIGNUP) {
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      referrer,
      utm_campaignid,
      utm_adgroupid,
    } = new Cookies().getAll();
    if (referrer) {
      userData.referrer = referrer;
    }
    if (utm_source) {
      userData.utm = {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
        utm_content,
        utm_campaignid,
        utm_adgroupid,
      };
    }
  }
  const _gaCookie = new Cookies().get("_ga");
  if (_gaCookie) {
    userData._ga = _gaCookie;
  }
  return userData;
};
const sendToOneAIAnalytics = async (event: UserEvent, data?: EventData) => {
  try {
    const userData = { ...data };
    delete userData["api_key"];
    delete userData["uid"];
    delete userData["org_id"];
    const myData = await getUTMParams(event, userData);
    const eventData = {
      type: event,
      session_id: session_id,
      user_id: data?.uid,
      api_key: data?.api_key,
      org_id: data?.org_id,
      data: myData,
      value: data?.value,
    };
    await api.post("/analytics", eventData);
  } catch (error) {
    console.error(
      "[event logger] error sendToOneAIAnalytics event, error",
      error,
      " event:",
      event,
      "data",
      data,
    );
  }
};

declare global {
  interface Window {
    dataLayer: any;
  }
}

export enum ErrorType {
  API_KEY_GENERATION_ERROR = "API_KEY_GENERATION_ERROR",
  POPUP_ERROR = "POPUP_ERROR",
  MIXPANEL_ERROR = "MIXPANEL_ERROR",
  HOTJAR_ERROR = "HOTJAR_ERROR",
  GTAG_ERROR = "GTAG_ERROR",
  UET_ERROR = "UET_ERROR",
}
export interface ErrorData {
  error: ErrorType;
}
interface EventData {
  msg?: string | undefined;
  uid?: string | undefined;
  email?: string | undefined;
  display_name?: string | undefined;
  api_key?: string | undefined;
  name?: string | undefined;
  utm?: object | undefined;
  referrer?: string | undefined;
  org_id?: string | undefined;
  search_word?: string | undefined;
  found?: boolean | undefined;
  result?: Array<string> | undefined;
  os?: string | undefined;
  device?: string | undefined;
  user_agent?: string | undefined;
  error?: string | undefined;
  sign_type?: string | undefined;
  value?: string | boolean | undefined;
  date_time?: string | undefined;
  user_id?: string | undefined;
  _ga?: string | undefined;
}

enum EventMessage {}

function gtag_report_conversion(url = undefined, userEvent: UserEvent) {
  try {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    const send_to = eventToSendTo(userEvent);
    //@ts-ignore
    gtag("event", "conversion", {
      send_to,
      event_callback: callback,
    });
    return false;
  } catch (error) {
    errorLogger({ error: ErrorType.GTAG_ERROR });
    console.error(
      "[event logger] error gtag_report_conversion event",
      userEvent,
    );
  }
}

const sendToHotjar = (event: UserEvent, data?: EventData) => {
  try {
    //@ts-ignore
    if ((event as UserEvent) === UserEvent.LOGIN) {
      //@ts-ignore
      window.hj("identify", data.uid, data);
    } else {
      //@ts-ignore
      window.hj("event", event);
    }
  } catch (error) {
    errorLogger({ ...data, error: ErrorType.HOTJAR_ERROR });
    console.error(
      "[event logger] error sendToHotjar event",
      event,
      "data",
      data,
    );
  }
};

const sendToSegment = async (event: UserEvent, data?: EventData) => {
  try {
    const userData = { ...data };
    delete userData["api_key"];
    delete userData["uid"];
    delete userData["org_id"];
    const myData = await getUTMParams(event, userData);
    const eventData = {
      type: event,
      session_id: session_id,
      user_id: data?.uid,
      api_key: data?.api_key,
      org_id: data?.org_id,
      data: myData,
    };

    await segmentAnalytics.track(event, eventData);
  } catch (error) {
    errorLogger({ ...data, error: ErrorType.MIXPANEL_ERROR });
    console.error("[event logger] error Segment event", event, "data", data);
  }
};

const sendToMixPanel = async (event: UserEvent, data?: EventData) => {
  try {
    const userData = { ...data };
    delete userData["api_key"];
    delete userData["uid"];
    delete userData["org_id"];
    const myData = await getUTMParams(event, userData);
    const eventData = {
      type: event,
      session_id: session_id,
      user_id: data?.uid,
      api_key: data?.api_key,
      org_id: data?.org_id,
      data: myData,
    };
    mixpanel.track(event, {
      eventData,
    });
  } catch (error) {
    errorLogger({ ...data, error: ErrorType.MIXPANEL_ERROR });
    console.error(
      "[event logger] error sendToMixPanel event",
      event,
      "data",
      data,
    );
  }
};

const enhancedLogEvent = (event: UserEvent, data?: EventData) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    formID: undefined,
  });

  let enhancedEventLabel: any = event;
  if (event === UserEvent.LOGIN) enhancedEventLabel = "Login";
  else if (event === UserEvent.SIGNUP) enhancedEventLabel = "SignUp";
  else if (event === UserEvent.RUN_PIPELINE)
    enhancedEventLabel = "APIgenerated";
  else if (event === UserEvent.COPY_REQUEST) enhancedEventLabel = "APIcopied";
  else if (event === UserEvent.SHARE_BUTTON)
    enhancedEventLabel = "SharePipeline";
  else if (event === UserEvent.SKILL_ADD) {
    enhancedEventLabel = "skills_touching";
    window.dataLayer.push({
      formID: "SkillsTouching",
    });
  } else if (event === UserEvent.SEND_QUOTA_REQUEST) {
    enhancedEventLabel = "formSent";
    window.dataLayer.push({
      formID: "QuotaRequestFormStudio",
    });
  }
  window.dataLayer.push({ userID: data?.uid });
  // https://docs.google.com/document/d/1581RqZl4q3WpLwDUm9P16CvgNEVpx46mB_1pWub1daQ/edit
  logEvent(firebaseAnalytics, enhancedEventLabel);
};
const eventLogger = (event: UserEvent, data?: EventData) => {
  console.debug("[eventLogger]:", event, ",data :", data);
  sendToSegment(event, data);
  sendToOneAIAnalytics(event, data);
  enhancedLogEvent(event, data);
  gtag_report_conversion(undefined, event);
  uet_report_conversion(event);
  sendToHotjar(event, data);
  sendToMixPanel(event, data);
};

const getUETEventID = (userEvent: UserEvent) => {
  switch (userEvent) {
    case UserEvent.RUN_PIPELINE:
      return "run_pipeline";
    case UserEvent.RUN_PIPELINE_CUSTOM:
      return "run_pipeline_custom";
    case UserEvent.RUN_PIPELINE_SAMPLE:
      return "run_pipeline_sample";
    case UserEvent.RUN_PIPELINE_SHARE:
      return "run_pipeline_share";
    case UserEvent.RUN_SAMPLE_BUTTON:
    case UserEvent.SAMPLE_SELECTION: // Change this to the new event name from Staging after the merge
      return "ran_sample";
    case UserEvent.SIGNUP:
      return "registration";
    case UserEvent.COPY_REQUEST:
      return "copy_request";
    case UserEvent.API_KEY_GENERATION:
      return "token_gen";
    default:
      return "";
  }
};

function uet_report_conversion(userEvent: UserEvent) {
  try {
    const event_id = getUETEventID(userEvent);
    if (event_id) {
      //@ts-ignore
      window.uetq = window.uetq || [];
      //@ts-ignoreW
      window.uetq.push("event", event_id, {});
    } else {
      // No event_id, so we don't send anything
    }
  } catch (error) {
    errorLogger({ error: ErrorType.UET_ERROR });
    console.error(
      "[event logger] error uet_report_conversion event",
      userEvent,
    );
  }
}

const errorLogger = (data?: EventData) => {
  sendToOneAIAnalytics(UserEvent.ERROR, data);
};

export const getUserData = (userData: any, data?: EventData) => {
  return {
    ...data,
    uid: userData?.uid,
    user_id: userData?.uid,
    api_key: userData?.studio_key,
    org_id: userData?.org_ref,
    os: platform.os?.toString(),
    device: isDesktop ? "Desktop" : isTablet ? "Tablet" : "Mobile",
    user_agent: navigator?.userAgent,
  };
};

const useErrorLogger = () => {
  const userDataLoadable = useRecoilValueLoadable(userDataAtom);
  let userErrorLogger;
  if (userDataLoadable.state === "hasValue") {
    const userData = userDataLoadable.contents;
    userErrorLogger = (data?: EventData) => {
      data = getUserData(userData, data);
      errorLogger(data);
    };
  } else {
    userErrorLogger = (data?: EventData) => {
      errorLogger(data);
    };
  }
  return { userErrorLogger };
};

const useEventLogger = () => {
  const userDataLoadable = useRecoilValueLoadable(userDataAtom);
  let userEventLogger: any;
  if (userDataLoadable.state === "hasValue") {
    const userData = userDataLoadable.contents;
    userEventLogger = (event: UserEvent, data?: EventData) => {
      data = getUserData(userData, data);
      if (event === UserEvent.PAGE_LOAD) {
        setAnalyticsUserID(data?.uid);
      }
      eventLogger(event, data);
    };
  } else {
    userEventLogger = (event: UserEvent, data?: EventData) => {
      eventLogger(event, data);
    };
  }
  const userUIEventLogger = (text: string, data?: EventData) => {
    const value = `${text}`;
    if (userDataLoadable.state === "hasValue") {
      const userData = userDataLoadable.contents;
      data = getUserData(userData, data);
    }
    eventLogger(UserEvent.CLICK, { ...data, value });
    console.debug("[userUIEventLogger]: ", value, data);
  };

  return { userEventLogger, userUIEventLogger };
};

const setAnalyticsUserID = (userId: string | undefined) => {
  if (!userId) {
    console.debug("[setAnalyticsUserID]: userId is undefined");
    return;
  }
  //@ts-ignore
  gtag("config", process.env.REACT_APP_GA_MEASUREMENT_ID!, {
    user_id: userId,
  });
  console.debug("[setAnalyticsUserID]: userId is ", userId);
};
export { eventLogger, useEventLogger, UserEvent, EventMessage, useErrorLogger };
