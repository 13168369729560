import clusteringAPI from "../../services/api";

const fetchClusteringAPI = async (
  key: string,
  apiKey: string,
  method: "GET" | "POST",
  body?: any,
) => {
  const config: any = {
    headers: {
      "api-key": apiKey ? apiKey : "NO_TOKEN",
      Accept: "application/json",
    },
  };
  let baseUrl = "/clustering/v1/collections/";
  if (method === "POST") {
    const response = await clusteringAPI.post(`${baseUrl}${key}`, body, config);
    return response?.data;
  } else if (method === "GET") {
    if (!key) {
      // Remove the slash from the end of the url
      // Backend fails to parse the url if it has a slash at the end
      baseUrl = baseUrl.slice(0, -1);
    }
    const response = await clusteringAPI.get(`${baseUrl}${key}`, config);
    return response?.data;
  }
};

export const getUserCollections = async (apiKey: string) => {
  return await fetchClusteringAPI("", apiKey, "GET");
};

export const getClustering = async (apiKey: string, collection: string) => {
  try {
    const res = await fetchClusteringAPI(
      `${collection}/clusters`,
      apiKey,
      "GET",
    );
    return res;
  } catch (e) {
    return "CLUSTERING_ERROR";
  }
};

export const createCollection = async (
  apiKey: string,
  collectionData: string,
  collectionName: string,
) => {
  return await fetchClusteringAPI(
    encodeURIComponent(collectionName) + "/items",
    apiKey,
    "POST",
    collectionData,
  );
};
