import React from "react";
import Lottie from "react-lottie";
import loader from "../../lotties/loader.json";
import "./styles.css";

type LoaderType = {
  loader?: boolean;
};

const Loader = ({ loader = false }: LoaderType) => {
  if (!loader) return null;

  return (
    <div data-cy="loader-wrapper" className="loader-wrapper">
      <LottieLoader />
    </div>
  );
};

export const OutputLoader = ({ loader = false }: LoaderType) => {
  if (!loader) return null;
  return (
    <div
      data-cy="loader-wrapper"
      className="no-blur absolute left-0 right-0 z-30 h-screen"
    >
      <div className="absolute top-[43%] left-0 right-0 ml-0 mr-auto">
        <LottieLoader />
      </div>
    </div>
  );
};

export const LottieLoader = ({ height = 220, width = 180 }: any) => {
  const loaderAnimation = {
    loop: true,
    autoplay: true,
    isClickToPauseDisabled: false,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      isClickToPauseDisabled={true}
      options={loaderAnimation}
      height={height}
      width={width}
      style={{ cursor: "auto" }}
    />
  );
};

export default Loader;
