import React from "react";
import { useResizeDetector } from "react-resize-detector";
import Split from "react-split-grid";
import { useRecoilValue } from "recoil";
import { textAreaEmptyStateAtom } from "../../lib/atoms";
import useIsSampleLibrary from "../../lib/use-is-sample-library";
import InputTabs from "../PipelinePage/input-tabs";
import FileUploader from "../PipelinePage/Input/file-uploader";
import InputDeterminator from "../PipelinePage/Input/input-determinator";
import InputSelection from "../PipelinePage/Input/input-type-selection";
import SampleLibrary from "../PipelinePage/sample-library";
import PipelineRequest from "./pipeline-request";
import { ResizeGutterRow } from "./resize-gutter";

export default function PipelineInputPanel() {
  const { width, height, ref } = useResizeDetector();

  return (
    <div className="w-full h-full max-h-full overflow-y-hidden grid grid-rows-auto-1fr">
      <InputTabsPanel />
      <Split minSize={100}>
        {({ getGridProps, getGutterProps }: any) => (
          <div
            className="w-full h-full grid grid-rows-pipeline-input-panel overflow-y-hidden"
            {...getGridProps()}
          >
            <div className="overflow-y-hidden h-full dark:bg-darkGrayBackground bg-white rounded-b-md">
              <TextPanel />
            </div>

            <ResizeGutterRow getGutterProps={getGutterProps} />
            <div className="dark:bg-categoryItemBackgroundDark rounded-md overflow-y-hidden h-full">
              <div
                className="overflow-y-hidden h-full grid grid-rows-auto-1fr relative"
                ref={ref}
              >
                <RequestPanel
                  ref={ref}
                  panelHeight={height}
                  panelWidth={width}
                />
              </div>
            </div>
          </div>
        )}
      </Split>
    </div>
  );
}

export function PipelineInputPanelMobile() {
  return (
    <div className="w-full h-full max-h-fullgrid grid-rows-auto-1fr">
      <InputTabsPanel />
      <div className="overflow-y-hidden dark:bg-darkGrayBackground bg-white rounded-b-md h-[calc(100%-53px-70px)]">
        <TextPanel />
      </div>
    </div>
  );
}

const InputTabsPanel = () => {
  return <InputTabs />;
};

const TextPanel = () => {
  const inputContainer = React.useRef(null);
  const { isSampleLibrary } = useIsSampleLibrary();

  const textAreaEmptyState = useRecoilValue(textAreaEmptyStateAtom);

  return (
    <div className="h-full grid grid-rows-auto-1fr">
      {!isSampleLibrary ? (
        <>
          <InputSelection />
          <div className={`grid h-full min-h-0`}>
            {textAreaEmptyState ? (
              <FileUploader inputContainer={inputContainer} />
            ) : (
              <InputDeterminator inputContainer={inputContainer} />
            )}
          </div>
        </>
      ) : (
        <SampleLibrary />
      )}
    </div>
  );
};

type RequestPanelProps = {
  ref: any;
  panelHeight: number | undefined;
  panelWidth: number | undefined;
};

const RequestPanel = ({ ref, panelHeight, panelWidth }: RequestPanelProps) => {
  return (
    <div>
      <PipelineRequest panelHeight={panelHeight} panelWidth={panelWidth} />
    </div>
  );
};
