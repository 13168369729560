import Menu from "@mui/material/Menu";
import * as React from "react";

import theme from "../theme";

import { usePipelineErrors } from "../lib/use-run-pipeline";
import { IconTooltip } from "../assets/SvgIcons";

export default function ErrorsPopover({
  openedPopover = () => {},
  closedPopover = () => {},
  anchorEl,
  setAnchorEl,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  anchorEl: any;
  setAnchorEl: any;
}) {
  const open = Boolean(anchorEl);

  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
    //eslint-disable-next-line
  }, [open]);

  return (
    <React.Fragment>
      <ErrorsPopup anchorEl={anchorEl} open={open} onClose={handleClose} />
    </React.Fragment>
  );
}

type ErrorsPopupPropsType = {
  anchorEl: any;
  open: boolean;
  onClose: any;
};

const ErrorsPopup = React.forwardRef((props: ErrorsPopupPropsType) => {
  const { anchorEl, open, onClose } = props;
  const [tipRight, setTipRight] = React.useState<number>(0);
  const hasErrors = usePipelineErrors();
  const tipWidth = 18;

  React.useEffect(() => {
    if (anchorEl) {
      setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2 + 16);
    }
  }, [anchorEl]);

  return (
    <Menu
      anchorEl={anchorEl}
      id="errors-popover"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          backgroundColor: theme.colors.lightBlack,
          color: theme.colors.white,
          width: "200px",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.3,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "4px",
            right: tipRight,
            width: tipWidth,
            height: 18,
            backgroundColor: theme.colors.lightBlack,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            borderRadius: "4px",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <ul className="px-2 grid gap-y-2 items-center">
        {hasErrors().map((el: string, index: number) => {
          return (
            <li
              key={index}
              className="py-1 grid grid-cols-auto-1fr gap-x-2 items-center"
            >
              <IconTooltip className={"text-blue"} />
              {el}
            </li>
          );
        })}
      </ul>
    </Menu>
  );
});
