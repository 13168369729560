import { AccordionDetails } from "@mui/material";
import "./docs-style.css";

export default function APIAccordionDetails(props: any) {
  return (
    <>
      <AccordionDetails {...props} />
    </>
  );
}
