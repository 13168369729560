import Masonry from "@mui/lab/Masonry";
import { flatMap } from "lodash";
import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useResizeDetector } from "react-resize-detector";
import { useLocation, useNavigate } from "react-router-dom";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import {
  IconBackToOutput,
  IconDropSkillPlaceholder,
  IconSettings,
  IconTooltip,
  TrashIcon,
} from "../../assets/SvgIcons";
import InfoPopover from "../../components/info-popover";
import { useThemeColor } from "../../components/theme-color";
import AppTooltip from "../../components/tooltip";
import { inputTypeAtom, loaderAtom } from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { SkillsType } from "../../lib/interfaces";
import usePipelineSkill from "../../lib/use-pipeline-skill";
import { useStudioData } from "../../lib/use-studio-data";
import SettingsPopover from "../PipelinePage/DragAndDrop/Popover";
import {
  convertInputType,
  detectedSkillName,
  getSkillCategory,
  isSkillAllowed,
  useIsMobile,
} from "../PipelinePage/utils";
import {
  apiResponseAtom,
  clusteringStatelessAtom,
  partitionedSkillsSelector,
  pipelineSkillsAtom,
  pipelineSkillsSearchAtom,
  skillsLibraryFilteredBySearchSelector,
} from "./pipeline-atoms";
import {
  categoryColor,
  getItemStyle,
  getListStyle,
  HalfWidthControlWrapper,
} from "./pipeline-common";
import { useOnDragEnd } from "./pipeline-drag-events";
import { SuggestASkill } from "./pipeline-skills-panel";

export const formatSkillName = (skillName: string) => {
  return skillName.replace(/ *\([^)]*\) */g, "");
};

export const nColsAtom = atom({ key: "nColsAtom", default: 4 });

function SearchAndBackToOutput() {
  const isMobile = useIsMobile();
  return (
    <div className={`bg-white dark:bg-darkGrayBackground w-full`}>
      <div className="rounded-t-md  max-w-screen-lg">
        <HalfWidthControlWrapper type="couple">
          {!isMobile && <GoBackToOutputOrText />}
          <SkillsSearch />
        </HalfWidthControlWrapper>
      </div>
    </div>
  );
}

function GoBackToOutputOrText() {
  const apiRseponse = useRecoilValue(apiResponseAtom);
  const navigate = useNavigate();
  const { search } = useLocation();

  const navigateToOutput = () => {
    navigate("/pipeline-output" + search);
  };
  if (apiRseponse) {
    return (
      <div>
        <button
          className="grid grid-cols-auto-1fr gap-x-1"
          onClick={() => {
            navigateToOutput();
          }}
        >
          <IconBackToOutput
            className="text-shadeBlue grid items-center"
            height="18px"
            width="18px"
          />
          <span className="grid text-black dark:text-white">
            Back to output view
          </span>
        </button>
      </div>
    );
  }
  return (
    <div className="grid">
      <i className="text-grayMedium font-medium font-poppins">
        Drag skills from the library to the pipeline above
      </i>
    </div>
  );
}
export function DND() {
  const onDragEnd = useOnDragEnd();
  return (
    <div className="grid h-full grid-rows-auto-auto-1fr">
      <DragDropContext onDragEnd={onDragEnd}>
        <SkillsDropArea />
        <SearchAndBackToOutput />
        <div className="dark:bg-darkGrayBackground h-full overflow-y-hidden bg-white">
          <SkillsLibrary />
        </div>
      </DragDropContext>
    </div>
  );
}

const SkillsLibrary = () => {
  const skillsLibraryFiltered = useRecoilValue(
    skillsLibraryFilteredBySearchSelector,
  );
  const [nCols, setNCols] = useRecoilState(nColsAtom);
  const { width, ref } = useResizeDetector();
  useEffect(() => {
    const _nCols =
      (width || 0) > 950
        ? 4
        : (width || 0) > 700
        ? 3
        : (width || 0) > 500
        ? 2
        : 1;
    if (_nCols !== nCols) {
      setNCols(_nCols);
    }
    //eslint-disable-next-line
  }, [width, setNCols]);

  const masonryCols = nCols === 4 ? 2 : nCols === 3 || nCols === 2 ? 1 : 1;

  return (
    <div
      className="overflow-y-auto h-full grid grid-rows-1fr-auto gap-y-8"
      ref={ref}
    >
      <Masonry
        columns={masonryCols}
        spacing={0}
        className="max-w-screen-lg w-fit-content px-4"
      >
        {Object.keys(skillsLibraryFiltered).map(
          (categoryKey: any, index: number) => {
            return (
              <React.Fragment key={categoryKey}>
                <SkillsLibraryCategory
                  categoryKey={categoryKey}
                  index={index}
                  skills={skillsLibraryFiltered[categoryKey]}
                  nCols={nCols}
                />
              </React.Fragment>
            );
          },
        )}
      </Masonry>

      <HalfWidthControlWrapper type="single">
        <SuggestASkill />
      </HalfWidthControlWrapper>
    </div>
  );
};

const SkillsLibraryCategory = ({ categoryKey, skills, index, nCols }: any) => {
  const { data } = useStudioData();
  return (
    <div>
      <div
        className={`mt-8 ${
          nCols === 4 && index % 2 === 0
            ? "lg:mr-14"
            : nCols === 4 && index % 2 === 1
            ? "lg:mr-14" // Instead of ml-8
            : ""
        }`}
      >
        <h3 style={categoryColor(categoryKey, "CATEGORY-TEXT", data)}>
          {categoryKey}
        </h3>
        <ul
          data-cy="dnd-droppable-library"
          className={`${
            nCols === 1
              ? "grid-cols-1"
              : nCols === 3
              ? "grid-cols-3"
              : "grid-cols-2"
          } grid gap-y-3 gap-x-4 md:gap-y-4 w-full `}
        >
          {skills &&
            skills?.map((skill: any) => {
              return (
                <li key={skill.name} className="w-full">
                  <SkillsLibraryCategoryDraggables
                    skill={skill}
                    categoryKey={categoryKey}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

const SkillsLibraryCategoryDraggables = ({ skill }: any) => {
  const { addSkill } = usePipelineSkill(skill);

  const onDoubleClick = () => {
    addSkill();
  };

  return (
    <Droppable
      key={skill.name}
      droppableId={String(skill.name)}
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          className="h-full"
          {...provided.droppableProps}
        >
          <Draggable
            key={skill.id}
            draggableId={skill.id}
            index={Number(skill.id)}
          >
            {(provided, snapshot) => (
              <React.Fragment>
                <div
                  onDoubleClick={onDoubleClick}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style,
                  )}
                  className={`h-fit`}
                >
                  <SkillItem skill={skill} isDragging={snapshot.isDragging} />
                </div>

                {snapshot.isDragging && <SkillItem skill={skill} />}
              </React.Fragment>
            )}
          </Draggable>
          <div className="hidden">{provided.placeholder}</div>
        </div>
      )}
    </Droppable>
  );
};

const DraggableWrapper = ({ children, provided, snapshot }: any) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
      className="h-full"
    >
      {children}
    </div>
  );
};

export const SkillItemPipelineOutput = ({
  index,
  skill,
  isActive,
}: {
  index: number;
  skill: any;
  isActive: boolean;
}) => {
  const [skillHover, setSkillHover] = React.useState<boolean>(false);
  const { data } = useStudioData();
  const [skillCount, setSkillCount] = React.useState<number>(0);
  const apiRseponse = useRecoilValue(apiResponseAtom);
  const [button, setButton] = React.useState<boolean>(true);
  const outputLoading = useRecoilValue(loaderAtom);

  React.useEffect(() => {
    setButton(isActive);
  }, [isActive]);

  const countSkill = (name: any) => {
    if (name === "summarize") name = "origin";

    if (apiRseponse?.output[index]) {
      let array = apiRseponse?.output[index]?.labels?.filter((label: any) => {
        // Fixes dialog-segment issue (backend sends labels with "dialog-segment" as name instead of "dialog-segmentation")
        if (label?.skill === "dialog-segment") {
          return name.startsWith(label?.skill);
        } else {
          return label.skill === name;
        }
      });
      if (array?.length) {
        setSkillCount(array?.length);
      }
    }
    setSkillHover(true);
  };

  return (
    <AppTooltip
      title={
        !outputLoading ? (
          <>
            <div className="flex justify-center items-center gap-2 w-fit">
              <span>
                {/* detectedSkillName functions just replaces Summary it with Origins because of Summary Origins */}
                {skillCount} {detectedSkillName(skill?.name, skillCount)}{" "}
                detected
              </span>

              {skillCount === 0 ? null : (
                <button
                  onClick={() => setButton(!button)}
                  className="py-2 px-2 bg-blue rounded-md whitespace-nowrap"
                >
                  Click to {button ? "hide" : "show"}
                </button>
              )}
            </div>
          </>
        ) : (
          ""
        )
      }
    >
      <div
        style={{
          boxShadow: "0px 1px 13px 0px rgb(0 0 0 / 8%)",
          ...categoryColor(
            getSkillCategory(skill?.name, data),
            isActive ? "SKILL-BORDER" : "INACTIVE",
            data,
          ),
        }}
        onMouseOver={() => countSkill(skill.value)}
        onMouseLeave={() => setSkillHover(false)}
        className="w-fit-content h-full dark:bg-categoryItemBackgroundDark bg-white leading-6 rounded-sm border-l-2 grid grid-cols-1fr-auto gap-x-4 items-center shadow min-w-[120px]"
      >
        <span
          className={`whitespace-nowrap pl-2 py-2  ${
            isActive
              ? "dark:text-white text-black"
              : " text-graylogo font-medium"
          }`}
        >
          {formatSkillName(skill.name)}
        </span>
        <IconOrTrash
          hover={skillHover}
          skill={skill}
          isActive={isActive}
          type={"output"}
          index={index}
        />
      </div>
    </AppTooltip>
  );
};

export const SkillItemPipeline = ({ skill }: { skill: any }) => {
  const [skillHover, setSkillHover] = React.useState<boolean>(false);
  const { data } = useStudioData();
  const { updateParams, skillMismatch } = usePipelineSkill(skill);

  return (
    <div>
      <div
        style={categoryColor(
          getSkillCategory(skill?.name, data),
          "SKILL-BORDER",
          data,
          skillMismatch(),
        )}
        onMouseOver={() => setSkillHover(true)}
        onMouseLeave={() => setSkillHover(false)}
        className="relative w-fit-content dark:bg-categoryItemBackgroundDark bg-white leading-6 rounded-sm border-l-2 grid grid-cols-1fr-auto-auto gap-x-2 items-center h-full shadow-md min-w-[120px] pr-2"
      >
        <span
          className={`whitespace-nowrap pl-2 py-2  ${
            skillMismatch() ? "text-error" : "dark:text-white text-black"
          }`}
        >
          {formatSkillName(skill?.short_name ? skill?.short_name : skill.name)}
        </span>
        {skill?.is_labs ? <LabsLabel /> : null}
        <SkillSettings skill={skill} updateParams={updateParams} />
        <IconOrTrash hover={skillHover} skill={skill} type={"pipeline"} />
      </div>
    </div>
  );
};

type IconOrTrashProps = {
  index?: number; // output index
  hover: boolean;
  skill: SkillsType;
  isActive?: boolean;
  type: "output" | "pipeline";
};
const IconOrTrash = ({
  index,
  hover,
  skill,
  isActive = true,
  type,
}: IconOrTrashProps) => {
  const { deleteSkill, deleteOutputSkill, iconSkill } = usePipelineSkill(skill);
  const { userEventLogger } = useEventLogger();

  if (hover) {
    return (
      <div className="grid grid-flow-col items-center">
        <button
          onClick={(e) => {
            userEventLogger(UserEvent.SKILL_REMOVE, { value: skill.name });
            if (type === "pipeline") {
              deleteSkill();
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            deleteOutputSkill(index || 0);
          }}
        >
          <figure className="grid items-center p-2">
            <TrashIcon className="text-shadeBlue" />
          </figure>
        </button>
      </div>
    );
  }
  return <div className="px-2">{iconSkill(isActive, "pipeline")}</div>;
};

const IconPlaceholder = () => (
  <figure className="invisible">
    <IconSettings width="8px" height="8px" />
  </figure>
);

const SkillSettings = ({
  skill,
  updateParams,
}: {
  skill: SkillsType;
  updateParams: (newParams: any) => void;
}) => {
  const [clusteringStatelessID, setOpenedPopover] = useRecoilState(
    clusteringStatelessAtom,
  );

  return (
    <div className="grid items-center">
      {skill?.params ? (
        <SettingsPopover
          columnName={skill.name}
          skillId={skill.id}
          params={skill.params}
          defaultParams={skill.defaultParams}
          setOpenedPopover={setOpenedPopover}
          updateParams={updateParams}
          isOpen={clusteringStatelessID === skill?.id}
        />
      ) : (
        <IconPlaceholder />
      )}
    </div>
  );
};

const SkillItem = ({ skill, isDragging = false }: any) => {
  const [skillHover, setSkillHover] = React.useState<boolean>(false);
  const { data } = useStudioData();
  return (
    <div className="max-w-[250px]">
      <div
        onMouseOver={() => setSkillHover(true)}
        onMouseLeave={() => setSkillHover(false)}
        style={categoryColor(skill?.category, "SKILL-BORDER", data)}
        className={`relative dark:bg-categoryItemBackgroundDark bg-white leading-6 shadow-md rounded-sm pl-2 border-l-2 grid gap-x-4 items-center text-ellipsis pr-2 ${
          isDragging
            ? "w-fit-content grid-cols-1fr-auto-auto min-w-[120px]"
            : "grid-cols-1fr-auto"
        }`}
      >
        <span
          className={`text-black ${
            isDragging ? "py-2" : "py-3 "
          } dark:text-white whitespace-nowrap overflow-hidden text-ellipsis`}
        >
          {isDragging ? formatSkillName(skill.name) : skill.name}
        </span>
        {skill?.is_labs ? <LabsLabel /> : null}
        {isDragging && <div></div>}
        <IconOrInfo
          skill={skill}
          hover={skillHover}
          setSkillHover={setSkillHover}
        />
      </div>
    </div>
  );
};

function LabsLabel() {
  return (
    <div className="absolute top-0 -translate-y-1/2 right-2 text-xxs bg-skillPink rounded-sm px-1 w-[34px] text-center">
      Labs
    </div>
  );
}

type IconOrInfoProps = {
  skill: SkillsType;
  hover: boolean;
  setSkillHover: (newHover: boolean) => void;
};
const IconOrInfo = ({ skill, hover, setSkillHover }: IconOrInfoProps) => {
  const { iconSkill } = usePipelineSkill(skill);

  if (hover) {
    return (
      <InfoPopover
        itemName={skill.value}
        setPreventDoubleClick={() => {}}
        closedPopover={() => {
          setSkillHover(false);
        }}
      />
    );
  }
  return <div className="p-2">{iconSkill(true, "library")}</div>;
};

const SkillsSearch = () => {
  const [pipelineSkillsSearch, setPipelineSkillsSearch] = useRecoilState(
    pipelineSkillsSearchAtom,
  );

  const filteredSkills = useRecoilValue(skillsLibraryFilteredBySearchSelector);

  const onChange = (event: any) => {
    setPipelineSkillsSearch(event.target.value);
  };
  const { userEventLogger } = useEventLogger();

  React.useEffect(() => {
    const debouncedSkillSearch = setTimeout(() => {
      const parsedLibrarySkills = flatMap(filteredSkills);
      const skillResults = parsedLibrarySkills.map(
        (skill: SkillsType) => skill.value,
      );
      if (pipelineSkillsSearch !== "")
        userEventLogger(UserEvent.SEARCH, {
          search_word: pipelineSkillsSearch,
          found: skillResults.length ? true : false,
          result: skillResults,
          value: pipelineSkillsSearch,
        });
    }, 3000);
    return () => clearTimeout(debouncedSkillSearch);

    //eslint-disable-next-line
  }, [pipelineSkillsSearch]);

  return (
    <input
      value={pipelineSkillsSearch}
      onChange={onChange}
      type="text"
      className="font-medium border-b rounded-none border-b-strongPurple py-2 w-full bg-transparent outline-none
        placeholder:text-md placeholder:text-middlegray placeholder:dark:text-formsgray placeholder:font-poppins placeholder:tracking-normal placeholder:font-medium"
      placeholder="Search skills (try ‘emotions’, ‘love’, ‘people’, ’pricing’...)"
    />
  );
};

const SkillsDropArea = () => {
  const pipelineSkills = useRecoilValue(pipelineSkillsAtom);
  return (
    <div className="mb-3 min-h-[100px] relative overflow-x-hidden">
      <DropAreaBorder />
      <SkillsDropAreaSkills />
      {pipelineSkills.steps.length === 0 ? <SkillsPlaceholder /> : null}
    </div>
  );
};

export const DropAreaBorder = () => {
  return (
    <>
      <div className="absolute top-0 left-0 right-0 h-px z-10 bg-repeat-x bg-top dashed-top"></div>
      <div className="absolute bottom-0 left-0 right-0 h-px z-10 bg-repeat-x bg-bottom dashed-bottom"></div>
      <div className="absolute top-0 left-0 bottom-0 w-px z-10 bg-repeat-y	bg-left dashed-left"></div>
      <div className="absolute top-0 right-0 bottom-0 w-px z-10 bg-repeat-y bg-right dashed-right"></div>
      <style>
        {`
        .dashed-top {
          background-image: linear-gradient(to right, #454D86 40%, rgba(255, 255, 255, 0) 20%);
          background-size: 20px 1px;
        }
        .dashed-bottom {
          background-image: linear-gradient(to right, #454D86 40%, rgba(255, 255, 255, 0) 20%);
          background-size: 20px 1px;
        }
        .dashed-left {
          background-image: linear-gradient(to bottom, #454D86 40%, rgba(255, 255, 255, 0) 20%);
          background-size: 1px 20px;
        }
        .dashed-right {
          background-image: linear-gradient(to bottom, #454D86 40%, rgba(255, 255, 255, 0) 20%);
          background-size: 1px 20px;
        }
        `}
      </style>
    </>
  );
};

const SkillsPlaceholder = () => {
  const { isDark } = useThemeColor();
  return (
    <div className={`absolute top-0 left-0 w-full h-full`}>
      <div className="grid items-center grid-cols-1 grid-rows-auto md:grid-cols-2-auto md:grid-rows-none align-content-center justify-items-center py-2 md:py-0 gap-x-5 px-8 h-full w-fit-content mx-auto">
        <div>
          <IconDropSkillPlaceholder color={isDark ? "white" : "#D8D8D8"} />
        </div>
        <p className="p-0 m-0 font-poppins text-black dark:text-white">
          Drop language skills here
        </p>
      </div>
    </div>
  );
};

const SkillsDropAreaSkills = () => {
  const partitionedSkills = useRecoilValue(partitionedSkillsSelector);
  const noSkillsAdded =
    partitionedSkills.length === 1 && partitionedSkills[0].length === 0;

  return (
    <div className="flex h-full overflow-x-auto bg-white dark:bg-darkGrayBackground">
      {noSkillsAdded ? (
        <SkillsDropAreaSkillsSectionEmpty />
      ) : (
        partitionedSkills.map((skills: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <SkillsDropAreaSkillsSection
                skills={skills}
                index={index}
                isLast={index === partitionedSkills.length - 1}
              />
            </React.Fragment>
          );
        })
      )}
    </div>
  );
};

const SkillsDropAreaSkillsSectionEmpty = () => {
  return (
    <Droppable
      key={"PIPELINE_LIBRARY_EMPTY"}
      droppableId={"PIPELINE_LIBRARY_EMPTY"}
      direction={"horizontal"}
      data-cy="dnd-droppable-skill"
    >
      {(provided, snapshot) => (
        <div
          data-cy="dnd-droppable-skill"
          ref={provided.innerRef}
          className="flex h-full w-full"
          style={getListStyle(snapshot.isDraggingOver)}
          {...provided.droppableProps}
        >
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const SkillsDropAreaSkillsSection = ({
  skills,
  index,
  isLast,
}: {
  skills: Array<SkillsType>;
  index: number;
  isLast: boolean;
}) => {
  return (
    <div className={`grid ${isLast ? "grow mr-3" : "pr-1"}`}>
      <Droppable
        key={`PIPELINE_LIBRARY_OUTPUT__${index}`}
        droppableId={`PIPELINE_LIBRARY_OUTPUT__${index}`}
        direction={"horizontal"}
        data-cy="dnd-droppable-skill"
      >
        {(provided, snapshot) => (
          <div
            key={index}
            ref={provided.innerRef}
            className="flex gap-x-1 py-6 pb-9 px-3 relative"
            style={getListStyle(snapshot.isDraggingOver)}
            data-cy="dnd-droppable-skill"
            {...provided.droppableProps}
          >
            <div className="absolute pl-3 top-0 left-0 text-xs text-formsgray mt-1">
              OUTPUT {index + 1}
            </div>
            {skills.map((skill: any, index: number) => (
              <>
                <Draggable
                  key={skill.id}
                  draggableId={skill.id}
                  index={Number(index)}
                >
                  {(provided, snapshot) => (
                    <DraggableWrapper provided={provided} snapshot={snapshot}>
                      <SkillItemPipeline skill={skill} />
                      <SkillTypeMismatch
                        skill={skill}
                        isDragging={snapshot.isDragging}
                      />
                    </DraggableWrapper>
                  )}
                </Draggable>
              </>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const SkillTypeMismatch = ({
  skill,
  isDragging,
}: {
  skill: SkillsType;
  isDragging: boolean;
}) => {
  const [hover, setHover] = React.useState<boolean>(false);
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const inputType = useRecoilValue(inputTypeAtom);
  const overflowRef: any = React.useRef(null);

  React.useEffect(() => {
    if (overflowRef?.current) {
      if (overflowRef.current.offsetWidth < overflowRef.current.scrollWidth) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    }
  }, [hover]);

  if (!isSkillAllowed(skill, inputType) || isDragging) {
    return null;
  }

  const spanText = (
    <span>
      {skill.name} supports <b>{convertInputType(inputType?.name, true)}</b>{" "}
      input type.
      <br />
      Please change input type, or remove from pipeline.
    </span>
  );

  return (
    <div className="pb-6">
      <div className="dark:bg-modalDark bg-catskill absolute bottom-1">
        <AppTooltip title={showTooltip ? spanText : ""}>
          <div
            className="px-2 py-2 grid grid-cols-auto-1fr gap-x-3 items-center border-l border-catskill dark:border-modalDark relative h-full"
            onMouseLeave={() => setHover(false)}
            onMouseOver={() => setHover(true)}
          >
            <IconTooltip
              width="14px"
              height="14px"
              className="text-error z-50"
            />
            <p
              className="p-0 m-0 overflow-y-hidden text-black dark:text-white whitespace-nowrap text-ellipsis overflow-x-hidden"
              ref={overflowRef}
            >
              {spanText}
            </p>
          </div>
        </AppTooltip>
      </div>
    </div>
  );
};
