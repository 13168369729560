import { atom, selector, useRecoilState } from "recoil";
import { useMutateUser } from "./auth/data";

const themeEffect = ({ setSelf, onSet }: any) => {
  const initDark =
    localStorage.studioTheme === "dark" || !("studioTheme" in localStorage);
  if (initDark) {
    setSelf("dark");
    document.documentElement.classList.add("dark");
  } else {
    setSelf("light");
    document.documentElement.classList.remove("dark");
  }

  onSet((newValue: any) => {
    localStorage.studioTheme = newValue;
  });
};

const useThemeColor = () => {
  const [isDark, setIsDark] = useRecoilState(isDarkAtom);
  const currentTheme = !isDark ? "dark" : "light";

  const { mutateUser, mutateFirebaseUserPreferences } = useMutateUser();

  const toggleTheme = () => {
    mutateUser({
      "preferences.studio_theme": currentTheme,
    });
    mutateFirebaseUserPreferences({
      studio_theme: currentTheme,
    });
    setIsDark(!isDark);
  };

  const toggleDarkTheme = () => {
    setIsDark(true);
  };
  const toggleLightTheme = () => {
    setIsDark(false);
  };
  return { isDark, toggleTheme, toggleDarkTheme, toggleLightTheme };
};

const isDarkAtom = selector({
  key: "isDarkAtom",
  get: ({ get }) => get(themeAtom) === "dark",
  set: ({ set }, newValue) => {
    const dark = newValue === true;
    if (dark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    return set(themeAtom, dark ? "dark" : "light");
  },
});

const themeAtom = atom({
  key: "themeAtom",
  default: "dark",
  effects_UNSTABLE: [themeEffect],
});

export { useThemeColor };
