import React from "react";
import { useTranslation } from "react-i18next";
import { useMutateUser } from "../../components/auth/data";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import "./select-technology.css";

type SelectType = {
  technology: any;
  setTechnology: (value: any) => void;
  selectOptions: any;
};

const SelectTechnology = ({
  technology,
  setTechnology,
  selectOptions,
}: SelectType) => {
  const { t, i18n } = useTranslation("index");
  const { mutateUser, mutateFirebaseUserPreferences } = useMutateUser();
  const { userEventLogger } = useEventLogger();

  const updateProgrammingLanguage = async (technologyName: string) => {
    try {
      await mutateUser({
        "preferences.programming_language": technologyName,
      });
      // In case of disaster, we use firebase as well
      await mutateFirebaseUserPreferences({
        programming_language: technologyName,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = async (event: React.ChangeEvent<any>) => {
    const technlogyName = event?.target?.value;
    userEventLogger(UserEvent.CODE_GENERATOR_LANG_SELECT, {
      value: technlogyName,
    });
    setTechnology(technlogyName);
    updateProgrammingLanguage(technlogyName);
  };

  return (
    <div className="select m-0 min-w-0 whitespace-nowrap">
      <select
        className={`select-code-language outline-none dark:bg-darkGrayBackground text-tableGrayBody dark:text-pearl font-poppins border-b-md w-fit-content font-medium`}
        value={technology}
        style={{
          padding:
            i18n.dir() === "rtl" ? `0px 0px 0px 32px` : `0px 32px 0px 0px`,
          backgroundPosition: `calc(${
            i18n.dir() === "rtl" ? "0% +" : "100% -"
          } 0.75rem) center`,
        }}
        onChange={(e) => handleSelectChange(e)}
      >
        {selectOptions.map(({ name, value }: any, index: number) => (
          <option
            key={index}
            value={value}
            title={name}
            className="text-pearl font-poppins font-medium whitespace-nowrap"
          >
            {t(name)}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectTechnology;
