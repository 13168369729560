import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { toLower } from "lodash";
import { useEffect, useState } from "react";
import {
  IconAcademy,
  IconAcorn,
  IconAnonymize,
  IconAudio,
  IconCircle,
  IconClustering,
  IconDoubleTrinangle,
  IconEye,
  IconFlag,
  IconGear,
  IconHashtag,
  IconHeart,
  IconHexagon,
  IconHTMLExtract,
  IconKeyLeft,
  IconLine,
  IconReceipt,
  IconRecycle,
  IconSplitBySentence,
  IconSplitByTopics,
  IconSquare,
  IconStack,
  IconStar,
  IconTooltip,
  IconTrafficCone,
} from "../../assets/SvgIcons";
import { LottieLoader } from "../../components/Loader";
import { FeatureType, RequestTypes, SkillsType } from "../../lib/interfaces";
import theme from "../../theme";
import contentFile from "../../utils/content.json";
import { parseConversation } from "../../utils/detectTypeText";
import {
  article,
  emotions_anger,
  emotions_happiness,
  sdr_prosp_busy,
  sdr_prosp_insterested,
  sdr_prosp_not_insterested,
  sdr_raw_1,
  sdr_raw_2,
} from "../../utils/text-helper";
import { getFileExtension, getShortName } from "./Input/file-uploader";

// import { json } from "d3";

export const inputSelectionTypes = {
  article: {
    id: 1,
    name: "Article",
  },
  conversation: {
    id: 2,
    name: "Conversation",
  },
  "auto-detect": {
    id: 3,
    name: "Auto-Detect",
  },
};

export const inputOptions = [
  {
    id: "1",
    name: "Select Sample",
    value: "",
    selectionTypeId: inputSelectionTypes["auto-detect"],
  },
  {
    id: "2",
    name: "Article",
    value: article,
    selectionTypeId: inputSelectionTypes["article"],
  },
  {
    id: "3",
    name: "SDR Call - Prospect Interested",
    value: sdr_prosp_insterested,
    selectionTypeId: inputSelectionTypes["conversation"],
  },
  {
    id: "4",
    name: "SDR Call - Prospect Not Interested",
    value: sdr_prosp_not_insterested,
    selectionTypeId: inputSelectionTypes["conversation"],
  },
  {
    id: "5",
    name: "SDR Call - Prospect Busy",
    value: sdr_prosp_busy,
    selectionTypeId: inputSelectionTypes["conversation"],
  },
  {
    id: "6",
    name: "Emotions - Happiness",
    value: emotions_happiness,
    selectionTypeId: inputSelectionTypes["conversation"],
  },
  {
    id: "7",
    name: "Emotions - Anger",
    value: emotions_anger,
    selectionTypeId: inputSelectionTypes["conversation"],
  },
  {
    id: "8",
    name: "SDR Raw Transcript 1",
    value: sdr_raw_1,
    selectionTypeId: inputSelectionTypes["conversation"],
  },
  {
    id: "9",
    name: "SDR Raw Transcript 2",
    value: sdr_raw_2,
    selectionTypeId: inputSelectionTypes["conversation"],
  },
];

export const numberOperators = [">", "<", "<=", ">="];

export const operators = [
  "contains",
  "not_contains",
  "===",
  "!==",
  ">",
  "<",
  "<=",
  ">=",
];

export const technologiesOptions = [
  {
    id: "1",
    name: "Node.js",
    value: "Node.js",
  },
  {
    id: "2",
    name: "cURL",
    value: "cURL",
  },
  {
    id: "3",
    name: "Python",
    value: "Python",
  },
  {
    id: "4",
    name: "JSON",
    value: "JSON",
  },
  {
    id: "5",
    name: "Node.js SDK",
    value: "Node.js SDK",
  },
  {
    id: "6",
    name: "Python SDK",
    value: "Python SDK",
  },
];

export const technologyToLanguage = (technology: string) => {
  switch (technology) {
    case "Node.js":
    case "Node.js SDK":
      return "javascript";
    case "Python SDK":
      return "python";
    default:
      return technology?.toLowerCase();
  }
};
let server: string;
if (process.env.REACT_APP_API_SERVER) {
  server = process.env.REACT_APP_API_SERVER;
} else {
  server = window.location.origin;
}
let API_ADDRESS = server + "/api/v0/pipeline";

const extractRequest = (requestBuilder: any) => {
  const { input_type, steps, is_file } = requestBuilder;
  return { input_type, steps, is_file };
};

const getPreviewParams = (
  requestBuilder: any,
  preview: boolean,
  isSummaryOrigin: boolean,
  apiKey: string,
  programmingLanguage?: string,
) => {
  const { input_type, input, is_academic, is_file } = requestBuilder();

  let replacedText = "";
  if (
    (input_type === "conversation" && input !== "<YOUR INPUT TEXT>") ||
    (is_academic && input !== "<YOUR INPUT TEXT>")
  ) {
    replacedText = input?.replace(/\n/g, `\\n`);
  } else {
    replacedText =
      programmingLanguage === "Python"
        ? `"${requestBuilder()
            ?.input?.replace(/\n/g, `\\n`)
            ?.replace(/'/g, "\\'")}"`
        : `"` + JSON.stringify(input?.replace(/\n/g, `\\n`)).slice(1, -1) + `"`;
  }

  const key = apiKey && apiKey !== "-1" ? apiKey : "<YOUR-API-KEY>";

  function textPreview() {
    // Preview means what we see in the request section
    if (preview) {
      // If text is not empty and is more than 33 characters
      if (replacedText !== `"<YOUR INPUT TEXT>"` && replacedText?.length > 33) {
        // We show the first 33 characters and add ...
        if (is_file) {
          if (input_type === "conversation") {
            return `"${replacedText.slice(0, 33)}..."`;
          }
          return `${replacedText.slice(0, 33)}..."`;
        } else if (input_type === "conversation") {
          replacedText = removeTextLineProperty(replacedText);

          const secondUtteranceStart = indexOfNth(replacedText, "utterance", 2);
          if (secondUtteranceStart === -1) {
            return replacedText;
          }
          return (
            // 11 is the length of 'utterance":'
            `${replacedText?.substring(0, secondUtteranceStart + 11)}"..."}]`
          );
        } else if (is_academic) {
          return getAcademyInput(replacedText, programmingLanguage, true);
        }
        return `${replacedText?.substring(0, 33)}..."`;
        // If the request is empty, we show the highlighted <YOUR INPUT TEXT>
      } else if (
        replacedText === `"<YOUR INPUT TEXT>"` ||
        replacedText === `'<YOUR INPUT TEXT>'` ||
        replacedText === ""
      ) {
        return `"<YOUR INPUT TEXT>"`;

        // If none of the conditions above met and it's preview section, we show the text without the HTML
      } else if (preview) {
        return replacedText;
      } // If it's summary origins (input text highlighting), we strip it of HTML
      else if (isSummaryOrigin) {
        return stripHTML(replacedText);
      } // If none of the conditions above met, just return the text.
      else {
        return replacedText;
      }
    } else if (is_academic) {
      return getAcademyInput(replacedText, programmingLanguage, false);
    } else if (is_file) {
      if (input_type === "conversation") {
        return `"${replacedText}"`;
      } else {
        return replacedText;
      }
    } else {
      return replacedText;
    }
  }
  const apiKeyPreview = key;

  return { textPreview, apiKeyPreview };
};
export const getCURL = ({
  requestBuilder,
  preview,
  summaryOrigins,
  apiKey,
  isAnonymous,
}: RequestTypes) => {
  const { textPreview, apiKeyPreview } = getPreviewParams(
    requestBuilder,
    preview,
    summaryOrigins,
    apiKey,
  );
  const { steps, input_type, is_file } = extractRequest(requestBuilder());
  return `curl -X POST \\
'${API_ADDRESS}' \\
-H 'accept: application/json' \\
-H 'Content-Type: application/json' \\${
    isAnonymous
      ? `\n# grab your API key from https://${window.location.host}/settings/api-keys`
      : ""
  }
-H 'api-key: ${apiKeyPreview}' \\
-d '{
    "input":${textPreview()?.replace(/'/g, "\\'")},
    ${getInputType(is_file, input_type)}
    ${stepsStrategy(steps, "JSON")}
}'`;
};

const stepsStrategy = (steps: any, format: "JSON" | "NORMAL") => {
  if (format === "JSON") {
    if (!steps?.length) {
      return `"steps": [
      {
        "skill": "<SKILL-NAME>"
      }
    ]`;
    }
    return `"steps": [${steps.map((step: any) => {
      if (step?.params) {
        return `
        {
          "skill": "${step?.skill}",
          "params": {
            ${formatParams(step)}
          }
        }`;
      } else {
        return `
      {
        "skill": "${step?.skill}"
      }`;
      }
    })}
    ]`;
  } else {
    if (!steps?.length) {
      return `steps: [
      {
        skill: "<SKILL-NAME>", 
      },
    ],`;
    }
    return `steps: [${steps.map((step: any) => {
      if (step?.params) {
        return `
      {
        skill: "${step?.skill}",
        params: {
          ${Object.keys(step.params)
            .map((key) => {
              if (step.params[key] === undefined) {
                return "";
              } else if (key === "thresholds") {
                return `"${key}": ${JSON.stringify(
                  step.params[key],
                  null,
                  "\t\t\t\t\t\t\t",
                ).slice(0, -1)}           }`;
              }
              return `"${key}": ${JSON.stringify(step.params[key])}`;
            })
            // Params fix (show it exactly in the same place)
            .join(",\n\t\t\t\t\t")}
        },
      }`;
      } else {
        return `
      {
        skill: "${step?.skill}",
      }`;
      }
    })}
    ],`;
  }
};

const formatParams = (step: any) => {
  return (
    Object.keys(step.params)
      .map((key) => {
        if (step.params[key] === undefined) {
          return "";
        } else if (key === "thresholds") {
          return `
                  "${key}": ${JSON.stringify(
            step.params[key],
            null,
            "\t\t\t\t\t\t\t",
          ).slice(0, -1)}           }`;
        }
        return `"${key}": ${JSON.stringify(step.params[key])}`;
      })
      // Params fix (show it exactly in the same place)
      .join(",\n\t\t\t\t\t")
  );
};

export const getNodeJS = ({
  requestBuilder,
  preview,
  summaryOrigins,
  apiKey,
  isAnonymous,
}: RequestTypes) => {
  const { textPreview, apiKeyPreview }: any = getPreviewParams(
    requestBuilder,
    preview,
    summaryOrigins,
    apiKey,
  );
  const { steps, input_type, is_file } = extractRequest(requestBuilder());

  const nodeJS_request = `const axios = require("axios");${
    isAnonymous
      ? `\n// grab your API key from https://${window.location.host}/settings/api-keys`
      : ""
  }
const apiKey = "${apiKeyPreview}";
const config = { 
  method: "POST", 
  url: "${API_ADDRESS}",
  headers: { 
    "api-key": apiKey, 
    "Content-Type": "application/json",
  },
  data: {
    input: ${textPreview()},
    ${getInputType(is_file, input_type, "nodejs")}
    ${stepsStrategy(steps, "NORMAL")}
  },
};
axios(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });`;

  return nodeJS_request;
};

function convertToPythonCase(str: string) {
  const originalString = str;
  const replacedHyphen = originalString?.replaceAll("-", " ");
  let automaticPythonCase = replacedHyphen?.replaceAll(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1)?.toLowerCase();
    },
  );

  // Skills that their name should be changed
  if (automaticPythonCase === "Numbers & Time") {
    automaticPythonCase = "Numbers";
  }
  return automaticPythonCase?.replaceAll(" ", "");
}

function convertToCamelCase(str: string) {
  return lowerCaseFirstLetter(convertToPythonCase(str));
}

function getPySDKConversationPreview(input: string) {
  try {
    const objects = JSON.parse(input);
    return `\toneai.Utterance(
    "${objects[0].speaker}",
    "${objects[0].utterance}",
    ),
    oneai.Utterance(
    "${objects[1].speaker}",
    "${objects[1].utterance}"
    ),`;
  } catch (err) {
    console.log(err);
  }
}

function getInputType(is_file: boolean, input_type: string, language?: string) {
  const isConversation =
    input_type === inputSelectionTypes.conversation.name?.toLowerCase();
  const prefix = language === "nodejs" ? `` : `"`;
  const indentFix = language !== "python" ? `    ` : `  `;

  if (isConversation) {
    if (is_file) {
      return `input_type: "${input_type}",
${indentFix}${prefix}encoding${prefix}: "base64",`;
    } else {
      return `${prefix}input_type${prefix}: "${input_type}",
    ${prefix}content_type${prefix}: "application/json",`;
    }
  } else {
    if (is_file) {
      return `${prefix}input_type${prefix}: "${input_type}",
${indentFix}${prefix}encoding${prefix}: "base64",`;
    }
    return `${prefix}input_type${prefix}: "${input_type}",`;
  }
}
function getNodeSDKconversationPreview(text: string) {
  try {
    const objects = JSON.parse(text);
    return ` {
    speaker:"${objects[0].speaker}", 
    utterance:"${objects[0].utterance}"
  },
  {
    speaker:"${objects[1].speaker}", 
    utterance:"${objects[1].utterance}"
  },
  {
    speaker:"${objects[2].speaker}", 
    utterance:"${objects[2].utterance}"
  },`;
  } catch (err) {
    console.log(err);
  }
}

function getAcademyInput(
  text: string,
  language: "Python" | "Node.js" | "cURL" | "JSON" | string | undefined,
  isPreview: boolean,
) {
  try {
    let objects = JSON.parse(text);
    let newObjects;
    if (isPreview) {
      objects = objects.splice(0, 3);
      newObjects = objects.map((text: string) => {
        return text.length > 20 ? `${text.substring(0, 20)}...` : text;
      });
    } else {
      newObjects = objects.map((text: string) => {
        return text;
      });
    }

    let indentation = "";
    switch (language) {
      case "Python":
      case "Python SDK":
        indentation = `  `;
        break;
      case "Node.js":
      case "Node.js SDK":
        indentation = `         `;
        break;
      default:
        indentation = `      `;
        break;
    }
    return `[
${newObjects
  .map((text: string) => {
    return `${indentation}${JSON.stringify(text)}`;
  })
  .join(",\n")} 
${indentation.slice(0, -2)}]`;
  } catch (err) {
    console.log(err);
    return text;
  }
}

export const getJSON = ({
  requestBuilder,
  preview,
  summaryOrigins,
  apiKey,
}: RequestTypes) => {
  const { textPreview, apiKeyPreview }: any = getPreviewParams(
    requestBuilder,
    preview,
    summaryOrigins,
    apiKey,
  );
  const { steps, input_type, is_file } = extractRequest(requestBuilder());

  const JSONRequest = `
{
  "headers": {
    "api-key": "${apiKeyPreview}",
    "content-type": "application/json"
  },
  "payload":{
    "input": ${textPreview()},
    ${getInputType(is_file, input_type)}
    ${stepsStrategy(steps, "JSON")}
  }
}
`;

  return JSONRequest;
};

export const getNodeJSSDK = ({
  requestBuilder,
  preview,
  summaryOrigins,
  apiKey,
  isAnonymous,
}: RequestTypes) => {
  const { textPreview, apiKeyPreview }: any = getPreviewParams(
    requestBuilder,
    preview,
    summaryOrigins,
    apiKey,
  );

  const { input } = requestBuilder();
  const skills = extractRequest(requestBuilder());

  const nodeJSSDK_request = `// npm install oneai
import oneai from "oneai";${
    isAnonymous
      ? `\n// grab your API key from https://${window.location.host}/settings/api-keys`
      : ""
  }
oneai.apiKey = "${apiKeyPreview}";
${
  skills.input_type === "article" || skills.input_type === "auto-detect"
    ? `const text = ${textPreview()};
const pipeline = new oneai.Pipeline(${skills?.steps?.map((step: any) => {
        const convertedSkill = skillValueToName(step.skill);
        return `\n  oneai.skills.${convertToCamelCase(convertedSkill)}()`;
      })}
);
const output = await pipeline.run(text);
`
    : `const conversation = new oneai.Conversation([
 ${
   input === ("<YOUR INPUT TEXT>" || undefined)
     ? `// ${textPreview()}`
     : getNodeSDKconversationPreview(input)
 }
]);

const pipeline = new oneai.Pipeline(${skills?.steps?.map((step: any) => {
        const convertedSkill = skillValueToName(step.skill);
        return `\n  oneai.skills.${convertToCamelCase(convertedSkill)}()`;
      })}
);
const output = await pipeline.run(conversation);`
}`;

  return nodeJSSDK_request;
};

export const getPython = ({
  requestBuilder,
  preview,
  summaryOrigins,
  apiKey,
  isAnonymous,
}: RequestTypes) => {
  const { textPreview, apiKeyPreview }: any = getPreviewParams(
    requestBuilder,
    preview,
    summaryOrigins,
    apiKey,
    "Python",
  );
  const { steps, input_type, is_file } = extractRequest(requestBuilder());

  const python_request = `import requests${
    isAnonymous
      ? `\n# grab your API key from https://${window.location.host}/settings/api-keys`
      : ""
  }
api_key = "${apiKeyPreview}"
url = "${API_ADDRESS}"
text = ${textPreview()}
headers = {
  "api-key": api_key, 
  "content-type": "application/json"
}
payload = {
  "input": text,
  ${getInputType(is_file, input_type, "python")}
  ${stepsStrategy(steps, "JSON").replaceAll("true", "True")}
}
r = requests.post(url, json=payload, headers=headers)
data = r.json()
print(data)`;

  return python_request;
};

export const getPythonSDK = ({
  requestBuilder,
  preview,
  summaryOrigins,
  apiKey,
  isAnonymous,
}: RequestTypes) => {
  const { textPreview, apiKeyPreview }: any = getPreviewParams(
    requestBuilder,
    preview,
    summaryOrigins,
    apiKey,
  );

  const skills = extractRequest(requestBuilder());
  const { input } = requestBuilder();

  const pythonSDK_request = `# pip install oneai
import oneai${
    isAnonymous
      ? `\n# grab your API key from https://${window.location.host}/settings/api-keys`
      : ""
  }
oneai.api_key = "${apiKeyPreview}"
${
  skills.input_type === "article" || skills.input_type === "auto-detect"
    ? `text = ${textPreview()}
pipeline = oneai.Pipeline(
  steps=[${skills?.steps?.map((step: any) => {
    const convertedSkill: string = skillValueToName(step.skill);
    return `\n    oneai.skills.${convertToPythonCase(convertedSkill)}()`;
  })}
  ]
)
output = pipeline.run(text)
 `
    : `pipeline = oneai.Pipeline(
  steps = [${skills?.steps?.map((step: any, index: number) => {
    const convertedSkill: string = skillValueToName(step.skill);
    return `\n    oneai.skills.${convertToPythonCase(convertedSkill)}()`;
  })}
  ]
)

conversation = oneai.Conversation(
  [
   ${
     input === ("<YOUR INPUT TEXT>" || undefined)
       ? `   # ${textPreview()}`
       : getPySDKConversationPreview(input)
   }
  ]
)

output = pipeline.run(conversation)`
}`;

  return pythonSDK_request;
};

export const decodeHTMLEntities = (text: string) => {
  let textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
};

export const isOneAiEmail = (email: string) => {
  return email.includes("@oneai.com");
};

/* The purpose of this function is to remove HTML tags from a string from a given start index to a given end index.
  This is useful for removing the BLUE color from the <Your Token Here> for example. We won't strip all request HTML
  because sometimes we need it for the raw data, for example for the new skill "HTML Content Extract" */
export const stripHTMLRange = (
  html: string,
  start_index: number,
  end_index: number,
) => {
  let fullString = "";
  fullString = html.substring(0, start_index);
  let extract = html.substring(start_index, end_index);
  let tmp = document.createElement("div");
  tmp.innerHTML = extract;
  fullString += tmp.innerText += html.substring(end_index, html.length);
  return decodeHTMLEntities(fullString);
};

export const stripHTML = (html: any) => {
  let tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export const capitalizeFirstCharacter = (word: any) =>
  word?.charAt(0).toUpperCase() + word?.slice(1);

export function randomString(length: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString: string = "";

  for (let i = 0; i < length; i++) {
    let randomPoz = Math.floor(Math.random() * characters.length);
    randomString += characters.substring(randomPoz, randomPoz + 1);
  }
  return randomString;
}

function getWindowDimensions() {
  const mdWidth = Number(theme.screens.md.replace("px", ""));
  return window.innerWidth <= mdWidth ? true : false;
}
function getWindowHeight() {
  const maxWidth = Number(400);
  return window.innerHeight <= maxWidth ? true : false;
}

function getWindowHeightWithWidth() {
  const maxWidth = Number(600);
  const mdWidth = Number(theme.screens.md.replace("px", ""));
  return window.innerHeight <= maxWidth && window.innerWidth < mdWidth
    ? true
    : false;
}

export function useIsOnSide() {
  const [isOnSide, setIsOnSide] = useState(getWindowHeightWithWidth());

  useEffect(() => {
    function handleResize() {
      setIsOnSide(getWindowHeightWithWidth());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isOnSide;
}

export const validateEmail = (email: string) => {
  return String(email)
    ?.toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export function useIsShortHeight() {
  const [isOnSide, setIsOnSide] = useState(getWindowHeight());

  useEffect(() => {
    function handleResize() {
      setIsOnSide(getWindowHeight());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isOnSide;
}

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setIsMobile(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
}
export const replaceItemAtIndex = (
  arr: Array<any>,
  index: number,
  newItem: any,
) => {
  return [...arr.slice(0, index), newItem, ...arr.slice(index + 1)];
};

export async function getPipelineFromFirestore(id: string) {
  const db = getFirestore();
  const sharedPipelineRef = doc(db, "sharedPipeline", id);
  const sharedPipeline = await getDoc(sharedPipelineRef);
  const sharedPipelineData = sharedPipeline.data();
  return sharedPipelineData;
}
export const getQueryFeatures = () => {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  let variableArray = [];
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === "feature") variableArray.push(pair[1]);
  }
  return variableArray;
};

export const bignumbers = (quota: number | undefined) => {
  if (!quota) return "0";
  else if (quota >= 1000000000) {
    return String(Math.floor(quota / 1000000000) + "B");
  } else if (quota >= 1000000 && quota < 1000000000) {
    return String(Math.floor(quota / 1000000) + "M");
  } else if (quota >= 1000 && quota < 1000000) {
    return String(Math.floor(quota / 1000) + "K");
  } else return quota;
};

export function isStaging() {
  return window.location.hostname === "stage-studio.oneai.com";
}

export function isProduction() {
  return (
    window.location.hostname === "studio.oneai.com" ||
    window.location.hostname.startsWith("prod-studio") ||
    window.location.hostname.startsWith("preview-studio")
  );
}

export function isProdPreview() {
  return window.location.hostname === "preview-studio.oneai.com";
}

export function isLocalhost() {
  return window.location.hostname === "localhost";
}

export const getEnvironment = () => {
  if (isLocalhost()) return "localhost";
  if (isStaging()) return "staging";
  if (isProdPreview()) return "prodPreview";
  if (isProduction()) return "production";
  return "development";
};

export const isClusterable = (skillEndpoint: string | undefined) => {
  if (skillEndpoint) {
    return [
      "highlights",
      "sentences",
      "entities",
      "emotions",
      "sentiments",
      "business-entities",
      "action-items",
      "keywords",
      "clustering",
    ].includes(skillEndpoint || "");
  }
  return false;
};

// export function tryToParseString(input: string): any {
//   try {
//     return JSON.parse(input);
//   } catch (e) {
//     return input;
//   }
// }

export const addPipeline = async (
  pipeline: any,
  id: string,
  file?: any,
  copy?: any,
) => {
  const db = getFirestore();

  const docRef = doc(db, "sharedPipeline", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    if (copy !== false) {
      navigator.clipboard.writeText(`${window.location.host}/?pipeline=${id}`);
    }
    return {
      success: true,
      id: `${id}`,
      link: `${window.location.host}/?pipeline=${id}`,
    };
  } else {
    try {
      if (file) {
        pipeline.file = getShortName(file);
      }
      pipeline.pipeline.items = pipeline.pipeline.items.map((el: any) => {
        return {
          ...(el?.params && { params: el?.params }),
          ...(el?.defaultParams && { defaultParams: el?.defaultParams }),
          ...(el?.name && { name: el?.name }),
          ...(el?.category && { category: el?.category }),
          ...(el?.value && { value: el?.value }),
          ...(el?.id && { id: el?.id }),
        };
      });

      await setDoc(doc(db, "sharedPipeline", id), pipeline);
      if (copy !== false) {
        navigator.clipboard.writeText(
          `${window.location.host}/?pipeline=${id}`,
        );
      }
      return {
        success: true,
        id: `${id}`,
        link: `${window.location.host}/?pipeline=${id}`,
      };
    } catch (e) {
      console.log(e);
      return {
        success: false,
      };
    }
  }
};

// DragAndDrop Utils
export const SkillPipelineData = [];

export const skillValueToName = (value: string) => {
  // if the skill value not found it returns the input value
  try {
    if (value?.split(" ")[1] === "Clustering") {
      return (
        contentFile.skills.find((skill) => skill.value === value?.split(" ")[0])
          ?.name + " Clustering"
      );
    }

    return (
      contentFile.skills.find((skill) => skill.value === value)?.name || value
    );
  } catch (error) {
    return value;
  }
};
export const getSkillTypeNameByID = (skillID: number, contentFile: any) => {
  const foundSkill = contentFile?.skill_categories?.find(
    (o: any) => o.id === skillID,
  );
  return foundSkill?.category_name || "";
};

export const getSkillCategory = (skillName: string, contentFile: any) => {
  const foundSkill = contentFile?.skills?.find(
    (o: SkillsType) => o.name === skillName,
  );
  return foundSkill?.category;
};

export const getSkillType = (skillName: string, contentFile: any) => {
  const foundSkill = contentFile?.skills?.find(
    (o: any) => o.value === skillName,
  );
  return foundSkill?.type || "";
};
export const getSkillTypeByName = (skillName: string, contentFile: any) => {
  const foundSkill = contentFile?.skills?.find(
    (o: any) => o.name === skillName,
  );
  return foundSkill?.type || "";
};
export const getSkillEndpoint = (skillName: string, contentFile: any) => {
  const foundSkill = contentFile?.skills?.find(
    (o: any) => o.name?.toLowerCase() === skillName?.toLowerCase(),
  );

  return foundSkill?.value || "";
};
export const getSkillNameByEndpoint = (endpoint: string, contentFile: any) => {
  const foundSkill = contentFile?.skills?.find(
    (o: any) => o.value === endpoint,
  );
  return foundSkill?.name || "";
};
export const hasWritingSkillBefore = (
  currentIndex: number,
  requestBuilder: any,
  contentFile: any,
) => {
  const { steps } = requestBuilder();

  const foundSkillType =
    contentFile.skill_categories?.find(
      (o: any) => o.category_name === "Writing skill",
    ) || 1;

  return (
    steps?.find(
      (o: any, stepIndex: number) =>
        stepIndex < currentIndex &&
        getSkillType(o.skill, contentFile) === foundSkillType,
    ) || false
  );
};

type skillIconType = "LIBRARY" | "PIPELINE" | "DOCS_SMALL" | "DOCS_LARGE";

export function getSkillIcon(
  skillName: string,
  type: skillIconType,
  isActive: boolean,
  contentFile: any,
  isError?: boolean,
) {
  const isPipeline = type === "PIPELINE";
  const isLibrary = type === "LIBRARY";
  const isDocsSmall = type === "DOCS_SMALL";
  const isDocsLarge = type === "DOCS_LARGE";

  let width = "";
  let height = "";

  switch (skillName) {
    case "Emotions":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-skillPink"
              : "text-graylogo"
          }
        >
          <IconHeart height={height} width={width} />
        </figure>
      );
    case "Sentiments":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-skillPink"
              : "text-graylogo"
          }
        >
          <IconDoubleTrinangle height={height} width={width} />
        </figure>
      );
    case "Transcribe Audio":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure className={isActive ? "text-brightCyan" : "text-graylogo"}>
          <IconAudio height={height} width={width} />
        </figure>
      );
    case "Highlights":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError ? "text-error" : isActive ? "text-yellow" : "text-graylogo"
          }
        >
          <IconLine height={height} width={width} />
        </figure>
      );
    case "Keywords":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-brightCyan"
              : "text-graylogo"
          }
        >
          <IconKeyLeft
            height={height}
            width={width}
            className={
              isError
                ? "text-error"
                : isActive
                ? "text-brightCyan"
                : "text-graylogo"
            }
          />
        </figure>
      );
    case "Entities":
    case "Prices":
    case "Pricing":
    case "Numbers":
    case "Numbers & Time":
    case "Names":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-brightCyan"
              : "text-graylogo"
          }
        >
          <IconEye height={height} width={width} />
        </figure>
      );
    case "Proofread":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-brightCyan"
              : "text-graylogo"
          }
        >
          <IconStar height={height} width={width} />
        </figure>
      );
    case "Topics":
      if (isPipeline) {
        height = "20px";
        width = "16px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "23px";
        width = "23px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError ? "text-error" : isActive ? "text-yellow" : "text-graylogo"
          }
        >
          <IconHashtag height={height} width={width} />
        </figure>
      );
    case "Summarize":
      if (isPipeline) {
        height = "20px";
        width = "16px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "23px";
        width = "23px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-brightCyan"
              : "text-graylogo"
          }
        >
          <IconAcorn height={height} width={width} />
        </figure>
      );
    case "Anonymize":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-brightCyan"
              : "text-graylogo"
          }
        >
          <IconAnonymize height={height} width={width} />
        </figure>
      );
    case "Split by Sentence":
      if (isPipeline) {
        height = "14px";
        width = "14px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "23px";
        width = "23px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-skillPink"
              : "text-graylogo"
          }
        >
          <IconSplitBySentence height={height} width={width} />
        </figure>
      );
    case "Clustering":
      if (isPipeline) {
        height = "14px";
        width = "14px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "23px";
        width = "23px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-shadeBlue"
              : "text-graylogo"
          }
        >
          <IconClustering height={height} width={width} />
        </figure>
      );
    case "Split by Topic":
      if (isPipeline) {
        height = "14px";
        width = "14px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "23px";
        width = "23px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError
              ? "text-error"
              : isActive
              ? "text-skillPink"
              : "text-graylogo"
          }
        >
          <IconSplitByTopics height={height} width={width} />
        </figure>
      );
    case "Html-to-Article":
    case "Html-all-text":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError ? "text-error" : isActive ? "text-yellow" : "text-graylogo"
          }
        >
          <IconHTMLExtract height={height} width={width} />
        </figure>
      );
    case "Action Items":
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <figure
          className={
            isError ? "text-error" : isActive ? "text-rose" : "text-graylogo"
          }
        >
          <IconRecycle height={height} width={width} />
        </figure>
      );
    default:
      if (isPipeline) {
        height = "20px";
        width = "20px";
      } else if (isLibrary) {
        height = "18px";
        width = "18px";
      } else if (isDocsSmall) {
        height = "27px";
        width = "27px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      const type = getSkillTypeByName(skillName, contentFile);
      const foundType = contentFile?.skill_types?.find(
        (o: any) => o.id === type,
      );
      // Academy category
      if (type === 6) {
        return (
          <figure className={isActive ? "text-white" : "text-graylogo"}>
            <IconAcademy height={height} width={width} />
          </figure>
        );
      } else if (type === foundType?.id) {
        if (isPipeline) {
          height = "20px";
          width = "20px";
        } else if (isLibrary) {
          height = "18px";
          width = "18px";
        } else if (isDocsSmall) {
          height = "27px";
          width = "27px";
        } else if (isDocsLarge) {
          height = "40px";
          width = "40px";
        }
        return (
          <figure
            className={
              isError ? "text-error" : isActive ? "text-rose" : "text-graylogo"
            }
          >
            <IconRecycle height={height} width={width} />
          </figure>
        );
      } else {
        return (
          <figure
            className={
              isError
                ? "text-error"
                : isActive
                ? "text-shadeBlue"
                : "text-graylogo"
            }
          >
            <IconTooltip height={height} width={width} />
          </figure>
        );
      }
  }
}
export function defaultAccordions() {
  let accordionObject: any = {};
  Object.entries(contentFile?.documentation).forEach(([key, value]) => {
    if (value.default_open === true) {
      accordionObject[value.name] = true;
    }
  });
  return accordionObject;
}

export function getItemType(itemName: string) {
  const currentAPI = contentFile?.documentation?.find((apiSections: any) => {
    return apiSections.items.find((item: any) => {
      return item?.item_name === itemName;
    });
  });
  return currentAPI?.type || "Item name";
}
export function getItemIcon(itemName: string, type: skillIconType) {
  const isDocsSmall = type === "DOCS_SMALL";
  const isDocsLarge = type === "DOCS_LARGE";

  let width = "";
  let height = "";

  switch (itemName) {
    case "Overview":
      if (isDocsSmall) {
        height = "24px";
        width = "24px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <IconCircle height={height} width={width} className="text-skillPink" />
      );
    case "Getting Started":
      if (isDocsSmall) {
        height = "24px";
        width = "19px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <IconFlag height={height} width={width} className="text-shadeBlue" />
      );
    case "Request Structure":
      if (isDocsSmall) {
        height = "24px";
        width = "19px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <IconHexagon height={height} width={width} className="text-yellow" />
      );

    case "Pipeline Setup":
      if (isDocsSmall) {
        height = "24px";
        width = "19px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <IconGear height={height} width={width} className="text-skillPink" />
      );

    case "Expected Input Format":
      if (isDocsSmall) {
        height = "24px";
        width = "19px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <IconReceipt height={height} width={width} className="text-yellow" />
      );

    case "Response Structure":
      if (isDocsSmall) {
        height = "24px";
        width = "19px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <IconStack height={height} width={width} className="text-shadeBlue" />
      );

    case "Errors":
      if (isDocsSmall) {
        height = "24px";
        width = "19px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return (
        <IconTrafficCone
          height={height}
          width={width}
          className="text-skillPink"
        />
      );
    default:
      if (isDocsSmall) {
        height = "22px";
        width = "22px";
      } else if (isDocsLarge) {
        height = "40px";
        width = "40px";
      }
      return <IconSquare height={height} width={width} className="text-rose" />;
  }
}
export const generateOutputTitle = (item: any) => {
  switch (item.value) {
    case "enhance":
      return "Proof-read";
    case "summarize":
      return "Summarize";
    case "html-extract-article":
      return "Html-to-Article";
    case "business-entities":
      return "Business Entities";
    default:
      return "Original Text";
  }
};

export const getTypeColor = (skillType: string | number, contentFile: any) => {
  const type = contentFile?.skill_types?.find((o: any) => o.id === skillType);
  if (type && type?.color) {
    return `${type?.color?.trim()}`;
  } else {
    switch (toLower(String(skillType))) {
      case "1":
      case toLower(getSkillTypeNameByID(1, contentFile)):
        return theme.colors.brightYellow;
      case "2":
      case toLower(getSkillTypeNameByID(2, contentFile)):
        return theme.colors.brightCyan;
      case "3":
      case toLower(getSkillTypeNameByID(3, contentFile)):
      case "lab skill":
        return theme.colors.rose;
    }
  }
};

export const PreviewLoader = () => (
  <div className="grid items-center grid-flow-row justify-center h-full">
    <div>
      <p className="text-center text-black dark:text-white ">
        Loading preview..
      </p>
      <LottieLoader />
    </div>
  </div>
);

export const getContentType = (
  ext: string,
  textArea: string,
  fileExists: boolean,
) => {
  switch (ext) {
    case ".aac":
    case ".cdf":
    case ".mp3":
    case ".ogg":
    case ".wav":
    case ".3gp":
      return textArea.startsWith("data:") || fileExists
        ? "audio/wav"
        : "application/json";
    default:
      return "application/json";
  }
};

export const isAudioFile = (fileName: string) => {
  switch (getFileExtension(fileName) || "") {
    case ".aac":
    case ".cdf":
    case ".mp3":
    case ".ogg":
    case ".wav":
    case ".3gp":
      return true;
    default:
      return false;
  }
};

export function defaultVideosAccordions() {
  let accordionObject: any = {};
  Object.entries(contentFile?.video_categories).forEach(([key, value]) => {
    if (value.video_category_open === true) {
      accordionObject[value.video_category_name] = true;
    }
  });
  return accordionObject;
}

export function getOpenedAccordionArray(accordion: any) {
  let accordionsArray: any = [];
  if (accordion) {
    Object.entries(accordion).forEach(([key, value]) => {
      if (value === true) {
        accordionsArray.push(key.replaceAll(" ", "+"));
      }
    });
  }
  return accordionsArray?.join("%2C");
}
export function isClusteringLabel(skillName: any) {
  return skillName?.split(" ").pop() === "Clustering";
}

export function getQueryParams(features: any) {
  const featureParams = getQueryFeatures();
  let mutatedArray: Array<FeatureType> = features;

  if (featureParams.length > 0) {
    for (let i = 0; i < featureParams.length; i++) {
      let featureIndex = features.findIndex(
        (feature: FeatureType) => feature.name === featureParams[i],
      );
      if (featureIndex !== -1) {
        mutatedArray = replaceItemAtIndex(mutatedArray, featureIndex, {
          name: featureParams[i],
          active: true,
        });
      }
    }
  }
  return mutatedArray;
}

export function lowerCaseFirstLetter(text: string) {
  return text.charAt(0)?.toLowerCase() + text.slice(1);
}

export function detectedSkillName(skillName: string, skillCount: number) {
  switch (skillName) {
    case "Summarize":
      return "Origins";
    case "Html-to-Article":
      if (skillCount > 1) {
        return "Headings";
      }
      return "Heading";
    default:
      return skillName;
  }
}

export function detectInputType(textArea: string) {
  const httpRegexp = new RegExp(
    // eslint-disable-next-line
    /^https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$/gi,
  );
  if (textArea?.trim()?.match(httpRegexp)) {
    return inputSelectionTypes.article;
  } else if (textArea?.length && Array.isArray(parseConversation(textArea))) {
    return inputSelectionTypes.conversation;
  } else {
    return inputSelectionTypes["article"];
  }
}

const indexOfNth = (
  string: string,
  char: string,
  nth: number,
  fromIndex = 0,
): number => {
  const indexChar = string.indexOf(char, fromIndex);
  if (indexChar === -1) {
    return -1;
  } else if (nth === 1) {
    return indexChar;
  } else {
    return indexOfNth(string, char, nth - 1, indexChar + 1);
  }
};

export function parseAcademyTextArea(textArea: string): any {
  try {
    return textArea
      ?.split(/={3,}/g)
      .map((item: string) => item.trim())
      .filter((item: string) => item);
  } catch (e) {
    return textArea;
  }
}

export function shouldParseAcademy(
  pipelineSkills: SkillsType[],
  inputType: any,
) {
  return (
    pipelineSkills.some((skill: SkillsType) => skill.category === 6) &&
    inputType === inputSelectionTypes.article.name?.toLowerCase()
  );
}

export function parseInput(shouldParse: boolean, input: string) {
  try {
    return shouldParse ? JSON.parse(input) : input;
  } catch (e) {
    console.error(e);
    return input;
  }
}

export function isSkillAllowed(skill: SkillsType, inputType: any) {
  if (inputType?.name === "Article" && skill.disallow_in_document === false) {
    return false;
  }
  if (
    inputType?.name === "Conversation" &&
    skill.disallow_in_dialog === false
  ) {
    return false;
  }
  return true;
}

export function convertInputType(inputType: string, isReversed: boolean) {
  switch (inputType) {
    case "Article":
      return isReversed ? "Dialog" : "Document";
    case "Conversation":
      return isReversed ? "Document" : "Dialog";
    default:
      return inputType;
  }
}

export function containsHTML(textArea: string) {
  return /<[a-z][\s\S]*>/i.test(textArea);
}

export function containsALink(textArea: string) {
  if (
    new RegExp(
      "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?",
    ).test(textArea)
  ) {
    return true;
  }
  return false;
}
export function isHTML(textArea: string) {
  const doc = new DOMParser().parseFromString(textArea, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

export const isValidURL = (urlString: string) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  );
  return urlPattern.test(urlString);
};

function removeTextLineProperty(arrayOfObjects: any) {
  try {
    if (!JSON.parse(arrayOfObjects)?.length) {
      return arrayOfObjects;
    }
    const res = JSON.parse(arrayOfObjects)?.map((object: any) => {
      delete object["text_line"];
      return object;
    });
    return JSON.stringify(res);
  } catch (e: any) {
    return arrayOfObjects;
  }
}
