module.exports = {
  darkMode: "class",
  content: [
    "./pages/**/*.js",
    "./pages/**/*.ts",
    "./pages/**/*.jsx",
    "./pages/**/*.tsx",
    "./components/**/*.js",
    "./components/**/*.ts",
    "./components/**/*.jsx",
    "./components/**/*.tsx",
    "./src/**/*.{js.jsx,ts,tsx,html}",
  ],
  theme: {
    colors: {
      dark: "#252525",
      white: "#FFFFFF",
      gray: "#F3F3F9",
      steelGray: "#272535",
      jsonYellowLight: "#F0D700",
      jsonYellowDark: "#F3F99A",
      lightgrey: "#E3E3E3",
      textLight: "#A2A2A2",
      middlegray: "#AAAAAA",
      gentlegray: "#FAFAFE",
      scorpionGray: "#5A5A5A",
      formsgray: "#878787",
      borderLight: "#D8DEE6",
      borderSmoke: "#ECECEC",
      graylogo: "#9b9b9b",
      lightred: "#de7e7e",
      purple: "#4F49B1",
      green: "#8FCD41",
      orange: "#EDB476",
      cyan: "#63DDDD",
      aqua: "#00FFFF",
      rose: "#F23DE9",
      turbo: "#FFE400",
      turboLight: "#FFF69A",
      dodgerBlue: "#4D4DFF",
      shadeBlue: "#435BFF",
      blue: "#435BFF",
      yellow: "#FAD71E",
      pink: "#DE7EDA",
      transparent: "transparent",
      darkgrey: "#555555",
      red: "#FF0000",
      black: "#000",
      catskill: "#f3f5f9",
      sirocco: "#748A8A",
      modalDark: "#1D1C27",
      modalOverlayDark: "rgba(0, 0, 0, 0.75)",
      modalOverlay: "rgba(255, 255, 255, 0.75)",
      grayBox: "#4F4F4F",
      grayBorder: "#BCC5D8",
      grayBorderLight: "#393939",
      outputGray: "#F7F9FF",
      disabledGray: "#BEBEBE",
      whiteGrayBorder: "#EEF1F5",
      faWhite: "#F6F9FF",
      hawkes: "#DBDBFD",
      marguerite: "6363C3",
      darkBackground: "#1d1c28",
      darkGrayBackground: "#272534",
      darkSkillDark: "#1d1c28",
      darkGrayOutput: "#7D7D7D",
      darkGrayBorder: "#363636",
      veryDarkGray: "#1A1A1A",
      geyser: "#D8DEE7",
      mineShaft: "#363636",
      tableGrayBody: "#5A5A5A",
      tableGrayHeader: "#A3A3A3",
      tableHeaderBorder: "#BCC5D8",
      tableHeaderBorderDark: "#787878",
      tableBodyBorder: "#F2F3F4",
      tableRowGray: "#C7C7C7",
      blueOpacity: "rgba(77, 77, 255, 0.6)",
      pearl: "#AAA6B5",
      mustard: "#B0AD59",
      darkPink: "#E9B0B0",
      lightBlack: "#1e1e1e",
      geraldine: "#F99090",
      dashedGray: "#B0B0B0",
      pinkAlert: "#FFF4F4",
      darkPurple: "#7676F0",
      strongPurple: "#4D4DFF",
      popoverGray: "#636363",
      grayButton: "#363636",
      newCyan: "#0DDDDD",
      turquoise: "#3E9E9E",
      lightRed: "#d0a7a6",
      darkSearch: "#A1A1A1",
      lightYellow: "#FFF500",
      grayButtons: "#B4B4B4",
      charadea: "#2E2C3C",
      topaz: "#747189",
      linkWater: "#E4EAF8",
      pigeonPost: "#AFBBD7",
      verdunGreen: "#655A00",
      cookieGray: "#D3D3D3",
      tooltipGray: "#959595",
      inputGray: "#989898",
      inputTabGrayDark: "#201F2A",
      borderBlue: "#4D4DFE",
      categoryCyan: "#0CFEFF",
      categoryDark: "#333043",
      categoryGray: "#CFCFCF",
      categoryItemBackgroundDark: "#1D1C27",
      categoryItemBackgroundDarkHover: "#171620",
      brightYellow: "#FFE800",
      skillPink: "#F13BE9",
      brightCyan: "#0CFEFF",
      oceanBlue: "#2188FF",
      darkGrayTab: "#302E40",
      inputBorderDarkGray: "#3D3A50",
      inputDarkGray: "#272533",
      lightCharade: "#2E2C3E",
      darkCharade: "#272636",
      numberGray: "#ACACAC",
      tabDarkGray: "#232230",
      tabLightGray: "#999999",
      darkBlueVideo: "#201F2F",
      darkBlueScroll: "#52506B",
      darkBlueScrollEmpty: "#2C2B3E",
      lightBlueScroll: "#CAD8F8",
      linkInactive: "#BCBCBC",
      specialGray: "#343437",
      activeAPIButton: "#2C293D",
      faSmoke: "#FAFAFA",
      codeNumberColor: "#cc99ff",
      codeStringLightColor: "#F0D700",
      codeStringDarkColor: "#999966",
      codeCommentColor: "#666699",
      usageBackground: "#323040",
      error: "#FF756C",
      outputHeaderValue: "#6F6B8A",
      outputHeaderBorderDark: "#35324C",
      outputTableValue: "#ADABBD",
      darkGray: "#222222",
      grayMedium: "#52515d",
      transparentGray: "#696969",
      neatWhite: "#f5f5f5",
      cyanDark: "#48D1CC",
      clusteringBlue: "#3D3CAC",
      clusteringGray: "#3D3D3D",
      darkRed: "#6D1515",
    },
    boxShadow: {
      md: "8px 8px 37px 0px rgba(0, 0, 0, 0.08)",
      none: "none",
      modalOverlay: "rgba(80, 80, 80, 0.75)",
      menu: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    },
    extend: {
      keyframes: {
        "fade-in": {
          "0%": {
            opacity: "0",
          },
          "100%": {
            opacity: "1",
          },
        },
        "fade-in-up": {
          "0%": {
            opacity: "0",
            transform: "translateY(10px)",
          },
          "100%": {
            opacity: "1",
            transform: "translateY(0)",
          },
        },
        "fade-in-down": {
          "0%": {
            opacity: "0",
            transform: "translateY(0)",
          },
          "100%": {
            opacity: "1",
            transform: "translateY(10px)",
          },
        },
        "grow-up": {
          "0%": {
            transform: "scale(0)",
          },
          "100%": {
            transform: "scale(1)",
          },
        },
      },
      animation: {
        "fade-in-up": "fade-in-up 0.3s ease-out",
        "fade-in-down": "fade-in-down 0.3s ease-out",
        "grow-up": "grow-up 0.3s ease-out",
      },
      gridTemplateColumns: {
        "auto-1fr": "auto 1fr",
        "1fr-auto": "1fr auto",
        "1fr-2fr": "1fr 2fr",
        "2fr-1fr": "2fr 1fr",
        "3fr-1fr": "3fr 1fr",
        "1fr-1fr": "1fr 1fr",
        "1fr-8fr": "1fr 8fr",
        "1fr-6fr-1fr": "1fr 6fr 1fr",
        "auto-1fr-auto": "auto 1fr auto",
        "auto-auto-1fr": "auto auto 1fr",
        "1fr-1fr-auto": "1fr 1fr auto",
        "1fr-auto-auto": "1fr auto auto",
        "2fr-1fr-auto": "2fr 1fr auto",
        "2-auto": "repeat(2, auto)",
        "3-auto": "repeat(3, auto)",
        "4-auto": "repeat(4, auto)",
        "minmax-auto": "auto minmax(0, 1fr)",
        "pipeline-skills-panel": "350px 8px 1fr",
      },
      gridTemplateRows: {
        "auto-1fr": "auto 1fr",
        "1fr-auto": "1fr auto",
        "1fr-9fr-1fr": "1fr 9fr 1fr",
        "1fr-1fr": "1fr 1fr",
        "1fr-2fr": "1fr 2fr",
        "2fr-1fr": "2fr 1fr",
        "1fr-8fr": "1fr 8fr",
        "auto-1fr-1fr": "auto 1fr 1fr",
        "auto-1fr-1fr-1fr": "auto 1fr 1fr 1fr",
        "auto-1fr-auto": "auto 1fr auto",
        "auto-auto-1fr": "auto auto 1fr",
        "auto-auto-auto-1fr": "auto auto auto 1fr",
        "auto-auto-1fr-auto": "auto auto 1fr auto",
        "1fr-1fr-auto": "1fr 1fr auto",
        "1fr-6fr-1fr": "1fr 6fr 1fr",
        "1fr-auto-auto": "1fr auto auto",
        "2-auto": "repeat(2, auto)",
        "3-auto": "repeat(3, auto)",
        "4-auto": "repeat(4, auto)",
        "0.5fr-1fr": "0.5fr 1fr",
        "pipeline-input-panel": "2fr 8px 1fr",
        "pipeline-output-panel": "2fr 8px 1fr",
        "drag-3-2": "3fr 8px 2fr",
      },
      gridAutoRows: {
        "2fr": "minmax(0, 2fr)",
      },
      inset: {
        "235px": "235px",
      },
      margin: {
        "0-0-0-auto": "margin: 0 0 0 auto",
      },

      height: {
        400: "400px",
        500: "500px",
        600: "600px",
        700: "700px",
        740: "740px",
        "fit-content": "fit-content",
      },
      width: {
        235: "235px",
        "fit-content": "fit-content",
        "table-width": "90%",
      },
      fontWeight: {
        normal: "200",
        medium: "300",
        semibold: "300",
        bold: "300",
        extrabold: "500",
      },
      minWidth: { "250px": "250px" },
      fontSize: {
        xxs: ["0.625rem", { lineHeight: "0.75rem" }],
        md: "1rem",
        logo: "26px",
      },
      fontFamily: {
        roboto: ["Roboto", "sans-serif"],
        montserrat: ["Montserrat", "sans-serif"],
        poppins: ["Poppins", "sans-serif"],
        mono: ["IBM Plex Mono", "monospace"],
      },
      letterSpacing: {
        "widest-mobile": "0.23em",
        "tracking-tight": "-0.03em",
      },
    },
  },
  variants: {
    extend: {
      shadow: ["dark"],
      borderRadius: {
        xl: "1.6875rem",
      },
    },
  },
  plugins: [],
};
