import {
  getAdditionalUserInfo,
  GithubAuthProvider,
  GoogleAuthProvider,
  linkWithPopup,
  signInWithCredential,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { queryClient } from "../../App";
import {
  anonymousePreNavigationAtom,
  experimentFinishTypeAtom,
  experimentModalAtom,
  forgotPasswordTokenAtom,
  loginFormAtom,
  registrationTokenAtom,
  shadowSignupAtom,
  shouldShowExperimentAtom,
  userDataAtom,
  useResetUser,
  userVisibleKeyAtom,
} from "../../lib/atoms";
import { eventLogger, useEventLogger, UserEvent } from "../../lib/event_logger";
import {
  APIKey,
  emptyOrganizationData,
  emptyUserData,
  FinishType,
  IOrganization,
  IOrganizationFetch,
  ProviderType,
  UserData,
  UserFirebase,
} from "../../lib/interfaces";
import { isOneAiEmail, isStaging } from "../../pages/PipelinePage/utils";
import { KeyPopupType } from "../ApiKeys/popover";
import {
  auth,
  functions,
  githubProvider,
  googleProvider,
  segmentAnalytics,
  useUser,
} from "./auth";

const userCredentialAtom = atom<any>({
  key: "userCredentialAtom",
  default: null,
});
const additionalUserInfoAtom = atom<any>({
  key: "additionalUserInfoAtom",
  default: null,
});

export const fireEventAtom = atom<any>({
  key: "fireEventAtom",
  default: null,
});

const getUserDataKey = "getUserDataKey";

export const LoginSignupListener = () => {
  const [fireEvent, setFireEvent] = useRecoilState(fireEventAtom);
  const userCredential = useRecoilValue(userCredentialAtom);
  const additionalUserInfo = useRecoilValue(additionalUserInfoAtom);
  const userData = useRecoilValueLoadable(userDataAtom);
  const setRegistrationToken = useSetRecoilState(registrationTokenAtom);
  const setForgotPasswordToken = useSetRecoilState(forgotPasswordTokenAtom);
  const setAnonymousePreNavigation = useSetRecoilState(
    anonymousePreNavigationAtom,
  );
  const [shadowSignup, setShadowSignup] = useRecoilState(shadowSignupAtom);

  useEffect(() => {
    if (fireEvent && !!userCredential && userData?.contents?.studio_key) {
      const eventData = {
        uid: userCredential.uid,
        email: userCredential.email,
        display_name: userCredential?.displayName,
        api_key: userData?.contents.studio_key,
        org_id: userData?.contents.org_ref,
        user_id: userCredential.uid,
        sign_type: fireEvent === "Email Signup" ? "Email" : fireEvent,
      };
      if (additionalUserInfo?.isNewUser || shadowSignup) {
        eventLogger(UserEvent.USER_CREATED, eventData);
        eventLogger(UserEvent.SIGNUP, eventData);
        eventLogger(UserEvent.LOGIN, eventData);
      } else {
        eventLogger(UserEvent.LOGIN, eventData);
        fireSIGNUPEventForExistingUser(userCredential, eventData);
      }
      mixpanel.identify(userCredential.uid);
      segmentAnalytics.identify(userCredential.uid);
      // console.log("Sending identify event");
      setAnonymousePreNavigation(false);
      setRegistrationToken("");
      setForgotPasswordToken("");
      setShadowSignup(false);
      setFireEvent(null);
      queryClient.invalidateQueries("getUserDataKey");
    }

    //eslint-disable-next-line
  }, [userData, userCredential, additionalUserInfo, fireEvent]);
  return null;
};

function useAuth() {
  const setFireEvent = useSetRecoilState(fireEventAtom);
  const setUserCredential = useSetRecoilState(userCredentialAtom);
  const setAdditionalUserInfo = useSetRecoilState(additionalUserInfoAtom);
  const setUserVisibleKey = useSetRecoilState(userVisibleKeyAtom);
  const setLoginForm = useSetRecoilState(loginFormAtom);
  const setShadowSignup = useSetRecoilState(shadowSignupAtom);
  const setExperimentModal = useSetRecoilState(experimentModalAtom);
  const [experiment, setExperiment] = useRecoilState(shouldShowExperimentAtom);
  const experimentFinishType = useRecoilValue(experimentFinishTypeAtom);
  const setAnonymousePreNavigation = useSetRecoilState(
    anonymousePreNavigationAtom,
  );
  const navigate = useNavigate();
  const { resetUser } = useResetUser();

  const location = useLocation();
  const logout = () => {
    console.debug("[useAuth::logout]");
    signOut(auth);
    setAnonymousePreNavigation(false);
    resetUser();
  };

  const loginSignup = async (chosenProvider: ProviderType) => {
    const isAnnon = auth?.currentUser?.isAnonymous;
    const anonUser = auth?.currentUser;
    const getProvider =
      chosenProvider === "Google" ? googleProvider : githubProvider;

    setFireEvent(chosenProvider);
    if (anonUser && isAnnon) {
      // Anonymous signs in with Google
      try {
        const userCredential = await linkWithPopup(anonUser, getProvider);
        setShadowSignup(true);
        if (userCredential) {
          await updateProfile(anonUser, {
            displayName:
              userCredential?.user?.providerData[0]?.displayName || "",
            photoURL: userCredential?.user?.providerData[0]?.photoURL || "",
          });

          const additionalUserInfo = getAdditionalUserInfo(userCredential);

          setAdditionalUserInfo(additionalUserInfo);
          setUserCredential({
            uid: userCredential.user?.uid,
            email: userCredential?.user?.providerData[0]?.email || undefined,
            displayName:
              userCredential?.user?.providerData[0]?.displayName || undefined,
            isAnonymous: false,
          });
          setAdditionalUserInfo({
            isNewUser: additionalUserInfo?.isNewUser,
            createdAt: new Date(
              userCredential.user.metadata.creationTime || "",
            ).toISOString(),
          });

          setLoginForm(false);
          if (location.pathname === "/login") {
            navigate("/");
          }

          const { isNewUser } = getAdditionalUserInfo(userCredential) as any;

          await increaseOrganizationQuota({
            email: userCredential?.user?.providerData[0]?.email,
          });

          setUserVisibleKey(true);
          await sendRegistrationEmail({
            emailFields: {
              email: userCredential?.user?.providerData[0]?.email,
              name: userCredential?.user?.providerData[0]?.displayName,
            },
            prefix: isStaging() ? "[STAGING]" : "",
          });

          if (experiment) {
            if (experimentFinishType === FinishType.COPY_CODE) {
              setExperimentModal(true);
            }
            setExperiment(false);
          }

          return { userInstance: userCredential, isNewUser };
        }
      } catch (error: any) {
        if (error?.code === "auth/credential-already-in-use") {
          const credential: any =
            chosenProvider === "Google"
              ? GoogleAuthProvider.credentialFromError(error)
              : GithubAuthProvider.credentialFromError(error);

          try {
            const existingUserLogin = await signInWithCredential(
              auth,
              credential,
            );
            if (existingUserLogin) {
              setLoginForm(false);
              const anonUid = anonUser.uid;
              await deleteAnonymousUser({ anonUid });
              const additionalUserInfo =
                getAdditionalUserInfo(existingUserLogin);
              setUserCredential({
                uid: existingUserLogin.user?.uid,
                email: existingUserLogin.user?.email || undefined,
                displayName: existingUserLogin.user?.displayName || undefined,
              });
              setAdditionalUserInfo({
                isNewUser: additionalUserInfo?.isNewUser,
                createdAt: new Date(
                  existingUserLogin.user.metadata.creationTime || "",
                ).toISOString(),
              });

              if (experiment) {
                if (experimentFinishType === FinishType.COPY_CODE) {
                  setExperimentModal(true);
                }
                setExperiment(false);
              }
            }
          } catch (error: any) {
            console.log(error);
          }
        }
      }
    } else {
      // User signs up with provider (WHEN ANONYMOUS FEATURE IS OFF)
      const userCredential = await signInWithPopup(auth, getProvider);
      const additionalUserInfo = getAdditionalUserInfo(userCredential);

      setUserCredential({
        uid: userCredential.user?.uid,
        email: userCredential.user?.email || undefined,
        displayName: userCredential.user?.displayName || undefined,
      });

      setAdditionalUserInfo({
        isNewUser: additionalUserInfo?.isNewUser,
        createdAt: new Date(
          userCredential.user.metadata.creationTime || "",
        ).toISOString(),
      });

      await _createKey({
        allowed_skills: ["ALL"],
        type: "USER_CREATED",
      });

      setUserVisibleKey(true);
      const { isNewUser } = getAdditionalUserInfo(userCredential) as any;

      setLoginForm(false);
      // Sends registration email and fire signup events to users who sign up via google (not anonymous)
      if (
        isNewUser &&
        userCredential?.user?.email &&
        !userCredential?.user?.email.includes("@example.com")
      ) {
        await sendWelcomeEmail(userCredential?.user);
      }
      return { userInstance: userCredential, isNewUser };
    }
  };

  const safeMail = () => {
    return auth !== null ? isOneAiEmail(auth.currentUser?.email || "") : false;
  };

  return { safeMail, loginSignup, logout };
}

async function createAnonymousKeys(variant: string) {
  const reuslt = await _createKey({
    name: "default key",
    allowed_skills: ["ALL"],
    type: "STUDIO",
    isAnonymousLogin: true,
    rate_limit: 100,
    ...(variant === "2" && { anonymous_key: true }),
  });

  console.log("result :: ", reuslt);

  const result2 = await _createOrganization({
    data_save_method: "Regular",
    daily_quota: 100_000,
    monthly_quota: 1_000_000,
  });

  console.log("result2 :: ", result2);
  return {
    success: true,
    message: "Anonymous keys created",
  };
}
const fireSIGNUPEventForExistingUser = (
  userCredential: any,
  eventData: any,
) => {
  if (parseInt(userCredential.createdAt) < 1644823399557) {
    eventLogger(UserEvent.SIGNUP, eventData);
  }
};

const getUserData = async ({ queryKey }: any) => {
  // queryKey[1] holds the user id
  const res = await getUserDocument(queryKey[1]);
  return res;
};

const useUserData = () => {
  const { user } = useUser();
  const {
    data: value,
    // isLoading is only set to true if there is no data before while fetching
    // Use isFetching to indicate the fetching state
    isFetching,
    isLoading: loading,
    error,
  } = useQuery<any>([getUserDataKey, user?.uid], getUserData, {
    // The query will not execute until the user?.uid exists
    enabled: !!user?.uid,
    placeholderData: emptyUserData(),
  });

  const safeMail = isOneAiEmail(user?.email || "");
  const isAdmin =
    value?.is_admin === true &&
    (value?.role === "Admin" ||
      value?.role === "Editor" ||
      value?.role === "Viewer") &&
    safeMail;

  const isReady = value?.uid !== "";
  return {
    isReady,
    value,
    loading,
    isFetching,
    error,
    safeMail,
    isAdmin,
  };
};

async function mutateUserDocument({ payload }: { payload: any }) {
  const res = await mutateUser({
    payload,
  });

  queryClient.invalidateQueries("getUserDataKey");
  console.log("Res is :: ", res);
  return res;
}
export const sendWelcomeEmail = async (user: UserFirebase) => {
  return await sendRegistrationEmail({
    emailFields: {
      email: user?.email,
      name: user?.displayName ? user?.displayName : "Dear User",
    },
    prefix: isStaging() ? "[STAGING]" : "",
  });
};

const useMutateUser = () => {
  const { value: user } = useUserData();

  type UserFields = {
    name: string;
    position: string;
    company: string;
    company_size: string;
    email: string;
    phone?: string;
    completed_signup: boolean;
    experiment_count?: number;
  };

  const mutateFirebaseUserPreferences = async (data: any) => {
    try {
      if (user?.uid) {
        const newData = { ...user.preferences, ...data };
        const firestoreRes = await mutateFirebaseUser({
          preferences: {
            ...newData,
          },
        });
        console.debug("[mutateFirebaseUserPreferences]", firestoreRes);
        return {
          success: true,
        };
      }
    } catch (error) {
      console.error(error);
      return {
        sucess: false,
        error: "Firebase error",
      };
    }
  };

  const mutation = useMutation(mutateUserDocument, {
    onSuccess: () => {
      console.debug("[useMutateUser] Succeeded");
    },
    onError: (error: any) => {
      console.log("Error :: ", error);
    },
  });

  const fillSignupData = async (data: UserFields) => {
    mutation.mutate({
      payload: { ...data },
    });
  };

  const finishExperiment = async (experiment_limit: number) => {
    mutation.mutate({
      payload: {
        experiment_count: experiment_limit + 1,
      },
    });
  };
  const increaseExperimentCount = async () => {
    mutation.mutate({
      payload: {
        experiment_count: (user?.experiment_count || 1) + 1,
      },
    });
  };

  const mutateUser = async (data: any) => {
    mutation.mutate({
      payload: {
        ...data,
      },
    });
  };

  return {
    mutateUser,
    fillSignupData,
    increaseExperimentCount,
    mutateFirebaseUserPreferences,
    finishExperiment,
  };
};

type UserFirebaseFields = {
  clientId: string;
  field: "is_admin" | "role";
  newValue: string | number | boolean;
};

type OrgFirebaseFields = {
  orgId: string;
  field: "data_save_method" | "status" | "daily_quota" | "monthly_quota";
  newValue: string | number | boolean;
};
const useAdminMutate = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { value } = useUserData();

  const mutateUserField = async ({
    clientId,
    field,
    newValue,
  }: UserFirebaseFields) => {
    try {
      if (value?.uid) {
        const mutateUserByUserID = {
          clientId: clientId,
          newObject: {
            [field]: newValue,
          },
        };
        const result = await adminMutateUser(mutateUserByUserID);
        setLoading(false);
        return result;
      } else {
        setLoading(false);
        return {
          sucess: false,
          error: "No user found.",
        };
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      return {
        sucess: false,
        error: "Firebase error",
      };
    }
  };
  const mutateOrganizationField = async ({
    orgId,
    field,
    newValue,
  }: OrgFirebaseFields) => {
    try {
      if (value?.uid) {
        const mutateOrgByID = {
          orgId,
          field,
          newValue,
        };
        const result = await adminMutateOrganization(mutateOrgByID);
        setLoading(false);
        return result;
      } else {
        setLoading(false);
        return {
          sucess: false,
          error: "No user found.",
        };
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      return {
        sucess: false,
        error: "Firebase error",
      };
    }
  };

  return {
    mutateUserField,
    mutateOrganizationField,
    loading,
  };
};

const useUserOrganization = () => {
  const { user } = useUser();
  const {
    data: value,
    // isLoading is only set to true if there is no data before while fetching
    // Use isFetching to indicate the fetching state
    isFetching,
    isLoading: loading,
    error,
  } = useQuery<any>([getUserDataKey, user?.uid], getUserData, {
    // The query will not execute until the user?.uid exists
    enabled: !!user?.uid,
    placeholderData: emptyOrganizationData(),
  });

  return {
    value,
    isFetching,
    loading,
    error,
  };
};

const organizationKeysState = atom({
  key: "organizationKeysState",
  default: [] as Array<APIKey>,
});

const useDeleteOrganizationKey = (apiKey: APIKey) => {
  const [api_keys, setKeys] = useRecoilState(organizationKeysState);
  const [loading, setLoading] = useState<boolean>(false);
  const { value } = useUserData();
  const deleteAPIKey = async () => {
    const apiKeyAtIndex = api_keys.findIndex(
      (existingKeys: APIKey) => apiKey.token_id === existingKeys.token_id,
    );
    let new_api_keys = [...api_keys];
    if (apiKeyAtIndex >= 0) {
      try {
        setLoading(true);
        await deleteKey(value?.org_ref, apiKey.token_id);
        new_api_keys = removeItemAtIndex(new_api_keys, apiKeyAtIndex);
        setLoading(false);
        setKeys(new_api_keys);
      } catch (error) {}
    }
  };
  return { deleteAPIKey, loading };
};

const useMutateOrganizationKey = (apiKey: APIKey) => {
  const [api_keys, setKeys] = useRecoilState(organizationKeysState);
  const [loading, setLoading] = useState<boolean>(false);
  const { value } = useUserData();
  const mutateAPIKey = async (data: any) => {
    const apiKeyAtIndex = api_keys.findIndex(
      (existingKeys: APIKey) => apiKey.token_id === existingKeys.token_id,
    );
    let new_api_keys = [...api_keys];
    if (apiKeyAtIndex >= 0) {
      if (data?.daily_quota && data?.daily_quota === -1)
        delete data.daily_quota;

      try {
        setLoading(true);
        const result = await mutateKey(value?.org_ref, apiKey.token_id, data);
        const newKey = { ...new_api_keys[apiKeyAtIndex], ...data };
        new_api_keys = replaceItemAtIndex(new_api_keys, apiKeyAtIndex, newKey);
        setLoading(false);
        setKeys(new_api_keys);
        return result?.data;
      } catch (error) {}
    }
  };
  const revokeAPIKey = async () => {
    mutateAPIKey({ state: "REVOKED" });
  };
  const activateAPIKey = async () => {
    mutateAPIKey({ state: "ACTIVE" });
  };

  const editAPIKey = async ({
    name,
    allowed_skills,
    daily_quota,
  }: {
    name: string;
    allowed_skills: Array<string>;
    daily_quota: number;
  }) => {
    return mutateAPIKey({
      name,
      allowed_skills,
      daily_quota,
    });
  };
  return { mutateAPIKey, revokeAPIKey, activateAPIKey, editAPIKey, loading };
};

function removeItemAtIndex(arr: Array<any>, index: number) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

function replaceItemAtIndex(arr: Array<any>, index: number, newItem: any) {
  return [...arr.slice(0, index), newItem, ...arr.slice(index + 1)];
}

const useUserOrganizationKeys = () => {
  const [api_keys, setKeys] = useRecoilState(organizationKeysState);
  const userVisibleKey = useRecoilValue(userVisibleKeyAtom);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const { value: value_ } = useUserData();
  const setLocalKey = (key: APIKey) => {
    setKeys([...api_keys, key] as any);
  };
  useEffect(() => {}, [api_keys]);
  useEffect(() => {
    async function fetchKeys() {
      try {
        if (value_?.org_ref) {
          setLoading(true);
          const keys = await getKeys(value_?.org_ref);
          if (keys) {
            setKeys(keys);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError(error);
        setLoading(false);
      }
    }
    fetchKeys();
  }, [value_?.org_ref, setKeys, userVisibleKey]);
  return { api_keys, loading, error, setLocalKey };
};

const _createKey = httpsCallable(functions, "createKey");
const _createOrganization = httpsCallable(functions, "createOrganization");
const _getKeys = httpsCallable(functions, "getKeys");
const _getUserDocument = httpsCallable(functions, "getUserDocument");
const _getOrganization = httpsCallable(functions, "getOrganization");
const _deleteKey = httpsCallable(functions, "deleteKey");
const _mutateKey = httpsCallable(functions, "mutateKey");
const _mutateUser = httpsCallable(functions, "mutateUser");
const _mutateFirebaseUser = httpsCallable(functions, "mutateFirebaseUser");
const _mutateContentObject = httpsCallable(functions, "mutateContentObject");
const _resetContentDraft = httpsCallable(functions, "resetContentDraft");
const _mutateDraftObject = httpsCallable(functions, "mutateDraftObject");
const _deleteAnonymousUser = httpsCallable(functions, "deleteAnonymousUser");
const _changeUserPassword = httpsCallable(functions, "changeUserPassword");
const _getOrganizations = httpsCallable(functions, "getOrganizations");
const _getAdmins = httpsCallable(functions, "getAdmins");
const _inviteAdmin = httpsCallable(functions, "inviteAdmin");
const _adminMutateUser = httpsCallable(functions, "adminMutateUser");
const _adminMutateOrganization = httpsCallable(
  functions,
  "adminMutateOrganization",
);
const _mutateFirebaseOrganization = httpsCallable(
  functions,
  "mutateFirebaseOrganization",
);
const _getAPIKeyByTokenId = httpsCallable(functions, "getAPIKeyByTokenId");
const _getUserOrganizationData = httpsCallable(
  functions,
  "getUserOrganizationData",
);

const _sendQuotaRequestEmail = httpsCallable(
  functions,
  "sendQuotaRequestEmail",
);

const _emailEvent = httpsCallable(functions, "emailEvent");

const _increaseOrganizationQuota = httpsCallable(
  functions,
  "increaseOrganizationQuota",
);

const _billingHandler = httpsCallable(functions, "billingHandler");

const billingHandler = async (data: any) => {
  const result: any = await _billingHandler(data);
  return result?.data;
};

const getAPIKeyByTokenId = async (data: any) => {
  const result: any = await _getAPIKeyByTokenId(data);
  return result?.data;
};
const _getTokenDigest = httpsCallable(functions, "getTokenDigest");

const increaseOrganizationQuota = async (data: any) => {
  const result: any = await _increaseOrganizationQuota(data);
  return result?.data;
};

const _sendRegistrationEmail = httpsCallable(
  functions,
  "sendRegistrationEmail",
);

// const _sendSkillSuggestionEmail = httpsCallable(
//   functions,
//   "sendSkillSuggestionEmail",
// );

const mutateContentObject = async (data: any) => {
  const result: any = await _mutateContentObject(data);
  return result?.data;
};

const resetContentDraft = async (data: any) => {
  const result: any = await _resetContentDraft(data);
  return result?.data;
};

const mutateDraftObject = async (data: any) => {
  const result: any = await _mutateDraftObject(data);
  return result?.data;
};

const _createTempUser = httpsCallable(functions, "createTempUser");
const _forgotPassword = httpsCallable(functions, "forgotPassword");

const _validateTempUser = httpsCallable(functions, "validateTempUser");
const _verifyForgotPassword = httpsCallable(functions, "verifyForgotPassword");
const _resendValidationEmail = httpsCallable(
  functions,
  "resendValidationEmail",
);

const resendValidationEmail = async (data: any) => {
  console.log("Sending email validation: ", data);
  const result: any = await _resendValidationEmail({ email: data });
  return result?.data;
};

const forgotPassword = async (email: any) => {
  const result: any = await _forgotPassword({ email });
  return result?.data;
};

const createTempUser = async (userCredential: any) => {
  const result: any = await _createTempUser({ userCredential });
  return result?.data;
};

const getTokenDigest = async (token: any) => {
  const result: any = await _getTokenDigest(token);
  return result?.data;
};

const validateTempUser = async (token: any) => {
  const result: any = await _validateTempUser({ token });
  return result?.data;
};

const verifyForgotPassword = async (token: any) => {
  const result: any = await _verifyForgotPassword({ token });
  return result?.data;
};
const changeUserPassword = async (data: any) => {
  const result: any = await _changeUserPassword(data);
  return result?.data;
};
const sendRegistrationEmail = async ({ emailFields, prefix }: any) => {
  const result: any = await _sendRegistrationEmail({ emailFields, prefix });
  return result?.data;
};

const mutateUser = async (data: object) => {
  const result: any = await _mutateUser(data);
  return result;
};

const mutateFirebaseUser = async (data: object) => {
  const result: any = await _mutateFirebaseUser(data);
  return result;
};

const deleteAnonymousUser = async (data: object) => {
  const result: any = await _deleteAnonymousUser(data);
  return result;
};
const adminMutateUser = async (data: object) => {
  const result: any = await _adminMutateUser(data);
  return result;
};

const mutateFirebaseOrganization = async (data: object) => {
  const result: any = await _mutateFirebaseOrganization(data);
  return result;
};

type MutateDataObjectType = {
  orgId: string;
  field: any;
  newValue: any;
};
const adminMutateOrganization = async (data: MutateDataObjectType) => {
  const result: any = await _adminMutateOrganization(data);
  return result;
};

const deleteKey = async (orgId: string, tokenId: string) => {
  const result: any = await _deleteKey({ orgId, tokenId });
  return result;
};

const mutateKey = async (orgId: string, tokenId: string, data: any) => {
  const result: any = await _mutateKey({ orgId, tokenId, data });
  return result;
};

const getKeys = async (orgId: string): Promise<APIKey[]> => {
  const result: any = await _getKeys(orgId);
  const keys = result.data;
  return keys;
};

const getUserDocument = async (userId: string): Promise<UserData> => {
  const result: any = await _getUserDocument(userId);
  return result.data;
};

const getOrganization = async (orgId: string): Promise<IOrganization> => {
  const result: any = await _getOrganization(orgId);
  return result?.data;
};

const getUserOrganizationData = async (): Promise<any> => {
  const result: any = await _getUserOrganizationData();
  return result?.data;
};

const getOrganizations = async (
  limit: number,
  search?: string,
): Promise<IOrganizationFetch> => {
  const result: any = await _getOrganizations({ limit, search });
  const organizationsArray = result?.data;
  return organizationsArray;
};

const inviteAdmin = async (emailAddress: string) => {
  const result: any = await _inviteAdmin({ emailAddress });
  return result;
};

const getAdmins = async (limit: number): Promise<Array<UserData>> => {
  const result: any = await _getAdmins({ limit });
  const users = result?.data;
  return users;
};

const sendQuotaRequestEmail = async ({ formFields, prefix }: any) => {
  const result: any = await _sendQuotaRequestEmail({
    formFields,
    prefix,
  });
  return result?.data;
};
const emailEvent = async ({ title, body }: any) => {
  const result: any = await _emailEvent({ title, body });
  return result?.data;
};

const useMutateKey = () => {
  const { createKey: mutateKey }: any = useCreateKey("MUTATE");
  return mutateKey;
};
const useCreateKey = (popupType: KeyPopupType = "CREATE") => {
  const { setLocalKey } = useUserOrganizationKeys();
  const { userEventLogger } = useEventLogger();
  const createKey = async ({
    name = "",
    allowed_skills = [],
    token_id = "",
    rate_limit = 100,
    daily_quota = 100,
    type = "",
    state = "ACTIVE",
  }: APIKey) => {
    try {
      const key = {
        name,
        allowed_skills,
        token_id,
        rate_limit,
        daily_quota,
        type,
        state,
      };
      let result: any;
      if (popupType === "CREATE") {
        userEventLogger(UserEvent.API_KEY_GENERATION);
        result = await _createKey(key);
      } else {
        result = await _mutateKey(key);
      }
      console.debug("[settings :: createKey]", result.data);
      setLocalKey(result.data.key);
      return result.data;
    } catch (error: any) {
      const code = error.code;
      const message = error.message;
      const details = error.details;
      console.error("[settings :: createKey], error", code, message, details);
      return null;
    }
  };
  return { createKey };
};

export {
  useAdminMutate,
  useUserData,
  useUserOrganization,
  useUserOrganizationKeys,
  useMutateUser,
  mutateUser,
  getOrganizations,
  getUserOrganizationData,
  getAdmins,
  inviteAdmin,
  getTokenDigest,
  useDeleteOrganizationKey,
  useMutateOrganizationKey,
  useCreateKey,
  useMutateKey,
  getKeys,
  getAPIKeyByTokenId,
  organizationKeysState,
  useAuth,
  sendQuotaRequestEmail,
  emailEvent,
  sendRegistrationEmail,
  createAnonymousKeys,
  // sendSkillSuggestionEmail,
  createTempUser,
  validateTempUser,
  resendValidationEmail,
  forgotPassword,
  verifyForgotPassword,
  changeUserPassword,
  mutateContentObject,
  mutateDraftObject,
  resetContentDraft,
  billingHandler,
  mutateFirebaseOrganization,
  getOrganization,
  getUserDocument,
  mutateFirebaseUser,
};
