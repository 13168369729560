import React from "react";
import FeedBackTooltip from "../../../../components/feedback-tooltip";
import ThumbsRectangle from "../../../../components/thumbs-rectangle";
import { FeedbackDataType, ThumbsType } from "../../../../lib/interfaces";

function ThumbsRectangleWrapper({
  setPopup,
  setThumb,
  thumb,
  data,
  feedbackText,
  setFeedbackText,
  labelOriginal,
}: {
  setPopup: (value: boolean) => void;
  setThumb: (value: ThumbsType) => void;
  thumb: ThumbsType;
  data: any;
  feedbackText: string;
  setFeedbackText: (value: string) => void;
  labelOriginal: any;
}) {
  return (
    <ThumbsRectangle
      setPopup={setPopup}
      thumb={thumb}
      setThumb={setThumb}
      feedbackText={feedbackText}
      setFeedbackText={setFeedbackText}
      data={data}
      labelOriginal={labelOriginal}
    />
  );
}

function FeedbackPopup({
  children,
  data,
  labelOriginal,
  feedbackOpen,
}: {
  children: any;
  data: FeedbackDataType;
  feedbackOpen?: any;
  labelOriginal?: any;
}) {
  const [popup, setPopup] = React.useState<boolean>(false);
  const [thumb, setThumb] = React.useState<ThumbsType>("NONE");
  const [feedbackText, setFeedbackText] = React.useState<string>("");

  return (
    <button
      tabIndex={0}
      className="relative"
      onClick={() => {
        setPopup(true);
      }}
      onMouseOver={() => {
        setPopup(true);
      }}
      onMouseLeave={() => {
        if (thumb !== "THUMBS_DOWN") {
          setPopup(false);
        }
      }}
    >
      <FeedBackTooltip
        open={popup || feedbackOpen}
        title={
          popup || feedbackOpen ? (
            <ThumbsRectangleWrapper
              data={data}
              labelOriginal={labelOriginal}
              setPopup={(value: boolean) => setPopup(value)}
              setThumb={(value: ThumbsType) => setThumb(value)}
              feedbackText={feedbackText}
              setFeedbackText={setFeedbackText}
              thumb={thumb}
            />
          ) : (
            ""
          )
        }
        children={children}
      />
    </button>
  );
}

export default FeedbackPopup;
