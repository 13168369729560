import { signInAnonymously } from "firebase/auth";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { anonymousLoginAtom } from "../lib/atoms";
import { useEventLogger, UserEvent } from "../lib/event_logger";
import { useExperiment } from "../lib/use-experiment";
import useMustLogin from "../lib/use-must-login";
import { auth, useUser } from "./auth/auth";
import { createAnonymousKeys, useUserData } from "./auth/data";

export default function ShadowUser() {
  const { user, loading } = useUser();
  const [searchParams] = useSearchParams();
  const shadowParam = searchParams.get("shadow");
  const [anonymousLogin, setAnonymousLogin] =
    useRecoilState(anonymousLoginAtom);
  const { userEventLogger } = useEventLogger();
  const [sendEvent, setSendEvent] = React.useState<boolean>(false);
  const { value: userData } = useUserData();
  const [shouldCreateAnonymousKeys, setShouldCreateAnonymousKeys] =
    React.useState<boolean>(false);

  const {
    variant,
    reachedLimit,
    isLateSignupVariant,
    loading: expLoading,
  } = useExperiment();
  const mustLogin = useMustLogin();

  const tokenParam = searchParams.get("verify_token");
  const forgotTokenParam = searchParams.get("forgot_token");

  React.useEffect(() => {
    if (sendEvent) {
      userEventLogger(UserEvent.USER_CREATED, {
        email: user?.email || "Anonymous",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEvent]);

  React.useEffect(() => {
    if (
      shouldCreateAnonymousKeys === true ||
      localStorage.getItem("anonymousLogin") === "true"
    ) {
      const createAnonKeys = async () => {
        if (userData?.org_ref) {
          setAnonymousLogin(true);
          if (userData) {
            const keysResult = await createAnonymousKeys(variant);
            if (keysResult.success === true) {
              setAnonymousLogin(false);
              localStorage.setItem("anonymousLogin", "false");
              setSendEvent(true);
              setShouldCreateAnonymousKeys(false);
              localStorage.setItem("shouldContinueAnonymousCreation", "false");
            } else {
              const keysResult = await createAnonymousKeys(variant);
              if (keysResult.success === true) {
                setAnonymousLogin(false);
                localStorage.setItem("anonymousLogin", "false");
                setSendEvent(true);
                setShouldCreateAnonymousKeys(false);
              }
            }
          }
        }
      };
      createAnonKeys();
    }
    //eslint-disable-next-line
  }, [shouldCreateAnonymousKeys, userData?.org_ref]);

  React.useEffect(() => {
    const shadowSignIn = async () => {
      if (!loading) {
        if (shadowParam !== "false") {
          if (!user && !forgotTokenParam && !tokenParam) {
            localStorage.setItem("anonymousLogin", "true");
            await signInAnonymously(auth);
            setShouldCreateAnonymousKeys(true);
          } else {
            if (!anonymousLogin && !user) {
              await signInAnonymously(auth);
              setShouldCreateAnonymousKeys(true);
              localStorage.setItem("anonymousLogin", "true");
            }
          }
        }
      }
    };

    if (!expLoading) {
      if (isLateSignupVariant && reachedLimit) {
        // Do nothing - show the login modal
        console.debug("[experiment useEffect]: Should show login modal1");
      } else if (mustLogin.shouldShowLoginForm && !isLateSignupVariant) {
        // Do nothing - show the login modal
      } else {
        shadowSignIn();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, user, expLoading, userData, anonymousLogin]);

  return null;
}
