import React from "react";
import ContentModal from "./content-modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { contentFileAtom } from "../lib/atoms";
import { useThemeColor } from "./theme-color";
import { cloneDeep } from "lodash";
import { selectStyles } from "../utils/select-styles";
import { useDraftData } from "../lib/use-studio-data";

export default function AddVideoCategoryModal() {
  const [isOpen, setIsOpen_] = React.useState<boolean>(false);

  function setIsOpen(isOpen: boolean) {
    setError([]);
    setIsOpen_(isOpen);
  }

  const { isDark } = useThemeColor();
  const [error, setError] = React.useState<Array<string>>([]);
  const { t, i18n } = useTranslation("content-management");
  const [draft, setDraft] = useRecoilState(contentFileAtom);
  const { data } = useDraftData(draft);
  const options = [
    {
      value: false,
      label: "Default closed",
    },
    {
      value: true,
      label: "Default open",
    },
  ];
  const [selectedType, setSelectedType] = React.useState<any>(options[0]);
  const onSubmit = async (values: any) => {
    try {
      const clonedContent = cloneDeep(data);

      let biggerId = 0;
      clonedContent?.video_categories.forEach((category: any) => {
        if (Number(category.video_category) > biggerId)
          biggerId = category.video_category;
      });
      biggerId++;

      const newCategory = {
        video_category: biggerId,
        video_category_name: values?.name,
        video_category_open: selectedType.value,
      };

      clonedContent?.video_categories?.push(newCategory);

      setDraft(clonedContent);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.name) errors.name = t("Required");
    return errors;
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        aria-controls={isOpen ? "add-category-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        className="min-w-fit cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-medium tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl"
      >
        {t("Add new video category")}
      </button>

      <ContentModal isOpen={isOpen} setIsOpen={setIsOpen}>
        {error.length > 0 && (
          <div className="fixed left-3 rounded-md max-w-[200px] bg-dark bg-opacity-40 text-white p-2 z-50">
            {error.map((error: any) => (
              <p>{error}</p>
            ))}
          </div>
        )}

        <Formik
          initialValues={{
            name: "",
          }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="grid grid-cols-1 gap-y-4 font-poppins pb-4 text-black dark:text-white">
              <p className="text-center text-2xl">{t("Add a new category")}</p>
              <span className="font-normal text-center">
                {t("Make sure sure you know what you're doing!")}
              </span>
              <div className="grid md:grid-cols-1 gap-y-4 gap-x-4 md:h-full ">
                <div className="gap-y-4">
                  <p className="underline text-xl font-normal">
                    {t("Category information")}
                  </p>
                  <div className="grid gap-y-4">
                    <div className="grid gap-y-1">
                      <label
                        htmlFor="name"
                        className="text-sm font-normal leading-6"
                      >
                        {t("Category name:")}
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="bg-transparent border border-borderLight dark:border-darkGrayBorder rounded p-3 w-full"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red error-message"
                      />
                    </div>
                    <div className="grid gap-y-1">
                      <label
                        htmlFor="select"
                        className="text-sm font-normal leading-6"
                      >
                        {t("Default:")}
                      </label>
                      <Select
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        styles={selectStyles(isDark ? true : false)}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        options={options}
                        onChange={(selectedOption): any => {
                          setSelectedType(selectedOption);
                        }}
                        value={selectedType}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                dir={i18n.dir()}
                className={`grid grid-flow-col gap-x-2 justify-end fixed bottom-1 right-3`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-medium tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={() => setIsOpen(false)}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-medium tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Add Category")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ContentModal>
    </>
  );
}
