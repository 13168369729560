import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { useSetRecoilState } from "recoil";
import { queryClient } from "../../App";
import { useUserData } from "../../components/auth/data";
import Button from "../../components/button";
import { preferencesModalOpenAtom } from "../../lib/billing-atoms";
import { validateEmail } from "../PipelinePage/utils";
import {
  getPayerCustomerQueryKey,
  getPayerOrganizationQueryKey,
  mutateBillingOrganization,
  quotaPerOneDollar,
} from "../settings/billing";
import Select from "react-select";
import { useThemeColor } from "../../components/theme-color";
import { countrySelectStyles } from "./select-styles";
import { countryValue, countryOptions } from "./countries-provider";
export default function PrefrencesModal({ userBilling }: any) {
  const setPrefrencesModalOpen = useSetRecoilState(preferencesModalOpenAtom);
  const { value: userData } = useUserData();
  const [errors, setErrors] = React.useState<any>({
    limit: "",
    email: "",
    country: "",
  });

  const { isDark } = useThemeColor();
  const mutation = useMutation(mutateBillingOrganization, {
    onSuccess: () => {
      console.log("Refreshing getPayerCustomerQueryKey");
      queryClient.invalidateQueries(getPayerOrganizationQueryKey);
      queryClient.invalidateQueries(getPayerCustomerQueryKey);
      setPrefrencesModalOpen(false);
    },
    onError: () => {
      setPrefrencesModalOpen(false);
    },
  });

  const isLoading =
    mutation.status === "loading" || mutation.status === "success";

  const [draft, setDraft] = useState({
    limit_budget: userBilling?.limit_budget,
    billing_email: userBilling?.billing_email,
    company_name: userBilling?.company_name,
    company_address: userBilling?.company_address,
    country_code: userBilling?.country_code,
  });

  const onChange = (ev: any, field: string) => {
    setDraft({ ...draft, [field]: ev.target.value });
    console.debug("[PreferencesModal] Change:", field, ev.target.value);
  };
  const saveChanges = async () => {
    if (
      !validateLimit(draft.limit_budget) ||
      !validateBillingEmail(draft.billing_email) ||
      !validateCountry(draft.country_code)
    ) {
      return;
    }
    console.debug("[PrefrencesModal] saveChanges:", draft);

    const updatedObject = {
      "billing.limit_budget": Math.round(draft.limit_budget),
      "billing.billing_email": draft.billing_email,
      "billing.company_name": draft.company_name,
      "billing.company_address": draft.company_address,
      "billing.country_code": draft.country_code,
      monthly_quota: Math.round(draft.limit_budget) * quotaPerOneDollar,
    };

    mutation.mutate({
      organizationID: userData?.org_ref,
      updatedObject,
    });
  };

  function validateCountry(newValue: any) {
    setErrors({ ...errors, country: "" });
    if (newValue === "" || newValue === null) {
      setErrors({ ...errors, country: "Please select country" });
      return false;
    }
    return true;
  }
  function validateLimit(newValue: any) {
    setErrors({ ...errors, limit: "" });
    if (newValue === "") {
      setErrors({ ...errors, limit: "Please enter a limit budget" });
      return false;
    } else if (Number(newValue) < 11) {
      setErrors({ ...errors, limit: "The minimum is 11$" });
      return false;
    }
    return true;
  }

  function validateBillingEmail(newValue: any) {
    setErrors({ ...errors, email: "" });
    if (!validateEmail(newValue) && newValue !== "") {
      setErrors({ ...errors, email: "Please enter a valid email!" });
      return false;
    }
    return true;
  }

  return (
    <div className="dark:text-white text-black p-4 md:p-12 max-h-[80vh]">
      <div className="h-full grid align-content-center">
        <h2 className="text-center text-2xl font-poppins font-medium">
          Preferences
        </h2>

        <div className="grid items-center">
          <div className="grid items-center">
            <div className="grid grid-rows-1 md:grid-rows-1 md:grid-cols-2 gap-y-4 md:gap-y-0 gap-x-4">
              <div className="grid">
                <div>
                  <h3 className=" dark:text-white text-black text-2xl font-medium">
                    Usage Limit
                  </h3>
                  <p className=" dark:text-white text-black text-md">
                    When your usage reaches this limit <br />
                    subsequent API calls will be rejected
                  </p>

                  <div>
                    <label htmlFor="usd_limit" className="relative">
                      <input
                        className="input-container "
                        name="usd_limit"
                        type="tel"
                        placeholder="Limit"
                        value={draft.limit_budget}
                        onChange={(ev) => {
                          validateLimit(ev.target.value);
                          onChange(ev, "limit_budget");
                        }}
                      />
                      <span className="absolute left-3 top-0.5">$</span>
                    </label>
                    {errors.limit && (
                      <p className="text-red p-0 m-0" key={errors.limit}>
                        {errors.limit}
                      </p>
                    )}
                  </div>
                </div>

                <h3 className="text-2xl ">Billing Information</h3>

                <div className="grid gap-y-4">
                  <input
                    type="text"
                    className="input-container"
                    placeholder="Company name"
                    value={draft.company_name}
                    onChange={(ev) => {
                      onChange(ev, "company_name");
                    }}
                  />

                  <div>
                    <input
                      type="email"
                      className="input-container"
                      placeholder="Billing Email"
                      value={draft.billing_email}
                      onChange={(ev) => {
                        validateBillingEmail(ev.target.value);
                        onChange(ev, "billing_email");
                      }}
                    />
                    {errors.email && (
                      <p className="text-red p-0 m-0" key={errors.email}>
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid gap-y-4 items-end">
                <div className="grid gap-y-4">
                  <div>
                    <Select
                      placeholder="Select country"
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                      styles={countrySelectStyles(isDark ? true : false)}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                      options={countryOptions}
                      onChange={(selectedOption: any) => {
                        setDraft({
                          ...draft,
                          country_code: selectedOption.value,
                        });
                        validateCountry(selectedOption?.value);
                      }}
                      value={countryValue(draft.country_code)}
                    />
                    {errors.country && (
                      <p
                        className="text-red p-0 m-0 animate-fade-in-up"
                        key={errors.country}
                      >
                        {errors.country}
                      </p>
                    )}
                  </div>

                  <div>
                    <input
                      type="text"
                      className="input-container"
                      placeholder="Company address"
                      value={draft.company_address}
                      onChange={(ev) => {
                        onChange(ev, "company_address");
                      }}
                    />
                    {errors.email && (
                      <p className="text-red p-0 m-0 invisible">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid justify-items-center pt-6">
            <Button onClick={saveChanges} ariaLabel="Save changes">
              {isLoading ? (
                <span className="px-12 grid items-center justify-center grid-cols-1fr-auto gap-x-3">
                  <Box>
                    <CircularProgress
                      style={{
                        width: 15,
                        height: 15,
                        color: "white",
                        padding: 0,
                        marginLeft: 6,
                      }}
                    />
                  </Box>
                </span>
              ) : (
                <span className="font-poppins px-12">Save Changes</span>
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
