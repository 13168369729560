import React from "react";
import { useRecoilValue } from "recoil";
import { useUser } from "../components/auth/auth";
import {
  getAPIKeyByTokenId,
  useUserOrganizationKeys,
} from "../components/auth/data";
import {
  getCURL,
  getJSON,
  getNodeJS,
  getNodeJSSDK,
  getPython,
  getPythonSDK,
  technologiesOptions,
} from "../pages/PipelinePage/utils";
import { summaryOriginsAtom } from "./atoms";
import { RequestTypes } from "./interfaces";
import useRequestBuilder from "./use-request-builder";

export default function useRequestParams() {
  const requestBuilder = useRequestBuilder();
  const summaryOrigins = useRecoilValue(summaryOriginsAtom);
  const { api_keys } = useUserOrganizationKeys();
  const { user } = useUser();

  const visibleKey = [...api_keys].reverse()?.find((key) => {
    return key.state === "ACTIVE";
  });

  const [visibleGeneratedCodeKey, setVisibleGeneratedCodeKey] =
    React.useState<string>("");

  React.useEffect(() => {
    if (!visibleGeneratedCodeKey) {
      const getKey = async () => {
        const orgId = visibleKey?.account_id;
        const tokenId = visibleKey?.token_id;

        if (visibleKey?.account_id) {
          const lastKey = await getAPIKeyByTokenId({
            orgId,
            tokenId,
          });

          console.log("Last Key :: ", lastKey);
          setVisibleGeneratedCodeKey(lastKey?.details?.api_key);
        }
      };
      getKey();
    }

    //eslint-disable-next-line
  }, [api_keys]);

  function getRequest(
    technology: string,
    isPreview: "REAL-REQUEST" | "PREVIEW",
  ) {
    const showPreview = isPreview === "REAL-REQUEST" ? false : true;
    const dataObject: RequestTypes = {
      requestBuilder,
      summaryOrigins,
      apiKey: visibleGeneratedCodeKey,
      preview: showPreview,
      isAnonymous: user?.isAnonymous,
    };

    switch (technology) {
      case technologiesOptions[0].value /* Node.js */:
        return getNodeJS(dataObject);
      case technologiesOptions[1].value /* cURL */:
        return getCURL(dataObject);
      case technologiesOptions[2].value /* Python */:
        return getPython(dataObject);
      case technologiesOptions[3].value /* JSON */:
        return getJSON(dataObject);
      case technologiesOptions[4].value /* Node.js SDK */:
        return getNodeJSSDK(dataObject);
      case technologiesOptions[5].value /* Python SDK */:
        return getPythonSDK(dataObject);
    }
  }

  return { getRequest };
}
