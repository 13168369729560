import { InputOutputSpan, LabelType } from "../pages/PipelinePage/types";

export default function highlightRange(text: string, labels: Array<LabelType>) {
  const sections = annotateInputSections(text, labels);
  return mergeSections(sections);
}

export const annotateOutputText = (
  text: string,
  labels: Array<LabelType>,
  input_text: string,
) => {
  // console.log("annotateOutputText");
  input_text = stripHtml(input_text);
  const sections = splitTextToSections(input_text);
  splitLabelsToSections(labels, sections);
  sortSections(sections);
  dropOutputSpansDuplicates(sections);
  const section = sections[0];
  section.text = text;
  const returnString = annotateOutput(section);
  section.text = returnString;

  return section;
};

export const annotateInputSections = (
  text: string,
  labels: Array<LabelType>,
) => {
  // console.log("annotateInputSections");
  //text = stripHtml(text); SUMMARY ORIGIN UI FIX
  const sections = splitTextToSections(text);
  splitLabelsToSections(labels, sections);
  sortSections(sections);
  dropInputSpansDuplicates(sections);
  annotateSections(sections);

  return sections;
};

export const colorPaletteGenerator = () => {
  const colors = [
    // "#E5FFC5",
    // "#C8FF83",
    // "#9BF52A",
    "#90E02D",
    // "#8FCD41",
    // "#FFF7E2",
    // "#FFECBB",
    // "#FFD66B",
    // "#FFC83A",
    // "#EFB31A",
    // "#E2F5FF",
    // "#CDEDFF",
    // "#A3DDFF",
    // "#4EBEFE",
    // "#00A3FF",
  ];

  return stepGen(colors);
};

function* stepGen(steps: Array<any>) {
  let index = 0;
  while (true) {
    yield steps[index];
    index = (index + 1) % steps.length;
  }
}

interface Section {
  id: number;
  text: string;
  input_spans: Array<InputOutputSpan>;
  output_spans: Array<InputOutputSpan>;
}
/* This function removes all HTML tags from HTML, we want this in order to  */
function stripHtml(html: any) {
  let tmp = document.createElement("div");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

const sortInputSpans = (iSpanA: InputOutputSpan, iSpanB: InputOutputSpan) => {
  if (iSpanA.section === iSpanB.section) {
    return iSpanA.start - iSpanB.start;
  }
  return iSpanA.section > iSpanB.section ? 1 : -1;
};

const splitLabelsToSections = (
  labels: Array<LabelType>,
  sections: Array<Section>,
) => {
  for (let index = 0; index < labels.length; index++) {
    const label = labels[index];
    if (label && label?.input_spans) {
      for (const inputSpan of label?.input_spans) {
        if (sections[inputSpan.section]?.input_spans !== undefined) {
          //@ts-ignore
          inputSpan.color = index;
          sections[inputSpan.section].input_spans?.push(inputSpan);
        }
      }
    }
    if (label && label?.output_spans) {
      for (const outputSpan of label?.output_spans) {
        if (sections[outputSpan.section]?.input_spans !== undefined) {
          //@ts-ignore
          outputSpan.color = index;
          sections[outputSpan.section].output_spans.push(outputSpan);
        }
      }
    }
  }
};

const annotateOutput = (section: Section) => {
  const colorGen = colorPaletteGenerator();
  const returnString = annotateSection(section, "output_spans", colorGen);

  return returnString;
};

const annotateInputSection = (section: Section, colorGen: Generator) => {
  if (section.input_spans.length > 0) {
    const cut = section.text.indexOf("\n") + 1;
    let returnString = section.text.substring(0, cut);
    section.text = section.text.substring(cut);
    returnString += annotateSection(section, "input_spans", colorGen);
    section.text = returnString;
  }
};

const annotateSection = (
  section: Section,
  type: "input_spans" | "output_spans",
  colorGen: Generator,
) => {
  let returnString = "";
  let lastIndex = 0;
  for (const iSpan of section[type]) {
    iSpan.color = colorGen.next().value;
    const openSpan =
      type === "input_spans"
        ? `<span style="background-color: ${iSpan.color}; border-radius: 6px; position: relative;" start="${iSpan.start}" end="${iSpan.end}">`
        : // : `<span style="text-decoration: underline; text-decoration-color: ${iSpan.color}; text-decoration-thickness: 2px; position: relative;">`;
          `<span style="border-bottom: 2px solid ${iSpan.color}; line-height: 1.5; position: relative;" start="${iSpan.start}" end="${iSpan.end}">`;

    returnString +=
      section.text.substring(lastIndex, iSpan.start) +
      openSpan +
      section.text.substring(iSpan.start, iSpan.end) +
      `</span>`;

    lastIndex = iSpan.end;
  }
  returnString += section.text.substring(lastIndex);
  return returnString;
};

export const annotateSections = (sections: Array<Section>) => {
  const colorGen = colorPaletteGenerator();
  sections.forEach((section) => annotateInputSection(section, colorGen));
};

const sortSections = (sections: Array<Section>) => {
  sections.forEach((section: Section) =>
    section.input_spans.sort(sortInputSpans),
  );
};

const dropInputSpansDuplicates = (sections: Array<Section>) => {
  dropSpansDuplicates(sections, "input_spans");
};

const dropOutputSpansDuplicates = (sections: Array<Section>) => {
  dropSpansDuplicates(sections, "output_spans");
};

const dropSpansDuplicates = (
  sections: Array<Section>,
  type: "input_spans" | "output_spans",
) => {
  sections.forEach((section: Section) => {
    const newSpans = [];
    if (section[type].length > 0) {
      newSpans.push(section[type][0]);
      let lastSpan = section[type][0];
      for (let index = 0; index < section[type].length; index++) {
        const currentSpan = section[type][index];
        if (
          currentSpan.start !== lastSpan.start &&
          currentSpan.end !== lastSpan.end
        ) {
          newSpans.push(currentSpan);
          lastSpan = currentSpan;
        }
      }
      section[type] = newSpans;
    }
  });
};

const splitTextToSections = (text: string): Array<Section> => {
  return text.split("\n\n").map((txt, idx) => {
    return { id: idx, text: txt, input_spans: [], output_spans: [] };
  });
};

const mergeSections = (sections: Array<Section>) =>
  sections.map((s) => s.text).join("\n\n");
