import { Menu, MenuItem, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  IconArrowDown,
  IconArrowUp,
  IconDocumentation,
  IconFile,
  IconGlobe,
  IconLogout,
  IconSettings,
  IconYourApiKeys,
  IconContactUs,
} from "../assets/SvgIcons";
import { useEventLogger, UserEvent } from "../lib/event_logger";
import { useDocsParams } from "../lib/use-docs-params";
import theme from "../theme";
import { useUser } from "./auth/auth";
import { useAuth } from "./auth/data";
import { useThemeColor } from "./theme-color";
import { NoPhoto, UserImage, UserName } from "./user-views";

type MenuType = "LOGGED_IN" | "ANONYMOUS";

type MenuProps = {
  type: MenuType;
};

export default function DesktopMenu({ type }: MenuProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { isDark, toggleTheme } = useThemeColor();
  const { userEventLogger, userUIEventLogger } = useEventLogger();
  const { t, i18n } = useTranslation("user-menu");
  const docsLink = useDocsParams();

  const isAnonymousMenu = type === "ANONYMOUS";
  const menuStyle =
    "focus-visible:border-2 dark:focus-visible:border-white focus-visible:rounded-md inline-block w-full focus-visible:outline-none";
  const { logout } = useAuth();

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMenuOpen(event: any) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
      userEventLogger(UserEvent.USER_MENU_OPEN);
    }
  }

  const { user } = useUser();

  return (
    <>
      <div
        className="grid grid-flow-col items-center cursor-pointer gap-x-2"
        onClick={handleMenuOpen}
      >
        {user && !user?.isAnonymous && (
          <span className="grid justify-center items-center  gap-x-2">
            {user?.photoURL ? (
              <UserImage />
            ) : user?.displayName ? (
              <UserName />
            ) : (
              <NoPhoto />
            )}
          </span>
        )}

        <div className="h-[39px] w-[39px] bg-linkWater dark:bg-darkGrayBackground rounded-md grid items-center justify-center cursor-pointer overflow-hidden">
          <Button
            aria-owns={anchorEl ? "user-menu" : undefined}
            aria-haspopup="true"
            onMouseOver={handleMenuOpen}
            tabIndex={0}
            data-cy="user-menu"
          >
            <figure>
              {anchorEl ? (
                <IconArrowDown
                  width="10px"
                  height="6px"
                  className="text-borderBlue"
                />
              ) : (
                <IconArrowUp
                  width="10px"
                  height="6px"
                  className="text-borderBlue"
                />
              )}
            </figure>
          </Button>
        </div>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: isDark
              ? theme.colors.steelGray
              : theme.colors.white,
            color: theme.colors.white,
            padding: theme.spacing[2],
            mt: 2,
            margin: "none",
            filter: !isDark
              ? "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))"
              : "none",
          },
        }}
      >
        <MenuItem
          key="settings"
          className="dark:hover:bg-darkSkillDark"
          style={{
            display: isAnonymousMenu ? "none" : "block",
          }}
          component={Link}
          to="/settings/api-keys"
        >
          <div
            className={`grid grid-cols-2-auto gap-x-7 w-full items-center justify-start transition-all duration-200 py-1  `}
          >
            <figure className="text-borderBlue">
              <IconSettings
                width="22px"
                height="22px"
                className={` text-xs ${
                  i18n.dir() === "rtl" ? "transform rotate-180" : ""
                }`}
              />
            </figure>
            <span className="text-black dark:text-white text-md font-poppins">
              {t("Manage Account")}
            </span>
          </div>
        </MenuItem>
        <MenuItem
          key="copyApiKeys"
          className="dark:hover:bg-darkSkillDark"
          onClick={() => userEventLogger(UserEvent.API_KEYS_USER_MENU)}
          component={Link}
          style={{
            display: isAnonymousMenu ? "none" : "block",
          }}
          to="/settings/api-keys"
        >
          <div
            className={`grid grid-cols-2-auto gap-x-7 w-full items-center justify-start transition-all duration-200 py-1`}
          >
            <figure>
              <IconYourApiKeys
                width="22px"
                height="22px"
                className={`text-borderBlue text-xs ${
                  i18n.dir() === "rtl" ? "transform rotate-180" : ""
                }`}
              />
            </figure>
            <Typography className="text-black dark:text-white text-md font-poppins">
              {t("API Keys")}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          key="documentation"
          className="dark:hover:bg-darkSkillDark"
          style={{
            display: isAnonymousMenu ? "none" : "block",
          }}
          component={Link}
          to={docsLink}
        >
          <div
            className={`grid grid-cols-2-auto gap-x-7 w-full items-center justify-start transition-all duration-200 py-1`}
          >
            <figure>
              <IconDocumentation
                width="22px"
                height="22px"
                className={`text-borderBlue text-xs ${
                  i18n.dir() === "rtl" ? "transform rotate-180" : ""
                }`}
              />
            </figure>
            <span className="text-black dark:text-white py-1 text-md font-poppins">
              {t("API Documentation")}
            </span>
          </div>
        </MenuItem>
        <div
          className={` border-t border-gray dark:border-darkGrayBorder  ${
            isAnonymousMenu ? "hidden" : ""
          }`}
        />
        <MenuItem
          key="theme-mode"
          className="dark:hover:bg-darkSkillDark"
          onClick={() => {
            userEventLogger(UserEvent.TOGGLE_THEME_USER_MENU);
            toggleTheme();
          }}
        >
          <div
            className={`grid grid-cols-3-auto gap-x-2 w-full items-center justify-start transition-all duration-200 py-1 ${menuStyle}`}
          >
            <ToggleModeButton />
          </div>
        </MenuItem>
        <div className=" border-t border-gray dark:border-darkGrayBorder  " />
        <MenuItem
          key="visit-oneai"
          className="dark:hover:bg-darkSkillDark"
          href="https://www.oneai.com"
          component="a"
          target="_blank"
        >
          <div
            className={`grid grid-cols-2-auto gap-x-7 w-full items-center justify-start transition-all duration-200 py-1`}
            onClick={() => {
              userUIEventLogger("app__user_menu__visit_oneai");
            }}
          >
            <figure>
              <IconGlobe
                width="22px"
                height="22px"
                className={`text-borderBlue  ${
                  i18n.dir() === "rtl" ? "transform rotate-180" : ""
                }`}
              />
            </figure>
            <p className="text-black dark:text-white m-0 text-md py-1 font-poppins">
              {t("Visit")} <span className="text-borderBlue ">OneAi.com</span>
            </p>
          </div>
        </MenuItem>
        <MenuItem
          key="contact-us"
          className="dark:hover:bg-darkSkillDark"
          href="https://www.oneai.com/contact-us"
          component="a"
          target="_blank"
        >
          <div
            className={`grid grid-cols-2-auto gap-x-7 w-full items-center justify-start transition-all duration-200 py-1`}
            onClick={() => {
              userUIEventLogger("app__user_menu__contact_us");
            }}
          >
            <figure>
              <IconContactUs
                width="22px"
                height="22px"
                className={`text-borderBlue  ${
                  i18n.dir() === "rtl" ? "transform rotate-180" : ""
                }`}
              />
            </figure>
            <p className="text-black dark:text-white m-0 text-md py-1 font-poppins">
              {t("Contact us")}
            </p>
          </div>
        </MenuItem>
        <MenuItem
          key="privacy-policy"
          className="dark:hover:bg-darkSkillDark "
          href="https://www.oneai.com/privacy-policy"
          component="a"
          target="_blank"
        >
          <div
            className={`grid grid-cols-2-auto gap-x-7 w-full items-center justify-start transition-all duration-200 py-1`}
            onClick={() => {
              userUIEventLogger("app__user_menu__privacy_policy");
            }}
          >
            <figure>
              <IconFile
                width="22px"
                height="22px"
                className={`text-borderBlue text-xs ${
                  i18n.dir() === "rtl" ? "transform rotate-180" : ""
                }`}
              />
            </figure>
            <span className="text-black dark:text-white py-1 text-md font-poppins">
              {t("Privacy Policy")}
            </span>
          </div>
        </MenuItem>

        <MenuItem
          key="terms-of-service"
          className="dark:hover:bg-darkSkillDark"
          href="https://www.oneai.com/tos"
          component="a"
          target="_blank"
        >
          <div
            className={`grid grid-cols-2-auto gap-x-7 w-full items-center justify-start transition-all duration-200 py-1`}
            onClick={() => {
              userUIEventLogger("app__user_menu__terms_of_service");
            }}
          >
            <figure>
              <IconFile
                width="22px"
                height="22px"
                className={`text-borderBlue text-xs ${
                  i18n.dir() === "rtl" ? "transform rotate-180" : ""
                }`}
              />
            </figure>
            <span className="text-black dark:text-white py-1 text-md font-poppins">
              {t("Terms of Service")}
            </span>
          </div>
        </MenuItem>
        <div
          className={` border-t border-gray dark:border-darkGrayBorder ${
            isAnonymousMenu ? "hidden" : ""
          }`}
        />
        <MenuItem
          key="logout"
          onClick={logout}
          className="dark:hover:bg-darkSkillDark"
          style={{
            display: isAnonymousMenu ? "none" : "block",
          }}
        >
          <div
            className={`grid grid-cols-2-auto gap-x-7 items-center justify-start transition-all duration-200 py-1`}
          >
            <figure>
              <IconLogout
                width="22px"
                height="22px"
                className={`text-dodgerBlue text-xs ${
                  i18n.dir() === "rtl" ? "transform rotate-180" : ""
                }`}
              />
            </figure>
            <span className="text-black dark:text-white py-1 text-md font-poppins">
              {t("Log Out")}
            </span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}

export const ToggleModeButton = () => {
  const { isDark } = useThemeColor();
  const { t } = useTranslation("user-menu");
  return (
    <>
      <span className="text-black dark:text-white py-1 text-md font-poppins">
        {t("Light")}
      </span>
      <div
        className={`shadow-md rounded-xl h-[25px] w-[41px] p-[3px] grid relative ${
          isDark
            ? "justify-items-end bg-charadea"
            : "justify-items-start bg-white"
        }`}
      >
        {isDark ? (
          <div className="absolute left-[14px] top-[1px]">
            <MoonIcon />
          </div>
        ) : (
          <div className="absolute top-[1px]">
            <SunIcon />
          </div>
        )}
      </div>
      <span className="text-black dark:text-white py-1 text-md font-poppins">
        {t("Dark")}
      </span>
    </>
  );
};

const MoonIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2249_893)">
        <path
          d="M22 12.3559C21.8336 14.1564 21.1578 15.8724 20.0518 17.303C18.9457 18.7335 17.4552 19.8195 15.7544 20.4338C14.0537 21.0481 12.2132 21.1653 10.4483 20.7718C8.68338 20.3783 7.06704 19.4902 5.7884 18.2116C4.50977 16.933 3.62173 15.3166 3.22819 13.5517C2.83466 11.7868 2.9519 9.94628 3.56621 8.24556C4.18052 6.54485 5.26649 5.05425 6.69704 3.94821C8.12759 2.84216 9.84355 2.1664 11.6441 2C10.59 3.4262 10.0827 5.18339 10.2146 6.952C10.3465 8.7206 11.1087 10.3831 12.3628 11.6372C13.6169 12.8913 15.2794 13.6536 17.048 13.7854C18.8166 13.9173 20.5738 13.41 22 12.3559V12.3559Z"
          fill="#0CFEFF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2249_893"
          x="0"
          y="0"
          width="27"
          height="27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 1 0 0 0 0 1 0 0 0 0.19 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2249_893"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2249_893"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
const SunIcon = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2249_894)">
        <circle cx="12.5" cy="11.5" r="9.5" fill="#FFE801" />
      </g>
      <defs>
        <filter
          id="filter0_d_2249_894"
          x="0"
          y="0"
          width="27"
          height="27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.890196 0 0 0 0 0 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2249_894"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2249_894"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
