import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  pipelineFilteredStepsSelector,
  pipelineSkillsAtom,
  pipelineStepsSelector,
} from "../pages/pipeline/pipeline-atoms";
import {
  decodeHTMLEntities,
  getSkillEndpoint,
  inputSelectionTypes,
  parseAcademyTextArea,
  shouldParseAcademy,
  stripHTML,
} from "../pages/PipelinePage/utils";
import { parseConversation } from "../utils/detectTypeText";
import {
  curApiItemAtom,
  fileContentAtom,
  inputTypeAtom,
  textAreaAtom,
} from "./atoms";
import { useStudioData } from "./use-studio-data";
import { useSummaryOrigin } from "./use-summary-origin";

export default function useRequestBuilder() {
  const selectedInputType = useRecoilValue(inputTypeAtom);
  const textArea = useRecoilValue(textAreaAtom);
  const filteredPipelineSteps = useRecoilValue(pipelineFilteredStepsSelector);
  const pipelineSteps = useRecoilValue(pipelineStepsSelector);
  const { isSummary } = useSummaryOrigin();
  const location = useLocation();
  const foundSummary = isSummary().foundSummary;
  const currentDocsItem = useRecoilValue(curApiItemAtom);
  const pipelineSkills = useRecoilValue(pipelineSkillsAtom);
  const { data } = useStudioData();
  const fileContent = useRecoilValue(fileContentAtom);

  function pipelineStepsStrategy() {
    if (location.pathname === "/docs") {
      if (getSkillEndpoint(currentDocsItem?.item_name, data)) {
        return [{ skill: getSkillEndpoint(currentDocsItem?.item_name, data) }];
      } else {
        return [];
      }
    }
    return filteredPipelineSteps;
  }

  const requestBuilder = () => {
    const input_type = selectedInputType?.name?.toLowerCase();
    const include_intermediates = "true";
    const isConversation =
      selectedInputType === inputSelectionTypes.conversation;

    let formattedText = textArea;
    const shouldParse = shouldParseAcademy(pipelineSkills?.steps, input_type);

    formattedText = shouldParse
      ? JSON.stringify(parseAcademyTextArea(textArea))
      : textArea;

    formattedText = shouldParse
      ? JSON.stringify(parseAcademyTextArea(textArea))
      : textArea;

    if (
      (selectedInputType === inputSelectionTypes["auto-detect"] ||
        isConversation) &&
      !Object.keys(parseConversation(formattedText)).includes("error")
    ) {
      formattedText = JSON.stringify(parseConversation(formattedText));
    }

    let textAreaStrategy = `${formattedText}`;
    if (foundSummary) {
      textAreaStrategy = stripHTML(textAreaStrategy);
    }

    return {
      input: fileContent
        ? fileContent
        : !textArea
        ? "<YOUR INPUT TEXT>"
        : decodeHTMLEntities(textAreaStrategy),
      input_type,
      steps: pipelineStepsStrategy(),
      unfiltered_steps: pipelineSteps,
      include_intermediates,
      is_academic: shouldParse,
      is_file: !!fileContent,
      ...(isConversation && { content_type: "application/json" }),
    };
    //eslint-disable-next-line
  };
  return requestBuilder;
}
