import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { IconPlayVideo } from "../assets/SvgIcons";
import Accordion from "../components/accordion";
import Layout from "../components/Layout/layout";
import { useThemeColor } from "../components/theme-color";
import {
  mainVideoAtom,
  mobileMenuOpenAtom,
  shouldPlayVideoAtom,
  videoCategoriesAccordionOpenedAtom,
} from "../lib/atoms";
import { useEventLogger, UserEvent } from "../lib/event_logger";
import useDidMountEffect from "../lib/use-did-mount-effect";
import useElementSize from "../lib/use-element-size";
import { useStudioData } from "../lib/use-studio-data";
import theme from "../theme";
import { useIsMobile } from "./PipelinePage/utils";
interface VideosType {
  link?: string;
  title: string;
  description?: string;
  category?: number;
}
interface VideoCategoriesType {
  video_category: number;
  video_category_name: string;
}
interface ExtendedVideosType extends VideosType {
  preview?: boolean;
  setMainVideo?: (link: VideosType) => void;
  mainVideo?: VideosType;
  handleClickEvent?: any;
}

const NOVIDEO = null;

// const videos: VideosType[] = contentFile.videos;
// const videoCategories: VideoCategoriesType[] = contentFile.video_categories;

function Video({ link, title, setMainVideo }: ExtendedVideosType) {
  const { data, loading } = useStudioData();
  const videos = data.videos;
  const { userEventLogger } = useEventLogger();
  const getVideoTitle = videos?.find(
    (video: VideosType) => video.link === link,
  );
  const [hideTitle, setHideTitle] = React.useState<boolean>(false);
  const isMobile = useIsMobile();
  const image = `https://img.youtube.com/vi/${link}/sddefault.jpg`;
  const shouldPlayVideo = useRecoilValue(shouldPlayVideoAtom);

  if (loading) return <>Loading</>;
  return (
    <>
      <div className="rounded-md h-full w-full relative">
        <div className="bg-darkBlueVideo h-full w-full rounded-md">
          <div className={`w-full h-full rounded-md`} id="video-container">
            {link === NOVIDEO && !isMobile ? (
              <DemoPlayer
                link={videos[1]?.link}
                title={videos[1]?.title}
                setMainVideo={setMainVideo}
                preview={true}
              />
            ) : (
              <div className={link === NOVIDEO ? "hidden" : "h-full"}>
                <div
                  className={`absolute bottom-3 left-3 w-[70%] ${
                    hideTitle || !isMobile ? "hidden" : ""
                  }`}
                >
                  <p className={`font-poppins text-left font-normal text-xs`}>
                    {title}
                  </p>
                </div>
                <ReactPlayer
                  width="100%"
                  height={isMobile ? "161px" : "100%"}
                  playing={shouldPlayVideo}
                  controls={true}
                  light={isMobile}
                  onPlay={() => {
                    userEventLogger(UserEvent.VIDEO_PLAY, {
                      value: getVideoTitle?.title || "",
                    });
                    setHideTitle(true);
                  }}
                  playIcon={
                    <IconPlayVideo
                      height={isMobile ? "38px" : "93px"}
                      width={isMobile ? "38px" : "93px"}
                    />
                  }
                  url={`https://www.youtube.com/watch?v=${link}`}
                />
                <style>{`
                  ${
                    isMobile
                      ? `
                          .react-player__preview{
                            background-image: url(${image}) !important;
                            background-repeat: no-repeat;
                            background-size: 114% 135% !important;
                            border-radius: 4px !important;
                          }
                          `
                      : ""
                  }
                  
                  `}</style>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
function VideoPreview({
  link,
  title,
  setMainVideo,
  description,
  mainVideo,
  preview,
  handleClickEvent,
}: ExtendedVideosType) {
  const image = `https://img.youtube.com/vi/${link}/maxresdefault.jpg`;
  const { data, loading } = useStudioData();
  const [ref, { height }] = useElementSize();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const setShouldPlayVideo = useSetRecoilState(shouldPlayVideoAtom);
  const videos: [VideosType] = data.videos;
  const [searchParams] = useSearchParams();
  if (loading) return <>Loading</>;

  return (
    <div className="grid gap-x-3">
      <div
        tabIndex={0}
        ref={ref}
        className={
          "relative grid justify-center items-center rounded-md cursor-pointer h-full w-full"
        }
        onClick={() => {
          handleClickEvent(title ? title : "unknown_title");
          const index = videos?.findIndex((video: any) => video.link === link);
          setMainVideo?.(videos[index] as VideosType);

          const currentParams = searchParams
            .get("category")
            ?.replaceAll(" ", "+");

          if (currentParams) {
            navigate(
              `/how-to-videos/${videos[index]["title"]}` +
                `?category=${currentParams}`,
            );
          } else {
            navigate(`/how-to-videos/${videos[index]["title"]}`);
          }
          setShouldPlayVideo(true);
        }}
      >
        <div
          className={`bg-darkBlueVideo rounded-md overflow-hidden ${
            mainVideo?.link === link ? "shadow-lg" : ""
          }`}
        >
          <img
            style={{
              height: preview ? (isMobile ? "" : `${height}px`) : `""`,
              display: "inline-block",
              transform: preview ? "scaleX(1.14)" : "scaleX(1.13)",
            }}
            alt={title}
            src={image}
            className="opacity-20 bg-darkBlueVideo rounded-md"
          />
        </div>

        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="grid items-center justify-center">
            <IconPlayVideo
              height={preview ? "33px" : "33px"}
              width={preview ? "33px" : "33px"}
            />
          </div>
        </div>
      </div>
      <div className="dark:text-white text-black lg:text-xl md:text-xs">
        {title} {description}
      </div>
    </div>
  );
}

function DemoPlayer({
  link,
  title,
  setMainVideo,
  mainVideo,
  preview,
}: ExtendedVideosType) {
  const image = `https://img.youtube.com/vi/${link}/maxresdefault.jpg`;

  const [ref, { height }] = useElementSize();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const setShouldPlayVideo = useSetRecoilState(shouldPlayVideoAtom);
  const { data, loading } = useStudioData();

  const videos: [VideosType] = data.videos;

  if (loading) return <>Loading preview....</>;
  return (
    <>
      <div
        tabIndex={0}
        ref={ref}
        className={
          "relative grid justify-center items-center rounded-md cursor-pointer h-full w-full"
        }
        onClick={() => {
          const index = videos?.findIndex((video: any) => video.link === link);
          setMainVideo?.(videos[index] as VideosType);
          navigate(`/how-to-videos/${videos[index]["title"]}`);
          setShouldPlayVideo(true);
        }}
      >
        <div
          className={`bg-darkBlueVideo rounded-md overflow-hidden ${
            mainVideo?.link === link ? "shadow-lg" : ""
          }`}
        >
          <img
            style={{
              height: preview ? (isMobile ? "" : `${height}px`) : `""`,
              display: "inline-block",
              transform: preview ? "scaleX(1.14)" : "scaleX(1.13)",
            }}
            alt={title}
            src={image}
            className="opacity-20 bg-darkBlueVideo rounded-md"
          />
        </div>

        <div className="absolute bottom-6 left-6 w-[80%]">
          <p
            className={`font-poppins text-left  ${
              preview
                ? "text-sm md:text-3xl font-bold"
                : "text-sm md:text-lg font-normal"
            }`}
          >
            {title}
          </p>
        </div>
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="grid items-center justify-center">
            <IconPlayVideo
              height={preview ? "93px" : "53px"}
              width={preview ? "93px" : "53px"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default function HowToVideos() {
  const { data, loading } = useStudioData();
  const [mainVideo, setMainVideo] = useRecoilState(mainVideoAtom);
  const { isDark } = useThemeColor();
  const isMobile = useIsMobile();
  const params = useParams();
  const navigate = useNavigate();
  const setShouldPlayVideo = useSetRecoilState(shouldPlayVideoAtom);
  const isMobileMenuOpen = useRecoilValue(mobileMenuOpenAtom);
  const videos: [VideosType] = data?.videos;
  const videoCategories: [VideoCategoriesType] = data?.video_categories;
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation("index");
  const [accordions, setAccordions] = useRecoilState(
    videoCategoriesAccordionOpenedAtom,
  );
  const { userUIEventLogger } = useEventLogger();

  React.useEffect(() => {
    if (params.title && !loading) {
      const getVideoFromQuery = () => {
        const videoIndex = videos?.findIndex(
          (video: any) => video.title === params.title,
        );

        if (videoIndex === -1 || videoIndex === undefined) {
          setMainVideo?.(videos[0] as VideosType);
        } else {
          setMainVideo?.(videos[videoIndex] as VideosType);
          setShouldPlayVideo(false);
        }
      };
      getVideoFromQuery();
    } else {
      if (mainVideo?.link !== NOVIDEO && !loading) {
        const index = videos?.findIndex(
          (video: any) => video.link === mainVideo?.link,
        );
        navigate(`/how-to-videos/${videos[index]["title"]}`);
        setShouldPlayVideo(false);
      }
    }

    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (!loading) {
      const index = videos?.findIndex(
        (video: any) => video.title === params?.title,
      );
      if (params?.title) {
        setMainVideo?.(videos[index] as VideosType);
      }
    }

    //eslint-disable-next-line
  }, [params?.title]);

  React.useEffect(() => {
    const accordion = searchParams.get("category");
    if (accordion) {
      let newObj: any = {};
      accordion.split(",").forEach((el: any) => {
        if (accordion !== null) newObj[el] = true;
      });
      setAccordions(newObj);
    }
    //eslint-disable-next-line
  }, [searchParams]);

  const handleAccordionOpen = (category: any) => {
    const isAlreadyOpen = accordions[category.video_category_name] || false;
    setAccordions((accordionOpened: any) => ({
      ...accordionOpened,
      [category.video_category_name]: !isAlreadyOpen,
    }));
  };

  useDidMountEffect(() => {
    const accordion = searchParams.get("category");

    let accordionsArray: any = [];
    let defaultAccordion: any = [];

    Object.entries(accordions).forEach(([key, value]) => {
      if (value === true) {
        defaultAccordion.push(key);
        accordionsArray.push(key);
      }
    });

    const match = accordionsArray.join(",") === accordion?.split(",").join(",");
    const currentParams = Object.fromEntries(searchParams);

    if (!match)
      setSearchParams({
        ...currentParams,
        category: accordionsArray.join(","),
      });

    //eslint-disable-next-line
  }, [accordions]);

  function accordionContent(category: any) {
    return (
      <div className="grid grid-flow-row-dense gap-y-6 relative h-full">
        {videos
          ?.filter(
            (video: any) =>
              video.category === category && video.link !== NOVIDEO,
          )
          .sort((a: any, b: any) => {
            return a.order - b.order;
          })
          .map((video: any) => (
            <div key={video.link}>
              <VideoPreview
                link={video.link}
                title={video.title}
                description={video.description}
                setMainVideo={setMainVideo}
                mainVideo={mainVideo}
                handleClickEvent={handleClickEvent}
              />
            </div>
          ))}
      </div>
    );
  }

  const handleClickEvent = (text: any) => {
    const value = `how_to_videos__right_pane__${text}`;
    userUIEventLogger(value);
  };

  return (
    <>
      {isMobile ? (
        <Layout headerRightRender={<React.Fragment />} loader={false}>
          <div className="text-white overflow-hidden bg-darkBlueVideo overflow-y-auto h-full pb-[70px]">
            <div className="md:grid md:grid-cols-2fr-1fr gap-x-4 overflow-y-auto">
              <div className="w-full rounded-md md:p-0 p-6">
                <Video
                  link={mainVideo?.link}
                  title={mainVideo?.title}
                  setMainVideo={setMainVideo}
                />
              </div>
              <div className="overflow-y-auto grid gap-y-6  px-6 scrollbar">
                {!loading &&
                  videos.map((video: VideosType) => {
                    if (video.link === NOVIDEO) return null;
                    else if (isMobile) {
                      return (
                        <Video
                          link={video.link}
                          title={video.title}
                          setMainVideo={setMainVideo}
                        />
                      );
                    } else {
                      return (
                        <DemoPlayer
                          link={video.link}
                          title={video.title}
                          setMainVideo={setMainVideo}
                          mainVideo={mainVideo}
                        />
                      );
                    }
                  })}
              </div>
            </div>
          </div>
          <style>
            {`     
              .scrollbar::-webkit-scrollbar {
                width: ${isMobileMenuOpen ? "0px" : "7px"} !important;
                height: ${isMobileMenuOpen ? "0px" : "7px"} !important;
              }

              .scrollbar::-webkit-scrollbar-track {
                  border-radius: 100vh;
                  background: ${
                    isDark
                      ? theme.colors.darkBlueScrollEmpty
                      : theme.colors.linkWater
                  } !important;
                }

                .scrollbar::-webkit-scrollbar-thumb {
                  background: ${
                    isDark
                      ? theme.colors.darkBlueScroll
                      : theme.colors.lightBlueScroll
                  } !important;
                  border-radius: 100vh !important;
                }
            `}
          </style>
        </Layout>
      ) : (
        <Layout headerRightRender={<React.Fragment />} loader={false}>
          <div className="h-full pb-4 overflow-y-hidden text-white preserve-scrollbar">
            <div className="h-full md:grid md:grid-cols-2fr-1fr xl:grid-cols-3fr-1fr gap-x-8">
              <div className="w-full rounded-md md:p-0 p-6 overflow-y-auto gap-y-4 preserve-scrollbar">
                <Video
                  link={mainVideo?.link}
                  title={mainVideo?.title}
                  setMainVideo={setMainVideo}
                />
              </div>

              <div className="preserve-scrollbar overflow-y-auto scrollbar px-4">
                <h1 className="text-[22px] font-medium">{t("Categories")}</h1>

                {videoCategories &&
                  videoCategories
                    .slice()
                    .sort((a: any, b: any) => {
                      return a.video_category_order - b.video_category_order;
                    })
                    .map((category: any) => {
                      const isAlreadyOpen =
                        accordions[category.video_category_name] || false;

                      return (
                        <Accordion
                          onClick={() => {
                            handleAccordionOpen(category);
                            handleClickEvent("chosen_category");
                          }}
                          isOpen={isAlreadyOpen}
                          title={category?.video_category_name}
                          chevronClassNames="text-shadeBlue"
                          titleClassNames="text-sm font-medium text-numberGray"
                          activeTitleClassNames="text-skillPink text-sm font-medium"
                          content={accordionContent(category?.video_category)}
                        />
                      );
                    })}
              </div>
              {/*
                  <div className="w-full md:h-[calc(100vh-175px)] rounded-md md:p-0 p-6">
                    <Video
                      id={mainVideo?.id}
                      title={mainVideo?.title}
                      setMainVideo={setMainVideo}
                    />
                  </div>
                  <div className="overflow-y-auto grid gap-y-6 h-[30%] md:h-[calc(100vh-115px)] px-6 scrollbar">
                    {videos.map((video: VideosType) => {
                      if (video.id === NOVIDEO) return null;
                      else if (isMobile) {
                        return (
                          <Video
                            id={video.id}
                            title={video.title}
                            setMainVideo={setMainVideo}
                          />
                        );
                      } else {
                        return (
                          <DemoPlayer
                            id={video.id}
                            title={video.title}
                            setMainVideo={setMainVideo}
                            mainVideo={mainVideo}
                          />
                        );
                      }
                    })}
                  </div>
      */}
            </div>
          </div>
          <style>
            {`     
              .scrollbar::-webkit-scrollbar {
                width: ${isMobileMenuOpen ? "0px" : "7px"} !important;
                height: ${isMobileMenuOpen ? "0px" : "7px"} !important;
              }

              .scrollbar::-webkit-scrollbar-track {
                  border-radius: 100vh;
                  background: ${
                    isDark
                      ? theme.colors.darkBlueScrollEmpty
                      : theme.colors.linkWater
                  } !important;
                }

                .scrollbar::-webkit-scrollbar-thumb {
                  background: ${
                    isDark
                      ? theme.colors.darkBlueScroll
                      : theme.colors.lightBlueScroll
                  } !important;
                  border-radius: 100vh !important;
                }
            `}
          </style>
        </Layout>
      )}
    </>
  );
}
