import * as Highcharts from "highcharts";
import ReactHighcharts from "highcharts-react-official";
import moment from "moment";
import { useState } from "react";
import { Select } from "./select-element";

export const Chart = ({ data }: any) => {
  const [value, setValue] = useState("days");
  const chartData = data[value];
  const currentTime = moment();
  currentTime.format("MM-DD-YY");

  const treeConfig = {
    chart: {
      type: "column",
    },
    title: {
      text: null,
    },

    xAxis: {
      type: "category",
      labels: {
        rotation: 0,
        style: {
          fontSize: "8px",
          fontFamily: "Verdana, sans-serif",
        },
        width: 8,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
    },
    plotOptions: {
      series: {
        pointWidth: 8,
      },
    },
    colors: ["#0CFEFF"],
    legend: {
      enabled: false,
    },

    series: [
      {
        name: "Population",
        data: chartData,
      },
    ],
  };
  return (
    <>
      <div className="mt-[62px] mb[16px]">Usage chart</div>
      <div className="grid items-center justify-end  mb-[30px]">
        <Select value={value} setValue={setValue} />
      </div>
      <ReactHighcharts
        highcharts={Highcharts}
        options={treeConfig}
      ></ReactHighcharts>
    </>
  );
};
