import { isEqual } from "lodash";
import Papa from "papaparse";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { IconMagnifyingGlass, IconUploadSimple } from "../../assets/SvgIcons";
import { useUserData } from "../../components/auth/data";
import Button from "../../components/button";
import GlobalModal from "../../components/global-modal";
import { useThemeColor } from "../../components/theme-color";
import {
  analyticsItemsErrorAtom,
  clusterAtom,
  collectionsDataAtom,
  createdLocalCollectionAtom,
  currCollectionsTabAtom,
  itemsTextAreaAtom,
  refetchingClusterAtom,
  searchCollectionAtom,
  stopRefetchingAtom,
} from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { CollectionsType } from "../../lib/interfaces";
import {
  createCollection,
  getUserCollections,
} from "../PipelinePage/clustering-api";
import { collectionSelectStyles } from "./analytics-select-styles";
import airline_json from "./clustering-demos/airline_service_demo.json";
import customer_json from "./clustering-demos/customer_service_demo.json";
import wallstreet_json from "./clustering-demos/wallstreet_bets_demo_clean.json";

const getCollectionsQueryID = "getCollectionsQueryID";

export function LeftPaneTabs() {
  const { t } = useTranslation("insights");

  const { userUIEventLogger } = useEventLogger();
  const navigate = useNavigate();
  const [curTab, setCurTab] = useRecoilState<CollectionsType>(
    currCollectionsTabAtom,
  );

  const { collection } = useParams();
  function handleTabClick(
    event: React.MouseEvent<HTMLElement>,
    tab: CollectionsType,
  ) {
    if (tab === "MY_COLLECTIONS") {
      userUIEventLogger("analytics__sample_collection__tab__click");
      navigate("/insights/my-collection");
    } else if (tab === "SAMPLE_COLLECTIONS") {
      userUIEventLogger("analytics__my_collection__tab__click");
      navigate("/insights/sample-collection");
    }
  }

  React.useEffect(() => {
    if (collection === "my-collection") {
      setCurTab("MY_COLLECTIONS");
    } else {
      setCurTab("SAMPLE_COLLECTIONS");
    }
    //eslint-disable-next-line
  }, [collection]);

  return (
    <div className="h-full grid grid-rows-auto-1fr min-w-[320px]">
      <div
        className={` h-[60px] relative grid grid-cols-auto-1fr items-center gap-x-2 dark:text-white font-poppins text-black font-bold text-lg `}
      >
        <button
          onClick={(event) => handleTabClick(event, "SAMPLE_COLLECTIONS")}
          className={`h-full rounded-t-md w-fit-content md:px-6 px-2
            ${
              curTab === "SAMPLE_COLLECTIONS"
                ? "dark:bg-steelGray bg-white"
                : "dark:bg-tabDarkGray bg-white"
            }
            `}
        >
          <span>{t("Sample Collections")}</span>
        </button>

        <button
          onClick={(event) => handleTabClick(event, "MY_COLLECTIONS")}
          className={`h-full rounded-t-md w-fit-content md:px-6 px-2
            ${
              curTab === "MY_COLLECTIONS"
                ? "dark:bg-steelGray bg-white"
                : "dark:bg-tabDarkGray bg-white"
            }
            `}
        >
          <span>{t("My Collections")}</span>
        </button>
      </div>

      {curTab === "SAMPLE_COLLECTIONS" ? (
        <SampleCollections />
      ) : (
        <MyCollections />
      )}
    </div>
  );
}

function SampleCollections() {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const button = `bg-faWhite dark:bg-categoryItemBackgroundDark dark:text-white text-black hover:bg-opacity-50 rounded-md h-[44px]`;
  const setCluster = useSetRecoilState(clusterAtom);
  const clusterArray = demoClusters();
  const navigate = useNavigate();
  const params: any = useParams();
  const { userUIEventLogger } = useEventLogger();

  React.useEffect(() => {
    if (params.title && params.collection === "sample-collection") {
      setCluster({ clusterTitle: params.title, clusterType: "DEMO" });
    }
  }, [params, setCluster]);

  const navigation = (path: any) => {
    setCluster({ clusterTitle: path, clusterType: "DEMO" });
    userUIEventLogger("analytics__sample_collection_click");
    navigate(`/insights/sample-collection/${path}`);
  };

  return (
    <TabsWrapper>
      <div className="grid grid-flow-row gap-y-3">
        {clusterArray.map((text: any, index: any) => {
          return (
            <button
              key={index}
              onClick={() => navigation(text?.clusterName)}
              className={button}
            >
              <span
                className={`px-4 grid ${isRTL ? "text-right" : "text-left"}`}
              >
                {text.clusterName} [DEMO]
              </span>
            </button>
          );
        })}
      </div>
    </TabsWrapper>
  );
}

function TabsWrapper({ children }: any) {
  return (
    <div
      className={`h-full dark:bg-steelGray bg-white rounded-b-md overflow-y-auto`}
    >
      <div className="p-4 overflow-y-auto">{children}</div>
    </div>
  );
}

function MyCollections() {
  return (
    <TabsWrapper>
      <div className="grid grid-flow-col">
        <SearchCollection />
        <AddItems />
      </div>
      <MyCollectionsList />
    </TabsWrapper>
  );
}

function MyCollectionsList() {
  const { t } = useTranslation("insights");
  const { value: firebaseUser } = useUserData();
  const [cluster, setCluster] = useRecoilState(clusterAtom);
  const [stopRefetching, setStopRefetching] =
    useRecoilState(stopRefetchingAtom);
  const createdLocalCollection = useRecoilValue(createdLocalCollectionAtom);
  const [lastData, setLastData] = React.useState<any>([]);
  const [data, setData] = useRecoilState(collectionsDataAtom);
  const shouldShowNewCollection = !stopRefetching && createdLocalCollection;
  const navigation = useNavigate();
  const { isLoading } = useQuery<any>(
    [getCollectionsQueryID, firebaseUser?.studio_key],
    fetchCollectionsWrapper,
    {
      enabled: firebaseUser?.studio_key !== "",
      refetchInterval: !shouldShowNewCollection ? false : 2000,
      keepPreviousData: true,
      onSuccess: (data) => {
        if (!isEqual(data, lastData)) {
          setLastData(data.sort());
          setStopRefetching(true);
          setData(data.sort());
          console.debug(
            "[onSuccess] disabling auto-querying (data is different)",
          );
        }
      },
    },
  );

  let collectionsList = data;
  const searchCollection = useRecoilValue(searchCollectionAtom);

  if (searchCollection !== "") {
    collectionsList = data.filter((collection: string) =>
      decodeURIComponent(collection)
        ?.toLowerCase()
        ?.includes(searchCollection.toLowerCase()),
    );
  }

  if (isLoading) {
    return <div className="pt-3">{t("Loading collections...")}</div>;
  }
  return (
    <div className="grid grid-flow-row gap-y-3 h-full pt-2">
      {collectionsList?.map((collection: string) => {
        const isActiveCollection = cluster?.clusterTitle === collection;
        const decodedCollectionName = decodeURIComponent(collection);
        return (
          <button
            className={`rounded-md py-2 hover:bg-categoryItemBackgroundDarkHover  text-left
                ${
                  isActiveCollection
                    ? "bg-categoryItemBackgroundDarkHover"
                    : "dark:bg-modalDark bg-catskill"
                }
                `}
            onClick={() => {
              setCluster({
                clusterTitle: collection,
                clusterType: "SELF",
              });
              navigation(`/insights/my-collection/${collection}`);
            }}
          >
            <span
              className={`px-2 ${
                isActiveCollection
                  ? "text-borderBlue"
                  : "dark:text-white text-black"
              }`}
            >
              {decodedCollectionName}
            </span>
          </button>
        );
      })}
    </div>
  );
}
function AddItems() {
  const { t } = useTranslation("");
  const [itemsModalOpen, setItemsModalOpen] = React.useState<boolean>(false);
  const { userUIEventLogger } = useEventLogger();
  return (
    <>
      <div className="grid justify-end items-center">
        <Button
          ariaLabel={t("Add items")}
          onClick={() => {
            setItemsModalOpen(true);
            userUIEventLogger("analytics__add_items_dialog__click");
          }}
        >
          <span className="px-4 font-mono">{t("Add Items")}</span>
        </Button>
      </div>
      <AddItemsModal isOpen={itemsModalOpen} setIsOpen={setItemsModalOpen} />
    </>
  );
}

function AddItemsModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: any;
}) {
  const { t } = useTranslation("insights");
  const [selectedOption, setSelectedOption] = React.useState<any>(null);
  const setCreatedLocalCollection = useSetRecoilState(
    createdLocalCollectionAtom,
  );

  React.useEffect(() => {
    if (isOpen === true) {
      setCreatedLocalCollection(false);
    }
    //eslint-disable-next-line
  }, [isOpen]);

  // This is more performant than using it directly since it does not render this component unless it is open
  return isOpen ? (
    <GlobalModal isOpen={isOpen} setIsOpen={setIsOpen} rounded={false}>
      <div className="text-black dark:text-white justify-center p-4 md:min-w-[390px] md:min-h-[421px] gap-y-3">
        <div className="grid gap-y-4">
          <h1 className="text-lg text-center">{t("Add Items")}</h1>
          <NewItemsInput />
          <ChooseOrCreateCollection
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
          <ConfirmOrCancel
            setIsOpen={setIsOpen}
            selectedOption={selectedOption}
          />
        </div>
      </div>
    </GlobalModal>
  ) : (
    <></>
  );
}

function NewItemsInput() {
  const [itemsTextArea, setItemsTextArea] = useRecoilState(itemsTextAreaAtom);
  const setAnalyticsItemsError = useSetRecoilState(analyticsItemsErrorAtom);
  const textAreaRef = React.useRef<any>(null);
  const { t } = useTranslation("insights");
  React.useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
    setItemsTextArea("");
    //eslint-disable-next-line
  }, []);

  // React dropZone
  const { open } = useDropzone({
    noClick: true,
    onDrop: async (acceptedFiles) => onDrop(acceptedFiles),
    multiple: false,
    accept: {
      "text/plain": [".txt"],
      "text/csv": [".csv"],
    },
  });

  const onDrop = React.useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      try {
        const fileContent: any = await readFileAsync(acceptedFiles[0]);
        if (fileContent) {
          if (acceptedFiles[0].type === "text/csv") {
            const flattenArray = Papa.parse(fileContent, {
              skipEmptyLines: true,
            }).data.flat();
            setItemsTextArea(flattenArray.join("\n"));
          } else {
            setItemsTextArea(fileContent);
          }
        }
      } catch (e) {
        setAnalyticsItemsError("Could not read file content");
        console.log("Could not read file content :: ", e);
      }
    }
    //eslint-disable-next-line
  }, []);

  const readFileAsync = async (
    file: File,
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  return (
    <div className="relative">
      {!itemsTextArea && (
        <div
          className="absolute top-3 left-3 md:top-3 md:left-6 select-none grid md:grid-flow-col gap-y-2"
          onClick={() => {
            textAreaRef.current.focus();
          }}
        >
          <span className="text-textLight text-md cursor-text">
            {t("Insert your items")}
          </span>
          <span className="md:px-4 cursor-text">{t("or")}</span>

          <button
            className=" text-shadeBlue grid grid-cols-auto-1fr items-center gap-x-2 cursor-pointer"
            onClick={() => {
              open();
            }}
          >
            <IconUploadSimple className="text-shadeBlue" />
            <span>{t("Upload file")}</span>
          </button>
        </div>
      )}

      <textarea
        data-gramm="false"
        dir="ltr"
        placeholder=" "
        value={itemsTextArea}
        ref={textAreaRef}
        className="w-full h-full bg-inputDarkGray rounded-md border border-inputBorderDarkGray overflow-y-auto resize-none cursor-auto p-3 min-h-[190px] outline-none"
        onChange={(e) => {
          setItemsTextArea(e.target.value);
        }}
      />
    </div>
  );
}

export function demoClusters() {
  return [
    {
      clusterName: "Airline Inquiries",
      clusterJSON: airline_json,
    },
    {
      clusterName: "Customer service",
      clusterJSON: customer_json,
    },
    {
      clusterName: "Wallstreet Bets Reddit",
      clusterJSON: wallstreet_json,
    },
  ];
}

function SearchCollection({ props }: any) {
  const [searchCollection, setSearchCollection] =
    useRecoilState(searchCollectionAtom);
  const { userEventLogger } = useEventLogger();
  React.useEffect(() => {
    const debouncedSearchEvent = setTimeout(() => {
      if (searchCollection !== "")
        userEventLogger(UserEvent.ANALYTICS_COLLECTION_SEARCH, {
          value: searchCollection,
        });
    }, 700);
    return () => clearTimeout(debouncedSearchEvent);
  }, [searchCollection, userEventLogger]);

  return (
    <label htmlFor="search" className="relative">
      <input
        value={searchCollection}
        onChange={(e: React.ChangeEvent<any>) => {
          setSearchCollection(e.target.value);
        }}
        type={"text"}
        placeholder="Search collection"
        autoComplete="none"
        className={`p-4 bg-white dark:bg-inputDarkGray border border-borderLight font-medium dark:border-darkGrayBorder rounded-[5px] text-black h-[54px] dark:text-white focus:outline-none w-full placeholder-formsgray text-sm`}
        {...props}
      />

      <figure className="absolute right-4 top-4 select-none">
        <IconMagnifyingGlass width="24px" height="24px" />
      </figure>
    </label>
  );
}

async function fetchCollectionsWrapper({ queryKey }: any) {
  // queryKey[0] contains the queryKey, queryKey[1] contains the apiKey
  const response = await getUserCollections(queryKey[1]);
  console.debug("[fetchCollectionsWrapper] response: ", response);
  return response;
}

type CreateCollectionType = {
  apiKey: string;
  collectionData: any;
  collectionName: string;
};
async function createCollectionWrapper({
  apiKey,
  collectionData,
  collectionName,
}: CreateCollectionType) {
  // queryKey[0] contains the queryKey, queryKey[1] contains the apiKey

  return await createCollection(apiKey, collectionData, collectionName);
}

function CreateCollection({
  collectionOptions,
  setCollectionOptions,
  setSelectedOption,
  setShowCreateLine,
}: {
  collectionOptions: any;
  setCollectionOptions: any;
  setSelectedOption: any;
  setShowCreateLine: any;
}) {
  const { t } = useTranslation("insights");
  const [collectionName, setCollectionName] = React.useState<string>("");
  const [collectionError, setCollectionError] = React.useState<string>("");
  const setCreatedLocalCollection = useSetRecoilState(
    createdLocalCollectionAtom,
  );
  const onChange = (e: React.ChangeEvent<any>) => {
    setCollectionName(e.target?.value);
  };

  function validateCollectionName() {
    const objectsArrToStrArr = collectionOptions.map(
      (collection: any) => collection.value,
    );

    if (collectionName.length === 0) {
      setCollectionError("Please enter a collection name");
      return false;
    } else if (collectionName.length > 50) {
      setCollectionError("Collection name is too long!");
      return false;
    } else if (objectsArrToStrArr.includes(collectionName)) {
      setCollectionError("This collection name is already taken!");
      return false;
    }
    return true;
  }

  function createLocalCollection() {
    const isValidated = validateCollectionName();

    if (isValidated) {
      const newCollectionOptions = collectionOptions;
      const collectionObj = {
        label: collectionName,
        value: collectionName,
      };

      newCollectionOptions.push(collectionObj);
      setCollectionOptions(newCollectionOptions);
      setSelectedOption(collectionObj);
      setShowCreateLine(false);
      setCreatedLocalCollection(true);
    }
  }
  return (
    <>
      <div className="animate-fade-in grid grid-flow-col">
        <input
          type="text"
          placeholder={t("Collection Name")}
          className="p-3 bg-white dark:bg-inputDarkGray border border-borderLight dark:border-darkGrayBorder rounded-[5px] text-black dark:text-white focus:outline-none w-full placeholder-formsgray md:min-w-[275px]"
          onChange={onChange}
          value={collectionName}
        />
        <div className="grid justify-end">
          <Button
            ariaLabel="Add"
            onClick={() => {
              createLocalCollection();
            }}
          >
            <span className="px-4">{t("Add")}</span>
          </Button>
        </div>
      </div>
      {collectionError && <p className="text-red p-0 m-0">{collectionError}</p>}
    </>
  );
}

function ConfirmOrCancel({
  setIsOpen,
  selectedOption,
}: {
  setIsOpen: any;
  selectedOption: { value: string; label: string };
}) {
  const { t } = useTranslation("insights");
  const setStopRefetching = useSetRecoilState(stopRefetchingAtom);
  const createdLocalCollection = useRecoilValue(createdLocalCollectionAtom);
  const { value: firebaseUser } = useUserData();
  const [data, setData] = useRecoilState(collectionsDataAtom);
  const itemsTextArea = useRecoilValue(itemsTextAreaAtom);
  const setCluster = useSetRecoilState(clusterAtom);
  const setRefetchingCluster = useSetRecoilState(refetchingClusterAtom);
  const navigation = useNavigate();
  const { userUIEventLogger } = useEventLogger();
  const encodedCollection = encodeURIComponent(selectedOption?.value);
  const [itemsError, setItemsError] = useRecoilState<string>(
    analyticsItemsErrorAtom,
  );
  const { mutate } = useMutation(createCollectionWrapper, {
    onError: (e: any) => {
      console.log("Error while trying to create a collection :: ", e);
    },
    onSuccess: (res: any) => {
      setItemsError("");
      if (!data.includes(encodedCollection)) {
        setData([...data, encodedCollection].sort());
        if (createdLocalCollection) {
          setStopRefetching(false);
        }
      } else {
        if (!createdLocalCollection) {
          setRefetchingCluster(encodedCollection);
        }
      }
      setCluster({
        clusterTitle: encodeURIComponent(selectedOption?.value),
        clusterType: "SELF",
      });
      navigation(`/insights/my-collection/${selectedOption?.value}`);
      setIsOpen(false);
    },
    onSettled: () => {
      // Not really needed as we already querying the db every 2 seconds
      //queryClient.invalidateQueries(getCollectionsQueryID);
    },
  });

  const onClickCancel = () => {
    userUIEventLogger("analytics__add_items_cancel__click");
    setIsOpen(false);
  };

  const parseCollection = () => {
    try {
      if (!itemsTextArea) {
        setItemsError("Please enter cluster names (one per line)");
        return;
      }
      if (!selectedOption) {
        setItemsError("Please select a collection");
        return;
      }
      const clusters = itemsTextArea.split("\n");
      const clustersArray = clusters.map((item: string) => {
        return { text: item };
      });
      return [...clustersArray];
    } catch (e) {
      setItemsError("Please enter cluster names (one per line)");
      console.error(e);
      return;
    }
  };

  const onClickConfirm = () => {
    userUIEventLogger("analytics__add_items_confirm__click");
    mutate({
      apiKey: firebaseUser?.studio_key,
      collectionData: parseCollection(),
      collectionName: selectedOption.value,
    });
  };

  React.useEffect(() => {
    setItemsError("");
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsTextArea]);

  return (
    <>
      {itemsError && <p className="text-red p-0 m-0">{itemsError}</p>}
      <div className="grid grid-flow-col gap-x-4 justify-center pt-6">
        <Button
          ariaLabel={t("Cancel")}
          className="!bg-inputDarkGray !w-fit-content"
          onClick={onClickCancel}
        >
          <span className="px-4">{t("Cancel")}</span>
        </Button>
        <Button
          ariaLabel={t("Confirm")}
          className="!w-fit-content"
          onClick={onClickConfirm}
        >
          <span className="px-6">{t("Confirm")}</span>
        </Button>
      </div>
    </>
  );
}
function ChooseOrCreateCollection({
  selectedOption,
  setSelectedOption,
}: {
  selectedOption: { value: string; label: string };
  setSelectedOption: any;
}) {
  const { t } = useTranslation("insights");
  const { isDark } = useThemeColor();
  const [showCreateLine, setShowCreateLine] = React.useState<boolean>(false);
  const { value: firebaseUser } = useUserData();
  const [collectionOptions, setCollectionOptions] = React.useState<any[]>([]);
  const data: any = useRecoilValue(collectionsDataAtom);

  React.useEffect(() => {
    const fetchedOptions = data?.map((collection: string) => {
      return {
        label: decodeURIComponent(collection),
        value: decodeURIComponent(collection),
      };
    });
    setCollectionOptions(fetchedOptions);
  }, [firebaseUser?.studio_key, data]);

  const onClick = () => {
    setShowCreateLine(!showCreateLine);
  };

  return (
    <>
      <div className="grid grid-flow-col">
        <Select
          menuPlacement="auto"
          menuPortalTarget={document.body}
          className="md:min-w-[233px]"
          styles={collectionSelectStyles(isDark ? true : false)}
          components={{
            IndicatorSeparator: () => null,
          }}
          options={collectionOptions}
          onChange={(selectedOption): any => {
            setSelectedOption(selectedOption);
          }}
          value={selectedOption}
        />
        <div className="grid justify-end">
          <button
            onClick={onClick}
            className="min-w-fit cursor-pointer text-center transform-none m-0 items-center py-3 px-1 text-white whitespace-nowrap text-xs md:text-sm font-mono font-bold tracking-tight border-shadeBlue border bg-transparent rounded-md shadow-xl w-fit-content"
          >
            <span className="px-2 font-poppins">{t("Create new")}</span>
          </button>
        </div>
      </div>
      {showCreateLine && (
        <CreateCollection
          collectionOptions={collectionOptions}
          setCollectionOptions={setCollectionOptions}
          setSelectedOption={setSelectedOption}
          setShowCreateLine={setShowCreateLine}
        />
      )}
    </>
  );
}
