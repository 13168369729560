import { useRecoilValue, useSetRecoilState } from "recoil";
import { useMutateUser } from "../components/auth/data";
import { useEventLogger, UserEvent } from "../lib/event_logger";
import { useSharePipeline } from "../lib/use-share-pipeline";
import {
  editorCodeTab,
  pipelineSkillsAtom,
} from "../pages/pipeline/pipeline-atoms";
import { experimentFinishTypeAtom, savedCopyCodeAtom } from "./atoms";
import { FinishType } from "./interfaces";
import { useExperiment } from "./use-experiment";

export const useSendCopyRequestEvent = () => {
  const { userEventLogger } = useEventLogger();
  const skills = useRecoilValue(pipelineSkillsAtom);
  const tab = useRecoilValue(editorCodeTab);
  const { sharePipeline } = useSharePipeline();
  const { isParticipating, experimentLimit } = useExperiment();
  const { finishExperiment } = useMutateUser();
  const setFinishType = useSetRecoilState(experimentFinishTypeAtom);
  const setSavedCopyCode = useSetRecoilState(savedCopyCodeAtom);

  async function sendCopyRequest(
    technology: string,
    method: string,
    request?: string,
  ) {
    const array = [];
    let data = await sharePipeline(false);
    const string = "Edit this One AI API call using our studio at https://";

    if (data && request && tab) {
      if (isParticipating) {
        // Do not allow copy code generator if user is participating in experiment
        // And set the login modal to show
        finishExperiment(experimentLimit);
        setFinishType(FinishType.COPY_CODE);
        setSavedCopyCode(data);
      } else {
        if (technology.startsWith("Python")) {
          // only code generator
          data = `# ${string}${data}\n\n\n`.concat(request);
        } else {
          data = `// ${string}${data}\n\n\n`.concat(request);
        }

        console.debug("[sendCopyRequest - Python] copied data:", method, data);
        navigator.clipboard.writeText(data);
        debugger;
      }
    }

    if ((!data || !tab) && request) {
      // copies unshareable code generator or JSON response
      if (isParticipating) {
        // Do not allow copy code generator if user is participating in experiment
        // And set the login modal to show
        finishExperiment(experimentLimit);
        setFinishType(FinishType.COPY_CODE);
        setSavedCopyCode(request);
      } else {
        console.debug("[sendCopyRequest] copied request:", method, request);
        navigator.clipboard.writeText(request);
        debugger;
      }
    }

    for (const skill of skills?.steps) {
      array.push(skill.value);
    }

    if (tab) {
      userEventLogger(UserEvent.COPY_REQUEST, {
        value: { technology, skills: array, copied: method },
      });
    } else {
      userEventLogger(UserEvent.COPY_RESPONSE, {
        value: { skills: array, copied: method },
      });
    }
  }

  return { sendCopyRequest };
};
