import { useRecoilValue } from "recoil";
import {
  isLocalhost,
  isProduction,
  isStaging,
} from "../pages/PipelinePage/utils";
import { activeFeaturesSelector } from "./atoms";
import { FeatureType } from "./interfaces";

export default function useMustLogin() {
  const activeFeaturesList = useRecoilValue<Array<FeatureType>>(
    activeFeaturesSelector,
  );

  const authFeature = activeFeaturesList?.some(
    (feature: FeatureType) => feature.name === "mslg",
  );

  const mustLogin = {
    hostname: window.location.hostname,
    featureActivated: authFeature,
    shouldShowLoginForm:
      (isStaging() && !authFeature) ||
      (isProduction() && !authFeature) ||
      (isLocalhost() && !authFeature),
  };

  return mustLogin;
}
