import { useTranslation } from "react-i18next";
import { useEventLogger } from "../../../lib/event_logger";

type OutputDataType = {
  handleSetData: (data: any) => void;
  outputData: any;
  outputIndexChosen: any;
  loader: any;
};
function OutputData({
  handleSetData,
  outputData,
  outputIndexChosen,
  loader,
}: OutputDataType) {
  const { t } = useTranslation();
  const { userUIEventLogger } = useEventLogger();

  return (
    <div
      className={`flex flex-row h-full bg-outputGray dark:bg-darkBackground gap-x-2 overflow-x-auto ${
        loader ? "output-loader select-none" : ""
      }`}
    >
      {outputData?.current.map((output: any, outputIndex: any) => {
        let outputTitle = "Original text";
        const outputType = output.text_generated_by_step_name;
        const isCurrentOutput = outputIndexChosen.current === outputIndex;

        switch (outputType) {
          case "summarize":
          case "summarize-transcript":
            outputTitle = "Summarize";
            break;
          case "html-extract-article":
            outputTitle = "Html-to-Article";
            break;
          case "html-extract-text":
            outputTitle = "Html-all-text";
            break;
          case "enhance":
            outputTitle = "Proof-read";
            break;
          case "anonymize":
            outputTitle = "Anonymize";
            break;
          case "business-entities":
            outputTitle = "Business Entites";
            break;
        }

        return (
          <div
            data-cy={`output-tab-${outputType}`}
            key={outputIndex}
            onClick={() => {
              if (!isCurrentOutput) handleSetData(outputIndex);
              userUIEventLogger(
                `studio__output_data__${outputTitle.toLowerCase()}`,
              );
            }}
            className={`grid text-lg font-bold px-1 rounded-t-md w-[203px] ${
              isCurrentOutput
                ? "bg-white dark:bg-darkGrayBackground"
                : "bg-faWhite hover:bg-white dark:bg-tabDarkGray dark:hover:bg-opacity-50"
            } ${
              loader ? "" : "cursor-pointer"
            } transition-all duration-200 items-center overflow-y-hidden`}
          >
            <div className="grid grid-cols-auto-1fr justify-start text-start font-poppins">
              <div
                className={` grid h-[53px] w-[54px] justify-center items-center rounded-sm ${
                  isCurrentOutput
                    ? "text-black dark:text-light dark:bg-lightCharade bg-catskill"
                    : "text-disabledGray dark:bg-darkCharade bg-catskill"
                }`}
              >
                <span
                  className={`text-xs md:text-xl font-poppins ${
                    isCurrentOutput
                      ? "text-black dark:text-white"
                      : "dark:text-numberGray text-tabLightGray"
                  }`}
                >
                  {outputIndex + 1}
                </span>
              </div>
              <div className="grid items-center px-2 justify-center">
                <span
                  className={`text-lg ${
                    isCurrentOutput
                      ? "text-black dark:text-white"
                      : "text-disabledGray"
                  }`}
                >
                  {t(outputTitle)}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default OutputData;
