import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import logo from "../assets/logo-forms.svg";
import darkModeLogo from "../assets/logo-gray.svg";
import { useEventLogger, UserEvent } from "../lib/event_logger";
import { isStaging } from "../pages/PipelinePage/utils";
import { sendQuotaRequestEmail } from "./auth/data";
import { LottieLoader } from "./Loader";
import { useThemeColor } from "./theme-color";

type QuotaRequestFormType = {
  setIsOpen: (isOpen: boolean) => void;
};

export default function QuotaRequest({ setIsOpen }: QuotaRequestFormType) {
  const { t, i18n } = useTranslation("usage-chart");
  const { isDark } = useThemeColor();
  const { userUIEventLogger, userEventLogger } = useEventLogger();
  const isRTL = () => {
    return i18n.dir() === "rtl";
  };

  const [formLoading, setFormLoading] = React.useState<boolean>(false);
  const [nextMessage, setNextMessage] = React.useState<JSX.Element | null>(
    null,
  );

  const inputContainer =
    "p-5 bg-white dark:bg-darkBackground border border-borderLight dark:border-darkGrayBorder rounded-[5px] text-black dark:text-white focus:outline-none w-full placeholder-formsgray";

  const handleSubmit = async (values: any) => {
    setFormLoading(true);
    const formFields = values;
    let prefix = "";
    if (isStaging()) {
      prefix = "[STAGING] ";
    }
    userUIEventLogger("ask_for_quota_form__send_request");
    const result = await sendQuotaRequestEmail({ formFields, prefix });
    const successMessage = <span>{t("Your request has been sent!")}</span>;
    if (result.success) {
      userEventLogger(UserEvent.SEND_QUOTA_REQUEST);
      setNextMessage(successMessage);
    } else {
      setNextMessage(t("We can't process your request at this time."));
    }
    setFormLoading(false);
  };

  function EmptyNumber(typeErrorMessage = "Please enter a valid number") {
    return Yup.number()
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;
        if (!originalValue || !originalValue.trim()) {
          return null;
        }
        // we return the invalid original value
        return originalValue;
      })
      .nullable(true)
      .typeError(typeErrorMessage);
  }

  const requestSchema: object = Yup.object().shape({
    requested_daily_quota: EmptyNumber("Please enter a valid number").positive(
      "Please enter a positive number",
    ),
    requested_monthly_quota: EmptyNumber(
      "Please enter a valid number",
    ).positive("Please enter a positive number"),
  });

  const initialQuotaValues = {
    name: "",
    organization: "",
    role: "",
    requested_daily_quota: null,
    requested_monthly_quota: null,
    more_information: "",
  };

  if (formLoading)
    return (
      <div>
        <div className="grid justify-center">
          <figure className="image w-[155px] h-[37px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <h1 className="grid text-2xl dark:text-white text-black font-bold text-center">
          <LottieLoader />
        </h1>
      </div>
    );

  if (nextMessage)
    return (
      <div className="grid space-x-2 h-[250px] py-2">
        <div className="grid justify-center">
          <figure className="image w-[155px] h-[37px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <div className="grid grid-rows-1fr-auto justify-center">
          <h1 className="grid text-xl dark:text-white text-black font-normal font-poppins text-center break-words p-2">
            {nextMessage}
          </h1>
          <button
            onClick={() => {
              setIsOpen(false);
              setTimeout(() => {
                setNextMessage(null);
              }, 200);
            }}
            className={`bg-shadeBlue justify-self-center rounded-md text-white outline-none w-[165px]  align-middle p-3 hover:bg-opacity-60`}
          >
            <span className="text-center align-middle">{t("Go back")}</span>
          </button>
        </div>
      </div>
    );

  return (
    <div>
      <div className="grid justify-center p-4 md:p-12">
        <figure className="image w-[155px] h-[37px]">
          <img src={isDark ? darkModeLogo : logo} alt="Logo" />
        </figure>
      </div>
      <h1 className="grid text-xl dark:text-white text-black font-bold text-center  font-poppins">
        {t("Ask for quota increase")}
      </h1>
      <p className="text-black dark:text-white text-sm font-normal text-center font-poppins">
        {t("Please tell us more about you and your product:")}
      </p>

      <Formik
        initialValues={initialQuotaValues}
        validationSchema={requestSchema}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched, handleSubmit, isValid, dirty }: any) => (
          <div className="space-y-6 p-6">
            <Form className="space-y-3 overflow-y-auto max-h-[calc(100vh-350px)] min-h-[100px]  font-poppins">
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  name="name"
                  type="text"
                  placeholder={t("Name")}
                  className={`${inputContainer}`}
                />
              </div>
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  name="organization"
                  type="text"
                  placeholder={t("Organization")}
                  className={`${inputContainer}`}
                />
              </div>
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  name="role"
                  type="text"
                  placeholder={t("Role")}
                  className={`${inputContainer}`}
                />
              </div>
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  name="requested_daily_quota"
                  aria-invalid={
                    errors.requested_daily_quota &&
                    touched.requested_daily_quota
                      ? "true"
                      : null
                  }
                  aria-describedby={
                    errors.requested_daily_quota &&
                    touched.requested_daily_quota
                      ? "requested_daily_quota-error"
                      : null
                  }
                  type="tel"
                  placeholder={t("Requested Daily Quota")}
                  className={`${inputContainer} ${
                    errors.requested_daily_quota &&
                    touched.requested_daily_quota
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage name="requested_daily_quota" component="div">
                  {(msg) => (
                    <div
                      role="alert"
                      id="requested_daily_quota"
                      className="invalid-feedback text-red text-left"
                    >
                      {t(msg)}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  name="requested_monthly_quota"
                  aria-invalid={
                    errors.requested_monthly_quota &&
                    touched.requested_monthly_quota
                      ? "true"
                      : null
                  }
                  aria-describedby={
                    errors.requested_monthly_quota &&
                    touched.requested_monthly_quota
                      ? "requested_monthly_quota-error"
                      : null
                  }
                  type="tel"
                  placeholder={t("Requested Monthly Quota")}
                  className={`${inputContainer} ${
                    errors.requested_monthly_quota &&
                    touched.requested_monthly_quota
                      ? "is-invalid"
                      : ""
                  }`}
                />
                <ErrorMessage name="requested_monthly_quota" component="div">
                  {(msg) => (
                    <div
                      role="alert"
                      id="requested_monthly_quota"
                      className="invalid-feedback text-red text-left"
                    >
                      {t(msg)}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  component="textarea"
                  name="more_information"
                  type="textarea"
                  placeholder={t("Tell us more about your product")}
                  className={`${inputContainer}`}
                />
              </div>
            </Form>
            <button
              onClick={handleSubmit}
              type="submit"
              className={`bg-shadeBlue  rounded-md text-white outline-none w-full p-4 hover:bg-opacity-60`}
            >
              {t("Send Request")}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
