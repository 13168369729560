import { useRecoilValue } from "recoil";
import {
  cubeFileAtom,
  fileContentAtom,
  inputResponseAtom,
  inputTypeAtom,
  textAreaAtom,
} from "../../lib/atoms";
import { maybeMockFetch } from "../../lib/mock-data";
import api from "../../services/api";
import { pipelineSkillsAtom } from "../pipeline/pipeline-atoms";
import { getFileExtension } from "../PipelinePage/Input/file-uploader";
import {
  getContentType,
  inputSelectionTypes,
  parseAcademyTextArea,
  parseInput,
  shouldParseAcademy,
} from "./utils";

const fetchAPI = async (
  key: string,
  body: {
    input: string | string[] | object | object[];
    input_type: string;
    steps?: any[];
    encoding?: string;
    content_type?: string;
  },
  apiKey: any,
) => {
  const config = {
    headers: {
      "api-key": apiKey ? apiKey : "NO_TOKEN",
    },
  };
  const baseUrl = "/api/v0/";
  try {
    return await maybeMockFetch(key, body);
  } catch (error) {}
  console.log(body, config);
  return api.post(`${baseUrl}${key}`, body, config).then((response: any) => {
    const { data } = response;
    return { data, headers: response?.headers };
  });
};

export const useGetPipeline = () => {
  // File name is stored in state including it's extension. We need to get the extension in order to detect whether
  // It is a wave file or something else. If it is a wave file we'd like to add the content type to the request.
  const cubeFile = useRecoilValue(cubeFileAtom);
  const inputResponse = useRecoilValue(inputResponseAtom);
  const textArea = useRecoilValue(textAreaAtom);
  const fileContent = useRecoilValue(fileContentAtom);
  const isBase64 =
    (cubeFile && !inputResponse && textArea.startsWith("data:")) || fileContent;
  const encoding = isBase64 ? "base64" : null;
  const contentType = getContentType(
    getFileExtension(cubeFile),
    textArea,
    !!fileContent,
  );
  const selectedInputType = useRecoilValue(inputTypeAtom);
  const pipelineSkills = useRecoilValue(pipelineSkillsAtom);

  const getPipeline = async (
    input: string,
    input_type: string,
    steps: {
      skill: string;
    }[],
    apiKey: string,
  ) => {
    const isConversation =
      selectedInputType === inputSelectionTypes.conversation;
    const shouldParse = shouldParseAcademy(pipelineSkills?.steps, input_type);
    let newInput: string | string[] | object | object[] = input;
    const loweredInputType = input_type?.toLowerCase();

    if (shouldParse) {
      newInput = JSON.parse(parseAcademyTextArea(input));
    } else if (isConversation) {
      newInput = parseInput(isConversation, input);
    }

    return await fetchAPI(
      "pipeline",
      {
        input: newInput,
        input_type: loweredInputType,

        steps,
        ...(encoding && { encoding }),
        ...((isConversation || shouldParse) && {
          content_type: contentType,
        }),
      },
      apiKey,
    );
  };
  return { getPipeline };
};
