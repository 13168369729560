import Menu from "@mui/material/Menu";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { validateEmail } from "../pages/PipelinePage/utils";
import theme from "../theme";
import { getAdmins, inviteAdmin } from "./auth/data";
import { LottieLoader } from "./Loader";

export default function AddAdminPopover({
  openedPopover = () => {},
  closedPopover = () => {},
  setAdmins,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  setAdmins: Function;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("manage-admins");

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <button
        onClick={handleClick}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className="cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl"
      >
        <span>{t("Invite Admin")}</span>
      </button>
      <AddAdminPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        setAdmins={setAdmins}
      />
    </React.Fragment>
  );
}

type IAddAdmin = {
  anchorEl: any;
  open: boolean;
  onClose: any;
  setAdmins: Function;
};

const AddAdminPopup = React.forwardRef((props: IAddAdmin, ref: any) => {
  const { anchorEl, open, onClose, setAdmins } = props;
  const [tipRight, setTipRight] = React.useState(0);
  const tipWidth = 18;
  React.useEffect(() => {
    if (anchorEl) {
      setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2);
    }
  }, [anchorEl]);
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          backgroundColor: theme.colors.lightBlack,
          color: theme.colors.white,
          padding: theme.spacing[4],
          width: "280px !important",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.3,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "4px",
            right: tipRight,
            width: tipWidth,
            height: 18,
            backgroundColor: theme.colors.lightBlack,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            borderRadius: "4px",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <AddAdminForm onClose={onClose} setAdmins={setAdmins} />
    </Menu>
  );
});

const AddAdminForm = ({
  onClose = () => {},
  setAdmins,
}: {
  onClose: any;
  setAdmins: Function;
}) => {
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState("FORM");
  const { t } = useTranslation("manage-accounts");

  const onSubmit = async (values: any, { setSubmfitting }: any) => {
    setState("LOADING");

    const result = await inviteAdmin(values?.email);
    if (result?.data?.success === true) {
      const adminList = await getAdmins(300);
      setAdmins(adminList);
      setState("SUCCESS");
    } else {
      setError(result?.data?.message);
      setState("ERROR");
    }
  };

  const validate = (values: any) => {
    const errors = {} as any;
    if (!validateEmail(values.email)) {
      errors.email = t("Please enter a valid email");
    }
    return errors;
  };

  if (error) {
    return <div>{t(error)}</div>;
  }

  return (
    <div>
      {state === "LOADING" ? (
        <LottieLoader />
      ) : state === "SUCCESS" ? (
        <div>{t("You added a new admin!")}</div>
      ) : state === "ERROR" ? (
        <div>{error}</div>
      ) : (
        <Formik
          initialValues={{ email: "" }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="grid grid-cols-1 gap-y-4 font-poppins pb-4">
              <p className="text-center">{t("Add an admin")}</p>
              <div className="grid grid-cols-1 gap-y-2">
                <label htmlFor="name" className="text-sm">
                  {t("Email Address")}
                </label>
                <Field
                  type="text"
                  name="email"
                  className="bg-dark rounded p-3"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red"
                />
              </div>
              <div
                dir={i18n.dir()}
                className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto  font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={onClose}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Invite")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
