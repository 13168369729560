import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { IconLogIn, IconMobileMenu, IconMobileMenuX } from "../assets/SvgIcons";
import { loginFormAtom, mobileMenuOpenAtom } from "../lib/atoms";
import { useIsMobile } from "../pages/PipelinePage/utils";
import { useUser } from "./auth/auth";
import { useAuth } from "./auth/data";
import NewMenu from "./desktop-menu";
import Discord from "./discord";
import TopNavigation from "./Layout/top-navigation";
import Login from "./login-button";
import MobileMenu from "./mobile-menu";
import { useThemeColor } from "./theme-color";
import { NoPhoto, UserImage, UserName } from "./user-views";

export default function UserMenu() {
  const isMobile = useIsMobile();
  const { user, loading } = useUser();

  // Use this to re-render the user menu when the user changes
  const loginForm = useRecoilValue(loginFormAtom);

  if (loading) return <></>;

  return (
    <div
      className={`grid grid-cols-2-auto items-center h-8  ${
        isMobile ? "pr-4" : ""
      }`}
    >
      {!isMobile && <Discord />}
      {user && !loginForm && !user?.isAnonymous ? (
        <User />
      ) : isMobile ? (
        <RenderMobileMenu />
      ) : (
        <Login />
      )}
    </div>
  );
}

const MobileNav = () => {
  const { t, i18n } = useTranslation("");
  const { isDark, toggleTheme } = useThemeColor();
  const { logout } = useAuth();
  const { user } = useUser();
  const [menuOpen, setMenuOpen] = useRecoilState(mobileMenuOpenAtom);
  const setLoginForm = useSetRecoilState(loginFormAtom);

  const onClick = () => {
    setMenuOpen(!menuOpen);
    setLoginForm(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const isAnonymous = user?.isAnonymous;
  const anonymousCheck = user?.anonymous ? " hidden" : "";

  const linkActive =
    "top-navigation-link text-black dark:text-white font-mono dark:border-b-skillPink border-b-shadeBlue border-b  font-light text-lg text-center";
  const linkInactive =
    "top-navigation-link text-linkInactive dark:text-darkGrayOutput font-mono font-light text-lg  text-center";

  return (
    <>
      <div className="h-full bg-catskill dark:bg-darkBackground p-4">
        <div className="h-[calc(100vh-100px)] w-full bg-white dark:bg-steelGray rounded-md p-4 grid justify-center overflow-y-auto pt-4">
          <div className="grid gap-y-4 text-white grid-rows-auto-auto-auto-1fr items-center py-4">
            <button
              className={`grid grid-cols-2-auto gap-x-4 items-center justify-between cursor-pointer bg-borderBlue hover:opacity-75 rounded-md px-4 h-[50px] ${
                user && !isAnonymous ? "hidden" : ""
              }`}
              onClick={onClick}
            >
              <span className="text-white font-mono font-bold">
                {t("Log In")}
              </span>
              <figure className="text-white ">
                <IconLogIn
                  width="22px"
                  height="22px"
                  className={`text-xs ${
                    i18n.dir() === "rtl" ? "transform rotate-180" : ""
                  }`}
                />
              </figure>
            </button>

            <TopNavigation screen="mobile" />

            <div className={`pt-10 grid justify-center gap-y-8  items-center`}>
              <NavLink
                to="/settings/api-keys"
                onClick={() => closeMenu()}
                className={({ isActive }) =>
                  isActive ? linkActive : linkInactive + anonymousCheck
                }
              >
                <div className="text-center">
                  <span className="text-linkInactive dark:text-darkGrayOutput font-normal text-md text-center font-mono">
                    {t("Manage Account")}
                  </span>
                </div>
              </NavLink>

              <NavLink
                to="/settings/api-keys"
                onClick={() => closeMenu()}
                className={({ isActive }) =>
                  isActive ? linkActive : linkInactive + anonymousCheck
                }
              >
                <div className="text-center">
                  <span className="text-linkInactive dark:text-darkGrayOutput font-normal text-md text-center font-mono">
                    {t("API Keys")}
                  </span>
                </div>
              </NavLink>

              <button onClick={() => toggleTheme()}>
                <span className="text-linkInactive dark:text-darkGrayOutput font-normal py-1 text-md text-center font-mono">
                  {isDark ? t("Dark Mode") : t("Light Mode")}
                </span>
              </button>
              <a
                href="https://www.oneai.com"
                target="_blank"
                rel="noreferrer"
                tabIndex={0}
              >
                <div className="text-center">
                  <p className="text-linkInactive dark:text-darkGrayOutput font-normal m-0 text-md py-1 text-center font-mono">
                    {t("Visit")}{" "}
                    <span className="text-borderBlue ">OneAi.com</span>
                  </p>
                </div>
              </a>

              <a
                href="https://www.oneai.com/contact-us"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <div className="text-center">
                  <span className="text-linkInactive dark:text-darkGrayOutput font-normal py-1 text-md text-center font-mono">
                    {t("Contact Us")}
                  </span>
                </div>
              </a>

              <a
                href="https://www.oneai.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <div className="text-center">
                  <span className="text-linkInactive dark:text-darkGrayOutput font-normal py-1 text-md text-center font-mono">
                    {t("Privacy Policy")}
                  </span>
                </div>
              </a>

              <a
                href="https://www.oneai.com/tos"
                target="_blank"
                rel="noreferrer"
              >
                <div className="text-center">
                  <span className="text-linkInactive dark:text-darkGrayOutput font-normal py-1 text-md text-center font-mono">
                    {t("Terms of Service")}
                  </span>
                </div>
              </a>

              <button
                className={`w-full ${isAnonymous ? "hidden" : ""}`}
                onClick={() => {
                  logout();
                  closeMenu();
                }}
              >
                <div className="text-center">
                  <span className="text-linkInactive dark:text-darkGrayOutput font-normal py-1 text-md text-center font-mono">
                    {t("Log Out")}
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RenderMobileMenu = () => {
  const { user } = useUser();

  return (
    <div className="grid grid-cols-1fr-auto gap-x-4 items-center h-8">
      <span className="grid justify-center items-center">
        {user && user?.photoURL ? (
          <UserImage />
        ) : user && user?.displayName ? (
          <UserName />
        ) : (
          user && !user.isAnonymous && <NoPhoto />
        )}
      </span>
      <div className="grid items-center h-8 w-8">
        <MobileMenu
          mobileComponent={<MobileNav />}
          desktopComponent={<></>}
          showMobileOnDesktop={false}
          showDesktopOnMobile={false}
          dir={"ltr"}
          classNameMobileNav="bg-inputgray text-white w-full  grid items-center"
          hamburgerOpenComponent={
            <IconMobileMenu
              width="24"
              height="24"
              className=" text-shadeBlue  grid items-center"
            />
          }
          hamburgerCloseComponent={
            <IconMobileMenuX
              width="24"
              height="24"
              className="text-shadeBlue grid items-center"
            />
          }
        />
      </div>
    </div>
  );
};

const User = () => {
  const isMobile = useIsMobile();
  return isMobile ? <RenderMobileMenu /> : <NewMenu type="LOGGED_IN" />;
};
