import { Link } from "react-router-dom";
import parse, {
  Element,
  domToReact,
  HTMLReactParserOptions,
} from "html-react-parser";

export default function AppLink({ to, children }: any) {
  const parser: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element && domNode.name === "a") {
        return (
          <Link
            to={domNode.attribs.href}
            className={`text-brightCyan underline`}
          >
            {domToReact(domNode.children, parser)}
          </Link>
        );
      }
    },
  };

  return (
    <>
      {children ? (
        <Link to={to} className={`text-brightCyan underline`}>
          {children}
        </Link>
      ) : (
        <>{parse(to, parser)}</>
      )}
    </>
  );
}
