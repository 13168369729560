import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

const options = [
  {
    id: "1",
    name: "Daily",
    value: "days",
  },
  {
    id: "2",
    name: "Monthly",
    value: "months",
  },
];

export const Select = ({ value, setValue }: any) => {
  const { t } = useTranslation("usage-chart");
  return (
    <div className="border border-grayBorderLight rounded-md ">
      <select
        className="h-full dark:bg-darkGrayBackground dark:text-white rounded-md"
        value={value}
        style={{
          padding:
            i18n.dir() === "rtl"
              ? `12px 16px 12px 32px`
              : `12px 32px 12px 16px`,
          fontSize: "14px",
          backgroundPosition: `calc(${
            i18n.dir() === "rtl" ? "0% +" : "100% -"
          } 0.75rem) center`,
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      >
        {options.map(({ name, value }, index) => (
          <option
            key={index}
            value={value}
            title={name}
            style={{ fontSize: "14px" }}
            className="font-poppins dark:text-white font-normal"
          >
            {t(name)}
          </option>
        ))}
      </select>
    </div>
  );
};
