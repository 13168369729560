import { cloneDeep, toLower, update } from "lodash";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { IconArrowLeft, IconPlus, TrashIcon } from "../../../assets/SvgIcons";
import InfoPopover from "../../../components/info-popover";
import AppTooltip from "../../../components/tooltip";
import {
  openPopoverAtom,
  pipelineDNDColumnsAtom,
  showSkillLibraryAtom,
} from "../../../lib/atoms";
import { useEventLogger, UserEvent } from "../../../lib/event_logger";
import { SkillDataType } from "../types";
import {
  generateOutputTitle,
  getSkillIcon,
  getSkillTypeNameByID,
  getTypeColor,
  useIsMobile,
} from "../utils";
//import contentFile from "../../../utils/content.json";
import { useStudioData } from "../../../lib/use-studio-data";
import Popover from "./Popover";
import SearchSkill from "./search-skill";
import SkillNotFound from "./skill-not-found";
import SkillsPlaceholder from "./skills-placeholder";
import "./styles.css";
import SuggestSkill from "./suggest-skill";

type DragAndDropType = {
  loader?: boolean;
  setSearch: any;
  search: string;
};

const DragAndDrop = ({ loader, setSearch, search }: DragAndDropType) => {
  const [showSkillLibrary, setShowSkillLibrary] =
    useRecoilState(showSkillLibraryAtom);
  const { t, i18n } = useTranslation("index");
  const isMobile = useIsMobile();
  const { userEventLogger } = useEventLogger();
  const [skillMouseHover, setSkillMouseHover] = useState<string>("");
  const [preventDoubleClick, setPreventDoubleClick] = useState<boolean>(false);
  const [columns, setColumns] = useRecoilState(pipelineDNDColumnsAtom);
  const [openedPopover, setOpenedPopover] = useRecoilState(openPopoverAtom);

  const { data, loading, mode } = useStudioData();
  const handleExpandSkillLibrary = () => {
    // Log only if user expends skill library
    if (!showSkillLibrary) {
      userEventLogger(UserEvent.EXPAND_SKILL_LIBRARY);
    }
    setShowSkillLibrary(!showSkillLibrary);
  };

  useEffect(() => {
    if (loader) {
      setShowSkillLibrary(false);
    }
    //eslint-disable-next-line
  }, [loader]);

  const onDragEnd = React.useCallback(
    (
      result: any,
      columns: any,
      setColumns: any,
      userEventLogger: any,
      setOpenedPopover: any,
    ) => {
      const { source, destination } = result;

      console.log(result);
      if (!destination) {
        const itemIndex = source.index;
        if (source.droppableId === "pipeline")
          deleteItemFromPipeline(itemIndex, columns, setColumns);
        return;
      }

      if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        console.log("⭐ ~ DragAndDrop ~ columns", columns);
        const destColumn = columns[destination.droppableId];

        const sourceItems =
          mode === "preview" ? [...data?.skills] : [...sourceColumn.items];
        const destItems = [...destColumn.items];

        const item = sourceItems[source.index];
        const newId = uuidv4();
        destItems.splice(destination.index, 0, { ...item, id: newId });

        if (item?.name === "Clustering") {
          setOpenedPopover(newId);
        }

        userEventLogger(UserEvent.SKILL_ADD, { value: item?.value });
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            items: sourceItems,
          },
          [destination.droppableId]: {
            ...destColumn,
            items: destItems,
          },
        });
      } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
          ...columns,
          [source.droppableId]: {
            ...column,
            items: copiedItems,
          },
        });
      }
    },
    //eslint-disable-next-line
    [data],
  );

  const deleteItemFromPipeline = (
    index: any,
    columns: any,
    setColumns: any,
  ) => {
    const clonedArray = cloneDeep(columns);
    const skillName = clonedArray.pipeline.items[index].value;
    clonedArray.pipeline.items.splice(index, 1);
    setColumns({
      ...clonedArray,
      pipeline: {
        ...clonedArray.pipeline,
        items: clonedArray.pipeline.items,
      },
      library: {
        ...clonedArray.library,
        items: clonedArray.library.items,
      },
    });

    userEventLogger(UserEvent.SKILL_REMOVE, { value: skillName });
  };

  const getAnimationStyle = (
    style: any,
    snapshot: any,
    removeAnimation: boolean,
  ) => {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    const { moveTo } = snapshot.dropAnimation;

    if (!removeAnimation) return style;

    return {
      ...style,
      opacity: moveTo.y ? 1 : 0,
      transitionDuration: moveTo.y ? `500ms` : `0.00001ms`,
    };
  };

  const updateColumn = (itemId: any, params: any) => {
    const clonedArray = cloneDeep(columns);
    const updatedItems = clonedArray.pipeline.items.map((item: any) => {
      if (item.id === itemId) {
        item.params = params;
      }
      return item;
    });

    const newObj = update(clonedArray, `pipeline.items`, () => {
      return updatedItems;
    });

    setColumns({ ...newObj });
  };

  function handleSkillDoubleClick(skillClicked: any) {
    const column = columns["pipeline"];
    const copiedItems = [...column.items];

    const skill: SkillDataType | undefined = data.skills?.find(
      (skill: any) => skill.name === skillClicked,
    );
    if (skill) {
      const mutatedObject: any = { ...skill };
      mutatedObject.id = uuidv4();
      copiedItems.push(mutatedObject);

      if (mutatedObject?.name === "Clustering") {
        setOpenedPopover(mutatedObject.id);
      }

      setColumns({
        ...columns,
        pipeline: {
          ...column,
          items: copiedItems,
        },
      });
    }
  }

  function getFirstSkillForType(type: number) {
    return data?.skills?.find((skill: any) => skill?.type === type);
  }

  const getPipelineColumn = (isColumnSkill: any, column: any) => {
    if (isColumnSkill) {
      return column?.items;
    } else {
      if (mode === "preview") {
        if (search === "") {
          return data?.skills;
        } else {
          return column?.items;
        }
      } else {
        return column?.items;
      }
    }
  };

  // Improves performance
  const popoverCallback = React.useCallback(
    (item: any) => {
      return (
        <Popover
          columnName={item.name}
          itemId={item.id}
          params={item.params}
          defaultParams={item.defaultParams}
          isOpen={openedPopover === item.id}
          setOpenedPopover={setOpenedPopover}
          updateColumn={updateColumn}
        />
      );
    },
    //eslint-disable-next-line
    [updateColumn],
  );

  return (
    <div className="md:h-[calc(100vh-124px)]">
      <div className="flex relative w-auto md:h-full overflow-x-scroll md:overflow-x-visible md:mt-0 mt-4">
        <DragDropContext
          onDragEnd={(result: any) =>
            onDragEnd(
              result,
              columns,
              setColumns,
              userEventLogger,
              setOpenedPopover,
            )
          }
        >
          {Object.entries(columns).map(([columnId, column]: any) => {
            const isColumnSkill = columnId === "pipeline";
            const isColumnLibrary = columnId === "library";

            return (
              <div
                className={`h-700 md:h-full flex flex-col md:overflow-x-visible overflow-y-hidden ${
                  isColumnLibrary ? `bg-gray dark:bg-darkBackground  ` : ""
                }  ${
                  isColumnLibrary
                    ? showSkillLibrary || isMobile
                      ? `library-column  ${
                          isMobile
                            ? "absolute"
                            : i18n.dir() === "rtl"
                            ? "mr-2"
                            : "ml-2"
                        }`
                      : `library-column-closed`
                    : ""
                } `}
                key={columnId}
                style={
                  isColumnLibrary && (showSkillLibrary || isMobile)
                    ? i18n.dir() === "rtl"
                      ? isMobile
                        ? { right: "205px" }
                        : { right: "251px" }
                      : isMobile
                      ? { left: "205px" }
                      : { left: "251px" }
                    : {}
                }
              >
                <div className="dnd-header rounded-t-md bg-white dark:bg-darkGrayBackground dark:text-white">
                  <span className="font-poppins">{t(column.name)}</span>
                  {isColumnSkill &&
                    (showSkillLibrary ? (
                      <AppTooltip title={t("Close Skills Library")}>
                        <button onClick={handleExpandSkillLibrary}>
                          <IconArrowLeft
                            className={`invisible md:visible text-dodgerBlue ${
                              i18n.dir() === "rtl" ? "transform rotate-180" : ""
                            }`}
                          />
                        </button>
                      </AppTooltip>
                    ) : (
                      <AppTooltip title={t("Show Skills Library")}>
                        <button onClick={handleExpandSkillLibrary}>
                          <IconPlus
                            data-cy="show-skill-library"
                            className={`invisible md:visible text-dodgerBlue`}
                          />
                        </button>
                      </AppTooltip>
                    ))}
                </div>
                <div className="bg-catskill dark:bg-darkBackground" />
                <div
                  className={`dnd-body bg-white dark:bg-darkGrayBackground rounded-b-md overflow-y-hidden`}
                >
                  {isColumnLibrary && (
                    <SearchSkill search={search} setSearch={setSearch} />
                  )}
                  {isColumnLibrary &&
                    search &&
                    !columns?.library?.items?.length && (
                      <SkillNotFound setSearch={setSearch} />
                    )}
                  <div className="grid grid-rows-1fr-auto">
                    <Droppable
                      droppableId={columnId}
                      key={columnId}
                      isDropDisabled={isColumnLibrary}
                    >
                      {(provided: any) => {
                        let outputIndex = 0;
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={`w-[202px] pl-2 md:w-[240px] md:min-w-0 h-400 column ${
                              isColumnSkill
                                ? "md:h-[calc(100vh-180px)]"
                                : "md:h-[calc(100vh-285px)]"
                            }  ${
                              isColumnSkill && columns.pipeline.items.length > 2
                                ? "overflow-y-auto"
                                : isColumnLibrary
                                ? "overflow-y-auto"
                                : "overflow-y-hidden"
                            }`}
                            id="dnd-droppable"
                            data-cy={`${
                              isColumnSkill
                                ? "dnd-droppable-skill"
                                : "dnd-droppable-library"
                            }`}
                          >
                            {isColumnSkill &&
                              !columns?.pipeline?.items?.length && (
                                <SkillsPlaceholder />
                              )}

                            {getPipelineColumn(isColumnSkill, column)?.map(
                              (item: any, index: number) => {
                                return (
                                  <React.Fragment key={index}>
                                    {isColumnSkill &&
                                      toLower(
                                        getSkillTypeNameByID(item.type, data),
                                      ) !== "generator skill" &&
                                      toLower(item.type) !==
                                        "generator skill" &&
                                      item.name !== "Business Entities" &&
                                      index === 0 && (
                                        <div
                                          dir={i18n.dir()}
                                          className={`text-xs font-poppins md:text-xs font-bold  text-black dark:text-darkGrayOutput pt-2`}
                                        >
                                          {t("Output")} {++outputIndex}:&nbsp;
                                          <span>{t("Original text")}</span>
                                        </div>
                                      )}
                                    {isColumnSkill &&
                                      (toLower(
                                        getSkillTypeNameByID(item.type, data),
                                      ) === "generator skill" ||
                                        toLower(item.type) ===
                                          "generator skill" ||
                                        item.name === "Business Entities") && (
                                        <div
                                          dir={i18n.dir()}
                                          className={`text-xs font-poppins md:text-xs font-bold text-black dark:text-darkGrayOutput pt-2`}
                                        >
                                          {t("Output")} {++outputIndex}:&nbsp;
                                          <span>
                                            {t(generateOutputTitle(item))}
                                          </span>
                                        </div>
                                      )}

                                    {isColumnLibrary &&
                                      search === "" &&
                                      getFirstSkillForType(item.type)?.name ===
                                        item.name && (
                                        <div
                                          dir={i18n.dir()}
                                          className={`text-sm font-mono font-bold ${
                                            item.type === 1
                                              ? "text-brightYellow"
                                              : item.type === 2
                                              ? "text-brightCyan"
                                              : "text-skillPink"
                                          } text- pb-1 pt-6`}
                                        >
                                          {t(
                                            getSkillTypeNameByID(
                                              item.type,
                                              data,
                                            ),
                                          )}
                                        </div>
                                      )}
                                    <Draggable
                                      key={item.id}
                                      draggableId={item.id}
                                      index={index}
                                    >
                                      {(provided: any, snapshot) => {
                                        return (
                                          <React.Fragment>
                                            <div
                                              onDoubleClick={() => {
                                                if (isColumnLibrary)
                                                  if (!preventDoubleClick) {
                                                    handleSkillDoubleClick(
                                                      item.name,
                                                    );
                                                  }
                                              }}
                                              onFocus={() => {
                                                setSkillMouseHover(item.id);
                                              }}
                                              onMouseOver={() => {
                                                setSkillMouseHover(item.id);
                                              }}
                                              onMouseLeave={() => {
                                                setSkillMouseHover("");
                                                setPreventDoubleClick(false);
                                              }}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className={`select-none text-black dark:text-white rounded-sm mt-2 relative shadow-md dark:shadow-none bg-white border-l-2 dark:bg-darkSkillDark h-[43px] grid  items-center px-2 ${
                                                isColumnSkill &&
                                                skillMouseHover === item.id
                                                  ? "grid-cols-1fr-auto"
                                                  : "grid-cols-auto-1fr-auto"
                                              }  `}
                                              style={{
                                                userSelect: "none",
                                                borderLeftColor: getTypeColor(
                                                  item.type,
                                                  data,
                                                ),
                                                ...getAnimationStyle(
                                                  provided.draggableProps.style,
                                                  snapshot,
                                                  isColumnSkill,
                                                ),
                                              }}
                                            >
                                              <span
                                                className={`grid mx-2 font-poppins font-normal text-sm justify-start ${
                                                  isMobile ? "mt-2" : ""
                                                }`}
                                              >
                                                {t(item.name)}
                                              </span>
                                              <div
                                                className={`grid grid-flow-col justify-self-end`}
                                              >
                                                {isColumnLibrary &&
                                                  item.id ===
                                                    skillMouseHover && (
                                                    <div
                                                      className={`grid justify-self-end w-[30px] ${
                                                        isColumnSkill
                                                          ? "hidden"
                                                          : ""
                                                      }`}
                                                    >
                                                      <InfoPopover
                                                        itemName={item.value}
                                                        setPreventDoubleClick={
                                                          setPreventDoubleClick
                                                        }
                                                      />
                                                    </div>
                                                  )}
                                                {item.hasOwnProperty(
                                                  "params",
                                                ) &&
                                                  isColumnSkill &&
                                                  popoverCallback(item)}

                                                {isColumnSkill &&
                                                  item.id ===
                                                    skillMouseHover && (
                                                    <AppTooltip
                                                      title={t(
                                                        "Remove skill from pipeline",
                                                      )}
                                                    >
                                                      <button
                                                        className="cursor-pointer grid justify-center items-center w-[30px]"
                                                        onClick={() => {
                                                          deleteItemFromPipeline(
                                                            index,
                                                            columns,
                                                            setColumns,
                                                          );
                                                        }}
                                                      >
                                                        <TrashIcon
                                                          className="text-blueOpacity m-0"
                                                          height="20px"
                                                          width="20px"
                                                        />
                                                      </button>
                                                    </AppTooltip>
                                                  )}
                                              </div>

                                              {item.id !== skillMouseHover && (
                                                <figure
                                                  className={`grid justify-center items-center w-[30px]`}
                                                >
                                                  {!loading &&
                                                    getSkillIcon(
                                                      item.name,
                                                      "PIPELINE",
                                                      true,
                                                      data,
                                                    )}
                                                </figure>
                                              )}
                                            </div>

                                            {snapshot.isDragging &&
                                              isColumnSkill && (
                                                <div
                                                  style={{
                                                    borderLeftColor:
                                                      getTypeColor(
                                                        item.type,
                                                        data,
                                                      ),
                                                  }}
                                                  className={`select-none text-black dark:text-white rounded-sm mt-2 relative shadow-md dark:shadow-none bg-white dark:bg-darkSkillDark h-[43px] grid gap-x-2 items-center px-2  grid-cols-1fr-auto border-l-2 dnd-animation-remove`}
                                                >
                                                  <span
                                                    className={`grid px-2 font-poppins font-normal text-sm  justify-start ${
                                                      isMobile ? "mt-2" : ""
                                                    }`}
                                                  >
                                                    {t(item.name)}
                                                  </span>

                                                  <div
                                                    className={`grid grid-flow-col gap-x-2 justify-self-end ${
                                                      isColumnLibrary
                                                        ? ""
                                                        : isColumnSkill &&
                                                          item.id !==
                                                            skillMouseHover
                                                        ? "hidden"
                                                        : ""
                                                    }`}
                                                  >
                                                    <button
                                                      className={`grid justify-self-end w-[30px]  ${
                                                        isColumnSkill
                                                          ? "hidden"
                                                          : ""
                                                      }`}
                                                    >
                                                      <InfoPopover
                                                        itemName={item.value}
                                                        setPreventDoubleClick={
                                                          setPreventDoubleClick
                                                        }
                                                      />
                                                    </button>
                                                  </div>
                                                </div>
                                              )}
                                          </React.Fragment>
                                        );
                                      }}
                                    </Draggable>
                                  </React.Fragment>
                                );
                              },
                            )}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                    <SuggestSkill isColumnSkill={isColumnSkill} />
                  </div>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </div>
    </div>
  );
};
export default DragAndDrop;
