import { useNavigate } from "react-router-dom";
import number from "../assets/404.svg";
import logo404 from "../assets/logo404.svg";
import Button from "./button";

export default function Page404() {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 relative sm:w-full sm:top-12 top-4">
      <img
        src={logo404}
        alt="logo"
        className="sm:justify-self-end w-[90vw] sm:w-[60vw] max-w-[520px] max-h-[150px] md:max-h-[420px]"
      />
      <div className="grid sm:justify-self-start">
        <img
          src={number}
          alt="404"
          className="max-w-[250px] md:max-w-[320px] max-h-[260px]"
        />
        <div className="dark:text-white font-poppins mb-16">
          <p className="-mt-3 mb-0 font-medium  md:text-4xl sm:text-3xl whitespace-nowrap">
            There's nothing here
          </p>
          <p className="mt-10 mb-2 font-normal text-xs md:text-base">
            The requested URL was not found on this server
          </p>
          <p className="mt-0 font-normal text-xs md:text-base">
            That's all we know
          </p>
        </div>
        <Button
          ariaLabel="Go back"
          onClick={() => navigate("/")}
          className="!w-fit-content px-12 py-4 !text-sm"
        >
          Go back
        </Button>
      </div>
    </div>
  );
}
