import Menu from "@mui/material/Menu";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { IconTooltip } from "../assets/SvgIcons";
import { useStudioData } from "../lib/use-studio-data";
import theme from "../theme";
import AppLink from "./appLink";

export default function InfoPopover({
  openedPopover = () => {},
  closedPopover = () => {},
  setPreventDoubleClick = () => {},
  itemName,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  setPreventDoubleClick: Function;
  itemName: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
    //eslint-disable-next-line
  }, [open]);

  return (
    <React.Fragment>
      <button
        onClick={handleClick}
        aria-controls={open ? "info-popover" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className="cursor-pointer p-2 items-center font-mono font-bold tracking-tight grid justify-center"
        data-cy="skill-info-button"
      >
        <figure>
          <IconTooltip
            width="18"
            height="18"
            className={open ? "text-green" : "text-shadeBlue"}
          />
        </figure>
      </button>
      <InfoPopup
        anchorEl={anchorEl}
        open={open}
        itemName={itemName}
        onClose={handleClose}
        setPreventDoubleClick={setPreventDoubleClick}
        setAnchorEl={setAnchorEl}
      />
    </React.Fragment>
  );
}

const useGetTypeExample = (itemName: string) => {
  const { data, loading } = useStudioData();
  const getTypeExample = () => {
    const foundSkill =
      !loading && data?.skills?.find((skill: any) => skill?.value === itemName);
    let title = foundSkill?.tooltip?.title || "";
    let example = foundSkill?.tooltip?.example || "";
    let extras = foundSkill?.tooltip?.extras || [];
    let skillName = foundSkill?.name || "";

    return { title, example, extras, skillName };
  };
  return getTypeExample;
};

type InfoPopupPropsType = {
  anchorEl: any;
  open: boolean;
  itemName: string;
  onClose: any;
  setPreventDoubleClick: Function;
  setAnchorEl: Function;
};

const InfoPopup = React.forwardRef((props: InfoPopupPropsType, ref) => {
  const { anchorEl, open, onClose, itemName, setPreventDoubleClick } = props;
  const [tipRight, setTipRight] = React.useState(0);
  const tipWidth = 18;
  const getTypeExample = useGetTypeExample(itemName);

  setPreventDoubleClick(open);

  React.useEffect(() => {
    if (anchorEl) {
      setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2 + 16);
    }
  }, [anchorEl]);
  const isMultiDocumentSummarization =
    getTypeExample()?.skillName === "Multi-Doc Summary by Ernst et al";

  return (
    <Menu
      anchorEl={anchorEl}
      id="info-popover"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          backgroundColor: theme.colors.lightBlack,
          color: theme.colors.white,

          marginLeft: 2,
          width: "350px !important",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.3,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "4px",
            right: tipRight,
            width: tipWidth,
            height: 18,
            backgroundColor: theme.colors.lightBlack,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            borderRadius: "4px",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div
        className="p-4"
        onMouseLeave={() => {
          onClose();
        }}
      >
        {isMultiDocumentSummarization ? (
          <InfoPopupContentMultiDocumentSummarization itemName={itemName} />
        ) : (
          <InfoPopupContent itemName={itemName} />
        )}
      </div>
    </Menu>
  );
});

const InfoPopupContent = ({ itemName }: { itemName: string }) => {
  const getTypeExample = useGetTypeExample(itemName);
  const { t, i18n } = useTranslation("index");
  return (
    <>
      <div className="grid grid-cols-1fr-auto pb-6">
        <i dir={i18n.dir()} className="text-white font-sans text-md">
          {getTypeExample()?.skillName}
        </i>

        <AppLink
          to={`/docs?api=Language+Skills&accordion=Language+Skills&item=${
            getTypeExample()?.skillName
          }`}
        >
          view docs
        </AppLink>
      </div>
      <div
        dir={i18n.dir()}
        className="text-white font-sans text-sm pb-3"
        dangerouslySetInnerHTML={{ __html: getTypeExample()?.title }}
      />
      <div
        dir={i18n.dir()}
        className={`leading-7 italic text-sm ${
          getTypeExample()?.example ? "" : "hidden"
        }`}
      >
        ● {t("Example")}
      </div>

      <AppLink to={`${getTypeExample()?.example}`}></AppLink>
    </>
  );
};

const InfoPopupContentMultiDocumentSummarization = ({
  itemName,
}: {
  itemName: string;
}) => {
  const getTypeExample = useGetTypeExample(itemName);
  const { i18n } = useTranslation("index");
  return (
    <>
      <div className="grid grid-cols-1fr-auto pb-6">
        <i className="text-white font-sans text-md">
          Proposition-Level Clustering for Multi-Document Summarization by Ernst
          et al
        </i>

        {/* <a
          target={"_blank"}
          className={`text-brightCyan underline`}
          href="https://example.com"
          rel="noreferrer"
        >
          paper^
        </a> */}
      </div>
      <div>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-brightCyan grid mb-2 text-sm"
          href="https://aclanthology.org/2022.naacl-main.128.pdf"
        >
          Paper
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-brightCyan grid !mb-2 text-sm"
          href="https://github.com/oriern/ProCluster"
        >
          Git
        </a>
      </div>
      <div
        dir={i18n.dir()}
        className="text-white font-sans text-sm pb-3"
        dangerouslySetInnerHTML={{ __html: getTypeExample()?.title }}
      />
      <div
        dir={i18n.dir()}
        className={`leading-7 italic text-sm ${
          getTypeExample()?.example ? "" : "hidden"
        }`}
      >
        ● Example
      </div>

      <AppLink to={`${getTypeExample()?.example}`}></AppLink>
    </>
  );
};
