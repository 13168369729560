import React from "react";
import { useTranslation } from "react-i18next";
import { IconAddSkills } from "../../../assets/SvgIcons";
import skillPlaceholder from "../../../assets/skill-placeholder.png";

function SkillsPlaceholder() {
  const { t } = useTranslation("index");
  return (
    <div className="relative h-full overflow-none grid">
      <div className="absolute mt-2 select-none">
        <figure>
          <img src={skillPlaceholder} alt="skills placeholder" />
        </figure>
      </div>
      <div className="absolute top-[100px] w-full">
        <div className="grid justify-center items-center">
          <figure className="grid justify-center items-center">
            <IconAddSkills className="text-shadeBlue" width="43" height="43" />
          </figure>
          <span className="grid justify-center items-center font-poppins font-semibold text-lg dark:text-white text-black mt-6 mb-3">
            {t("Add Skills")}
          </span>
          <span className="grid justify-center items-center dark:text-white text-black font-normal font-poppins leading-8 text-center">
            {t("Drag a skill to the pipeline")}
            <br />
          </span>
        </div>
      </div>
    </div>
  );
}

export default SkillsPlaceholder;
