import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useRecoilValue } from "recoil";
import * as Yup from "yup";
import logo from "../../assets/logo-forms.svg";
import darkModeLogo from "../../assets/logo-gray.svg";
import { activeFeaturesSelector } from "../../lib/atoms";
import { useEventLogger } from "../../lib/event_logger";
import { FeatureType } from "../../lib/interfaces";
import { theme } from "../../tailwind.config";
import { LottieLoader } from "../Loader";
import { useThemeColor } from "../theme-color";
import { useUser } from "./auth";
import CredentialForm from "./credential-form";
import { useAuth, useMutateUser, useUserData } from "./data";
import "./signup.css";

export default function SignUpForm({ setForm }: any) {
  const [step, setStep] = useState(1);
  const activeFeaturesList = useRecoilValue<Array<FeatureType>>(
    activeFeaturesSelector,
  );

  const [formData, setFormData] = useState<Object>({
    name: "",
    position: "",
    company: "",
    company_size: "",
    email: "",
    phone: "",
    agree: false,
  });

  return step === 1 &&
    activeFeaturesList.some(
      (feature: FeatureType) => feature.name === "signup-form",
    ) ? (
    <StepOne
      setForm={setForm}
      formData={formData}
      setFormData={setFormData}
      setStep={setStep}
    />
  ) : (
    <StepTwo setForm={setForm} formData={formData} setFormData={setFormData} />
  );
}

const StepOne = ({ setForm, formData, setFormData, setStep }: any) => {
  const { t, i18n } = useTranslation("auth");
  const { logout } = useAuth();
  const { user } = useUser();
  const { isDark } = useThemeColor();
  const { userUIEventLogger } = useEventLogger();
  const { value: userData } = useUserData();
  const [formLoading, setFormLoading] = useState(false);

  const options = [
    { label: "0-2", value: "0-2" },
    { label: "3-10", value: "3-10" },
    { label: "11-50", value: "11-50" },
    { label: "51-100", value: "51-100" },
    { label: "101-1000", value: "101-1000" },
    { label: "1001+", value: "1001+" },
  ];

  const isRTL = () => {
    return i18n.dir() === "rtl";
  };

  const { fillSignupData } = useMutateUser();

  const handleFinishRegistration = async (values: any) => {
    setFormLoading(true);
    setFormData({ ...values });
    localStorage.setItem("UserSignUpData", JSON.stringify(values));
    if (user !== null) {
      const updateSignup = {
        name: values?.name,
        position: values?.position,
        company: values?.company,
        company_size: values?.company_size?.value,
        email: values?.email,
        phone: values?.phone,
        completed_signup: true,
      };
      await fillSignupData(updateSignup);
      localStorage.removeItem("UserSignUpData");
    } else setStep(2);

    setFormLoading(false);
  };

  const inputContainer =
    "p-5 bg-white dark:bg-darkBackground border border-borderLight dark:border-darkGrayBorder rounded-[5px] text-black dark:text-white focus:outline-none w-full placeholder-formsgray";

  const SignUpSchema: object = Yup.object().shape({
    name: Yup.string()
      .min(2, t("Name is too short"))
      .required(t("Name is required")),
    position: Yup.string()
      .min(3, t("Position is too short"))
      .required(t("Position is required")),
    company: Yup.string()
      .min(3, t("Company is too short"))
      .required(t("Company is required")),
    company_size: Yup.object()
      .shape({
        value: Yup.string(),
        label: Yup.string(),
      })
      .nullable()
      .required("Company size is required"),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
    agree: Yup.bool().oneOf([true], "Please agree to the terms"),
  });

  if (formLoading) {
    return (
      <div className="grid justify-center items-center">
        <LottieLoader />
      </div>
    );
  }
  return (
    <div className="w-full">
      <div className="text-center grid gap-y-2 font-poppins">
        <div className="grid justify-center ">
          <figure className="image w-[155px] h-[37px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <h1 className="text-2xl dark:text-white text-black font-bold m-0 p-0">
          {t("Sign Up")}
        </h1>
        <p className="text-black dark:text-white font-normal">
          {t("Please fill in the following details")}
        </p>
        <div className="grid gap-y-4 font-poppins font-normal  text-sm  px-10">
          <Formik
            initialValues={formData}
            validationSchema={SignUpSchema}
            onSubmit={async (values) => {
              await handleFinishRegistration(values);
            }}
          >
            {({ errors, touched, handleSubmit, isValid, dirty }: any) => (
              <>
                <Form className="grid grid-flow-row gap-y-6  overflow-y-auto  max-h-[calc(100vh-350px)] min-h-[100px]">
                  <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                    <ErrorMessage name="name" component="div">
                      {(msg) => (
                        <div
                          role="alert"
                          id="name-error"
                          className="invalid-feedback text-red text-left"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    <Field
                      name="name"
                      aria-invalid={errors.name && touched.name ? "true" : null}
                      aria-describedby={
                        errors.name && touched.name ? "name-error" : null
                      }
                      aria-required="true"
                      type="text"
                      placeholder="Name"
                      className={`${inputContainer} ${
                        errors.name && touched.name ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                  <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                    <ErrorMessage name="position" component="div">
                      {(msg) => (
                        <div
                          role="alert"
                          id="position-error"
                          className="invalid-feedback text-red"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    <Field
                      name="position"
                      type="text"
                      aria-invalid={
                        errors.position && touched.position ? "true" : null
                      }
                      aria-describedby={
                        errors.position && touched.position
                          ? "position-error"
                          : null
                      }
                      aria-required="true"
                      placeholder="Position"
                      className={`${inputContainer} ${
                        errors.position && touched.position ? "is-invalid" : ""
                      }`}
                    />
                  </div>

                  <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                    <ErrorMessage name="company" component="div">
                      {(msg) => (
                        <div
                          role="alert"
                          id="company-error"
                          className="invalid-feedback text-red"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    <Field
                      name="company"
                      type="text"
                      aria-invalid={
                        errors.company && touched.company ? "true" : null
                      }
                      aria-describedby={
                        errors.company && touched.company
                          ? "company-error"
                          : null
                      }
                      aria-required="true"
                      placeholder="Company"
                      className={`${inputContainer} ${
                        errors.company && touched.company ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                  <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                    <ErrorMessage name="company_size" component="div">
                      {(msg) => (
                        <div
                          role="alert"
                          id="company_size-error"
                          className="invalid-feedback text-red"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    <Field name="company_size" type="select">
                      {({ field, form }: any) => (
                        <Select
                          placeholder={"Company Size"}
                          className={`text-left ${
                            errors.company_size && touched.company_size
                              ? "is-invalid"
                              : ""
                          }`}
                          styles={selectStyles(isDark)}
                          {...field}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          options={options}
                          onChange={(
                            selectedOption: React.FormEvent<HTMLInputElement>,
                          ) => {
                            form.setFieldValue(field.name, selectedOption);
                          }}
                        />
                      )}
                    </Field>
                  </div>

                  <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                    <ErrorMessage name="email" component="div">
                      {(msg) => (
                        <div
                          role="alert"
                          id="email-error"
                          className="invalid-feedback text-red"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                    <Field
                      type="email"
                      name="email"
                      formNoValidate="formNoValidate"
                      aria-invalid={
                        errors.email && touched.email ? "true" : null
                      }
                      aria-describedby={
                        errors.email && touched.email ? "email-error" : null
                      }
                      aria-required="true"
                      placeholder="Email"
                      className={`${inputContainer}
                        ${errors.email && touched.email ? "is-invalid" : ""}`}
                    />
                  </div>

                  <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                    <Field
                      type="tel"
                      name="phone"
                      placeholder={t("Phone")}
                      className={inputContainer}
                    />
                  </div>

                  <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                    <ErrorMessage name="agree" component="div">
                      {(msg) => (
                        <div
                          role="alert"
                          id="agree-error"
                          className="invalid-feedback text-red"
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>

                    <Field name="agree" type="checkbox">
                      {({ field, form }: any) => (
                        <label
                          className="grid grid-cols-auto-1fr"
                          htmlFor="agree"
                        >
                          <input
                            type="checkbox"
                            className="checkbox bg-white dark:bg-darkBackground"
                            aria-invalid={
                              errors.agree && touched.agree ? "true" : null
                            }
                            aria-describedby={
                              errors.agree && touched.agree
                                ? "agree-error"
                                : null
                            }
                            aria-required="true"
                            onChange={(e) => {
                              form.setFieldValue(field.name, e.target.checked);
                            }}
                            {...field}
                          />
                          <span
                            className="text-sm dark:text-white text-formsgray text-left px-3"
                            onClick={(e) => {
                              form.setFieldValue(field.name, !field.checked);
                            }}
                          >
                            {t("I accept the")}{" "}
                            <a
                              className="text-shadeBlue"
                              href="https://www.oneai.com/tos"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t("terms of service")}
                            </a>{" "}
                            {t("and")}{" "}
                            <a
                              className="text-shadeBlue"
                              href="https://www.oneai.com/privacy-policy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t("privacy policy")}
                            </a>
                          </span>
                        </label>
                      )}
                    </Field>
                  </div>
                </Form>

                <button
                  onClick={handleSubmit}
                  type="submit"
                  className={`bg-shadeBlue  rounded-md text-white outline-none w-full p-4 ${
                    !isValid || !dirty ? "bg-opacity-50" : "hover:bg-opacity-75"
                  }`}
                >
                  {t("Next step")}
                </button>
                <div className="text-formsgray dark:text-white mt-4 text-sm font-poppins font-normal">
                  {t("Have an account?")}
                  <button
                    className="text-shadeBlue px-2 font-mono font-bold"
                    onClick={() => {
                      setForm("login");
                      localStorage.removeItem("UserSignUpData");
                      logout();
                      userUIEventLogger("app__login_form__signin", {
                        uid: userData?.uid || "empty",
                        api_key: userData?.studio_key || "empty",
                      });
                    }}
                  >
                    {t("Sign In")}
                  </button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const StepTwo = ({ setForm }: any) => {
  const { t } = useTranslation("auth");
  const { isDark } = useThemeColor();
  const { userUIEventLogger } = useEventLogger();
  const { value: userData } = useUserData();
  return (
    <div className="w-[279px] grid justify-center ">
      <div className="py-3 grid justify-center ">
        <figure className="image w-[165px] py-8">
          <img src={isDark ? darkModeLogo : logo} alt="Logo" />
        </figure>
      </div>

      <div className="grid gap-y-2 w-full  overflow-x-hidden overflow-y-auto">
        <CredentialForm type="SIGNUP" setForm={setForm} />
        <div className="grid justify-center py-2 pt-8">
          <div className="text-black dark:text-white text-sm font-poppins font-normal text-center leading-6">
            {t("By clicking “Sign up”, you agree to our ")}
            <a
              className="text-shadeBlue inline-block"
              href="https://www.oneai.com/tos"
              target={"_blank"}
              rel="noreferrer"
            >
              &nbsp;{t("terms of service")}&nbsp;
            </a>
            {t("and")}
            <a
              className="text-shadeBlue inline-block"
              href="https://www.oneai.com/privacy-policy"
              target={"_blank"}
              rel="noreferrer"
            >
              &nbsp;{t("privacy policy")}&nbsp;
            </a>
          </div>
        </div>
        <div className=" grid justify-center py-3">
          <div className="text-black dark:text-white text-sm font-roboto font-normal">
            {t("Have an account?")}
            <button
              className="text-shadeBlue px-2 font-mono font-bold justify-self-center"
              onClick={() => {
                setForm("login");
                localStorage.removeItem("UserSignUpData");
                userUIEventLogger("app__login__form__sign_in", {
                  uid: userData?.uid || "empty",
                  api_key: userData?.studio_key || "empty",
                });
              }}
            >
              {t("Sign In")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const selectStyles = (isDark: any) => ({
  control: (provided: any) => ({
    ...provided,
    padding: "11px 4px",
    border: isDark
      ? `1px solid ${theme.colors.darkGrayBorder}`
      : `1px solid ${theme.colors.borderLight}`,
    boxShadow: "none",
    borderRadius: "5px",
    backgroundColor: isDark ? theme.colors.darkBackground : theme.colors.white,
    "&:hover": {
      border: isDark
        ? `1px solid ${theme.colors.darkGrayBorder}`
        : `1px solid ${theme.colors.borderLight}`,
    }, // border style on hover
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: isDark ? theme.colors.white : theme.colors.black,
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    svg: {
      fill: theme.colors.dodgerBlue,
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: theme.colors.formsgray,
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: isDark
      ? theme.colors.darkGrayBackground
      : theme.colors.faWhite,
    border: isDark
      ? `1px solid ${theme.colors.darkGrayBorder}`
      : `1px solid ${theme.colors.borderLight} `,
  }),
  option: (provided: any, { isFocused, isSelected, isActive }: any) => ({
    ...provided,
    backgroundColor: isSelected
      ? isDark
        ? theme.colors.faWhite
        : theme.colors.darkGrayBackground
      : isFocused
      ? isDark
        ? theme.colors.faWhite
        : theme.colors.darkGrayBackground
      : isDark
      ? theme.colors.darkGrayBackground
      : theme.colors.faWhite,
    color: isSelected
      ? isDark
        ? theme.colors.darkGrayBackground
        : theme.colors.white
      : isFocused
      ? isDark
        ? theme.colors.darkGrayBackground
        : theme.colors.white
      : isDark
      ? theme.colors.white
      : theme.colors.darkGrayBackground,
    "&:focused": {
      backgroundColor: isDark
        ? theme.colors.faWhite
        : theme.colors.darkGrayBackground,
    },
    "&:hover": {
      color: isDark ? theme.colors.black : theme.colors.white,
    },
    "&:active": {
      color: isDark ? theme.colors.black : theme.colors.white,
      backgroundColor: isDark
        ? theme.colors.faWhite
        : theme.colors.darkGrayBackground,
    },
  }),
});
