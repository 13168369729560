import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { IconLongArrow } from "../../assets/SvgIcons";
import AppModal from "../../components/app-modal";
import { OutputLoader } from "../../components/Loader";
import SuggestSkillForm from "../../components/suggest-skill-form";
import { loaderAtom } from "../../lib/atoms";
import { useIsMobile } from "../PipelinePage/utils";
import { DND, nColsAtom } from "./pipeline-dnd";

export default function PipelineSkillsPanel() {
  const outputLoader = useRecoilValue(loaderAtom);
  const isMobile = useIsMobile();

  return (
    <>
      <OutputLoader loader={!isMobile && outputLoader} />
      <div
        className={`w-full h-[calc(100%-60px)] md:h-full overflow-y-hidden text-white rounded-md ${
          outputLoader && !isMobile ? "output-loader" : ""
        }`}
      >
        <DND />
      </div>
    </>
  );
}

export const SuggestASkill = () => {
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const { t } = useTranslation("index");

  const nCols = useRecoilValue(nColsAtom);

  return (
    <div className={`pb-4 ${nCols === 4 ? "pr-16" : ""}`}>
      <button
        onClick={() => setModalOpen(true)}
        className="dark:bg-categoryItemBackgroundDark bg-white py-5 px-2 border-l-2 border-turbo grid grid-cols-1fr-auto gap-x-4 items-center w-full shadow-md"
      >
        <span className="p-0 m-0 underline decoration-brightYellow text-turbo text-left">
          {t("Suggest a Skill")}
        </span>
        <IconLongArrow />
      </button>
      <AppModal isOpen={isModalOpen} setIsOpen={setModalOpen}>
        <SuggestSkillForm setIsOpen={setModalOpen} />
      </AppModal>
    </div>
  );
};
