import { cloneDeep, groupBy, isEqual, unescape } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { IconLongArrowLeft } from "../assets/SvgIcons";
import { currentClusterDataAtom, tabsValueAtom } from "../lib/atoms";
import TreeMapGenerator from "../pages/PipelinePage/Output/clustering-generator";
import {
  isClusterable,
  isClusteringLabel,
  skillValueToName,
} from "../pages/PipelinePage/utils";
import AppTooltip from "./tooltip";

type ClusteringProps = {
  width: number;
  height: number;
  json?: string;
  type?: string;
  rightButton: JSX.Element;
  hideCategories?: boolean;
};

export default function Clustering({
  json,
  width,
  height,
  rightButton,
  hideCategories,
}: ClusteringProps) {
  const [dataCategories, setDataCategories] = React.useState<any>([]);
  const [globalData, setGlobalData] = React.useState<any>([]);
  const SQUARES_LIMIT = 30;
  const [chosenCategory, setChosenCategory] = React.useState<any>("All");
  const setCurrentCluster = useSetRecoilState(currentClusterDataAtom);
  const [level, setLevel] = React.useState<number>(0);
  const [chart, _setChart] = React.useState<any>({
    children: [],
  });
  const setTabsValue = useSetRecoilState(tabsValueAtom);
  const setChart = (data: any) => {
    setGlobalData({ ...globalData, level });
    _setChart({ ...data, children: data.children?.slice(0, SQUARES_LIMIT) });
  };

  function fetchCategoryItems() {
    try {
      if (json) {
        // Clones the array so we can use it without mutating the original
        const clonedArray = cloneDeep(json);

        // Gets the labels from the JSON with the format we need
        const fullArray = formatJSONForLabels(clonedArray);
        setGlobalData({ ...globalData, fullArray });

        // Groups the labels by skill
        const groupedBy: any = groupBy(fullArray, "skill");

        // All is the initial state, but we actually show the first skill (see useEffect)
        if (chosenCategory !== "All") {
          // Checks whether we have too many squares
          if (groupedBy[chosenCategory]?.length >= SQUARES_LIMIT) {
            let counter = 0;
            for (
              let i = SQUARES_LIMIT;
              i < groupedBy[chosenCategory].length;
              i++
            )
              counter += groupedBy[chosenCategory][i].items_count;

            groupedBy[chosenCategory] = groupedBy[chosenCategory].splice(
              0,
              SQUARES_LIMIT - 1,
            );

            const isOthersExists = groupedBy[chosenCategory]?.some(
              (label: any) => label.text === "Others",
            );

            // Show "Others" if it doesn't exist, or "Other Values" if it does.
            groupedBy[chosenCategory].push({
              text: isOthersExists ? "Other Values" : "Others",
              items_count: counter,
              position: groupedBy[chosenCategory]?.length,
              others: true,
            });
          }
          const groupByData = groupedBy[chosenCategory]?.map(
            (item: any, index: number) => {
              // Action items get a different format
              if (item?.skill === "action-items") {
                return item?.phrases?.map(
                  (phrase: any, phraseIndex: number) => {
                    return {
                      text: phrase.text,
                      items_count: phrase?.items_count,
                      position: phraseIndex,
                      ...(phrase.others && { others: true }),
                    };
                  },
                );
              }
              return {
                text: item.text ? item.text : item?.cluster_phrase,
                items_count: item.items_count,
                position: index,
                ...(item.others && { others: true }),
              };
            },
          );

          const childrenArray: any = {
            children: [],
          };

          try {
            childrenArray.children = groupByData.flat();
          } catch (e) {
            childrenArray.children = groupByData;
          }

          if (!isEqual(childrenArray, chart)) {
            const newLevel = level ? level - 1 : 0;
            setLevel(newLevel);
            setChart(childrenArray);
          }
        } else {
          if (json) {
            let clonedJson = cloneDeep(fullArray);

            if (clonedJson?.length >= SQUARES_LIMIT) {
              let counter = 0;
              for (let i = SQUARES_LIMIT; i < clonedJson.length; i++) {
                counter += clonedJson[i].items_count;
              }
              clonedJson = clonedJson?.splice(0, SQUARES_LIMIT - 1);

              const isOthersExists = clonedJson?.some(
                (label: any) => label.text === "Others",
              );

              clonedJson.push({
                text: isOthersExists ? "Other Values" : "Others",
                items_count: counter,
                position: clonedJson?.length,
                others: true,
              });
            }

            const newArray = clonedJson?.map((item: any, index: any) => {
              return {
                text: item.text ? item.text : item?.cluster_phrase,
                items_count: item.items_count,
                position: index,
                //phrases: item?.phrases || [],
              };
            });
            const childrenArray = {
              children: [],
            };

            setCurrentCluster({
              clusterCount: 0,
              clusterName: "",
            });
            childrenArray.children = newArray;
            if (!isEqual(childrenArray, chart)) {
              const newLevel = level ? level - 1 : 0;
              setLevel(newLevel);
              setChart(childrenArray);
              setGlobalData({ ...globalData, level: newLevel });
            }
            if (childrenArray) {
              setChart(childrenArray);
            }
          }
        }
      }
    } catch (e) {
      console.log("Could not parse JSON object ", e);
    }
  }

  React.useMemo(() => {
    const allLabels = formatJSONForLabels(json)?.map(
      (item: any) => item?.skill,
    );

    const allCategories = Array.from(new Set(allLabels.flat()));

    if (allCategories.includes("origin"))
      delete allCategories[allCategories.indexOf("origin")];

    setDataCategories(allCategories);
    setChosenCategory(allCategories[0]);
    setCurrentCluster({
      clusterName: "",
      clusterCount: 0,
    });

    let clonedJson = formatJSONForLabels(cloneDeep(json));

    if (clonedJson.length >= SQUARES_LIMIT) {
      let counter = 0;
      for (let i = SQUARES_LIMIT; i < clonedJson?.length; i++) {
        counter += clonedJson[i].items_count;
      }
      clonedJson = clonedJson.splice(0, SQUARES_LIMIT - 1);

      const isOthersExists = clonedJson?.some(
        (label: any) => label.text === "Others",
      );

      clonedJson.push({
        text: isOthersExists ? "Other Values" : "Others",
        items_count: counter,
        others: true,
        position: clonedJson.length,
      });
    }

    const newArray = clonedJson?.map((item: any, index: any) => {
      return {
        text: item.text ? item?.text : item?.cluster_phrase,
        items_count: item.items_count,
        position: index,
      };
    });

    const childrenArray = {
      children: [],
    };
    childrenArray.children = newArray;

    if (childrenArray) {
      setChart(childrenArray);
    }
    //eslint-disable-next-line
  }, [json, setTabsValue]);

  function setFather(itemName: any) {
    const clonedArray = cloneDeep(json);

    const filterName = formatJSONForLabels(clonedArray).filter((item: any) => {
      return item.text
        ? item.text === itemName && item.skill === chosenCategory
        : item.cluster_phrase === itemName;
    });
    let totalClusterCount = 0;

    if (filterName.length) {
      const newArray: any = [];
      filterName.forEach((value: any) => {
        // This part fixes the array

        if (value?.phrases?.length >= SQUARES_LIMIT - 1) {
          let otherChildrenCounters = 0;

          //console.log("NewSplice is :: ", newSplice);

          // console.log("Value is :: ");
          for (let i = SQUARES_LIMIT; i < value?.phrases?.length; i++) {
            // console.log("Adding phrases :: ", value?.phrases[i]?.items_count);
            otherChildrenCounters += value?.phrases[i]?.items_count;
          }
          value?.phrases?.splice(SQUARES_LIMIT, value?.phrases?.length);
          // console.log("Value pharses :: ", otherChildrenCounters);

          const isOthersExists = value?.phrases?.some(
            (phrase: any) => phrase.text === "Others",
          );

          newArray.push({
            text: isOthersExists ? "Other Values" : "Others",
            items_count: otherChildrenCounters,
            position: value?.phrases?.length,
            others: true,
          });
          totalClusterCount += otherChildrenCounters;
        }

        value?.phrases?.forEach((item: any, index: any) => {
          console.log("Item :: ", item);
          newArray.push({
            text: item.text,
            items_count: item.items_count,
            position: index,
            phrases: item?.phrases
              ? Object.entries(item.phrases).map((v) => {
                  return { text: v[0], items_count: v[1] };
                })
              : [],
          });
          console.log(newArray);
          totalClusterCount += item.items_count;
        });
      });
      let childrenArray: any = {
        children: [],
      };
      childrenArray.children = newArray;

      if (!isEqual(childrenArray, chart)) {
        if (level === 1) return;

        setChart(childrenArray);
        setLevel(1);
        setCurrentCluster({
          clusterName: itemName,
          clusterCount: totalClusterCount,
        });
      }
    }
  }

  React.useEffect(() => {
    fetchCategoryItems();
    //eslint-disable-next-line
  }, [chosenCategory, json]);

  // The purpose of fixHeight is to make sure that the height of the chart is
  // exactly the same either there are categories or not (together)
  // when not, the rectangles should reach to the top.
  const fixHeight = level && hideCategories ? true : false;

  return (
    <>
      <div className="bg-white dark:bg-darkGrayBackground  justify-center items-center overflow-x-auto">
        <div className="items-center">
          <div className="gap-x-6 items-center">
            <AnalyticsClusteringHeader
              hideCategories={hideCategories || false}
              fetchCategoryItems={fetchCategoryItems}
              dataCategories={dataCategories}
              setChosenCategory={setChosenCategory}
              chosenCategory={chosenCategory}
              level={level}
              json={json}
              rightButton={rightButton}
            />
          </div>
        </div>
        <div className="overflow-hidden w-full grid justify-center items-end">
          {chart && chart.children && (
            <TreeMapGenerator
              globalData={globalData}
              level={level}
              dataLabel={chart}
              width={width}
              height={fixHeight ? height - 48 : height + 22}
              setFather={setFather}
            />
          )}
        </div>
      </div>
    </>
  );
}

type AnalyticsClusteringHeaderProps = {
  hideCategories: boolean;
  fetchCategoryItems: () => void;
  dataCategories: any;
  setChosenCategory: (category: any) => void;
  chosenCategory: string;
  level: number;
  json: any;
  rightButton: JSX.Element;
};
function AnalyticsClusteringHeader({
  hideCategories,
  fetchCategoryItems,
  dataCategories,
  setChosenCategory,
  chosenCategory,
  level,
  json,
  rightButton,
}: AnalyticsClusteringHeaderProps) {
  const { t } = useTranslation("index");

  return hideCategories && !level ? (
    <></>
  ) : (
    <div className="bg-opacity-60 bg-clusteringBlue">
      <div className="grid grid-flow-col gap-y-4 justify-between gap-x-2 mt-1 items-center py-4 px-2  border-b-2 border-black">
        <div className="grid grid-cols-1fr-auto items-center gap-x-4">
          <button
            className={`grid rounded-[3px]  items-center grid-cols-1fr-auto gap-x-2 px-4 bg-white bg-opacity-30 hover:bg-opacity-50 hover:bg-white py-2 ${
              !hideCategories ? "ml-2" : ""
            } ${!level ? "invisible" : ""}`}
            onClick={() => fetchCategoryItems()}
          >
            <figure className="text-clusteringGray grid items-center text-center">
              <IconLongArrowLeft />
            </figure>
            <span className="text-clusteringGray grid items-center text-center m-0 font-mono font-[400] select-none">
              {t("BACK")}
            </span>
          </button>
          <ClusterTitle shouldShowTitle={hideCategories} />
        </div>
        <div className="px-2  overflow-x-auto overflow-y-hidden whitespace-nowrap">
          {!hideCategories &&
            dataCategories?.map((value: any) => {
              return (
                <button
                  onClick={() => setChosenCategory(value)}
                  className="px-2"
                >
                  <span
                    className={`dark:text-yellow text-shadeBlue font-poppins font-light text-[16px] ${
                      value === chosenCategory ? "underline " : ""
                    }`}
                  >
                    {skillValueToName(value)}
                    {value !== "All" && ` (${getLabelsCount(value, json)})`}
                  </span>
                </button>
              );
            })}
        </div>

        <div className="flex justify-end">
          <AppTooltip title={t("Labels clusters")}>{rightButton}</AppTooltip>
        </div>
        <ClusterCount shouldShowCount={hideCategories} />
      </div>
    </div>
  );
}

function getLabelsCount(itemName: string, json: any) {
  const allLabels = formatJSONForLabels(json, "count")?.filter((item: any) => {
    return item?.skill === itemName;
  });
  const flatLabels = allLabels?.flat();
  let counter = 0;
  flatLabels?.forEach((value: any) => {
    if (value?.phrases) counter += value?.phrases?.length;
  });
  return counter;
}

function ClusterTitle({ shouldShowTitle }: { shouldShowTitle: boolean }) {
  const currentCluster = useRecoilValue(currentClusterDataAtom);
  if (shouldShowTitle) {
    return (
      <span className="text-white font-poppins md:text-lg">
        {unescape(currentCluster.clusterName)}
      </span>
    );
  } else {
    return null;
  }
}

function ClusterCount({ shouldShowCount }: { shouldShowCount: boolean }) {
  const currentCluster = useRecoilValue(currentClusterDataAtom);
  if (shouldShowCount) {
    return (
      <span className="text-[#D0CBCB] font-poppins text-lg grid justify-end pr-4">
        {currentCluster.clusterCount}
      </span>
    );
  } else {
    return null;
  }
}

function formatJSONForLabels(json: any, type: string = "regular") {
  try {
    if (json[0]?.labels || json?.labels) {
      // Takes only the labels from the JSON (anything else isn't needed)
      const categories: any = json.length
        ? json?.map((output: any) => {
            return output?.labels;
          })
        : json?.labels;

      // Flatten the labels, then we can group them later by skill
      const flattenLabels = categories.flat();

      // Clones the labels, so we can modify them without affecting the original
      const clonedFlattenLabels = cloneDeep(flattenLabels);

      const clusterLabels = clonedFlattenLabels
        // Filters the labels that are not clusterable (see clusterable lists)
        .filter((label: any) => isClusterable(label.skill))
        .map((label: any) => {
          if (label.skill === "clustering") {
            if (label?.data?.input_skill) {
              // Adds "Clustering" to the skill name to show clustering
              label.skill = label?.data?.input_skill + " Clustering";
            } else {
              label.skill = "clustering";
            }
          }
          return label;
        });

      // Groups labels by skill (LABEL.skill)
      const unitedLabels = groupBy(clusterLabels, "skill");
      const labels: any = [];

      for (const label in unitedLabels) {
        // Some of the skills use the "value" while others use the "name"
        if (shouldShowValue(label)) {
          labels.push(groupBy(unitedLabels[label], "value"));
        } else labels.push(groupBy(unitedLabels[label], "name"));
      }

      const labelsArray: any = [];

      // Loops through grouped labels
      for (const label in labels) {
        // Loops through the labels of each skill
        for (const name in labels[label]) {
          const phrasesArray: any = [];
          const currentLabel = labels[label][name][0];
          for (const subItem in labels[label][name]) {
            if (
              labels[label][name][subItem]?.skill === "sentiments" ||
              labels[label][name][subItem]?.skill === "emotions"
            ) {
              // Pushes span_text for sentiments & emotions only (we get that from API)
              phrasesArray.push({
                text: labels[label][name][subItem].span_text,
                items_count: 1,
              });
            } else {
              if (isClusteringLabel(labels[label][name][subItem].skill)) {
                // Loops through items inside "data" to get phrases
                labels[label][name][subItem]?.data?.items?.forEach(
                  (item: any) => {
                    // Loops item.value times through phrases(items)
                    for (let i = 0; i < item.value; i++) {
                      // Pushes the values N times with count 1 to create the same effect
                      phrasesArray.push({
                        text: item.name,
                        items_count: 1,
                      });
                    }
                  },
                );
              } else {
                // Regular skills (no clustering, sentiments or emotions)
                phrasesArray.push({
                  text: labels[label][name][subItem].value,
                  items_count: 1,
                });
              }
            }
          }

          // By default insertedText equals to name
          let insertedText = name;

          if (name === "POS" && currentLabel?.skill === "sentiments") {
            // Sentiments POS is not a good name, so we change it to Positive
            insertedText = "Positive";
          }
          if (name === "NEG" && currentLabel?.skill === "sentiments") {
            // Sentiments NEG is not a good name, so we change it to Negative
            insertedText = "Negative";
          }

          // Pushes the first LEVEL (this is what to be shown in the chart when it gets rendered)
          if (isClusteringLabel(currentLabel?.skill)) {
            labelsArray.push({
              skill: currentLabel?.skill,
              input_skill: currentLabel?.data?.input_skill,
              text: insertedText,
              span_text: insertedText,
              items_count: phrasesArray.length,
              phrases: phrasesArray,
            });
          } else {
            labelsArray.push({
              skill: currentLabel?.skill,
              text: insertedText,
              span_text: insertedText,
              items_count: labels[label][name].length,
              phrases: phrasesArray,
            });
          }
        }
      }

      // Sets the real categories count (we look at the total and not the first level)
      if (type !== "count") {
        labelsArray.forEach((label: any, index: number) => {
          if (label.phrases) {
            const newItems: any = [];
            const newGroup = groupBy(label.phrases, "text");
            newGroup &&
              Object.entries(newGroup).forEach(([key, value]) => {
                newItems.push({
                  text: key,
                  items_count: value.length,
                });
              });
            labelsArray[index].phrases = newItems;
          }
        });
      }

      return labelsArray;
    } else {
      return cloneDeep(json);
    }
  } catch (e) {
    console.debug("[formatJSONForLabels] error:", e);
    return [];
  }
}

function shouldShowValue(label: string) {
  switch (label) {
    case "sentiments":
    case "article-topics":
    case "anonymize":
    case "enhances":
    case "sentences":
    case "highlights":
      return true;
    default:
      return false;
  }
}
