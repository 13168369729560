import { useEffect } from "react";
import {
  atom,
  DefaultValue,
  selector,
  useRecoilStateLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { auth } from "../components/auth/auth";
import {
  getKeys,
  getOrganization,
  getUserDocument,
  useUserData,
} from "../components/auth/data";
import {
  defaultAccordions,
  defaultVideosAccordions,
  inputSelectionTypes,
  SkillPipelineData,
} from "../pages/PipelinePage/utils";
import contentFile from "../utils/content.json";
import {
  APIKey,
  ClusterType,
  CollectionsType,
  CubeStateType,
  DNDColumnsType,
  DocsTabsType,
  FeatureType,
  FinishType,
  IOrganization,
  SampleButtonType,
  scrollPositionsType,
  TabsValueType,
  UserData,
  UserFirebase,
} from "./interfaces";

export const AtomManager = () => {
  const [userFirebase, setUserFirebase] =
    useRecoilStateLoadable(userFirebaseAtom);
  const setUserData = useSetRecoilState(userDataAtom);
  const { value: mongoUserData, isFetching, isReady } = useUserData();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      console.debug("[onAuthStateChanged] user.uid", user?.uid);
      if (user) {
        const userFirebase = {
          uid: user?.uid,
          email: user?.email,
          displayName: user?.displayName,
        } as UserFirebase;
        setUserFirebase(userFirebase);
      } else {
      }
    });
  }, [setUserFirebase]);

  useEffect(() => {
    let unsub = () => {};
    if (
      userFirebase.state === "hasValue" &&
      !(userFirebase.contents instanceof DefaultValue)
    ) {
      // console.log("useEffect AtomManager ::", userFirebase.contents);
      unsub = mongoUserData;
    }
    return unsub;
    //eslint-disable-next-line
  }, [userFirebase, setUserData, isFetching, isReady]);

  return null;
  // return (
  //   <div className="bg-white">
  //     <div>
  //       <span className="bg-red">userFirebase:</span>{" "}
  //       {JSON.stringify(userFirebase, null, 2)}
  //     </div>
  //     <div>
  //       <span className="bg-red">userDataAtom:</span>{" "}
  //       {JSON.stringify(userData, null, 2)}
  //     </div>
  //   </div>
  // );
};

const useResetUser = () => {
  const resetUserFirebaseAtom = useResetRecoilState(userFirebaseAtom);
  const resetUserDataAtom = useResetRecoilState(userDataAtom);

  const resetUser = () => {
    resetUserFirebaseAtom();
    resetUserDataAtom();
  };
  return { resetUser };
};

const logAtoms = ({ node, onSet }: any) => {
  onSet((newValue: any) => {
    console.debug(`[${node.key}] newValue:`, newValue);
  });
};

const loadUserData = async (userFirebase: UserFirebase) => {
  return new Promise<UserData>((resolve) => {
    getUserDocument(userFirebase.uid).then((docSnap) => {
      resolve(docSnap);
    });
  });
};

const loadOrganization = async (userData: UserData) => {
  return new Promise<IOrganization>((resolve) => {
    getOrganization(userData?.org_ref).then((docSnap) => {
      resolve(docSnap);
    });
  });
};

const loadOrganizationKeys = async (userData: UserData): Promise<APIKey[]> => {
  const keys = await getKeys(userData?.org_ref);
  console.debug("[loadOrganizationKeys] keys", keys);
  return keys;
};

const userFirebaseDefault = async () => {
  return new Promise<UserFirebase>(() => {});
};

const userFirebaseAtom = atom<UserFirebase>({
  key: "userFirebaseAtom",
  default: userFirebaseDefault(),
});

const userDataSelector = selector<UserData>({
  key: "userDataSelector",
  get: async ({ get }) => {
    const userFirebase = get(userFirebaseAtom);
    const userData = await loadUserData(userFirebase);
    console.debug("[userDataSelector] userData:", userData);
    return userData;
  },
});

const userDataAtom = atom<UserData>({
  key: "userDataAtom",
  default: userDataSelector,
  effects: [logAtoms],
});

const userVisibleKeyAtom = atom<any>({
  key: "userVisibleKeyAtom",
  default: null,
});

const organizationSelector = selector<IOrganization>({
  key: "organizationSelector",
  get: async ({ get }) => {
    const userData = get(userDataAtom);
    const organization = await loadOrganization(userData);
    console.debug("[organizationSelector] organization:", organization);
    return organization;
  },
});

const sampleButtonsAtom = atom<Array<SampleButtonType>>({
  key: "sampleButtonsAtom",
  default: [],
});

const organizationKeysAtom = atom<Array<APIKey>>({
  key: "organizationKeysAtom",
  default: [],
});

const pipelineSampleAtom = atom<string>({
  key: "pipelineSampleAtom",
  default: "",
});

const organizationKeysSelector = selector<Array<APIKey>>({
  key: "organizationKeysSelector",
  get: async ({ get }) => {
    const userData = get(userDataAtom);
    const keys = await loadOrganizationKeys(userData);
    console.debug("[organizationKeysSelector] organization", keys);
    return keys;
  },
  set: ({ set }, newValue) => set(organizationKeysAtom, newValue),
});

const featuresAtom = atom({
  key: "featuresAtom",
  default: [
    {
      name: "signup-form",
      active: false,
    },
    {
      name: "mslg",
      active: false,
    },
  ] as Array<FeatureType>,
});

const activeFeaturesSelector = selector<Array<FeatureType>>({
  key: "activeFeatures",
  get: ({ get }) => {
    return get(featuresAtom).filter((feature) => feature.active);
  },
});

const tabsValueAtom = atom<TabsValueType>({
  key: "tabsValueAtom",
  default: "table",
});

const showSkillLibraryAtom = atom<boolean>({
  key: "showSkillLibraryAtom",
  default: true,
});

const savedParamsAtom = atom<string>({
  key: "savedParamsAtom",
  default: "",
});

const anonymousLoginAtom = atom<boolean>({
  key: "anonymousLoginAtom",
  default: false,
});

const loginFormAtom = atom<boolean>({
  key: "loginFormAtom",
  default: false,
});

const anonymousePreNavigationAtom = atom<boolean>({
  key: "anonymousePreNavigationAtom",
  default: false,
});

const registrationEmailAtom = atom<string>({
  key: "registrationEmailAtom",
  default: "",
});

const forgotPasswordTokenAtom = atom<string>({
  key: "forgotPasswordTokenAtom",
  default: "",
});

const registrationTokenAtom = atom<string>({
  key: "registrationTokenAtom",
  default: "",
});

const savedPathAtom = atom<string>({
  key: "savedPathAtom",
  default: "",
});

const mobileMenuOpenAtom = atom<boolean>({
  key: "mobileMenuOpenAtom",
  default: false,
});

const verificationFailedAtom = atom<boolean>({
  key: "verificationFailedAtom",
  default: false,
});

const contentFileAtom = atom<any>({
  key: "contentFileAtom",
  default: contentFile,
});

const previewContentFileAtom = atom<any>({
  key: "previewContentFileAtom",
  default: contentFile,
});

// const saveToLocalStorage = ({ node, onSet }: any) => {
//   onSet((newValue: any) => {
//     if (typeof newValue === "object") {
//       reactLocalStorage.setObject(node.key, newValue);
//     } else {
//       reactLocalStorage.set(node.key, newValue);
//     }
//   });
// };

const textAreaAtom = atom<string>({
  key: "textAreaAtom",
  default: "",
});

const textAreaEmptyStateAtom = atom<boolean>({
  key: "textAreaEmptyStateAtom",
  default: true,
});

const inputResponseAtom = atom<boolean>({
  key: "inputResponseAtom",
  default: false,
});

const reRenderRequestAtom = atom<number>({
  key: "reRenderRequestAtom",
  default: 0,
});

const pipelineErrorsAtom = atom<Array<string>>({
  key: "hasPipelineErrorsAtom",
  default: [],
});

const pipelineErrorSelector = selector<Array<string>>({
  key: "pipelineErrorSelector",
  get: ({ get }) => {
    const textArea = get(textAreaAtom);
    let errors = [];
    if (!textArea?.length) {
      errors.push("Please add input text");
    }
    // if (!pipelineDNDColumns.pipeline.items?.length){errors.push("Please add skills to pipeline")}
    return errors;
  },
});

const inputTypeAtom = atom<any>({
  key: "inputTypeAtom",
  default: inputSelectionTypes["auto-detect"],
});

const mainVideoAtom = atom<any>({
  key: "mainVideoAtom",
  default: {
    link: null,
    title: null,
  },
});

const curApiAtom = atom<any>({
  key: "curApiAtom",
  default: contentFile?.documentation[0],
});

const accordionOpenedAtom = atom<any>({
  key: "accordionOpenedAtom",
  default: defaultAccordions(),
});

const videoCategoriesAccordionOpenedAtom = atom<any>({
  key: "videoCategoriesAccordionOpenedAtom",
  default: defaultVideosAccordions(),
});

const refetchingClusterAtom = atom<any>({
  key: "refetchingClusterAtom",
  default: "",
});

const curApiItemAtom = atom<any>({
  key: "curApiItemAtom",
  default: contentFile?.documentation[0].items[0],
});
const shadowSignupAtom = atom<boolean>({
  key: "shadowSignupAtom",
  default: false,
});

const curApiTabAtom = atom<DocsTabsType>({
  key: "curApiTabAtom",
  default: "Code Generator",
});

const shouldPlayVideoAtom = atom<boolean>({
  key: "shouldPlayVideoAtom",
  default: true,
});

const createdLocalCollectionAtom = atom<boolean>({
  key: "createdLocalCollectionAtom",
  default: false,
});

const loaderAtom = atom<boolean>({
  key: "loaderAtom",
  default: false,
});

export const analyticsItemsErrorAtom = atom<string>({
  key: "analyticsItemsErrorAtom",
  default: "",
});

export type ClusterAtomType = {
  clusterTitle: string;
  clusterType: ClusterType;
};
const clusterAtom = atom<ClusterAtomType>({
  key: "clusterAtom",
  default: { clusterTitle: "Airline Inquiries", clusterType: "DEMO" },
});

const detectedCheckedAtom = atom<boolean>({
  key: "detectedCheckedAtom",
  default: true,
});

const pipelineDNDColumnsAtom = atom<DNDColumnsType>({
  key: "pipelineDNDColumnsAtom",
  default: {
    pipeline: {
      name: "Skill Pipeline",
      items: SkillPipelineData,
    },
    library: {
      name: "Skill Library",
      items: contentFile.skills,
    },
  },
});

const pipelineDNDColumnsOriginalAtom = atom<DNDColumnsType>({
  key: "pipelineDNDColumnsOriginalAtom",
  default: {
    pipeline: {
      name: "Skill Pipeline",
      items: SkillPipelineData,
    },
    library: {
      name: "Skill Library",
      items: contentFile.skills,
    },
  },
});

const previewModeAtom = atom<boolean>({
  key: "previewModeAtom",
  default: false,
});

const openPopoverAtom = atom<string>({
  key: "openPopoverAtom",
  default: "",
});

const cubeStateAtom = atom<CubeStateType>({
  key: "cubeStateAtom",
  default: "WAITING",
});

const cubeFileAtom = atom<string>({
  key: "cubeFileAtom",
  default: "",
});

const summaryOriginsAtom = atom<boolean>({
  key: "summaryOriginsAtom",
  default: false,
});

const docsScrollPositionsAtom = atom<Array<scrollPositionsType>>({
  key: "docsSrollPositionsAtom",
  default: [],
});

const currDocsScrollPositionAtom = atom<scrollPositionsType>({
  key: "currDocsScrollPositionAtom",
  default: { name: "", position: 0 },
});

const itemsTextAreaAtom = atom<string>({
  key: "itemsTextAreaAtom",
  default: "",
});

const searchCollectionAtom = atom<string>({
  key: "searchCollectionAtom",
  default: "",
});

const stopRefetchingAtom = atom<boolean>({
  key: "stopRefetchingAtom",
  default: true,
});

// const organizationKeysSelectorFamily = selectorFamily({
//   key: "organizationKeysSelectorFamily",
//   get:
//     (token_id: string) =>
//     ({ get }) => {
//       const keys = get(organizationKeysSelector);
//       return keys.find((key) => key.token_id === token_id);
//     },
//   set:
//     (token_id: string) =>
//     ({ set }, newValue) => {
//       set(organizationKeysSelector, (prevState) => {
//         const keyIndex = prevState.findIndex(
//           (key) => key.token_id === token_id,
//         );
//         if (keyIndex >= 0) {
//           return replaceItemAtIndex(prevState, keyIndex, {
//             ...(newValue as APIKey),
//           });
//         } else {
//         }
//         return [...prevState];
//       });
//     },
// });
//
// function replaceItemAtIndex(arr: any[], index: number, newValue: any) {
//   return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
// }

const currentClusterDataAtom = atom<{
  clusterName: string;
  clusterCount: number;
}>({
  key: "currentClusterDataAtom",
  default: {
    clusterName: "",
    clusterCount: 0,
  },
});

const collectionsDataAtom = atom<string[]>({
  key: "collectionsDataAtom",
  default: [],
});

const currCollectionsTabAtom = atom<CollectionsType>({
  key: "currCollectionsTabAtom",
  default: "SAMPLE_COLLECTIONS",
});

const generatingCollectionAtom = atom<string>({
  key: "generatingCollectionAtom",
  default: "",
});

const lastClusteringData = atom<any>({
  key: "lastClusteringData",
  default: { clusterName: "", clusterJSON: {} },
});

const experimentModalAtom = atom<boolean>({
  key: "experimentModalAtom",
  default: false,
});

const shouldShowExperimentAtom = atom<boolean>({
  key: "shouldShowExperimentAtom",
  default: false,
});

const popupShouldStayAtom = atom<boolean>({
  key: "popupShouldStayAtom",
  default: false,
});

const fileContentAtom = atom<string>({
  key: "fileContentAtom",
  default: "",
});

const experimentFinishTypeAtom = atom<FinishType>({
  key: "experimentFinishTypeAtom",
  default: FinishType.RUN_PIPELINE,
});

const savedCopyCodeAtom = atom<string>({
  key: "savedCopyCodeAtom",
  default: "",
});

export {
  userFirebaseAtom,
  userDataAtom,
  organizationSelector,
  organizationKeysSelector,
  pipelineSampleAtom,
  sampleButtonsAtom,
  featuresAtom,
  activeFeaturesSelector,
  savedParamsAtom,
  tabsValueAtom,
  showSkillLibraryAtom,
  anonymousLoginAtom,
  anonymousePreNavigationAtom,
  registrationEmailAtom,
  registrationTokenAtom,
  useResetUser,
  forgotPasswordTokenAtom,
  savedPathAtom,
  userVisibleKeyAtom,
  verificationFailedAtom,
  contentFileAtom,
  mainVideoAtom,
  curApiItemAtom,
  curApiAtom,
  curApiTabAtom,
  textAreaAtom,
  inputTypeAtom,
  reRenderRequestAtom,
  pipelineErrorSelector,
  openPopoverAtom,
  pipelineDNDColumnsAtom,
  cubeStateAtom,
  shouldPlayVideoAtom,
  accordionOpenedAtom,
  previewContentFileAtom,
  previewModeAtom,
  pipelineDNDColumnsOriginalAtom,
  mobileMenuOpenAtom,
  cubeFileAtom,
  pipelineErrorsAtom,
  inputResponseAtom,
  textAreaEmptyStateAtom,
  videoCategoriesAccordionOpenedAtom,
  summaryOriginsAtom,
  loaderAtom,
  clusterAtom,
  loginFormAtom,
  docsScrollPositionsAtom,
  currDocsScrollPositionAtom,
  shadowSignupAtom,
  currentClusterDataAtom,
  itemsTextAreaAtom,
  searchCollectionAtom,
  stopRefetchingAtom,
  createdLocalCollectionAtom,
  collectionsDataAtom,
  currCollectionsTabAtom,
  generatingCollectionAtom,
  detectedCheckedAtom,
  lastClusteringData,
  refetchingClusterAtom,
  experimentModalAtom,
  popupShouldStayAtom,
  shouldShowExperimentAtom,
  fileContentAtom,
  experimentFinishTypeAtom,
  savedCopyCodeAtom,
};
