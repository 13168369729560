import { cloneDeep, update } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import {
  apiResponseAtom,
  clusteringStatelessAtom,
  pipelineSkillsAtom,
} from "../pages/pipeline/pipeline-atoms";
import {
  getSkillIcon,
  isSkillAllowed,
  stripHTML,
} from "../pages/PipelinePage/utils";
import { inputTypeAtom, textAreaAtom } from "./atoms";
import { useEventLogger, UserEvent } from "./event_logger";
import { SkillsType } from "./interfaces";
import { multiSummaryText } from "./multi-summary-text";
import { useStudioData } from "./use-studio-data";
import { useSummaryOrigin } from "./use-summary-origin";

export default function usePipelineSkill(skill: SkillsType) {
  const { data } = useStudioData();
  const { search } = useLocation();
  const { isSummary } = useSummaryOrigin();
  const navigate = useNavigate();
  const [pipelineSkills, setPipelineSkills] =
    useRecoilState(pipelineSkillsAtom);
  const setOpenedPopover = useSetRecoilState(clusteringStatelessAtom);
  const [apiResponse, setApiResponse] = useRecoilState(apiResponseAtom);
  const [textArea, setTextArea] = useRecoilState(textAreaAtom);
  const { userEventLogger } = useEventLogger();
  const inputType = useRecoilValue(inputTypeAtom);

  function deleteOutputSkill(outputIndex: number) {
    const clonedAPI = cloneDeep(apiResponse);
    const currentOutputLabels = [...apiResponse.output[outputIndex]?.labels];

    const { summaryIndex } = isSummary();

    if (currentOutputLabels) {
      // Filters the output labels to remove the skills
      const filteredLabels = currentOutputLabels.filter((label: any) => {
        if (skill?.name === "Summarize") {
          return label?.skill !== "origin";
        } else if (skill?.name === "Clustering") {
          return label?.skill !== "clustering";
        }
        return label?.skill !== skill?.value;
      });

      // Sets the new labels
      clonedAPI.output[outputIndex].labels = filteredLabels;

      // Checks whether the label array are empty and removes the output if it is
      if (!filteredLabels.length) {
        clonedAPI?.output?.splice(outputIndex, 1);
      }

      // Navigates to pipeline-skills if there's no output (to prevent empty output panel)
      if (!clonedAPI?.output.length) {
        navigate("/pipeline-skills" + search);
      }

      // Reformats the textArea to remove all html tags
      if (outputIndex === summaryIndex && skill?.name === "Summarize") {
        setTextArea(stripHTML(textArea));
      }
    }
    setApiResponse(clonedAPI);
    deleteSkill();
  }

  function deleteSkill() {
    const newPipelineSkills = [...pipelineSkills.steps];
    newPipelineSkills?.splice(
      newPipelineSkills?.findIndex((iterator: any) => {
        return iterator.id === skill.id;
      }),
      1,
    );
    setPipelineSkills({ steps: newPipelineSkills });
    userEventLogger(UserEvent.SKILL_REMOVE, { value: skill.name });
  }

  function addSkill() {
    const newPipelineSkills = [...pipelineSkills.steps];
    const mutatedSkill = { ...skill };
    userEventLogger(UserEvent.SKILL_ADD, { value: skill.name });
    mutatedSkill.id = uuidv4();

    if (skill.type === 6 && textArea === "") {
      setTextArea(multiSummaryText);
    }
    newPipelineSkills?.push(mutatedSkill);
    openClusteringPopover(mutatedSkill);
    setPipelineSkills({ steps: newPipelineSkills });
  }

  function iconSkill(isActive: boolean, type: "pipeline" | "library") {
    return getSkillIcon(
      skill?.name,
      "LIBRARY",
      isActive,
      data,
      skillMismatch() && type === "pipeline",
    );
  }

  function skillMismatch() {
    return isSkillAllowed(skill, inputType);
  }
  function updateParams(params: any) {
    const newPipelineSkills = cloneDeep(pipelineSkills);
    const updatedItems = newPipelineSkills.steps.map((item: any) => {
      //console.log("Skill to update params to :: ", skill);
      if (item.id === skill.id) {
        item.params = params;
      }
      return item;
    });

    const newObj = update(newPipelineSkills, "steps", () => {
      return updatedItems;
    });

    setPipelineSkills(newObj);
  }

  function openClusteringPopover(skill: any) {
    if (skill?.name === "Clustering") {
      setOpenedPopover(skill?.id);
    }
  }

  return {
    addSkill,
    deleteSkill,
    iconSkill,
    updateParams,
    deleteOutputSkill,
    skillMismatch,
  };
}
