const monacoTheme = {
  base: "vs-dark",
  inherit: true,
  rules: [
    {
      foreground: "cf9351",
      token: "punctuation.definition.delayed.unison",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.list.begin.unison",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.list.end.unison",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.ability.begin.unison",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.ability.end.unison",
    },
    {
      foreground: "cf9351",
      token: "punctuation.operator.assignment.as.unison",
    },
    {
      foreground: "cf9351",
      token: "punctuation.separator.pipe.unison",
    },
    {
      foreground: "cf9351",
      token: "punctuation.separator.delimiter.unison",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.hash.unison",
    },
    {
      foreground: "c191d7",
      token: "variable.other.generic-type.haskell",
    },
    {
      foreground: "58b2b7",
      token: "storage.type.haskell",
    },
    {
      foreground: "cf9351",
      token: "support.variable.magic.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.separator.period.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.separator.element.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.parenthesis.begin.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.parenthesis.end.python",
    },
    {
      foreground: "e5c07b",
      token: "variable.parameter.function.language.special.self.python",
    },
    {
      foreground: "d4d4d4",
      token: "storage.modifier.lifetime.rust",
    },
    {
      foreground: "b1be65",
      token: "support.function.std.rust",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.lifetime.rust",
    },
    {
      foreground: "cf9351",
      token: "variable.language.rust",
    },
    {
      foreground: "c191d7",
      token: "support.constant.edge",
    },
    {
      foreground: "cf9351",
      token: "constant.other.character-class.regexp",
    },
    {
      foreground: "58b2b7",
      token: "keyword.operator.quantifier.regexp",
    },
    {
      foreground: "f57954",
      token: "punctuation.definition.string.begin",
    },
    {
      foreground: "f57954",
      token: "punctuation.definition.string.end",
    },
    {
      foreground: "d4d4d4",
      token: "variable.parameter.function",
    },
    {
      foreground: "ffffff",
      token: "comment markup.link",
    },
    {
      foreground: "e5c07b",
      token: "markup.changed.diff",
    },
    {
      foreground: "b1be65",
      token: "meta.diff.header.from-file",
    },
    {
      foreground: "b1be65",
      token: "meta.diff.header.to-file",
    },
    {
      foreground: "b1be65",
      token: "punctuation.definition.from-file.diff",
    },
    {
      foreground: "b1be65",
      token: "punctuation.definition.to-file.diff",
    },
    {
      foreground: "f57954",
      token: "markup.inserted.diff",
    },
    {
      foreground: "cf9351",
      token: "markup.deleted.diff",
    },
    {
      foreground: "cf9351",
      token: "meta.function.c",
    },
    {
      foreground: "cf9351",
      token: "meta.function.cpp",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.block.begin.bracket.curly.cpp",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.block.end.bracket.curly.cpp",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.terminator.statement.c",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.block.begin.bracket.curly.c",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.block.end.bracket.curly.c",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.parens.begin.bracket.round.c",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.parens.end.bracket.round.c",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.parameters.begin.bracket.round.c",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.parameters.end.bracket.round.c",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.separator.key-value",
    },
    {
      foreground: "b1be65",
      token: "keyword.operator.expression.import",
    },
    {
      foreground: "e5c07b",
      token: "support.constant.math",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.property.math",
    },
    {
      foreground: "e5c07b",
      token: "variable.other.constant",
    },
    {
      foreground: "e5c07b",
      token: "storage.type.annotation.java",
    },
    {
      foreground: "e5c07b",
      token: "storage.type.object.array.java",
    },
    {
      foreground: "cf9351",
      token: "source.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.block.begin.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.block.end.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.method-parameters.begin.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.method-parameters.end.java",
    },
    {
      foreground: "d4d4d4",
      token: "meta.method.identifier.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.method.begin.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.method.end.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.terminator.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.class.begin.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.class.end.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.inner-class.begin.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.inner-class.end.java",
    },
    {
      foreground: "d4d4d4",
      token: "meta.method-call.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.class.begin.bracket.curly.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.class.end.bracket.curly.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.method.begin.bracket.curly.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.method.end.bracket.curly.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.separator.period.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.bracket.angle.java",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.annotation.java",
    },
    {
      foreground: "d4d4d4",
      token: "meta.method.body.java",
    },
    {
      foreground: "b1be65",
      token: "meta.method.java",
    },
    {
      foreground: "e5c07b",
      token: "storage.modifier.import.java",
    },
    {
      foreground: "e5c07b",
      token: "storage.type.java",
    },
    {
      foreground: "e5c07b",
      token: "storage.type.generic.java",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.instanceof.java",
    },
    {
      foreground: "cf9351",
      token: "meta.definition.variable.name.java",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.logical",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.bitwise",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.channel",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.property-value.scss",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.property-value.css",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.css",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.scss",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.less",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.color.w3c-standard-color-name.css",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.color.w3c-standard-color-name.scss",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.separator.list.comma.css",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.color.w3c-standard-color-name.css",
    },
    {
      foreground: "56b6c2",
      token: "support.type.vendored.property-name.css",
    },
    {
      foreground: "e5c07b",
      token: "support.module.node",
    },
    {
      foreground: "e5c07b",
      token: "support.type.object.module",
    },
    {
      foreground: "e5c07b",
      token: "support.module.node",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.type.module",
    },
    {
      foreground: "cf9351",
      token: "variable.other.readwrite",
    },
    {
      foreground: "cf9351",
      token: "meta.object-literal.key",
    },
    {
      foreground: "cf9351",
      token: "support.variable.property",
    },
    {
      foreground: "cf9351",
      token: "support.variable.object.process",
    },
    {
      foreground: "cf9351",
      token: "support.variable.object.node",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.json",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.expression.instanceof",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.new",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.ternary",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.optional",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.expression.keyof",
    },
    {
      foreground: "cf9351",
      token: "support.type.object.console",
    },
    {
      foreground: "58b2b7",
      token: "support.variable.property.process",
    },
    {
      foreground: "b1be65",
      token: "entity.name.function",
    },
    {
      foreground: "b1be65",
      token: "support.function.console",
    },
    {
      foreground: "d4d4d4",
      token: "keyword.operator.misc.rust",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.sigil.rust",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.delete",
    },
    {
      foreground: "56b6c2",
      token: "support.type.object.dom",
    },
    {
      foreground: "cf9351",
      token: "support.variable.dom",
    },
    {
      foreground: "cf9351",
      token: "support.variable.property.dom",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.arithmetic",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.comparison",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.decrement",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.increment",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.relational",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.assignment.c",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.comparison.c",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.c",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.increment.c",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.decrement.c",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.bitwise.shift.c",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.assignment.cpp",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.comparison.cpp",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.cpp",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.increment.cpp",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.decrement.cpp",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.bitwise.shift.cpp",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.separator.delimiter",
    },
    {
      foreground: "c191d7",
      token: "punctuation.separator.c",
    },
    {
      foreground: "c191d7",
      token: "punctuation.separator.cpp",
    },
    {
      foreground: "56b6c2",
      token: "support.type.posix-reserved.c",
    },
    {
      foreground: "56b6c2",
      token: "support.type.posix-reserved.cpp",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.sizeof.c",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.sizeof.cpp",
    },
    {
      foreground: "58b2b7",
      token: "variable.parameter.function.language.python",
    },
    {
      foreground: "56b6c2",
      token: "support.type.python",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.logical.python",
    },
    {
      foreground: "58b2b7",
      token: "variable.parameter.function.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.arguments.begin.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.arguments.end.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.separator.arguments.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.list.begin.python",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.list.end.python",
    },
    {
      foreground: "b1be65",
      token: "meta.function-call.generic.python",
    },
    {
      foreground: "58b2b7",
      token: "constant.character.format.placeholder.other.python",
    },
    {
      foreground: "d4d4d4",
      token: "keyword.operator",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.assignment.compound",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.assignment.compound.js",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.assignment.compound.ts",
    },
    {
      foreground: "c191d7",
      token: "keyword",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.namespace",
    },
    {
      foreground: "cf9351",
      token: "variable",
    },
    {
      foreground: "d4d4d4",
      token: "variable.c",
    },
    {
      foreground: "e5c07b",
      token: "variable.language",
    },
    {
      foreground: "d4d4d4",
      token: "token.variable.parameter.java",
    },
    {
      foreground: "e5c07b",
      token: "import.storage.java",
    },
    {
      foreground: "c191d7",
      token: "token.package.keyword",
    },
    {
      foreground: "d4d4d4",
      token: "token.package",
    },
    {
      foreground: "b1be65",
      token: "entity.name.function",
    },
    {
      foreground: "b1be65",
      token: "meta.require",
    },
    {
      foreground: "b1be65",
      token: "support.function.any-method",
    },
    {
      foreground: "b1be65",
      token: "variable.function",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.type.namespace",
    },
    {
      foreground: "e5c07b",
      token: "support.class",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.type.class",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.class.identifier.namespace.type",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.class",
    },
    {
      foreground: "e5c07b",
      token: "variable.other.class.js",
    },
    {
      foreground: "e5c07b",
      token: "variable.other.class.ts",
    },
    {
      foreground: "cf9351",
      token: "variable.other.class.php",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.type",
    },
    {
      foreground: "c191d7",
      token: "keyword.control",
    },
    {
      foreground: "58b2b7",
      token: "control.elements",
    },
    {
      foreground: "58b2b7",
      token: "keyword.operator.less",
    },
    {
      foreground: "b1be65",
      token: "keyword.other.special-method",
    },
    {
      foreground: "c191d7",
      token: "storage",
    },
    {
      foreground: "c191d7",
      token: "token.storage",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.expression.delete",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.expression.in",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.expression.of",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.expression.instanceof",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.new",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.expression.typeof",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.expression.void",
    },
    {
      foreground: "e5c07b",
      token: "token.storage.type.java",
    },
    {
      foreground: "56b6c2",
      token: "support.function",
    },
    {
      foreground: "d4d4d4",
      token: "support.type.property-name",
    },
    {
      foreground: "d4d4d4",
      token: "support.constant.property-value",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.font-name",
    },
    {
      foreground: "d4d4d4",
      token: "meta.tag",
    },
    {
      foreground: "f57954",
      token: "string",
    },
    {
      foreground: "e5c07b",
      token: "entity.other.inherited-class",
    },
    {
      foreground: "56b6c2",
      token: "constant.other.symbol",
    },
    {
      foreground: "58b2b7",
      token: "constant.numeric",
    },
    {
      foreground: "58b2b7",
      token: "constant",
    },
    {
      foreground: "58b2b7",
      token: "punctuation.definition.constant",
    },
    {
      foreground: "cf9351",
      token: "entity.name.tag",
    },
    {
      foreground: "58b2b7",
      token: "entity.other.attribute-name",
    },
    {
      foreground: "b1be65",
      fontStyle: "normal",
      token: "entity.other.attribute-name.id",
    },
    {
      foreground: "58b2b7",
      fontStyle: "normal",
      token: "entity.other.attribute-name.class.css",
    },
    {
      foreground: "c191d7",
      token: "meta.selector",
    },
    {
      foreground: "cf9351",
      token: "markup.heading",
    },
    {
      foreground: "b1be65",
      token: "markup.heading punctuation.definition.heading",
    },
    {
      foreground: "b1be65",
      token: "entity.name.section",
    },
    {
      foreground: "cf9351",
      token: "keyword.other.unit",
    },
    {
      foreground: "58b2b7",
      token: "markup.bold",
    },
    {
      foreground: "58b2b7",
      token: "todo.bold",
    },
    {
      foreground: "e5c07b",
      token: "punctuation.definition.bold",
    },
    {
      foreground: "c191d7",
      token: "markup.italic",
    },
    {
      foreground: "c191d7",
      token: "punctuation.definition.italic",
    },
    {
      foreground: "c191d7",
      token: "todo.emphasis",
    },
    {
      foreground: "c191d7",
      token: "emphasis md",
    },
    {
      foreground: "cf9351",
      token: "entity.name.section.markdown",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.heading.markdown",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.list.begin.markdown",
    },
    {
      foreground: "d4d4d4",
      token: "markup.heading.setext",
    },
    {
      foreground: "58b2b7",
      token: "punctuation.definition.bold.markdown",
    },
    {
      foreground: "f57954",
      token: "markup.inline.raw.markdown",
    },
    {
      foreground: "f57954",
      token: "markup.inline.raw.string.markdown",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.list.markdown",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.string.begin.markdown",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.string.end.markdown",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.metadata.markdown",
    },
    {
      foreground: "cf9351",
      token: "beginning.punctuation.definition.list.markdown",
    },
    {
      foreground: "cf9351",
      token: "punctuation.definition.metadata.markdown",
    },
    {
      foreground: "c191d7",
      token: "markup.underline.link.markdown",
    },
    {
      foreground: "c191d7",
      token: "markup.underline.link.image.markdown",
    },
    {
      foreground: "b1be65",
      token: "string.other.link.title.markdown",
    },
    {
      foreground: "b1be65",
      token: "string.other.link.description.markdown",
    },
    {
      foreground: "56b6c2",
      token: "string.regexp",
    },
    {
      foreground: "56b6c2",
      token: "constant.character.escape",
    },
    {
      foreground: "cf9351",
      token: "punctuation.section.embedded",
    },
    {
      foreground: "cf9351",
      token: "variable.interpolation",
    },
    {
      foreground: "c191d7",
      token: "punctuation.section.embedded.begin",
    },
    {
      foreground: "c191d7",
      token: "punctuation.section.embedded.end",
    },
    {
      foreground: "ffffff",
      token: "invalid.illegal",
    },
    {
      foreground: "d4d4d4",
      token: "invalid.illegal.bad-ampersand.html",
    },
    {
      foreground: "ffffff",
      token: "invalid.broken",
    },
    {
      foreground: "ffffff",
      token: "invalid.deprecated",
    },
    {
      foreground: "ffffff",
      token: "invalid.unimplemented",
    },
    {
      foreground: "cf9351",
      token: "source.json meta.structure.dictionary.json > string.quoted.json",
    },
    {
      foreground: "cf9351",
      token:
        "source.json meta.structure.dictionary.json > string.quoted.json > punctuation.string",
    },
    {
      foreground: "f57954",
      token:
        "source.json meta.structure.dictionary.json > value.json > string.quoted.json",
    },
    {
      foreground: "f57954",
      token:
        "source.json meta.structure.array.json > value.json > string.quoted.json",
    },
    {
      foreground: "f57954",
      token:
        "source.json meta.structure.dictionary.json > value.json > string.quoted.json > punctuation",
    },
    {
      foreground: "f57954",
      token:
        "source.json meta.structure.array.json > value.json > string.quoted.json > punctuation",
    },
    {
      foreground: "56b6c2",
      token:
        "source.json meta.structure.dictionary.json > constant.language.json",
    },
    {
      foreground: "56b6c2",
      token: "source.json meta.structure.array.json > constant.language.json",
    },
    {
      foreground: "cf9351",
      token: "support.type.property-name.json",
    },
    {
      foreground: "cf9351",
      token: "support.type.property-name.json punctuation",
    },
    {
      foreground: "c191d7",
      token:
        "text.html.laravel-blade source.php.embedded.line.html entity.name.tag.laravel-blade",
    },
    {
      foreground: "c191d7",
      token:
        "text.html.laravel-blade source.php.embedded.line.html support.constant.laravel-blade",
    },
    {
      foreground: "e5c07b",
      token: "support.other.namespace.use.php",
    },
    {
      foreground: "e5c07b",
      token: "support.other.namespace.use-as.php",
    },
    {
      foreground: "e5c07b",
      token: "support.other.namespace.php",
    },
    {
      foreground: "e5c07b",
      token: "entity.other.alias.php",
    },
    {
      foreground: "e5c07b",
      token: "meta.interface.php",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.error-control.php",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.type.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.array.begin.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.array.end.php",
    },
    {
      foreground: "f44747",
      token: "invalid.illegal.non-null-typehinted.php",
    },
    {
      foreground: "e5c07b",
      token: "storage.type.php",
    },
    {
      foreground: "e5c07b",
      token: "meta.other.type.phpdoc.php",
    },
    {
      foreground: "e5c07b",
      token: "keyword.other.type.php",
    },
    {
      foreground: "e5c07b",
      token: "keyword.other.array.phpdoc.php",
    },
    {
      foreground: "b1be65",
      token: "meta.function-call.php",
    },
    {
      foreground: "b1be65",
      token: "meta.function-call.object.php",
    },
    {
      foreground: "b1be65",
      token: "meta.function-call.static.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.parameters.begin.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.parameters.end.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.separator.delimiter.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.scope.begin.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.section.scope.end.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.terminator.expression.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.arguments.begin.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.arguments.end.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.storage-type.begin.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.storage-type.end.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.array.begin.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.array.end.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.begin.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.end.bracket.round.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.begin.bracket.curly.php",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.end.bracket.curly.php",
    },
    {
      foreground: "d4d4d4",
      token:
        "punctuation.definition.section.switch-block.end.bracket.curly.php",
    },
    {
      foreground: "d4d4d4",
      token:
        "punctuation.definition.section.switch-block.start.bracket.curly.php",
    },
    {
      foreground: "d4d4d4",
      token:
        "punctuation.definition.section.switch-block.begin.bracket.curly.php",
    },
    {
      foreground: "d4d4d4",
      token:
        "punctuation.definition.section.switch-block.end.bracket.curly.php",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.core.rust",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.ext.php",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.std.php",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.core.php",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.parser-token.php",
    },
    {
      foreground: "b1be65",
      token: "entity.name.goto-label.php",
    },
    {
      foreground: "b1be65",
      token: "support.other.php",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.logical.php",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.bitwise.php",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.arithmetic.php",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.regexp.php",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.comparison.php",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.heredoc.php",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.nowdoc.php",
    },
    {
      foreground: "b1be65",
      token: "meta.function.decorator.python",
    },
    {
      foreground: "56b6c2",
      token: "support.token.decorator.python",
    },
    {
      foreground: "56b6c2",
      token: "meta.function.decorator.identifier.python",
    },
    {
      foreground: "d4d4d4",
      token: "function.parameter",
    },
    {
      foreground: "d4d4d4",
      token: "function.brace",
    },
    {
      foreground: "d4d4d4",
      token: "function.parameter.ruby",
    },
    {
      foreground: "d4d4d4",
      token: "function.parameter.cs",
    },
    {
      foreground: "56b6c2",
      token: "constant.language.symbol.ruby",
    },
    {
      foreground: "56b6c2",
      token: "rgb-value",
    },
    {
      foreground: "58b2b7",
      token: "inline-color-decoration rgb-value",
    },
    {
      foreground: "58b2b7",
      token: "less rgb-value",
    },
    {
      foreground: "cf9351",
      token: "selector.sass",
    },
    {
      foreground: "e5c07b",
      token: "support.type.primitive.ts",
    },
    {
      foreground: "e5c07b",
      token: "support.type.builtin.ts",
    },
    {
      foreground: "e5c07b",
      token: "support.type.primitive.tsx",
    },
    {
      foreground: "e5c07b",
      token: "support.type.builtin.tsx",
    },
    {
      foreground: "d4d4d4",
      token: "block.scope.end",
    },
    {
      foreground: "d4d4d4",
      token: "block.scope.begin",
    },
    {
      foreground: "e5c07b",
      token: "storage.type.cs",
    },
    {
      foreground: "cf9351",
      token: "entity.name.variable.local.cs",
    },
    {
      foreground: "b1be65",
      token: "token.info-token",
    },
    {
      foreground: "58b2b7",
      token: "token.warn-token",
    },
    {
      foreground: "f44747",
      token: "token.error-token",
    },
    {
      foreground: "c191d7",
      token: "token.debug-token",
    },
    {
      foreground: "c191d7",
      token: "punctuation.definition.template-expression.begin",
    },
    {
      foreground: "c191d7",
      token: "punctuation.definition.template-expression.end",
    },
    {
      foreground: "c191d7",
      token: "punctuation.section.embedded",
    },
    {
      foreground: "d4d4d4",
      token: "meta.template.expression",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.module",
    },
    {
      foreground: "b1be65",
      token: "support.type.type.flowtype",
    },
    {
      foreground: "e5c07b",
      token: "support.type.primitive",
    },
    {
      foreground: "cf9351",
      token: "meta.property.object",
    },
    {
      foreground: "cf9351",
      token: "variable.parameter.function.js",
    },
    {
      foreground: "f57954",
      token: "keyword.other.template.begin",
    },
    {
      foreground: "f57954",
      token: "keyword.other.template.end",
    },
    {
      foreground: "f57954",
      token: "keyword.other.substitution.begin",
    },
    {
      foreground: "f57954",
      token: "keyword.other.substitution.end",
    },
    {
      foreground: "56b6c2",
      token: "keyword.operator.assignment",
    },
    {
      foreground: "e5c07b",
      token: "keyword.operator.assignment.go",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.arithmetic.go",
    },
    {
      foreground: "c191d7",
      token: "keyword.operator.address.go",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.package.go",
    },
    {
      foreground: "56b6c2",
      token: "support.type.prelude.elm",
    },
    {
      foreground: "58b2b7",
      token: "support.constant.elm",
    },
    {
      foreground: "c191d7",
      token: "punctuation.quasi.element",
    },
    {
      foreground: "cf9351",
      token: "constant.character.entity",
    },
    {
      foreground: "56b6c2",
      token: "entity.other.attribute-name.pseudo-element",
    },
    {
      foreground: "56b6c2",
      token: "entity.other.attribute-name.pseudo-class",
    },
    {
      foreground: "e5c07b",
      token: "entity.global.clojure",
    },
    {
      foreground: "cf9351",
      token: "meta.symbol.clojure",
    },
    {
      foreground: "56b6c2",
      token: "constant.keyword.clojure",
    },
    {
      foreground: "cf9351",
      token: "meta.arguments.coffee",
    },
    {
      foreground: "cf9351",
      token: "variable.parameter.function.coffee",
    },
    {
      foreground: "f57954",
      token: "source.ini",
    },
    {
      foreground: "cf9351",
      token: "meta.scope.prerequisites.makefile",
    },
    {
      foreground: "e5c07b",
      token: "source.makefile",
    },
    {
      foreground: "e5c07b",
      token: "storage.modifier.import.groovy",
    },
    {
      foreground: "b1be65",
      token: "meta.method.groovy",
    },
    {
      foreground: "cf9351",
      token: "meta.definition.variable.name.groovy",
    },
    {
      foreground: "f57954",
      token: "meta.definition.class.inherited.classes.groovy",
    },
    {
      foreground: "e5c07b",
      token: "support.variable.semantic.hlsl",
    },
    {
      foreground: "c191d7",
      token: "support.type.texture.hlsl",
    },
    {
      foreground: "c191d7",
      token: "support.type.sampler.hlsl",
    },
    {
      foreground: "c191d7",
      token: "support.type.object.hlsl",
    },
    {
      foreground: "c191d7",
      token: "support.type.object.rw.hlsl",
    },
    {
      foreground: "c191d7",
      token: "support.type.fx.hlsl",
    },
    {
      foreground: "c191d7",
      token: "support.type.object.hlsl",
    },
    {
      foreground: "cf9351",
      token: "text.variable",
    },
    {
      foreground: "cf9351",
      token: "text.bracketed",
    },
    {
      foreground: "e5c07b",
      token: "support.type.swift",
    },
    {
      foreground: "e5c07b",
      token: "support.type.vb.asp",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.function.xi",
    },
    {
      foreground: "56b6c2",
      token: "entity.name.class.xi",
    },
    {
      foreground: "cf9351",
      token: "constant.character.character-class.regexp.xi",
    },
    {
      foreground: "c191d7",
      token: "constant.regexp.xi",
    },
    {
      foreground: "56b6c2",
      token: "keyword.control.xi",
    },
    {
      foreground: "d4d4d4",
      token: "invalid.xi",
    },
    {
      foreground: "f57954",
      token: "beginning.punctuation.definition.quote.markdown.xi",
    },
    {
      foreground: "ffffff",
      token: "beginning.punctuation.definition.list.markdown.xi",
    },
    {
      foreground: "b1be65",
      token: "constant.character.xi",
    },
    {
      foreground: "b1be65",
      token: "accent.xi",
    },
    {
      foreground: "58b2b7",
      token: "wikiword.xi",
    },
    {
      foreground: "ffffff",
      token: "constant.other.color.rgb-value.xi",
    },
    {
      foreground: "ffffff",
      token: "punctuation.definition.tag.xi",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.label.cs",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.scope-resolution.function.call",
    },
    {
      foreground: "e5c07b",
      token: "entity.name.scope-resolution.function.definition",
    },
    {
      foreground: "cf9351",
      token: "entity.name.label.cs",
    },
    {
      foreground: "cf9351",
      token: "markup.heading.setext.1.markdown",
    },
    {
      foreground: "cf9351",
      token: "markup.heading.setext.2.markdown",
    },
    {
      foreground: "d4d4d4",
      token: "meta.brace.square",
    },
    {
      foreground: "ffffff",
      fontStyle: "italic",
      token: "comment",
    },
    {
      foreground: "ffffff",
      fontStyle: "italic",
      token: "punctuation.definition.comment",
    },
    {
      foreground: "ffffff",
      token: "markup.quote.markdown",
    },
    {
      foreground: "d4d4d4",
      token: "punctuation.definition.block.sequence.item.yaml",
    },
    {
      foreground: "56b6c2",
      token: "constant.language.symbol.elixir",
    },
    {
      fontStyle: "italic",
      token: "entity.other.attribute-name.js",
    },
    {
      fontStyle: "italic",
      token: "entity.other.attribute-name.ts",
    },
    {
      fontStyle: "italic",
      token: "entity.other.attribute-name.jsx",
    },
    {
      fontStyle: "italic",
      token: "entity.other.attribute-name.tsx",
    },
    {
      fontStyle: "italic",
      token: "variable.parameter",
    },
    {
      fontStyle: "italic",
      token: "variable.language.super",
    },
    {
      fontStyle: "italic",
      token: "comment.line.double-slash",
    },
    {
      fontStyle: "italic",
      token: "comment.block.documentation",
    },
    {
      fontStyle: "italic",
      token: "keyword.control.import.python",
    },
    {
      fontStyle: "italic",
      token: "keyword.control.flow.python",
    },
    {
      fontStyle: "italic",
      token: "markup.italic.markdown",
    },
  ],
  colors: {
    "editor.foreground": "#e5c07b",
    "editor.background": "#272535",
    "editor.selectionBackground": "#264f78",
    "editor.lineHighlightBackground": "#ffffff0A",
    "editorCursor.foreground": "#aeafad",
    "editorWhitespace.foreground": "#e3e4e229",
  },
};

export default monacoTheme;
