import React from "react";
import { doc, getFirestore } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import contentFile from "../utils/content.json";
import { app } from "../components/auth/auth";
import { previewModeAtom } from "./atoms";
import { useRecoilValue } from "recoil";

export const useStudioData = () => {
  const previewMode = useRecoilValue(previewModeAtom);
  const document = useDocument(
    doc(getFirestore(app), "settings", "content_draft"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  if (previewMode) {
    let [value_, loading]: any = document;

    const value: any =
      value_?.data() === undefined ? contentFile : value_.data();
    return { data: value, loading, mode: "preview" };
  } else {
    return { data: contentFile, loading: false, mode: "regular" };
  }
};

export const useDraftData = (draft: any) => {
  const [showDraft, setShowDraft] = React.useState<boolean>(false);
  let [value_, loading] = useDocument(
    doc(getFirestore(app), "settings", "content_draft"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  let value: any = value_?.data() === undefined ? draft : value_.data();

  React.useEffect(() => {
    setShowDraft(true);
    setTimeout(() => {
      setShowDraft(false);
    }, 3000);
  }, [draft]);

  if (showDraft) {
    return { data: draft, loading: true };
  } else {
    return { data: value, loading };
  }
};
