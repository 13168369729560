import { DroppableId, DropResult } from "react-beautiful-dnd";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { textAreaAtom } from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { multiSummaryText } from "../../lib/multi-summary-text";
import {
  clusteringStatelessAtom,
  partitionedSkillsSelector,
  pipelineSkillsAtom,
  skillsLibraryAtom,
} from "./pipeline-atoms";

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const useOnDragEnd = () => {
  const [pipelineSkills, setPipelineSkills] =
    useRecoilState(pipelineSkillsAtom);
  const { userEventLogger } = useEventLogger();

  const partitionedSkills = useRecoilValue(partitionedSkillsSelector);
  const skillsLibrary = useRecoilValue(skillsLibraryAtom);
  const setOpenedPopover = useSetRecoilState(clusteringStatelessAtom);
  const [textArea, setTextArea] = useRecoilState(textAreaAtom);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    const droppableOffset = (droppable: any) => {
      const outputIndex = parseInt(droppable.split("__")[1]);
      let offset = 0;
      for (let index = 0; index < partitionedSkills.length; index++) {
        if (index < outputIndex) {
          offset += partitionedSkills[index].length;
        }
      }
      return offset;
    };

    const getSkillByName = (skillName: string) => {
      for (const category in skillsLibrary.skills) {
        const skills = skillsLibrary.skills[category];
        for (let index = 0; index < skills.length; index++) {
          const skill = skills[index];
          if (skill.name === skillName) {
            return { ...skill, id: uuidv4() };
          }
        }
      }
    };

    const getLinearIndex = (draggableLocation: any) => {
      const offset = droppableOffset(draggableLocation.droppableId);
      return offset + draggableLocation.index;
    };

    const sourceDroppable: DroppableId = source.droppableId;

    const skillNotPlacedInPipeline = destination === null;
    const removeSkill =
      sourceDroppable.startsWith("PIPELINE_LIBRARY_OUTPUT") &&
      skillNotPlacedInPipeline;

    if (removeSkill) {
      // Skill from pipeline dropped outside the droppable
      const linearIndex = getLinearIndex(source);
      const pipelineSkillsStepsNew = [...pipelineSkills.steps];
      const removedSkill = pipelineSkillsStepsNew[linearIndex];
      pipelineSkillsStepsNew.splice(linearIndex, 1);
      setPipelineSkills({ steps: pipelineSkillsStepsNew });
      userEventLogger(UserEvent.SKILL_REMOVE, { value: removedSkill.name });
      return;
    }

    if (!destination) {
      // Skill from library dropped outside the droppable
      return;
    }

    const destinationDroppable: DroppableId = destination.droppableId;

    const moveInSameDroppable = sourceDroppable === destinationDroppable;
    const moveBetweenDroppables =
      sourceDroppable.startsWith("PIPELINE_LIBRARY_OUTPUT") &&
      destinationDroppable.startsWith("PIPELINE_LIBRARY_OUTPUT");
    const addFirstSkill = destinationDroppable.startsWith(
      "PIPELINE_LIBRARY_EMPTY",
    );
    const addAnotherSkill = destinationDroppable.startsWith(
      "PIPELINE_LIBRARY_OUTPUT",
    );

    if (moveInSameDroppable || moveBetweenDroppables) {
      // We reorder in same output OR We move from one output to another
      const offsetSrc = droppableOffset(sourceDroppable);
      const offsetDest = droppableOffset(destinationDroppable);
      const items = reorder(
        pipelineSkills.steps,
        offsetSrc + source.index,
        offsetDest + destination.index,
      );
      const newState = { steps: items };
      setPipelineSkills(newState);
      return;
    }

    const openClusteringPopover = (skill: any) => {
      if (skill?.name === "Clustering") {
        setOpenedPopover(skill?.id);
      }
    };

    if (addFirstSkill || addAnotherSkill) {
      const skill = getSkillByName(sourceDroppable);
      openClusteringPopover(skill);

      if (skill.type === 6 && textArea === "") {
        setTextArea(multiSummaryText);
      }
    }

    if (addFirstSkill) {
      // We add the first skill
      const skill = getSkillByName(sourceDroppable);
      userEventLogger(UserEvent.SKILL_ADD, { value: skill.name });
      setPipelineSkills({ steps: [skill] });

      return;
    }

    if (addAnotherSkill) {
      // We add (non first) skill
      const offsetDest = droppableOffset(destinationDroppable);
      const skill = getSkillByName(sourceDroppable);
      userEventLogger(UserEvent.SKILL_ADD, { value: skill.name });
      let pipelineSkillsNew = [...pipelineSkills.steps];
      pipelineSkillsNew.splice(offsetDest + destination.index, 0, skill);

      setPipelineSkills({ steps: pipelineSkillsNew });
      return;
    }
  };
  return onDragEnd;
};
