import Menu from "@mui/material/Menu";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { IconEditAccountUsageLimit } from "../assets/SvgIcons";
import i18n from "../i18n";
import theme from "../theme";
import { useAdminMutate } from "./auth/data";
import { LottieLoader } from "./Loader";
import AppTooltip from "./tooltip";

export default function EditAccountPopover({
  initialValues,
  openedPopover = () => {},
  closedPopover = () => {},
  orgId,
  setMonthlyUsageLimit,
  setDailyUsageLimit,
  enabled,
}: {
  initialValues?: object;
  openedPopover?: Function;
  closedPopover?: Function;
  orgId: string;
  setMonthlyUsageLimit: (limit: number) => void;
  setDailyUsageLimit: (limit: number) => void;
  enabled: boolean;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("manage-account");

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);
  if (!initialValues) {
    initialValues = {
      monthly_usage_limit: 0,
    };
  }
  return (
    <React.Fragment>
      <AppTooltip title={t("Edit account")}>
        <button
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className="grid items-center justify-center"
          disabled={enabled}
        >
          <figure>
            <IconEditAccountUsageLimit
              height="15px"
              width="15px"
              className={enabled ? "text-graylogo" : "text-shadeBlue"}
            />
          </figure>
        </button>
      </AppTooltip>
      <AccountPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        initialValues={initialValues}
        orgId={orgId}
        setMonthlyUsageLimit={setMonthlyUsageLimit}
        setDailyUsageLimit={setDailyUsageLimit}
      />
    </React.Fragment>
  );
}

type IAccountPopup = {
  anchorEl: any;
  initialValues: object;
  open: boolean;
  onClose: any;
  orgId: string;
  setMonthlyUsageLimit: (limit: number) => void;
  setDailyUsageLimit: (limit: number) => void;
};

const AccountPopup = React.forwardRef((props: IAccountPopup, ref: any) => {
  const {
    anchorEl,
    initialValues,
    open,
    onClose,
    orgId,
    setMonthlyUsageLimit,
    setDailyUsageLimit,
  } = props;
  const [tipRight, setTipRight] = React.useState(0);
  const tipWidth = 18;
  React.useEffect(() => {
    if (anchorEl) {
      setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2);
    }
  }, [anchorEl]);
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          backgroundColor: theme.colors.lightBlack,
          color: theme.colors.white,
          padding: theme.spacing[4],
          width: "280px !important",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.3,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "4px",
            right: tipRight,
            width: tipWidth,
            height: 18,
            backgroundColor: theme.colors.lightBlack,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            borderRadius: "4px",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <EditForm
        initialValues={initialValues}
        onClose={onClose}
        orgId={orgId}
        setMonthlyUsageLimit={setMonthlyUsageLimit}
        setDailyUsageLimit={setDailyUsageLimit}
      />
    </Menu>
  );
});

const EditForm = ({
  initialValues,
  onClose = () => {},
  orgId,
  setMonthlyUsageLimit,
  setDailyUsageLimit,
}: {
  initialValues: object;
  onClose: any;
  orgId: string;
  setMonthlyUsageLimit: (limit: number) => void;
  setDailyUsageLimit: (limit: number) => void;
}) => {
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState("FORM");
  const { mutateOrganizationField } = useAdminMutate();
  const { t } = useTranslation("manage-accounts");

  const onSubmit = async (values: any) => {
    setState("LOADING");
    const monthlyRes = await mutateOrganizationField({
      orgId: orgId,
      field: "monthly_quota",
      newValue: values.monthly_usage_limit,
    });
    if (monthlyRes?.data?.success) {
      const dailyRes = await mutateOrganizationField({
        orgId: orgId,
        field: "daily_quota",
        newValue: values.daily_usage_limit,
      });
      if (dailyRes?.data?.success) {
        setMonthlyUsageLimit(values.monthly_usage_limit);
        setDailyUsageLimit(values.daily_usage_limit);
        setState("SUCCESS");
        onClose();
      } else {
        setState("ERROR");
        setError(dailyRes?.data?.message);
      }
    } else {
      setState("ERROR");
      setError(monthlyRes?.data?.message);
    }
  };
  const validate = (values: any) => {
    const errors = {} as any;
    if (!/^\d+$/.test(values.monthly_usage_limit)) {
      errors.monthly_usage_limit = t("Please enter a valid number");
    }
    return errors;
  };

  if (error) {
    return <div>{t(error)}</div>;
  }

  return (
    <div>
      {state === "LOADING" ? (
        <LottieLoader />
      ) : state === "SUCCESS" ? (
        <div></div>
      ) : state === "ERROR" ? (
        <div>{error}</div>
      ) : (
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="grid grid-cols-1 gap-y-4 font-poppins pb-4">
              <b className="text-center">{t("Edit account")}</b>
              <div className="grid grid-cols-1 gap-y-2">
                <label htmlFor="name" className="text-sm">
                  {t("Monthly usage limit")}
                </label>
                <Field
                  type="tel"
                  name="monthly_usage_limit"
                  className="bg-dark rounded p-3"
                />
                <ErrorMessage
                  name="monthly_usage_limit"
                  component="div"
                  className="text-red"
                />
              </div>
              <div className="grid grid-cols-1 gap-y-2">
                <label htmlFor="name" className="text-sm">
                  {t("Daily usage limit")}
                </label>
                <Field
                  type="tel"
                  name="daily_usage_limit"
                  className="bg-dark rounded p-3"
                />
                <ErrorMessage
                  name="daily_usage_limit"
                  component="div"
                  className="text-red"
                />
              </div>
              <div
                dir={i18n.dir()}
                className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={onClose}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Save changes")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
