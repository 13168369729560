// Render Prop
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import {
  getAdmins,
  useAdminMutate,
  useUserData,
} from "../../components/auth/data";
import DeleteAdminPopover from "../../components/delete-admin";
import AddAdminPopover from "../../components/invite-admin";
import { LottieLoader } from "../../components/Loader";
import { useThemeColor } from "../../components/theme-color";
import { UserData } from "../../lib/interfaces";
import { theme } from "../../tailwind.config";

export default function ManageAccounts() {
  const { t } = useTranslation("manage-admins");
  const [admins, setAdmins] = useState<Array<UserData>>([]);

  return (
    <React.Fragment>
      <div className="px-3 dark:text-white text-black ">
        <div className="grid">
          <div className="container grid grid-cols-auto-1fr items-center pb-0">
            <div className="">
              <h1
                className={`items-center md:visible font-poppins font-bold dark:text-white text-black`}
              >
                {t("One AI Backoffice Admins")}
              </h1>
            </div>

            <div className="grid justify-end ">
              <div className="grid">
                <div className="grid items-center gap-y-1">
                  <AddAdminPopover setAdmins={setAdmins} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:max-h-[calc(100vh-400px)] overflow-y-auto mt-20">
          <div className="w-full grid justify-center"></div>
          <AdminsList admins={admins} setAdmins={setAdmins} />
        </div>
      </div>
    </React.Fragment>
  );
}

type AdminsListType = {
  admins: Array<UserData>;
  setAdmins: (users: Array<UserData>) => void;
};
const AdminsList = ({ admins, setAdmins }: AdminsListType) => {
  const { t } = useTranslation("manage-admins");

  const [adminsLoading, setAdminsLoading] = useState<boolean>(true);

  // In order to increase the limit of the users from firebase please increase this value
  const [limit] = useState<number>(1000);

  useEffect(() => {
    const fetchAdmins = async () => {
      const adminList = await getAdmins(limit);

      console.log("AdminsList :: ", adminList);
      setAdmins(adminList);
      setAdminsLoading(false);
    };
    fetchAdmins();
    // eslint-disable-next-line
  }, []);

  if (adminsLoading) {
    return <LottieLoader />;
  }

  if (!admins?.length) {
    return <div>{t("No admins found")}</div>;
  }
  return (
    <>
      <div className="grid">
        <table className="w-full table-fixed">
          <thead className="border-b border-tableHeaderBorder dark:border-tableHeaderBorderDark dark:text-white text-black">
            <tr>
              <th
                scope="col"
                className="text-sm font-bold font-poppins  text-tableGrayHeader px-6 py-4 text-left"
              >
                {t("Email")}
              </th>
              <th
                scope="col"
                className="text-sm font-bold font-poppins  text-tableGrayHeader px-6 py-4 text-left"
              >
                {t("Permission")}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {admins &&
              admins?.map((adminData: any, index: number) => (
                <React.Fragment key={index}>
                  <TableRow data={adminData} setAdmins={setAdmins} />
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

type permissionSelectType = {
  value: string;
  label: string;
  color?: string;
};

const Option = (props: any) => {
  return (
    <div style={{ color: props.data.color }}>
      <components.Option {...props} className="text-green" />
    </div>
  );
};

const permissionSelectOptions: Array<permissionSelectType> = [
  { value: "Admin", label: "Admin", color: theme.colors.green },
  { value: "Editor", label: "Editor", color: theme.colors.blue },
  { value: "Viewer", label: "Viewer", color: theme.colors.blue },
];

const TableRow = ({ data, setAdmins }: any) => {
  const firebaseUserData = data;
  const { isDark } = useThemeColor();
  const { value: currentUserData } = useUserData();
  const getOptionByName = (name: string) => {
    return permissionSelectOptions.find(
      (option) => option.value.toLowerCase() === name.toLowerCase(),
    );
  };

  const [permissionSelected, setPermissionSelected] =
    useState<permissionSelectType>(
      getOptionByName(firebaseUserData?.role) || permissionSelectOptions[0],
    );

  const { mutateUserField } = useAdminMutate();

  const handleSelectDefaultData = async (selectedOption: any) => {
    setPermissionSelected(selectedOption);
    await mutateUserField({
      clientId: firebaseUserData?.uid,
      field: "role",
      newValue: selectedOption.value,
    });
  };

  return (
    <tr className="border-b border-tableBodyBorder dark:border-darkGrayBorder dark:text-white">
      <td className="px-6 py-4 font-poppins text-sm font-bold overflow-x-auto">
        {firebaseUserData?.auth_email}
      </td>
      <td className="text-sm font-poppins w-full font-normal  px-6 py-4">
        <div className="w-[149px]">
          <Select
            menuPortalTarget={document.body}
            styles={selectStyles(isDark ? true : false)}
            components={{
              Option,
              IndicatorSeparator: () => null,
            }}
            options={permissionSelectOptions}
            onChange={(selectedOption): any => {
              handleSelectDefaultData(selectedOption);
            }}
            isDisabled={
              currentUserData?.role !== "Admin" &&
              currentUserData?.role !== "Editor"
            }
            value={permissionSelected}
          />
        </div>
      </td>

      <td className="font-poppins whitespace-nowrap font-light px-6 py-4 h-full">
        <div className="grid grid-cols-2-auto gap-x-6 justify-end">
          <div>
            {/*
              <EditAccountPopover
              userId={firebaseUserData.uid}
              initialValues={{ monthly_usage_limit: monthUsageLimit }}
              setMonthlyUsageLimit={setMonthlyUsageLimit}
            />
            */}
          </div>
          <DeleteAdminPopover
            email={firebaseUserData?.auth_email}
            userId={firebaseUserData?.uid}
            setAdmins={setAdmins}
          />
        </div>
      </td>
    </tr>
  );
};

const adminColor: any = {
  Admin: theme.colors.green,
};

const selectStyles = (isDark: boolean) =>
  ({
    control: (provided: any, { isDisabled }: any) => ({
      ...provided,

      height: "41px",
      border: isDisabled
        ? "none"
        : isDark
        ? `1px solid ${theme.colors.darkGrayBorder}`
        : `1px solid ${theme.colors.borderLight}`,
      outline: "none",
      boxShadow: "none",
      borderRadius: "5px",
      backgroundColor: isDisabled
        ? theme.colors.transparent
        : isDark
        ? theme.colors.darkBackground
        : theme.colors.white,
      "&:hover": {
        border: isDark
          ? `1px solid ${theme.colors.darkGrayBorder}`
          : `1px solid ${theme.colors.borderLight}`,
      }, // border style on hover
    }),

    singleValue: (provided: any, { data }: any) => ({
      ...provided,
      color: adminColor[data.value]
        ? adminColor[data.value]
        : isDark
        ? theme.colors.white
        : theme.colors.black,
    }),
    dropdownIndicator: (provided: any, { isDisabled }: any) => ({
      ...provided,
      svg: {
        fill: isDisabled ? theme.colors.transparent : theme.colors.dodgerBlue,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.colors.formsgray,
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: isDark
        ? theme.colors.darkGrayBackground
        : theme.colors.faWhite,
      border: isDark
        ? `1px solid ${theme.colors.darkGrayBorder}`
        : `1px solid ${theme.colors.borderLight} `,
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),

    option: (provided: any, { isFocused, isSelected, data }: any) => ({
      ...provided,
      backgroundColor: isSelected
        ? isDark
          ? theme.colors.darkGrayBackground
          : theme.colors.faWhite
        : isFocused
        ? isDark
          ? theme.colors.faWhite
          : theme.colors.darkGrayBackground
        : isDark
        ? theme.colors.darkGrayBackground
        : theme.colors.faWhite,
      color: adminColor[data.value]
        ? adminColor[data.value]
        : isSelected
        ? isDark
          ? theme.colors.faWhite
          : theme.colors.darkGrayBackground
        : isFocused
        ? isDark
          ? theme.colors.darkGrayBackground
          : theme.colors.faWhite
        : isDark
        ? theme.colors.faWhite
        : theme.colors.darkGrayBackground,

      "&:hover": {
        backgroundColor: isDark
          ? theme.colors.faWhite
          : theme.colors.darkGrayBackground,
        color: isDark ? theme.colors.darkGrayBackground : theme.colors.faWhite,
      },
      "&:active": {
        color: isDark ? theme.colors.black : theme.colors.white,
        backgroundColor: isDark
          ? theme.colors.faWhite
          : theme.colors.darkGrayBackground,
      },
    }),
  } as any);
