import { cloneDeep, groupBy } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { CSVDownloader } from "react-papaparse";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Split from "react-split-grid";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  AddSkillToPipelineIcon,
  IconDownloadCSV,
  IconTabsTable,
  IconTabsTree,
} from "../../assets/SvgIcons";
import { OutputLoader } from "../../components/Loader";
import { useThemeColor } from "../../components/theme-color";
import AppTooltip from "../../components/tooltip";
import Topics from "../../components/topics";
import {
  loaderAtom,
  showSkillLibraryAtom,
  summaryOriginsAtom,
  textAreaAtom,
} from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import highlightRange from "../../lib/InputHighlight";
import { PipelineValuesType, SkillsType } from "../../lib/interfaces";
import useElementSize from "../../lib/use-element-size-debounce";
import theme from "../../theme";
import HighlightText from "../PipelinePage/Output/HighlightText";
import Tabs from "../PipelinePage/Output/tabs";
import { randomString, stripHTML, useIsMobile } from "../PipelinePage/utils";
import {
  apiResponseAtom,
  currentOutputTab,
  partitionedSkillsSelector,
  reRenderAtom,
  runPipelineAtom,
  sharedPipelineAtom,
} from "./pipeline-atoms";
import {
  generateOutputLabels,
  getOnlyActiveLabels,
  isActiveSkill,
  useLabelTypeExists,
} from "./pipeline-common";
import { SkillItemPipelineOutput } from "./pipeline-dnd";
import { ResizeGutterRow } from "./resize-gutter";
export interface Output {
  text_generated_by_step_name: string;
  text_generated_by_step_id: string;
  text: string;
  labels: any;
}

export default function PipelineOutputPanel() {
  const apiResponse = useRecoilValue(apiResponseAtom);
  const outputLoader = useRecoilValue(loaderAtom);
  const isMobile = useIsMobile();
  const runningPipeline = useRecoilValue(runPipelineAtom);
  const sharedPipeline = useRecoilValue(sharedPipelineAtom);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (apiResponse === null && !runningPipeline && !sharedPipeline) {
      navigate("/pipeline-input");
    }
    //eslint-disable-next-line
  }, [apiResponse]);

  return (
    <>
      <OutputLoader loader={(!isMobile && outputLoader) || sharedPipeline} />
      <div
        className={`w-full h-[calc(100%-70px)] md:h-full overflow-y-auto text-white grid grid-rows-auto-1fr md:grid-rows-1
        ${
          (outputLoader && !isMobile) || sharedPipeline ? "output-loader" : ""
        }`}
      >
        <OutputTabs />
        <OutputContent />
      </div>
    </>
  );
}

function OutputTabs() {
  const apiResponse = useRecoilValue(apiResponseAtom);
  const [currentTab, setCurrentTab] = useRecoilState(currentOutputTab);

  return (
    <div className="block md:hidden overflow-x-auto">
      <div className="grid grid-flow-col gap-x-2">
        {apiResponse &&
          apiResponse?.output?.map((output: Output, index: number) => {
            return (
              <div
                key={index}
                className={`cursor-pointer w-fit-content px-6 py-4 rounded-t-md
              ${
                currentTab === index
                  ? "bg-white dark:bg-darkGrayBackground dark:text-white text-black" // Active tab
                  : "bg-catskill shadow-md dark:bg-tabDarkGray dark:text-numberGray text-black" // Inactive tabs
              }`}
                onClick={() => setCurrentTab(index)}
              >
                <span className="text-sm font-poppins">Output {index + 1}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
}
function OutputContent() {
  return (
    <>
      <OutputContentDesktop />
      <OutputContentMobile />
    </>
  );
}

function OutputContentDesktop() {
  const apiResponse = useRecoilValue(apiResponseAtom);
  const partitionedSkills = useRecoilValue(partitionedSkillsSelector);

  if (!apiResponse?.output?.length) {
    return (
      <div className="hidden md:grid grid-flow-col gap-x-2 overflow-y-hidden overflow-x-auto pr-4">
        {partitionedSkills &&
          partitionedSkills?.map((output: any, index: number) => {
            return (
              <SingleOutputPanel
                key={index}
                output={{
                  text_generated_by_step_name: "",
                  text_generated_by_step_id: "",
                  text: "",
                  labels: [],
                }}
                index={index}
              />
            );
          })}
      </div>
    );
  }
  return (
    <div
      className={`hidden md:grid grid-flow-col gap-x-2 overflow-y-hidden overflow-x-auto pr-4`}
    >
      {apiResponse?.output.map((output: any, index: number) => {
        return <SingleOutputPanel output={output} index={index} />;
      })}
    </div>
  );
}

function OutputContentMobile() {
  const apiResponse = useRecoilValue(apiResponseAtom);
  const currentTab = useRecoilValue(currentOutputTab);
  const output = apiResponse?.output[currentTab];

  return (
    <div className="md:hidden grid grid-col-1 overflow-y-hidden ">
      <SingleOutputPanelMobile output={output} index={currentTab} />
    </div>
  );
}

function SingleOutputPanel({
  output,
  index,
}: {
  output: Output;
  index: number;
}) {
  let uniques = groupBy(output?.labels, "skill");

  const [activeLabels, setActiveLabels] = React.useState<[]>(
    generateOutputLabels(uniques),
  );

  // This fixes the active labels not being updated
  React.useEffect(() => {
    setActiveLabels(generateOutputLabels(uniques));
    //eslint-disable-next-line
  }, [output]);

  return (
    <div
      className="w-full h-full grid grid-rows-auto-1fr overflow-y-hidden "
      style={{ minWidth: "450px" }}
    >
      <SingleOutputPanelSkillsList
        output={output}
        index={index}
        activeLabels={activeLabels}
        setActiveLabels={setActiveLabels}
      />

      <SingleOutputPanelContent activeLabels={activeLabels} output={output} />
    </div>
  );
}

function SingleOutputPanelSkillsList({
  output,
  index,
  activeLabels,
  setActiveLabels,
}: {
  output: Output;
  index: number;
  filteredOutput?: Output;
  activeLabels: any;
  setActiveLabels: any;
}) {
  const partitionedSkills = useRecoilValue(partitionedSkillsSelector);
  const setClusteringReRender = useSetRecoilState(reRenderAtom);
  const [textArea, setTextArea] = useRecoilState(textAreaAtom);
  const { search } = useLocation();
  const [summaryOrigins, setSummaryOrigins] =
    useRecoilState<boolean>(summaryOriginsAtom);

  const setActive = (skill: SkillsType, labelExists: any) => {
    const newObj: any = { ...activeLabels };
    if (labelExists === "NOT_EXISTS") {
      // Fixes dialog-segment not being toggeled because of backend
      // (they send back the label as "dialog-segment" instead of "dialog-segmentation")
      // This is a temporary fix but will work also when they fix this
      // However if you still want to remove this, just remove the if statement here and below.
      if (skill?.value === "dialog-segmentation") {
        newObj["dialog-segmentation"] = false;
        newObj["dialog-segment"] = false;
      } else {
        newObj[skill?.value] = false;
      }
    } else {
      if (skill?.value === "dialog-segmentation") {
        newObj["dialog-segmentation"] = !newObj[skill?.value];
        newObj["dialog-segment"] = !newObj["dialog-segment"];
      } else {
        newObj[skill?.value] = !newObj[skill?.value];
      }
    }

    setClusteringReRender(randomString(3));
    setActiveLabels(newObj);

    if (skill.value === "summarize") {
      if (summaryOrigins) {
        setTextArea(stripHTML(textArea));
      } else {
        const clonedLabels = cloneDeep(output?.labels);
        const highlightedText = highlightRange(
          stripHTML(textArea),
          clonedLabels,
        );
        setTextArea(highlightedText);
      }
      setSummaryOrigins(!summaryOrigins);
    }
  };

  return (
    <div className="flex dark:bg-darkGrayBackground bg-white rounded-t-md p-1.5 min-h-[58px] w-fit-content max-w-full  no-blur">
      <ul className="flex gap-2 m-0 flex-wrap w-full h-full ">
        {partitionedSkills[index] &&
          partitionedSkills[index]?.map((skill: SkillsType) => {
            const isActive = isActiveSkill(activeLabels, skill);

            return (
              <li key={skill.name}>
                <div
                  className="cursor-pointer"
                  tabIndex={0}
                  onClick={() => {
                    setActive(skill, isActive);
                  }}
                >
                  <SkillItemPipelineOutput
                    index={index}
                    skill={skill}
                    isActive={isActive === "TRUE" || isActive === "NOT_EXISTS"}
                  />
                </div>
              </li>
            );
          })}
      </ul>
      <div className="hidden md:block">
        <Link
          to={"/pipeline-skills" + search}
          className="ml-2 grid text-borderBlue"
        >
          <AddSkillToPipelineIcon />
        </Link>
      </div>
    </div>
  );
}

function SingleOutputPanelContent({
  output,
  activeLabels,
}: {
  output: Output;
  activeLabels: any;
}) {
  const [tabValue, setTabValue] = React.useState<PipelineValuesType>("TABLE");
  const [ref, { height }] = useElementSize();
  const setReRender = useSetRecoilState(reRenderAtom);
  const isClustering = tabValue === "CLUSTERING";
  const filteredOutput = getOnlyActiveLabels(output, activeLabels);
  const showTableHeader = !isClustering && output?.labels?.length > 0;
  return (
    <div className="rounded-b-md h-full overflow-y-hidden">
      <Split
        minSize={100}
        onDragEnd={() => {
          setReRender(randomString(2));
        }}
      >
        {({ getGridProps, getGutterProps }: any) => (
          <div
            className="w-full h-full grid grid-rows-pipeline-output-panel overflow-y-hidden"
            {...getGridProps()}
          >
            <div className="dark:bg-darkGrayBackground bg-white rounded-b-md overflow-y-hidden h-full">
              <div className="overflow-y-hidden h-full grid grid-rows-auto-1fr pl-4">
                <OutputTopicsAndText output={filteredOutput} />
              </div>
            </div>

            <ResizeGutterRow getGutterProps={getGutterProps} />
            <div
              ref={ref}
              className="dark:bg-darkGrayBackground bg-white rounded-md overflow-y-hidden h-full"
            >
              <div
                className={`overflow-y-hidden h-full grid ${
                  !isClustering ? "grid-rows-auto-1fr" : ""
                }`}
              >
                {/* prettier-ignore */}
                {showTableHeader && (
                  <div>
                    <OutputTableHeader
                      output={filteredOutput}
                      setTabValue={setTabValue}
                    />
                  </div>
                )}

                <div className="overflow-y-auto">
                  <OutputTableAndClustering
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    output={filteredOutput}
                    tabHeight={height}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Split>
    </div>
  );
}

function OutputTopicsAndText({ output }: { output: Output }) {
  return (
    <>
      {/* Don't wrap topics in a div. it's important that it will be null if no topics exist so that we won't have gap above the text */}
      <Topics labels={output?.labels} />
      <div className="overflow-y-auto dark:text-white text-darkGray md:text-sm text-md font-poppins font-medium dark:font-normal py-4 pr-4 whitespace-pre-wrap">
        <OutputText output={output} />
      </div>
    </>
  );
}

function SingleOutputPanelMobile({
  output,
  index,
}: {
  output: Output;
  index: number;
}) {
  let uniques = groupBy(output?.labels, "skill");

  const [activeLabels, setActiveLabels] = React.useState<[]>(
    generateOutputLabels(uniques),
  );

  // This fixes the active labels not being updated
  React.useEffect(() => {
    setActiveLabels(generateOutputLabels(uniques));
    //eslint-disable-next-line
  }, [output]);

  const filteredOutput = getOnlyActiveLabels(output, activeLabels);

  return (
    <div className="rounded-b-md h-full overflow-y-hidden grid grid-rows-auto-1fr dark:bg-darkGrayBackground bg-white">
      <SingleOutputPanelSkillsList
        activeLabels={activeLabels}
        setActiveLabels={setActiveLabels}
        index={index}
        output={output}
      />
      <div className="overflow-y-hidden grid grid-rows-auto-1fr p-4">
        <OutputTopicsAndText output={filteredOutput} />
      </div>
    </div>
  );
}

function OutputText({ output }: { output: Output }) {
  const apiResponse = useRecoilValue(apiResponseAtom);
  if (!output) {
    return null;
  }
  return (
    <HighlightText
      text={output.text}
      labels={output.labels ? output.labels : []}
      input_text={apiResponse?.input_text}
      //hide_origin={hideOrigin}  // Uncomment this and remove next line when you want the output summary origin to be visible
      hide_origin={true}
    />
  );
}

type OutputTableHeaderValueProps = {
  children: React.ReactNode;
  className?: string;
  showCondition?: boolean;
};

function OutputTableHeaderValue({
  children,
  className = "",
  showCondition = true,
}: OutputTableHeaderValueProps) {
  return (
    <div
      className={`flex-1 text-outputHeaderValue text-xs font-medium
      ${className} 
      ${!showCondition ? "hidden" : ""}`}
    >
      {children}
    </div>
  );
}
function OutputTableHeader({
  output,
  setTabValue,
}: {
  output: Output;
  setTabValue: (value: PipelineValuesType) => void;
}) {
  const { userEventLogger } = useEventLogger();
  const { isDark } = useThemeColor();
  const setShowSkillLibrary = useSetRecoilState(showSkillLibraryAtom);

  const generateDateFileName = () => {
    return new Date().toJSON().slice(0, 10);
  };

  const { t } = useTranslation("index");
  const _label = useLabelTypeExists(output.labels);

  return (
    <div className="flex border-b border-grayBorder dark:border-outputHeaderBorderDark">
      <div className="font-poppins w-full flex items-center justify-center px-4 py-3 relative">
        <OutputTableHeaderValue showCondition={true}>
          {t("Skill")}
        </OutputTableHeaderValue>
        <OutputTableHeaderValue>{t("Label")}</OutputTableHeaderValue>
        <OutputTableHeaderValue showCondition={_label.showSpanColumn}>
          {t("Span")}
        </OutputTableHeaderValue>
        <OutputTableHeaderValue showCondition={_label.showScoreColumn()}>
          {t("Score")}
        </OutputTableHeaderValue>
        <OutputTableHeaderValue showCondition={_label.showValueColumn}>
          {t("Value")}
        </OutputTableHeaderValue>
        <div
          className={`hidden md:block absolute right-4`}
          onClick={() => {
            userEventLogger(UserEvent.EXPORT_TO_CSV);
          }}
        >
          <div className="grid grid-flow-col gap-x-5">
            <AppTooltip title={t("Download table as CSV")}>
              {/* This div is needed to make the tooltip work */}
              <div className="grid items-center">
                <CSVDownloader
                  data={_label.labelsCSVData()}
                  type="button"
                  filename={generateDateFileName()}
                  className={"hidden md:block"}
                >
                  <IconDownloadCSV
                    className="text-shadeBlue"
                    width="17px"
                    height="17px"
                  />
                </CSVDownloader>
              </div>
            </AppTooltip>
            <AppTooltip title={t("Labels Table")}>
              <button
                className="grid items-center"
                onClick={() => {
                  setTabValue("CLUSTERING");
                  setShowSkillLibrary(false);
                }}
              >
                <IconTabsTree
                  width="13px"
                  height="13px"
                  className="text-shadeBlue mt-[2.5px]"
                />
              </button>
            </AppTooltip>
          </div>
        </div>
        <div className={`block md:hidden`}>
          <button
            onClick={() => {
              setTabValue("CLUSTERING");
              setShowSkillLibrary(false);
            }}
          >
            <IconTabsTable
              width="35px"
              height="35px"
              fill={
                isDark ? theme.colors.darkGrayBackground : theme.colors.white
              }
              type="table"
            />
          </button>
        </div>
      </div>
    </div>
  );
}

function OutputTableAndClustering({
  output,
  tabValue,
  setTabValue,
  tabHeight,
}: {
  output: Output;
  tabValue: PipelineValuesType;
  setTabValue: (value: PipelineValuesType) => void;
  tabHeight: any;
}) {
  const labelTypeExists = useLabelTypeExists(output.labels);
  return (
    <Tabs
      labels={output.labels}
      text={output.text}
      labelTypeExists={labelTypeExists}
      dataLabel={output}
      tabValue={tabValue}
      setTabValue={setTabValue}
      tabHeight={tabHeight}
    />
  );
}
