import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import IconBrokenShapesImage from "../assets/broken-shapes.png";
import { IconPuzzle } from "../assets/SvgIcons";
import { anonymousePreNavigationAtom } from "../lib/atoms";
import { ErrorType, useErrorLogger } from "../lib/event_logger";
import { theme } from "../tailwind.config";
import { useUser } from "./auth/auth";
import { useThemeColor } from "./theme-color";

type ErrorPopupType = {
  isOpen: boolean;
  setIsOpen: any;
  title?: string | undefined;
  text?: string | undefined;
  jsxContent?: JSX.Element | undefined;
  jsxTitle?: JSX.Element | undefined;
  jsxFigure?: JSX.Element;
  jsxButton?: JSX.Element | undefined;
  requestId: string | undefined;
};

const IconBrokenShapes = () => (
  <figure>
    <img
      src={IconBrokenShapesImage}
      alt="broken shapes"
      width="176px"
      height="144px"
      className="max-w-[176px]"
    />
  </figure>
);

export const ErrorPopup = ({
  isOpen,
  setIsOpen,
  title,
  text,
  requestId,
}: ErrorPopupType) => {
  const navigate = useNavigate();
  const { user, loading } = useUser();
  const { t } = useTranslation();
  const { userErrorLogger } = useErrorLogger();

  const setAnonymousePreNavigation = useSetRecoilState(
    anonymousePreNavigationAtom,
  );

  const button = `w-20 rounded-md text-white font-mono text-sm`;

  React.useEffect(() => {
    if (isOpen) {
      userErrorLogger({ error: ErrorType.POPUP_ERROR, msg: title });
    }
    //eslint-disable-next-line
  }, [isOpen]);

  const jsxQuotaButtons = (
    <div className="flex flex-col-2 gap-2 h-9">
      <button
        onClick={() => {
          if (!loading) {
            if (user && user?.isAnonymous) {
              setIsOpen(false);
              setAnonymousePreNavigation(true);
            } else {
              navigate("/settings/billing");
            }
          }
        }}
        className={`${button} bg-shadeBlue`}
      >
        {t("Upgrade")}
      </button>
      <button
        onClick={() => {
          if (!loading) {
            setIsOpen(false);
          }
        }}
        className={`${button} bg-grayBox`}
      >
        {t("Cancel")}
      </button>
    </div>
  );

  const isQuotaExceededError =
    !!title && title?.includes("Monthly organization quota exceeded");
  if (isQuotaExceededError)
    return (
      <ErrorPopupTemplate
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        jsxTitle={<>Monthly organization quota exceeded</>}
        jsxButton={jsxQuotaButtons}
        jsxFigure={
          <figure className="px-4">
            <IconPuzzle width="150px" height="133px" />
          </figure>
        }
        jsxContent={
          <>{t("You have exceeded your processing quota this month.")}</>
        }
        requestId={requestId}
      />
    );

  return (
    <ErrorPopupTemplate
      isOpen={isOpen}
      jsxFigure={
        <figure>
          <IconBrokenShapes />
        </figure>
      }
      setIsOpen={setIsOpen}
      title={title}
      text={text}
      requestId={requestId}
    />
  );
};

const ErrorPopupTemplate = ({
  isOpen,
  setIsOpen,
  title,
  text,
  jsxFigure,
  jsxContent,
  jsxTitle,
  jsxButton,
  requestId,
}: ErrorPopupType) => {
  const { t } = useTranslation();
  const { isDark } = useThemeColor();

  const jsxRegularButton = (
    <button
      onClick={() => setIsOpen(false)}
      className="w-[75px] h-[39px] bg-shadeBlue hover:bg-opacity-75 rounded-md text-white self-end font-mono"
      data-cy="error-popup-ok-button"
    >
      {t("Ok")}
    </button>
  );

  return (
    <Modal
      isOpen={isOpen}
      appElement={document.getElementById("root") as HTMLElement}
      className="h-screen sm:h-screen grid content-center"
      role="alert"
      style={{
        overlay: {
          inset: "0px",
          zIndex: 100,
          background: isDark
            ? theme.colors.modalOverlayDark
            : theme.colors.modalOverlay,
        },
      }}
    >
      <div
        data-cy="error-popup"
        className="mx-auto w-full max-w-[90vw] sm:max-w-[600px] sm:w-full max-h-[90vh] overflow-y-auto bg-white dark:bg-modalDark rounded-md grid border font-poppins relative"
      >
        <div className="px-6 py-2 sm:p-8 grid sm:block h-full sm:justify-start sm:gap-y-4 relative">
          <div className="relative sm:absolute right-0 top-0 justify-center grid">
            {jsxFigure}
          </div>
          <div className="grid gap-y-6 sm:w-[68%] h-[97%]">
            <div className="grid content-start gap-y-6 pb-4">
              <div className=" text-black text-[18px] sm:text-[24px] dark:text-white font-bold leading-8 ">
                <p className="m-0 p-0">{title ? t(title) : jsxTitle}</p>
              </div>

              <div className="text-black dark:text-white text-[12px] sm:text-[14px] font-normal leading-6 ">
                <p className="m-0 p-0">
                  {text ? t(text) : jsxContent}
                  {jsxButton ? null : <RequestID requestId={requestId} />}
                </p>
              </div>
            </div>
          </div>
          <div className="sticky bottom-6">
            {jsxButton ? jsxButton : jsxRegularButton}
          </div>
        </div>
      </div>
    </Modal>
  );
};

function RequestID({ requestId }: { requestId: string | undefined }) {
  if (!requestId) return null;
  return <div className="py-4">Request ID: {requestId}</div>;
}
export default ErrorPopup;
