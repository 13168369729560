import mixpanel from "mixpanel-browser";
import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { segmentAnalytics, useUser } from "../components/auth/auth";
import {
  fireEventAtom,
  sendWelcomeEmail,
  useUserData,
} from "../components/auth/data";
import { useThemeColor } from "../components/theme-color";
import { getQueryParams } from "../pages/PipelinePage/utils";
import {
  activeFeaturesSelector,
  anonymousePreNavigationAtom,
  experimentModalAtom,
  featuresAtom,
  forgotPasswordTokenAtom,
  loginFormAtom,
  registrationTokenAtom,
  savedParamsAtom,
  savedPathAtom,
  shouldShowExperimentAtom,
} from "./atoms";
import { getUserData, useEventLogger, UserEvent } from "./event_logger";
import { FeatureType, FormNameType } from "./interfaces";
import { useExperiment } from "./use-experiment";
import useMustLogin from "./use-must-login";

export default function useLocationParams() {
  const { user, loading: userLoading } = useUser();
  const { value, loading: userDataLoading, isReady } = useUserData();
  const location = useLocation();
  const { toggleDarkTheme, toggleLightTheme } = useThemeColor();
  const navigate = useNavigate();
  const { userEventLogger } = useEventLogger();
  const mustLogin = useMustLogin();
  const [searchParams] = useSearchParams();
  const { reachedLimit, isParticipating, delayModal, variant } =
    useExperiment();
  // Getters
  const anonymousePreNavigation = useRecoilValue(anonymousePreNavigationAtom);
  const loginForm = useRecoilValue(loginFormAtom);

  // Recoil states
  const [savedParams, setParams] = useRecoilState(savedParamsAtom);
  const [savedPath, setSavedPath] = useRecoilState(savedPathAtom);
  const [features, setFeatures] = useRecoilState(featuresAtom);
  const [fireEvent, setFireEvent] = useRecoilState(fireEventAtom);
  const [registrationToken, setRegistrationToken] = useRecoilState(
    registrationTokenAtom,
  );
  const [forgotPasswordToken, setForgotPasswordToken] = useRecoilState(
    forgotPasswordTokenAtom,
  );
  const activeFeaturesList = useRecoilValue<Array<FeatureType>>(
    activeFeaturesSelector,
  );
  const experimentModal = useRecoilValue(experimentModalAtom);

  const setExperiment = useSetRecoilState(shouldShowExperimentAtom);
  const completedSignup = value?.completed_signup;
  const loginPathname = location.pathname === "/login";

  React.useEffect(() => {
    if (user !== null) {
      if (
        savedParams &&
        !savedParams.includes("forgot_token") &&
        !savedParams.includes("verify_token")
      ) {
        navigate(savedParams);
        setParams("");
      } else if (loginPathname) {
        if (savedPath) {
          navigate(savedPath);
          setSavedPath("");
        }
      }
    }
    // eslint-disable-next-line
  }, [user]);

  function setThemePreferences() {
    if (value?.preferences?.studio_theme === "light") {
      toggleLightTheme();
    } else {
      toggleDarkTheme();
    }
  }

  React.useEffect(() => {
    if (value?.studio_key !== "" && isReady) {
      const date = new Date(Date.parse(user?.metadata?.creationTime));
      userEventLogger(UserEvent.PAGE_LOAD, {
        value: window?.location?.href,
        ...getUserData(value),
      });

      mixpanel.identify(value?.uid);
      segmentAnalytics.identify(value?.uid, {
        email: user?.email,
        name: user?.displayName,
        createdAt: date.toISOString(),
      });

      const eventsArr = ["Email Login", "Email Signup"];

      const fireSignupEvents = async () => {
        if (eventsArr.includes(fireEvent)) {
          if (fireEvent === eventsArr[0]) {
            userEventLogger(UserEvent.LOGIN, {
              sign_type: "Email",
              email: user?.email,
            });
          } else {
            userEventLogger(UserEvent.USER_CREATED, {
              email: user?.email,
            });
            userEventLogger(UserEvent.SIGNUP, {
              sign_type: "Email",
              email: user?.email,
            });
            userEventLogger(UserEvent.LOGIN, {
              sign_type: "Email",
              email: user?.email,
            });
            await sendWelcomeEmail(user);
          }

          setFireEvent(null);
        }
      };
      fireSignupEvents();
      setThemePreferences();

      // Send an event on daily basis
      const visitDate = localStorage.getItem("visitDate");
      const theme = localStorage.getItem("studioTheme");
      setThemePreferences();

      if (!visitDate && !theme) {
        userEventLogger(UserEvent.DAILY_VISIT, {
          value: true,
        });

        localStorage.setItem(
          "visitDate",
          new Date().toISOString().split("T")[0],
        );
      } else {
        if (visitDate !== new Date().toISOString().split("T")[0]) {
          userEventLogger(UserEvent.DAILY_VISIT, {
            value: false,
          });
          localStorage.setItem(
            "visitDate",
            new Date().toISOString().split("T")[0],
          );
        }
      }
    }
    // eslint-disable-next-line
  }, [isReady, value?.studio_key]);

  // Fire PAGE_LOAD event regardless of if the user was signed in or not
  React.useEffect(() => {
    if (!userDataLoading) {
      if (user) {
        userEventLogger(UserEvent.PAGE_LOAD, {
          value: window?.location?.href,
        });
      } else {
        userEventLogger(UserEvent.PAGE_LOAD, {
          value: window?.location?.href,
          uid: "empty",
          api_key: "empty",
        });
      }
    }

    //eslint-disable-next-line
  }, [userDataLoading]);

  React.useEffect(() => {
    if (
      value?.uid &&
      localStorage.getItem("experiment_variant") !== "true" &&
      variant !== "-1"
    ) {
      localStorage.setItem("experiment_variant", "true");
      userEventLogger(UserEvent.LATE_SIGNUP_EXPERIMENT, {
        value: {
          uid: value?.uid,
          variant,
        },
      });
    }
    //eslint-disable-next-line
  }, [userDataLoading, value, variant]);

  React.useEffect(() => {
    // Saves the path before user logs in to preserve the path after login
    const queryParams = getQueryParams(features);
    setFeatures(queryParams);
    setParams(location.pathname + location.search);
    if (location.pathname !== "/" && location.pathname !== "/login") {
      //setSavedPath(location.pathname + location.search);
    }
    // eslint-disable-next-line
  }, []);

  const signUpFeatureActivated =
    !completedSignup &&
    !userDataLoading &&
    !userLoading &&
    activeFeaturesList?.some(
      (feature: FeatureType) => feature.name === "signup-form",
    );

  const authFeatureActivated =
    !user &&
    !userLoading &&
    mustLogin.shouldShowLoginForm &&
    location.pathname !== "/docs";

  const anonymousTryOut = user && !userLoading && user?.isAnonymous;
  const anonymouseProtectedAPIKeyGeneration =
    anonymousePreNavigation && !userLoading && user?.isAnonymous;

  const tokenParam = searchParams.get("verify_token");
  const forgotTokenParam = searchParams.get("forgot_token");

  if (!user?.email) {
    if (tokenParam) {
      setRegistrationToken(tokenParam);
    } else if (forgotTokenParam) {
      setForgotPasswordToken(forgotTokenParam);
    } else {
      if (tokenParam || forgotTokenParam) {
        navigate("/");
      }
    }
  }

  let formToShow: FormNameType = "login";

  if (registrationToken) {
    formToShow = "verification-success";
  }
  if (forgotPasswordToken) {
    formToShow = "reset-password";
  }
  if (experimentModal) {
    formToShow = "experiment-modal";
  }

  const isLoginModalOpen =
    delayModal &&
    (signUpFeatureActivated ||
      authFeatureActivated ||
      (loginPathname && (user?.isAnonymous || !user)) ||
      anonymouseProtectedAPIKeyGeneration ||
      registrationToken ||
      forgotPasswordToken ||
      loginForm ||
      experimentModal ||
      (reachedLimit && isParticipating));

  React.useEffect(() => {
    if (reachedLimit && isParticipating && !experimentModal) {
      navigate("/login");
      setExperiment(true);
    }
    //eslint-disable-next-line
  }, [reachedLimit, isParticipating]);

  return {
    isLoginModalOpen,
    registrationToken,
    forgotPasswordToken,
    formToShow,
    anonymousTryOut,
    completedSignup,
    anonymouseProtectedAPIKeyGeneration,
  };
}
