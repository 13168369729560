import React from "react";
import * as runes from "runes";
import theme from "../../../../theme";
import { LabelsType, LabelType } from "../../types";
import FeedbackPopup from "./feedback";

export type ConvertLabelsType = {
  positionStart: number;
  positionEnd: number;
  type: string;
  skill: string;
  background: string;
  color: string;
  text: string;
  startAtBucket: number;
  level: number;
  data: object;
  value?: string;
  span_text?: string;
};

interface IBucket {
  start: number;
  end: number;
  labels: Array<ConvertLabelsType>;
  depth: number;
  bucketIndex: number;
  text: string;
}

const underlineWidth = 2;
const spaceBetweenUnderlines = 2;
const labelPaddingY = 4;
const lineHeight = "1";

export default function BucketsHighlighter({
  text,
  labels,
}: {
  text: string;
  labels: any;
}) {
  labels = convertLabelsToFormat(labels);
  const buckets = splitToBuckets(text, labels);

  if (labels === undefined) {
    return null;
  }

  // console.log(
  //   "[BucketsHighlighter]",
  //   "labels",
  //   labels,
  //   "buckets",
  //   buckets,
  //   JSON.stringify(text)
  // );

  return (
    <div className="font-medium dark:font-normal text-md md:text-sm">
      {buckets.length > 0
        ? buckets.map((bucket: IBucket) => {
            return (
              <React.Fragment key={bucket.bucketIndex}>
                <Bucket text={text} bucket={bucket} />
              </React.Fragment>
            );
          })
        : text}
    </div>
  );
}

function Bucket({ text, bucket }: { text: string; bucket: IBucket }) {
  return (
    <>
      {bucket.depth === 0 ? (
        <span
          className="leading-relaxed dark:text-white md:text-sm text-md text-darkGray font-medium dark:font-normal"
          key={bucket.bucketIndex}
        >
          {runes.substr(text, bucket.start, bucket.end - bucket.start)}
        </span>
      ) : (
        <HighlightedBucket text={text} bucket={bucket} />
      )}
    </>
  );
}

function HighlightedBucket({
  text,
  bucket,
}: {
  text: string;
  bucket: IBucket;
}) {
  let words: any = runes.substr(text, bucket.start, bucket.end - bucket.start);
  let splitted: any = words.split(/^(\n+\s+)/g);
  let newLine = null;
  if (splitted.length === 3) {
    newLine = splitted[1];
    words = splitted[2];
  }

  const [feedback, setFeedback] = React.useState<any>(null);

  return (
    <React.Fragment key={bucket.bucketIndex}>
      <span>{newLine}</span>
      <BucketLabels bucket={bucket} feedback={feedback} />
      <BucketText
        bucket={bucket}
        text={words}
        setFeedback={(data: any) => setFeedback(data)}
      />
    </React.Fragment>
  );
}

function BucketText({
  text,
  bucket,
  setFeedback,
}: {
  text: string;
  bucket: IBucket;
  setFeedback: any;
}) {
  // const words = text.substring(bucket.start, bucket.end).trim().split(" ");
  // const words = text.substring(bucket.start, bucket.end).split(" ");
  const words = text.split(" ");
  if (
    (words.length === 1 && (words[0] === "" || words[0] === " ")) ||
    (words.length === 2 && words[0] === "" && words[1] === "")
  ) {
    // console.log("@@@@@ cleared", words);
    return null;
  }

  return (
    <React.Fragment>
      {words.map((word: string, index: number) => {
        const newLineCount = (word.match(/\n/g) || []).length;
        if (newLineCount > 0) {
          const wordSplit = word.split("\n");
          // console.log("replaced", wordSplit);
          return (
            <React.Fragment key={`${bucket.bucketIndex}_${index}`}>
              {wordSplit.map((word, indexInner: number) => {
                if (word === "") {
                  return (
                    <React.Fragment
                      key={`${bucket.bucketIndex}_${index}_${indexInner}`}
                    >
                      {index !== 0 || indexInner !== 0 ? <br /> : null}
                    </React.Fragment>
                  );
                }
                return (
                  <React.Fragment
                    key={`${bucket.bucketIndex}_${index}_${indexInner}`}
                  >
                    <div className="inline-grid">
                      <span
                        style={{
                          // paddingTop: `${labelPaddingY + 2}px`,
                          height: "21px",
                          paddingTop: "4px",
                          lineHeight: `${lineHeight}rem`,
                        }}
                      >
                        <SpaceBefore word={word} />
                        {word}
                        {index === words.length - 1 ? " " : null}
                      </span>
                      <Underlines bucket={bucket} spaceToLines={2} />
                    </div>
                    {indexInner !== 0 && indexInner !== wordSplit.length - 1 ? (
                      <br />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        }
        if (word === " " || word === "") {
          if (index !== words.length - 1) return null;
        }
        return (
          <React.Fragment key={`${bucket.bucketIndex}_${index}`}>
            <div
              className="inline-grid font-medium cursor-pointer"
              onMouseOver={() => {
                setFeedback(bucket);
              }}
              onMouseLeave={() => {
                setFeedback(null);
              }}
            >
              <span
                style={{
                  // paddingTop: `${labelPaddingY}px`,
                  height: "21px",
                  paddingTop: "4px",
                  lineHeight: `${lineHeight}rem`,
                  fontWeight: theme.fontWeight.medium,
                }}
              >
                <SpaceBefore word={word} />
                {word}
              </span>
              <Underlines
                bucket={bucket}
                spaceToLines={2} // this is for fixing the 14px label to be in the middle of the 18px line height
              />
            </div>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
}

function SpaceBefore({ word }: { word: string }) {
  return <>{!!word.match(/^[.,:!?]/) ? null : " "}</>;
}

function Underlines({
  bucket,
  isLabels = false,
  labelIndex = -1,
  spaceToLines = 0,
}: {
  bucket: IBucket;
  isLabels?: boolean;
  labelIndex?: number;
  spaceToLines?: number | string;
}) {
  if (isLabels && labelIndex !== bucket.depth - 1) {
    return (
      <UnderlineShape bucket={bucket} isLabels={true} labelIndex={labelIndex} />
    );
  }
  return (
    <React.Fragment>
      {isLabels ? null : <span style={{ height: `${spaceToLines}px` }}></span>}
      {Array.from({ length: bucket.depth }, (x, i) => i).map(
        (depth: number) => {
          const labelIndexInDepth = findLabelIndexInDepth(bucket.labels, depth);
          const spacer = isLabels ? 0 : 4;
          const empty =
            labelIndexInDepth === -1 ||
            (isLabels && labelIndex <= labelIndexInDepth);
          return empty ? (
            <span
              key={depth}
              style={{
                height: `${spacer}px`,
              }}
            ></span>
          ) : (
            <span
              key={depth}
              style={{
                height: `${underlineWidth + spaceBetweenUnderlines}px`,
                borderBottom: `${underlineWidth}px solid ${bucket.labels[labelIndexInDepth].background}`,
                marginBottom: depth === bucket.depth - 1 ? 2 : 0,
              }}
            ></span>
          );
        },
      )}
    </React.Fragment>
  );
}

function UnderlineShape({
  bucket,
  isLabels = false,
  labelIndex = -1,
  spaceToLines = 0,
}: {
  bucket: IBucket;
  isLabels?: boolean;
  labelIndex?: number;
  spaceToLines?: number | string;
}) {
  if (bucket === undefined) {
    return null;
  }
  const curveBlockDepth = bucket.labels[labelIndex].level;
  return (
    <React.Fragment key={bucket.bucketIndex}>
      {bucket.labels[labelIndex].level !== 0 ? (
        <span className="grid grid-cols-2">
          <span></span>
          <span
            className={"rounded-bl"}
            style={{
              height: 4 * curveBlockDepth,
              borderBottom: `${underlineWidth}px solid ${bucket.labels[labelIndex].background}`,
              borderLeft: `${underlineWidth}px solid ${bucket.labels[labelIndex].background}`,
              marginBottom:
                bucket.labels[labelIndex].level === bucket.depth - 1 ? 2 : 0,
            }}
          ></span>
        </span>
      ) : null}

      {Array.from({ length: bucket.depth }, (x, i) => i).map(
        (depth: number) => {
          const labelIndexInDepth = findLabelIndexInDepth(bucket.labels, depth);
          if (
            bucket.labels[labelIndex].level !== 0 &&
            depth <= curveBlockDepth
          ) {
            return null;
          }
          if (labelIndexInDepth === -1 || labelIndex <= labelIndexInDepth) {
            return <span style={{ height: 2 }}></span>;
          }
          return (
            <span
              key={depth}
              style={{
                height: `${underlineWidth + spaceBetweenUnderlines}px`,
                borderBottom: `${underlineWidth}px solid ${bucket.labels[labelIndexInDepth].background}`,
                marginBottom: depth === bucket.depth - 1 ? 2 : 0,
              }}
            ></span>
          );
        },
      )}
    </React.Fragment>
  );
}

function BucketLabels({
  bucket,
  feedback,
}: {
  bucket: IBucket;
  feedback: any;
}) {
  let nulls = 0;
  return (
    <React.Fragment>
      {bucket.labels.map((label: ConvertLabelsType, index: number) => {
        if (bucket.bucketIndex !== label.startAtBucket) {
          nulls += 1;
          return null;
        }
        return (
          <FeedbackPopup
            key={index}
            data={{
              ...(label?.data && { data: label.data }),
              label: label.skill,
              value: bucket,
            }}
            labelOriginal={label}
            feedbackOpen={feedback}
          >
            <div className="inline-grid cursor-pointer">
              <span
                className={`pl-2 pr-1 font-poppins ${
                  index === nulls ? "rounded-tl rounded-bl " : ""
                } ${index === bucket.labels.length - 1 ? "rounded-tr" : ""}
                ${
                  bucket.labels[index].level !== 0 &&
                  index === bucket.labels.length - 1
                    ? "rounded-br"
                    : ""
                }
                ${
                  (label.skill === "anonymize" || label.skill === "enhance") &&
                  label.positionStart === label.positionEnd
                    ? "rounded-br"
                    : ""
                }
                `}
                style={{
                  letterSpacing: "0.125em",
                  height: "27px",
                  lineHeight: `${lineHeight}rem`,
                  backgroundColor: label.background,
                  color: label.color,
                  paddingTop: `${labelPaddingY}px`,
                }}
              >
                {label.skill === "anonymize" || label.skill === "enhance" ? (
                  <del
                    className="text-md md:text-[11px] font-medium"
                    style={{ lineHeight: `${lineHeight}rem` }}
                  >
                    {label?.text?.replaceAll?.("\n", "").toUpperCase()}
                  </del>
                ) : (
                  <span
                    className="text-md md:text-[11px] font-medium"
                    style={{ lineHeight: `${lineHeight}rem` }}
                  >
                    {label?.text?.replaceAll?.("\n", "").toUpperCase()}
                  </span>
                )}
              </span>
              <Underlines bucket={bucket} isLabels={true} labelIndex={index} />
            </div>
          </FeedbackPopup>
        );
      })}
    </React.Fragment>
  );
}

const findLabelIndexInDepth = (
  labels: Array<ConvertLabelsType>,
  depth: number,
) => {
  for (let index = 0; index < labels.length; index++) {
    if (labels[index].level === depth) {
      return index;
    }
  }
  return -1;
};

const getZeroWidthaLabelsPosition = (labels: Array<ConvertLabelsType>) => {
  return labels
    .filter((label) => label.positionStart === label.positionEnd)
    .map((label) => label.positionStart);
};

const computePivotPoints = (labels: Array<ConvertLabelsType>) => {
  let points = [];
  for (let i = 0; i < labels.length; i++) {
    points.push(labels[i].positionStart);
    points.push(labels[i].positionEnd);
  }
  points = points.filter(onlyUnique);
  points = [...points, ...getZeroWidthaLabelsPosition(labels)];
  points.sort((a, b) => a - b);
  return points;
};

const computeBuckets = (
  text: string,
  points: Array<number>,
): Array<IBucket> => {
  let buckets = [];
  for (let i = 0; i < points.length - 1; i++) {
    // Insert an empty bucket if the first label is not at the beginning of text
    if (i === 0) {
      if (points[0] !== 0) {
        buckets.push(newBucket({ start: 0, end: points[0] }));
      }
    }
    // Insert a bucket
    buckets.push(newBucket({ start: points[i], end: points[i + 1] }));
    // Insert an empty bucket at the end if the last label does not reach the end of text
    if (i === points.length - 2) {
      if (points[points.length - 1] !== text.length) {
        buckets.push(
          newBucket({ start: points[points.length - 1], end: text.length }),
        );
      }
    }
  }
  return buckets;
};

const insertLabelsToBucket = (
  labels: Array<ConvertLabelsType>,
  buckets: Array<IBucket>,
) => {
  for (const label of labels) {
    for (const bucket of buckets) {
      if (
        label.positionStart <= bucket.start &&
        label.positionEnd >= bucket.end
      ) {
        bucket.labels.push(label);
      }
    }
  }
};

const setLabelsLevels = (buckets: Array<IBucket>, text: string) => {
  let bucketIdx = 0;
  for (const bucket of buckets) {
    const depth = bucket.labels.length - 1;
    let labelIdx = 0;
    bucket.depth = bucket.labels.length;
    bucket.labels.sort(sortBucketLabels);
    for (const label of bucket.labels) {
      // Set the label bucket index
      if (label.startAtBucket === -1) {
        label.startAtBucket = bucketIdx;
      }
      // Set the label level
      const labelDepth = depth - labelIdx;
      if (labelDepth > label.level) {
        label.level = labelDepth;
      }
      // Set the bucket depth (It's not the same as the number of labels in the bucket,
      // it can be greater if there is a label from previos bucket)
      if (label.level > bucket.depth) {
        bucket.depth = label.level + 1;
      }
      labelIdx += 1;
    }
    // Set the bucket index
    bucket.bucketIndex = bucketIdx;
    bucketIdx += 1;
  }

  // the bucket depth needs to be adjusted for deeper buckets after it
  for (const bucket of buckets) {
    bucket.text = runes.substr(text, bucket.start, bucket.end - bucket.start);
    if (bucket.labels.length > 0) {
      bucket.depth = Math.max(bucket.labels.length, bucket.labels[0].level + 1);
    }
  }
};

const sortBucketLabels = (
  labelA: ConvertLabelsType,
  labelB: ConvertLabelsType,
) => {
  if (labelA.positionStart < labelB.positionStart) {
    return -1;
  }
  if (labelB.positionStart < labelA.positionStart) {
    return 1;
  }
  if (labelA.positionEnd < labelB.positionEnd) {
    return 1;
  }
  if (labelB.positionEnd < labelA.positionEnd) {
    return -1;
  }
  return 0;
};

const splitToBuckets = (text: string, labels: Array<ConvertLabelsType>) => {
  const points = computePivotPoints(labels);
  const buckets = computeBuckets(text, points);
  insertLabelsToBucket(labels, buckets);
  setLabelsLevels(buckets, text);

  return buckets;
};

const newBucket = ({ start, end }: { start: number; end: number }): IBucket => {
  return { start, end, labels: [], depth: 0, bucketIndex: -1, text: "" };
};

const onlyUnique = (value: number, index: number, self: any) => {
  return self.indexOf(value) === index;
};

const convertLabelsToFormat = (labels: LabelsType): ConvertLabelsType[] => {
  labels = labels?.filter((label) => label.span !== null);
  labels = labels?.filter((label) => label.span !== undefined);
  labels = labels?.filter((label) => label.skill !== "origin");
  labels = labels?.filter((label) => label.skill !== "clustering");
  return labels?.map((label: LabelType): ConvertLabelsType => {
    let labelContent = label?.skill ?? "";
    let background = theme.colors.pink;
    let color = "white";
    switch (label?.skill) {
      case "highlights":
        background = theme?.colors.cyan;
        break;
      case "business-entities":
        labelContent = label.name;
        break;
      case "entities":
        background = theme?.colors.rose;
        labelContent = label.name;
        break;
      case "html-extract-article":
        background = theme.colors.rose;
        labelContent = label.name;
        break;
      case "html-extract-text":
        background = theme?.colors.rose;
        labelContent = label.name;
        break;
      case "keywords":
        background = theme?.colors.purple;
        labelContent = "keyword";
        break;
      case "enhance":
        background = "#e3e3e3";
        color = "#555";
        // color = theme.colors.textLight;
        labelContent = label.value;
        break;
      case "anonymize":
        background = "#e3e3e3";
        color = "#555";
        // color = theme.colors.textLight;
        labelContent = label?.value;
        break;
      case "emotions":
        background = theme.colors.pink;
        labelContent = label?.name;
        break;
      case "sentences":
        background = theme.colors.orange;
        break;
      case "sales-insights":
        labelContent = label?.name;
        break;
      case "sentiments":
        labelContent = label?.value === "NEG" ? "Negative" : "Positive";
        break;
      case "names":
      case "numbers":
        background = theme?.colors.categoryCyan;
        color = "#555";
        labelContent = label?.name;
        break;
    }

    return {
      positionStart: label?.span && label?.span[0],
      positionEnd: label?.span && label?.span[1],
      skill: label?.skill || "",
      type: label?.type || "",
      background,
      color,
      text: labelContent,
      startAtBucket: -1,
      level: -1,
      data: label?.data,
      value: label?.value,
      span_text: label?.span_text,
    };
  });
};
