import { User } from "firebase/auth";

export interface PipelineStep {
  skill: string;
  params?: object;
}
export type CubeStateType =
  | "WAITING"
  | "UPLOADING"
  | "FINISHED"
  | "ERROR"
  | "EXTENSION_NOT_ALLOWED";

export type DocsTabsType = "Example Responses" | "Code Generator";

export type TabsValueType = "table" | "tree";

export type PipelineValuesType = "TABLE" | "CLUSTERING";

export type ClusterType = "DEMO" | "SELF";

export type FormNameType =
  | "none"
  | "login"
  | "signup"
  | "forgot-password"
  | "validate-email"
  | "verification-success"
  | "reset-password"
  | "experiment-modal";

export type scrollPositionsType = {
  name: string;
  position: number;
};

export type ProviderType = "Github" | "Google";
export interface APIKey {
  name?: string;
  account_id?: string;
  allowed_skills: Array<string>;
  rate_limit?: number;
  daily_quota?: number;
  token_id: string;
  type: string;
  state: APIKeyState;
  secret_head?: string;
  secret_key?: string;
}
export type APIKeyState = "ACTIVE" | "REVOKED";

export type FeatureType = {
  name: string;
  active: boolean;
};

export interface IOrganizationFetch {
  organizations: Array<IOrganization>;
  collectionLength: number;
}

export type DataSaveMethodType = "Regular" | "No Save" | "Anonymize";

export interface IOrganizationUsageData {
  monthly_quota: number;
  daily_quota: number;
  data_save_method: DataSaveMethodType;
}
export interface IOrganizationUsage {
  data: IOrganizationUsageData;
  daily_usage: number;
  monthly_usage: number;
}
export interface IOrganization {
  org_id: string;
  data_save_method: string;
  daily_quota: any;
  monthly_quota: any;
  billing: BillingType;
  uid: string;
  founder_email: string;
  name: string;
  status: string;
  usage?: any;
}

type BillingType = {
  customer_id: string;
  account_type: string;
  payment_methods: string[];
  limit_budget: number;
  billing_email: string;
  company_name: string;
  company_address: string;
};

export type UserFirebase = User;

export type SampleButtonType = {
  title: string;
  pipeline_id: string;
};

export type LibrarySampleType = {
  header?: string;
  description?: string;
  categories?: Array<string>;
  skills?: Array<string>;
  share_link?: string;
};

export const emptyUserFirebase = () => {
  return { uid: "", email: "", displayName: "" };
};

export type PipelineErrorType = {
  name: string | undefined;
  message: string | undefined;
  showError: boolean;
  requestId: string | undefined;
};
export interface UserData {
  role: string;
  uid: string;
  studio_key: string;
  org_ref: string;
  completed_signup: boolean;
  name: string;
  position: string;
  company: string;
  company_size: string;
  email: string;
  phone?: string;
  is_admin: boolean;
  preferences: {
    programming_language: string;
    studio_theme: string;
  };
  organization?: {
    name: string;
    uid: string;
  };
  experiment_count?: number;
}

export type PaymentMethod = any;
export type PaymentHistory = any;

export type OrganizationFirebaseDataType = {
  founder_email: string;
  name: string;
  status: string;
  uid: string;
};

export type ThumbsType = "NONE" | "THUMBS_UP" | "THUMBS_DOWN";

export type FeedbackDataType = {
  value: any;
  label: string;
  span_text?: string;
};

export type OrganizationUsageDataType = {
  monthly_quota: number;
  monthly_usage: {
    [key: string]: number;
  };
  daily_quota: number;
  daily_usage: {
    [key: string]: {
      count: number;
      date: string;
    };
  };
  daily_average: number;
};

export type UserOrganizationDataType = {
  organization: OrganizationFirebaseDataType;
  usage: OrganizationUsageDataType;
};

export const emptyOrganizationData = () => {
  return {
    organization: {
      founder_email: "",
      name: "",
      status: "",
      uid: "",
    },
    usage: {
      monthly_quota: 0,
      monthly_usage: {},
      daily_quota: 0,
      daily_usage: {},
      daily_average: 0,
    },
  };
};

export const emptyUserData = () => {
  return {
    uid: "",
    studio_key: "",
    name: "",
    position: "",
    company: "",
    org_ref: "",
    company_size: "",
    email: "",
    phone: "",
    completed_signup: false,
    is_admin: false,
    role: "User",
    preferences: {
      programming_language: "",
      studio_theme: "",
    },
  };
};

export const emptyAPIKey = () => {
  return {
    name: "",
    allowed_skills: [],
    rate_limit: 100,
    daily_quota: 100000,
    token_id: "",
    type: "",
    state: "ACTIVE" as APIKeyState,
  };
};

export const studioAPIKey = () => {
  return {
    name: "default key",
    allowed_skills: ["ALL"],
    token_id: "",
    type: "STUDIO",
  };
};

export type DNDColumnsType = {
  pipeline: {
    name: string;
    items: SkillsType[];
  };
  library: {
    name: string;
    items: SkillsType[];
  };
};

export type SkillsType = {
  name: string;
  id: number | string;
  creates_new_output?: boolean;
  value: string;
  category?: number;
  type?: string | number;
  is_labs?: boolean;
  short_name?: string;
  keywords?: string[];
  params?: {};
  defaultParams?: {};
  tooltip?: {
    extras?: {}[];
    title?: string;
    example?: string;
  };
  disallow_in_document?: boolean;
  disallow_in_dialog?: boolean;
};

export enum FinishType {
  RUN_PIPELINE = "RUN_PIPELINE",
  COPY_CODE = "COPY_CODE",
}

export type SkillCategoriesType = {
  category_name: string;
  id: number;
  color?: string;
};

export type CollectionsType = "MY_COLLECTIONS" | "SAMPLE_COLLECTIONS";

export type TableColumnType = "Skill" | "Label" | "Span" | "Value" | "Score";

export type RequestTypes = {
  requestBuilder: any;
  preview: boolean;
  apiKey: string;
  summaryOrigins: boolean;
  isAnonymous?: boolean;
};
