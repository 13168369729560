import { useTranslation } from "react-i18next";
import { IconDiscord } from "../assets/SvgIcons";
import AppTooltip from "./tooltip";
import { useEventLogger } from "../lib/event_logger";

export default function Discord() {
  const { t } = useTranslation();
  const { userUIEventLogger } = useEventLogger();

  return (
    <AppTooltip title={t<string>("Discord community")}>
      <a
        rel="noreferrer"
        href="https://discord.gg/cQGED7b2Ss"
        target={"_blank"}
        aria-label="OneAI discord link "
        tabIndex={0}
        className="mx-6 focus:rounded-md focus-visible:border-2 dark:focus-visible:border-white focus-visible:border-black"
      >
        <figure
          onClick={() =>
            userUIEventLogger("header__right_nav__discord_community")
          }
        >
          <IconDiscord width="27px" height="27px" />
        </figure>
      </a>
    </AppTooltip>
  );
}
