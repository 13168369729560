import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { IconCopy, IconGetOutput, IconTooltip } from "../../../assets/SvgIcons";
import AppTooltip from "../../../components/tooltip";
import Topics from "../../../components/topics";
import { useEventLogger, UserEvent } from "../../../lib/event_logger";
import { useIsMobile, useIsOnSide } from "../utils";
import Checkboxes from "./Checkboxes";
import HighlightText from "./HighlightText";
import "./styles.css";
import Tabs from "./tabs";
import Split from "react-split";
import { OutputLoader } from "../../../components/Loader";

const Output = ({
  treeData,
  text,
  labels,
  originLabels,
  setLabels,
  apiResponse,
  loader,
}: any) => {
  const { t, i18n } = useTranslation("index");
  const { userEventLogger } = useEventLogger();
  const [requestCopied, setRequestCopied] = React.useState<boolean>(false);
  const tabsRef = React.useRef<any>();
  const [tabsHeight, setTabsHeight] = React.useState<number>();

  const labelTypeExists = React.useMemo(() => {
    const isEmotionExists = labels?.some(
      (label: any) => label.skill === "emotions",
    );
    const isBusinessEntitiesExists = labels?.some(
      (label: any) => label.skill === "business-entities",
    );
    const isEntityExists = labels?.some(
      (label: any) => label.skill === "entities",
    );
    const isHtmlExtractArticleExists = labels?.some(
      (label: any) => label.skill === "html-extract-article",
    );
    const isHtmlExtractTextExists = labels?.some(
      (label: any) => label.skill === "html-extract-text",
    );
    const isHighlightExists = labels?.some(
      (label: any) => label.skill === "highlights",
    );
    const isKeywordExists = labels?.some(
      (label: any) => label.skill === "keywords",
    );
    const isEnhanceSkillExists = labels?.some(
      (label: any) => label.skill === "enhance",
    );
    const isSentenceExists = labels?.some(
      (label: any) => label.skill === "sentences",
    );
    const isTopicsExists = labels?.some(
      (label: any) => label.skill === "article-topics" && label.value !== "-1",
    );
    const onlyTopicsExists = labels?.every(
      (label: any) => label.skill === "article-topics" && label.value !== "-1",
    );
    const isSentimentExists = labels?.every(
      (label: any) => label.skill === "sentiments",
    );

    const isSalesInsightsExists = labels?.every(
      (label: any) => label.skill === "sales-insights",
    );

    const isTopicExistsWithOrWithoutOrigin = labels?.some(
      (label: any) =>
        (label.skill === "origin" && label.skill === "article-topics") ||
        label.skill === "article-topics",
    );

    const allExceptTopics = labels?.some(
      (label: any) =>
        label.skill === "sentiments" ||
        label.skill === "enhance" ||
        label.skill === "html-extract-article" ||
        label.skill === "html-extract-text" ||
        label.skill === "emotions" ||
        label.skill === "entities" ||
        label.skill === "highlights" ||
        label.skill === "keywords" ||
        label.skill === "sentences" ||
        label.skill === "sales-insights",
    );

    return {
      isEmotionExists,
      isBusinessEntitiesExists,
      isEntityExists,
      isHtmlExtractArticleExists,
      isHtmlExtractTextExists,
      isHighlightExists,
      isKeywordExists,
      isEnhanceSkillExists,
      isSentenceExists,
      isTopicsExists,
      onlyTopicsExists,
      isSentimentExists,
      isTopicExistsWithOrWithoutOrigin,
      allExceptTopics,
      isSalesInsightsExists,
    };
  }, [labels]);

  const highlightTextMemo = React.useMemo(() => {
    return (
      <HighlightText
        text={text}
        labels={labels ? labels : []}
        input_text={apiResponse.input_text}
        //hide_origin={hideOrigin}  // Uncomment this and remove next line when you want the output summary origin to be visible
        hide_origin={true}
      />
    );
  }, [labels, text, apiResponse.input_text]);

  const isTopicsAlone =
    !labelTypeExists.allExceptTopics &&
    labelTypeExists.isTopicExistsWithOrWithoutOrigin;

  const apiResponseExists = apiResponse?.status === "success";
  const phoneOnSide = useIsOnSide();

  const isMobile = useIsMobile();

  React.useEffect(() => {
    setTabsHeight(tabsRef?.current?.offsetHeight);
    //eslint-disable-next-line
  }, [tabsRef?.current]);

  const showOutputEmptyState = () => (
    <div
      data-cy={"output-empty-state"}
      className="bg-gray dark:bg-darkBackground rounded-md grid gap-y-2"
    >
      <Split
        className={`flex flex-col mt-0 overflow-x-hidden overflow-y-clip w-full h-[500px] md:h-[calc(100vh-185px)]`}
        gutterSize={2}
        direction="vertical"
        sizes={[50, 50]}
        snapOffset={0}
        onDragEnd={() => {
          setTabsHeight(tabsRef?.current?.offsetHeight);
        }}
      >
        <div
          className={`output-content grid grid-flow-row justify-center bg-white dark:bg-darkGrayBackground text-black dark:text-white overflow-y-auto rounded-md md:h-[calc(50vh-50px)] h-full`}
          dir="ltr"
        >
          <div
            className={` ${
              isMobile ? "grid justify-center items-center py-4" : "mt-[100px]"
            }`}
          >
            <figure className="grid justify-center items-center">
              <IconGetOutput
                className="text-shadeBlue"
                width="43"
                height="42"
              />
            </figure>
            <span className="grid justify-center items-center font-poppins font-semibold text-lg dark:text-white text-black mt-6 mb-3">
              {t("Get output")}
            </span>
            <span className="grid justify-center items-center dark:text-white text-black font-poppins font-normal leading-8  text-center">
              {t("Click Run Pipeline to see the results.")}
              <br />
            </span>
          </div>
        </div>
        <div className="md:h-[calc(50vh-111px)]  min-w-full m-0 p-0">
          <Tabs
            labels={labels}
            text={text}
            labelTypeExists={labelTypeExists}
            dataLabel={treeData}
          />
        </div>
      </Split>
    </div>
  );

  const onClose = () => {
    setTimeout(() => {
      setRequestCopied(false);
    }, 200);
  };

  return (
    <div
      className={`grid md:h-[calc(100vh-152px)] relative  ${
        loader === true ? "output-loader select-none" : ""
      }`}
    >
      <OutputLoader loader={loader} />
      {!apiResponseExists ? (
        showOutputEmptyState()
      ) : (
        <div
          className={`bg-gray dark:bg-darkBackground rounded-md grid overflow-none w-full`}
        >
          <Split
            className={`flex flex-col mt-0 overflow-x-hidden overflow-y-clip w-full h-[500px] md:h-[calc(100vh-185px)] `}
            gutterSize={4}
            direction="vertical"
            sizes={[50, 50]}
            snapOffset={0}
            onDragEnd={() => {
              setTabsHeight(tabsRef?.current?.offsetHeight);
            }}
          >
            <div
              className={`overflow-auto rounded-b-md grid grid-rows-auto-1fr-auto output-content bg-white dark:bg-darkGrayBackground text-black dark:text-white   ${
                i18n.dir() === "ltr" ? "rounded-tr-md" : "rounded-tl-md"
              }  ${
                isTopicsAlone
                  ? "md:h-[calc(50vh-118px)]"
                  : labelTypeExists.isTopicsExists
                  ? "md:h-[calc(50vh-147px)]"
                  : "md:h-[calc(50vh-114px)]"
              }`}
              dir="ltr"
            >
              <div
                className={`bg-white overflow-x-hidden middle-wrapper dark:bg-darkGrayBackground 
                ${!apiResponseExists ? "hidden" : ""} items-center`}
              >
                <div className={`px-2 py-2`}>
                  <Checkboxes
                    originLabels={originLabels}
                    labels={labels}
                    setLabels={setLabels}
                  />
                  <Topics labels={labels} />
                </div>
              </div>

              {!!labels?.length ? (
                <div className="px-3 py-3 w-[97%] font-normal font-poppins text-tableGrayBody dark:text-white overflow-y-auto leading-relaxed md:h-full ">
                  {highlightTextMemo}
                </div>
              ) : (
                <div className="px-3 py-3 w-[97%] font-normal font-poppins text-tableGrayBody dark:text-white overflow-y-auto leading-relaxed md:h-full ">
                  {text}
                </div>
              )}

              <div
                className={`border-t rounded-b-md border-borderLight dark:border-darkGrayBorder items-center flex justify-end ${
                  !apiResponse?.status ? "hidden" : ""
                }`}
              >
                <div
                  className={`py-1.5 px-2 hidden 
                ${phoneOnSide ? "md:hidden" : "md:block"}
                `}
                >
                  <CopyToClipboard
                    text={JSON.stringify(apiResponse.output, null, 2)}
                    onCopy={() => {
                      userEventLogger(UserEvent.COPY_RESPONSE);
                      setRequestCopied(true);
                    }}
                  >
                    <AppTooltip
                      title={
                        requestCopied ? (
                          <div className="grid grid-cols-auto-1fr gap-x-3 items-center">
                            <IconTooltip className={"text-blue"} />
                            <span>
                              {t("Response has been copied to clipboard")}
                            </span>
                          </div>
                        ) : (
                          t("")
                        )
                      }
                      arrow={!requestCopied}
                      leaveDelay={0}
                      onClose={onClose}
                    >
                      <button
                        className={`invisible md:visible items-center grid grid-cols-1fr-auto gap-x-2 border dark:border-turbo border-shadeBlue rounded-md py-2 px-4 dark:text-turbo text-shadeBlue text-sm font-mono`}
                      >
                        {t("Copy Response")}
                        <figure>
                          <IconCopy
                            className="dark:text-turbo text-shadeBlue"
                            height="17px"
                            width="18px"
                          />
                        </figure>
                      </button>
                    </AppTooltip>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div
              ref={tabsRef}
              className={`overflow-hidden m-0 p-0 min-w-full h-fit`}
              data-cy="output-table-section"
            >
              <Tabs
                labels={labels}
                text={text}
                labelTypeExists={labelTypeExists}
                dataLabel={treeData}
                tabsHeight={tabsHeight}
              />
            </div>
          </Split>
        </div>
      )}
    </div>
  );
};

export default React.memo(Output);
