import React from "react";
import { useUser } from "../components/auth/auth";
import { useUserData } from "../components/auth/data";
import { useIsMobile } from "../pages/PipelinePage/utils";

declare global {
  interface Window {
    google_optimize: any;
    ONEAI_EXPERIMENT_1477_LATE_SIGNUP: string;
  }
}

export const variantOne = "1";
export const variantTwo = "2";

export const useExperiment = () => {
  const [variant, setVariant] = React.useState<string>("-1");

  const [experimentLimit, setExperimentLimit] = React.useState<number>(-1);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { value: userData, loading: userDataLoading } = useUserData();
  const { user } = useUser();
  const isMobile = useIsMobile();
  const experimentId = process.env.REACT_APP_ONEAI_EXPERIMENT_1477_LATE_SIGNUP;

  React.useEffect(() => {
    if (!isMobile) {
      console.debug("[Experiment] Participating in experiment");
      (async () => {
        if (window.dataLayer) {
          console.debug(
            "[Experiment] Desktop - Optimize experiment triggering",
          );
          await window.dataLayer.push({ event: "optimize.activate" });
        }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            const variant = window.google_optimize.get(experimentId);
            window.google_optimize.get(experimentId);
            setVariant(variant);
            console.debug("[Experiment], Variant:", variant);
            clearInterval(intervalId);
          }
        }, 1000);
      })();
      return;
    } else {
      console.debug(
        "[Experiment] Not Participating in experiment (mobile detection)",
      );
    }
  }, [experimentId, isMobile]);

  React.useEffect(() => {
    if (variant !== undefined && variant !== "") {
      if (variant === variantTwo) {
        setExperimentLimit(
          Number(window?.ONEAI_EXPERIMENT_1477_LATE_SIGNUP) + 1,
        );
      } else if (variant === variantOne) {
        setExperimentLimit(Number(window?.ONEAI_EXPERIMENT_1477_LATE_SIGNUP));
      } else {
        setLoading(false);
      }
    }
  }, [variant]);

  React.useEffect(() => {
    if (experimentLimit !== undefined && experimentLimit !== -1) {
      setLoading(false);
    }
  }, [experimentLimit]);

  const isParticipating =
    (variant === variantTwo || variant === variantOne) && user?.isAnonymous;
  const experimentCount = userData?.experiment_count || 0;
  const isLateSignupVariant = variant === variantTwo || variant === variantOne;
  const reachedLimit =
    experimentLimit === -1
      ? false
      : (userData?.experiment_count || 0) > experimentLimit;
  const preventFutureCall =
    isParticipating && experimentCount >= experimentLimit;

  const almostReachedLimit = experimentCount === experimentLimit;

  const delayModal =
    isParticipating ||
    (!user && variant === "0") ||
    (!user && variant === undefined);

  return {
    variant,
    experimentLimit,
    experimentCount,
    isParticipating,
    isLateSignupVariant,
    reachedLimit,
    almostReachedLimit,
    preventFutureCall,
    loading: userDataLoading || loading,
    delayModal,
  };
};
