import "./docs-style.css";
import { IconMagnifyingGlass } from "../../assets/SvgIcons";

export default function APISearchEdit(props: any) {
  return (
    <>
      <label htmlFor="search" className="relative">
        <input
          type={"text"}
          placeholder="Search"
          autoComplete="none"
          className={`p-4 bg-white dark:bg-inputDarkGray border border-borderLight font-medium dark:border-darkGrayBorder rounded-[5px] text-black h-[54px] dark:text-white focus:outline-none w-full placeholder-formsgray text-[14px]`}
          {...props}
        />

        <figure className="absolute right-4 top-4 select-none">
          <IconMagnifyingGlass width="24px" height="24px" />
        </figure>
      </label>
    </>
  );
}
