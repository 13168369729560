import Menu from "@mui/material/Menu";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import i18n from "../i18n";
import { contentFileAtom } from "../lib/atoms";
import { useDraftData } from "../lib/use-studio-data";
import theme from "../theme";
import { LottieLoader } from "./Loader";
import { useThemeColor } from "./theme-color";

export default function AddSkillTypePopover({
  openedPopover = () => {},
  closedPopover = () => {},
}: {
  openedPopover?: Function;
  closedPopover?: Function;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation("content-management");
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <button
        onClick={handleClick}
        aria-controls={open ? "add-skill-type-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className="min-w-fit cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl"
      >
        {t("Add new type")}
      </button>

      <AddSkillTypePopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
    </React.Fragment>
  );
}

type IAddSkillTypePopup = {
  anchorEl: any;
  open: boolean;
  onClose: any;
};

const AddSkillTypePopup = React.forwardRef(
  (props: IAddSkillTypePopup, ref: any) => {
    const { anchorEl, open, onClose } = props;
    const [tipRight, setTipRight] = React.useState(0);
    const { isDark } = useThemeColor();
    const tipWidth = 18;
    React.useEffect(() => {
      if (anchorEl) {
        setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2);
      }
    }, [anchorEl]);
    return (
      <Menu
        anchorEl={anchorEl}
        id="add-skill-type-popover"
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: isDark
              ? theme.colors.darkBackground
              : theme.catskill,
            color: theme.colors.white,
            padding: theme.spacing[4],
            width: "280px !important",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.3,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "4px",
              right: tipRight,
              width: tipWidth,
              height: 18,
              backgroundColor: isDark
                ? theme.colors.darkBackground
                : theme.catskill,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              borderRadius: "4px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <AddSkillTypeForm onClose={onClose} />
      </Menu>
    );
  },
);

const AddSkillTypeForm = ({ onClose = () => {} }: { onClose: any }) => {
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState("FORM");
  const { t } = useTranslation("content-management");
  const [draft, setDraft] = useRecoilState(contentFileAtom);

  const { data } = useDraftData(draft);
  const clonedContent = cloneDeep(data);
  let biggerId = 0;
  clonedContent?.skill_categories.forEach((skill: any) => {
    if (Number(skill.id) > biggerId) biggerId = skill.id;
  });
  biggerId++;

  const onSubmit = async (values: any) => {
    try {
      const newSkillType = {
        category_name: values?.name,
        color: values?.color,
        id: biggerId,
      };
      clonedContent?.skill_categories?.push(newSkillType);
      setDraft(clonedContent);
      onClose();
    } catch (error) {
      setError("Could not edit this skill for some reason");
      console.log(error);
      setState("ERROR");
    }
  };
  if (error) {
    return <div>{t(error)}</div>;
  }

  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.name) errors.name = t("Please enter name!");
    if (!values.color) errors.color = t("Please enter color!");
    if (values.color[0] !== "#") errors.color = t("Please enter hex color!");

    return errors;
  };

  return (
    <div>
      {state === "LOADING" ? (
        <LottieLoader />
      ) : state === "SUCCESS" ? (
        <div>Success</div>
      ) : state === "ERROR" ? (
        <div>{error}</div>
      ) : (
        <Formik
          initialValues={{ name: "", color: "", id: biggerId }}
          onSubmit={onSubmit}
          validate={validate}
        >
          {() => (
            <Form className="grid grid-cols-1 gap-y-4 font-poppins pb-4 dark:text-white text-black">
              <p className="text-center">{t("Add skill type")}</p>
              <span className="font-normal text-center">
                {t("Make sure sure you know what you're doing!")}
              </span>
              <div className="grid grid-cols-1 gap-y-4">
                <div className="grid gap-y-1">
                  <label htmlFor="id" className="text-sm py-2">
                    {t("Type ID (auto):")}
                  </label>
                  <Field
                    disabled={true}
                    type="text"
                    name="id"
                    className="bg-transparent border border-borderLight dark:border-darkGrayBorder rounded p-3 w-full"
                  />
                </div>
                <div className="grid gap-y-1">
                  <label htmlFor="name" className="text-sm py-2">
                    {t("Type name:")}
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="bg-transparent border border-borderLight dark:border-darkGrayBorder rounded p-3 w-full"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red"
                  />
                </div>
                <div className="grid gap-y-1">
                  <label htmlFor="color" className="text-sm py-2">
                    {t("Type color(hex):")}
                  </label>
                  <Field
                    type="text"
                    name="color"
                    className="bg-transparent border border-borderLight dark:border-darkGrayBorder rounded p-3 w-full"
                  />
                  <ErrorMessage
                    name="color"
                    component="div"
                    className="text-red"
                  />
                </div>
              </div>
              <div
                dir={i18n.dir()}
                className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={onClose}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Add new type")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
