import { LabelType } from "../PipelinePage/types";
import { tableLabelValue, useLabelTypeExists } from "./pipeline-common";
import { formatSkillName } from "./pipeline-dnd";

const Table = ({ labels, text, tabsHeight }: any) => {
  const _labels = useLabelTypeExists(labels);

  if (labels) {
    labels = labels?.filter(
      (label: LabelType) =>
        label.skill !== "origin" &&
        label.skill !== "clustering" &&
        label.value !== "-1",
    );
  }

  return !!labels?.length ? (
    <div
      className="table min-w-full pl-4 pr-1"
      data-cy="output-table-with-labels"
    >
      <div
        className="overflow-y-auto preserve-scrollbar"
        style={{ height: `${tabsHeight - 90}px` }}
      >
        {labels?.map((label: any, index: number) => {
          return (
            <div
              className="flex text-tableGrayBody dark:text-outputTableValue font-poppins"
              key={index}
            >
              {/* Skill */}
              <OutputTableValue showCondition={true}>
                <span className="text-rose font-mono">
                  {formatSkillName(tableLabelValue(label, text, "Skill"))}
                </span>
              </OutputTableValue>
              {/* Label */}
              <OutputTableValue showCondition={true}>
                {tableLabelValue(label, text, "Label")}
              </OutputTableValue>
              {/* Span */}
              <OutputTableValue showCondition={_labels.showSpanColumn}>
                {tableLabelValue(label, text, "Span")}
              </OutputTableValue>
              {/* Score */}
              <OutputTableValue showCondition={_labels.showScoreColumn()}>
                {tableLabelValue(label, text, "Score")}
              </OutputTableValue>
              {/* Value */}
              <OutputTableValue showCondition={_labels.showValueColumn}>
                {tableLabelValue(label, text, "Value")}
              </OutputTableValue>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

type OutputTableValueProps = {
  children: React.ReactNode;
  className?: string;
  showCondition: boolean;
};

function OutputTableValue({
  children,
  className = "",
  showCondition,
}: OutputTableValueProps) {
  return (
    <div
      className={`flex-1 items-start md:text-sm font-medium dark:font-normal py-3
      ${className} 
      ${!showCondition ? "hidden" : ""}`}
    >
      {children}
    </div>
  );
}

export default Table;
