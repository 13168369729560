import { useEffect, useRef } from "react";

// I created this hook so we can skip the first render of the component
const useDidMountEffect = (func: Function, deps: any) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    //eslint-disable-next-line
  }, deps);
};

export default useDidMountEffect;
