import FeedbackPopup from "../pages/PipelinePage/Output/HighlightText/feedback";

type TopicsPropsType = { labels: [] };

export default function Topics({ labels }: TopicsPropsType) {
  const topics = labels?.filter(
    (label: any) => label?.skill === "article-topics" && label?.value !== "-1",
  );
  if (!topics || topics?.length === 0) {
    return null;
  }
  return (
    <div className="flex flex-row flex-wrap gap-x-4 pt-4 overflow-x-auto w-full overflow-y-hidden h-fit-content">
      {topics.map((label, index) => {
        return <Topic key={index} topic={label} />;
      })}
    </div>
  );
}

function Topic({ topic }: { topic: any }) {
  return (
    <FeedbackPopup
      data={{
        label: "topics",
        value: topic?.value,
      }}
    >
      <label
        className={`rounded-md items-center cursor-pointer whitespace-nowrap`}
      >
        <span
          className={`text-md self-center text-turbo tracking-tight font-mono font-bold uppercase`}
        >
          #{topic.value}
        </span>
      </label>
    </FeedbackPopup>
  );
}
