import { createTheme, ThemeProvider } from "@mui/material/styles";
import mixpanel from "mixpanel-browser";
import { QueryClient, QueryClientProvider } from "react-query";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { RecoilRoot, useRecoilValue } from "recoil";
import { useUser } from "./components/auth/auth";
import { LoginSignupListener, useUserData } from "./components/auth/data";
import BlurScreen from "./components/blur-screen";
import ClusteringDemo from "./components/clustering-demo";
import DocumentDirChanger from "./components/document-dir-changer";
import Layout from "./components/Layout/layout";
import Page404 from "./components/page404";
import ShadowUser from "./components/shadow-user";
import StoreUTMCookies from "./components/store-utm-cookies";
import { anonymousLoginAtom, AtomManager } from "./lib/atoms";
import Analytics from "./pages/analytics";
import DocsPage from "./pages/docs-page";
import HowToVideos from "./pages/how-to-videos";
import { PipelinePage } from "./pages/pipeline-page";
import { PipelineInputPanelMobile } from "./pages/pipeline/pipeline-input-panel";
import PipelineOutputPanel from "./pages/pipeline/pipeline-output-panel";
import PipelineSkillsPanel from "./pages/pipeline/pipeline-skills-panel";
import { default as PipelinePageOld } from "./pages/PipelinePage/";
import SettingsPage from "./pages/settings-page";
import APIKeys from "./pages/settings/api-keys";
import Billing from "./pages/settings/billing";
import ContentManagement from "./pages/settings/content-management";
import ManageAccounts from "./pages/settings/manage-accounts";
import ManageAdmins from "./pages/settings/manage-admins";
import UsageChart from "./pages/settings/usage-chart/usage-chart";
import Utilities from "./pages/settings/utilities";
import theme from "./theme";

type ProtectedRouteType = {
  children: JSX.Element;
};

const ProtectedRoute = ({ children }: ProtectedRouteType) => {
  const anonymousLogin = useRecoilValue(anonymousLoginAtom);

  const protectedAnonymouseRoutes = [
    "/",
    "/login",
    "/content-library",
    "/pipeline-input",
    "/pipeline-output",
    "/pipeline-skills",
  ];

  const location = useLocation();
  const { user, loading } = useUser();

  if (!loading) {
    if (anonymousLogin) return children;
    if (!user) {
      return <Navigate to="/login" replace />;
    }
    if (
      user &&
      user?.isAnonymous &&
      !protectedAnonymouseRoutes.includes(location.pathname)
    ) {
      return <Navigate to="/login" replace />;
    } else {
      return children;
    }
  } else {
    return children;
  }
};

const LoggedInCheck = ({ children }: ProtectedRouteType) => {
  const { user, loading } = useUser();
  if (!loading && user && !user?.isAnonymous) {
    return <Navigate to="/" replace />;
  } else {
    return children;
  }
};

const AdminRoute = ({ children }: ProtectedRouteType) => {
  const { isAdmin, isReady } = useUserData();

  if (!isReady) return <></>;
  else if (isAdmin) {
    return children;
  } else {
    return <Navigate to="/unauthorized" replace />;
  }
};

export const queryClient = new QueryClient();

function App() {
  if (!process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
    console.error(
      "Please set the environment variable REACT_APP_MIXPANEL_PROJECT_TOKEN",
    );

  // Initialize Mixpanel analytics
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || "", {
    ignore_dnt: true,
  });

  const muiTheme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Poppins",
        font: "Poppins",
        fontWeight: theme.fontWeight.medium,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
      },
    },
  });

  return (
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryClient}>
        <div className="bg-catskill dark:bg-darkBackground h-full">
          <RecoilRoot>
            <DocumentDirChanger />
            <AtomManager />
            <ShadowUser />
            <BlurScreen />
            <AppRoutes />
            <LoginSignupListener />
            <StoreUTMCookies />
          </RecoilRoot>
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <PipelinePage />
          </ProtectedRoute>
        }
      >
        {["", "pipeline-input"].map((path: string, index: number) => {
          return (
            <Route
              key={index}
              path={path}
              element={
                <div className="h-full overflow-y-hidden">
                  <div className="md:hidden block h-full">
                    <PipelineInputPanelMobile />
                  </div>
                  <div className="hidden md:block h-full">
                    <PipelineSkillsPanel />
                  </div>
                </div>
              }
            />
          );
        })}

        <Route path="pipeline-skills" element={<PipelineSkillsPanel />} />
        <Route path="pipeline-output" element={<PipelineOutputPanel />} />
      </Route>
      <Route path="/old-pipeline" element={<PipelinePageOld />} />
      <Route path="/how-to-videos" element={<HowToVideos />}>
        <Route path=":title" element={<HowToVideos />} />
      </Route>

      <Route
        path="/content-library"
        element={
          <ProtectedRoute>
            <PipelinePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        }
      >
        <Route
          path="api-keys"
          element={
            <ProtectedRoute>
              <APIKeys />
            </ProtectedRoute>
          }
        />
        <Route
          path="utilities"
          element={
            <AdminRoute>
              <Utilities />
            </AdminRoute>
          }
        />

        <Route
          path="content-management"
          element={
            <AdminRoute>
              <ContentManagement />
            </AdminRoute>
          }
        />
        <Route
          path="usage-chart"
          element={
            <ProtectedRoute>
              <UsageChart />
            </ProtectedRoute>
          }
        />
        <Route
          path="billing"
          element={
            <ProtectedRoute>
              <Billing />
            </ProtectedRoute>
          }
        />
        <Route
          path="manage-accounts"
          element={
            <AdminRoute>
              <ManageAccounts />
            </AdminRoute>
          }
        />
        <Route
          path="manage-admins"
          element={
            <AdminRoute>
              <ManageAdmins />
            </AdminRoute>
          }
        />
      </Route>
      <Route
        path="clustering-demo"
        element={
          <Layout headerRightRender={<></>} loader={false}>
            <ClusteringDemo />
          </Layout>
        }
      />
      <Route
        path="docs"
        element={
          <Layout headerRightRender={<></>} loader={false}>
            <DocsPage />
          </Layout>
        }
      />

      <Route
        path="/login"
        element={
          <LoggedInCheck>
            <PipelinePage />
          </LoggedInCheck>
        }
      >
        <Route
          key={0}
          path={""}
          element={
            <div className="h-full overflow-y-hidden">
              <div className="md:hidden block h-full">
                <PipelineInputPanelMobile />
              </div>
              <div className="hidden md:block h-full">
                <PipelineSkillsPanel />
              </div>
            </div>
          }
        />
      </Route>
      <Route path="/insights" element={<Analytics />} />
      <Route path="/insights/:collection/:title" element={<Analytics />} />
      <Route path="/insights/:collection" element={<Analytics />} />

      <Route
        path="*"
        element={
          <Layout headerRightRender={<></>} loader={false}>
            <Page404 />
          </Layout>
        }
      />
    </Routes>
  );
};
export default App;
