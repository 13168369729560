import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import Split from "react-split-grid";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  IconRunPipeline,
  IconSharePipeline,
  IconTooltip,
} from "../assets/SvgIcons";
import { useUserData } from "../components/auth/data";
import ErrorPopup from "../components/error-popup";
import Layout from "../components/Layout/layout";
import { OutputLoader } from "../components/Loader";
import AppTooltip from "../components/tooltip";
import { loaderAtom } from "../lib/atoms";
import useRunPipeline, { usePipelineErrors } from "../lib/use-run-pipeline";
import {
  useSharedPipelineListener,
  useSharePipeline,
} from "../lib/use-share-pipeline";
import { pipelineErrorAtom } from "./pipeline/pipeline-atoms";
import PipelineInputPanel from "./pipeline/pipeline-input-panel";
import { PipelineMobileNav } from "./pipeline/pipeline-mobile";
import { ResizeGutterCol } from "./pipeline/resize-gutter";
import { useIsMobile } from "./PipelinePage/utils";

function SharedPipelineListener() {
  const { runSharedPipeline } = useSharedPipelineListener();
  const { value } = useUserData();
  const location = useLocation();

  React.useEffect(() => {
    runSharedPipeline();
    //eslint-disable-next-line
  }, [location, value?.studio_key]);
  return null;
}

export function PipelinePage() {
  const loader = useRecoilValue(loaderAtom);
  const isMobile = useIsMobile();
  return (
    <Layout headerRightRender={<PipelinePageTopButtons />} loader={loader}>
      <PipelineErrorPopup />
      <SharedPipelineListener />
      {!isMobile ? (
        <div className="hidden md:block h-full">
          <Pipeline />
        </div>
      ) : (
        <div className="md:hidden block h-full">
          <PipelineMobile />
        </div>
      )}
    </Layout>
  );
}

function PipelineTooltip({ children }: any) {
  const hasErrors = usePipelineErrors();

  return (
    <AppTooltip
      arrow={!hasErrors()}
      title={
        hasErrors().length ? (
          <>
            {hasErrors().map((alert: any, index: number) => (
              <div
                key={index}
                className="grid grid-cols-auto-1fr gap-x-3 items-center"
              >
                <IconTooltip className={"text-blue"} />
                {alert}
              </div>
            ))}
          </>
        ) : (
          ""
        )
      }
    >
      {children}
    </AppTooltip>
  );
}

function RunPipelineButton() {
  const { runPipeline } = useRunPipeline();
  const hasErrors = usePipelineErrors();
  const hasErrorsLength = hasErrors().length;
  const { t, i18n } = useTranslation("index");
  return (
    <button
      id="run_pipeline_button"
      className={`cursor-pointer text-center transform-none m-0 items-center py-[0.6rem] px-5 whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-extrabold tracking-tight 
      ${
        hasErrorsLength
          ? "dark:bg-charadea dark:text-topaz bg-linkWater text-pigeonPost"
          : "bg-turbo text-verdunGreen hover:bg-opacity-80"
      } 
      rounded-md shadow-xl dark:shadow-none`}
      onClick={runPipeline}
    >
      {t("Run Pipeline")}
      <figure>
        <IconRunPipeline
          width="19"
          height="19"
          className={`
              ${i18n.dir() === "rtl" ? "transform rotate-180" : ""}
              ${
                hasErrorsLength
                  ? "text-pigeonPost dark:text-topaz"
                  : "text-verdunGreen"
              }`}
        />
      </figure>
    </button>
  );
}
function SharePipelineButton() {
  const hasErrors = usePipelineErrors();
  const {
    sharePipeline,
    sharePipelineButtonLoading,
    sharePipelineMessage,
    clickedSharePipeline,
    onClose,
  } = useSharePipeline();
  const { t, i18n } = useTranslation("index");

  const hasPipelineErrors = hasErrors().length;
  return (
    <AppTooltip
      title={
        clickedSharePipeline ? (
          <div className="grid grid-cols-auto-1fr gap-x-3 items-center">
            <IconTooltip className={"text-blue"} />
            <span>{t(sharePipelineMessage)}</span>
          </div>
        ) : (
          ""
        )
      }
      open={clickedSharePipeline}
      onClose={onClose}
    >
      <button
        id="share_pipeline_button"
        onClick={sharePipeline}
        className={`cursor-pointer share-button text-center transform-none  m-0 items-center py-[0.6rem] px-5 whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight rounded-md border-0 shadow-xl dark:shadow-none ${
          hasPipelineErrors
            ? "dark:bg-charadea dark:text-topaz bg-linkWater text-pigeonPost"
            : "bg-blue text-white  hover:bg-opacity-80"
        }`}
      >
        {/* {t("Share Pipeline")} */}
        <span className="font-semibold hidden lg:block">
          {t("Share Pipeline")}
        </span>
        <span className="font-semibold lg:hidden">{t("Share")}</span>

        {sharePipelineButtonLoading ? (
          <Box>
            <CircularProgress
              style={{
                width: 15,
                height: 15,
                color: "white",
                padding: 0,
                marginLeft: 4,
              }}
            />
          </Box>
        ) : (
          <IconSharePipeline
            width="19"
            height="19"
            className={` ${
              i18n.dir() === "rtl" ? "transform rotate-180" : ""
            } ${
              hasPipelineErrors
                ? "text-pigeonPost dark:text-topaz"
                : "text-white"
            }`}
          />
        )}
      </button>
    </AppTooltip>
  );
}
function PipelinePageTopButtons() {
  return (
    <PipelineTooltip>
      <div className="grid grid-cols-2-auto gap-x-4 px-4 md:h-auto">
        <RunPipelineButton />
        <SharePipelineButton />
      </div>
    </PipelineTooltip>
  );
}

function Pipeline() {
  return (
    <Split minSize={100} columnMinSize={350} cursor="col-resize">
      {({ getGridProps, getGutterProps }: any) => (
        <div
          className="pb-4 w-full h-full grid grid-cols-pipeline-skills-panel"
          {...getGridProps()}
        >
          <PipelineInputPanel />
          <ResizeGutterCol getGutterProps={getGutterProps} />
          <PipelineSkillsAndOutput />
        </div>
      )}
    </Split>
  );
}

function PipelineSkillsAndOutput() {
  return (
    <>
      <Outlet />
    </>
  );
}

function PipelineErrorPopup() {
  const [pipelineError, setPipelineError] = useRecoilState(pipelineErrorAtom);

  const setIsOpen = (isOpen: boolean) => {
    setPipelineError({
      name: undefined,
      message: undefined,
      showError: isOpen,
      requestId: undefined,
    });
  };

  return (
    <ErrorPopup
      isOpen={pipelineError.showError}
      setIsOpen={setIsOpen}
      title={pipelineError?.name}
      text={pipelineError?.message}
      requestId={pipelineError?.requestId}
    />
  );
}
function PipelineMobile() {
  const loader = useRecoilValue(loaderAtom);

  return (
    <div
      className={`w-full h-full grid grid-cols-1 grid-rows-auto-1fr ${
        loader ? "output-loader" : ""
      }`}
    >
      <OutputLoader loader={loader} />
      <PipelineMobileNav />
      <Outlet />
      {/* <PipelineMobileFooter /> */}
    </div>
  );
}
