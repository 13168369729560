import Menu from "@mui/material/Menu";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { IconPlusThin } from "../assets/SvgIcons";
import i18n from "../i18n";
import theme from "../theme";
import { emailEvent, useCreateKey, useUserOrganizationKeys } from "./auth/data";
import { LottieLoader } from "./Loader";
import { useUser } from "./auth/auth";

export default function AddKeyPopver({
  openedPopover = () => {},
  closedPopover = () => {},
  buttonText,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  buttonText: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <button
        onClick={handleClick}
        className="cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl"
        data-cy="add-api-key-button"
      >
        <span>{buttonText}</span>
        <figure>
          <IconPlusThin
            className={` ${i18n.dir() === "rtl" ? "transform rotate-180" : ""}`}
          />
        </figure>
      </button>

      <AddKeyPopup anchorEl={anchorEl} open={open} onClose={handleClose} />
    </React.Fragment>
  );
}

type IAddKeyPopup = {
  anchorEl: any;
  open: boolean;
  onClose: any;
};

const AddKeyPopup = React.forwardRef((props: IAddKeyPopup, ref: any) => {
  const { anchorEl, open, onClose } = props;
  return (
    <Menu
      anchorEl={anchorEl}
      id="add-skill-order"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          backgroundColor: theme.colors.lightBlack,
          color: theme.colors.white,
          padding: theme.spacing[4],
          width: "280px !important",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <AddKey onClose={onClose} open={open} />
    </Menu>
  );
});

const AddKey = ({ onClose = () => {}, open }: { onClose: any; open: any }) => {
  const [state, setState] = React.useState("LOADING");
  const [error, setError] = React.useState("");
  const [key, setKey] = React.useState<any>(null);
  const [keyCopied, setKeyCopied] = React.useState<boolean>(false);
  const { api_keys } = useUserOrganizationKeys();
  const { t } = useTranslation("content-management");
  const { createKey } = useCreateKey();
  const { user } = useUser();

  const handleCopyKey = () => {
    if (!keyCopied) {
      setKeyCopied(true);
      setTimeout(() => {
        setKeyCopied(false);
      }, 6000);
    }
  };

  const onSendNewKeyEmail = async (secretKey: string) => {
    if (user.email.includes("@oneai.com")) return;
    const secretHead =
      secretKey.substring(0, 3) +
      "..." +
      secretKey.substring(secretKey.length - 3, secretKey.length);

    let title = "";
    const body = `key: ${secretHead} \nLimit: 100\nSkills: All`;

    if (api_keys?.length === 3) {
      title = `User ${user?.email} created a non-default key`;
    } else {
      title = `User ${user?.email} created a non-default key [number #${api_keys?.length}]`;
    }
    await emailEvent({ title, body });
  };

  React.useEffect(() => {
    if (open) {
      const handleKeyCreation = async () => {
        const keyData = await createKey({
          token_id: "",
          allowed_skills: ["ALL"],
          rate_limit: 100,
          type: "USER_CREATED",
          state: "ACTIVE",
        });
        if (keyData?.key) {
          setKey(keyData);
          onSendNewKeyEmail(keyData?.secretKey);
          setState("SUCCESS");
          console.log(keyData);
        } else {
          setState("ERROR");
          setError("There was an error creating the key");
        }
      };

      handleKeyCreation();
    }
    //eslint-disable-next-line
  }, [open]);

  if (state === "LOADING") {
    return (
      <div>
        <LottieLoader />
      </div>
    );
  } else if (state === "ERROR") {
    return <div>{error}</div>;
  }
  return (
    <div
      role="alert"
      className="text-white text-center grid"
      data-cy="api-key-success"
    >
      <h2 className="text-white">{t("Here is your api key")}</h2>
      <b className="text-yellow">{key?.key?.name || "No name"}</b>

      <span className="py-4">{key?.secretKey}</span>
      <CopyToClipboard text={key?.secretKey} onCopy={() => handleCopyKey()}>
        <button
          disabled={keyCopied}
          className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5  whitespace-nowrap text-xs md:text-xs grid  gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-70 ${
            keyCopied ? " border  text-white" : "bg-shadeBlue text-white"
          } rounded-md mt-2`}
        >
          {keyCopied ? (
            <span role="alert">{t("Key copied to clipboard!")}</span>
          ) : (
            <span>{t("Copy to clipboard")}</span>
          )}
        </button>
      </CopyToClipboard>
    </div>
  );
};
