import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import googleLogo from "../../assets/google.png";
import logo from "../../assets/logo-forms.svg";
import darkModeLogo from "../../assets/logo-gray.svg";
import { IconGithub } from "../../assets/SvgIcons";
import { experimentFinishTypeAtom } from "../../lib/atoms";
import { useEventLogger } from "../../lib/event_logger";
import { FinishType, FormNameType, ProviderType } from "../../lib/interfaces";
import { useExperiment } from "../../lib/use-experiment";
import { useThemeColor } from "../theme-color";
import CredentialForm from "./credential-form";
import { useAuth, useUserData } from "./data";
type FormType = {
  setForm: Dispatch<FormNameType>;
  setIsOpen: (value: boolean) => void;
};

export default function LoginForm({ setForm, setIsOpen }: FormType) {
  const { t, i18n } = useTranslation("index");
  const { loginSignup } = useAuth();
  const { isDark } = useThemeColor();
  const { userUIEventLogger } = useEventLogger();
  const { value: userData } = useUserData();
  const loginSignupHandler = async (provider: ProviderType) => {
    const value = `app__login_form__${provider.toLowerCase()}_login`;
    userUIEventLogger(value);
    await loginSignup(provider);
  };

  return (
    <div className="w-[279px]">
      <div className="grid justify-center pt-6">
        <figure className="image w-[165px] grid justify-center py-8">
          <img src={isDark ? darkModeLogo : logo} alt="Logo" />
        </figure>
      </div>

      <div className="grid gap-y-2 w-full overflow-x-hidden overflow-y-auto">
        <SignInToGenerateAPIKeyText />
        <div className="grid gap-y-7">
          <button
            className="border border-oceanBlue rounded-md px-4 py-2 m-auto text-sm gap-x-2  min-w-full h-[49px] pt-2"
            onClick={() => loginSignupHandler("Github")}
          >
            <span
              className={`font-roboto dark:text-white text-black focus:outline-none ${
                i18n.dir() === "rtl" ? "float-right" : "float-left"
              } mt-0.5`}
            >
              {t("Continue with Github")}
            </span>
            <figure
              className={`${
                i18n.dir() === "rtl" ? "float-left" : "float-right"
              }`}
            >
              <IconGithub />
            </figure>
          </button>
          <button
            className="border border-cyan rounded-md px-4 py-2 m-auto text-sm gap-x-2  min-w-full h-[49px]"
            onClick={() => loginSignupHandler("Google")}
          >
            <span
              className={`font-roboto dark:text-white text-black ${
                i18n.dir() === "rtl" ? "float-right" : "float-left"
              } mt-0.5`}
            >
              {t("Continue with Google")}
            </span>
            <figure
              className={`${
                i18n.dir() === "rtl" ? "float-left" : "float-right"
              }`}
            >
              <img src={googleLogo} alt="google logo" />
            </figure>
          </button>
        </div>
        <p className="text-formsgray grid justify-center font-poppins font-normal">
          {t("or")}
        </p>
        <CredentialForm type="LOGIN" setForm={setForm} />
        <div className="grid justify-center py-3 pt-10">
          <div className="text-black dark:text-white text-sm font-poppins font-normal">
            {t("Don't have an account?")}
            <button
              className="text-shadeBlue px-2 font-mono font-bold justify-self-center"
              onClick={() => {
                setForm("signup");
                const value = `app__login_form__sign_up`;
                userUIEventLogger(value, {
                  uid: userData?.uid || "empty",
                  api_key: userData?.studio_key || "empty",
                });
              }}
            >
              {t("Sign Up")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function SignInToGenerateAPIKeyText() {
  const { isParticipating, reachedLimit } = useExperiment();
  const finishType = useRecoilValue(experimentFinishTypeAtom);

  const textToShow =
    finishType === FinishType.COPY_CODE
      ? "Please sign in to generate an API key"
      : "To run this pipeline please sign in";

  if (!isParticipating || !reachedLimit) return null;
  return (
    <p className="p-0 m-0 dark:text-white text-black font-poppins text-center text-sm">
      {textToShow}
    </p>
  );
}
