import { cloneDeep, flatMap } from "lodash";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { CloseIcon, IconThreeArrows } from "../../../assets/SvgIcons";
import Button from "../../../components/button";
import {
  cubeFileAtom,
  cubeStateAtom,
  fileContentAtom,
  inputResponseAtom,
  inputTypeAtom,
  textAreaAtom,
} from "../../../lib/atoms";
import { useEventLogger, UserEvent } from "../../../lib/event_logger";
import { CubeStateType, SampleButtonType } from "../../../lib/interfaces";
import settingsFile from "../../../utils/settings.json";
import {
  lastSharedPipelineAtom,
  pipelineSkillsAtom,
  skillsLibraryAtom,
} from "../../pipeline/pipeline-atoms";
import { DropAreaBorder } from "../../pipeline/pipeline-dnd";
import { getContentType, inputSelectionTypes } from "../utils";
import InputDeterminator from "./input-determinator";
import TextArea from "./textarea";

type FileUploaderProps = {
  inputContainer: any;
};

export default function FileUploader({ inputContainer }: FileUploaderProps) {
  // States & Refs & hooks
  //  const [progress, setProgress] = React.useState<number>(0);
  const [cubeFile, setCubeFile] = useRecoilState<string>(cubeFileAtom);
  const [cubeState, setCubeState] =
    useRecoilState<CubeStateType>(cubeStateAtom);
  const setTextarea = useSetRecoilState(textAreaAtom);
  const setFileContent = useSetRecoilState(fileContentAtom);
  const setInputResponse = useSetRecoilState(inputResponseAtom);
  const navigate = useNavigate();
  const { t } = useTranslation("file-uploader");
  const inputResponse = useRecoilValue(inputResponseAtom);
  const { userEventLogger } = useEventLogger();
  const [searchParams, setSearchParams] = useSearchParams();
  const setLastSharedPipeline = useSetRecoilState(lastSharedPipelineAtom);
  const setInputType = useSetRecoilState(inputTypeAtom);
  const [pipelineSkills, setPipelineSkills] =
    useRecoilState(pipelineSkillsAtom);
  const skillsLibrary = useRecoilValue(skillsLibraryAtom);

  // React dropZone
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    onDrop: async (acceptedFiles) => onDrop(acceptedFiles),
    multiple: false,
    accept: {
      "text/plain": [".txt"],
      "application/json": [".json"],
      "audio/aac": [".aac"],
      "application/x-cdf": [".cdf"],
      "audio/mpeg": [".mp3"],
      "audio/ogg": [".ogg"],
      "audio/wav": [".wav"],
      "audio/3gpp": [".3gp"],
    },
    preventDropOnDocument: false,
  });

  // onDrop - a callback invoked when a file is dropped on the dropzone
  const readFileAsync = async (
    file: File,
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const sendEvents = (file: string) => {
    userEventLogger(UserEvent.FILE_UPLOAD, {
      value: file,
    });
    userEventLogger(UserEvent.INPUT_CONTENT, {
      value: "FILE_UPLOAD",
    });
  };

  const onDrop = async (acceptedFiles: any) => {
    setInputResponse(false);
    if (acceptedFiles.length > 0) {
      try {
        const fileContent: any = await readFileAsync(acceptedFiles[0]);

        const audioSkill = flatMap(skillsLibrary.skills).find((skill) => {
          return skill.name === "Transcribe Audio";
        });
        console.log("Audio skill : :", audioSkill);
        if (fileContent) {
          setCubeState("FINISHED");
          setCubeFile(acceptedFiles[0]?.name);
          sendEvents(acceptedFiles[0]?.type);

          if (
            getContentType(
              getFileExtension(acceptedFiles[0]?.name),
              "data:",
              !!fileContent,
            ) === "audio/wav"
          ) {
            setInputType(inputSelectionTypes.conversation);
            addTranscribeSkill(
              pipelineSkills,
              setPipelineSkills,
              skillsLibrary,
            );
          }
          setFileContent(fileContent);
        }
      } catch (e) {
        console.error("[onDrop] Error: ", e);
        setCubeState("EXTENSION_NOT_ALLOWED");
      }
    } else {
      setCubeState("EXTENSION_NOT_ALLOWED");
    }
    //eslint-disable-next-line
  };

  function addTranscribeSkill(
    pipelineSkills: any,
    setPipelineSkills: any,
    skillsLibrary: any,
  ) {
    const audioSkill: any = flatMap(skillsLibrary.skills).find((skill: any) => {
      return skill.name === "Transcribe Audio";
    });
    const mutatedSkill = {
      ...audioSkill,
      id: uuidv4(),
    };
    const pipelineArray = cloneDeep(pipelineSkills);
    pipelineArray.steps.unshift(mutatedSkill);
    setPipelineSkills(pipelineArray);
  }
  /* An example of how to send the file to an external API using axios, including working progress bar */

  // let formData = new FormData();
  // formData.append("file", acceptedFiles[0]);

  //  setCubeState("UPLOADING");
  // const response: any = await axios.post(
  //   "http://localhost:4000/upload_file",
  //   formData,
  //   {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //     },
  //     onUploadProgress: (data: any) => {
  //       setProgress(Math.round((100 * data.loaded) / data.total));
  //     },
  //   },
  // );

  // if(response.status)
  // console.log("Response is :: ", response);
  // if (response?.data?.code === 200) {
  //   setTextarea(response.data.content);
  //   setCubeState("WAITING");
  // } else {
  //   console.log(response);
  //   setCubeState("ERROR");
  // }

  //eslint-disable-next-line
  //  }, []);

  /* We will need this when we implement the external API uploading mechanism */

  // const fileLoading = (
  //   <div className="grid justify-center items-center h-full w-full" ref={ref}>
  //     <div>
  //       <figure className="grid justify-center">
  //         <LottieLoader width={105} height={105} />
  //       </figure>

  //       <p className="text-center text-black dark:text-white font-poppins font-bold">
  //         Uploading.... {progress}%
  //       </p>
  //     </div>
  //   </div>
  // );

  const fileAccepted = (
    <div className="drop-zone grid justify-center items-center h-full w-full relative">
      <div className="absolute inset-4">
        <DropAreaBorder />
      </div>

      <div className="">
        <figure className="grid justify-center animate-fade-in-down">
          <IconThreeArrows height="57px" width="12px" />
        </figure>

        <p className="text-center text-black dark:text-white font-poppins font-bold">
          {t("Drop your file here")}
        </p>
      </div>
    </div>
  );

  const fileUploaded = (
    <div className="grid grid-rows-auto-1fr">
      <div className="px-3 dark:bg-darkGrayBackground grid">
        <div className="dark:bg-tabDarkGray rounded-md w-fit-content">
          <div className="grid grid-cols-1fr-auto gap-x-4 py-2 items-center px-3 ">
            <p className="p-0 m-0 dark:text-white text-black grid items-center font-mono font-bold text-sm">
              {getFileName(cubeFile) + getFileExtension(cubeFile)}
            </p>
            <button
              className="grid items-center"
              onClick={() => {
                setTextarea("");
                setCubeState("WAITING");
                setInputResponse(false);
                setCubeFile("");
                setFileContent("");
              }}
            >
              <CloseIcon className="text-cyan" />
            </button>
          </div>
        </div>
      </div>

      {inputResponse ? (
        <InputDeterminator inputContainer={inputContainer} />
      ) : (
        <></>
      )}
    </div>
  );

  const handleSampleClick = (event: any, sample: SampleButtonType) => {
    event.preventDefault();
    event.stopPropagation();
    setLastSharedPipeline("");
    setFileContent("");
    setCubeFile("");
    userEventLogger(UserEvent.RUN_SAMPLE_BUTTON, { name: sample.title });
    if (sample.pipeline_id !== "" && sample.pipeline_id !== undefined)
      navigate("/?pipeline=" + sample.pipeline_id, { replace: true });
  };

  const initialState = (
    <div
      className="grid grid-flow-row h-full w-full cursor-text"
      onClick={() => {
        inputContainer?.current?.focus();
      }}
    >
      <div className="grid items-start gap-x-2">
        <TextArea inputContainer={inputContainer} />

        <div className="p-4 py-6">
          <div className="h-[1px] border-t dark:border-t-inputBorderDarkGray border-t-tableRowGray text-center relative ">
            <span className="relative top-[-.7em] inline-block text-black dark:text-white bg-white dark:bg-darkGrayBackground px-4">
              {t("or")}
            </span>
          </div>
        </div>
      </div>

      <div className="grid items-center px-4">
        <div className="grid justify-center">
          <Button
            ariaLabel={t("Upload a file")}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              open();
            }}
          >
            <span className="text-sm px-4">{t("Upload a file")}</span>
          </Button>
          <p className="text-textLight font-poppins text-xxs leading-5 pb-4 font-medium">
            Example: txt, JSON, wav, mp3
          </p>
        </div>
        <div className="h-[1px] border-t dark:border-t-inputBorderDarkGray border-t-tableRowGray text-center relative">
          <span className="relative top-[-.7em] inline-block text-black dark:text-white bg-white dark:bg-darkGrayBackground px-4">
            {t("or")}
          </span>
        </div>
      </div>

      <div className="text-center px-2">
        <p className="dark:text-white text-black text-md py-3">
          {t("select a sample")}
        </p>
        <div className="grid grid-cols-1 md:flex gap-y-4 gap-x-1 md:gap-x-3 py-2 justify-items-center justify-center overflow-hidden m-0 mt-4 mx-auto md:w-full">
          {settingsFile?.buttons
            ?.filter((sample: SampleButtonType, index: number) => index < 2)
            .map((sample: SampleButtonType, index: any) => (
              <Button
                ariaLabel={t("sample?.title")}
                className="!w-full max-w-[200px]"
                key={index}
                onClick={(event: React.MouseEvent) => {
                  handleSampleClick(event, sample);
                }}
              >
                <span className="text-white font-semibold text-xs md:text-sm">
                  {t(sample.title)}
                </span>
              </Button>
            ))}
          <Button
            ariaLabel="More samples"
            className="!w-full max-w-[200px]"
            key={2}
            onClick={() => {
              searchParams.set("textInput", "sample-library");
              setSearchParams(searchParams);
            }}
          >
            <span className="text-white font-semibold text-xs md:text-sm">
              {t("More Samples")}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );

  const fileRejected = (
    <div
      className="grid grid-flow-row h-full w-full cursor-text"
      onClick={() => {
        inputContainer?.current?.focus();
      }}
    >
      <div className="grid items-start gap-x-2">
        <TextArea inputContainer={inputContainer} />

        <div className="p-4 py-6">
          <div className="h-[1px] border-t dark:border-t-inputBorderDarkGray border-t-tableRowGray text-center relative ">
            <span className="relative top-[-.7em] inline-block text-black dark:text-white bg-white dark:bg-darkGrayBackground px-4">
              {t("or")}
            </span>
          </div>
        </div>
      </div>

      <div className="grid items-center px-4">
        <div className="grid justify-center">
          <Button
            ariaLabel="Upload a file"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              open();
            }}
          >
            <span className="text-sm px-4">{t("Upload a file")}</span>
          </Button>
          <p className="text-textLight font-poppins text-xxs leading-5 pb-4 font-medium">
            Example: txt, JSON, wav, mp3
          </p>
        </div>
        <div className="h-[1px] border-t dark:border-t-inputBorderDarkGray border-t-tableRowGray text-center relative">
          <span className="relative top-[-.7em] inline-block text-black dark:text-white bg-white dark:bg-darkGrayBackground px-4">
            {t("or")}
          </span>
        </div>
      </div>

      <div className="text-center px-2">
        <p className="dark:text-white text-black text-md py-3">
          {t("select a sample")}
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-y-4 gap-x-1 md:gap-x-3 py-2 justify-center overflow-hidden m-0 mt-4">
          {settingsFile?.buttons
            ?.filter((sample: SampleButtonType, index: number) => index < 2)
            .map((sample: SampleButtonType, index: any) => (
              <Button
                ariaLabel={sample?.title}
                key={index}
                onClick={(event: React.MouseEvent) => {
                  handleSampleClick(event, sample);
                }}
              >
                <span className="text-white font-semibold text-xs md:text-sm">
                  {t(sample.title)}
                </span>
              </Button>
            ))}
          <Button
            ariaLabel="More samples"
            key={2}
            onClick={() => {
              searchParams.set("textInput", "sample-library");
              setSearchParams(searchParams);
            }}
          >
            <span className="text-white font-semibold text-xs md:text-sm">
              {t("More Samples")}
            </span>
          </Button>
        </div>
      </div>
      <div className="text-center">
        <p className="text-error font-poppins font-light text-sm pb-4">
          {t("Failed to upload file, the format is not supported")}
        </p>
      </div>
    </div>
  );

  return (
    <div
      {...getRootProps({ className: "dropzone" })}
      className="grid bg-white dark:bg-darkGrayBackground rounded-b-md overflow-y-auto"
      data-cy="input-empty-state"
    >
      <input {...getInputProps()} />

      {/* {cubeState === "UPLOADING" && fileLoading}*/}
      {cubeState === "FINISHED" && fileUploaded}
      {cubeState === "EXTENSION_NOT_ALLOWED" && fileRejected}
      {cubeState === "WAITING" && isDragAccept && fileAccepted}
      {cubeState === "WAITING" && isDragReject && fileRejected}
      {cubeState === "WAITING" && !isDragActive && initialState}
    </div>
  );
}

// Makes the name shorter if the length is too long
function getFileName(fileName: string) {
  try {
    return fileName.length > 18
      ? fileName.substring(0, fileName.lastIndexOf(".")).substring(0, 18)
      : fileName.substring(0, fileName.lastIndexOf("."));
  } catch (e) {
    console.log(e);
    return "";
  }
}

// Gets the extension of the file
export function getFileExtension(fileName: string) {
  try {
    return "." + fileName.split(".").pop();
  } catch (e) {
    console.log(e);
    return "";
  }
}

// Makes a combination of name & extension
export function getShortName(fileName: string) {
  return getFileName(fileName) + getFileExtension(fileName);
}
