import React from "react";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "../../../assets/SvgIcons";

type SearchSkillType = {
  search: string;
  setSearch: (value: string) => void;
};
function SearchSkill({ search, setSearch }: SearchSkillType) {
  const { t } = useTranslation("index");

  function handleSearch(event: any) {
    setSearch(event.target.value);
  }

  return (
    <div className="grid focus:ring-2 m-2 max-w-[185px] md:max-w-full px-3 py-3 gap-x-3 rounded-md grid-cols-auto-1fr items-center justify-items-center border dark:border-grayBox border-borderLight bg-transparent placeholder-textLight font-roboto dark:bg-darkGrayBackground text-black dark:text-white">
      <SearchIcon />
      <input
        placeholder={t("Search")}
        type="text"
        className="w-full focus:outline-none bg-transparent"
        onChange={handleSearch}
        value={search}
      />
    </div>
  );
}

export default SearchSkill;
