import { Form, InputNumber, Popover } from "antd";
import { isEqual } from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconSettings } from "../../../../assets/SvgIcons";
import { isClusterable, randomString } from "../../utils";
import AutoLength from "./auto-length";
import FindOrigin from "./find-origins";
import Select from "react-select";
import { useThemeColor } from "../../../../components/theme-color";
import { useRecoilValue } from "recoil";
import "./styles.css";
import { selectStyles } from "../../../../utils/select-styles";
import { pipelineSkillsAtom } from "../../../pipeline/pipeline-atoms";

const SettingsPopover = ({
  columnName,
  itemId,
  params,
  defaultParams,
  updateParams,
  isOpen,
  setOpenedPopover,
}: any) => {
  const [form] = Form.useForm();

  const [visible, setVisible] = useState(isOpen);

  const [automaticLengthChecked, setAutomaticLengthChecked] = React.useState(
    params?.auto_length,
  );
  const [findOriginsChecked, setFindOriginsChecked] = React.useState(
    params?.find_origins,
  );

  const [allSkills, setSkills] = React.useState<Array<any>>([{}]);
  const [selectedSkill, setSelectedSkill] = React.useState<any>(null);
  const { isDark } = useThemeColor();
  const pipelineSkills = useRecoilValue(pipelineSkillsAtom);

  const hasThresholdsParam = useMemo(
    () => params.hasOwnProperty("thresholds"),
    [params],
  );
  const hasParamsChange = useMemo(
    () => !isEqual(params, defaultParams),
    [params, defaultParams],
  );
  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const onHide = (e?: any) => {
    setVisible(false);
  };

  const onFinish = (values: any) => {
    let params = {};

    if (hasThresholdsParam) {
      params = { thresholds: values };
    } else {
      params = {
        ...values,
        auto_length: automaticLengthChecked,
        find_origins: findOriginsChecked,
      };
    }

    if (columnName === "Clustering") {
      params = {
        input_skill: selectedSkill?.value,
      };
    }

    updateParams(params);
    onHide();
  };

  // const isDisabled = () => {
  //   if (columnName === "Clustering") {
  //     return selectedSkill === null;
  //   }
  // };

  React.useEffect(() => {
    if (!visible && columnName === "Clustering") {
      setOpenedPopover("");
    }
    //eslint-disable-next-line
  }, [visible]);

  React.useEffect(() => {
    const skillOptions = pipelineSkills.steps
      .filter(
        (skill: any) =>
          skill.name !== "Clustering" &&
          isClusterable(skill?.value.toLowerCase()),
      )
      .map((skill: any) => {
        return {
          value: skill.value,
          label: skill.name,
        };
      });
    setSkills(skillOptions);

    // Removes the input skill from the input_skill params
    if (columnName === "Clustering" && params?.input_skill) {
      const exists = pipelineSkills?.steps.some(
        (skill: any) => skill.value === params?.input_skill,
      );

      if (!exists) {
        let params: any = {};
        params.input_skill = "";
        setSelectedSkill(null);
        updateParams(params);
      }
    }
    //eslint-disable-next-line
  }, [pipelineSkills]);

  function getInputSkillObject(input_skill: string) {
    try {
      const skillName = pipelineSkills?.steps.find(
        (skill: any) => skill.value === input_skill,
      )?.value;
      return {
        value: input_skill,
        label: skillName,
      };
    } catch (e) {
      return {
        value: input_skill,
        label: input_skill,
      };
    }
  }

  // Fixes states when re-ordering the draggable items
  React.useEffect(() => {
    if (itemId) {
      if (params) {
        form.setFieldsValue(params);
        if (params?.hasOwnProperty("find_origins")) {
          console.log("setting find origins", params.find_origins);
          setFindOriginsChecked(params.find_origins);
        }
        if (params?.hasOwnProperty("auto_length")) {
          console.log("setting auto_length", params.auto_length);
          setAutomaticLengthChecked(params.auto_length);
        }
        if (params?.hasOwnProperty("input_skill")) {
          if (params?.input_skill !== "")
            setSelectedSkill(getInputSkillObject(params.input_skill));
        }
      }
    }
    //eslint-disable-next-line
  }, []);

  const onDefault = (e: any) => {
    e.preventDefault();
    form.setFieldsValue(
      hasThresholdsParam
        ? { ...defaultParams.thresholds }
        : { ...defaultParams },
    );

    setAutomaticLengthChecked(true);
  };
  const setDefaultParams = () => {
    form.setFieldsValue(
      hasThresholdsParam
        ? { ...defaultParams.thresholds }
        : { ...defaultParams },
    );
  };

  const { t, i18n } = useTranslation("index");

  const button = `py-1 px-3 flex-grow text-white hover:bg-white
  cursor-pointer rounded-md border border-borderLight border-solid`;

  return (
    <Popover
      visible={visible}
      onVisibleChange={handleVisibleChange}
      content={
        <Form
          form={form}
          onFinish={onFinish}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          initialValues={
            hasThresholdsParam ? { ...params.thresholds } : { ...params }
          }
        >
          {columnName === "Summarize" && (
            <>
              <AutoLength
                setAutomaticLengthChecked={setAutomaticLengthChecked}
                automaticLengthChecked={automaticLengthChecked}
                setDefaultParams={setDefaultParams}
              />
              <FindOrigin
                setFindOriginsChecked={setFindOriginsChecked}
                findOriginsChecked={findOriginsChecked}
                setDefaultParams={setDefaultParams}
              />
            </>
          )}
          {Object.entries(params)
            ?.sort()
            .map(([key, value]) => {
              if (
                key === "find_origins" ||
                key === "auto_length" ||
                key === "input_skill"
              )
                return <></>;
              else if (
                key !== "thresholds" &&
                key !== "find_origins" &&
                key !== "auto_length"
              ) {
                let name = key.toString().replace("_", " ");

                if (name === "min length") name = "Min Length (words)";
                if (name === "max length") name = "Max Length (words)";

                return (
                  <Form.Item label={t(name)} name={key} key={randomString(10)}>
                    <InputNumber
                      type="number"
                      placeholder={`${value}`}
                      min={0}
                      disabled={automaticLengthChecked}
                      // max={99}
                    />
                  </Form.Item>
                );
              } else {
                // @ts-ignore
                return Object.entries(value).map(([key, value]) => {
                  return (
                    <Form.Item label={t(key)} name={key} key={randomString(10)}>
                      <InputNumber
                        type="number"
                        // placeholder={`${value}`}
                        min={0}
                        // max={1}
                        step={0.01}
                        value={typeof value === "number" ? value : 0}
                      />
                    </Form.Item>
                  );
                });
              }
            })}

          {columnName === "Clustering" && (
            <div className="py-4">
              {/* <Form.Item
                label={t("Source skill")}
                name={"input_skill"}
                key={randomString(10)}
                className="grid items-center grid-cols-auto-1fr gap-x-2"
              > */}
              <div
                key={randomString(10)}
                className="grid items-center grid-cols-auto-1fr gap-x-2 h-full"
              >
                <p className="text-white p-0 m-0">{t("Source skill:")}</p>
                <Select
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  styles={selectStyles(isDark ? true : false)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  options={allSkills}
                  onChange={(selectedOption): any => {
                    setSelectedSkill(selectedOption);
                  }}
                  value={selectedSkill}
                />
              </div>
              {/* </Form.Item> */}
            </div>
          )}
          <Form.Item>
            <div dir={i18n.dir()} className={`flex justify-center gap-x-2`}>
              <button
                className={`${button}
                bg-mineShaft hover:text-mineShaft hover:border-mineShaft
                ${columnName === "Clustering Stateless" ? "hidden" : ""}`}
                onClick={onDefault}
              >
                {t("Default")}
              </button>
              <button
                className={`${button}
                bg-mineShaft hover:text-mineShaft hover:border-mineShaft`}
                onClick={(e) => {
                  e.preventDefault();
                  onHide();
                }}
              >
                {t("Cancel")}
              </button>
              <button
                type="submit"
                className={`${button} hover:text-purple hover:border-purple`}
              >
                {t("Ok")}
              </button>
            </div>
          </Form.Item>
        </Form>
      }
      trigger="click"
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          setVisible(true);
        }}
      >
        <figure
          className={`settings-icon w-[30px] grid justify-center m-0  ${
            hasParamsChange ? "text-green" : ""
          } ${
            window.location.hostname === "studio.oneai.com" &&
            columnName === "Emotions"
              ? "invisible"
              : "text-blueOpacity "
          }`}
        >
          <IconSettings />
        </figure>
      </button>
    </Popover>
  );
};

export default React.memo(SettingsPopover);
