import Menu from "@mui/material/Menu";
import { Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { TrashIcon } from "../assets/SvgIcons";
import i18n from "../i18n";
import { contentFileAtom } from "../lib/atoms";
import theme from "../theme";
import { LottieLoader } from "./Loader";
import AppTooltip from "./tooltip";

export default function RemoveSkillTypePopover({
  openedPopover = () => {},
  closedPopover = () => {},
  type,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  type: any;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("content-management");
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <AppTooltip title={t("Remove type")}>
        <button
          onClick={handleClick}
          aria-controls={open ? "edit-type-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <TrashIcon className="text-shadeBlue" />
        </button>
      </AppTooltip>

      <RemoveSkillTypePopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        type={type}
      />
    </React.Fragment>
  );
}

type IRemoveSkillTypePopup = {
  anchorEl: any;
  open: boolean;
  onClose: any;
  type: any;
};

const RemoveSkillTypePopup = React.forwardRef(
  (props: IRemoveSkillTypePopup, ref: any) => {
    const { anchorEl, open, onClose, type } = props;
    const [tipRight, setTipRight] = React.useState(0);
    const tipWidth = 18;
    React.useEffect(() => {
      if (anchorEl) {
        setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2);
      }
    }, [anchorEl]);
    return (
      <Menu
        anchorEl={anchorEl}
        id="edit-skill-menu"
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: theme.colors.lightBlack,
            color: theme.colors.white,
            padding: theme.spacing[4],
            width: "280px !important",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.3,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "4px",
              right: tipRight,
              width: tipWidth,
              height: 18,
              backgroundColor: theme.colors.lightBlack,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              borderRadius: "4px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <EditSkillForm onClose={onClose} type={type} />
      </Menu>
    );
  },
);

const EditSkillForm = ({
  onClose = () => {},
  type,
}: {
  onClose: any;
  type: any;
}) => {
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState("FORM");
  const { t } = useTranslation("content-management");
  const [content, setContent] = useRecoilState(contentFileAtom);

  const onSubmit = async () => {
    try {
      const clonedContent = cloneDeep(content);

      const typeIndex: any = clonedContent?.skill_categories.findIndex(
        (clonedSkill: any) => clonedSkill.id === type.id,
      );

      clonedContent?.skill_categories?.splice(typeIndex, 1);
      setContent(clonedContent);
      onClose();
    } catch (error) {
      setError("Could not edit this skill for some reason");
      setState("ERROR");
    }
  };
  if (error) {
    return <div>{t(error)}</div>;
  }

  return (
    <div>
      {state === "LOADING" ? (
        <LottieLoader />
      ) : state === "SUCCESS" ? (
        <div>{t("You edited the skill!")}</div>
      ) : state === "ERROR" ? (
        <div>{error}</div>
      ) : (
        <Formik
          initialValues={{ name: type.category_name }}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="grid grid-cols-1 font-poppins">
              <p className="text-center">
                {t("Delete " + type.category_name + " category?")}
              </p>
              <span className="font-normal text-center">
                {t("Are you sure you want to delete this skill category?")}
              </span>
              <div
                dir={i18n.dir()}
                className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={onClose}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Yes")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
