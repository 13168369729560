import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { IconTooltip } from "../../assets/SvgIcons";
import { useThemeColor } from "../../components/theme-color";
import AppTooltip from "../../components/tooltip";
import { curApiItemAtom } from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import useDidMountEffect from "../../lib/use-did-mount-effect";
import { theme } from "../../tailwind.config";
import { getItemIcon, getSkillIcon } from "../PipelinePage/utils";
import Accordion, { AccordionDetails, AccordionSummary } from "./api-accordion";
import APISearchEdit from "./api-search-edit";
import "./docs-style.css";

type APIListPanelProps = {
  data: any;
  setCurApiItem: any;
  accordionOpened: any;
  setAccordionOpened: Function;
};

export default function APIListPanel({
  data,
  setCurApiItem,
  accordionOpened,
  setAccordionOpened,
}: APIListPanelProps) {
  const panelHeader = React.useRef<any>();
  const panelContent = React.useRef<any>();
  const [curApiId, setCurApiId] = React.useState<any>(null);
  const [searchText, setSearchText] = React.useState<string>("");
  const curApiItem = useRecoilValue<any>(curApiItemAtom);
  const { isDark } = useThemeColor();
  const { userEventLogger } = useEventLogger();
  const [searchParams, setSearchParams] = useSearchParams();

  const sortedDocumentationSkills = (categoryNamesArray: any) => {
    let sortingArray: any = data.skills_order;
    let documentationSkillsArray: any = categoryNamesArray;
    let finalArray: any = [];

    sortingArray.forEach((el: any) => {
      const alreadyExists = finalArray?.find((e: any) => e?.item_name === el);
      if (!alreadyExists)
        finalArray.push(
          documentationSkillsArray?.find((e: any) => e.item_name === el),
        );
    });
    const retArray = finalArray.filter(function (element: any) {
      return element !== undefined;
    });

    // Add last and not sorted skills to end of the array
    documentationSkillsArray.forEach((el: any) => {
      if (!retArray.includes(el)) {
        retArray.push(el);
      }
    });

    return retArray;
  };
  const handleClickAPIButton = (e: any, api: any, section: any) => {
    setCurApiId(api?.item_name);
    setCurApiItem(api);

    const currentParams = Object.fromEntries(searchParams);

    setSearchParams({
      ...currentParams,
      ...(api && { api: section.name }),
      ...(api.item_name && { item: api.item_name }),
    });
  };

  React.useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (searchText !== "")
        userEventLogger(UserEvent.DOCS_SEARCH, {
          value: searchText,
        });
    }, 1000);

    return () => clearTimeout(delaySearch);
    //eslint-disable-next-line
  }, [searchText]);

  const findApiByText = (apiGroup: any) => {
    for (let api of apiGroup.items) {
      if (api?.item_name?.toLowerCase().startsWith(searchText?.toLowerCase())) {
        return true;
      }
    }
    return false;
  };

  const handleAccordionOpen = (apiGroupId: any, expanded: any) => {
    setAccordionOpened((accordionOpened: any) => ({
      ...accordionOpened,
      [apiGroupId]: expanded,
    }));
  };

  useDidMountEffect(() => {
    const accordion = searchParams.get("accordion");

    let accordionsArray: any = [];
    let defaultAccordion: any = [];

    Object.entries(accordionOpened).forEach(([key, value]) => {
      if (value === true) {
        defaultAccordion.push(key);
        accordionsArray.push(key);
      }
    });

    const match = accordionsArray.join(",") === accordion?.split(",").join(",");
    const currentParams = Object.fromEntries(searchParams);

    if (!match)
      setSearchParams({
        ...currentParams,
        accordion: accordionsArray.join(","),
      });

    //eslint-disable-next-line
  }, [accordionOpened]);

  function getItemType(itemName: string) {
    const currentAPI = data?.documentation?.find((apiSections: any) => {
      return apiSections?.items?.find((item: any) => {
        return item?.item_name === itemName;
      });
    });
    return currentAPI?.type;
  }

  function itemArrayIcon(item: any) {
    const type = getItemType(item);
    const itemIcon: any =
      type === "skills"
        ? getSkillIcon(item, "DOCS_SMALL", true, data)
        : type === "items"
        ? getItemIcon(item, "DOCS_SMALL")
        : "";
    return itemIcon;
  }

  return (
    <>
      <div
        className="py-4 grid grid-rows-auto-1fr gap-y-6 dark:bg-darkGrayBackground bg-white rounded-md"
        data-pane="left"
      >
        <div className="items-center grid p-3" ref={panelHeader}>
          <APISearchEdit
            onChange={(e: React.ChangeEvent<any>) =>
              setSearchText(e.target.value)
            }
            value={searchText}
          />
        </div>
        <div
          className="overflow-y-auto overflow-x-hidden h-full"
          ref={panelContent}
        >
          {data?.documentation?.map((section: any, index: any) => {
            const found = findApiByText(section);
            if (!found) {
              return null;
            }
            return (
              <Accordion
                sx={{
                  backgroundColor: "transparent",
                }}
                key={index}
                expanded={
                  searchText !== ""
                    ? true
                    : accordionOpened[section.name] !== undefined
                    ? accordionOpened[section.name]
                    : false
                }
                onChange={(e: any, expanded: any) => {
                  handleAccordionOpen(section.name, expanded);
                }}
              >
                <AccordionSummary
                  sx={{
                    backgroundColor: "transparent",
                    "& .MuiSvgIcon-root": {
                      color: theme.colors.borderBlue,
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: theme.extend.fontWeight.medium,
                      fontSize: "22px",
                      paddingLeft: 3,
                      color: isDark ? "white" : "black",
                    }}
                  >
                    {section.name}
                  </Typography>
                  {section.info && (
                    <div className="grid items-center">
                      <AppTooltip title={section.info}>
                        <figure className="grid items-center">
                          <IconTooltip
                            width="19px"
                            height="19px"
                            className="text-shadeBlue z-50"
                          />
                        </figure>
                      </AppTooltip>
                    </div>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {sortedDocumentationSkills(section?.items).map(
                    (api: any) =>
                      api?.item_name
                        ?.toLowerCase()
                        .startsWith(searchText?.toLowerCase()) && (
                        <ListItemButton
                          sx={{
                            color: isDark
                              ? theme.colors.numberGray
                              : theme.colors.formsgray,
                            paddingTop: 3.7,
                            paddingBottom: 3.7,

                            "&&.Mui-selected, && .Mui-selected:hover": {
                              bgcolor: isDark
                                ? theme.colors.activeAPIButton
                                : theme.colors.faSmoke,
                              color: isDark ? "white" : "black",
                            },
                          }}
                          className="min-h-[32px] p-0 h-[30px] font-poppins"
                          key={api.id}
                          onClick={(e) => handleClickAPIButton(e, api, section)}
                          selected={
                            curApiId === undefined
                              ? false
                              : curApiItem?.item_name === api?.item_name
                          }
                        >
                          <ListItemIcon>
                            {itemArrayIcon(api?.item_name)}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontSize: "16px" }}>
                                {api.item_name}
                              </Typography>
                            }
                          ></ListItemText>
                        </ListItemButton>
                      ),
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>

        <style>
          {`
        .MuiAccordionSummary-root {
          flex-direction: row-reverse;
        }

        `}
        </style>
      </div>
    </>
  );
}
