import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  connectAuthEmulator,
  getAuth,
  GithubAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import {
  connectFirestoreEmulator,
  initializeFirestore,
} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { useAuthState } from "react-firebase-hooks/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_POJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// This lets the cypress tests access the firestore database
const firestore = initializeFirestore(app, {
  experimentalForceLongPolling: true,
  useFetchStreams: false,
} as any);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const firebaseAnalytics = getAnalytics(app);
const functions = getFunctions(app);
//const firestore = getFirestore(app);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
googleProvider.addScope("email");
const githubProvider = new GithubAuthProvider();

// Segment Analytics
declare global {
  interface Window {
    analytics: any;
  }
}

const segmentAnalytics = window.analytics;

if (
  process.env.NODE_ENV === "development" ||
  process.env.FIREBASE_AUTH_EMULATOR_HOST
) {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(firestore, "localhost", 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
}

const useUser = () => {
  const [user, loading, error] = useAuthState(auth);
  const _user: any = user === undefined ? null : user;
  const _loading = loading as boolean;

  return { user: _user, loading: _loading, error };
};

export {
  app,
  auth,
  firestore,
  functions,
  firebaseAnalytics,
  googleProvider,
  githubProvider,
  segmentAnalytics,
  useUser,
};
