import { isEmpty, isEqual } from "lodash";
import React from "react";
import { useResizeDetector } from "react-resize-detector";
import { useParams } from "react-router-dom";
import Split from "react-split";
import { useRecoilState } from "recoil";
import { useUserData } from "../components/auth/data";
import Clustering from "../components/clustering-global";
import Layout from "../components/Layout/layout";
import {
  clusterAtom,
  generatingCollectionAtom,
  refetchingClusterAtom,
} from "../lib/atoms";
import { demoClusters, LeftPaneTabs } from "./analytics/analytics-left-pane";
import { getClustering } from "./PipelinePage/clustering-api";
import { useIsMobile } from "./PipelinePage/utils";

export const getCollectionsQueryID = "getCollectionsQueryID";

export default function Analytics() {
  const isMobile = useIsMobile();

  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <Layout headerRightRender={<React.Fragment />} loader={false}>
      {isMobile ? (
        <>
          <div className="grid grid-flow-row gap-y-2 overflow-y-auto h-full">
            <LeftPaneTabs />
            <AnalyticsClustering />
          </div>
        </>
      ) : (
        <div className="w-full h-full min-w-[1000px] overflow-x-auto text-white pb-3">
          <Split
            className={`flex flex-row h-full mt-0 overflow-x-hidden overflow-y-clip`}
            gutterSize={2}
            sizes={[20, 80]}
            minSize={[420, 300]}
            snapOffset={0}
          >
            <LeftPaneTabs />
            <AnalyticsClustering />
          </Split>
        </div>
      )}
    </Layout>
  );
}

function AnalyticsClustering() {
  const { width, height, ref } = useResizeDetector();
  const { value: firebaseUser } = useUserData();
  const [, setLoadingCluster] = React.useState<boolean>(true);
  const [clusterState, setClusterState] = useRecoilState(clusterAtom);
  const clusterArray = demoClusters();
  const [currentJSON, setCurrentJSON] = React.useState<any>("");
  const { title, collection } = useParams();
  const [refetchingCluster, setRefetchingCluster] = useRecoilState(
    refetchingClusterAtom,
  );
  const isMobile = useIsMobile();
  const [generatingCollection, setGeneratingCollection] = useRecoilState(
    generatingCollectionAtom,
  );

  const isMyCollection = collection === "my-collection";
  async function getClusteringInfo() {
    const clusterCur: any = await getClustering(
      firebaseUser?.studio_key,
      clusterState.clusterTitle,
    );
    return clusterCur;
  }

  const clusterAsync = async () => {
    let refetchInterval: any = null;

    try {
      if (clusterState.clusterType === "DEMO" && !isMyCollection) {
        const cluster = clusterArray.find(
          (c: any) => c.clusterName === clusterState.clusterTitle,
        );

        setCurrentJSON(
          cluster
            ? JSON.parse(JSON.stringify(cluster.clusterJSON))
            : JSON.parse(JSON.stringify(clusterArray[0].clusterJSON)),
        );
        setLoadingCluster(false);
      } else if (clusterState.clusterType === "SELF") {
        setLoadingCluster(true);
        const cluster: any = await getClusteringInfo();
        if (cluster === "CLUSTERING_ERROR") return;

        if (isEmpty(cluster)) {
          setGeneratingCollection(clusterState.clusterTitle);
          refetchInterval = setInterval(async () => {
            const newCluster: any = await getClusteringInfo();
            if (
              newCluster.length > 0 &&
              Object.keys(newCluster[0]).length > 0
            ) {
              clearInterval(refetchInterval);
              // navigation(
              //   `/analytics/my-collection/${clusterState.clusterTitle}`,
              // );
              setTimeout(async () => {
                const newCluster: any = await getClusteringInfo();
                if (newCluster === "CLUSTERING_ERROR") {
                  console.log(
                    "Encountered error while trying to fetch cluster",
                  );
                  return;
                }

                setGeneratingCollection("");
                setCurrentJSON(
                  cluster
                    ? JSON.parse(JSON.stringify(newCluster))
                    : JSON.parse(JSON.stringify(clusterArray[0].clusterJSON)),
                );
                setLoadingCluster(false);
              }, 10_000);
            }
          }, 5000);
        } else {
          if (refetchingCluster === clusterState.clusterTitle) {
            const cluster: any = await getClusteringInfo();
            refetchInterval = setInterval(async () => {
              const newCluster: any = await getClusteringInfo();
              if (cluster === "CLUSTERING_ERROR") {
                console.log("Encountered error while trying to fetch cluster");
                return;
              }
              if (!isEqual(newCluster, cluster)) {
                clearInterval(refetchInterval);
                // Get all clustering information together since backend generates them one by one
                setTimeout(async () => {
                  const newCluster: any = await getClusteringInfo();
                  if (cluster === "CLUSTERING_ERROR") {
                    console.log(
                      "Encountered error while trying to fetch cluster",
                    );
                    return;
                  }
                  setGeneratingCollection("");
                  setCurrentJSON(
                    cluster
                      ? JSON.parse(JSON.stringify(newCluster))
                      : JSON.parse(JSON.stringify(clusterArray[0].clusterJSON)),
                  );
                  setLoadingCluster(false);
                  setRefetchingCluster("");
                }, 5000);
              }
            }, 3000);
          } else {
            setCurrentJSON(
              cluster
                ? JSON.parse(JSON.stringify(cluster))
                : JSON.parse(JSON.stringify(clusterArray[0].clusterJSON)),
            );
            setLoadingCluster(false);
          }
        }
        console.debug("Cluster :: ", cluster);
      }
    } catch (e) {
      clearInterval(refetchInterval);
      return JSON.parse(JSON.stringify(clusterArray[0].clusterJSON));
    }
  };

  React.useEffect(() => {
    if (firebaseUser?.studio_key) {
      clusterAsync();
    }

    //eslint-disable-next-line
  }, [clusterState, firebaseUser?.studio_key, refetchingCluster]);

  const clusteringHeight = height || 0;

  React.useEffect(() => {
    if (collection === "my-collection" && title !== "" && title !== undefined) {
      if (encodeURIComponent(title) !== clusterState.clusterTitle) {
        setClusterState({
          clusterType: "SELF",
          clusterTitle: encodeURIComponent(title || "") || "",
        });
      }
    }
    //eslint-disable-next-line
  }, [title]);

  const isDemo = clusterState.clusterType === "DEMO";
  const isProcessingItems = clusterState.clusterTitle === refetchingCluster;

  return (
    <div
      ref={ref}
      className={`px-6 pb-6 text-black dark:text-white overflow-y-auto text-xs dark:bg-steelGray bg-white rounded-md font-mono w-full`}
      dir="ltr"
    >
      <div className="h-[60px] grid items-center">
        <h2 className="text-lg p-0">{`${decodeURIComponent(
          clusterState.clusterTitle,
        )} 
        
        ${isDemo ? "[DEMO]" : ""} 
        
        ${isProcessingItems ? "(Processing...)" : ""}
        
        `}</h2>
      </div>
      <div
        className="animate-fade-in-up"
        // This key fixes the re-rendering thing and also I added an animation
        key={currentJSON}
      >
        {generatingCollection === clusterState.clusterTitle ? (
          <CollectionIsBeingGenerated />
        ) : (
          currentJSON && (
            <Clustering
              type={"STUDIO"}
              width={width || 0}
              height={isMobile ? 750 : clusteringHeight - 110}
              hideCategories={true}
              json={currentJSON}
              rightButton={<></>}
            />
          )
        )}
      </div>
    </div>
  );
}

function CollectionIsBeingGenerated() {
  return (
    <p>
      This collection is still being generated in our server, please wait for
      the results
    </p>
  );
}
