import React from "react";
import "./auto-length.css";
import { useTranslation } from "react-i18next";
export default function AutoLength({
  automaticLengthChecked,
  setAutomaticLengthChecked,
  setDefaultParams,
}: any) {
  const { t } = useTranslation("index");

  const handleOnChange = () => {
    setAutomaticLengthChecked(!automaticLengthChecked);
    setDefaultParams();
  };

  return (
    <div className="py-2">
      <label className="flex items-center" htmlFor="auto-length-checkbox">
        <input
          type="checkbox"
          className="checkbox"
          name="auto-length-checkbox"
          id="auto-length-checkbox"
          checked={automaticLengthChecked}
          onChange={() => {
            handleOnChange();
          }}
        />
        <span className="text-sm">{t("Automatic Length")}</span>
      </label>
    </div>
  );
}
