// Render Prop
import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { getTokenDigest } from "../../components/auth/data";

export default function Utilities() {
  const { t } = useTranslation("utilities");
  const [token, setToken] = React.useState("");
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [nextMessage, setNextMessaage] = React.useState("");
  const handleChange = (event: any) => {
    setToken(event.target.value);
  };

  const handleSubmit = async () => {
    if (!token) {
      setNextMessaage("Please enter a valid token");
      return;
    }

    setButtonLoading(true);
    try {
      const digest = await getTokenDigest(token);
      if (digest?.success) setNextMessaage("API key: " + digest?.hashedKey);
      else setNextMessaage("Could not fetch API key");
    } catch (error) {
      setNextMessaage("Could not fetch API key");
    }
    setButtonLoading(false);
  };
  return (
    <React.Fragment>
      <div className="px-3 dark:text-white text-black ">
        <div className="grid">
          <div className="container grid grid-cols-auto-1fr items-center pb-0">
            <div className="">
              <h1
                className={`items-center md:visible font-poppins font-bold dark:text-white text-black`}
              >
                {t("API key digest tool")}
              </h1>
            </div>
          </div>
        </div>

        <div className="max-h-[calc(100vh-300px)] overflow-y-auto mt-20 justify-center gap-y-4">
          <div className="grid justify-center gap-y-4 w-full h-full overflow-x-auto min-w-[120px]">
            <div className="grid w-full min-w-[120px]">
              <input
                placeholder={t("API key")}
                onChange={handleChange}
                type="text"
                value={token}
                className="p-4 bg-white dark:bg-inputDarkGray border border-borderLight dark:border-darkGrayBorder rounded-[5px] text-black h-[54px] dark:text-white focus:outline-none  placeholder-formsgray text-[14px] w-[350px]"
              />
            </div>
            <div className="relative">
              {buttonLoading ? (
                <button
                  type="button"
                  className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none  p-4 hover:bg-opacity-60 grid grid-flow-col  w-[350px]`}
                  disabled
                >
                  <span>
                    <Box>
                      <CircularProgress
                        style={{
                          width: 15,
                          height: 15,
                          color: "white",
                          padding: 0,
                          margin: 0,
                        }}
                      />
                    </Box>
                  </span>
                </button>
              ) : (
                <button
                  onClick={() => handleSubmit()}
                  className={`bg-shadeBlue disabled:opacity-70 font-mono rounded-md text-sm text-white outline-none w-full p-4 min-w-[120px] ${
                    !nextMessage ? "hover:bg-opacity-60" : ""
                  }`}
                >
                  {t("Go")}
                </button>
              )}
            </div>
          </div>
          <div className="grid justify-center py-8 text-yellow break-all">
            {nextMessage}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
