import Menu from "@mui/material/Menu";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { IconEdit } from "../assets/SvgIcons";
import i18n from "../i18n";
import theme from "../theme";
import { LottieLoader } from "./Loader";
import { contentFileAtom } from "../lib/atoms";
import { useRecoilState } from "recoil";
import { cloneDeep } from "lodash";
import AppTooltip from "./tooltip";
import { useThemeColor } from "./theme-color";
import { useDraftData } from "../lib/use-studio-data";
import { selectStyles } from "../utils/select-styles";
import Select from "react-select";

export default function EditVideoCategory({
  openedPopover = () => {},
  closedPopover = () => {},
  category,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  category: any;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation("content-management");
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <AppTooltip title={t("Edit category")}>
        <button
          onClick={handleClick}
          aria-controls={open ? "edit-category-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <IconEdit className="text-white" />
        </button>
      </AppTooltip>

      <EditVideoCategoryPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        category={category}
      />
    </React.Fragment>
  );
}

type IEditVideoCategoryPopup = {
  anchorEl: any;
  open: boolean;
  onClose: any;
  category: any;
};

const EditVideoCategoryPopup = React.forwardRef(
  (props: IEditVideoCategoryPopup, ref: any) => {
    const { anchorEl, open, onClose, category } = props;
    const { isDark } = useThemeColor();
    const [tipRight, setTipRight] = React.useState(0);
    const tipWidth = 18;
    React.useEffect(() => {
      if (anchorEl) {
        setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2);
      }
    }, [anchorEl]);
    return (
      <Menu
        anchorEl={anchorEl}
        id="edit-video-category"
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: isDark
              ? theme.colors.darkBackground
              : theme.catskill,
            color: theme.colors.white,
            padding: theme.spacing[4],
            width: "280px !important",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.3,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "4px",
              right: tipRight,
              width: tipWidth,
              height: 18,
              backgroundColor: isDark
                ? theme.colors.darkBackground
                : theme.catskill,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              borderRadius: "4px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <EditVideoCategoryForm onClose={onClose} category={category} />
      </Menu>
    );
  },
);

const EditVideoCategoryForm = ({
  onClose = () => {},
  category,
}: {
  onClose: any;
  category: any;
}) => {
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState("FORM");
  const { t } = useTranslation("content-management");
  const [draft, setDraft] = useRecoilState(contentFileAtom);
  const { data } = useDraftData(draft);
  const { isDark } = useThemeColor();
  const options = [
    {
      value: false,
      label: "Default closed",
    },
    {
      value: true,
      label: "Default open",
    },
  ];
  const [selectedType, setSelectedType] = React.useState<any>(options[0]);

  React.useEffect(() => {
    if (category.video_category_open === true) {
      setSelectedType(options[1]);
    } else {
      setSelectedType(options[0]);
    }
    //eslint-disable-next-line
  }, []);
  const onSubmit = async (values: any, { setSubmfitting }: any) => {
    try {
      const clonedContent = cloneDeep(data);
      const categoryIndex = clonedContent.video_categories.findIndex(
        (newSection: any) =>
          newSection.video_category_name === category.video_category_name,
      );

      clonedContent.video_categories[categoryIndex].video_category_name =
        values?.name;

      clonedContent.video_categories[categoryIndex].video_category_open =
        selectedType.value;

      setDraft(clonedContent);
      onClose();
    } catch (error) {
      setError("Could not edit this category for some reason");
      console.log(error);
      setState("ERROR");
    }
  };
  if (error) {
    return <div>{t(error)}</div>;
  }

  return (
    <div>
      {state === "LOADING" ? (
        <LottieLoader />
      ) : state === "SUCCESS" ? (
        <div>Success</div>
      ) : state === "ERROR" ? (
        <div>{error}</div>
      ) : (
        <Formik
          initialValues={{ name: category.video_category_name }}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="grid grid-cols-1 gap-y-4 font-poppins pb-4 text-black dark:text-white">
              <p className="text-center">{t("Edit category")}</p>
              <span className="font-normal text-center">
                {t("Make sure sure you know what you're doing!")}
              </span>
              <div className="grid grid-cols-1 gap-y-4">
                <div className="grid gap-y-1">
                  <label htmlFor="name" className="text-sm py-2">
                    {t("Category name:")}
                  </label>
                  <Field
                    type="text"
                    name="name"
                    className="bg-transparent border border-borderLight dark:border-darkGrayBorder rounded p-3 w-full"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-red"
                  />
                </div>
                <div className="grid gap-y-1">
                  <label
                    htmlFor="select"
                    className="text-sm font-normal leading-6"
                  >
                    {t("Default:")}
                  </label>
                  <Select
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={selectStyles(isDark ? true : false)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    options={options}
                    onChange={(selectedOption: any) => {
                      setSelectedType(selectedOption);
                    }}
                    value={selectedType}
                  />
                </div>
              </div>
              <div
                dir={i18n.dir()}
                className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-medium tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={onClose}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-medium tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Save")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
