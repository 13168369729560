import { Link } from "react-router-dom";
import logoMedium from "../../assets/logo-medium.svg";
import { useEventLogger, UserEvent } from "../../lib/event_logger";

const LogoMedium = () => {
  const { userEventLogger } = useEventLogger();

  return (
    <div className="">
      <Link to="/" onClick={() => userEventLogger(UserEvent.ONEAI_LOGO)}>
        <figure className="" tabIndex={0}>
          <img
            src={logoMedium}
            className="h-[30px]"
            alt="Logo"
            data-cy="logo"
          />
        </figure>
      </Link>
    </div>
  );
};
export default LogoMedium;
