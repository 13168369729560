import React from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import { useUserData } from "../components/auth/data";
import {
  lastSharedPipelineAtom,
  partitionedSkillsSelector,
  pipelineErrorAtom,
  pipelineSkillsAtom,
  runPipelineHasErrorAtom,
  sharedPipelineAtom,
  sharedPipelineIDAtom,
} from "../pages/pipeline/pipeline-atoms";
import {
  addPipeline,
  getPipelineFromFirestore,
  inputSelectionTypes,
  randomString,
  stripHTML,
} from "../pages/PipelinePage/utils";
import {
  cubeFileAtom,
  cubeStateAtom,
  inputResponseAtom,
  inputTypeAtom,
  textAreaAtom,
  textAreaEmptyStateAtom,
} from "./atoms";
import { useEventLogger, UserEvent } from "./event_logger";
import useRequestBuilder from "./use-request-builder";
import useRunPipeline from "./use-run-pipeline";
import { useStudioData } from "./use-studio-data";
import { useSummaryOrigin } from "./use-summary-origin";

export function useSharedPipelineListener() {
  // Hooks
  const { value } = useUserData();
  const { data } = useStudioData();
  const { runPipeline } = useRunPipeline();
  const [searchParams] = useSearchParams();

  // Setters
  const setSelectedInputType = useSetRecoilState(inputTypeAtom);
  const setTextAreaEmptyState = useSetRecoilState(textAreaEmptyStateAtom);
  const setInputResponse = useSetRecoilState(inputResponseAtom);
  const setPipelineHasError = useSetRecoilState(runPipelineHasErrorAtom);
  const setPipelineSkills = useSetRecoilState(pipelineSkillsAtom);
  const setCubeState = useSetRecoilState(cubeStateAtom);
  const setCubeFile = useSetRecoilState(cubeFileAtom);
  const setTextarea = useSetRecoilState(textAreaAtom);
  const setPipelineError = useSetRecoilState(pipelineErrorAtom);
  const setSharedPipeline = useSetRecoilState(sharedPipelineAtom);
  const [lastSharedPipeline, setLastSharedPipeline] = useRecoilState(
    lastSharedPipelineAtom,
  );
  const pipelineSkills = useRecoilValue(pipelineSkillsAtom);
  const setSharedPipelineID = useSetRecoilState(sharedPipelineIDAtom);
  const textArea = useRecoilValue(textAreaAtom);
  // React States
  const [shouldRunPipeline, setShouldRunPipeline] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setSharedPipelineID(randomString(6));
    //eslint-disable-next-line
  }, [textArea, pipelineSkills]);

  const synchronizedSkills = (pipelineSkills: any) => {
    const retSkills: any = [];
    pipelineSkills.forEach((sharedPipelineSkill: any) => {
      const skill = data?.skills.find(
        (skill: any) => skill.name === sharedPipelineSkill.name,
      );
      if (skill) {
        const mutatedSkill = { ...skill };
        mutatedSkill.id = uuidv4();

        if (sharedPipelineSkill.hasOwnProperty("params")) {
          mutatedSkill.params = sharedPipelineSkill.params;
        }

        retSkills.push(mutatedSkill);
      } else if (sharedPipelineSkill.name === "Entities") {
        retSkills.push(sharedPipelineSkill);
      }
    });
    return retSkills;
  };

  const runSharedPipeline = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const pipelineParam = searchParams.get("pipeline");

    if (params.pipeline && lastSharedPipeline !== pipelineParam) {
      if (value?.studio_key) {
        getPipelineFromFirestore(params.pipeline).then((res: any) => {
          if (res?.pipeline) {
            if (res?.file) {
              setCubeState("FINISHED");
              setCubeFile(res.file);
              setInputResponse(false);
            } else {
              setTextAreaEmptyState(false);
            }

            if (res?.text) {
              setTextarea(res?.text);
            } else {
              setTextarea(res?.input);
            }

            setPipelineSkills({
              steps: synchronizedSkills(res?.pipeline?.items),
            });

            if (res?.input_type === "conversation")
              setSelectedInputType(inputSelectionTypes["conversation"]);
            else if (res?.input_type === "auto-detect")
              setSelectedInputType(inputSelectionTypes["auto-detect"]);
            else if (res?.input_type === "article")
              setSelectedInputType(inputSelectionTypes["article"]);

            setShouldRunPipeline(true);
            setLastSharedPipeline(pipelineParam || "");
          } else {
            setPipelineHasError(true);
            setPipelineError({
              name: "Error",
              message: "Invalid pipeline",
              showError: true,
              requestId: "",
            });
          }
        });
      } else {
        setSharedPipeline(true);
      }
    }
  };

  React.useEffect(() => {
    if (shouldRunPipeline) {
      runPipeline();
      setShouldRunPipeline(false);
      setSharedPipeline(false);
    }
    //eslint-disable-next-line
  }, [shouldRunPipeline]);
  return { runSharedPipeline };
}

export function useSharePipeline() {
  // Hooks
  const hasErrors = usePipelineErrors();
  const requestBuilder = useRequestBuilder();
  const { userEventLogger } = useEventLogger();
  const { isSummary } = useSummaryOrigin();

  // Setters
  const [sharePipelineButtonLoading, setSharePipelineButtonLoading] =
    React.useState<boolean>(false);
  const [sharePipelineMessage, setSharePipelineMessage] =
    React.useState<string>("");

  // Getters
  const cubeFile = useRecoilValue(cubeFileAtom);
  const sharedPipelineID = useRecoilValue(sharedPipelineIDAtom);
  const partitionedSkills = useRecoilValue(partitionedSkillsSelector);
  const textArea = useRecoilValue(textAreaAtom);

  // React States
  const [clickedSharePipeline, setClickedSharePipeline] =
    React.useState<boolean>(false);

  const onClose = () => {
    setTimeout(() => {
      setClickedSharePipeline(false);
    }, 1000);
  };

  const sharePipeline = async (event: any) => {
    if (hasErrors().length) {
      return;
    }

    if (event !== false) {
      setSharePipelineButtonLoading(true);
    }

    const { input_type, steps } = requestBuilder();

    const pipeline = {
      items: partitionedSkills.flat(),
      name: "Skill Pipeline",
    };

    const result = await addPipeline(
      {
        input: isSummary().foundSummary ? stripHTML(textArea) : textArea,
        pipeline,
        steps,
        input_type,
      },
      sharedPipelineID,
      cubeFile,
      event,
    );

    if (event !== false) {
      if (result.success) {
        setSharePipelineMessage("Pipeline link has been copied to clipboard");
        userEventLogger(UserEvent.SHARE_BUTTON, {
          value: result?.link,
        });
      } else {
        setSharePipelineMessage(
          "Error: could not generate pipeline link, please try again later!",
        );
      }
      setClickedSharePipeline(true);
      setSharePipelineButtonLoading(false);
    } else {
      return result?.link;
    }
  };

  return {
    sharePipeline,
    sharePipelineButtonLoading,
    sharePipelineMessage,
    clickedSharePipeline,
    setClickedSharePipeline,
    onClose,
  };
}

export function usePipelineErrors() {
  const pipelineSkills = useRecoilValue(pipelineSkillsAtom);
  const textArea = useRecoilValue(textAreaAtom);

  const hasErrors = () => {
    let errors = [];
    if (!textArea?.length) {
      errors.push("Please add input text");
    }
    if (!pipelineSkills?.steps?.length) {
      errors.push("Please add skills to pipeline");
    }
    return errors;
  };
  return hasErrors;
}
