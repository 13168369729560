import Menu from "@mui/material/Menu";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import Select, { components } from "react-select";
import { IconEditRow, IconPlusThin, IconTooltip } from "../../assets/SvgIcons";
import i18n from "../../i18n";
import {
  ErrorType,
  useErrorLogger,
  useEventLogger,
  UserEvent,
} from "../../lib/event_logger";
import { APIKey, emptyAPIKey } from "../../lib/interfaces";
import contentFile from "../../utils/content.json";
import theme from "../../theme";
import { useCreateKey, useMutateOrganizationKey } from "../auth/data";
import { LottieLoader } from "../Loader";
import AppTooltip from "../tooltip";
import "./styles.css";

export default function AddKeyPopver({
  initialValues,
  buttonText,
  openedPopover = () => {},
  closedPopover = () => {},
}: {
  initialValues?: APIKey;
  buttonText?: string;
  openedPopover?: Function;
  closedPopover?: Function;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("api-keys");
  const { userEventLogger } = useEventLogger();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    userEventLogger(UserEvent.ADD_API_KEY);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  if (initialValues === undefined) {
    initialValues = emptyAPIKey();
  }
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <button
        onClick={handleClick}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        className="cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl"
        data-cy="add-api-key-button"
      >
        {/* Do not forget to translate buttonText (Look for buttonText) */}
        <span>{buttonText ? t(buttonText) : t("Add Key")}</span>
        <figure className={`${buttonText && "hidden"}`}>
          <IconPlusThin
            className={` ${i18n.dir() === "rtl" ? "transform rotate-180" : ""}`}
          />
        </figure>
      </button>
      <KeyPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        initialValues={initialValues}
        popupType={"CREATE"}
      />
    </React.Fragment>
  );
}

export function EditKeyPopver({ initialValues }: { initialValues?: APIKey }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userUIEventLogger } = useEventLogger();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    userUIEventLogger(`settings__api_keys__edit_key`);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (initialValues === undefined) {
    initialValues = emptyAPIKey();
  }

  const { t } = useTranslation("api-keys");
  return (
    <React.Fragment>
      <AppTooltip title={t<string>("Edit Key")}>
        <button
          className="cursor-pointer w-fit-content p-2"
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <IconEditRow width="15" height="15" />
        </button>
      </AppTooltip>

      <KeyPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        initialValues={initialValues}
        popupType={"MUTATE"}
      />
    </React.Fragment>
  );
}

export type KeyPopupType = "CREATE" | "MUTATE";

type IKeyPopup = {
  anchorEl: any;
  initialValues: APIKey;
  open: boolean;
  onClose: any;
  popupType: KeyPopupType;
};

const KeyPopup = React.forwardRef((props: IKeyPopup, ref: any) => {
  const { anchorEl, initialValues, open, onClose, popupType } = props;
  const [tipRight, setTipRight] = React.useState(0);

  const tipWidth = 18;
  React.useEffect(() => {
    if (anchorEl) {
      setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2);
    }
  }, [anchorEl]);
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      // onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          backgroundColor: theme.colors.lightBlack,
          color: theme.colors.white,
          padding: theme.spacing[4],
          width: "280px !important",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.3,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "4px",
            // right: 46,
            right: tipRight,
            width: tipWidth,
            height: 18,
            backgroundColor: theme.colors.lightBlack,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            borderRadius: "4px",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <KeyForm
        initialValues={initialValues}
        onClose={onClose}
        popupType={popupType}
      />
    </Menu>
  );
});

const KeyForm = ({
  initialValues,
  popupType = "CREATE",
  onClose = () => {},
}: {
  initialValues: APIKey;
  popupType?: "CREATE" | "MUTATE";
  onClose: any;
}) => {
  const { createKey } = useCreateKey();
  const { userErrorLogger } = useErrorLogger();
  const { editAPIKey } = useMutateOrganizationKey(initialValues);
  const [secretKey, setSecretKey] = React.useState("");
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState("FORM");

  const { t } = useTranslation("api-keys");
  const [limitDailyUsage, setLimitDailyUsage] = useState<boolean>(
    initialValues.daily_quota === -1 || !initialValues.daily_quota
      ? false
      : popupType === "CREATE"
      ? false
      : true,
  );

  const onSubmit = async (values: any) => {
    setState("LOADING");
    console.debug("[settings :: api-key] onSubmit");

    const getQuota = !limitDailyUsage ? -1 : values.daily_quota;
    let data;
    if (popupType === "CREATE") {
      data = await createKey({
        name: values.name,
        allowed_skills: values.allowed_skills,
        token_id: "",
        rate_limit: 100,
        daily_quota: getQuota,
        type: "USER_CREATED",
        state: "ACTIVE",
      });
    } else {
      data = await editAPIKey({
        name: values.name,
        allowed_skills: values.allowed_skills,
        daily_quota: getQuota,
      });
    }
    if (data === null) {
      setError("We ran into a problem");
      setState("ERROR");
      userErrorLogger({ error: ErrorType.API_KEY_GENERATION_ERROR });
    }

    if (popupType === "CREATE") {
      if (data?.secretKey) {
        setSecretKey(data.secretKey);
        setState("SUCCESS");
      } else {
        setError("We ran into a problem, please try again later.");
        setState("ERROR");
      }
    } else {
      if (data?.res?.modifiedCount > 0 || data?.res?.matchedCount > 0) {
        onClose();
      } else {
        console.error("[KeyForm] error, data:", data);
        setError("We ran into a problem");
        setState("ERROR");
      }
    }
  };
  const validate = (values: any) => {
    const errors = {} as any;
    if (!values.name) {
      errors.name = t("Required");
    }
    if (values.allowed_skills.length === 0) {
      errors.allowed_skills = t("Required");
    }

    if (!/^\d+$/.test(values.daily_quota)) {
      errors.daily_quota = t("Please enter a valid number");
    }
    // console.log("values", values, "errors", errors);
    return errors;
  };

  if (error) {
    return <div>{t("Something went wrong")}</div>;
  }
  const isNotCreate = popupType !== "CREATE";

  return (
    <div>
      {state === "LOADING" ? (
        <div data-cy="api-key-generation-loading">
          <LottieLoader />
        </div>
      ) : state === "SUCCESS" ? (
        <ShowSecretKey secretKey={secretKey} />
      ) : state === "ERROR" ? (
        <div>{error}</div>
      ) : (
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }: any) => (
            <Form
              className="grid grid-cols-1 gap-y-4 font-poppins"
              data-cy="add-key-form"
            >
              <b className="text-center">
                {isNotCreate ? t("Edit key") : t("Add new key")}
              </b>
              <div className="grid grid-cols-1 gap-y-2">
                <label htmlFor="name" className="text-sm">
                  {t("Key nickname")}
                </label>
                <Field
                  type="text"
                  name="name"
                  className="bg-dark rounded p-3"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red"
                />
              </div>

              <div className=" grid grid-flow-col auto-cols-max gap-x-2">
                <Field name="agree" type="checkbox">
                  {({ field, form }: any) => (
                    <label
                      className="grid grid-flow-col auto-cols-max"
                      htmlFor="limit-checkbox"
                    >
                      <input
                        name="limit-checkbox"
                        type="checkbox"
                        className="checkbox bg-lightBlack"
                        onChange={(e) => {
                          setLimitDailyUsage(e.target.checked);
                          if (e.target.checked === true)
                            form.setFieldValue("daily_quota", 100);
                          else form.setFieldValue("daily_quota", 0);
                        }}
                        checked={limitDailyUsage}
                      />
                      <span
                        className="text-sm text-white text-left"
                        onClick={(e) => {
                          setLimitDailyUsage(!limitDailyUsage);
                        }}
                      >
                        {t("Daily Limit")}
                      </span>
                    </label>
                  )}
                </Field>
                <AppTooltip
                  title={t<string>(
                    "You may limit the usage of each key. Your keys are always subject to your account's daily quota, see 'Usage & Quota'",
                  )}
                >
                  <figure className="grid items-center cursor-pointer">
                    <IconTooltip
                      className="text-green"
                      width="16px"
                      height="16px"
                    />
                  </figure>
                </AppTooltip>
              </div>

              {limitDailyUsage ? (
                <div className="grid grid-cols-1 gap-y-2">
                  <Field
                    type="tel"
                    name="daily_quota"
                    className="bg-dark rounded p-3 disabled:text-tableGrayBody"
                    disabled={!limitDailyUsage}
                  />
                  <ErrorMessage
                    name="daily_quota"
                    component="div"
                    className="text-red"
                  />
                </div>
              ) : (
                <div className="h-[42px]"></div>
              )}

              <div className="grid grid-cols-1 gap-y-2">
                <label htmlFor="allowed_skills" className="text-sm">
                  {t("Allowed skills")}
                </label>
                <Field name={"allowed_skills"} component={SelectField} />
                <ErrorMessage
                  name="allowed_skills"
                  component="div"
                  className="text-red"
                />
              </div>
              <div
                dir={i18n.dir()}
                className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={onClose}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                  data-cy="create-or-edit-api-key-button" // Use button name because there are two of it
                >
                  {popupType === "CREATE" ? t("Create new key") : t("Save key")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

function ShowSecretKey({
  secretKey,
}: // setIsOpen,
{
  secretKey: string;
  // setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation("api-keys");

  const [keyCopied, setKeyCopied] = React.useState(false);

  const handleCopyKey = () => {
    if (!keyCopied) {
      setKeyCopied(true);
      setTimeout(() => {
        setKeyCopied(false);
      }, 6000);
    }
  };
  return (
    <div
      role="alert"
      className="text-white text-center grid"
      data-cy="api-key-success"
    >
      <h2 className="text-white">{t("Here is your api key")}</h2>
      <b className="text-yellow">{t("We will only show this key once!")}</b>

      <span className="py-4">{secretKey}</span>
      <CopyToClipboard text={secretKey} onCopy={() => handleCopyKey()}>
        <button
          disabled={keyCopied}
          className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5  whitespace-nowrap text-xs md:text-xs grid  gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-70 ${
            keyCopied ? " border  text-white" : "bg-shadeBlue text-white"
          } rounded-md mt-2`}
        >
          {keyCopied ? (
            <span role="alert">{t("Key copied to clipboard!")}</span>
          ) : (
            <span>{t("Copy to clipboard")}</span>
          )}
        </button>
      </CopyToClipboard>
    </div>
  );
}

const SelectField = ({ field, form }: any) => {
  const [previousSelected, setPreviousSelected] = React.useState([
    { value: "ALL", label: "ALL" },
  ]);
  const options = [{ value: "ALL", label: "ALL" }].concat(
    contentFile.skills.map((skill: any) => {
      return { value: skill.value, label: skill.name };
    }),
  );
  const onChange = (selectedOptions: any) => {
    const isAllSelected =
      selectedOptions.filter((opt: any) => opt.value === "ALL").length > 0;
    const allWasPreviously =
      previousSelected.filter((opt: any) => opt.value === "ALL").length > 0;

    if (!allWasPreviously && isAllSelected) {
      form.setFieldValue(field.name, [options[0].value]);
      setPreviousSelected(selectedOptions);
    } else {
      const selectedOptionsNew = selectedOptions.filter(
        (opt: any) => opt.value !== "ALL",
      );
      form.setFieldValue(
        field.name,
        selectedOptionsNew.map((opt: any) => opt.value),
      );
      setPreviousSelected(selectedOptionsNew);
    }
  };

  React.useEffect(() => {
    if (form.initialValues?.allowed_skills?.length > 0) {
      form.setFieldValue(field.name, form.initialValues?.allowed_skills);
    } else {
      form.setFieldValue(field.name, [options[0].value]);
    }

    if (
      form.initialValues?.daily_quota === -1 ||
      !form.initialValues?.daily_quota
    ) {
      form.setFieldValue("daily_quota", emptyAPIKey().daily_quota);
    }
    //eslint-disable-next-line
  }, []);

  // React.useEffect(() => {
  //   setOptions(
  //     options.map((opt: any) => {
  //       return {
  //         ...opt,
  //         isDisabled: opt.label === "ALL" ? false : isAllSelected,
  //       };
  //     }),
  //   );
  //   // eslint-disable-next-line
  // }, [isAllSelected]);

  const getValue = () => {
    return options.filter((option) => field.value.indexOf(option.value) >= 0);
  };

  return (
    <Select
      menuPlacement={"auto"}
      components={{ Option, MultiValue }}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      options={options}
      value={getValue()}
      // defaultValue={form.initialValues?.allowed_skills || options[1]}
      isMulti={true}
      onChange={onChange}
      onBlur={field.onBlur}
      styles={customStyles}
    />
  );
};
const MultiValue = (props: any) => {
  return (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

const Option = (props: any) => {
  return (
    <div className="text-sm">
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          className="checkbox"
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const customStyles = {
  // control: (provided: any, state: any) => ({
  //   ...provided,
  //   border: "1px solid #363636",
  // }),
  menu: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#252525",
    color: state.selectProps.menuColor,
  }),
  multiValue: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: "#252525",
  }),
  multiValueLabel: (provided: any, state: any) => ({
    ...provided,
    color: theme.colors.white,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    height: "62px",
    overflowY: "scroll",
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    ":active": {
      color: theme.colors.white,
    },
    ":hover": {
      color: theme.colors.white,
    },
  }),
  control: (provided: any) => ({
    ...provided,
    backgroundColor: "#252525",
    border: "1px solid #363636",
    color: "#FFF",
    ":active": {
      ...provided[":active"],
    },
    ":hover": {
      ...provided[":hover"],
    },
  }),
  option: (provided: any) => ({
    ...provided,
    display: "grid",
    color: "#FFF",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    backgroundColor: provided.isDisabled
      ? undefined
      : provided.isSelected
      ? theme.colors.blue
      : provided.isFocused
      ? theme.colors.blue
      : undefined,
    ":hover": {
      ...provided[":hover"],
      backgroundColor: theme.colors.blue,
    },
    ":active": {
      ...provided[":active"],
      backgroundColor: theme.colors.blue,
    },
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    lineHeight: "normal",
    color: "#FFF",
    backgroundColor: "#252525",
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    color: "#FFF",
    backgroundColor: "#252525",
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
