import React from "react";
import { Accordion } from "@mui/material";
import AccordionDetails from "./api-accordion-details";
import AccordionSummary from "./api-accordion-summary";
import "./docs-style.css";
export default function APIAccordion(props: any) {
  return (
    <>
      <Accordion disableGutters elevation={0} square {...props} />
    </>
  );
}

export { AccordionDetails, AccordionSummary };
