import theme from "../theme";

export const selectStyles = (isDark: any) =>
  ({
    control: (provided: any, { isDisabled }: any) => ({
      ...provided,
      height: "45px",
      width: "100%",
      border: isDark
        ? `1px solid ${theme.colors.darkGrayBorder}`
        : `1px solid ${theme.colors.borderLight} `,
      outline: "none",
      boxShadow: "none",
      borderRadius: "4px",
      color: isDark ? theme.colors.white : theme.colors.black,
      backgroundColor: isDisabled
        ? isDark
          ? theme.colors.dark
          : theme.colors.faWhite
        : isDark
        ? theme.colors.dark
        : theme.colors.faWhite,
      "&:hover": {
        border: isDark
          ? `1px solid ${theme.colors.darkGrayBorder}`
          : `1px solid ${theme.colors.borderLight} `,
      }, // border style on hover
    }),

    singleValue: (provided: any) => ({
      ...provided,
      color: isDark ? theme.colors.white : theme.colors.black,
    }),
    dropdownIndicator: (provided: any, { isDisabled }: any) => ({
      ...provided,
      svg: {
        fill: isDisabled ? theme.colors.transparent : theme.colors.dodgerBlue,
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.colors.formsgray,
    }),
    menu: (provided: any) => ({
      ...provided,
      color: isDark ? theme.colors.white : theme.colors.black,
      backgroundColor: isDark ? theme.colors.dark : theme.colors.faWhite,
      border: isDark
        ? `1px solid ${theme.colors.darkGrayBorder}`
        : `1px solid ${theme.colors.borderLight} `,
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),

    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      backgroundColor: isSelected
        ? isDark
          ? theme.colors.darkGrayBorder
          : theme.colors.numberGray
        : isFocused
        ? isDark
          ? theme.colors.dark
          : theme.colors.faWhite
        : isDark
        ? theme.colors.dark
        : theme.colors.faWhite,
      color: isSelected
        ? isDark
          ? theme.colors.faWhite
          : theme.colors.dark
        : isFocused
        ? isDark
          ? theme.colors.faWhite
          : theme.colors.dark
        : isDark
        ? theme.colors.faWhite
        : theme.colors.dark,

      "&:hover": {
        backgroundColor: isDark ? theme.colors.faWhite : theme.colors.dark,
        color: isDark ? theme.colors.dark : theme.colors.faWhite,
      },
      "&:active": {
        color: isDark ? theme.colors.dark : theme.colors.white,
        backgroundColor: isDark ? theme.colors.faWhite : theme.colors.dark,
      },
    }),
  } as any);
