import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NavLink,
  useLocation,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  clusterAtom,
  mainVideoAtom,
  mobileMenuOpenAtom,
  shouldPlayVideoAtom,
  videoCategoriesAccordionOpenedAtom,
} from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { useDocsParams } from "../../lib/use-docs-params";
import useIsSampleLibrary from "../../lib/use-is-sample-library";
import { getOpenedAccordionArray } from "../../pages/PipelinePage/utils";
import MediumTopNavigation from "./medium-top-navigation";

export default function TopNavigation({ screen }: { screen: string }) {
  const linkActive =
    "text-black dark:text-white font-mono dark:border-b-skillPink border-b-shadeBlue border-b font-light text-lg text-center";
  const linkInactive =
    "text-linkInactive dark:text-darkGrayOutput font-mono font-medium text-lg text-center";

  const { t } = useTranslation("index");
  const { userEventLogger } = useEventLogger();
  const location = useLocation();
  const mainVideo = useRecoilValue<any>(mainVideoAtom);
  const videoCategoryAccordions = useRecoilValue<any>(
    videoCategoriesAccordionOpenedAtom,
  );
  const setShouldPlayVideo = useSetRecoilState<boolean>(shouldPlayVideoAtom);
  const setMobileMenuOpen = useSetRecoilState<boolean>(mobileMenuOpenAtom);
  const { isSampleLibrary } = useIsSampleLibrary();
  const docsLink = useDocsParams();
  const [menuLinkName, setMenuLinkName] = useState("");
  const clusterState = useRecoilValue(clusterAtom);

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setMenuLinkName("Studio");
        break;
      case location.pathname.match(/^\/insights/)?.input:
        setMenuLinkName("Insights");
        break;
      case "/docs":
        setMenuLinkName("Docs");
        break;
      case "/how-to-videos":
        setMenuLinkName("Videos");
        break;

      default:
        break;
    }
  }, [location.pathname]);

  function reportNavigationEvent(route?: string) {
    setMobileMenuOpen(false);

    if (route === "videos") {
      if (location.pathname === "/how-to-videos") {
        return;
      }
    }
    if (route === "docs") {
      setShouldPlayVideo(false);
      if (location.pathname === "/documentation") {
        return;
      }
    }
    if (route === "studio") {
      setShouldPlayVideo(false);
      if (location.pathname === "/") {
        return;
      }
    }
    if (route === "insights") {
      setShouldPlayVideo(false);
      if (location.pathname === "/insights") {
        return;
      }
    }

    userEventLogger(UserEvent.NAVIGATE, {
      value: route,
    });
  }

  type CustomNavLinkType = {
    to: string;
    title: string;
    onClick: () => void;
  };

  function HowToVideosNavLink({ to, title, onClick }: CustomNavLinkType) {
    const resolved = useResolvedPath(to);
    const match = useMatch({
      path: resolved.pathname.replaceAll(" ", "%20"),
      end: true,
    });
    const currentLocation = window.location.pathname === "/how-to-videos";

    return (
      <NavLink
        //@ts-ignore
        className={match || currentLocation ? linkActive : linkInactive}
        to={to}
        onClick={onClick}
        children={({ isActive }) => {
          const linkActive = isActive || currentLocation;
          return (
            <>
              <span
                className={`py-1 2xl:hidden ${
                  linkActive ? "font-light" : "font-medium"
                }`}
              >
                {t("Videos")}
              </span>
              <span
                className={`py-1 hidden 2xl:inline whitespace-nowrap ${
                  linkActive ? "font-light" : "font-medium"
                }`}
              >
                {t(title)}
              </span>
            </>
          );
        }}
      />
    );
  }

  function AnalyticsNavLink({ to, title, onClick }: CustomNavLinkType) {
    const currentLocation = window.location.pathname.includes("insights");

    return (
      <NavLink
        //@ts-ignore
        className={currentLocation ? linkActive : linkInactive}
        to={to}
        onClick={onClick}
        children={({ isActive }) => {
          return (
            <>
              <span className={isActive ? "font-light" : "font-medium"}>
                {t("Insights")}
              </span>
              <sup
                className={`text-xs p-0 m-0 dark:text-skillPink text-shadeBlue`}
              >
                {t("BETA")}
              </sup>
            </>
          );
        }}
      />
    );
  }

  function PipelinePageNavLink() {
    const isHome = [
      "/pipeline-input",
      "/pipeline-skills",
      "/pipeline-output",
      "/sample-library",
    ].includes(location.pathname);

    return (
      <NavLink
        //@ts-ignore
        onClick={() => reportNavigationEvent("studio")}
        className={({ isActive }: any) =>
          isActive || isHome ? linkActive : linkInactive
        }
        to={isSampleLibrary ? "/?textInput=sample-library" : "/"}
        children={({ isActive }) => {
          const isLinkActive = isActive || isHome;
          return (
            <>
              <span
                className={`py-1 hidden 2xl:inline whitespace-nowrap ${
                  isLinkActive ? "font-light" : "font-medium"
                }`}
              >
                {t("Language")} {t("Studio")}
              </span>
              <span
                className={`py-1 2xl:hidden ${
                  isLinkActive ? "font-light" : "font-medium"
                }`}
              >
                {t("Studio")}
              </span>
            </>
          );
        }}
      />
    );
  }

  function getVideoParams() {
    if (mainVideo && mainVideo?.link !== null) {
      const currentParams = getOpenedAccordionArray(videoCategoryAccordions);

      if (currentParams) {
        return isSampleLibrary
          ? `/how-to-videos/${mainVideo?.title}?category=${currentParams}?textInput=sample-library`
          : `/how-to-videos/${mainVideo?.title}?category=${currentParams}`;
      } else {
        return isSampleLibrary
          ? `/how-to-videos/${mainVideo?.title}?textInput=sample-library`
          : `/how-to-videos/${mainVideo?.title}`;
      }
    } else {
      return isSampleLibrary
        ? "/how-to-videos?textInput=sample-library"
        : "/how-to-videos";
    }
  }

  return (
    <>
      {screen !== "medium-nav" && (
        <div className={`${screen === "medium-nav" ? "hidden" : ""}`}>
          <MediumTopNavigation menuLinkName={menuLinkName} />
        </div>
      )}

      <div
        className={`${
          screen === "medium"
            ? "hidden"
            : screen === "medium-nav"
            ? "grid grid-flow-row gap-y-4"
            : "md:grid-flow-col grid gap-x-6 2xl:gap-x-12  gap-y-8 md:gap-y-0 "
        } items-center`}
      >
        <PipelinePageNavLink />

        <AnalyticsNavLink
          onClick={() => {
            reportNavigationEvent("insights");
          }}
          to={`/insights/${
            clusterState.clusterType === "SELF"
              ? "my-collection"
              : "sample-collection"
          }/${clusterState.clusterTitle}`}
          title={t("Insights")}
        />

        {/* <NavLink
          //@ts-ignore
          className={({ isActive }) => (isActive ? linkActive : linkInactive)}
          onClick={() => reportNavigationEvent("analytics")}
          to={
            isSampleLibrary
              ? "/analytics?textInput=sample-library"
              : "/analytics"
          }
          children={({ isActive }) => {
            return (
              <>
                <span className={isActive ? "font-light" : "font-medium"}>
                  {t("Analytics")}
                </span>
                <sup
                  className={`text-xs p-0 m-0 ${
                    location.pathname === "/analytics"
                      ? "dark:text-skillPink text-shadeBlue"
                      : ""
                  }`}
                >
                  {t("BETA")}
                </sup>
              </>
            );
          }}
        /> */}

        <>
          <NavLink
            //@ts-ignore
            className={({ isActive }) => (isActive ? linkActive : linkInactive)}
            onClick={() => reportNavigationEvent("docs")}
            to={docsLink}
            children={({ isActive }) => {
              return (
                <span
                  className={`py-1 ${isActive ? "font-light" : "font-medium"}`}
                >
                  {t("Docs")}
                </span>
              );
            }}
          />

          <HowToVideosNavLink
            onClick={() => {
              reportNavigationEvent("videos");
            }}
            to={getVideoParams()}
            title={t("How-To Videos")}
          />
        </>
      </div>
    </>
  );
}
