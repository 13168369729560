import React from "react";
import { useTranslation } from "react-i18next";
import { IconSkillNotFound } from "../../../assets/SvgIcons";
import SuggestSkill from "./suggest-skill";

type SkillNotFoundType = {
  setSearch: (value: string) => void;
};
function SkillNotFound({ setSearch }: SkillNotFoundType) {
  const { t, i18n } = useTranslation("index");
  return (
    <div className="grid  items-center  gap-y-2 grid-rows-1fr-auto">
      <div className="grid items-center overflow-y-auto md:h-[calc(100vh-300px)] px-1">
        <div className="-mt-10">
          <div className="py-4 grid justify-center">
            <IconSkillNotFound width="32px" height="32px" />
          </div>
          <p
            dir={i18n.dir()}
            className="text-lg text-center font-bold m-0 text-black dark:text-white grid justify-center"
          >
            {t("Skill not found")}
          </p>
          <p
            dir={i18n.dir()}
            className="text-center text-textLight leading-6 grid justify-center"
          >
            {t("Please try to retype your search.")}
            <br />
            {t("Or clear your search to see all skills.")}
          </p>
          <div
            dir={i18n.dir()}
            className="mt-12 self-center grid justify-center"
          >
            <button
              className="clear-search-button text-sm text-shadeBlue border border-shadeBlue hover:bg-shadeBlue hover:text-white"
              onClick={() => {
                setSearch("");
              }}
            >
              {t("Clear search")}
            </button>
          </div>
        </div>
      </div>
      <div className="grid items-end">
        <SuggestSkill isColumnSkill={false} />
      </div>
    </div>
  );
}

export default SkillNotFound;
