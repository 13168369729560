import Menu from "@mui/material/Menu";
import { Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import theme from "../theme";
import { useThemeColor } from "./theme-color";
import AppTooltip from "./tooltip";

export default function ConfirmPopover({
  openedPopover = () => {},
  closedPopover = () => {},
  button,
  onConfirm,
  title,
  text,
  tooltip = "",
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  button: JSX.Element;
  onConfirm: Function;
  title: string;
  text: string;
  tooltip?: string;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <AppTooltip title={tooltip}>
        <button onClick={handleClick}>{button}</button>
      </AppTooltip>
      <ConfirmChangesPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onConfirm={onConfirm}
        title={title}
        text={text}
      />
    </React.Fragment>
  );
}

type IConfirmPopup = {
  anchorEl: any;
  open: boolean;
  onClose: any;
  onConfirm: any;
  title: string;
  text: string;
};

const ConfirmChangesPopup = React.forwardRef(
  (props: IConfirmPopup, ref: any) => {
    const { anchorEl, open, onClose, onConfirm, title, text } = props;
    const { isDark } = useThemeColor();
    return (
      <Menu
        anchorEl={anchorEl}
        id="add-skill-order"
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: isDark
              ? theme.colors.darkBackground
              : theme.catskill,
            color: theme.colors.white,
            padding: theme.spacing[4],
            width: "280px !important",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <ConfirmPopup
          onClose={onClose}
          title={title}
          text={text}
          onConfirm={onConfirm}
        />
      </Menu>
    );
  },
);

const ConfirmPopup = ({
  onClose = () => {},
  title,
  text,
  onConfirm,
}: {
  onClose: any;
  title: string;
  text: string;
  onConfirm: Function;
}) => {
  const [error, setError] = React.useState("");
  const { t } = useTranslation("content-management");

  const onSubmit = async () => {
    try {
      onConfirm();
      onClose();
    } catch (error: any) {
      console.log(error);
      setError(error);
    }
  };
  if (error) {
    return <div>{t(error)}</div>;
  }

  return (
    <div>
      <Formik initialValues={{}} onSubmit={onSubmit}>
        {() => (
          <Form className="grid grid-cols-1 font-poppins text-black dark:text-white">
            <p className="text-center font-bold text-xl">{t(title)}</p>
            <span className="font-normal text-center">{t(text)}</span>
            <div
              dir={i18n.dir()}
              className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
            >
              <button
                className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-autofont-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                onClick={onClose}
                type="button"
              >
                {t("Cancel")}
              </button>
              <button
                className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                type="submit"
              >
                {t("Yes")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
