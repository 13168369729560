import FocusTrap from "focus-trap-react";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRecoilState } from "recoil";
import { tabbable } from "tabbable";
import { IconMobileMenu, IconMobileMenuX } from "../assets/SvgIcons";
import { mobileMenuOpenAtom } from "../lib/atoms";

interface MobileMenuProps {
  mobileComponent: React.ReactNode;
  desktopComponent: React.ReactNode;
  showMobileOnDesktop?: boolean;
  showDesktopOnMobile?: boolean;
  breakPoint?: number;
  dir?: "ltr" | "rtl";
  styleNav?: React.CSSProperties;
  classNameNav?: string;
  styleMobileNav?: React.CSSProperties;
  classNameMobileNav?: string;
  hamburgerOpenComponent?: React.ReactNode;
  hamburgerCloseComponent?: React.ReactNode;
}

export const useMobileMenu = () => useContext(MobileMenuContext);

export default function MobileMenu({
  mobileComponent,
  desktopComponent,
  showMobileOnDesktop = false,
  showDesktopOnMobile = false,
  breakPoint = 768,
  dir = "ltr",
  styleNav = {},
  styleMobileNav = {},
  classNameNav = "",
  classNameMobileNav = "",
  hamburgerOpenComponent = <IconMobileMenu />,
  hamburgerCloseComponent = <IconMobileMenuX />,
}: MobileMenuProps) {
  const [menuOpen, setMenuOpen] = useRecoilState(mobileMenuOpenAtom);
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const openMenu = () => {
    setMenuOpen(false);
  };
  breakPoint = parseInt(breakPoint.toString());
  return (
    <>
      <MobileMenuContext.Provider value={{ closeMenu, openMenu, menuOpen }}>
        <nav style={styleNav} className={classNameNav}>
          <div className="mobile__menu__mobile__component h-8 w-8 z-20">
            <MobileMenuContainer
              dir={dir}
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              classNameMobileNav={classNameMobileNav}
              styleMobileNav={styleMobileNav}
              hamburgerOpenComponent={hamburgerOpenComponent}
              hamburgerCloseComponent={hamburgerCloseComponent}
            >
              {mobileComponent}
            </MobileMenuContainer>
          </div>
          <div className="mobile__menu__desktop__component">
            {desktopComponent}
          </div>
        </nav>
      </MobileMenuContext.Provider>
      <style>{`
        div.mobile__menu__mobile__component {
          width: 32px;
        }
        @media only screen and (max-width: ${breakPoint}px) {
          div.mobile__menu__desktop__component {
            display: ${showDesktopOnMobile ? "block" : "none"};
          }
        }
        @media only screen and (min-width: ${breakPoint + 1}px) {
          div.mobile__menu__mobile__component {
            display: ${showMobileOnDesktop ? "block" : "none"};
          }
        }
      `}</style>
    </>
  );
}

const MobileMenuContext = createContext(
  {} as {
    menuOpen: boolean;
    openMenu: Setter<Dispatch<SetStateAction<boolean>>>;
    closeMenu: Setter<Dispatch<SetStateAction<boolean>>>;
  },
);

//eslint-disable-next-line
type Setter<T> = () => void;

function MobileMenuContainer({
  children,
  dir,
  classNameMobileNav,
  styleMobileNav,
  hamburgerOpenComponent,
  hamburgerCloseComponent,
  menuOpen,
  setMenuOpen,
}: {
  children: React.ReactNode;
  dir: "ltr" | "rtl";
  classNameMobileNav: string;
  styleMobileNav: React.CSSProperties;
  hamburgerOpenComponent: React.ReactNode;
  hamburgerCloseComponent: React.ReactNode;
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <>
      <FocusTrap active={menuOpen}>
        <div className="mobile__menu__input__wrapper">
          <MobileMenuInput menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <MobileMenuLabel
            menuOpen={menuOpen}
            hamburgerOpenComponent={hamburgerOpenComponent}
            hamburgerCloseComponent={hamburgerCloseComponent}
          />
          <MobileMenuSlidingMenu
            dir={dir}
            menuOpen={menuOpen}
            classNameMobileNav={classNameMobileNav}
            styleMobileNav={styleMobileNav}
          >
            {children}
          </MobileMenuSlidingMenu>
        </div>
      </FocusTrap>
      <style>{`
        .mobile__menu__input__wrapper {
          position: relative;
        }
      `}</style>
      <style>{`
        .mobile__menu__checkbox:checked ~ .mobile__menu__sliding__menu {
          transform: translate(0, 0) !important;
          z-index: 18000;
        }
        .mobile__menu__checkbox:checked ~ .mobile__menu__button {
          // z-index: 21000;
          z-index: 1;
        }
        .mobile__menu__checkbox:checked {
          // z-index: 21000;
          z-index: 1;
        }
        .mobile__menu__button {
          // z-index: 21000;
          z-index: 1;
        }
      `}</style>
    </>
  );
}

function MobileMenuInput({
  menuOpen,
  setMenuOpen,
}: {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}) {
  useEffect(() => {
    if (menuOpen === true) {
      document.body.classList.add("mobile__menu__body__noscroll");
    } else {
      document.body.classList.remove("mobile__menu__body__noscroll");
    }
    const checkbox = document.getElementById(
      "mobile__menu__checkbox",
    ) as HTMLInputElement;
    if (checkbox && !menuOpen) {
      checkbox.checked = false;
    }
  }, [menuOpen]);

  return (
    <>
      <input
        type="checkbox"
        id="mobile__menu__checkbox"
        className="mobile__menu__checkbox"
        onClick={() => {
          setMenuOpen((prev) => !prev);
        }}
        aria-expanded={menuOpen}
      />
      <style>{`
        .mobile__menu__body__noscroll {
          overflow: hidden;
        }
      `}</style>
      <style>{`
        input.mobile__menu__checkbox {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: none;
          background: none;
          appearance: none;
          outline: none;
        }
      `}</style>
    </>
  );
}

function MobileMenuLabel({
  menuOpen,
  ariaOpen = "open menu",
  ariaClose = "close menu",
  hamburgerOpenComponent = <IconMobileMenu />,
  hamburgerCloseComponent = <IconMobileMenuX />,
}: {
  menuOpen: boolean;
  ariaOpen?: string;
  ariaClose?: string;
  hamburgerOpenComponent?: React.ReactNode;
  hamburgerCloseComponent?: React.ReactNode;
}) {
  return (
    <>
      <label
        className="mobile__menu__button bg-white dark:bg-inputDarkGray rounded-md h-8 w-8 grid items-center justify-items-center"
        htmlFor="mobile__menu__checkbox"
        aria-label={menuOpen ? ariaClose : ariaOpen}
      >
        {menuOpen ? hamburgerCloseComponent : hamburgerOpenComponent}
      </label>
      <style>{`
        label.mobile__menu__button {
          border: none;
          padding: 0;
          position: relative;
          width: min-content;
          cursor: pointer;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 32px;
          height: 32px;
        }
      `}</style>
    </>
  );
}

function MobileMenuSlidingMenu({
  children,
  dir,
  menuOpen,
  classNameMobileNav,
  styleMobileNav,
}: {
  children: React.ReactNode;
  dir: "ltr" | "rtl";
  menuOpen: boolean;
  classNameMobileNav: string;
  styleMobileNav: React.CSSProperties;
}) {
  const ref = useRef(null);
  const [tabbables, setTabbables] = useState([] as any);
  useEffect(() => {
    if (menuOpen) {
      tabbables.forEach((element: any) => {
        element.tabIndex = 0;
      });
    } else {
      // @ts-ignore
      const newTabbables = tabbable(ref.current);
      setTabbables(newTabbables);
      newTabbables.forEach((element: any) => {
        element.tabIndex = -1;
      });
    }
    //eslint-disable-next-line
  }, [menuOpen]);
  return (
    <>
      <div
        ref={ref}
        className={`mobile__menu__sliding__menu ${classNameMobileNav}`}
        style={styleMobileNav}
      >
        {children}
      </div>
      <style>{`
        .mobile__menu__sliding__menu {
          width: 100%;
          top: 66px !important;
          height: 100vh;
          transform: translate(${dir === "ltr" ? "100%" : "-100%"}, 0);
          transition: transform 300ms ease-in-out;
          position: fixed;
          bottom: 0;
          right: ${dir === "ltr" ? 0 : "auto"};
          left: ${dir === "rtl" ? 0 : "auto"};
          display: block;
          margin: 0;
          z-index: 18000;
          -webkit-overflow-scrolling: touch;
          overflow-y: scroll;
          scrollbar-width: none; /* Firefox */
        }
        ::-webkit-scrollbar {
          width: 0px; /* Remove scrollbar space */
          background: transparent; /* Optional: just make scrollbar invisible */
        }
      `}</style>
    </>
  );
}
