import React from "react";
import { useTranslation } from "react-i18next";
import { IconArrowRight } from "../../../assets/SvgIcons";
import AppModal from "../../../components/app-modal";
import SuggestSkillForm from "../../../components/suggest-skill-form";
import theme from "../../../theme";
import { useEventLogger } from "../../../lib/event_logger";

type SuggestSkillType = {
  isColumnSkill: boolean;
};
function SuggestSkill({ isColumnSkill }: SuggestSkillType) {
  const { t } = useTranslation("index");
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);
  const { userUIEventLogger } = useEventLogger();

  return (
    <>
      <div className="grid p-1 -mt-1">
        <button
          onClick={() => {
            userUIEventLogger("studio__skill_library__suggest_skill");
            setModalOpen(true);
          }}
          className={`p-2 px-4 text-black dark:text-white rounded-sm mt-2 relative shadow-md dark:shadow-none bg-white dark:bg-darkSkillDark dark:hover:bg-lightBlack h-[43px] gap-x-2  grid grid-flow-col items-center w-full ${
            isColumnSkill ? "hidden" : "py-2"
          }`}
        >
          <span className="grid justify-start dark:text-brightYellow text-black  font-mono text-sm underline new-underline">
            {t("Suggest a Skill")}
          </span>
          <figure className="grid justify-end">
            <IconArrowRight className="dark:text-brightYellow text-brightYellow self-end" />
          </figure>
        </button>
      </div>
      <AppModal isOpen={isModalOpen} setIsOpen={setModalOpen}>
        <SuggestSkillForm setIsOpen={setModalOpen} />
      </AppModal>
      <style>{` 
      .new-underline {
        text-decoration-color: ${theme.colors.brightYellow};
      }
      `}</style>
    </>
  );
}

export default SuggestSkill;
