import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import logo from "../assets/logo-forms.svg";
import darkModeLogo from "../assets/logo-gray.svg";
import { useEventLogger } from "../lib/event_logger";
import { capitalizeFirstCharacter } from "../pages/PipelinePage/utils";
import { useUser } from "./auth/auth";
import { emailEvent } from "./auth/data";
import { LottieLoader } from "./Loader";
import { useThemeColor } from "./theme-color";

type SuggestSkillFormType = {
  setIsOpen: (isOpen: boolean) => void;
};

export default function QuotaRequest({ setIsOpen }: SuggestSkillFormType) {
  const { t, i18n } = useTranslation("index");
  const { isDark } = useThemeColor();
  const { user } = useUser();
  const isRTL = () => {
    return i18n.dir() === "rtl";
  };

  const [formLoading, setFormLoading] = React.useState<boolean>(false);
  const [nextMessage, setNextMessage] = React.useState<JSX.Element | null>(
    null,
  );
  const { userUIEventLogger } = useEventLogger();

  const inputContainer =
    "p-5 bg-white dark:bg-darkBackground border border-borderLight dark:border-darkGrayBorder rounded-[5px] text-black dark:text-white focus:outline-none w-full placeholder-formsgray";

  const handleSubmit = async (values: any) => {
    userUIEventLogger("app__user_menu__send_skill_suggestion");
    setFormLoading(true);
    const formFields = values;

    if (user?.displayName) formFields.user_name = user?.displayName;
    formFields.user_id = user?.uid;

    const title = `User ${
      user?.email ? user?.email : "Anonymous"
    } suggested a new skill`;

    const result = await emailEvent({
      title: title,
      body: parseFormValues(formFields),
    });

    const successMessage = (
      <span>
        {t("Thank you!")}
        <br />
        {t("Your suggestion has been sent!")}
      </span>
    );
    if (result.success) {
      setNextMessage(successMessage);
    } else {
      setNextMessage(t("We can't process your suggestion at this time."));
    }
    setFormLoading(false);
  };

  const parseFormValues = (formFields: any) => {
    let body = "";
    for (const key in formFields) {
      if (Object.hasOwnProperty.call(formFields, key)) {
        const keyName = capitalizeFirstCharacter(key);
        if (typeof formFields[key] === "object") {
          body += `${keyName}: ${JSON.stringify(
            formFields[key]?.value || "User didn't provide a value",
          )}\n`;
        } else if (typeof formFields[key] === "boolean") {
          body += `${keyName}: ${formFields[key] === true ? "Yes" : "No"}\n`;
        } else {
          body += `${keyName}: ${formFields[key]}\n`;
        }
      }
    }
    return body;
  };

  const initialSuggestionValues = {
    name_suggestion: "",
    where_suggestion: "",
    skill_purpose: "",
  };

  if (formLoading)
    return (
      <div>
        <div className="grid justify-center">
          <figure className="image w-[155px] h-[37px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <h1 className="grid text-2xl dark:text-white text-black font-bold text-center">
          <LottieLoader />
        </h1>
      </div>
    );

  if (nextMessage)
    return (
      <div className="grid space-x-2 h-[250px] py-2">
        <div className="grid justify-center">
          <figure className="image w-[155px] h-[37px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <div className="grid grid-rows-1fr-auto justify-center">
          <h1 className="grid text-xl dark:text-white text-black font-normal font-poppins text-center break-words p-2">
            {nextMessage}
          </h1>
          <button
            onClick={() => {
              setIsOpen(false);
              setTimeout(() => {
                setNextMessage(null);
              }, 200);
            }}
            className={`bg-shadeBlue justify-self-center rounded-md text-white outline-none w-[165px]  align-middle p-3 hover:bg-opacity-60`}
          >
            <span className="text-center align-middle">{t("Go back")}</span>
          </button>
        </div>
      </div>
    );

  const suggestFormSchema: object = Yup.object().shape({
    skill_purpose: Yup.string()
      .min(3, t("Skill purpose is too short"))
      .required(t("Skill purpose is required")),
  });

  return (
    <div>
      <div className="grid justify-center">
        <figure className="image w-[155px] h-[37px] pt-4">
          <img src={isDark ? darkModeLogo : logo} alt="Logo" />
        </figure>
      </div>
      <h1 className="grid text-xl dark:text-white text-black font-bold text-center  font-poppins py-6">
        {t("Suggest a Skill")}
      </h1>
      <p className="text-black dark:text-white text-sm font-normal text-center font-poppins">
        {t(
          "We are always happy to receive requests for new language skills, please tell us more",
        )}
      </p>

      <Formik
        initialValues={initialSuggestionValues}
        validationSchema={suggestFormSchema}
        onSubmit={async (values) => {
          console.log("Values :: ", values);
          await handleSubmit(values);
        }}
      >
        {({ handleSubmit }: any) => (
          <div className="space-y-6 p-6">
            <Form className="space-y-3 overflow-y-auto max-h-[calc(100vh-350px)] min-h-[100px]  font-poppins">
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  component="textarea"
                  name="name_suggestion"
                  type="textarea"
                  placeholder={t("How would you name the new skill?")}
                  className={`${inputContainer}`}
                />
              </div>
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  component="textarea"
                  name="skill_purpose"
                  type="textarea"
                  placeholder={t("What would the new skill do?")}
                  className={`${inputContainer}`}
                />
                <ErrorMessage name="skill_purpose" component="div">
                  {(msg) => (
                    <div
                      role="alert"
                      id="skill_purpose"
                      className="invalid-feedback text-red text-left pt-2"
                    >
                      {t(msg)}
                    </div>
                  )}
                </ErrorMessage>
              </div>
              <div className={`${isRTL() ? "text-right" : "text-left"}`}>
                <Field
                  component="textarea"
                  name="where_suggestion"
                  type="textarea"
                  placeholder={t("Where would you use the new skill?")}
                  className={`${inputContainer}`}
                />
              </div>
            </Form>
            <button
              onClick={handleSubmit}
              type="submit"
              className={`bg-shadeBlue  rounded-md text-white outline-none w-full p-4 hover:bg-opacity-60`}
            >
              {t("Send Suggestion")}
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
}
