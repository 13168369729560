import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import useIsSampleLibrary from "../../lib/use-is-sample-library";
import "./styles.css";

const InputTabs = () => {
  const { t } = useTranslation("index");
  const navigate = useNavigate();
  const { userEventLogger } = useEventLogger();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSampleLibrary } = useIsSampleLibrary();

  return (
    <div className="grid grid-cols-2 gap-x-2 h-[58px] w-full">
      <button
        data-cy="select-input-tab-input"
        className={`grid h-full rounded-t-md  text-center justify-center min-w-0 w-[100%] ${
          !isSampleLibrary
            ? "dark:bg-darkGrayBackground bg-white"
            : "bg-faWhite dark:bg-inputTabGrayDark dark:hover:bg-darkGrayBackground hover:bg-white"
        }`}
        onClick={() => {
          searchParams.delete("textInput");
          setSearchParams(searchParams);
          userEventLogger(UserEvent.SWITCH_INPUT_TAB, { value: "input" });
        }}
      >
        <p
          className={`self-center p-2 font-medium dark:font-normal ${
            !isSampleLibrary
              ? "dark:text-white text-black"
              : "text-inputGray font-medium"
          } font-poppins text-md xl2:text-lg`}
        >
          {t("Text Input")}
        </p>
      </button>
      <button
        data-cy="select-input-tab-sample"
        className={`grid h-full rounded-t-md text-center justify-center min-w-0  w-[100%] ${
          isSampleLibrary
            ? "dark:bg-darkGrayBackground bg-white"
            : "bg-faWhite dark:bg-inputTabGrayDark dark:hover:bg-darkGrayBackground hover:bg-white"
        }`}
        onClick={() => {
          navigate("?textInput=sample-library");
          userEventLogger(UserEvent.SWITCH_INPUT_TAB, {
            value: "sample_library",
          });
        }}
      >
        <p
          className={`self-center font-medium dark:font-normal ${
            isSampleLibrary
              ? "dark:text-white text-black"
              : "text-inputGray font-medium"
          } font-poppins text-md xl2:text-lg`}
        >
          {t("Sample Library")}
        </p>
      </button>
    </div>
  );
};

export default InputTabs;
