import { ReactNode, useState } from "react";
import { useRecoilValue } from "recoil";
import { activeFeaturesSelector } from "../../lib/atoms";
import { FeatureType, FormNameType } from "../../lib/interfaces";
import { useUser } from "./auth";
import ExperimentModal from "./experiment-modal";
import ForgotPasswordModal from "./forgot-password-modal";
import LoginForm from "./login-form";
import ResetPasswordModal from "./reset-password-modal";
import SignUpForm from "./signup-form";
import ValidateEmailModal from "./validate-email-modal";
import VerificationSuccessModal from "./verification-success-modal";

type AuthFormsType = {
  userCompletedSignup?: boolean;
  formToShow: FormNameType;
  setIsOpen: (isOpen: boolean) => void;
};
export default function AuthForms({
  userCompletedSignup,
  formToShow,
  setIsOpen,
}: AuthFormsType) {
  const { user, loading } = useUser();

  const [form, setForm] = useState<FormNameType>(
    formToShow ? formToShow : "login",
  );
  const activeFeaturesList = useRecoilValue<Array<FeatureType>>(
    activeFeaturesSelector,
  );

  if (
    !userCompletedSignup &&
    user &&
    !loading &&
    activeFeaturesList?.some(
      (feature: FeatureType) => feature.name === "signup-form",
    )
  )
    return (
      <FormWrapper>
        <SignUpForm setForm={setForm} />
      </FormWrapper>
    );

  if (form === "none") return null;
  return (
    <FormWrapper>
      {form === "login" && (
        <LoginForm setIsOpen={setIsOpen} setForm={setForm} />
      )}
      {form === "signup" && <SignUpForm setForm={setForm} />}
      {form === "forgot-password" && <ForgotPasswordModal setForm={setForm} />}
      {form === "validate-email" && <ValidateEmailModal setForm={setForm} />}
      {form === "verification-success" && (
        <VerificationSuccessModal setForm={setForm} />
      )}
      {form === "reset-password" && <ResetPasswordModal setForm={setForm} />}
      {form === "experiment-modal" && <ExperimentModal setForm={setForm} />}
    </FormWrapper>
  );
}

function FormWrapper({ children }: { children: ReactNode }) {
  return (
    <div className="grid justify-items-center items-center gap-y-4 bg-white dark:bg-darkBackground rounded-md">
      {children}
    </div>
  );
}
