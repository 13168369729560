import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/logo-forms.svg";
import darkModeLogo from "../../assets/logo-gray.svg";
import { IconEyePassword } from "../../assets/SvgIcons";
import theme from "../../theme";
import { useThemeColor } from "../theme-color";
import * as Yup from "yup";
import { verifyForgotPassword, changeUserPassword } from "./data";
import { forgotPasswordTokenAtom } from "../../lib/atoms";
import { useRecoilState } from "recoil";
import { LottieLoader } from "../Loader";
import { Box, CircularProgress } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../auth/auth";
import { useNavigate } from "react-router-dom";
import { FormNameType } from "../../lib/interfaces";

type FormType = {
  setForm: Dispatch<FormNameType>;
};

export default function ResetPasswordModal({ setForm }: FormType) {
  const { t } = useTranslation("index");
  const { isDark } = useThemeColor();
  const [forgotPasswordToken, setForgotPasswordToken] = useRecoilState(
    forgotPasswordTokenAtom,
  );
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [modalLoading, setModalLoading] = React.useState<boolean>(true);
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [showPassword2, setShowPassword2] = React.useState<boolean>(false);
  const [userData, setUserData] = React.useState<any>({});
  const formikRef: any = React.useRef(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const verifyForgot = async () => {
      try {
        const result = await verifyForgotPassword(forgotPasswordToken);
        if (result?.success) {
          setUserData(result.data);
          setModalLoading(false);
        } else {
          setForm("login");
          navigate("/login");

          setModalLoading(false);
        }
        setForgotPasswordToken("");
      } catch (e: any) {
        setModalLoading(false);
        console.log(e);
      }
    };
    if (forgotPasswordToken) {
      verifyForgot();
    } else {
      console.log("No forgot token found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = () => {
    formikRef.current.submitForm();
  };
  const handleSubmit = async (values: any) => {
    setButtonLoading(true);
    const newDetails = {
      token: userData?.token,
      uid: userData?.userID,
      password: values.newPassword,
    };

    try {
      const result = await changeUserPassword(newDetails);

      if (result?.success) {
        const login = await signInWithEmailAndPassword(
          auth,
          result?.userData?.email,
          result?.userData?.password,
        );
        navigate("/");
        setForgotPasswordToken("");
        if (!login) {
          setErrorMessage(result?.message);
          setButtonLoading(false);
        }
      } else {
        setErrorMessage(result?.message);
        setButtonLoading(false);
      }
      console.log(result);
    } catch (e: any) {
      console.log("Exception: ", e);
    }
    console.log(newDetails);
  };

  const inputContainer =
    "p-4 bg-white dark:bg-inputDarkGray border border-borderLight dark:border-darkGrayBorder rounded-[5px] text-black h-[54px] dark:text-white focus:outline-none w-full placeholder-formsgray text-[14px]";

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(6, t("Password is too short"))
      .max(14, t("Password is too long"))
      .required(t("Password is required")),
    repeatPassword: Yup.string().test(
      "passwords match",
      "Passwords must match",
      function (value) {
        return this.parent.newPassword === value;
      },
    ),
  });

  if (modalLoading) {
    return (
      <div className="h-[618px] grid items-center">
        <div className="py-3 grid justify-center items-center">
          <LottieLoader />
        </div>
      </div>
    );
  }
  return (
    <div className="h-[618px] grid grid-rows-1fr-auto">
      <div className="space-y-3">
        <div className="py-3 grid justify-center">
          <figure className="image w-[110px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <h1 className="grid justify-center text-2xl dark:text-white text-black font-bold m-0 p-0 py-6">
          {t("Reset Password")}
        </h1>
        <p className="grid text-center text-black dark:text-white font-normal font-poppins text-[16px] leading-8 m-0 p-0 ">
          {t("Please create new password")}
        </p>
        <div className="py-4 w-[279px]">
          <Formik
            innerRef={formikRef}
            validationSchema={validationSchema}
            initialValues={{
              newPassword: "",
              repeatPassword: "",
            }}
            onSubmit={async (values) => {
              handleSubmit(values);
            }}
          >
            {() => (
              <div className="space-y-6">
                <Form className="grid gap-y-7 overflow-y-auto font-poppins">
                  <div>
                    <Field name="newPassword" type="text">
                      {({ field }: any) => (
                        <label htmlFor="newPassword" className="relative">
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            autoComplete="current_password"
                            className={`${inputContainer} `}
                            maxLength={14}
                            {...field}
                          />
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute mt-1 right-4 dark:bg-inputDarkGray bg-white h-[49px] select-none"
                          >
                            <IconEyePassword />
                          </button>
                        </label>
                      )}
                    </Field>

                    <ErrorMessage name="newPassword" component="div">
                      {(msg) => (
                        <div
                          role="alert"
                          id="newPassword"
                          className="invalid-feedback text-red text-left pt-1 font-normal text-xs"
                        >
                          {t(msg)}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div>
                    <Field name="repeatPassword" type="text">
                      {({ field }: any) => (
                        <label htmlFor="repeatPassword" className="relative">
                          <input
                            type={showPassword2 ? "text" : "password"}
                            placeholder="Repeat New Password"
                            autoComplete="current_password"
                            className={`${inputContainer} `}
                            maxLength={14}
                            {...field}
                          />
                          <button
                            type="button"
                            onClick={() => setShowPassword2(!showPassword2)}
                            className="absolute mt-1 right-4 dark:bg-inputDarkGray bg-white h-[49px] select-none"
                          >
                            <IconEyePassword />
                          </button>
                        </label>
                      )}
                    </Field>

                    <ErrorMessage name="repeatPassword" component="div">
                      {(msg) => (
                        <div
                          role="alert"
                          id="repeatPassword"
                          className="invalid-feedback text-red text-left pt-1 font-normal text-xs"
                        >
                          {t(msg)}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </Form>

                <style>
                  {` 
        
          input:-webkit-autofill,
          input:-webkit-autofill:hover, 
          input:-webkit-autofill:focus,
          textarea:-webkit-autofill,
          textarea:-webkit-autofill:hover,
          textarea:-webkit-autofill:focus,
          select:-webkit-autofill,
          select:-webkit-autofill:hover,
          select:-webkit-autofill:focus {
            border: 1px solid ${
              isDark ? theme.colors.darkGrayBorder : theme.colors.grayBorder
            };
            -webkit-text-fill-color: ${
              isDark ? theme.colors.white : theme.colors.grayBorder
            };
            -webkit-box-shadow: 0 0 0px 1000px ${
              isDark ? theme.colors.inputDarkGray : theme.colors.white
            } inset;
            transition: background-color 5000s ease-in-out 0s;
      }`}
                </style>
              </div>
            )}
          </Formik>
        </div>
      </div>

      <div className="grid items-end">
        {errorMessage && (
          <p className="text-red font-normal text-md text-center p-0">
            {errorMessage}
          </p>
        )}

        {buttonLoading ? (
          <button
            type="button"
            className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none  p-4 hover:bg-opacity-60 w-full grid grid-flow-col`}
            disabled
          >
            <span>
              <Box>
                <CircularProgress
                  style={{
                    width: 15,
                    height: 15,
                    color: "white",
                    padding: 0,
                    margin: 0,
                  }}
                />
              </Box>
            </span>
          </button>
        ) : (
          <button
            onClick={submitForm}
            type="submit"
            className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none w-full p-4 hover:bg-opacity-60`}
          >
            {t("Save new password and Log In")}
          </button>
        )}

        <div className="grid justify-center py-3 pt-10">
          <div className="text-black dark:text-white text-sm font-poppins font-normal">
            {t("Have no account?")}
            <button
              className="text-shadeBlue px-2 font-mono font-bold justify-self-center"
              onClick={() => {
                setForm("signup");
                setForgotPasswordToken("");
              }}
            >
              {t("Sign Up")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
