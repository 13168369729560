import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import ErrorsPopover from "../../components/errors-popover";
import { useEventLogger } from "../../lib/event_logger";
import useIsSampleLibrary from "../../lib/use-is-sample-library";
import useRunPipeline, { usePipelineErrors } from "../../lib/use-run-pipeline";
import { pipelineErrorAtom } from "./pipeline-atoms";

export function PipelineMobileNav() {
  const { isSampleLibrary } = useIsSampleLibrary();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const hasErrors = usePipelineErrors();
  const { runPipeline } = useRunPipeline();
  const { userUIEventLogger } = useEventLogger();
  const location = useLocation();
  const pipeError = useRecoilValue(pipelineErrorAtom);

  return (
    <div className="grid grid-cols-3 gap-x-2 pb-8">
      {[
        { text: "Input", href: "pipeline-input", index: 1 },
        { text: "Skills", href: "pipeline-skills", index: 2 },
        { text: "Output", href: "pipeline-output", index: 3 },
      ].map((el) => {
        const activeTab =
          "text-white rounded-lg text-center relative dark:bg-steelGray bg-white py-3 px-4 border border-cyan";
        const inactiveTab =
          "text-white rounded-lg text-center relative dark:bg-steelGray bg-white py-3 px-4";
        const isHomepageAndInput =
          el.text === "Input" && location.pathname === "/";

        const isPipelineOutput = el.href === "pipeline-output";
        const shouldShowErrors = hasErrors().length;

        return (
          <NavLink
            onClick={(event: React.MouseEvent<any>) => {
              checkErrorsOrRunPipeline(event);
            }}
            to={
              isSampleLibrary ? el.href + "?textInput=sample-library" : el.href
            }
            key={el.href}
            children={({ isActive }) => {
              return (
                <div className="relative">
                  <span
                    className={`absolute top-0 px-2 py-1 left-0 font-mono text-xs dark:text-white text-black dark:bg-darkGrayTab rounded-tl-md rounded-br-md
                  ${pipeError?.showError ? "" : "z-10"}`}
                  >
                    {el.index}
                  </span>
                  <div
                    className={
                      isActive || isHomepageAndInput ? activeTab : inactiveTab
                    }
                  >
                    <span className="font-poppins text-sm dark:text-white text-black">
                      {el.text}
                    </span>
                  </div>
                  <ErrorsPopover
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                  />
                </div>
              );
            }}
          />
        );

        function checkErrorsOrRunPipeline(event: React.MouseEvent<any>) {
          if (isPipelineOutput) {
            if (shouldShowErrors) {
              if (anchorEl === null) {
                setAnchorEl(event.currentTarget);
              }
              event.preventDefault();
              return;
            } else {
              userUIEventLogger("run_pipeline_output_mobile");
              runPipeline();
            }
          }
        }
      })}
    </div>
  );
}

export function PipelineMobileFooter() {
  return (
    <></>
    // <div className="fixed bottom-0 left-0 right-0 w-full p-4 bg-darkGrayBackground h-[70px]">
    //   {/* <div className="w-full p-4 bg-darkGrayBackground"> */}
    // </div>
  );
}
