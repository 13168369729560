import React, { Dispatch, ReactNode } from "react";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { CloseIcon } from "../assets/SvgIcons";
import {
  anonymousePreNavigationAtom,
  forgotPasswordTokenAtom,
  loginFormAtom,
  registrationTokenAtom,
} from "../lib/atoms";
import "./app-modal.css";
import { useUser } from "./auth/auth";

type AppModalType = {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  children: ReactNode;
  style?: { overlay?: React.CSSProperties; content?: React.CSSProperties };
  shouldCloseOnOverlayClick?: boolean;
  showCloseButton?: boolean;
};

const AppModal = ({
  isOpen = true,
  setIsOpen,
  children,
  style,
  shouldCloseOnOverlayClick = true,
  showCloseButton = true,
}: AppModalType) => {
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const [anonymousePreNavigation, setAnonymousePreNavigation] = useRecoilState(
    anonymousePreNavigationAtom,
  );
  const setRegistrationToken = useSetRecoilState(registrationTokenAtom);
  const setForgotPasswordToken = useSetRecoilState(forgotPasswordTokenAtom);
  const setLoginForm = useSetRecoilState(loginFormAtom);

  const location = useLocation();
  function handleAuthModalClose() {
    if (!loading && user) {
      if (location.pathname === "/login") {
        navigate("/", { replace: true });
      }
    }
    if (anonymousePreNavigation) {
      setAnonymousePreNavigation(false);
    }
    setRegistrationToken("");
    setForgotPasswordToken("");
    setLoginForm(false);
    setIsOpen(false);
  }
  return (
    <>
      <Modal
        appElement={document.getElementById("root") as HTMLElement}
        closeTimeoutMS={300}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        className="bg-white dark:bg-darkBackground rounded-[7px]  shadow-md  dark:shadow-none"
        style={{
          overlay: {
            display: "grid",
            justifyItems: "center",
            background: "transparent",
            backdropFilter: "blur(2px)",
            WebkitBackdropFilter: "blur(2px)",
            zIndex: "30",
          },
          content: {
            ...{
              width: "fit-content",
              height: "fit-content",
              inset: 0,
              margin: "auto",
            },
            ...style?.content,
          },
        }}
      >
        <div className="grid grid-cols-auto-1fr-auto max-w-[400px] min-h-[270px] min-w-[280px] border-0 py-3 px-6 md:max-h-[calc(100vh-50px)] overflow-y-auto">
          <div className="grid items-start w-[20px]" />

          <div className="grid content-center items-center">
            <div>{children}</div>
          </div>
          <div className="grid items-start w-[20px]">
            {showCloseButton && (
              <button
                className="w-fit-content h-fit-content text-cyan"
                onClick={handleAuthModalClose}
              >
                <CloseIcon />
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AppModal;
