import React, { useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  GetYourKeyIcon,
  IconCopySmall,
  IconEmptyGeneratorState,
  IconTooltip,
} from "../../assets/SvgIcons";
import { useUser } from "../../components/auth/auth";
import {
  getAPIKeyByTokenId,
  useUserData,
  useUserOrganizationKeys,
} from "../../components/auth/data";
import AppTooltip from "../../components/tooltip";
import {
  anonymousePreNavigationAtom,
  summaryOriginsAtom,
} from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import useElementSize from "../../lib/use-element-size";
import useRequestBuilder from "../../lib/use-request-builder";
import "./request.css";
import SelectTechnology from "./select-technology";
import {
  getCURL,
  getJSON,
  getNodeJS,
  getNodeJSSDK,
  getPython,
  getPythonSDK,
  //stripHTML,
  technologiesOptions,
  useIsOnSide,
  useIsShortHeight,
} from "./utils";

type RequestType = {
  outputLength: number;
  reRenderRequest?: number;
  type?: string;
};

const Request = ({ outputLength, reRenderRequest, type }: RequestType) => {
  const { userEventLogger, userUIEventLogger } = useEventLogger();
  const { t, i18n } = useTranslation("index");
  const { value, loading } = useUserData();
  const [visibleGeneratedCodeKey, setVisibleGeneratedCodeKey] =
    useState<string>("");
  const requestBuilder = useRequestBuilder();
  const phoneOnSide = useIsOnSide();
  const shortHeight = useIsShortHeight();
  const isDocsGenerator = type === "docs";
  const setAnonymousePreNavigation = useSetRecoilState(
    anonymousePreNavigationAtom,
  );
  const { user, loading: userLoading } = useUser();
  const [technology, setTechnology] = useState<string>(
    technologiesOptions[2].value,
  );
  const summaryOrigins = useRecoilValue(summaryOriginsAtom);
  const { api_keys } = useUserOrganizationKeys();
  const [ref, { height }] = useElementSize();
  const reversedArray = [...api_keys].reverse();
  const visibleKey = reversedArray?.find((key) => {
    return key.state === "ACTIVE";
  });

  React.useEffect(() => {
    const getKey = async () => {
      const orgId = visibleKey?.account_id;
      const tokenId = visibleKey?.token_id;

      if (visibleKey?.account_id) {
        const lastKey = await getAPIKeyByTokenId({
          orgId,
          tokenId,
        });
        setVisibleGeneratedCodeKey(lastKey?.details?.api_key);
      }
    };
    getKey();
    //eslint-disable-next-line
  }, [api_keys]);

  const [request, setRequest] = useState<string>(technologiesOptions[0].value);
  const [requestCopied, setRequestCopied] = useState<boolean>(false);
  const [generatorHover, setGeneratorHover] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChanges = useCallback(() => {
    if (technology === technologiesOptions[0].value /* Node.js */) {
      setRequest(
        getNodeJS({
          requestBuilder,
          summaryOrigins,
          apiKey: visibleGeneratedCodeKey,
          preview: false,
        }),
      );
    }
    if (technology === technologiesOptions[1].value /* cURL */) {
      setRequest(
        getCURL({
          requestBuilder,
          summaryOrigins,
          apiKey: visibleGeneratedCodeKey,
          preview: false,
        }),
      );
    }
    if (technology === technologiesOptions[2].value /* Python */) {
      setRequest(
        getPython({
          requestBuilder,
          summaryOrigins,
          preview: false,
          apiKey: visibleGeneratedCodeKey,
        }),
      );
    }
    if (technology === technologiesOptions[3].value /* JSON */) {
      setRequest(
        getJSON({
          requestBuilder,
          summaryOrigins,
          apiKey: visibleGeneratedCodeKey,
          preview: false,
        }),
      );
    }
    if (technology === technologiesOptions[4].value /* NodeJS SDK */) {
      setRequest(
        getNodeJSSDK({
          requestBuilder,
          summaryOrigins,
          apiKey: visibleGeneratedCodeKey,
          preview: false,
        }),
      );
    }
    if (technology === technologiesOptions[5].value /* Python SDK */) {
      setRequest(
        getPythonSDK({
          requestBuilder,
          summaryOrigins,
          apiKey: visibleGeneratedCodeKey,
          preview: false,
        }),
      );
    }
  }, [technology, requestBuilder, summaryOrigins, visibleGeneratedCodeKey]);

  const requestPreview = () => {
    if (technology === technologiesOptions[0].value /* Node JS */) {
      return getNodeJS({
        requestBuilder,
        preview: true,
        apiKey: visibleGeneratedCodeKey,
        summaryOrigins: false,
      });
    }
    if (technology === technologiesOptions[1].value /* cURL */) {
      return getCURL({
        requestBuilder,
        preview: true,
        apiKey: visibleGeneratedCodeKey,
        summaryOrigins: false,
      });
    }
    if (technology === technologiesOptions[2].value /* Python */) {
      return getPython({
        requestBuilder,
        preview: true,
        apiKey: visibleGeneratedCodeKey,
        summaryOrigins: false,
      });
    }

    if (technology === technologiesOptions[3].value /* JSON */) {
      return getJSON({
        requestBuilder,
        preview: true,
        apiKey: visibleGeneratedCodeKey,
        summaryOrigins: false,
      });
    }
    if (technology === technologiesOptions[4].value /* NodeJS SDK */) {
      return getNodeJSSDK({
        requestBuilder,
        preview: true,
        apiKey: visibleGeneratedCodeKey,
        summaryOrigins: false,
      });
    }
    if (technology === technologiesOptions[5].value /* Python SDK */) {
      return getPythonSDK({
        requestBuilder,
        preview: true,
        apiKey: visibleGeneratedCodeKey,
        summaryOrigins: false,
      });
    }
  };

  useEffect(() => {
    handleChanges();
  }, [technology, handleChanges, reRenderRequest]);

  useEffect(() => {
    const userPreferencesFromFirebase =
      value?.preferences?.programming_language;
    if (!userPreferencesFromFirebase) {
      setTechnology(technologiesOptions[2].value);
    } else setTechnology(userPreferencesFromFirebase);
    // eslint-disable-next-line
  }, [loading]);

  const onClose = () => {
    setTimeout(() => {
      setRequestCopied(false);
    }, 200);
  };

  const handleCopyRequest = () => {
    if (requestCopied || !outputLength) return;
    setRequestCopied(true);
    userEventLogger(UserEvent.COPY_REQUEST);
  };

  const textPreview = requestPreview();
  function createMarkup() {
    return {
      __html: textPreview || "",
    };
  }

  function handleGeneratorOver() {
    setGeneratorHover(true);
  }

  function handleGeneratorLeave() {
    setGeneratorHover(false);
  }

  function handleProdAPIKeyClick() {
    userUIEventLogger("studio__generated_code__production_api_key");
  }

  return (
    <>
      <div
        className={`${
          isDocsGenerator ? "pb-1" : "p-4"
        } grid items-center grid-cols-1fr-auto-auto gap-x-2 rounded-t-md bg-white dark:bg-darkGrayBackground`}
      >
        <span
          className={`${
            isDocsGenerator ? "hidden" : ""
          } text-tableGrayBody dark:text-pearl font-poppins font-medium`}
        >
          {t("Generated code")}
        </span>
        <div className="select m-0 min-w-0 whitespace-nowrap">
          <SelectTechnology
            selectOptions={technologiesOptions}
            technology={technology}
            setTechnology={setTechnology}
          />
        </div>

        <AppTooltip
          title={
            requestCopied ? (
              <div className="grid grid-cols-auto-1fr gap-x-3 items-center">
                <IconTooltip className={"text-blue"} />
                <span>{t("Code copied to clipboard")}</span>
              </div>
            ) : !outputLength ? (
              t("Please run a pipeline to copy code")
            ) : (
              t("Copy generated code")
            )
          }
          arrow={!requestCopied}
          leaveDelay={0}
          onClose={onClose}
        >
          <button className={`relative`}>
            <figure>
              {outputLength ? (
                <CopyToClipboard
                  text={request}
                  onCopy={() => handleCopyRequest()}
                >
                  <IconCopySmall
                    width="17px"
                    height="17px"
                    className={`text-blue `}
                  />
                </CopyToClipboard>
              ) : (
                <IconCopySmall
                  width="17px"
                  height="17px"
                  className={`text-blue `}
                />
              )}
            </figure>
          </button>
        </AppTooltip>
      </div>

      <div
        ref={ref}
        className={`bg-white dark:bg-darkGrayBackground  ${
          isDocsGenerator ? "" : " px-4 pb-8 "
        } grid font-mono font-normal text-sm dark:text-white text-black leading-6 max-h-full rounded-b-md ${
          outputLength ? "overflow-y-auto" : "overflow-y-hidden"
        } min-h-full whitespace-pre-wrap break-all h-full`}
      >
        <div
          className={`${
            !outputLength
              ? "absolute  w-full backdrop-blur-[3px] rounded-b-md grid justify-center items-center overflow-hidden rounded-md  "
              : "hidden"
          }`}
          style={{
            height: height,
          }}
          onMouseOver={handleGeneratorOver}
          onMouseLeave={handleGeneratorLeave}
        >
          <div
            className={`grid grid-flow-row gap-y-2 justify-center items-center ${
              generatorHover ? "" : "hidden"
            }`}
          >
            <figure className="grid justify-center animate-fade-in-up">
              <IconEmptyGeneratorState width="34" height="24" />
            </figure>
            <p className="grid font-mono font-bold text-lg text-center animate-fade-in-up">
              {t("Please run pipeline")}
              <br /> {t("in order to generate code")}
            </p>
          </div>
        </div>
        <div dir={`ltr  `}>
          {/* <code style={{ fontFamily: "'Courier New', monospace line" }}> */}
          {/* <pre> */}
          {/* <Highlighter
            searchWords={["<YOUR-API-KEY>", "<YOUR INPUT TEXT>"]}
            highlightClassName="bg-transparent text-blue dark:text-turbo"
            autoEscape={true}
            caseSensitive={true}
            textToHighlight={
              summaryOrigins ? stripHTML(textPreview || "") : textPreview || ""
            }
          /> */}

          <div
            dangerouslySetInnerHTML={createMarkup()}
            className={`${
              outputLength ? "" : "select-none overflow-y-hidden"
            } ${isDocsGenerator ? "font-mono" : ""}`}
          />
          {/* </pre> */}
          {/* </code> */}
          {/* <SyntaxHighlighter
              language="javascript"
              style={isDark ? nightOwl : docco}
            >
          {request}
          </SyntaxHighlighter> */}
        </div>
      </div>
      <div
        className={`absolute bottom-0 grid justify-end bg-transparent p-4 pt-2 ${
          phoneOnSide || shortHeight || !outputLength ? "hidden" : ""
        }   ${i18n.dir() === "ltr" ? "right-0" : "left-0"}`}
      >
        <button
          className={`items-center grid grid-cols-1fr-auto gap-x-2 border border-blue dark:border-turbo rounded-md py-2 px-4 text-blue dark:text-turbo text-sm font-mono bg-white dark:bg-darkGrayBackground`}
          onClick={() => {
            handleProdAPIKeyClick();
            if (!userLoading && user && user?.isAnonymous) {
              setAnonymousePreNavigation(true);
            } else {
              navigate("/settings/api-keys");
            }
          }}
        >
          {t("Production API Key")}
          <figure>
            <GetYourKeyIcon
              className="text-blue dark:text-turbo"
              height="17px"
              width="18px"
            />
          </figure>
        </button>
      </div>
    </>
  );
};

export default Request;
