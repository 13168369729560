import { atom, DefaultValue, selector } from "recoil";

export const clientSecretAtom = atom<string | null>({
  key: "clientSecretAtom",
  default: null,
});

export const customerAtom = atom<any>({ key: "customerAtom", default: null });
export const customerSelector = selector<any>({
  key: "customerSelector",
  get: ({ get }) => get(customerAtom),
  set: ({ set }, newValue) => {
    set(customerAtom, newValue instanceof DefaultValue ? newValue : newValue);
  },
});

export const addMethodModalOpenAtom = atom({
  key: "addMethodModalOpenAtom",
  default: false,
});

export const preferencesModalOpenAtom = atom({
  key: "preferencesModalOpenAtom",
  default: false,
});

export const cancelPaidAccountModalOpenAtom = atom({
  key: "cancelPaidAccountModalOpenAtom",
  default: false,
});

export const addedPaymentMethodAtom = atom({
  key: "addedPaymentMethodAtom",
  default: false,
});
