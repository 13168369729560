import { Menu } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { IconMobileMenu, IconMobileMenuX } from "../../assets/SvgIcons";
import theme from "../../theme";
import { useThemeColor } from "../theme-color";
import TopNavigation from "./top-navigation";

type Props = {
  menuLinkName: string;
};

export default function MediumTopNavigation({ menuLinkName }: Props) {
  const { t } = useTranslation("index");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="hidden md:grid grid-flow-col xl:hidden justify-center justify-items-center">
      <React.Fragment>
        <button
          onClick={handleClick}
          aria-controls={open ? "info-popover" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className="h-8 w-8 "
          data-cy="medium"
        >
          {anchorEl ? (
            <IconMobileMenuX
              width="24"
              height="24"
              className="dark:text-grayButtons text-tableHeaderBorderDark"
            />
          ) : (
            <IconMobileMenu
              width="24"
              height="24"
              className="dark:text-grayButtons text-tableHeaderBorderDark hover:bg-opacity-20"
            />
          )}
        </button>
        <InfoPopup anchorEl={anchorEl} open={open} setAnchorEl={setAnchorEl} />
      </React.Fragment>
      {/* selected nav link name  in grey no bottom pink line */}
      <span className="text-tableHeaderBorderDark dark:text-grayButtons font-mono font-medium text-lg leading-[1.9em]">
        {t(menuLinkName)}
      </span>
    </div>
  );
}

type InfoPopupPropsType = {
  anchorEl: any;
  open: boolean;
  setAnchorEl: Function;
};
const InfoPopup = React.forwardRef(
  ({ anchorEl, open, setAnchorEl }: InfoPopupPropsType, ref) => {
    const { isDark } = useThemeColor();

    function handleClose() {
      setAnchorEl(null);
    }

    return (
      <>
        <Menu
          id="medium-top-navigation-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              backgroundColor: isDark
                ? theme.colors.steelGray
                : theme.colors.white,
              color: theme.colors.white,
              padding: theme.spacing[6],
              mt: 2,
              margin: "none",
              filter: !isDark
                ? "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))"
                : "none",
            },
          }}
        >
          <TopNavigation screen="medium-nav" />
        </Menu>
      </>
    );
  },
);
