import Menu from "@mui/material/Menu";
import { Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import theme from "../theme";
import { LottieLoader } from "./Loader";
import { mutateContentObject } from "../components/auth/data";
import { useThemeColor } from "./theme-color";
import { useDraftData } from "../lib/use-studio-data";
import { getEnvironment } from "../pages/PipelinePage/utils";

export default function ConfirmContentChanges({
  openedPopover = () => {},
  closedPopover = () => {},
  contentFile,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  contentFile: any;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("content-management");

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <button
        onClick={handleClick}
        className="overflow-hidden cursor-pointer text-center  transform-none m-0 items-center w-full py-3 px-5 dark:text-white bg-green bg-opacity-30 hover:bg-green text-black whitespace-nowrap text-xs md:text-xs  gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-40 hover:bg-lightback rounded-md shadow-xl "
      >
        <span>{t("Publish")}</span>
      </button>

      <ConfirmChangesPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        contentFile={contentFile}
      />
    </React.Fragment>
  );
}

type IConfirmChangesPopup = {
  anchorEl: any;
  open: boolean;
  onClose: any;
  contentFile: any;
};

const ConfirmChangesPopup = React.forwardRef(
  (props: IConfirmChangesPopup, ref: any) => {
    const { anchorEl, open, onClose, contentFile } = props;
    const { isDark } = useThemeColor();
    return (
      <Menu
        anchorEl={anchorEl}
        id="add-skill-order"
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: isDark
              ? theme.colors.darkBackground
              : theme.catskill,
            color: theme.colors.white,
            padding: theme.spacing[4],
            width: "280px !important",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <EditSkillForm onClose={onClose} contentFile={contentFile} />
      </Menu>
    );
  },
);

const EditSkillForm = ({
  onClose = () => {},
  contentFile,
}: {
  onClose: any;
  contentFile: any;
}) => {
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState("FORM");
  const { t } = useTranslation("content-management");
  const { data } = useDraftData(contentFile);
  const onSubmit = async () => {
    try {
      setState("LOADING");
      if (data) {
        const environment = getEnvironment();
        const response = await mutateContentObject(environment);

        if (response.status === 200) {
          setState("SUCCESS");
        } else {
          setState("ERROR");
          setError(response.message);
        }
      } else {
        setError("Could not publish the new content");
        setState("ERROR");
      }
    } catch (error) {
      console.log(error);
      setError("Could not publish the new content");
      setState("ERROR");
    }
  };
  if (error) {
    return <div>{t(error)}</div>;
  }

  return (
    <div>
      {state === "LOADING" ? (
        <LottieLoader />
      ) : state === "SUCCESS" ? (
        <div>{t("You edited the skill!")}</div>
      ) : state === "ERROR" ? (
        <div>{error}</div>
      ) : (
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {() => (
            <Form className="grid grid-cols-1 font-poppins dark:text-white text-black">
              <p className="text-center font-bold text-xl">
                {t("Are you sure?")}
              </p>
              <span className="font-normal text-center">
                {t("This will replace the current content object in firebase!")}
              </span>
              <div
                dir={i18n.dir()}
                className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-autofont-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={onClose}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Save")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
