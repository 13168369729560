import React from "react";
import Request from "../request";
import SampleLibrary from "../sample-library";
import { useIsMobile, useIsOnSide, useIsShortHeight } from "../utils";
import Split from "react-split";
import InputDeterminator from "./input-determinator";
import FileUploader from "./file-uploader";
import InputTypeSelection from "./input-type-selection";
import { textAreaEmptyStateAtom } from "../../../lib/atoms";
import { useRecoilValue } from "recoil";
import useIsSampleLibrary from "../../../lib/use-is-sample-library";

type InputType = {
  outputLength: any;
};

const Input = ({ outputLength }: InputType) => {
  const phoneOnSide = useIsOnSide();
  const shortHeight = useIsShortHeight();
  const [reRenderRequest, setReRenderRequest] = React.useState<number>(0);
  const isMobile = useIsMobile();
  const inputContainer = React.useRef<any>(null);
  const textAreaEmptyState = useRecoilValue(textAreaEmptyStateAtom);
  const { isSampleLibrary } = useIsSampleLibrary();
  return (
    <Split
      className={`flex flex-col mt-0 overflow-x-hidden overflow-y-hidden w-full md:h-[calc(100vh-178px)]`}
      gutterSize={8}
      direction="vertical"
      sizes={isMobile || phoneOnSide || shortHeight ? [99, 1] : [50, 50]}
      onDragEnd={(e) => {
        const random = Math.random();
        setReRenderRequest(random);
      }}
    >
      {!isSampleLibrary ? (
        <div
          className={`grid items-start min-h-0 overflow-hidden grid-rows-1fr-8fr`}
        >
          <InputTypeSelection />

          <div className={`grid h-full min-h-0`}>
            {textAreaEmptyState ? (
              <FileUploader inputContainer={inputContainer} />
            ) : (
              <InputDeterminator inputContainer={inputContainer} />
            )}
          </div>
        </div>
      ) : (
        <div
          className={`grid items-start h-full ${
            phoneOnSide || shortHeight ? "md:h-[calc(100vh-150px)]" : ""
          }`}
        >
          <div className={`grid h-full min-h-0`}>
            <SampleLibrary />
          </div>
        </div>
      )}

      <div
        className={`relative items-start grid-rows-auto-1fr-auto md:h-[calc(50vh-110px)] hidden md:grid rounded-md  ${
          phoneOnSide || shortHeight ? "md:hidden" : isMobile ? "hidden" : ""
        }`}
      >
        <Request
          outputLength={outputLength}
          reRenderRequest={reRenderRequest}
        />
      </div>
    </Split>
  );
};

export default Input;
