import Editor from "@monaco-editor/react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { cloneDeep } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useRecoilState } from "recoil";
import { contentFileAtom } from "../lib/atoms";
import { useDraftData } from "../lib/use-studio-data";
import { selectStyles } from "../utils/select-styles";
import ContentModal from "./content-modal";
import { useThemeColor } from "./theme-color";

export default function AddSkillModal() {
  const [isOpen, setIsOpen_] = React.useState<boolean>(false);

  function setIsOpen(isOpen: boolean) {
    setError([]);
    setIsOpen_(isOpen);
  }

  const { isDark } = useThemeColor();
  const [error, setError] = React.useState<Array<string>>([]);
  const { t, i18n } = useTranslation("content-management");
  const [typeOptions, setTypeOptions] = React.useState<any[]>([]);
  const [selectedType, setSelectedType] = React.useState<any>({});
  const [draft, setDraft] = useRecoilState(contentFileAtom);

  const { data } = useDraftData(draft);
  React.useEffect(() => {
    if (draft) {
      // Fetch skill types and categories
      const typeOptions = data?.skill_categories.map((type: any) => ({
        value: type.id,
        label: type.category_name,
      }));

      setTypeOptions(typeOptions);
      setSelectedType(typeOptions[0]);
    }

    //eslint-disable-next-line
  }, []);

  const onSubmit = async (values: any) => {
    try {
      const clonedContent = cloneDeep(data);
      let biggerId = 0;
      clonedContent?.skills.forEach((skill: any) => {
        if (Number(skill.id) > biggerId) biggerId = skill.id;
      });
      biggerId++;
      const newSkill = {
        name: values?.name,
        value: values?.value,
        category: selectedType?.value,
        id: biggerId.toString(),
        creates_new_output: values?.creates_new_output,
        keywords: values?.keywords
          ? values?.keywords.toString().split(",")
          : [],
        tooltip: {
          title: values?.title,
          example: values?.example,
          extras: values?.extras ? JSON.parse(values?.extras) : [],
        },
        fake_skill: values?.fake_skill,
      };

      const newSkillDocumentation = {
        item_name: values?.name,
        response: values?.response,
        content_html: values?.content,
        code_snippet: values?.code_snippet
          ? JSON.parse(values?.code_snippet)
          : [],
      };

      const findSkillsIndex = clonedContent?.documentation.findIndex(
        (section: any) => {
          return section.type === "skills";
        },
      );
      clonedContent?.documentation[findSkillsIndex].items.push(
        newSkillDocumentation,
      );

      clonedContent?.skills?.push(newSkill);

      setDraft(clonedContent);
      setIsOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const validate = (values: any) => {
    setError([]);
    const errors = {} as any;
    if (!values.name) errors.name = t("Required");
    if (values.value.length === 0) errors.value = t("Required");

    const skillExists = data?.skills.find(
      (skill: any) => skill.name === values.name,
    );

    const endpointExists = data?.skills.find(
      (skill: any) => skill.value === values.value,
    );

    if (skillExists) errors.name = t("Skill name already exists!");
    if (endpointExists) errors.value = t("Skill endpoint already exists!");

    try {
      if (values?.extras) JSON.parse(values.extras);
    } catch (e) {
      console.log(e);
      errors.extras = t("Please enter a valid JSON here");
      return errors;
    }
    try {
      if (values?.code_snippet) JSON.parse(values.code_snippet);
    } catch (e) {
      console.log(e);
      errors.code_snippet = t("Please enter a valid JSON here");

      return errors;
    }

    const keys = Object.keys(errors).map((key: any) => {
      switch (key) {
        case "value":
          return "Endpoint name is required";
        case "name":
          return "Skill name is required";
        case "extras":
          return "Extras JSON is not valid";
        case "code_snippet":
          return "Code snippet JSON is not valid";
      }
      return key;
    });

    setError(keys);
    return errors;
  };
  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        aria-controls={isOpen ? "add-skill-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        className="min-w-fit cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl"
      >
        {t("Add new skill")}
      </button>

      <ContentModal isOpen={isOpen} setIsOpen={setIsOpen}>
        {error.length > 0 && (
          <div className="fixed left-3 rounded-md max-w-[200px] bg-dark bg-opacity-40 text-white p-2 z-50">
            {error.map((error: any) => (
              <p>{error}</p>
            ))}
          </div>
        )}

        <Formik
          initialValues={{
            name: "",
            value: "",
            extras: "",
            title: "",
            example: "",
            code_snippet: "",
            response: "",
            content_html: "",
            creates_new_output: false,
            fake_skill: false,
          }}
          validate={validate}
          onSubmit={onSubmit}
        >
          {() => (
            <Form className="grid grid-cols-1 gap-y-4 font-poppins pb-4 text-black dark:text-white">
              <p className="text-center text-2xl">{t("Add a new skill")}</p>
              <span className="font-normal text-center">
                {t("Make sure sure you know what you're doing!")}
              </span>
              <div className="grid md:grid-cols-1 gap-y-4 gap-x-4 md:h-full ">
                <div className="gap-y-4">
                  <p className="underline text-xl font-normal">
                    {t("Skill information")}
                  </p>
                  <div className="grid gap-y-4">
                    <div className="grid gap-y-1">
                      <label
                        htmlFor="name"
                        className="text-sm font-normal leading-6"
                      >
                        {t("Skill UI name:")}
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="bg-transparent border border-borderLight dark:border-darkGrayBorder rounded p-3 w-full"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-red error-message"
                      />
                    </div>
                    <div className="grid  gap-y-1">
                      <label
                        htmlFor="name"
                        className="text-sm font-normal leading-6"
                      >
                        {t("Skill Endpoint name:")}
                      </label>
                      <Field
                        type="text"
                        name="value"
                        className="bg-transparent border border-borderLight dark:border-darkGrayBorder rounded p-3 w-full"
                      />
                      <ErrorMessage
                        name="value"
                        component="div"
                        className="text-red error-message"
                      />
                    </div>
                    <div className="grid  gap-y-1">
                      <label
                        htmlFor="select"
                        className="text-sm font-normal leading-6"
                      >
                        {t("Skill type:")}
                      </label>
                      <Select
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        styles={selectStyles(isDark ? true : false)}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                        options={typeOptions}
                        onChange={(selectedOption): any => {
                          setSelectedType(selectedOption);
                        }}
                        value={selectedType}
                      />
                    </div>
                    <Field name="creates_new_output" type="checkbox">
                      {({ field, form }: any) => (
                        <label
                          className="grid grid-cols-auto-1fr"
                          htmlFor="creates_new_output"
                        >
                          <input
                            type="checkbox"
                            className="checkbox bg-white dark:bg-darkBackground"
                            aria-required="true"
                            onChange={(e) => {
                              form.setFieldValue(field.name, e.target.checked);
                            }}
                            {...field}
                          />
                          <span
                            className="text-sm dark:text-white text-formsgray text-left px-3"
                            onClick={(e) => {
                              form.setFieldValue(field.name, !field.checked);
                            }}
                          >
                            This skill creates new output
                          </span>
                        </label>
                      )}
                    </Field>
                    <Field name="fake_skill" type="checkbox">
                      {({ field, form }: any) => (
                        <label
                          className="grid grid-cols-auto-1fr"
                          htmlFor="fake_skill"
                        >
                          <input
                            type="checkbox"
                            className="checkbox bg-white dark:bg-darkBackground"
                            aria-required="true"
                            onChange={(e) => {
                              form.setFieldValue(field.name, e.target.checked);
                            }}
                            {...field}
                          />
                          <span
                            className="text-sm dark:text-white text-formsgray text-left px-3"
                            onClick={(e) => {
                              form.setFieldValue(field.name, !field.checked);
                            }}
                          >
                            This skill is a fake skill (won't be added to the
                            actual api payload)
                          </span>
                        </label>
                      )}
                    </Field>
                    <div className="grid gap-y-1">
                      <label
                        htmlFor="keywords"
                        className="text-sm font-normal leading-6"
                      >
                        {t("Keywords (separate by comma):")}
                      </label>

                      <Field
                        component="textarea"
                        type="text"
                        name="keywords"
                        className="bg-transparent rounded p-3 w-full h-[75px] border border-borderLight dark:border-darkGrayBorder resize-none"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="underline text-xl font-normal">
                    {t("Documentation information")}
                  </p>
                  <div className="grid grid-cols-1 gap-y-4 gap-x-4 ">
                    <div>
                      <div className="grid gap-y-1">
                        <label
                          htmlFor="response"
                          className="text-sm font-normal leading-6"
                        >
                          {t("Response (String):")}
                        </label>
                        <Field name="response">
                          {({ field, form }: any) => (
                            <Editor
                              height="500px"
                              className="border border-borderLight dark:border-darkGrayBorder rounded-md p-1"
                              defaultLanguage="html"
                              defaultValue={field.value}
                              theme={isDark ? "vs-dark" : undefined}
                              onChange={(newValue: any) => {
                                form.setFieldValue(field.name, newValue);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="grid gap-y-4">
                      <div className="grid gap-y-1">
                        <label htmlFor="id" className="text-sm font-normal">
                          {t("Content (HTML):")}
                        </label>
                        <Field name="content_html">
                          {({ field, form }: any) => (
                            <Editor
                              height="500px"
                              className="border border-borderLight dark:border-darkGrayBorder rounded-md p-1"
                              defaultLanguage="html"
                              defaultValue={field.value}
                              theme={isDark ? "vs-dark" : undefined}
                              onChange={(newValue: any) => {
                                form.setFieldValue(field.name, newValue);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="grid gap-y-1">
                        <label
                          htmlFor="Snippets"
                          className="text-sm font-normal"
                        >
                          {t("Snippets")} (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            JSON
                          </span>
                          ):
                        </label>
                        <Field name="code_snippet">
                          {({ field, form }: any) => (
                            <Editor
                              height="500px"
                              className="border border-borderLight dark:border-darkGrayBorder rounded-md p-1"
                              defaultLanguage="json"
                              defaultValue={field.value}
                              theme={isDark ? "vs-dark" : undefined}
                              onChange={(newValue: any) => {
                                form.setFieldValue(field.name, newValue);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="code_snippet"
                          component="div"
                          className="text-red error-message"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="underline font-normal text-xl">
                    {t("Tooltip information")}
                  </p>
                  <div className="grid grid-cols-1 gap-y-4 gap-x-4">
                    <div>
                      <div className="grid gap-y-1">
                        <label
                          htmlFor="id"
                          className="text-sm font-normal leading-6"
                        >
                          {t("Title (HTML):")}
                        </label>
                        <Field name="title">
                          {({ field, form }: any) => (
                            <Editor
                              height="500px"
                              className="border border-borderLight dark:border-darkGrayBorder rounded-md p-1"
                              defaultLanguage="html"
                              defaultValue={field.value}
                              theme={isDark ? "vs-dark" : undefined}
                              onChange={(newValue: any) => {
                                form.setFieldValue(field.name, newValue);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div className="grid gap-y-4">
                      <div className="grid gap-y-1">
                        <label
                          htmlFor="example"
                          className="text-sm font-normal leading-6"
                        >
                          {t("Example (HTML):")}
                        </label>
                        <Field name="example">
                          {({ field, form }: any) => (
                            <Editor
                              height="500px"
                              className="border border-borderLight dark:border-darkGrayBorder rounded-md p-1"
                              defaultLanguage="html"
                              defaultValue={field.value}
                              theme={isDark ? "vs-dark" : undefined}
                              onChange={(newValue: any) => {
                                form.setFieldValue(field.name, newValue);
                              }}
                            />
                          )}
                        </Field>
                      </div>
                      <div className="grid gap-y-1">
                        <label
                          htmlFor="extras"
                          className="text-sm font-normal leading-6"
                        >
                          {t("Extras")} (
                          <span
                            style={{
                              color: "red",
                            }}
                          >
                            JSON Array
                          </span>
                          ):
                        </label>
                        <Field name="extras">
                          {({ field, form }: any) => (
                            <Editor
                              height="500px"
                              className="border border-borderLight dark:border-darkGrayBorder rounded-md p-1"
                              defaultLanguage="json"
                              defaultValue={field.value}
                              theme={isDark ? "vs-dark" : undefined}
                              onChange={(newValue: any) => {
                                form.setFieldValue(field.name, newValue);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="extras"
                          component="div"
                          className="text-red error-message"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                dir={i18n.dir()}
                className={`grid grid-flow-col gap-x-2 justify-end fixed bottom-1 right-3`}
              >
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
                  onClick={() => setIsOpen(false)}
                  type="button"
                >
                  {t("Cancel")}
                </button>
                <button
                  className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
                  type="submit"
                >
                  {t("Add skill")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </ContentModal>
    </>
  );
}
