import { toLower } from "lodash";
import React from "react";
import { useRecoilValue } from "recoil";
import { IconTooltip } from "../../../assets/SvgIcons";
import {
  inputTypeAtom,
  summaryOriginsAtom,
  textAreaAtom,
} from "../../../lib/atoms";
import useAcademicSkill from "../../../lib/use-academic-skill";
import { useSummaryOrigin } from "../../../lib/use-summary-origin";
import { convertInputType, detectInputType } from "../utils";
import HTMLTextArea from "./html-textarea";
import TextArea from "./textarea";

type InputDeterminatorProps = {
  inputContainer: any;
};

const InputDecider = ({ inputContainer }: InputDeterminatorProps) => {
  const summaryOrigins = useRecoilValue(summaryOriginsAtom);
  const { isSummary } = useSummaryOrigin();
  return summaryOrigins && isSummary().foundSummary ? (
    <div className="overflow-y-auto">
      <WarningsDetector />
      <HTMLTextArea inputContainer={inputContainer} />
    </div>
  ) : (
    <div>
      <WarningsDetector />
      <TextArea inputContainer={inputContainer} />
    </div>
  );
};

function WarningsDetector() {
  const { isAcademic, academicLength } = useAcademicSkill();
  const textArea = useRecoilValue(textAreaAtom);
  const inputShouldBe = detectInputType(textArea);
  const currentInputType = useRecoilValue(inputTypeAtom);

  if (inputShouldBe !== currentInputType) {
    return (
      <WarningsWrapper className="grid grid-cols-auto-1fr items-center gap-x-2">
        <IconTooltip className="text-white" />
        <p className="dark:text-white text-black text-sm m-0 p-0">
          Input does not appear to be a{" "}
          {toLower(convertInputType(currentInputType?.name, false))}.
        </p>
      </WarningsWrapper>
    );
  }

  if (isAcademic && (academicLength === 0 || academicLength === 1)) {
    return (
      <WarningsWrapper>
        <p className="dark:text-white text-black text-sm">
          Your input contains only one article. To add more articles, please add
          them to the input using "========" delimiter
        </p>
      </WarningsWrapper>
    );
  }
  return null;
}

function WarningsWrapper({
  children,
  className,
}: {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}) {
  return (
    <div className="px-4">
      <div className={`bg-darkRed w-fit p-1 ${className ? className : ""}`}>
        {children}
      </div>
    </div>
  );
}
export default InputDecider;
