import { useUser } from "./auth/auth";
import userImage from "../assets/user.png";

export function UserImage() {
  const { user } = useUser();
  if (user === null) {
    return null;
  }
  return (
    <div style={{ width: "35px", height: "35px" }}>
      <img className="rounded-full cursor-default" src={user.photoURL} alt="" />
    </div>
  );
}

export function UserName() {
  const { user } = useUser();
  if (user === null) {
    return null;
  }
  return (
    <span className="dark:text-white text-black cursor-default font-mono font-bold text-sm">
      {user?.displayName}
    </span>
  );
}

export function NoPhoto() {
  const { user } = useUser();
  if (user === null) {
    return null;
  }
  return (
    <span className="dark:text-white text-black font-mono font-bold text-sm">
      <div style={{ width: "35px", height: "35px" }}>
        <img className="rounded-full cursor-default" src={userImage} alt="" />
      </div>
    </span>
  );
}
