import React, { Dispatch, ReactNode } from "react";
import Modal from "react-modal";

import { CloseIcon } from "../assets/SvgIcons";

import "./app-modal.css";

type GlobalModalType = {
  isOpen: boolean;
  setIsOpen: Dispatch<boolean>;
  children: ReactNode;
  style?: { overlay?: React.CSSProperties; content?: React.CSSProperties };
  shouldCloseOnOverlayClick?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  rounded?: boolean;
};

const GlobalModal = ({
  isOpen = false,
  setIsOpen,
  children,
  style,
  shouldCloseOnOverlayClick = true,
  showCloseButton = true,
  onClose = () => {},
  rounded = true,
}: GlobalModalType) => {
  function handleAuthModalClose() {
    setIsOpen(false);
    onClose();
  }

  return (
    <Modal
      appElement={document.getElementById("root") as HTMLElement}
      closeTimeoutMS={300}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={() => {
        setIsOpen(false);
        onClose();
      }}
      isOpen={isOpen}
      className={`bg-white dark:bg-modalDark shadow-md  dark:shadow-none z-10 ${
        rounded ? "rounded-[7px]" : ""
      }`}
      style={{
        overlay: {
          display: "grid",
          justifyItems: "center",
          background: "transparent",
          backdropFilter: "blur(2px)",
          WebkitBackdropFilter: "blur(2px)",
        },
        content: {
          ...{
            width: "fit-content",
            height: "fit-content",
            inset: 0,
            margin: "auto",
          },
          ...style?.content,
        },
      }}
    >
      <div className="grid grid-cols-auto-1fr-auto p-3 md:max-h-[calc(100vh-150px)] min-w-[250px] min-h-[250px] shadow-xl rounded-md overflow-y-auto">
        <div className="grid items-start w-[20px]" />
        {children}
        <div className="grid items-start w-[20px]">
          {showCloseButton && (
            <button
              className="w-fit-content h-fit-content text-cyan fixed"
              onClick={handleAuthModalClose}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GlobalModal;
