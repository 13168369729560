import { cloneDeep, flatMap } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from "uuid";
import {
  cubeFileAtom,
  cubeStateAtom,
  detectedCheckedAtom,
  inputResponseAtom,
  inputTypeAtom,
  summaryOriginsAtom,
  textAreaAtom,
  textAreaEmptyStateAtom,
} from "../../../lib/atoms";
import { useEventLogger, UserEvent } from "../../../lib/event_logger";
import { SkillsType } from "../../../lib/interfaces";
import {
  pipelineSkillsAtom,
  skillsLibraryAtom,
} from "../../pipeline/pipeline-atoms";
import {
  detectInputType,
  inputSelectionTypes,
  isAudioFile,
  isHTML,
  isValidURL,
  stripHTML,
} from "../utils";

type TextAreaProps = {
  inputContainer: any;
  disabled?: boolean;
};

export default function TextArea({
  inputContainer,
  disabled = false,
}: TextAreaProps) {
  const [textArea, setTextarea] = useRecoilState(textAreaAtom);
  const [textAreaEmptyState, setTextAreaEmptyState] = useRecoilState(
    textAreaEmptyStateAtom,
  );
  const detectedChecked = useRecoilValue(detectedCheckedAtom);
  const setSelectedInputType = useSetRecoilState(inputTypeAtom);
  const setInputResponse = useSetRecoilState(inputResponseAtom);
  const [pipelineSkills, setPipelineSkills] =
    useRecoilState(pipelineSkillsAtom);
  const skillsLibrary = useRecoilValue(skillsLibraryAtom);
  const [summaryOrigins, setSummaryOrigins] =
    useRecoilState(summaryOriginsAtom);
  const { userEventLogger } = useEventLogger();
  const { t } = useTranslation("index");

  // Recoil states
  const [cubeState, setCubeState] = useRecoilState(cubeStateAtom);
  const [cubeFile, setCubeFile] = useRecoilState(cubeFileAtom);

  const handleOnBlur = () => {
    if (textArea === "") {
      setTextAreaEmptyState(true);
    }
    if (!textAreaEmptyState) {
      userEventLogger(UserEvent.INSERT_OR_PASTE_TEXT, { value: textArea });
      userEventLogger(UserEvent.INPUT_CONTENT, {
        value: "INSERT_OR_PASTE_TEXT",
      });
    }
  };

  const handleChange = (e: any) => {
    if (textArea === "" && e.target.value !== "" && !textAreaEmptyState) {
      userEventLogger(UserEvent.INSERT_OR_PASTE_TEXT);
      userEventLogger(UserEvent.INPUT_CONTENT, {
        value: "INSERT_OR_PASTE_TEXT",
      });
    }
    if (summaryOrigins) {
      setSummaryOrigins(false);
      setTextarea(stripHTML(e.target.value));
    } else {
      if (cubeFile) {
        setCubeFile("");
        setInputResponse(false);
        setCubeState("WAITING");
        focusTextArea();
      }
      setTextarea(e.target.value);
    }
    if (textAreaEmptyState && e.target.value !== "") {
      setTextAreaEmptyState(false);
      focusTextArea();
    }
    if (!textAreaEmptyState && e.target.value === "") {
      setTextAreaEmptyState(true);
      focusTextArea();
    }
  };

  function sendCursorToEnd(
    event: React.FocusEvent<HTMLTextAreaElement, Element>,
  ) {
    event.currentTarget.setSelectionRange(
      event?.currentTarget?.value?.length,
      event?.currentTarget?.value?.length,
    );
  }

  function focusTextArea() {
    setTimeout(() => {
      inputContainer?.current?.focus();
    }, 10);
  }

  React.useEffect(() => {
    focusTextArea();
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (cubeState === "FINISHED") {
      if (isAudioFile(cubeFile)) {
        setSelectedInputType(inputSelectionTypes.conversation);
      }
    } else {
      if (detectedChecked) {
        setSelectedInputType(detectInputType(textArea));
      }
    }

    if (textArea !== "") {
      setTextAreaEmptyState(false);
    }

    addHTMLToArticleSkill();
    //eslint-disable-next-line
  }, [textArea]);

  function addHTMLToArticleSkill() {
    if ((isHTML(textArea) || isValidURL(textArea)) && !summaryOrigins) {
      const pipelineHasHTMLSkill = pipelineSkills?.steps?.some(
        (skill: SkillsType) => {
          return skill?.name === "Html-to-Article";
        },
      );

      const htmlSkill = flatMap(skillsLibrary?.skills).find((skill) => {
        return skill.name === "Html-to-Article";
      });

      // If skill is not in pipeline, add it
      if (!pipelineHasHTMLSkill) {
        const mutatedPipelineSkills = cloneDeep(pipelineSkills);
        const mutatedHTMLSkill = cloneDeep(htmlSkill);
        mutatedHTMLSkill.id = uuidv4();
        mutatedPipelineSkills.steps.unshift(mutatedHTMLSkill);
        setPipelineSkills(mutatedPipelineSkills);

        // If skill is in pipeline, but not at the beginning, move it to the beginning
      } else if (pipelineSkills?.steps[0]?.name !== "Html-to-Article") {
        const mutatedPipelineSkills = cloneDeep(pipelineSkills);
        const currentHtmlSkillIndex = mutatedPipelineSkills.steps.findIndex(
          (skill: any) => skill.name === "Html-to-Article",
        );
        const mutatedHTMLSkill = cloneDeep(htmlSkill);
        mutatedHTMLSkill.id = uuidv4();
        mutatedPipelineSkills?.steps?.splice(currentHtmlSkillIndex, 1);
        mutatedPipelineSkills?.steps?.unshift(mutatedHTMLSkill);
        setPipelineSkills(mutatedPipelineSkills);
      }
    }
  }
  return (
    <textarea
      data-cy="input-main-textarea"
      placeholder={t("Insert your text")}
      dir="ltr"
      value={textArea}
      onChange={handleChange}
      onBlur={handleOnBlur}
      onFocus={sendCursorToEnd}
      ref={inputContainer}
      className={`border-none overflow-y-auto resize-none h-full w-full rounded-none outline-none p-4 leading-relaxed text-md md:text-sm font-poppins font-medium dark:font-normal text-darkGray dark:text-white cursor-auto bg-white dark:bg-darkGrayBackground rounded-b-md`}
      data-gramm="false"
      disabled={disabled}
    />
  );
}
