import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { IconLogIn } from "../assets/SvgIcons";
import { loginFormAtom, savedPathAtom } from "../lib/atoms";
import { useEventLogger } from "../lib/event_logger";
import { useUser } from "./auth/auth";
import { useUserData } from "./auth/data";
import NewMenu from "./desktop-menu";

export default function Login() {
  const { user, loading } = useUser();
  const { value: userData } = useUserData();
  const { i18n, t } = useTranslation("user-menu");
  const setLoginForm = useSetRecoilState(loginFormAtom);
  const setSavedPath = useSetRecoilState(savedPathAtom);
  const location = useLocation();
  const { userUIEventLogger } = useEventLogger();

  const onClick = () => {
    setLoginForm(true);
    setSavedPath(location.pathname + location.search);
    userUIEventLogger("app__login__button__top", {
      uid: user?.uid,
      api_key: userData?.studio_key,
    });
  };

  return (
    <div className="grid grid-cols-1fr-auto rounded-md h-[39px]">
      <button
        onClick={onClick}
        className={`${loading ? "opacity-0" : "opacity-1"}`}
      >
        <div className="grid grid-cols-2-auto gap-x-4 items-center justify-start cursor-pointer bg-borderBlue hover:opacity-75 rounded-l-md px-4 h-full">
          <span className="text-white font-mono font-bold">{t("Log In")}</span>
          <figure className="text-white ">
            <IconLogIn
              width="22px"
              height="22px"
              className={`text-xs ${
                i18n.dir() === "rtl" ? "transform rotate-180" : ""
              }`}
            />
          </figure>
        </div>
      </button>
      <div className="rounded-r-md bg-linkWater dark:bg-darkGrayBackground focus-visible:border-2 dark:focus-visible:border-white focus-visible:border-black">
        <NewMenu type="ANONYMOUS" />
      </div>
    </div>
  );
}
