import { ArrowForwardIosSharp } from "@mui/icons-material";
import { AccordionSummary } from "@mui/material";
import "./docs-style.css";

export default function APIAccordionSummary(props: any) {
  return (
    <>
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
        {...props}
      />
    </>
  );
}
