import React, { Dispatch } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useRecoilValue, useSetRecoilState } from "recoil";
import logo from "../../assets/logo-forms.svg";
import darkModeLogo from "../../assets/logo-gray.svg";
import {
  experimentFinishTypeAtom,
  experimentModalAtom,
  savedCopyCodeAtom,
} from "../../lib/atoms";
import { FinishType, FormNameType } from "../../lib/interfaces";
import Button from "../button";
import { LottieLoader } from "../Loader/index";
import { useThemeColor } from "../theme-color";
import { getAPIKeyByTokenId, useUserOrganizationKeys } from "./data";

type FormType = {
  setForm: Dispatch<FormNameType>;
};

export default function ExperimentModal({ setForm }: FormType) {
  const { api_keys } = useUserOrganizationKeys();
  const visibleKey = [...api_keys].reverse()?.find((key) => {
    return key.state === "ACTIVE";
  });
  const isDark = useThemeColor();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [key, setKey] = React.useState<string>("");
  const finishType = useRecoilValue(experimentFinishTypeAtom);
  const savedCopyCode = useRecoilValue(savedCopyCodeAtom);

  const setExperimentModal = useSetRecoilState(experimentModalAtom);
  React.useEffect(() => {
    const getKey = async () => {
      const orgId = visibleKey?.account_id;
      const tokenId = visibleKey?.token_id;

      if (visibleKey?.account_id) {
        const lastKey = await getAPIKeyByTokenId({
          orgId,
          tokenId,
        });

        if (lastKey) {
          if (finishType === FinishType.COPY_CODE) {
            if ("clipboard" in navigator) {
              navigator.clipboard.writeText(
                savedCopyCode.replace(
                  "<YOUR-API-KEY>",
                  lastKey?.details?.api_key,
                ),
              );
            } else {
              document.execCommand(
                savedCopyCode.replace(
                  "<YOUR-API-KEY>",
                  lastKey?.details?.api_key,
                ),
              );
            }
            setKey(
              savedCopyCode.replace(
                "<YOUR-API-KEY>",
                lastKey?.details?.api_key,
              ),
            );
          } else {
            if ("clipboard" in navigator) {
              navigator.clipboard.writeText(`${lastKey?.details?.api_key}`);
            } else {
              document.execCommand(`${lastKey?.details?.api_key}`);
            }
            setKey(lastKey?.details?.api_key);
          }
        }
      }
      setLoading(false);
    };
    getKey();
    //eslint-disable-next-line
  }, [api_keys]);

  if (loading) return <LottieLoader />;

  return (
    <div
      className="min-h-0 overflow-x-hidden overflow-y-auto grid grid-rows-1fr-auto pb-10 gap-y-4"
      aria-label="success"
    >
      <div className="grid justify-center items-center">
        <figure className="image w-[165px] grid justify-center py-8">
          <img src={isDark ? darkModeLogo : logo} alt="Logo" />
        </figure>
      </div>

      <p className="dark:text-white text-black items-center justify-center grid text-center overflow-y-hidden">
        Thanks for signing in! The API key was generated for you and has been
        copied to your clipboard - go ahead and paste it into your project
      </p>

      <CopyToClipboard text={key}>
        <Button
          ariaLabel="continue"
          onClick={() => {
            setExperimentModal(false);
          }}
        >
          <span>Continue to studio</span>
        </Button>
      </CopyToClipboard>
    </div>
  );
}
