import Menu from "@mui/material/Menu";
import * as React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { CopyRequestIcon, IconEmptyEye } from "../assets/SvgIcons";
import { useEventLogger } from "../lib/event_logger";
import theme from "../theme";
import { getAPIKeyByTokenId } from "./auth/data";
import { LottieLoader } from "./Loader";
import AppTooltip from "./tooltip";

export default function GetFullKeyPopover({
  openedPopover = () => {},
  closedPopover = () => {},
  tokenId,
  orgId,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  tokenId: string;
  orgId: string | undefined;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("api-keys");
  const { userUIEventLogger } = useEventLogger();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    userUIEventLogger("settings__api_keys__view_full_key");
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <AppTooltip title={t<string>("View full key")}>
        <button
          className={`cursor-pointer w-fit-content rounded-md`}
          onClick={handleClick}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <figure>
            <IconEmptyEye width="17" height="17" className="text-shadeBlue" />
          </figure>
        </button>
      </AppTooltip>

      <GetFullKeyPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        orgId={orgId}
        tokenId={tokenId}
      />
    </React.Fragment>
  );
}

type IGetFullKeyPopup = {
  anchorEl: any;
  open: boolean;
  onClose: any;
  tokenId: string;
  orgId: string | undefined;
};

const GetFullKeyPopup = React.forwardRef(
  (props: IGetFullKeyPopup, ref: any) => {
    const { anchorEl, open, onClose, tokenId, orgId } = props;
    const [tipRight, setTipRight] = React.useState(0);
    const tipWidth = 18;
    React.useEffect(() => {
      if (anchorEl) {
        setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2);
      }
    }, [anchorEl]);
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            backgroundColor: theme.colors.lightBlack,
            color: theme.colors.white,
            padding: theme.spacing[4],
            width: "280px !important",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.3,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "4px",
              right: tipRight,
              width: tipWidth,
              height: 18,
              backgroundColor: theme.colors.lightBlack,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
              borderRadius: "4px",
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <GetFullKey onClose={onClose} tokenId={tokenId} orgId={orgId} />
      </Menu>
    );
  },
);

const GetFullKey = ({
  onClose = () => {},
  tokenId,
  orgId,
}: {
  onClose: any;
  tokenId: string;
  orgId: string | undefined;
}) => {
  const [apiKey, setAPIKey] = React.useState("");
  const [copied, setCopied] = React.useState(false);
  const { t } = useTranslation("api-keys");
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    const fetchAPIKey = async () => {
      if (orgId) {
        const result = await getAPIKeyByTokenId({
          tokenId,
          orgId,
        });
        if (result?.status_code === 200) {
          setAPIKey(result?.details?.api_key);
        } else {
          setAPIKey("");
        }
        setLoading(false);
        console.log(result);
      } else {
        setLoading(false);
      }
    };

    fetchAPIKey();
    //eslint-disable-next-line
  }, []);

  if (loading) return <LottieLoader />;

  if (apiKey === "" || apiKey === "undefined" || !apiKey || apiKey === null) {
    return (
      <div className="grid justify-center items-center">
        <h1 className="m-0 text-white text-lg">
          {t("Full API Key doesn't exist")}
        </h1>
      </div>
    );
  }
  return (
    <div className="grid justify-center items-center">
      <h1 className="m-0 text-white text-lg">{t("Full API Key:")}</h1>
      <p className="text-yellow text-xs">
        {apiKey}

        <CopyToClipboard
          text={apiKey}
          onCopy={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
          }}
        >
          <AppTooltip title={copied ? t("API Key copied") : t("Copy API Key")}>
            <button className="absolute right-0 top-0">
              <CopyRequestIcon
                width="17px"
                height="18px"
                className={`dark:text-turbo mx-4`}
              />
            </button>
          </AppTooltip>
        </CopyToClipboard>
      </p>
    </div>
  );
};
