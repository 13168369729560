import { bignumbers } from "../../PipelinePage/utils";

type StatisticBlockProps = {
  data: {
    usage: number;
    max: number;
  };
  title: string;
  extraTitle?: string;
};

export const StatisticBlock = ({
  data,
  title,
  extraTitle,
}: StatisticBlockProps) => {
  const { usage, max } = data;
  const height = (100 / max) * usage;
  return (
    <div className="flex mr-10">
      <div className="relative w-[3px] h-[70px] bg-tableBodyBorder dark:bg-inputBorderDarkGray rounded-[20px] mr-5">
        <div
          className="absolute bottom-0 left-[-1px] w-[5px] bg-skillPink rounded-[20px]"
          style={{ height: `${height}%` }}
        />
      </div>

      <div className="flex flex-col justify-between">
        <div className="white-space-nowrap font-poppins font-bold text-3xl text-borderBlue leading-7">
          {bignumbers(usage)} / {extraTitle ? extraTitle : bignumbers(max)}
        </div>
        <div className="font-poppins font-normal text-[16px] color-white">
          {title}
        </div>
      </div>
    </div>
  );
};
