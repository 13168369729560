import * as React from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { useResizeDetector } from "react-resize-detector";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import { IconCookies } from "../assets/SvgIcons";
import { useEventLogger, UserEvent } from "../lib/event_logger";
import theme from "../theme";
import { useUser } from "./auth/auth";
import { useThemeColor } from "./theme-color";

export default function StoreUTMCookies() {
  const cookies = new Cookies();
  const { search } = useLocation();
  const { width = 0, ref } = useResizeDetector();

  const utm_source = new URLSearchParams(search).get("utm_source");
  const utm_medium = new URLSearchParams(search).get("utm_medium");
  const utm_campaign = new URLSearchParams(search).get("utm_campaign");
  const utm_term = new URLSearchParams(search).get("utm_term");
  const utm_content = new URLSearchParams(search).get("utm_content");
  const utm_campaignid = new URLSearchParams(search).get("utm_campaignid");
  const utm_adgroupid = new URLSearchParams(search).get("utm_adgroupid");
  const referrer = document.referrer;

  const { isDark } = useThemeColor();
  const { t } = useTranslation();
  const { loading, user } = useUser();

  const [buttonPad, setButtonPad] = React.useState<string>("0px");
  const [cookieConsent, setCookieConsent] = React.useState<boolean>(false);
  const { userEventLogger } = useEventLogger();

  React.useEffect(() => {
    if (utm_source) cookies.set("utm_source", utm_source, { path: "/" });
    if (utm_medium) cookies.set("utm_medium", utm_medium, { path: "/" });
    if (utm_campaign) cookies.set("utm_campaign", utm_campaign, { path: "/" });
    if (utm_term) cookies.set("utm_term", utm_term, { path: "/" });
    if (utm_content) cookies.set("utm_content", utm_content, { path: "/" });
    if (utm_campaignid)
      cookies.set("campaignid", utm_campaignid, { path: "/" });
    if (utm_adgroupid)
      cookies.set("utm_adgroupid", utm_adgroupid, { path: "/" });
    if (referrer !== "") cookies.set("referrer", referrer, { path: "/" });
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (width < 1024) {
      setButtonPad("0px");
    } else {
      setButtonPad("70px");
    }
  }, [width]);

  React.useEffect(() => {
    if (!loading && user) {
      setCookieConsent(true);
    }
  }, [loading, user]);

  if (cookieConsent) {
    return (
      <div ref={ref}>
        <CookieConsent
          location="bottom"
          buttonText="Accept and Close"
          cookieName="studio_cookieconsent"
          containerClasses="border-t border-tableHeaderBorder grid justify-center"
          contentClasses="max-w-[1400px] items-center"
          buttonClasses="font-mono"
          onAccept={() => {
            userEventLogger(UserEvent.USER_COOKIE_CONSENT);
          }}
          contentStyle={{
            overflowWrap: "anywhere",
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: isDark
              ? theme.colors.darkGrayBackground
              : theme.colors.catskill,
            paddingRight: buttonPad,
          }}
          buttonStyle={{
            color: theme.colors.shadeBlue,
            border: "1px solid " + theme.colors.shadeBlue,
            fontSize: "13px",
            height: "40px",
            borderRadius: "5px",
            background: "transparent",
          }}
          expires={365}
        >
          <div className="grid lg:grid-flow-col grid-flow-row lg:gap-x-16 items-center min-w-0 p-4 lg:p-0">
            <div className="grid">
              <IconCookies
                height="43px"
                width="43px"
                className="text-shadeBlue  min-w-0"
              />
            </div>
            <p className="grid dark:text-white text-black break-words w-[200px] text-lg font-poppins items-center leading-[30px]">
              {t("Terms of service and Cookie Consent")}
            </p>
            <p className="dark:text-cookieGray text-black text-sm overflow-y-auto font-poppins font-normal m-0 break-words">
              {t(`By continuing to use the Language Studio or by clicking ‘Accept’ you
              agree to the terms of service, privacy policy and storing of cookies
              on your device to provide you service, enhance your usage experience
              and for analytical purposes. To learn more about our terms of
              service, privacy policy and our usage of cookies please see our`)}
              <a
                className="text-shadeBlue inline-block"
                href="https://www.oneai.com/tos"
                rel="noreferrer"
                target="_blank"
              >
                &nbsp;{t("Terms of service")}&nbsp;
              </a>
              {t("and")}
              <a
                className="text-shadeBlue inline-block"
                href="https://www.oneai.com/privacy-policy"
                rel="noreferrer"
                target="_blank"
              >
                &nbsp;{t("Privacy Policy")}&nbsp;
              </a>
            </p>
          </div>
        </CookieConsent>
      </div>
    );
  }

  return null;
}
