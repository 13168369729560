import Menu from "@mui/material/Menu";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "../assets/SvgIcons";
import i18n from "../i18n";
import theme from "../theme";
import { getAdmins, useAdminMutate } from "./auth/data";
import { LottieLoader } from "./Loader";
import AppTooltip from "./tooltip";

export default function DeleteAdminPopover({
  openedPopover = () => {},
  closedPopover = () => {},
  email,
  userId,
  setAdmins,
}: {
  openedPopover?: Function;
  closedPopover?: Function;
  email: string;
  userId: string;
  setAdmins: Function;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation("manage-admins");

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    closedPopover();
    setAnchorEl(null);
  };
  React.useEffect(() => {
    if (open) {
      openedPopover();
    }
  }, [open, openedPopover]);

  return (
    <React.Fragment>
      <AppTooltip title={t<string>("Delete Admin")}>
        <button className="text-shadeBlue" onClick={handleClick}>
          <TrashIcon height="20px" width="17px" className="text-shadeBlue" />
        </button>
      </AppTooltip>
      <AddAdminPopup
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        userId={userId}
        email={email}
        setAdmins={setAdmins}
      />
    </React.Fragment>
  );
}

type IAddAdmin = {
  anchorEl: any;
  open: boolean;
  onClose: any;
  userId: string;
  email: string;
  setAdmins: Function;
};

const AddAdminPopup = React.forwardRef((props: IAddAdmin, ref: any) => {
  const { anchorEl, open, onClose, email, userId, setAdmins } = props;
  const [tipRight, setTipRight] = React.useState(0);
  const tipWidth = 18;
  React.useEffect(() => {
    if (anchorEl) {
      setTipRight(anchorEl.offsetWidth / 2 - tipWidth / 2);
    }
  }, [anchorEl]);
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          backgroundColor: theme.colors.lightBlack,
          color: theme.colors.white,
          padding: theme.spacing[4],
          width: "280px",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.3,
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: "4px",
            right: tipRight,
            width: tipWidth,
            height: 18,
            backgroundColor: theme.colors.lightBlack,
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
            borderRadius: "4px",
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <AddAdminForm
        onClose={onClose}
        email={email}
        userId={userId}
        setAdmins={setAdmins}
      />
    </Menu>
  );
});

const AddAdminForm = ({
  onClose = () => {},
  email,
  userId,
  setAdmins,
}: {
  onClose: any;
  email: string;
  userId: string;
  setAdmins: Function;
}) => {
  const [error, setError] = React.useState("");
  const [state, setState] = React.useState("FORM");
  const { mutateUserField } = useAdminMutate();
  const { t } = useTranslation("manage-accounts");

  const confirmDelete = async () => {
    setState("LOADING");

    const result = await mutateUserField({
      clientId: userId,
      field: "is_admin",
      newValue: false,
    });

    if (result?.data?.success === true) {
      onClose();
      const adminList = await getAdmins(300);
      setAdmins(adminList);
    } else {
      setState("ERROR");
      setError(t(result?.data?.message));
    }
  };

  if (error) {
    return <div>{t(error)}</div>;
  }

  return (
    <div>
      {state === "LOADING" ? (
        <LottieLoader />
      ) : state === "SUCCESS" ? (
        <div></div>
      ) : state === "ERROR" ? (
        <div>{error}</div>
      ) : (
        <div className="grid text-center gap-y-1">
          <p className="text-lg underline">{t("Are you sure?")}</p>
          <div className="text-dashedGray break-all">
            <p className="m-0">{t("Do you really want to delete")}</p>
            <p className="m-3">{email}?</p>
          </div>

          <div
            dir={i18n.dir()}
            className={`grid grid-cols-1fr-2fr gap-x-2 mt-8`}
          >
            <button
              className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-grayButton rounded-md shadow-xl`}
              onClick={onClose}
              type="button"
            >
              {t("Cancel")}
            </button>
            <button
              className={`cursor-pointer text-center transform-none m-0 items-center py-3 px-5 text-white whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl`}
              onClick={() => {
                confirmDelete();
              }}
            >
              {t("Confirm")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
