import { Box, CircularProgress } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import * as Yup from "yup";
import { IconEyePassword } from "../../assets/SvgIcons";
import {
  experimentFinishTypeAtom,
  experimentModalAtom,
  loginFormAtom,
  registrationEmailAtom,
  shouldShowExperimentAtom,
} from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { FinishType, FormNameType } from "../../lib/interfaces";
import { useExperiment } from "../../lib/use-experiment";
import theme from "../../theme";
import { auth } from "../auth/auth";
import { useThemeColor } from "../theme-color";
import { createTempUser, fireEventAtom } from "./data";

type CredentialType = "LOGIN" | "SIGNUP";
type CredentialFormType = {
  type: CredentialType;
  setForm: Dispatch<FormNameType>;
};

export default function CredentialForm({ type, setForm }: CredentialFormType) {
  const { t } = useTranslation("auth");
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { isDark } = useThemeColor();

  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const { userEventLogger, userUIEventLogger } = useEventLogger();

  const setLoginForm = useSetRecoilState(loginFormAtom);
  const setFireEvent = useSetRecoilState(fireEventAtom);
  const setEmail = useSetRecoilState(registrationEmailAtom);
  const setExperimentModal = useSetRecoilState(experimentModalAtom);
  const setExperiment = useSetRecoilState(shouldShowExperimentAtom);
  const experimentFinishType = useRecoilValue(experimentFinishTypeAtom);

  const { isParticipating, reachedLimit } = useExperiment();

  const localExperiment = isParticipating && reachedLimit;
  const loginInitialValues = {
    email: "",
    password: "",
    opt_in: false,
  };

  const signUpSchema: object = Yup.object().shape({
    password: Yup.string()
      .min(6, t("Password is too short"))
      .max(14, t("Password is too long"))
      .required(t("Password is required")),
    email: Yup.string()
      .email("Please enter a valid email address")
      .required("Email is required"),
  });
  const inputContainer =
    "p-4 bg-white dark:bg-inputDarkGray border border-borderLight text-[14px] dark:border-darkGrayBorder rounded-[5px] text-black h-[54px] dark:text-white focus:outline-none w-full placeholder-formsgray ";

  const handleSubmit = async (values: any) => {
    setButtonLoading(true);

    const eventText = type === "SIGNUP" ? "signup" : "login";
    userUIEventLogger(`app__login__form__${eventText}`, {
      email: values.email,
      value: values?.email,
      api_key: values.email ?? "",
      uid: values.email ?? "",
    });

    if (type === "SIGNUP") {
      setEmail(values.email);

      const userCredential = {
        email: values.email,
        password: values.password,
      };

      const result = await createTempUser(userCredential);

      if (result?.success) {
        setErrorMessage("");
        setForm("validate-email");
        userEventLogger(UserEvent.SIGNUP_ANY_EMAIL_BUTTON, {
          email: values?.email,
          value: values?.email,
          api_key: values.email ?? "",
          uid: values.email ?? "",
        });
      } else {
        if (result?.code === "USER_EXISTS") {
          setErrorMessage("Email already exists! please sign in.");
        } else {
          setErrorMessage(
            "We couldn't process your request. Please try again later.",
          );
        }
      }
    } else if (type === "LOGIN") {
      try {
        await signInWithEmailAndPassword(auth, values.email, values.password);
        setLoginForm(false);
        setFireEvent("Email Login");

        if (localExperiment) {
          if (experimentFinishType === FinishType.COPY_CODE) {
            setExperimentModal(true);
          }
          setExperiment(false);
          setForm("experiment-modal");
        }
      } catch (error: any) {
        console.log(error);
        const firebaseErrorMessage = error.code;

        switch (firebaseErrorMessage) {
          case "auth/wrong-password":
            setErrorMessage(
              `Wrong password. Don't remember it? press "Forgot Password"`,
            );
            break;
          case "auth/user-not-found":
            setErrorMessage(
              `Wrong password. Don't remember it? press "Forgot Password"`,
            );
            break;

          default:
            setErrorMessage(error?.message);
        }
      }
    }
    setButtonLoading(false);
  };

  return (
    <Formik
      initialValues={loginInitialValues}
      validationSchema={signUpSchema}
      onSubmit={async (values) => {
        await handleSubmit(values);
      }}
    >
      {({ handleSubmit }: any) => (
        <div className="space-y-6">
          <Form
            className="grid gap-y-7 overflow-y-auto font-poppins"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit();
              }
            }}
          >
            <div>
              <Field
                name="email"
                type="text"
                placeholder={t("Email")}
                className={`${inputContainer}`}
              />
              <ErrorMessage name="email" component="div">
                {(msg) => (
                  <div
                    role="alert"
                    id="email"
                    className="invalid-feedback text-red text-left pt-1 font-normal text-xs"
                  >
                    {t(msg)}
                  </div>
                )}
              </ErrorMessage>
            </div>

            <div>
              <Field name="password" type="text">
                {({ field }: any) => (
                  <label htmlFor="password" className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      autoComplete="current_password"
                      className={`${inputContainer} `}
                      maxLength={14}
                      {...field}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        userUIEventLogger("app__login__form__show_password");
                        setShowPassword((prevState) => !prevState);
                      }}
                      className="absolute mt-1 right-4 dark:bg-inputDarkGray bg-white h-[49px]"
                    >
                      <IconEyePassword />
                    </button>
                  </label>
                )}
              </Field>

              <ErrorMessage name="password" component="div">
                {(msg) => (
                  <div
                    role="alert"
                    id="password"
                    className="invalid-feedback text-red text-left pt-1 font-normal text-xs"
                  >
                    {t(msg)}
                  </div>
                )}
              </ErrorMessage>
            </div>

            {type === "SIGNUP" ? (
              <Field name="opt_in" type="checkbox">
                {({ field, form }: any) => (
                  <label className="grid grid-cols-auto-1fr" htmlFor="agree">
                    <input
                      type="checkbox"
                      className="checkbox bg-white dark:bg-darkBackground"
                      onChange={(e) => {
                        form.setFieldValue(field.name, e.target.checked);
                      }}
                      {...field}
                    />
                    <span
                      onClick={(e) => {
                        form.setFieldValue(field.name, !field.value);
                      }}
                      className={`font-normal dark:text-white text-black text-sm leading-6 -mt-1 select-none`}
                    >
                      {t(
                        "Opt-in to receive occasional product updates and digests.",
                      )}
                    </span>
                  </label>
                )}
              </Field>
            ) : (
              <button
                onClick={() => {
                  setForm("forgot-password");
                  userUIEventLogger("app__login__form__forgot-password", {
                    uid: "empty",
                    api_key: "empty",
                  });
                }}
                className="text-sm p-0 grid justify-center text-shadeBlue -mt-3 pt-2 pb-3"
              >
                {t("Forgot password")}
              </button>
            )}
          </Form>

          <div className="relative">
            {buttonLoading ? (
              <button
                type="button"
                className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none  p-4 hover:bg-opacity-60 w-[276px] grid grid-flow-col`}
                disabled
              >
                <span>
                  <Box>
                    <CircularProgress
                      style={{
                        width: 15,
                        height: 15,
                        color: "white",
                        padding: 0,
                        margin: 0,
                      }}
                    />
                  </Box>
                </span>
              </button>
            ) : (
              <button
                onClick={() => {
                  handleSubmit();
                }}
                type="submit"
                className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none  p-4 hover:bg-opacity-60 w-[276px]`}
              >
                {type === "LOGIN" ? t("Sign in") : t("Sign up")}
              </button>
            )}
            {errorMessage && (
              <p className="text-red font-normal text-sm text-center p-0">
                {errorMessage}
              </p>
            )}
          </div>

          <style>
            {` 
        
          input:-webkit-autofill,
          input:-webkit-autofill:hover, 
          input:-webkit-autofill:focus,
          textarea:-webkit-autofill,
          textarea:-webkit-autofill:hover,
          textarea:-webkit-autofill:focus,
          select:-webkit-autofill,
          select:-webkit-autofill:hover,
          select:-webkit-autofill:focus {
            border: 1px solid ${
              isDark ? theme.colors.darkGrayBorder : theme.colors.grayBorder
            };
            -webkit-text-fill-color: ${
              isDark ? theme.colors.white : theme.colors.grayBorder
            };
            -webkit-box-shadow: 0 0 0px 1000px ${
              isDark ? theme.colors.inputDarkGray : theme.colors.white
            } inset;
            transition: background-color 5000s ease-in-out 0s;
      }`}
          </style>
        </div>
      )}
    </Formik>
  );
}
