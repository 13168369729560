export const multiSummaryText = `Article 1

========================

Article 2

========================

Article 3
`;
