import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import {
  BillingIcon,
  IconGoBackTostudio,
  IconManageAccounts,
  IconManageAdmins,
  IconSettings,
  IconUsageAndQuota,
  IconYourApiKeys,
} from "../assets/SvgIcons";
import { useUserData } from "../components/auth/data";
import Layout from "../components/Layout/layout";
import { useEventLogger, UserEvent } from "../lib/event_logger";

export default function SettingsPage() {
  return (
    <Layout headerRightRender={<React.Fragment />} loader={false}>
      <Settings />
    </Layout>
  );
}
function Settings() {
  return (
    <div className="md:gap-x-2 grid grid-rows-auto-1fr md:grid-cols-minmax-auto overflow-y-auto overflow-x-auto h-full">
      <div className="bg-white dark:bg-darkGrayBackground p-3 md:px-4 md:pt-6 md:pb-6 rounded-md h-fit-content md:h-[calc(100vh-115px)] min-w-[235px]">
        <SettingsMenu />
      </div>
      <div className="bg-white dark:bg-darkGrayBackground p-3 md:px-8 py-10 rounded-md md:h-[calc(100vh-115px)] md:max-h-[calc(100vh-115px)] md:overflow-x-auto">
        <Outlet />
      </div>
    </div>
  );
}

const SettingsMenu = () => {
  const { t } = useTranslation("settings");
  const { userEventLogger, userUIEventLogger } = useEventLogger();
  const { value, safeMail } = useUserData();

  const buttonStyle = `cursor-pointer grid grid-cols-auto-1fr gap-x-4 p-4 justify-start rounded-md w-full text-md h-[57px] items-center dark:text-white text-black hover:dark:text-white hover:text-black w-full w-[200px] focus-visible:border-2 dark:focus-visible:border-white focus-visible:border-black`;
  const buttonActive = `${buttonStyle} bg-opacity-10 dark:bg-opacity-40 bg-darkBackground hover:bg-opacity-10 hover:dark:bg-opacity-40 dark:bg-darkBackground`;
  const buttonInactive = `${buttonStyle}  hover:bg-opacity-10 hover:bg-darkBackground dark:hover:bg-opacity-40  `;

  const isUserAdmin =
    value?.is_admin === true &&
    (value?.role === "Admin" ||
      value?.role === "Editor" ||
      value?.role === "Viewer") &&
    safeMail === true;

  return (
    <div className="grid justify-start h-full w-full overflow-y-auto">
      <nav className="w-full space-y-2">
        <NavLink
          className={`${buttonStyle} ${buttonInactive}`}
          to="/"
          onClick={() => {
            userEventLogger(UserEvent.GO_BACK_TO_STUDIO);
          }}
        >
          <IconGoBackTostudio className="text-shadeBlue" />
          <span className="font-poppins font-bold text-black dark:text-white">
            {t("Back to Studio")}
          </span>
        </NavLink>
        <h2 className="text-xs font-semibold font-poppins text-formsgray p-4">
          {t("Account settings")}
        </h2>
        <NavLink
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          data-cy="your-api-keys-page-link"
          to="api-keys"
          onClick={() =>
            userUIEventLogger("settings__left_pane__your_api_keys")
          }
        >
          <IconYourApiKeys className="text-shadeBlue" />
          <span className="text-gray-600 font-poppins font-bold dark:text-white">
            {t("Your Api Keys")}
          </span>
        </NavLink>
        {/*
          
        
        }
        <NavLink
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="team-access"
        >
          <IconTeamAccess width="17" height="18" />
          <span className="text-gray-600  font-poppins font-bold ">
            Team Access
          </span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="team-access"
        >
          <IconChangePassword width="17" height="18" />
          <span className="text-gray-600  font-poppins font-bold ">
            Change Password
          </span>
        </NavLink>
        */}
        <NavLink
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="usage-chart"
          onClick={() => {
            userEventLogger(UserEvent.USAGE_QUOTA_SETTINGS);
          }}
        >
          <IconUsageAndQuota className="text-shadeBlue" />
          <span className="text-gray-600  font-poppins font-bold ">
            {t("Usage & Quota")}
          </span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="billing"
          onClick={() => {
            userUIEventLogger("settings menu billing");
          }}
        >
          <BillingIcon className="text-shadeBlue" />
          <span className="text-gray-600  font-poppins font-bold ">
            {t("Billing")}
          </span>
        </NavLink>
        <hr
          className={`border-gray dark:border-darkGrayBorder`}
          style={isUserAdmin ? {} : { display: "none" }}
        />
        <h2
          style={isUserAdmin ? {} : { display: "none" }}
          className={`text-xs font-semibold font-poppins text-formsgray p-4`}
        >
          {t("ONE AI ADMIN")}
        </h2>
        {/*
        <hr className="border-gray" />
        <h2 className="text-xs font-semibold font-poppins text-formsgray pt-3">
          ONE AI ADMIN
        </h2>
        <NavLink
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="global-statistics"
        >
          <IconGlobalStatistics width="17" height="18" />
          <span className="text-gray-600  font-poppins font-bold ">
            Global Statistics
          </span>
        </NavLink>
        <div className="flex flex-col">
          <button
            onClick={() => {
              setCustomersActionMenuOpen(!isCustomersActionMenuOpen);
            }}
            className={`cursor-pointer flex space-x-4 px-2 py-3 justify-start rounded-lg w-full text-md bg-white text-black hover:bg-purple hover:bg-opacity-10 hover:text-strongPurple`}
          >
            <IconCustomerActions width="17" height="18" />
            <span className="text-gray-600 font-poppins font-bold flex flex-row items-center gap-x-2">
              Customer Actions
              {isCustomersActionMenuOpen ? (
                <IconArrowDownNav width="8" height="4" />
              ) : (
                <IconArrowUpNav width="8" height="4" />
              )}
            </span>
          </button>
          {isCustomersActionMenuOpen && (
            <div className="flex flex-col gap-y-4 text-sm justify-center font-poppins font-bold self-center">
              <NavLink
                to="/customer-statistics"
                className="hover:text-strongPurple"
              >
                Customer Statistics
              </NavLink>
              <NavLink to="/customer-users" className="hover:text-strongPurple">
                Customer Users
              </NavLink>
              <NavLink to="/customer-keys" className="hover:text-strongPurple">
                Customer Keys
              </NavLink>
            </div>
          )}
        </div>
          */}
        <NavLink
          style={isUserAdmin ? {} : { display: "none" }}
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="utilities"
        >
          <IconSettings width="17" height="18" className="text-shadeBlue" />
          <span className="text-gray-600  font-poppins font-bold whitespace-nowrap">
            {t("Utilities")}
          </span>
        </NavLink>
        <NavLink
          style={isUserAdmin ? {} : { display: "none" }}
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="manage-accounts"
        >
          <IconManageAccounts
            width="17"
            height="18"
            className="text-shadeBlue"
          />
          <span className="text-gray-600  font-poppins font-bold whitespace-nowrap">
            {t("Manage Accounts")}
          </span>
        </NavLink>
        <NavLink
          style={isUserAdmin ? {} : { display: "none" }}
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="content-management"
        >
          <IconSettings width="17" height="18" className="text-shadeBlue" />
          <span className="text-gray-600  font-poppins font-bold whitespace-nowrap">
            {t("Content")}
          </span>
        </NavLink>
        <NavLink
          style={isUserAdmin ? {} : { display: "none" }}
          className={({ isActive }) =>
            isActive ? buttonActive : buttonInactive
          }
          to="manage-admins"
        >
          <IconManageAdmins width="17" height="18" className="text-shadeBlue" />
          <span className="text-gray-600  font-poppins font-bold whitespace-nowrap">
            {t("Manage Admins")}
          </span>
        </NavLink>
      </nav>
    </div>
  );
};
