import splitCursorVertical from "../../assets/pages/split-cursor-vertical.png";
import splitCursor from "../../assets/pages/split-cursor.png";

export const ResizeGutterRow = ({ getGutterProps }: any) => {
  return (
    <>
      <div
        className="w-full h-full hidden md:block cursor-ns-resize	relative gutter-row gutter-vertical"
        {...getGutterProps("row", 1)}
      />
      <style>
        {`
          .gutter-row::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            height: 2px;
            width: 25px;
            display: block;
            border-radius: 2px;
            // background: white;
            background: transparent;
            transform: translate(-50%, -50%);
          }

          .gutter-row {
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: 50%;
            transition: 0.5s;
            z-index: 2;
            background-clip: content-box;
          }

          .gutter-row.gutter-horizontal {
            padding-inline: 3px;
            cursor: auto;
            cursor: url("../../assets/pages/split-cursor.png") 24 12, auto;
          }
          .gutter-row.gutter-vertical {
            padding-block: 3px;
            cursor: auto;
            cursor: url(${splitCursorVertical}) 12 24, auto;
          }
          .gutter-row:hover {
            background-clip: content-box;
            background-color: #f13be9;
          }

      `}
      </style>
    </>
  );
};

export const ResizeGutterCol = ({ getGutterProps }: any) => {
  return (
    <>
      <div
        className="w-full h-full hidden md:block cursor-ew-resize relative gutter-col gutter-horizontal"
        {...getGutterProps("column", 1)}
      />
      <style>
        {`
          .gutter-col::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 25px;
            display: block;
            border-radius: 2px;
            // background: white;
            background: transparent;
            transform: translate(-50%, -50%);
          }

          .gutter-col {
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: 50%;
            transition: 0.5s;
            z-index: 2;
            background-clip: content-box;
            }

          .gutter-col.gutter-horizontal {
            padding-inline: 3px;
            cursor: auto;
            cursor: url(${splitCursor}) 24 12, auto;
          }
          .gutter-col.gutter-vertical {
            padding-block: 3px;
            cursor: auto;
            cursor: url("../../assets/pages/split-cursor-vertical.png") 12 24, auto;
          }
          .gutter-col.gutter-vertical {
            padding-block: 3px;
            cursor: url("../../assets/pages/split-cursor-vertical.png") 12 24, auto !important;
          }
          .gutter-col:hover {
            background-clip: content-box;
            background-color: #f13be9;
          }

      `}
      </style>
    </>
  );
};
