import { Box, CircularProgress } from "@mui/material";
import { signInAnonymously, signInWithEmailAndPassword } from "firebase/auth";
import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import logo from "../../assets/logo-forms.svg";
import darkModeLogo from "../../assets/logo-gray.svg";
import { IconSuccess } from "../../assets/SvgIcons";
import {
  anonymousLoginAtom,
  experimentFinishTypeAtom,
  experimentModalAtom,
  registrationTokenAtom,
  shouldShowExperimentAtom,
} from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import { FinishType, FormNameType } from "../../lib/interfaces";
import { useExperiment } from "../../lib/use-experiment";
import useMustLogin from "../../lib/use-must-login";
import { auth, useUser } from "../auth/auth";
import { LottieLoader } from "../Loader";
import { useThemeColor } from "../theme-color";
import {
  createAnonymousKeys,
  fireEventAtom,
  useCreateKey,
  validateTempUser,
} from "./data";

type FormType = {
  setForm: Dispatch<FormNameType>;
};

export default function VerificationSuccessModal({ setForm }: FormType) {
  const { t } = useTranslation("index");
  const { isDark } = useThemeColor();
  const { user } = useUser();
  const [buttonLoading] = React.useState<boolean>(false);
  const [modalLoading, setModalLoading] = React.useState<boolean>(true);
  const { userEventLogger } = useEventLogger();
  const setFireEvent = useSetRecoilState(fireEventAtom);
  const setAnonymousLogin = useSetRecoilState(anonymousLoginAtom);
  const setExperimentModal = useSetRecoilState(experimentModalAtom);
  const setExperiment = useSetRecoilState(shouldShowExperimentAtom);
  const experimentFinishType = useRecoilValue(experimentFinishTypeAtom);
  const { createKey } = useCreateKey();
  const [registrationToken, setRegistrationToken] = useRecoilState(
    registrationTokenAtom,
  );
  const { isParticipating, reachedLimit, loading } = useExperiment();
  const mustLogin = useMustLogin();

  React.useEffect(() => {
    if (!loading) {
      const localExperiment = isParticipating && reachedLimit;
      const validateUser = async () => {
        try {
          const result = await validateTempUser(registrationToken);

          if (result.success) {
            const login = await signInWithEmailAndPassword(
              auth,
              result?.details?.userData?.email,
              result?.details?.userData?.password,
            );
            if (login) {
              const keyData = await createKey({
                token_id: "",
                allowed_skills: ["ALL"],
                rate_limit: 100,
                type: "USER_CREATED",
                state: "ACTIVE",
              });

              if (keyData) {
                setForm("none");
                setRegistrationToken("");
                setFireEvent("Email Signup");
                console.log(
                  "Signed up with email, but probably :: isPart:",
                  isParticipating,
                  "Reach:",
                  reachedLimit,
                );
                if (localExperiment) {
                  if (experimentFinishType === FinishType.COPY_CODE) {
                    setExperimentModal(true);
                  }
                  setExperiment(false);
                  setForm("experiment-modal");
                }
              }
            } else {
              console.log(
                "Login failed - probably because verify token wasnt correct",
              );
              setForm("login");
              setRegistrationToken("");
            }
          } else {
            if (!user && !mustLogin.shouldShowLoginForm) {
              setModalLoading(true);
              setAnonymousLogin(true);
              localStorage.setItem("anonymousLogin", "true");
              const userResult = await signInAnonymously(auth);
              if (userResult?.user) {
                const keysResult = await createAnonymousKeys("0");
                if (keysResult.success === true) {
                  setModalLoading(false);
                  setAnonymousLogin(false);
                  localStorage.setItem("anonymousLogin", "false");
                } else {
                  const keysResult = await createAnonymousKeys("0");
                  if (keysResult.success === true) {
                    setModalLoading(false);
                    setAnonymousLogin(false);
                    localStorage.setItem("anonymousLogin", "false");
                  }
                }
              }
              console.log("Could not validate user");
              setForm("login");
              setRegistrationToken("");
            } else {
              setRegistrationToken("");
              setForm("login");
            }
          }
        } catch (e: any) {
          console.log(e);
        }
        setModalLoading(false);
      };
      if (registrationToken) {
        validateUser();
      } else {
        console.log("No registration token");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (modalLoading || loading) {
    return (
      <div className="min-h-0 h-[579px] overflow-x-hidden overflow-y-auto grid grid-rows-1fr-auto justify-center items-center">
        <LottieLoader />
      </div>
    );
  }

  return (
    <div className="min-h-0 h-[579px] overflow-x-hidden overflow-y-auto grid grid-rows-1fr-auto">
      <div className="space-y-8">
        <div className="py-3 grid justify-center">
          <figure className="image w-[110px]">
            <img src={isDark ? darkModeLogo : logo} alt="Logo" />
          </figure>
        </div>
        <div className="pb-3 grid justify-center pt-8">
          <figure className="image w-[110px]">
            <IconSuccess />
          </figure>
        </div>
        <p className="grid text-center font-bold text-black dark:text-white  font-poppins text-2xl leading-8 m-0 p-0 ">
          {t("Verification successful")}
        </p>
        <p className="text-black dark:text-white text-sm font-poppins font-normal text-center leading-7 w-[277px]">
          {t("You may now continue to the Language Studio")}
        </p>
      </div>

      <div className="grid items-end px-2 gap-y-4 ">
        <div>
          {buttonLoading ? (
            <button
              type="button"
              className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none  p-4 hover:bg-opacity-60 w-full grid grid-flow-col`}
              disabled
            >
              <span>
                <Box>
                  <CircularProgress
                    style={{
                      width: 15,
                      height: 15,
                      color: "white",
                      padding: 0,
                      margin: 0,
                    }}
                  />
                </Box>
              </span>
            </button>
          ) : (
            <button
              onClick={async () => {
                setRegistrationToken("");
                userEventLogger(UserEvent.LOGIN, {
                  sign_type: "Email",
                });
              }}
              type="submit"
              className={`bg-shadeBlue font-mono rounded-md text-sm text-white outline-none w-full p-4 hover:bg-opacity-60`}
            >
              {t("Continue")}
            </button>
          )}
        </div>

        <div className="grid justify-center py-3 pt-10">
          <div className="text-black dark:text-white text-sm font-poppins font-normal">
            {t("Have an account?")}
            <button
              className="text-shadeBlue px-2 font-mono font-bold justify-self-center"
              onClick={() => {
                setRegistrationToken("");
                setForm("login");
              }}
            >
              {t("Sign in")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
