import parse from "html-react-parser";
import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  summaryOriginsAtom,
  textAreaAtom,
  textAreaEmptyStateAtom,
} from "../../../lib/atoms";
import { useEventLogger, UserEvent } from "../../../lib/event_logger";
import FeedbackPopup from "../Output/HighlightText/feedback";
import { stripHTML } from "../utils";
import { apiResponseAtom } from "./../../pipeline/pipeline-atoms";

type HTMLTextAreaProps = {
  inputContainer: any;
};

export default function HTMLTextArea({ inputContainer }: HTMLTextAreaProps) {
  const [textAreaEmptyState, setTextAreaEmptyState] = useRecoilState(
    textAreaEmptyStateAtom,
  );
  const [textArea, setTextarea] = useRecoilState(textAreaAtom);
  const [summaryOrigins, setSummaryOrigins] =
    useRecoilState(summaryOriginsAtom);
  const { userEventLogger } = useEventLogger();
  const [textAreaEditable, setTextAreaEditable] = React.useState<boolean>(true);
  const apiResponse = useRecoilValue(apiResponseAtom);
  const [labels, setLabels] = React.useState<any>([]);

  React.useEffect(() => {
    let array = [];
    for (let i = 0; i < apiResponse?.output.length; i++) {
      array = apiResponse.output[i].labels.filter(
        (label: any) => label.skill === "origin",
      );
    }

    setLabels(array);
    // eslint-disable-next-line
  }, [apiResponse]);

  const getLabel = (start: number, end: number, text: string) => {
    return labels.find(
      (label: any) =>
        Number(label.input_spans[0].start) === Number(start) &&
        Number(label.input_spans[0].end) === Number(end) &&
        label.span_text.trim() === text.trim(),
    );
  };

  const handleOnBlur = () => {
    if (textArea === "") {
      setTextAreaEmptyState(true);
    }
    if (!textAreaEmptyState) {
      userEventLogger(UserEvent.INSERT_OR_PASTE_TEXT, { value: textArea });
      userEventLogger(UserEvent.INPUT_CONTENT, {
        value: "INSERT_OR_PASTE_TEXT",
      });
    }
  };
  const handleChange = (e: any) => {
    if (textAreaEditable) {
      if (textArea === "" && textArea !== "" && !textAreaEmptyState) {
        userEventLogger(UserEvent.INSERT_OR_PASTE_TEXT);
      }
      if (summaryOrigins) {
        setSummaryOrigins(false);
        setTextarea(stripHTML(textArea));
      } else {
        console.log("Here");
        setTextarea(textArea);
      }
      if (textAreaEmptyState && textArea !== "") {
        setTextAreaEmptyState(false);
        setTimeout(() => {
          inputContainer?.current?.focus();
        }, 10);
      }
    }
  };

  return (
    <>
      <div
        id="editor"
        onKeyDown={handleChange}
        ref={inputContainer}
        style={{ wordBreak: "keep-all" }}
        onBlur={handleOnBlur}
        dir="ltr"
        onInput={handleChange}
        onChange={handleChange}
        className={`border-none resize-none w-full rounded-none outline-none p-4 text-md md:text-sm font-poppins font-medium dark:font-normal cursor-auto leading-relaxed input-textarea text-darkGray dark:text-white bg-white dark:bg-darkGrayBackground rounded-b-md overflow-y-scroll`}
        contentEditable={textAreaEditable}
        suppressContentEditableWarning={true}
      >
        {Object.values(parse(textArea)).map((line, index) => {
          return (
            <span key={index}>
              {line.props && line.props.children !== null ? (
                <InputLine
                  data={getLabel(
                    line.props?.start,
                    line.props?.end,
                    line.props.children,
                  )}
                  text={line.props.children}
                  setTextAreaEditable={setTextAreaEditable}
                />
              ) : (
                <>{line}</>
              )}
            </span>
          );
        })}
      </div>

      <style>
        {`
          .input-textarea {
            -moz-appearance: textfield-multiline;
            -webkit-appearance: textarea;
            word-break: break-all;
            white-space: pre-wrap;
            height: 100%;
            overflow: auto;
          }
        `}
      </style>
    </>
  );
}

function InputLine({
  text,
  setTextAreaEditable,
  data,
}: {
  text: any;
  setTextAreaEditable: any;
  data: any;
}) {
  return (
    <span
      onMouseOver={() => {
        setTextAreaEditable(false);
      }}
      onMouseLeave={() => {
        setTextAreaEditable(true);
      }}
    >
      <FeedbackPopup
        data={{
          label: "summarize",
          value: data,
        }}
      >
        <span
          className={`text-white bg-shadeBlue cursor-pointer inline-grid rounded-[3px]`}
        >
          {text}
        </span>
      </FeedbackPopup>
    </span>
  );
}
