import { Box, CircularProgress } from "@mui/material";
import { AxiosError } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  IconRunPipeline,
  IconSharePipeline,
  IconTooltip,
} from "../../assets/SvgIcons/";
import { useUserData } from "../../components/auth/data";
import ErrorPopup from "../../components/error-popup";
import Layout from "../../components/Layout/layout";
import AppTooltip from "../../components/tooltip";
import {
  anonymousLoginAtom,
  cubeFileAtom,
  cubeStateAtom,
  inputResponseAtom,
  inputTypeAtom,
  pipelineDNDColumnsAtom,
  pipelineDNDColumnsOriginalAtom,
  summaryOriginsAtom,
  textAreaAtom,
  textAreaEmptyStateAtom,
} from "../../lib/atoms";
import { useEventLogger, UserEvent } from "../../lib/event_logger";
import highlightRange from "../../lib/InputHighlight";
import { PipelineStep } from "../../lib/interfaces";
import contentFile from "../../utils/content.json";
import { parseConversation } from "../../utils/detectTypeText";
import { useGetPipeline } from "./api";
import DragAndDrop from "./DragAndDrop";
import Input from "./Input";
import InputTabs from "./input-tabs";
import Output from "./Output";
import OutputData from "./Output/output-data";
import "./styles.css";
import {
  addPipeline,
  capitalizeFirstCharacter,
  decodeHTMLEntities,
  getPipelineFromFirestore,
  hasWritingSkillBefore,
  inputSelectionTypes,
  isAudioFile,
  randomString,
  stripHTML,
} from "./utils";

const PipelinePage = () => {
  // Hooks
  const { userEventLogger } = useEventLogger();
  const { i18n, t } = useTranslation("index");
  const { loading, value } = useUserData();
  const location = useLocation();
  const { getPipeline } = useGetPipeline();

  // States
  const [loader, setLoader] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [search, setSearch] = useState<string>("");
  const [textarea, setTextarea] = useRecoilState<string>(textAreaAtom);
  const [text, setText] = useState<string>("");
  const [originLabels, setOriginLabels] = useState([]);
  const [labels, setLabels] = useState();
  const [pipelineParams, setPipelineParams] = useState<boolean>(false);
  const [cubeState, setCubeState] = useRecoilState(cubeStateAtom);
  const [cubeFile, setCubeFile] = useRecoilState(cubeFileAtom);
  const [hasPipelineErrors, setHasPipelineErrors] = useState<boolean>(true);
  const [pipelineDNDColumns, setPipelineDNDColumns] = useRecoilState(
    pipelineDNDColumnsAtom,
  );
  const pipelineDNDColumnsOriginal = useRecoilValue(
    pipelineDNDColumnsOriginalAtom,
  );
  const [inputResponse, setInputResponse] = useRecoilState(inputResponseAtom);
  const setTextAreaEmptyState = useSetRecoilState(textAreaEmptyStateAtom);

  const [selectedInputType, setSelectedInputType] =
    useRecoilState(inputTypeAtom);
  const [sharePipelineButtonLoading, setSharePipelineButtonLoading] =
    useState<boolean>(false);
  const [sharePipelineMessage, setSharePipelineMessage] = useState<string>("");
  const [clickedSharePipeline, setClickedSharePipeline] =
    useState<boolean>(false);
  const [shouldRunPipeline, setShouldRunPipeline] = useState<boolean>(false);
  const [summaryOrigins, setSummaryOrigins] =
    useRecoilState<boolean>(summaryOriginsAtom);

  // Refs
  const originalText = useRef<any>();
  const apiResponse = useRef<any>([]);
  const outputData = useRef<Array<any>>([]);
  const outputIndexChosen = useRef<any>(0);
  const sharePipelineId = useRef<string>("");
  const runPipelineRef = useRef<any>([]);

  // Atoms
  const anonymousLogin = useRecoilValue(anonymousLoginAtom);

  const runPipeline = () => {
    let { input, input_type, steps } = requestBuilder();

    // const skillNamesArray = steps?.map((skill: any) => skill?.skill);

    setLoader(true);
    // userEventLogger(UserEvent.RUN_PIPELINE, {
    //   value: skillNamesArray?.join(","),
    // });

    let formattedText = input;
    if (summaryOrigins) formattedText = stripHTML(input);

    getPipeline(formattedText, input_type, steps, value?.studio_key)
      .then(({ data, headers }: any) => {
        apiResponse.current = data ?? [];
        outputData.current = data?.output ?? [];

        formattedText = stripHTML(textarea);
        originalText.current = formattedText;
        handleSetData();

        if (data?.input_text && cubeState === "FINISHED") {
          setInputResponse(true);
          setTextarea(data?.input_text);
        }

        userEventLogger(UserEvent.PIPELINE_SUCCESS, {
          value: headers?.["x-oneai-request-id"],
        });
        if (pipelineParams) setPipelineParams(false);
      })
      .catch(
        (
          error: AxiosError<{
            status_code: string;
            message: string;
            details: string;
          }>,
        ) => {
          setLoader(false);
          setHasError(true);
          if (error.response) {
            setError({
              name: error?.response?.data?.message
                ? capitalizeFirstCharacter(error?.response?.data?.message)
                : "Error",
              message: error.response.data.details,
            });
          } else {
            console.error("[PipelinePage] error", error);
            setError({
              name: "Error",
              message: error.message,
            });
          }
        },
      );
  };

  useEffect(() => {
    if (pipelineParams) {
      if (!anonymousLogin) {
        if (!loading) {
          userEventLogger(UserEvent.OPEN_SHARE_LINK, {
            value: window.location.href,
            date_time: new Date().toISOString(),
            user_id: value?.uid,
          });
          runPipeline();
        }
      }
    }
    //eslint-disable-next-line
  }, [pipelineParams, loading, anonymousLogin]);

  useEffect(() => {
    const httpRegexp = new RegExp(
      // eslint-disable-next-line
      /^https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$/gi,
    );
    if (cubeState === "FINISHED") {
      if (isAudioFile(cubeFile)) {
        setSelectedInputType(inputSelectionTypes.conversation);
      }
    } else if (textarea?.trim()?.match(httpRegexp)) {
      setSelectedInputType(inputSelectionTypes.article);
    } else if (Array.isArray(parseConversation(textarea)) && textarea.length) {
      setSelectedInputType(inputSelectionTypes.conversation);
    } else {
      setSelectedInputType(inputSelectionTypes.article);
    }
  }, [textarea, setSelectedInputType, cubeState, cubeFile]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    if (params.pipeline) {
      setLoader(true);

      if (value?.studio_key) {
        getPipelineFromFirestore(params.pipeline).then((res: any) => {
          if (res?.pipeline) {
            setPipelineDNDColumns({
              ...pipelineDNDColumns,
              pipeline: res.pipeline,
            });

            if (res?.file) {
              setCubeState("FINISHED");
              setInputResponse(false);
              setCubeFile(res.file);
            } else {
              setTextAreaEmptyState(false);
            }

            if (res?.text) {
              setTextarea(res?.text);
            } else {
              setTextarea(res?.input);
              setInputResponse(true);
            }

            if (res?.input_type === "conversation")
              setSelectedInputType(inputSelectionTypes["conversation"]);
            else if (res?.input_type === "auto-detect")
              setSelectedInputType(inputSelectionTypes["auto-detect"]);
            else if (res?.input_type === "article")
              setSelectedInputType(inputSelectionTypes["article"]);

            setPipelineParams(true);
          } else {
            setError({
              name: "Error",
              message: t("Pipeline link is invalid"),
            });
            setHasError(true);
            setLoader(false);
          }
        });
      } else {
        console.debug(
          "[PipelinePage] No studio key while trying to generate pipeline",
        );
      }
    }
    // eslint-disable-next-line
  }, [location, value?.studio_key]);

  useEffect(() => {
    let searchResults: Array<string> = [];
    const handleSearch = async () => {
      let arrayToSearch = pipelineDNDColumnsOriginal?.library?.items;

      const keywordsArray = arrayToSearch?.filter(
        (skillsIncludingKeywords: any) => {
          return skillsIncludingKeywords.keywords?.some((keyword: any) =>
            keyword.includes(search.toLowerCase()),
          );
        },
      );
      const parsedSkillNames = keywordsArray.map((skill: any) => skill.name);
      const filteredColumn = arrayToSearch?.filter((libraryItem: any) =>
        parsedSkillNames.includes(libraryItem.name),
      );

      setPipelineDNDColumns({
        pipeline: pipelineDNDColumns.pipeline,
        library: { ...pipelineDNDColumns.library, items: filteredColumn },
      });

      searchResults = filteredColumn.map((item: any) => item.name);
    };
    const delaySearch = setTimeout(() => {
      if (search !== "")
        userEventLogger(UserEvent.SEARCH, {
          search_word: search,
          found: searchResults.length ? true : false,
          result: searchResults,
          value: search,
        });
    }, 700);
    handleSearch();
    return () => clearTimeout(delaySearch);
    // eslint-disable-next-line
  }, [search]);

  const getParameters = (step: any) => {
    let params = {};
    if (step.name === "Summarize" && step?.params) {
      if (step?.params?.auto_length) {
        params = {
          find_origins: step?.params?.find_origins, // we need this in order to show the Summary Origin UI
        };
      } else {
        if (step?.params["auto_length"]) {
          delete step?.params["auto_length"];
        }
        params = step?.params;
      }
      return params;
    } else {
      return step?.params;
    }
  };
  const convertToPipelineSteps = useCallback((): Array<PipelineStep> => {
    return pipelineDNDColumns?.pipeline?.items.map((step: any) => {
      return {
        skill: step.value,
        params: getParameters(step),
      };
    });
  }, [pipelineDNDColumns.pipeline]);

  const hasEmptyInputSkill = useCallback((): any => {
    return pipelineDNDColumns?.pipeline?.items.some(
      (step: any) => step?.params?.input_skill === "",
    );
  }, [pipelineDNDColumns.pipeline]);

  const alerts = useMemo(
    () => [
      {
        message: "Please add input text",
        showCondition: !textarea?.length,
      },
      {
        message: "Please add skills to pipeline",
        showCondition: !pipelineDNDColumns.pipeline.items?.length,
      },
      {
        message: "Please choose clustering skill",
        showCondition: hasEmptyInputSkill(),
      },
    ],
    //eslint-disable-next-line
    [textarea, pipelineDNDColumns.pipeline],
  );

  const requestBuilder = useCallback((): {
    input: string;
    input_type: any;
    steps: any;
    include_intermediates: string;
  } => {
    const steps = convertToPipelineSteps();
    const input_type = selectedInputType?.name.toLowerCase();
    const include_intermediates = "true";

    const hasPipelineErrors = alerts.some((alert: any) => alert.showCondition);
    setHasPipelineErrors(hasPipelineErrors);

    let formattedText = textarea;

    if (
      selectedInputType === inputSelectionTypes["conversation"] &&
      !Object.keys(parseConversation(formattedText)).includes("error")
    ) {
      if (!cubeFile && !inputResponse) {
        formattedText = JSON.stringify(parseConversation(formattedText));
      }
    }

    let obj = {
      input: !textarea
        ? "<YOUR INPUT TEXT>"
        : decodeHTMLEntities(formattedText),
      input_type,
      steps,
      include_intermediates,
    };

    return obj;
    //eslint-disable-next-line
  }, [textarea, selectedInputType, convertToPipelineSteps, alerts, loading]);

  useEffect(() => {
    sharePipelineId.current = randomString(6);
  }, [requestBuilder]);

  const setOriginUI = useCallback(
    (outputIndex: number) => {
      const labels = outputData?.current[outputIndex]?.labels;
      if (labels?.some((label: any) => label.skill === "origin")) {
        setSummaryOrigins(true);
      } else {
        setSummaryOrigins(false);
      }

      if (summaryOrigins) {
        setTextarea(originalText.current);
      }

      if (
        outputData?.current[outputIndex]?.text_generated_by_step_name ===
          "summarize" ||
        outputData?.current[outputIndex]?.text_generated_by_step_name ===
          "summarize-transcript"
      ) {
        if (
          originalText.current === textarea &&
          !hasWritingSkillBefore(outputIndex, requestBuilder, contentFile)
        ) {
          setTextarea(
            highlightRange(textarea, outputData?.current[outputIndex]?.labels),
          );
        }
      } else {
        console.log("I am here with ", outputData?.current[outputIndex]);
        if (cubeFile && inputResponse) {
          setTextarea(outputData?.current[outputIndex]?.text);
        } else {
          setTextarea(originalText.current);
        }
      }
    },
    //eslint-disable-next-line
    [requestBuilder, textarea, setTextarea, summaryOrigins],
  );

  const handleSetData = useCallback(
    (outputIndex: any = 0) => {
      outputIndexChosen.current = outputIndex;
      setLabels(outputData?.current[outputIndex]?.labels ?? []);
      setOriginLabels(outputData?.current[outputIndex]?.labels ?? []);
      setText(outputData?.current[outputIndex]?.text ?? "");
      setOriginUI(outputIndex);
      setLoader(false);
    },
    //eslint-disable-next-line
    [setOriginUI, originLabels],
  );
  const DragAndDropMemo = useMemo(
    () => <DragAndDrop loader={loader} setSearch={setSearch} search={search} />,
    [loader, search],
  );

  const OutputMemo = useMemo(
    () => (
      <Output
        loader={loader}
        treeData={outputData?.current}
        text={text}
        labels={labels}
        originLabels={originLabels}
        setLabels={setLabels}
        apiResponse={apiResponse.current}
        outputIndexChosen={outputIndexChosen.current}
        hideOrigin={
          hasWritingSkillBefore(
            outputIndexChosen.current,
            requestBuilder,
            contentFile,
          ) || !summaryOrigins
        }
      >
        Some code
      </Output>
    ),
    [
      labels,
      originLabels,
      requestBuilder,
      summaryOrigins,
      text,
      outputIndexChosen,
      loader,
    ],
  );

  const SelectSampleMemo = useMemo(() => <InputTabs />, []);
  // React dropZone

  const InputMemo = useMemo(
    () => <Input outputLength={outputData?.current?.length} />,
    //eslint-disable-next-line
    [
      requestBuilder,
      selectedInputType,
      summaryOrigins,
      textarea,
      handleSetData,
    ],
  );

  const OutputDataMemo = useMemo(
    () => (
      <OutputData
        outputData={outputData}
        outputIndexChosen={outputIndexChosen}
        handleSetData={handleSetData}
        loader={loader}
      />
    ),
    [handleSetData, outputData, outputIndexChosen, loader],
  );

  const columnsRenderStrategy = [
    {
      id: "left",
      title: SelectSampleMemo,
      content: InputMemo,
      description: "1. Language input",
    },
    {
      id: "middle",
      content: DragAndDropMemo,
      description: "2. Configure Request",
    },
    {
      id: "right",
      description: "3. Examine Response",
      title: !!outputData.current?.length ? (
        OutputDataMemo
      ) : (
        <div className="flex flex-row space-x-2 h-full rounded-lg gap-x-1">
          <div
            className={`flex text-lg font-bold px-9 transition-all duration-200 items-center justify-center`}
          >
            <div className="flex flex-col text-center font-poppins">
              <div className={`text-xs md:text-xs`}>&nbsp;</div>
              <div className={`text-sm font-bold `}>&nbsp;</div>
            </div>
          </div>
        </div>
      ),
      content: OutputMemo,
    },
  ];

  const SharePipelineButton = () => {
    const onClose = () => {
      setTimeout(() => {
        setClickedSharePipeline(false);
      }, 100);
    };

    const handleSharePipeline = async () => {
      if (!hasPipelineErrors && !sharePipelineButtonLoading) {
        const { input_type, steps } = requestBuilder();
        setSharePipelineButtonLoading(true);

        const result = await addPipeline(
          {
            input: summaryOrigins ? originalText.current : textarea,
            pipeline: pipelineDNDColumns.pipeline,
            steps,
            input_type,
          },
          sharePipelineId.current || "",
          cubeFile,
        );
        if (result.success) {
          setSharePipelineMessage("Pipeline link has been copied to clipboard");
          userEventLogger(UserEvent.SHARE_BUTTON, {
            value: result?.link,
          });
        } else {
          setSharePipelineMessage(
            "Error: could not generate pipeline link, please try again later!",
          );
        }

        setClickedSharePipeline(true);
        setSharePipelineButtonLoading(false);
      }
    };

    return (
      <AppTooltip
        title={
          hasPipelineErrors ? (
            <>
              {alerts
                .filter((alerts) => alerts.showCondition)
                .map((alert: any, index: number) => (
                  <div
                    key={index}
                    className="grid grid-cols-auto-1fr gap-x-3 items-center"
                  >
                    <IconTooltip className={"text-blue"} />
                    {alert.message}
                  </div>
                ))}
            </>
          ) : clickedSharePipeline ? (
            <div className="grid grid-cols-auto-1fr gap-x-3 items-center">
              <IconTooltip className={"text-blue"} />
              <span>{t(sharePipelineMessage)}</span>
            </div>
          ) : (
            ""
          )
        }
        onClose={onClose}
        arrow={!hasPipelineErrors}
        leaveDelay={0}
      >
        <button
          onClick={handleSharePipeline}
          className={`cursor-pointer share-button text-center transform-none  m-0 items-center py-[0.6rem] px-5 whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight rounded-md border-0 shadow-xl dark:shadow-none ${
            hasPipelineErrors
              ? "dark:bg-charadea dark:text-topaz bg-linkWater text-pigeonPost"
              : "bg-blue text-white  hover:bg-opacity-80"
          }`}
        >
          {t("Share Pipeline")}

          {sharePipelineButtonLoading ? (
            <Box>
              <CircularProgress
                style={{
                  width: 19,
                  height: 15,
                  color: "white",
                  padding: 0,
                  margin: 0,
                }}
              />
            </Box>
          ) : (
            <IconSharePipeline
              width="19"
              height="19"
              className={` ${
                i18n.dir() === "rtl" ? "transform rotate-180" : ""
              } ${
                hasPipelineErrors
                  ? "text-pigeonPost dark:text-topaz"
                  : "text-white"
              }`}
            />
          )}
        </button>
      </AppTooltip>
    );
  };

  useEffect(() => {
    if (shouldRunPipeline) {
      setLoader(true);
      if (!anonymousLogin) {
        runPipeline();
        setShouldRunPipeline(false);
      }
    }
    //eslint-disable-next-line
  }, [anonymousLogin, shouldRunPipeline]);

  const RunPipelineButton = () => {
    const handleRunPipeline = () => {
      if (!hasPipelineErrors) {
        if (!anonymousLogin) runPipeline();
        else setShouldRunPipeline(true);
      }
    };

    return (
      <AppTooltip
        arrow={!hasPipelineErrors}
        title={
          hasPipelineErrors ? (
            <>
              {alerts
                .filter((alerts) => alerts.showCondition)
                .map((alert: any, index: number) => (
                  <div
                    key={index}
                    className="grid grid-cols-auto-1fr gap-x-3 items-center"
                  >
                    <IconTooltip className={"text-blue"} />
                    {alert.message}
                  </div>
                ))}
            </>
          ) : (
            "" // We don't want to show the tooltip if the system message is empty
          )
        }
      >
        <button
          onClick={handleRunPipeline}
          className={`cursor-pointer text-center transform-none  m-0 items-center py-[0.6rem] px-5  whitespace-nowrap text-xs md:text-xs grid grid-cols-1fr-auto gap-x-2 font-mono font-bold tracking-tight  ${
            hasPipelineErrors
              ? "dark:bg-charadea dark:text-topaz bg-linkWater text-pigeonPost"
              : "bg-turbo text-verdunGreen hover:bg-opacity-80"
          } rounded-md shadow-xl dark:shadow-none`}
          ref={runPipelineRef}
          data-cy="pipeline-button" // use cy.contains("button", "Run Pipeline").click();
        >
          {t("Run Pipeline")}
          <figure>
            <IconRunPipeline
              width="19"
              height="19"
              className={` ${i18n.dir() === "rtl" ? "transform rotate-180" : ""}
              ${hasPipelineErrors ? "text-pigeonPost" : "text-verdunGreen"}
              ${
                hasPipelineErrors
                  ? "text-pigeonPost dark:text-topaz"
                  : "text-white"
              }`}
            />
          </figure>
        </button>
      </AppTooltip>
    );
  };
  const headerRightRender = useCallback(() => {
    return (
      <div className={`flex justify-center items-center gap-x-4 md:py-0 `}>
        <RunPipelineButton />
        <SharePipelineButton />
      </div>
    );
    // eslint-disable-next-line
  }, [
    loader,
    i18n,
    t,
    requestBuilder,
    textarea,
    hasPipelineErrors,
    sharePipelineMessage,
    clickedSharePipeline,
    sharePipelineButtonLoading,
    location,
  ]);

  return (
    <>
      <ErrorPopup
        isOpen={hasError}
        setIsOpen={setHasError}
        title={error?.name}
        text={error?.message}
        requestId={""} // Not needed - we don't use this file
      />

      <Layout headerRightRender={headerRightRender()} loader={loader}>
        <div className="columns flex flex-col md:grid rounded-md bg-catskill dark:bg-darkBackground gap-x-2">
          {columnsRenderStrategy.map((column: any, index: number) => (
            <div
              className={`column rounded-md ${
                index === 0 ? "min-w-[300px] lg:min-w-[440px]" : "min-w-[230px]"
              } `}
              key={column.id}
            >
              {column.title && (
                <div
                  className={`column-header dark:text-white rounded-md ${
                    index === 2 ? "h-[61px]" : "h-[53px]"
                  } `}
                >
                  {column.title}
                </div>
              )}
              <div
                className={`column-body ${
                  i18n.dir() === "ltr" ? "rounded-tr-md" : "rounded-tl-md"
                }`}
              >
                {column.content}
              </div>
            </div>
          ))}
        </div>
      </Layout>
    </>
  );
};
export default PipelinePage;
