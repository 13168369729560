// Render Prop
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { atom, useRecoilState } from "recoil";
import { IconX } from "../../assets/SvgIcons";
import AddKeyPopver from "../../components/add-key-popover";
import { EditKeyPopver } from "../../components/ApiKeys/popover";
import {
  useMutateOrganizationKey,
  useUserOrganizationKeys,
} from "../../components/auth/data";
import GetFullKeyPopover from "../../components/get-full-key";
import Loader from "../../components/Loader";
import AppTooltip from "../../components/tooltip";
import { useEventLogger } from "../../lib/event_logger";
import { APIKey } from "../../lib/interfaces";
import { skillValueToName } from "../PipelinePage/utils";

export default function APIKeys() {
  const { t } = useTranslation("api-keys");

  const [loading] = React.useState(false);
  return (
    <React.Fragment>
      <div className="px-3 dark:text-white text-black">
        <div className="grid">
          <div className="container grid grid-cols-auto-1fr items-center pb-0">
            <h1
              className={`items-center md:visible font-poppins font-bold dark:text-white text-black`}
            >
              {t("API Keys")}
            </h1>
            <div className="grid justify-end ">
              <AddKeyPopver buttonText={t("Add Key")} />
            </div>
          </div>
        </div>

        <p className="font-poppins font-normal">
          {t("Create API keys and use them in your applications")}
        </p>
      </div>
      <div className="py-4">
        <div className="md:h-[calc(100vh-300px)] overflow-x-auto overflow-y-auto">
          <KeyList isLoading={loading} />
        </div>
      </div>
    </React.Fragment>
  );
}

const showCreateKeyButtonAtom = atom({
  key: "showCreateKeyButtonAtom",
  default: false,
});

const KeyList = ({ isLoading }: any) => {
  const { t } = useTranslation("api-keys");
  const [showCreateKeyButton, setShowCreateKeyButton] = useRecoilState(
    showCreateKeyButtonAtom,
  );
  const { api_keys, loading } = useUserOrganizationKeys();

  useEffect(() => {
    if (api_keys?.length > 0) {
      setShowCreateKeyButton(false);
    }
    // eslint-disable-next-line
  }, []);
  if (loading || isLoading) {
    return <Loader loader={true} />;
  }

  if (api_keys.length === 0 || showCreateKeyButton)
    return (
      <div className="grid md:mt-20 overflow-y-auto">
        <div className="grid min-w-full sm:px-6 lg:px-8 justify-center items-center mt-4">
          <AddKeyPopver buttonText={t("Create your first key!")} />
        </div>
      </div>
    );
  return (
    <>
      <div className="grid">
        <div className="">
          <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div className="w-full">
              <table className="w-full" data-cy="api-keys-table">
                <thead className="border-b border-tableHeaderBorder dark:border-tableHeaderBorderDark dark:text-white text-black">
                  <tr>
                    <th
                      scope="col"
                      className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left"
                    >
                      {t("Nickname")}
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left"
                    >
                      {t("Daily Quota")}
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left"
                    >
                      {t("Skills")}
                    </th>
                    <th
                      scope="col"
                      className="text-sm font-bold font-poppins whitespace-nowrap text-tableGrayHeader px-6 py-4 text-left"
                    >
                      {t("Key")}
                    </th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {api_keys.map((apiKey: APIKey) => (
                    <React.Fragment key={apiKey.token_id}>
                      <TableRow apiKey={apiKey} />
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const TableRow = ({ apiKey }: { apiKey: APIKey }) => {
  const { revokeAPIKey, activateAPIKey, loading } =
    useMutateOrganizationKey(apiKey);
  const { t } = useTranslation();
  const { userUIEventLogger } = useEventLogger();

  if (loading) {
    return (
      <tr>
        <td>
          <Loader loader={true} />
        </td>
      </tr>
    );
  }

  const isKeyActive = apiKey.state === "ACTIVE";
  const daily_quota = apiKey.daily_quota === -1 ? "" : apiKey.daily_quota;

  return (
    <tr className="border-b border-tableBodyBorder dark:border-darkGrayBorder dark:text-white">
      <td className="px-6 py-4 font-poppins whitespace-nowrap text-sm font-bold">
        {apiKey.name}
      </td>
      <td className="px-6 py-4 font-poppins whitespace-nowrap text-sm font-bold">
        {daily_quota}
      </td>
      <td className="text-sm font-poppins whitespace-nowrap font-light px-6 py-4 ">
        <div
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            width: "100%",
          }}
        >
          {apiKey.allowed_skills
            .map((skillValue) => skillValueToName(skillValue))
            .join(", ")}
        </div>
      </td>
      <td className="text-sm font-poppins whitespace-nowrap font-light px-6 py-4 ">
        {apiKey?.secret_head}
      </td>
      <td className="font-poppins whitespace-nowrap font-light px-6 py-4 h-full">
        <div className="grid grid-cols-3-auto gap-x-2 w-fit-content">
          <EditKeyPopver initialValues={apiKey} />

          <GetFullKeyPopover
            tokenId={apiKey?.token_id}
            orgId={apiKey?.account_id}
          />
          <AppTooltip
            title={t<string>(isKeyActive ? t("Revoke Key") : t("Activate Key"))}
          >
            <button
              className={`cursor-pointer w-fit-content rounded-md ${
                isKeyActive ? "p-2" : "bg-blue px-2"
              }`}
              onClick={() => {
                isKeyActive ? revokeAPIKey() : activateAPIKey();
                userUIEventLogger(
                  `settings__api_keys__${
                    isKeyActive ? "revoke_key" : "activate_key"
                  }`,
                );
              }}
            >
              {isKeyActive ? (
                <IconX width="15" height="15" />
              ) : (
                <span className="text-xs">{t("Activate Key")}</span>
              )}
            </button>
          </AppTooltip>
        </div>
      </td>
    </tr>
  );
};
