import { useRecoilValue } from "recoil";
import {
  parseAcademyTextArea,
  shouldParseAcademy,
} from "../pages/PipelinePage/utils";
import { inputTypeAtom, textAreaAtom } from "./atoms";
import { pipelineSkillsAtom } from "../pages/pipeline/pipeline-atoms";
import { toLower } from "lodash";

export default function useAcademicSkill() {
  const textArea = useRecoilValue(textAreaAtom);
  const parseAcademic = parseAcademyTextArea(textArea);
  const pipelineSkills = useRecoilValue(pipelineSkillsAtom);
  const inputType = useRecoilValue(inputTypeAtom);
  const isAcademic = shouldParseAcademy(
    pipelineSkills.steps,
    toLower(inputType.name),
  );
  const academicLength = parseAcademic.length;
  return { parseAcademic, isAcademic, academicLength };
}
