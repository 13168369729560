import React from "react";
import { useTranslation } from "react-i18next";
import useElementSize from "../lib/use-element-size-debounce";
import customer_json from "../pages/analytics/clustering-demos/customer_service_demo.json";
import Clustering from "./clustering-global";

export default function ClusteringDemo() {
  const { t } = useTranslation("index");
  const [formatError, setFormatError] = React.useState("");
  const [json, setJSON] = React.useState("");
  const [textArea, setTextArea] = React.useState(
    JSON.stringify(customer_json, null, 2),
  );
  const [ref, { width, height }] = useElementSize();

  if (!json)
    return (
      <div className="bg-white dark:bg-darkGrayBackground p-3 md:px-4 md:pt-6 md:pb-6 rounded-md h-400 md:h-[calc(100vh-135px)] setting-page-sidebar grid justify-center items-center">
        <div className="grid gap-y-2">
          <h1 className="text-center font-bold font-poppins dark:text-white text-black">
            {t("Clustering demo tool")}
          </h1>
          <p className="text-center dark:text-white text-black font-poppins">
            {t("Please enter JSON:")}
          </p>
          <textarea
            onChange={(e: any) => setTextArea(e.target.value)}
            value={textArea}
            rows={20}
            cols={60}
            className="rounded-md bg-white dark:bg-darkGrayBackground dark:text-white text-black border dark:border-white border-black p-2"
          />
          <button
            onClick={() => {
              setFormatError("");
              try {
                const parsedObject = JSON.parse(textArea);

                if (parsedObject) {
                  setJSON(parsedObject);
                  if (parsedObject.length) {
                  } else {
                    setFormatError("Error! bad JSON format");
                  }
                } else {
                  setFormatError("Error! bad JSON format");
                }
              } catch (e) {
                setFormatError("Error! bad JSON format");
              }
            }}
            className={`bg-shadeBlue disabled:opacity-70 font-mono rounded-md text-sm text-white outline-none  p-4 `}
          >
            {t("Run clustering")}
          </button>
          <div className="error">
            {formatError && (
              <p className="text-center text-red-600 font-poppins text-red">
                {formatError}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  else {
    return (
      <>
        <div className="bg-white dark:bg-darkGrayBackground rounded-md justify-center items-center overflow-x-auto">
          <div
            ref={ref}
            className="overflow-hidden md:h-[calc(100vh-110px)] overflow-y-auto w-full grid justify-center"
          >
            <Clustering
              width={width}
              height={height - 92}
              json={json}
              rightButton={
                <button
                  className="grid border dark:border-white border-shadeBlue rounded-md items-center grid-cols-1fr-auto px-3 hover:bg-opacity-20 hover:bg-black overflow-hidden"
                  onClick={() => setJSON?.("")}
                >
                  <span className="dark:text-white text-shadeBlue grid items-center text-center m-0 font-mono  py-1">
                    {t("New clustering demo")}
                  </span>
                </button>
              }
            />
          </div>
        </div>
      </>
    );
  }
}
