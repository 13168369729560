import { Checkbox, FormControlLabel, SvgIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { IconSquareCheck, IconV } from "../../../assets/SvgIcons";
import Button from "../../../components/button";
import {
  detectedCheckedAtom,
  inputTypeAtom,
  textAreaAtom,
} from "../../../lib/atoms";
import { useEventLogger, UserEvent } from "../../../lib/event_logger";
import { detectInputType, inputSelectionTypes } from "../utils";

const InputTypeSelection = () => {
  const { userEventLogger } = useEventLogger();
  const textArea = useRecoilValue(textAreaAtom);
  const setSelectedInputType = useSetRecoilState(inputTypeAtom);
  const [detectedChecked, setDetectedChecked] =
    useRecoilState(detectedCheckedAtom);
  const { t } = useTranslation("index");

  const handleToggle = (e: any) => {
    if (e.target.checked) {
      sendEvents("Detect");
      setSelectedInputType(detectInputType(textArea));
      setDetectedChecked(true);
    } else {
      setDetectedChecked(false);
    }
  };

  function sendEvents(name: string) {
    userEventLogger(UserEvent.INPUT_TYPE_SELECTION, {
      value: name,
    });
  }

  const checkbox = (
    <FormControlLabel
      className="grid items-center !m-0 justify-center"
      control={
        <Checkbox
          checked={detectedChecked}
          disableRipple
          icon={
            <SvgIcon
              children={<IconSquareCheck className="text-grayButtons" />}
            />
          }
          checkedIcon={
            <SvgIcon children={<IconV className="text-shadeBlue" />} />
          }
          onClick={handleToggle}
        />
      }
      label={
        <span className="dark:text-white font-medium dark:font-light !text-sm select-none">
          {t("Detect")}
        </span>
      }
    />
  );

  return (
    <div
      className={`grid grid-cols-auto-1fr gap-x-3 md:gap-x-8 px-4 items-center py-2`}
    >
      <div className="md:order-2 mt-1">{checkbox}</div>
      <div className="border border-[#3d3a4f] rounded-[3px] p-0 m-0 w-fit h-fit">
        <DocumentPressable />
        <DialogPressable />
      </div>
    </div>
  );
};

function DialogPressable() {
  const [selectedInputType, setSelectedInputType] =
    useRecoilState(inputTypeAtom);
  const { userEventLogger } = useEventLogger();
  const setDetectChecked = useSetRecoilState(detectedCheckedAtom);

  const handleInputTypeClick = () => {
    setSelectedInputType(inputSelectionTypes.conversation);
    setDetectChecked(false);
    userEventLogger(UserEvent.INPUT_TYPE_SELECTION, {
      value: "Dialog",
    });
  };

  return (
    <Button
      ariaLabel="Dialog"
      disableStyles
      onClick={handleInputTypeClick}
      className={`w-fit h-fit py-1 rounded-r-[3px] ${
        selectedInputType === inputSelectionTypes.conversation
          ? "bg-blue text-white"
          : "text-numberGray"
      }`}
    >
      <span className="font-poppins px-3">Dialog</span>
    </Button>
  );
}

function DocumentPressable() {
  const [selectedInputType, setSelectedInputType] =
    useRecoilState(inputTypeAtom);
  const { userEventLogger } = useEventLogger();
  const setDetectChecked = useSetRecoilState(detectedCheckedAtom);

  const handleInputTypeClick = () => {
    setSelectedInputType(inputSelectionTypes.article);
    setDetectChecked(false);
    userEventLogger(UserEvent.INPUT_TYPE_SELECTION, {
      value: "Document",
    });
  };

  return (
    <Button
      ariaLabel="Dialog"
      disableStyles
      className={`w-fit h-fit py-1 rounded-l-[3px] ${
        selectedInputType === inputSelectionTypes.article
          ? "bg-blue text-white"
          : "text-numberGray"
      }`}
      onClick={handleInputTypeClick}
    >
      <span className="font-poppins px-3">Document</span>
    </Button>
  );
}
export default InputTypeSelection;
