import { ClickAwayListener } from "@mui/material";
import React from "react";
import { CodeBlock, dracula } from "react-code-blocks";
import { useRecoilValue } from "recoil";
import { IconThumbsDown, IconThumbsUp } from "../assets/SvgIcons";
import { useEventLogger, UserEvent } from "../lib/event_logger";
import { ThumbsType } from "../lib/interfaces";
import { useSharePipeline } from "../lib/use-share-pipeline";
import {
  requestIdHeader,
  sharedPipelineIDAtom,
} from "../pages/pipeline/pipeline-atoms";
import theme from "../theme";
import Button from "./button";

type ThumbsRectangleProps = {
  setPopup: (value: boolean) => void;
  setThumb: (value: ThumbsType) => void;
  thumb: ThumbsType;
  feedbackText: string;
  setFeedbackText: (e: string) => void;
  data: any;
  labelOriginal: any;
};

export default function ThumbsRectangle({
  setPopup,
  setThumb,
  thumb,
  feedbackText,
  setFeedbackText,
  data,
  labelOriginal,
}: ThumbsRectangleProps) {
  const focusRef = React.useRef<any>(null);
  const { userEventLogger } = useEventLogger();
  const [detectedClick, setDetectedClick] = React.useState<boolean>(false);
  const requestID = useRecoilValue(requestIdHeader);
  const pipelineID = useRecoilValue(sharedPipelineIDAtom);
  const { sharePipeline } = useSharePipeline();

  const setThumbsUp = () => {
    setFeedbackText("");
    setThumb("THUMBS_UP");
    setDetectedClick(true);
    setTimeout(() => {
      setPopup(false);
    }, 500);
  };

  React.useEffect(() => {
    if (detectedClick) {
      fireEvent();
      setDetectedClick(false);
    }
    //eslint-disable-next-line
  }, [detectedClick]);

  const setThumbsDown = () => {
    setThumb("THUMBS_DOWN");
  };

  const closePopup = () => {
    setPopup(false);
  };

  const isThumbsDown = thumb === "THUMBS_DOWN";
  const isThumbsUp = thumb === "THUMBS_UP";

  React.useEffect(() => {
    // Accessibillity fix for the thumbs up/down buttons
    if (focusRef) {
      focusRef.current.focus();
    }
  }, [focusRef]);

  const fireEvent = async () => {
    const rating = thumb === "THUMBS_UP" ? "UP" : "DOWN";

    await sharePipeline(false);
    navigator.clipboard.writeText("");

    userEventLogger(UserEvent.FEEDBACK, {
      data: {
        thumbs: rating,
        pipeline_id: pipelineID,
        skill: data?.label,
        label: data?.value,
        message: feedbackText,
        request_id: requestID,
      },
    });
  };

  const shouldShowValueOrJSON =
    labelOriginal?.span_text !== labelOriginal?.value ||
    (data?.data && Object.keys(data?.data)?.length);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setPopup(false);
      }}
    >
      <div
        className={`bg-modalDark grid grid-flow-row rounded-md w-fit
      ${shouldShowValueOrJSON ? "p-2" : ""}
      `}
      >
        <div className="grid grid-cols-auto-1fr items-start p-1 rounded-md">
          <div className="grid grid-rows-1">
            <DataPresent data={data} labelOriginal={labelOriginal} />
            <div className="grid grid-cols-auto-1fr items-center">
              <div className="grid grid-cols-auto-1fr gap-x-1 focus-within:underline">
                <ButtonThumbsUp
                  focusRef={focusRef}
                  setThumbsUp={setThumbsUp}
                  isThumbsUp={isThumbsUp}
                />
                <ButtonThumbsDown
                  setThumbsDown={setThumbsDown}
                  isThumbsDown={isThumbsDown}
                />
              </div>
              {isThumbsDown && <ThumbsDownText />}
            </div>
          </div>
        </div>
        {isThumbsDown && (
          <ThumbsDownInputAndButton
            closePopup={closePopup}
            feedbackText={feedbackText}
            setFeedbackText={setFeedbackText}
            fireEvent={fireEvent}
          />
        )}
      </div>
    </ClickAwayListener>
  );
}

function DataPresent({ data, labelOriginal }: any) {
  return (
    <div className="max-h-[200px] overflow-auto cursor-text">
      {data?.value && (
        <div>
          {labelOriginal?.span_text !== labelOriginal?.value ? (
            <div className="text-white p-0 !mb-2 text-sm">
              {labelOriginal?.value}
            </div>
          ) : data?.data && Object.keys(data?.data).length ? (
            <div className="text-white p-0 !mb-2 text-sm">
              {labelOriginal?.value}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {data?.data && Object.keys(data?.data).length ? (
        <div className=" min-w-[200px] ">
          <CodeBlock
            className="tooltip-labels-buckets"
            showLineNumbers={false}
            language={"json"}
            text={JSON.stringify(data?.data || "", null, 4)}
            theme={{
              ...dracula,
              keywordColor: "#9CDCFE",
              backgroundColor: theme.colors.modalDark,
              variableColor: "#9CDCFE",
              nameColor: "#9CDCFE",
              codeColor: "#9CDCFE",
              textColor: "#9CDCFE",
              stringColor: "#CE9178",
              numberColor: "#B5CEA8",
            }}
            style={{
              fontFamily: theme.fontFamily.monospace,
              fontSize: theme.fontSize.xs,
            }}
          />
          <style>{`code {padding-right: 0 !important; padding-left: 0 !important; padding-top: 0 !important;}`}</style>
        </div>
      ) : null}
    </div>
  );
}
function ButtonThumbsDown({
  setThumbsDown,
  isThumbsDown,
}: {
  setThumbsDown: () => void;
  isThumbsDown: boolean;
}) {
  return (
    <button
      className={`outline-none rounded-sm transition-colors hover:bg-[#292971] duraiton-300	${
        isThumbsDown ? "bg-shadeBlue" : "bg-[#22222D]"
      } ${isThumbsDown ? "text-white" : "text-shadeBlue"}`}
      title="Thumbs Down"
      aria-label="Thumbs Down"
      onClick={() => {
        setThumbsDown();
      }}
    >
      <IconThumbsDown />
    </button>
  );
}

function ButtonThumbsUp({
  setThumbsUp,
  isThumbsUp,
  focusRef,
}: {
  setThumbsUp: () => void;
  isThumbsUp: boolean;
  focusRef: any;
}) {
  return (
    <button
      className={`outline-none rounded-sm transition-colors hover:bg-[#292971] duraiton-300	${
        isThumbsUp ? "bg-shadeBlue" : "bg-[#22222D]"
      } ${isThumbsUp ? "text-white" : "text-shadeBlue"}`}
      title="thumbs up"
      aria-label="Thumbs Up"
      tabIndex={0}
      onClick={() => {
        setThumbsUp();
      }}
      ref={focusRef}
    >
      <IconThumbsUp />
    </button>
  );
}

function ThumbsDownInputAndButton({
  closePopup,
  feedbackText,
  setFeedbackText,
  fireEvent,
}: {
  closePopup: () => void;
  feedbackText: string;
  setFeedbackText: (value: string) => void;
  fireEvent: () => void;
}) {
  return (
    <div className="grid grid-cols-1fr-auto w-full p-1 gap-x-2 items-end">
      <InputTextArea
        feedbackText={feedbackText}
        setFeedbackText={setFeedbackText}
      />
      <Button
        ariaLabel="Send"
        onClick={(e: any) => {
          e.preventDefault();
          e.stopPropagation();
          fireEvent();
          closePopup();
        }}
      >
        <span className="px-4">Send</span>
      </Button>
    </div>
  );
}

function InputTextArea({
  feedbackText,
  setFeedbackText,
}: {
  feedbackText: string;
  setFeedbackText: (e: string) => void;
}) {
  return (
    <textarea
      className="bg-modalDark font-poppins outline-none text-sm border-b border-blue resize-none p-2"
      rows={1}
      value={feedbackText}
      onChange={(e: any) => {
        setFeedbackText(e?.target?.value);
      }}
      placeholder="Tell how we could improve this"
    />
  );
}

function ThumbsDownText() {
  return (
    <div className="bg-modalDark">
      <div className="grid items-start md:min-w-[220px]">
        <p className="text-sm pl-6 pt-2 w-[80%]">
          Thanks, what didn’t work for you?
        </p>
      </div>
    </div>
  );
}
