import { partition } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEventLogger, UserEvent } from "../../../../lib/event_logger";
import theme from "../../../../theme";
import { LabelType } from "../../types";
import { skillValueToName } from "../../utils";
import styles from "./styles.module.css";

const getUniqueListBy = (arr: any, key: any) => {
  // @ts-ignore
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

type CheckboxesProps = {
  originLabels: LabelType[];
  labels: LabelType[];
  setLabels: (labels: LabelType[]) => void;
};
const Checkboxes = ({ originLabels, labels, setLabels }: CheckboxesProps) => {
  const [checkedState, setCheckedState]: any[] = useState([]);
  const [unCheckedLabels, setUnCheckedLabels]: any[] = useState([]);
  const [checkboxes, setCheckboxes]: any[] = useState([]);

  const { t } = useTranslation("index");
  const { userEventLogger } = useEventLogger();

  useEffect(() => {
    setUnCheckedLabels([]);
    const topics = originLabels.filter(
      (label: LabelType) => label.skill === "article-topics",
    );
    let nonTopics = originLabels.filter(
      (label: LabelType) => label.skill !== "article-topics",
    );
    nonTopics = nonTopics.filter(
      (label: LabelType) => label.skill !== "origin",
    );
    nonTopics = nonTopics.filter((label: LabelType) => {
      return label.skill !== "origin" && label.skill !== "clustering";
    });

    const uniqLabels = getUniqueListBy(nonTopics, "skill").concat(
      getUniqueListBy(topics, "value"),
    );
    const state = new Array(uniqLabels.length).fill(true);
    setCheckedState(state);
    setCheckboxes(uniqLabels);
  }, [originLabels]);

  const partitionLabelsByType = (labels: any, type: any) => {
    return partition(labels, (label: any) => label.skill === type);
  };

  const handleOnChange = (
    position: number,
    labelType: string,
    isChecked: boolean,
  ) => {
    const updatedCheckedState = checkedState.map(
      (item: boolean, index: number) => (index === position ? !item : item),
    );
    setCheckedState(updatedCheckedState);

    if (isChecked) {
      userEventLogger(UserEvent.LABEL_VISIBILITY_TOGGLE_OFF, {
        value: labelType,
      });
    } else {
      userEventLogger(UserEvent.LABEL_VISIBILITY_TOGGLE_ON, {
        value: labelType,
      });
    }
    handleHighlightLabels(labelType, isChecked);
  };

  const handleHighlightLabels = (type: string, isChecked: boolean) => {
    const [filteredOrigin, otherOrigin] = partitionLabelsByType(labels, type);
    const [filteredUnChecked, otherUnChecked] = partitionLabelsByType(
      unCheckedLabels,
      type,
    );

    if (isChecked) {
      setUnCheckedLabels([...unCheckedLabels, ...filteredOrigin]);
      setLabels(otherOrigin);
    } else {
      setUnCheckedLabels(otherUnChecked);
      setLabels([...filteredUnChecked, ...labels]);
    }
  };

  // This will return the correct label color for the specific lable type.
  const getLabelTypeColors = (labelTypeName: string) => {
    let background = theme.colors.pink;
    let color = "white";

    switch (labelTypeName) {
      case "highlights":
        background = theme.colors.cyan;
        break;
      case "entities":
        background = theme.colors.rose;
        break;
      case "enhance":
        background = theme.colors.lightgrey;
        color = theme.colors.darkgrey;
        break;
      case "anonymize":
        background = theme.colors.lightgrey;
        color = theme.colors.darkgrey;
        break;
      case "keywords":
        background = theme.colors.purple;
        break;
      case "emotions":
        background = theme.colors.pink;
        break;
      case "sentences":
        background = theme.colors.orange;
        break;
      case "article-topics":
        background = theme.colors.lightred;
        break;
      case "sentiments":
        background = theme.colors.pink;
        break;
    }

    return { background, color };
  };

  return (
    <div className={styles.wrapper}>
      {!checkboxes?.length && (
        <div className="text-center px-2 py-2 tracking-widest rounded-md uppercase select-none">
          &nbsp;
        </div>
      )}
      {checkboxes
        ?.filter((label: any) => label.skill !== "article-topics")
        .map((label: any, index: number) => {
          const { background, color } = getLabelTypeColors(label.skill);
          return (
            <label
              className={`grid grid-cols-2-auto gap-x-1 font-mono items-center px-2 py-2 tracking-widest font-normal rounded-md uppercase cursor-pointer select-none`}
              style={{ backgroundColor: background }}
              key={label.skill}
            >
              <input
                type="checkbox"
                className={`${styles.checkbox}`}
                id={`custom-checkbox-${label.skill}`}
                checked={checkedState[index]}
                onChange={() => {
                  handleOnChange(index, label.skill, checkedState[index]);
                }}
              />
              <span className={`text-xs`} style={{ color: color }}>
                {t(`${skillValueToName(label.skill)}`)}
              </span>
            </label>
          );
        })}
    </div>
  );
};

export default Checkboxes;
