import React from "react";

type ButtonProps = {
  children: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  ariaLabel: string;
  disableStyles?: boolean;
};

export default function Button({
  children,
  className,
  onClick,
  disabled,
  ariaLabel,
  disableStyles,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      area-label={ariaLabel}
      onClick={onClick}
      className={
        disableStyles
          ? `${className}`
          : `min-w-fit cursor-pointer text-center transform-none m-0 items-center py-3 px-1 text-white whitespace-nowrap text-xs md:text-xs font-mono font-bold tracking-tight hover:bg-opacity-70 hover:bg-shadeBlue bg-shadeBlue rounded-md shadow-xl ${className}`
      }
    >
      {children}
    </button>
  );
}
