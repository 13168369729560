import React, { useState } from "react";
import { ChevronClosed, ChevronOpen } from "../assets/SvgIcons";

type AccordionProps = {
  title: string;
  content: JSX.Element;
  isOpen?: boolean;
  titleClassNames?: any;
  chevronClassNames?: any;
  activeTitleClassNames?: any;
  onClick?: any;
};

const Accordion = ({
  title,
  content,
  isOpen,
  titleClassNames = "dark:text-white",
  activeTitleClassNames = "dark:text-white",
  chevronClassNames,
  onClick = (): any => {},
}: AccordionProps) => {
  const [isOpened, setOpened] = useState<boolean>(false);

  const handleAccordionOpen = () => {
    onClick();
    setOpened(!isOpened);
  };

  React.useEffect(() => {
    if (isOpen) {
      setOpened(true);
    } else {
      setOpened(false);
    }
    //eslint-disable-next-line
  }, [isOpen]);

  return (
    <div className={`w-full`}>
      <button
        className={`grid grid-flow-col items-center w-full ${
          isOpened ? "" : "border-b border-b-darkGrayBorder"
        } py-4`}
        onClick={handleAccordionOpen}
      >
        <h1
          className={`font-poppins grid text-xl justify-start items-center h-full m-0 p-0 ${
            isOpened ? activeTitleClassNames : titleClassNames
          }`}
        >
          {title}
        </h1>
        <figure
          className={`grid justify-end items-center dark:text-white text-black `}
        >
          {isOpened ? (
            <ChevronOpen className={chevronClassNames} />
          ) : (
            <ChevronClosed className={chevronClassNames} />
          )}
        </figure>
      </button>

      {isOpened && (
        <div className={`transition-all duration-600 h-full overflow-hidden`}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Accordion;
